import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import {withI18n} from 'react-i18next';
import {Icon} from 'semantic-ui-react';
import {CopyToClipboard} from 'react-copy-to-clipboard';

const addressObjToString = ({company, phone, street1, street2, street3, city, state_code, postal_code, country_code,contact}) => {
    let addressString = "";
    if (company) {
        addressString = company;
    }
    if (contact) {
        addressString = addressString+'\n'+contact;
    }
    if (street1) {
        addressString = addressString+'\n'+street1;
    }
    if (street2) {
        addressString = addressString+'\n'+street2;
    }
    if (street3) {
        addressString = addressString+'\n'+street3;
    }
    if (city) {
        addressString = addressString+'\n'+city;
    }
    if (state_code) {
        addressString = addressString+' '+state_code;
    }
    if (postal_code) {
        addressString = addressString+'  '+postal_code;
    }
    if (country_code) {
        addressString = addressString+'\n'+country_code;
    }
    if (phone) {
        addressString = addressString+'\n'+phone;
    }

    return addressString;
}

const AddressDisplay = ({t, orderType, addressType, address, tooltipId}) => (
    <div className="lara-text-wrapper">
        <span className='lara-text-label'>
            {orderType.includes('Customer') && addressType === 'Bill' ? t('BillTo') : null}
            {orderType.includes('Customer') && addressType === 'Ship' ? t('ShipTo') : null}
            {orderType.includes('Vendor') && addressType === 'Bill' ? t('BillFrom') : null}
            {orderType.includes('Vendor') && addressType === 'Ship' ? t('ShipFrom') : null}
            <CopyToClipboard text={addressObjToString(address)}>
                <Icon name='copy' className="lara-clickable" color='blue' />
            </CopyToClipboard>
        </span>
        <span 
            className='lara-text-value lara-clickable lara-tooltip-title'
            data-tip data-for={tooltipId}    
        >{address.company}</span>
        <ReactTooltip id={tooltipId} place="bottom" type="dark" effect="solid">
            <div className="lara-text-wrapper">
                <span className='lara-text-value'>{address.company}</span>
                {address.phone ? <span className='lara-text-value'>{address.phone}</span> : null}
                {address.street1 ? <span className='lara-text-value'>{address.street1}</span> : null}
                {address.street2 ? <span className='lara-text-value'>{address.street2}</span> : null}
                {address.street3 ? <span className='lara-text-value'>{address.street3}</span> : null}
                <span className='lara-text-value'>
                    {address.city ? `${address.city}, `: null}
                    {address.state_code ? `${address.state_code}    `: null}
                    {address.postal_code ? `${address.postal_code}`: null}
                </span>
            </div>
        </ReactTooltip>
    </div>
)

AddressDisplay.propTypes = {
    tooltipId: PropTypes.string.isRequired,
    addressType: PropTypes.string.isRequired,
    orderType: PropTypes.string.isRequired,
    address: PropTypes.object.isRequired
};


export default withI18n()(AddressDisplay);