import update from 'immutability-helper';
// import _ from 'lodash';
import {
    SET_BILLING_ENTRY_PAGINATED_LIST,
    MODIFY_BILLING_ENTRY,
    DELETE_BILLING_ENTRY
    // SET_BILLING_ENTRY_PAGE_PREDIFIEND_QUERIES,
    // CREATE_BILLING_ENTRY_PAGE_PREDIFINED_QUERIES
} from '../../actions/types';

export default function entryList(state = {
    list: {
        total: 0,
        per_page: 15,
        on_page: 1,
        records: [

        ]
    },
    queries: [

    ]
}, action = {}) {
    switch(action.type) {
        case SET_BILLING_ENTRY_PAGINATED_LIST:
            return update(state, {
                list: {$set: action.payload}
            });

        case MODIFY_BILLING_ENTRY:
            return update(state, {
                list: {
                    records: {[action.payload.index]: {$set: action.payload.model}}
                }
            });

        case DELETE_BILLING_ENTRY:
            return update(state, {
                list: {
                    records: {$splice: [[action.payload, 1]]}
                }
            })
        // case SET_BILLING_ENTRY_PAGE_PREDIFIEND_QUERIES:
        //     return update(state, {
        //         queries: {$set: action.payload}
        //     });

        // case CREATE_BILLING_ENTRY_PAGE_PREDIFINED_QUERIES:
        //     return update(state, {
        //         queries: {$push: [{
        //             value: _.toString(action.payload.id),
        //             text: action.payload.name,
        //             key: action.payload.id,
        //             query: action.payload.value
        //         }]}
        //     });

        default: return state;
    }
}