import React from 'react';
import PropTypes from 'prop-types';
import svgSprite from '../../../assets/svg/symbol-defs.svg';

// Aqui criamos um componente 
// que irá gerar nosso ícone SVG
const Icon = (props) => (
  <svg viewBox='0 0 16 16' className={props.className}>
    <use xlinkHref={`${svgSprite}#icon-${props.icon}`} />
  </svg>
);

Icon.propTypes = {
    icon: PropTypes.string.isRequired,
    className: PropTypes.string
};

export default Icon