import update from 'immutability-helper';
import {
    TOGGLE_SHOW_SIDE_BAR,
    SET_SUBMENU_TO_SHOW,
    GLOBAL_LOADING_START,
    GLOBAL_LOADING_DONE
} from '../../actions/types'

export default function config(state = {
    showSideBar: true,
    openSubMenu: null,
    globalLoading: false
}, action = {}) {
    switch(action.type) {
        case TOGGLE_SHOW_SIDE_BAR:
            return update(state, {
                showSideBar: {
                    $set: !state.showSideBar
                }
            });

        case SET_SUBMENU_TO_SHOW:
            return update(state,{
                openSubMenu: {$set: action.payload}
            });

        case GLOBAL_LOADING_START:
            return update(state, {
                globalLoading: {$set: true}
            });

        case GLOBAL_LOADING_DONE:
            return update(state, {
                globalLoading: {$set: false}
            });

        default: return state;
    }
}