import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {Form} from 'semantic-ui-react';

class SerialSelector extends React.PureComponent {
    onSelected = (value) => {
        const {serials, onSelectChange} = this.props;
        const index = _.findIndex(serials, s => {
            return _.toString(s.id) === value
        });
        return onSelectChange(index > -1 ? serials[index] : null);
    }

    render() {
        const {serials, label, selected} = this.props;
        const options = [];
        _.each(serials, c => {
            options.push({
                value: _.toString(c.id),
                text: c.lead,
                key: c.id
            });
        });
        options.unshift({
            value: "0",
            text: "NA",
            key: -1
        });
        return (
            <Form.Select fluid search selection 
                label={label}
                size='mini'
                options={options}
                name="serial_id" value={selected ? _.toString(selected) : "0"} 
                onChange={(e, {value}) => {
                    this.onSelected(value)
                }}
            />
        )
    }
}

SerialSelector.propTypes = {
    onSelectChange: PropTypes.func.isRequired,
    label: PropTypes.string,
    serials: PropTypes.array.isRequired
};

export default SerialSelector