import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import {withI18n} from 'react-i18next';

const InventoryDisplay = ({item, tooltipId}) => (
    <div className="lara-text-wrapper">
        <span 
            className='lara-product-tooltip'
            data-tip data-for={tooltipId}    
        >{item.name ? item.name.length > 50 ? item.name.substring(0,50) + '...' : item.name : null}</span>
        <ReactTooltip id={tooltipId} place="bottom" type="dark" effect="solid">
            <div className="lara-text-wrapper">
                <span className="lara-text-block">
                    <span className="lara-text-label">Name:</span>
                    <span className='lara-text-value'>{item.name}</span>
                </span>
                <span className="lara-text-block">
                    <span className="lara-text-label">Description:</span>
                    <span className='lara-text-value'>{item.description}</span>
                </span>
                <span className="lara-text-block">
                    <span className="lara-text-label">Categories:</span>
                    {item.categories.map(i => {
                        return <span className='lara-text-value' key={i.id}>{i.name}</span>
                    })}
                </span>
            </div>
        </ReactTooltip>
    </div>
)

InventoryDisplay.propTypes = {
    tooltipId: PropTypes.string.isRequired,
    item: PropTypes.object.isRequired
};


export default withI18n()(InventoryDisplay);