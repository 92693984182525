import update from 'immutability-helper';
import _ from 'lodash';
import {
    SET_INVENTORIES_PAGINATED_LIST,
    SET_INVENTORY_PAGE_PREDIFIEND_QUERIES,
    CREATE_INVENTORY_PAGE_PREDIFINED_QUERIES
} from '../../actions/types';

export default function inventories(state = {
    list: {
        total: 0,
        per_page: 15,
        on_page: 1,
        records: [

        ]
    },
    queries: [

    ]
}, action = {}) {
    switch(action.type) {
        case SET_INVENTORIES_PAGINATED_LIST:
            return update(state, {
                list: {$set: action.payload}
            });

        case SET_INVENTORY_PAGE_PREDIFIEND_QUERIES:
            return update(state, {
                queries: {$set: action.payload}
            });

        case CREATE_INVENTORY_PAGE_PREDIFINED_QUERIES:
            return update(state, {
                queries: {$push: [{
                    value: _.toString(action.payload.id),
                    text: action.payload.name,
                    key: action.payload.id,
                    query: action.payload.value
                }]}
            });

        default: return state;
    }
}