import React from 'react';
import PropTypes from 'prop-types';
import {Modal, Header} from 'semantic-ui-react';

const ModalWrapper = ({showModal, size, onModalClose, SubComponent, ...props}) => (
    <Modal size={size} 
        closeOnDimmerClick={false}
        closeIcon
        centered={false}
        onClose={() => {onModalClose()}}
        closeOnEscape={true} open={showModal}>
        <Header icon='pencil' content='' />
        <Modal.Content>
            <SubComponent {...props} onModalClose={onModalClose}/>
        </Modal.Content>
    </Modal>
)

ModalWrapper.propTypes = {
    showModal: PropTypes.bool.isRequired,
    onModalClose: PropTypes.func.isRequired,
    SubComponent: PropTypes.func.isRequired,
    size: PropTypes.string.isRequired
};


export default ModalWrapper