import React from 'react';
import { bindActionCreators} from 'redux';
import {Label, Icon} from 'semantic-ui-react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CategoryTagSelector from '../../includes/selectors/CategoryTagSelector';
import {
    addCategoryToResource,
    deleteCategoryToResource
} from '../../../actions/category';
import {
    ADD_CATEGORY_TO_ORDER_MODEL,
    DELETE_CATEGORY_TO_OPRDER_MODEL
} from '../../../actions/types';
import Lara from '../../../lara';
import AlertComponent from '../../includes/messages/alert';

class OrderTag extends React.PureComponent {
    onCategorySelectorSelected = (item) => {
        if (!item) {
            return;
        }
        const {categories} = this.props;
        const existCategory = categories.filter(c => {
            return c.name === item;
        });
        if (existCategory.length > 0) {
            return null;
        }
        this.props.addCategoryToResource(item, 'Order', this.props.id, ADD_CATEGORY_TO_ORDER_MODEL).then(res => {
            
        }).catch(err => {
            const errors = Lara.axiosError(err, this.props.t);
            Lara.axiosAlert(<AlertComponent 
                support={errors.support}
                message={errors.message}
                reference={errors.reference}
                t={this.props.t}
            />);
        });
    }

    onRemoveCategoryClick = (category, index) => {
        this.props.deleteCategoryToResource(category.id, 'Order', this.props.id, index, 
        DELETE_CATEGORY_TO_OPRDER_MODEL).then(res => {
        }).catch(err => {
            const errors = Lara.axiosError(err, this.props.t);
            Lara.axiosAlert(<AlertComponent 
                support={errors.support}
                message={errors.message}
                reference={errors.reference}
                t={this.props.t}
            />);
        })
    }

    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <CategoryTagSelector 
                            type='Order'
                            t={this.props.t}
                            resourceId={this.props.id}
                            onSelected={(value) => {
                                this.onCategorySelectorSelected(value)
                            }}
                        />
                    </div>
                </div>
                <div className="row lara-row-margin">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        {this.props.categories.map((i,index) => {
                            return (
                                <Label key={i.id} color='teal'>
                                    {i.name}
                                    <Icon name='delete'
                                        onClick={() => {this.onRemoveCategoryClick(i, index)}}
                                    />
                                </Label>
                            )
                        })}
                    </div>
                </div>
            </div>
        )
    }
}

OrderTag.propTypes = {
    categories: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired,
    id: PropTypes.number.isRequired,
    addCategoryToResource: PropTypes.func.isRequired,
    deleteCategoryToResource: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        addCategoryToResource,
        deleteCategoryToResource
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderTag);