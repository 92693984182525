import React, { Component } from 'react';
import { bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {withI18n} from 'react-i18next';
import PageBreadCrub from '../includes/partials/PageBreadCrub';
import CompanySettingForm from './forms/CompanySettingForm';


class CompanySettingPage extends Component {
    constructor() {
        super();
        this.state = {
            breadcrub: [
                {isLink:false, to:null, tag: 'Setting', active: false, key: 1},
                {isLink:false, to:null, tag: 'Company', active: true, key:2}
            ],
            errors: []
        }
    }
    
    //access router
    //this.props.history.push('/dashboard')
    
    render() {
        const {t} = this.props;
        return (
            <div>
                {/* Breadcrub Row */}
                <div className="row">
                    <div className="col-md-12 lara-breadcrub">
                        <PageBreadCrub lists={this.state.breadcrub} t={t}/>
                    </div>
                </div>
                {/* Main content row */}
                <div className="row">
                    <div className="col-md-12 col-xs-12">
                        <CompanySettingForm t={t} />
                    </div>
                </div>
            </div>
        )
    }
}

CompanySettingPage.propTypes = {
    history: PropTypes.shape({
       push: PropTypes.func.isRequired
    }).isRequired,
    t: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch)
}

export default withI18n()(connect(mapStateToProps, mapDispatchToProps)(CompanySettingPage));