import React from 'react';
import PropTypes from 'prop-types';
import {Form} from 'semantic-ui-react';
import FormError from '../messages/422';
import CountrySelector from '../selectors/CountrySelector';
const countries = require('../../../data/countries.json');

const onCountrySelect = (value) => {
    const country = countries.filter(c => {
        return c.value === value;
    });
    if (country.length > 0) {
        return country[0]
    };
    return {
        text: "",
        value: ""
    }
}

const AddressForm = ({model, onValueChange, onCreateSubmit, onEditSubmit, onDeleteSubmit, errors, t}) => (
    <div>
        {(errors.length > 0) ? (
            <FormError errors={errors} />
        ) : null}
        <Form>
            <Form.Group widths='equal'>
                <Form.Select 
                    options={[
                        {value: 'Billing', text: t('BillingAddress')},
                        {value: 'Shipping', text: t('ShippingAddress')},
                        {value: 'Both', text: t('BothAddress')}
                    ]}
                    fluid
                    label={t("AddressType")}
                    value={model.type}
                    onChange={(e, data) => {
                        onValueChange('type', data.value)
                    }}
                />
                <Form.Input 
                    fluid 
                    label={`${t("CompanyName")}`}
                    name="company"
                    required={true}
                    value={model.company ? model.company : ""} 
                    onChange={(e) => { onValueChange(e.target.name, e.target.value) }}
                />
            </Form.Group>
            <Form.Group widths='equal'>
                <Form.Input 
                    fluid 
                    label={t("Contact")}
                    name="contact"
                    value={model.contact ? model.contact : ""} 
                    onChange={(e) => { onValueChange(e.target.name, e.target.value) }}
                />
                <Form.Input 
                    fluid 
                    label={t("Phone")}
                    name="phone"
                    value={model.phone ? model.phone : ""} 
                    onChange={(e) => { onValueChange(e.target.name, e.target.value) }}
                />
            </Form.Group>
            <Form.Group widths='equal'>
                <Form.Input 
                    fluid 
                    label={t("StreetAddress")}
                    name="street1"
                    value={model.street1 ? model.street1 : ""} 
                    onChange={(e) => { onValueChange(e.target.name, e.target.value) }}
                />
                <Form.Input 
                    fluid 
                    label={t("StreetAddress")}
                    name="street2"
                    value={model.street2 ? model.street2 : ""} 
                    onChange={(e) => { onValueChange(e.target.name, e.target.value) }}
                />
                <Form.Input 
                    fluid 
                    label={t("StreetAddress")}
                    name="street3"
                    value={model.street3 ? model.street3 : ""} 
                    onChange={(e) => { onValueChange(e.target.name, e.target.value) }}
                />
            </Form.Group>
            <Form.Group widths='equal'>
                <Form.Input 
                    fluid 
                    label={t("City")}
                    name="city"
                    value={model.city ? model.city : ""} 
                    onChange={(e) => { onValueChange(e.target.name, e.target.value) }}
                />
                <Form.Input 
                    fluid 
                    label={t("Postal")}
                    name="postal_code"
                    value={model.postal_code ? model.postal_code : ""} 
                    onChange={(e) => { onValueChange(e.target.name, e.target.value) }}
                />
                <Form.Input 
                    fluid 
                    label={t("StateCode")}
                    name="state_code"
                    value={model.state_code ? model.state_code : ""} 
                    onChange={(e) => { onValueChange(e.target.name, e.target.value) }}
                />
            </Form.Group>
            <Form.Group widths='equal'>
                <CountrySelector 
                    selected={model.country_code}
                    countrySelected={(value) => {onValueChange('country_code', onCountrySelect(value))}}
                    label={t("Country")}
                />
            </Form.Group>
        </Form>
    </div>
)

AddressForm.propTypes = {
    model: PropTypes.object.isRequired,
    onValueChange: PropTypes.func.isRequired,
    errors: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired
};


export default AddressForm