import React from 'react'
import PropTypes from 'prop-types'
import {Message} from 'semantic-ui-react';
import {withI18n} from 'react-i18next';

const FormError = ({errors, t}) => (
    <Message
        color='red'
        header={t('DataSubmissionError(s)')}
        list={errors}
    />
)

FormError.propTypes = {
    errors: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired
};


export default withI18n()(FormError)