import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

class DataGridView extends Component {
    //access router
    //this.props.history.push('/dashboard')
    
    render() {
        return (
            <div 
                className="ag-theme-balham"
                style={{ 
                    height: '75vh', 
                    width: '100%' }} 
                >
                <AgGridReact
                    columnDefs={this.props.columnDefs}
                    rowData={this.props.rowData}>
                </AgGridReact>
            </div>
        )
    }
}

DataGridView.propTypes = {
    columnDefs: PropTypes.array.isRequired,
    rowData: PropTypes.array.isRequired
};

export default DataGridView