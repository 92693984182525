import {api} from '../api';
import {
    SET_LOCATIONS_PAGINATED_LIST,
    LOCATION_SAVED_ADD_TO_LIST
} from './types';

export const fetchPaginatedList = (path) => (dispatch) => {
    return api.locations.paginated(path).then(res => {
        dispatch({
            type: SET_LOCATIONS_PAGINATED_LIST,
            payload: res.data.data
        });
        return res;
    }).catch(err => {throw err});
}

export const locationSaved = (index, model) => {
    return {
        type: LOCATION_SAVED_ADD_TO_LIST,
        payload: {index, model}
    }
}