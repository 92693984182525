import React, { Component } from 'react';
import { bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import {withI18n} from 'react-i18next';
import PropTypes from 'prop-types';
import {Segment, Loader, Dimmer, Confirm,
    Form, Modal, Header} from 'semantic-ui-react';
import {
    fetchById,
    setEmptyShipmentInStore,
    resetModelAfterFillAll,
    updateShipmentProperty,
    shippoPurchased,
    shippoVoided,
    deleteShipmentFile,
    newFileUploaded,
    onChangeShipStationStatus,
    shipmentCommentAdded
} from '../../actions/shipment';
import PageBreadCrub from '../includes/partials/PageBreadCrub';
import AlertComponent from '../includes/messages/alert';
import SegmentRibbon from '../includes/partials/SegmentRibbon';
import Lara from '../../lara';
import LaraPagination from '../../laraPagination';
import ResourceTextPropertyView from '../includes/partials/ResourceTextPropertyView';
import ResourceBooleanView from '../includes/partials/ResourceBooleanPropertyView';
import AddressDisplay from '../includes/partials/AddressDisplay';
import FulfillmentTable from './FulfillmentTable';
import UpdateLineQtyTable from './components/UpdateLineQtyTable';
import ShipmentBillingModal from './components/ShipmentBillingModal';
import {api} from '../../api';
import ZaloraFulfillment from './components/ZaloraFulfillment';
import ShippoFulfillment from './components/ShippoFulfillment';
import FBAFulfillmentSidePanel from './components/FBAFulfillmentSidePanel';
import ListPrintMenu from './components/ListPrintMenu';
import FileUploadButton from '../includes/partials/FileUploadButton';
import ShipmentAttachmentList from '../includes/partials/ShipmentAttachmentList';
import ModalWrapper from '../includes/partials/ModalWrapper';
import ShipmentCommentList from '../includes/partials/ShipmentCommentList';
import SecondaryButton from '../includes/buttons/SecondaryButton';
import PrimaryNaviButton from '../includes/buttons/PrimaryNaviButton';
import PrimaryButton from '../includes/buttons/PrimaryButton';
import DeleteButton from '../includes/buttons/DeleteButton';
import ExternalResourceWebhook from "./components/ExternalResourceWebhook";
import ShipStationSideUtility from "./components/ShipStationSideUtility";
import ShipmentCommentForm from "../orders/parts/ShipmentCommentForm";
import ExcelReceivingSegment from "./components/ExcelReceivingSegment";
import ExcelShippingSegment from "./components/ExcelShippingSegment";
import CarrierUpdatePanel from "./components/CarrierUpdatePanel";

class ShipmentViewPage extends Component {
    constructor() {
        super();
        this.state = {
            modelId: null,
            breadcrub: [
                {isLink:false, to:null, tag: '', active: true, key: 1}
            ],
            loading: false,
            fulfillmentType: "",
            deleteConfirm: false,
            deleteFinishedConfirm: false,
            updateModalOpen: false,
            updateField: "na",
            updateValue: "",
            updateLineQtyModalOpen: false,
            packModalOpen: false,
            billingModal: false,
            uploadModal: false,
            commentModal: false,
        }
    }

    componentDidMount() {
        const {location, match} = this.props;
        const {type, id} = match.params;
        this.setState({
            loading: true
        });
        this.props.fetchById(id).then(res => {
            this.setState({
                modelId: id,
                loading: false,
                fulfillmentType: type,
                breadcrub: this.getBreadCrub(location.backTo, id, type)
            })
        }).catch(err => {
            this.handleHttpErrors(err);
            this.setState({loading: false});
        })
    }

    componentWillUnmount() {
        this.props.setEmptyShipmentInStore();
    }

    reloadShipment = () => {
        if (this.props.model.id) {
            this.setState({
                loading: true
            }, () => {
                this.props.fetchById(this.props.model.id).then(res => {
                    this.setState({loading: false})
                })
            });
        }
    }

    getBreadCrub = (backTo, name, type) => {
        if (!backTo) {
            backTo = `/f/${type}/default/${LaraPagination.shipments(type, false)}`;
        }
        return [
            {isLink:true, to: backTo, tag: _.replace(type,'-',' '), active: false, key: 1},
            {isLink:false, to:null, tag: name, active: true, key: 2}
        ]
    }

    updateShipmentProperty = () => {
        const {updateField, updateValue} = this.state;
        if (updateField === 'na') {
            return null;
        }
        this.setState({
            loading: true
        })
        this.props.updateShipmentProperty(this.props.model.id, updateField, updateValue).then(res => {
            this.setState({
                loading: false,
                updateField: "na",
                updateValue: ""
            })
        }).catch(err => {
            this.handleHttpErrors(err);
        })
    }

    segmentBtnGroupOrder = () => {
        const {model, t, preference} = this.props;
        return <React.Fragment>

            {model.status === 'Ready' && !model.billed ? <SecondaryButton
                size='mini'
                float='right'
                label={t('Bill')}
                onClick={() => {this.setState({billingModal: true})}}
                loading={this.state.loading}
            /> : null}
            {(model.order.type === 'Customer Order' || model.order.type === 'Vendor Return') && !model.packed && model.status !== 'Finished' && !model.user ? <PrimaryNaviButton
                link={`/f/${this.state.fulfillmentType}/${model.id}/action/pack`}
                size='mini'
                float='right'
                loading={false}
                label={t('Pack')}
            /> : null}
            {preference.quick_fulfill && model.status === 'In Warehouse' && !model.user? <PrimaryButton
                size='mini'
                float='right'
                label={t('Quick Fulfill')}
                onClick={() => {this.onQuickFulfillClick()}}
                loading={this.state.loading}
            /> : null}
            <div style={{float:'right', paddingTop: 4}}>
                <ListPrintMenu
                    fulfillmentType={this.state.fulfillmentType}
                    getListItemSelected={() => {
                        return this.getListItemSelected()
                    }}
                    shipmentReport={true}
                    reFethcRcords={() => {
                        return null;
                    }}
                    modelId={this.props.model.id}
                    adminList={true}
                    isLp={false}
                    modelLines={this.props.model.shipment_lines}
                />
            </div>
        </React.Fragment>
    }

    getListItemSelected = () => {
        return [this.props.model.id]
    }

    onQuickFulfillClick = () => {
        this.setState({
            loading: true
        });
        api.shipments.fillAll(this.props.model.id).then(res => {
            const {data} = res.data;
            this.props.resetModelAfterFillAll(data);
            this.setState({
                loading: false
            });
        }).catch(err => {
            this.handleHttpErrors(err);
        })
    }

    handleHttpErrors = (err) => {
        const errors = Lara.axiosError(err, this.props.t);
        if (errors.http_code === 422) {
            this.setState({
                errors: errors.message,
                loading: false
            });
        } else {
            this.setState({
                loading: false
            });
            Lara.axiosAlert(<AlertComponent
                support={errors.support}
                message={errors.message}
                reference={errors.reference}
                t={this.props.t}
            />);
        }
    }

    onVoidClick = () => {
        this.setState({
            loading: true
        });
        //this.props.history.push(`/f/${this.state.fulfillmentType}/default/${LaraPagination.shipments(this.state.fulfillmentType,false)}`)
        api.shipments.permanentlyDelete(this.props.model.id).then(res => {
            this.props.history.push(`/f/${this.state.fulfillmentType}/default/${LaraPagination.shipments(this.state.fulfillmentType,false)}`)
        }).catch(err => {
            this.handleHttpErrors(err);
        })
    }

    onVoidFinishedClick = () => {
        this.setState({
            loading: true
        });
        //this.props.history.push(`/f/${this.state.fulfillmentType}/default/${LaraPagination.shipments(this.state.fulfillmentType,false)}`)
        api.shipments.permanentlyDeleteFinished(this.props.model.id).then(res => {
            this.props.history.push(`/f/${this.state.fulfillmentType}/default/${LaraPagination.shipments(this.state.fulfillmentType,false)}`)
        }).catch(err => {
            this.handleHttpErrors(err);
        })
    }

    onZaloraFulfilled = () => {
        this.props.updateShipmentProperty(this.props.model.id, 'exist_in_fba', 1);
    }

    commentCreated = (data) => {
        this.props.shipmentCommentAdded(data.data);
    }

    shipmentFlexShipped = (tracking, bookingId, attachments) => {
        this.props.updateShipmentProperty(null, 'tracking', {
            waybill: tracking,
            cost: '',
            status: bookingId
        });
        _.each(attachments, a => {
            this.props.newFileUploaded(a);
        })
    }

    carrierUpdated = (newModel) => {
        // console.log(newModel);
        this.props.updateShipmentProperty(this.props.model.id, 'courier', newModel);
    }

    render() {
        const {t, model} = this.props;
        const courier = _.isObject(model.courier) ? model.courier : {};
        return (
            <div>
                {/* Breadcrub Row */}
                <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12 lara-breadcrub">
                        <PageBreadCrub lists={this.state.breadcrub} t={t}/>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-8 col-sm-8 col-xs-12">
                        <Segment>
                            <Dimmer inverted active={this.state.loading}>
                                <Loader />
                            </Dimmer>
                            <SegmentRibbon label={model.id ? _.toString(model.id) : "Loading..."} Buttons={
                                () => {return this.segmentBtnGroupOrder()}
                            }/>
                            {model.id ? <div className="row">
                                <div className="col-md-4 col-sm-6 col-xs-12">
                                    <ResourceTextPropertyView
                                        label='Status'
                                        value={model.status}
                                    />
                                    <AddressDisplay
                                        tooltipId='shipToolTip'
                                        addressType='Ship'
                                        orderType={this.state.fulfillmentType === 'inbound' ? 'Vendor Order' : 'Customer Order'}
                                        address={model.shipping_addr}
                                    />
                                    <ResourceTextPropertyView
                                        label='ShipDate'
                                        value={model.ship_date}
                                    />
                                    <ResourceTextPropertyView
                                        label='CancelDate'
                                        value={model.cancel_date}
                                    />
                                </div>
                                <div className="col-md-4 col-sm-6 col-xs-12">
                                    <ResourceBooleanView
                                        label="ShipStation"
                                        value={model.ss_order_id}
                                    />
                                    <ResourceTextPropertyView
                                        label='MobileLocked'
                                        value={model.user ? model.user.name : "No"}
                                    />
                                    <ResourceTextPropertyView
                                        label='PrintCount'
                                        value={model.print_count ? model.print_count : "0"}
                                    />
                                    <ResourceBooleanView
                                        label="Billed"
                                        value={model.billed}
                                    />
                                </div>
                                <div className="col-md-4 col-sm-6 col-xs-12">
                                    <ResourceTextPropertyView
                                        label='Reference'
                                        value={model.order ? model.order.reference : ""}
                                    />
                                    <ResourceTextPropertyView
                                        label='Courier'
                                        value={model.courier ? model.courier.name : ""}
                                    />
                                    <ResourceTextPropertyView
                                        label='CourierPaymentMethod'
                                        value={model.shipping ? t(_.upperFirst(model.shipping.payment)) : null}
                                    />
                                    {/* <ResourceBooleanView
                                        label='ShipStation'
                                        value={model.ss_order_id ? true : false}
                                    /> */}
                                </div>
                            </div> : null}
                            {model.id ? <FulfillmentTable t={t} action={this.state.fulfillmentType} /> : null}
                        </Segment>
                    </div>
                    <div className="col-md-4 col-sm-4 col-xs-12 lara-secondary-segment-wrapper">
                        {model.id ? <Segment>
                            <SegmentRibbon label="Actions" Buttons={() => {
                                return (
                                    <React.Fragment>
                                    {model.status !== 'Finished' && !model.user ? <DeleteButton
                                        size='mini'
                                        float='right'
                                        label={t('Void')}
                                        onClick={() => {this.setState({deleteConfirm: true})}}
                                        loading={this.state.loading}
                                    /> : null}
                                    {model.status === 'Finished' ? <DeleteButton
                                        size='mini'
                                        float='right'
                                        label={t('Void')}
                                        onClick={() => {this.setState({deleteFinishedConfirm: true})}}
                                        loading={this.state.loading}
                                    /> : null}
                                    {!model.user ? <SecondaryButton
                                        size='mini'
                                        float='right'
                                        label={t('Update')}
                                        onClick={() => {
                                            this.setState({updateModalOpen: true})
                                        }}
                                        loading={this.state.loading}
                                    /> : null}
                                    {model.status === 'In Warehouse' && !model.user ? <SecondaryButton
                                        size='mini'
                                        float='right'
                                        label={t('Change')}
                                        onClick={() => {this.setState({
                                            updateLineQtyModalOpen: true
                                        })}}
                                        loading={this.state.loading}
                                    /> : null}
                                    {(model.order.type === 'Customer Order' || model.order.type === 'Vendor Return')
                                        && model.packed && model.status !== 'Finished' ?
                                            <PrimaryNaviButton
                                                link={`/f/${this.state.fulfillmentType}/${model.id}/action/pack`}
                                                size='mini'
                                                float='right'
                                                loading={false}
                                                label={t('Repack')}
                                            />
                                            : null}
                                </React.Fragment>)
                            }}/>
                            <div className="row">
                                <div className="col-md-8 col-xs-8 col-xs-12">
                                    <ResourceTextPropertyView
                                        label='Waybill'
                                        value={model.tracking ? model.tracking.waybill : ""}
                                    />
                                </div>
                                <div className="col-md-4 col-xs-4 col-xs-12">
                                    <ResourceBooleanView
                                        label='Packed'
                                        value={model.packed}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-4 col-sm-4 col-xs-12">
                                    <ResourceTextPropertyView
                                        label='ShippingCharge'
                                        value={model.tracking ? model.tracking.cost : ""}
                                    />
                                </div>
                                <div className="col-md-4 col-sm-4 col-xs-12">
                                    <ResourceTextPropertyView
                                        label='CartonCount'
                                        value={model.carton_count ? model.carton_count : "0"}
                                    />
                                </div>
                                <div className="col-md-4 col-sm-4 col-xs-12">
                                    <ResourceTextPropertyView
                                        label='Attr1'
                                        value={model.attr_1 ? model.attr_1 : ''}
                                    />
                                </div>
                            </div>
                        </Segment>  : null}

                        {model.id && model.order.type === 'Customer Order' && model.status !== 'Finished' ? <CarrierUpdatePanel
                            model={model}
                            t={t}
                            courierUpdated={(new_model) => {
                                this.carrierUpdated(new_model)
                            }}
                        /> : null}

                        {model.id && model.fba_shipment_id ? <FBAFulfillmentSidePanel
                            model={model}
                            t={t}
                            updateShipmentProperty={this.props.updateShipmentProperty}
                        /> : null}
                        {model.id && courier.shippo_id && (model.order.type === 'Customer Order' || model.order.type === 'Vendor Return')? <ShippoFulfillment
                                model={model}
                                t={t}
                                measure={this.props.preference.measure}
                                homeCountry={this.props.preference.home_country_code}
                                shippoPurchased={(data) => {
                                    this.props.shippoPurchased(data);
                                }}
                                shippoVoided={(data, index) => {
                                    this.props.shippoVoided(index, data);
                                }}
                                server_time={this.props.server_time}
                                host={this.props.preference.host}
                            /> : null}
                        {model.id && model.order.source.source === 'Zalora' ?
                            <ZaloraFulfillment
                                id={model.id}
                                onZaloraFulfilled={() => {
                                    this.onZaloraFulfilled();
                                }}
                                t={t}
                                exist_in_fba={model.exist_in_fba ? true : false}
                                courier={courier.name}
                                tracking={model.tracking.waybill ? model.tracking.waybill : ""}
                                print_key={model._key}
                                host={this.props.preference.host}
                            /> : null}
                        {model.id ? <Segment>
                        <SegmentRibbon label={t('Comments')} Buttons={
                            () => {return (<React.Fragment>
                                <PrimaryButton
                                    size='mini'
                                    float='right'
                                    label={t('Add')}
                                    onClick={() => {this.setState({commentModal: true})}}
                                    loading={false}
                                />
                            </React.Fragment>)}
                        }/>
                            <ShipmentCommentList
                                orderNote={model.order.note}
                                comments={model.comments}
                                t={t}
                            />
                        </Segment> :  null}
                        {model.id ? <Segment>
                            <SegmentRibbon label={t('Attachments')} Buttons={
                                () => {return (<React.Fragment>
                                    <PrimaryButton
                                        size='mini'
                                        float='right'
                                        label={t('Upload')}
                                        onClick={() => {this.setState({uploadModal: true})}}
                                        loading={this.state.loading}
                                    />
                                </React.Fragment>)}
                            }/>
                            <ShipmentAttachmentList
                                files={model.files}
                                t={t}
                                onFileDeleted={(index) => {
                                    this.props.deleteShipmentFile(index);
                                }}
                                user={this.props.user}
                            />
                        </Segment> :  null}

                        {model.id && this.state.fulfillmentType === 'inbound' && model.status === 'In Warehouse' ? <ExcelReceivingSegment
                            model={model} t={t} onExcelUploaded={() => {
                                this.reloadShipment();
                            }}
                        /> : null}

                        {model.id && this.state.fulfillmentType === 'outbound'
                                    && model.status === 'In Warehouse'
                                    && this.props.server_pref.outbound_excel_upload_bool?
                            <ExcelShippingSegment
                                    model={model} t={t} onExcelUploaded={() => {
                                    this.reloadShipment();
                                }}
                        /> : null}

                        {model.id ? <ExternalResourceWebhook
                            cid={model.order.client_id}
                            sid={model.id}
                            oid={model.order.id}
                        /> : null}
                        {model.id && model.courier && model.courier.ss_mapping_carrier ? <ShipStationSideUtility
                            model={model}
                            t={this.props.t}
                            updateShipmentProperty={(field, value) => {
                                this.props.updateShipmentProperty(model.id, field, value)
                            }}
                            onChangeShipStationStatus={(idOrNull) => {
                                this.props.onChangeShipStationStatus(idOrNull);
                            }}
                        /> : null}

                    </div>
                </div>
                <Confirm open={this.state.deleteConfirm}
                    content={t('ShipmentVoidAlert')}
                    onCancel={() => {
                        this.setState({
                            deleteConfirm: false
                        })
                    }}
                    cancelButton={t('Go Back')}
                    confirmButton={t('Confirm')}
                    onConfirm={() => this.onVoidClick()}
                />
                <Confirm open={this.state.deleteFinishedConfirm}
                    content={t('ShipmentVoidFinishedAlert')}
                    onCancel={() => {
                        this.setState({
                            deleteFinishedConfirm: false
                        })
                    }}
                    cancelButton={t('Go Back')}
                    confirmButton={t('Confirm')}
                    onConfirm={() => this.onVoidFinishedClick()}
                />
                <Modal
                    closeOnDimmerClick={false}
                    closeIcon
                    onClose={() => {
                        this.setState({updateModalOpen: false})
                    }}
                    size='mini'
                    closeOnEscape={true} open={this.state.updateModalOpen}>
                    <Header icon='pencil' content={null} />
                    <Modal.Content>
                        <Form size='mini'>
                            <Form.Select label={t('ShipmentProperty')} options={[
                                {value: 'na', text: "NA"},
                                {value: 'waybill', text: t('Waybill')},
                                {value: 'cost', text: t('Cost')},
                                {value: 'carton_count', text: t('CartonCount')},
                                {value: 'attr_1', text: "Attr 1"}
                            ]} value={this.state.updateField} onChange={(e, {value}) => {
                                this.setState({
                                    updateField: value
                                })
                            }} fluid/>
                            <Form.Input label={t('NewValue')} value={this.state.updateValue}
                                onChange={(e, {value}) => {
                                    this.setState({
                                        updateValue: value
                                    })
                                }} fluid />
                            <div className="row">
                                <div className="col-md-12">
                                <PrimaryButton
                                    size='mini'
                                    float='right'
                                    label={t('Save')}
                                    onClick={() => {
                                        this.updateShipmentProperty();
                                    }}
                                    loading={this.state.loading}
                                />
                                </div>
                            </div>
                        </Form>
                    </Modal.Content>
                </Modal>

                <Modal
                    closeOnDimmerClick={false}
                    closeIcon
                    onClose={() => {
                        this.setState({updateLineQtyModalOpen: false})
                    }}
                    closeOnEscape={true} open={this.state.updateLineQtyModalOpen}>
                    <Header icon='pencil' content={null} />
                    <Modal.Content>
                        <UpdateLineQtyTable
                            t={t}
                        />
                    </Modal.Content>
                </Modal>

                <Modal
                    closeOnDimmerClick={false}
                    closeIcon
                    onClose={() => {
                        this.setState({billingModal: false})
                    }}
                    closeOnEscape={true} open={this.state.billingModal}>
                    <Header icon='credit card outline' content={null} />
                    <Modal.Content>
                        <ShipmentBillingModal
                            onCloseModal={() => {
                                this.setState({
                                    billingModal: false
                                })
                            }}
                        />
                    </Modal.Content>
                </Modal>
                <ModalWrapper
                    showModal={this.state.commentModal}
                    onModalClose={() => {
                        this.setState({
                            commentModal: false
                        })
                    }}
                    SubComponent={() => {
                        return <ShipmentCommentForm
                            commentable_id={model.id}
                            commentable_type='Shipment'
                            t={t}
                            commentCreated={(data) => {
                                this.commentCreated(data);
                            }}
                            user={this.props.user}
                        />
                    }}
                    size='mini'
                />
                <ModalWrapper
                    showModal={this.state.uploadModal}
                    onModalClose={() => {
                        this.setState({
                            uploadModal: false
                        })
                    }}
                    SubComponent={() => {
                        return <FileUploadButton
                            name={t('Upload')+' '+t('Attachments')}
                            type='file'
                            filable_id={model.id}
                            filable_type="Shipment"
                            onSuccess={(data) => { this.props.newFileUploaded(data.data) }}
                            t={this.props.t}
                            comment={true}
                        />
                    }}
                    size='mini'
                />
            </div>
        )
    }
}

ShipmentViewPage.propTypes = {
    model: PropTypes.object.isRequired,
    fetchById: PropTypes.func.isRequired,
    setEmptyShipmentInStore: PropTypes.func.isRequired,
    resetModelAfterFillAll: PropTypes.func.isRequired,
    deleteShipmentFile: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    history: PropTypes.shape({
       push: PropTypes.func.isRequired
    }).isRequired,
    location: PropTypes.object.isRequired,
    preference: PropTypes.object.isRequired,
    updateShipmentProperty: PropTypes.func.isRequired,
    shippoPurchased: PropTypes.func.isRequired,
    shippoVoided: PropTypes.func.isRequired,
    server_time: PropTypes.string.isRequired,
    newFileUploaded: PropTypes.func.isRequired,
    onChangeShipStationStatus: PropTypes.func.isRequired,
    shipmentCommentAdded: PropTypes.func.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            type: PropTypes.string.isRequired,
            id: PropTypes.string.isRequired
        }),
        path: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired
    }),
    server_pref: PropTypes.object.isRequired,
    build: PropTypes.number.isRequired
};

function mapStateToProps(state) {
    return {
        model: state.shipmentModel,
        preference: state.system.preference,
        server_time: state.system.server_time,
        user: state.system.user,
        server_pref: state.system.server_pref,
        build: state.system.build
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchById,
        setEmptyShipmentInStore,
        resetModelAfterFillAll,
        updateShipmentProperty,
        shippoPurchased,
        shippoVoided,
        newFileUploaded,
        deleteShipmentFile,
        onChangeShipStationStatus,
        shipmentCommentAdded
    }, dispatch)
}

export default withI18n()(connect(mapStateToProps, mapDispatchToProps)(ShipmentViewPage));
