import React, { Component } from 'react';
import _ from 'lodash';
import { bindActionCreators} from 'redux';
import update from 'immutability-helper';
import {withI18n} from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {Segment, Modal, List, Label, Message, Header} from 'semantic-ui-react';
import SegmentRibbon from '../../includes/partials/SegmentRibbon';
import AlertComponent from '../../includes/messages/alert';
import {
    saveConfigurationResourceToSever,
    deleteConfigurationResource
} from '../../../actions/setting';
import Lara from '../../../lara';
import ConfigurationResourceForm from '../forms/ConfigurationResourceForm';
import PrimaryButton from '../../includes/buttons/PrimaryButton';
import DeleteButton from '../../includes/buttons/DeleteButton';

const ModelName = 'Term';

class TermComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            index: null,
            errors: [],
            model: {

            },
            modalOpen: false,
            loading: false
        }
    }

    setModelThenOpenModal = (index, model) => {
        this.setState({
            index: index,
            model: model,
            modalOpen: true
        });
    }

    onModelValueChange = (field, value) => {
        this.setState({
            model: update(this.state.model, {
                [field]: {$set: value}
            })
        });
    }

    onDeleteSubmit = () => {
        this.setState({
            loading: true
        });
        this.props.deleteConfigurationResource(ModelName, this.state.index, this.state.model).then(res => {
            this.setState({
                loading: false,
                model: {},
                index: null,
                modalOpen: false
            });
        }).catch(err => {
            const errors = Lara.axiosError(err, this.props.t);
            if (errors.http_code === 422) {
                this.setState({
                    errors: errors.message,
                    loading: false
                });
            } else {
                this.setState({
                    loading: false
                });
                Lara.axiosAlert(<AlertComponent 
                    support={errors.support}
                    message={errors.message}
                    reference={errors.reference}
                    t={this.props.t}
                />);
            }
        });
    }

    onSaveSubmit = () => {
        const errors = this.validateInput();
        if (_.isEmpty(errors)) {
            this.setState({
                loading: true
            });
            this.props.saveConfigurationResourceToSever(ModelName,this.state.index, this.state.model).then(res => {
                this.setState({
                    errors: [],
                    loading: false,
                    modalOpen: false,
                    index: null,
                    model: {}
                });
            }).catch(err => {
                const errors = Lara.axiosError(err, this.props.t);
                if (errors.http_code === 422) {
                    this.setState({
                        errors: errors.message,
                        loading: false
                    });
                } else {
                    this.setState({
                        loading: false
                    });
                    Lara.axiosAlert(<AlertComponent 
                        support={errors.support}
                        message={errors.message}
                        reference={errors.reference}
                        t={this.props.t}
                    />);
                }
            });
        } else {
            this.setState({
                errors: errors
            });
        }
    }

    validateInput = () => {
        const {t} = this.props;
        const {name} = this.state.model;
        const errors = [];
        if (!name) {
            errors.push(t('Term name is required'));
        }
        return errors;
    }

    render() {
        const {t, lists} = this.props;
        return (
            <div>
                <Segment>
                    <SegmentRibbon label={t("PaymentTerm")} Buttons={
                        () => {return (<React.Fragment>
                            <PrimaryButton
                                size='mini'
                                float='right'
                                label={t('Create')}
                                onClick={() => {this.setModelThenOpenModal(null, Lara.models.setting(ModelName))}}
                                loading={false}
                            />
                        </React.Fragment>)}
                    }/>
                    <Message>
                        <Message.Header>{t("PaymentTerm")}</Message.Header>
                        <p>
                        {t('MessagPaymentTermPage')}
                        </p>
                    </Message>
                    <List divided selection>
                        {lists.map((i, index) => {
                            return (<List.Item key={i.id} onClick={() => {
                                this.setModelThenOpenModal(index, i);
                            }}>
                                <Label color='teal' horizontal>
                                    {i.name}
                                </Label>
                                {i.description}
                            </List.Item>)
                        })}
                    </List>
                </Segment>
                <Modal size="small" 
                    closeOnDimmerClick={false}
                    closeIcon
                    onClose={() => {this.setState({modalOpen: false})}}
                    closeOnEscape={true} open={this.state.modalOpen}>
                    <Header icon='disk' content={ModelName} />
                    <Modal.Content>
                        {_.isEmpty(this.state.model) ? null : <ConfigurationResourceForm
                            errors={this.state.errors}
                            t={t}
                            type={ModelName}
                            model={this.state.model}
                            onFormValueChange={(field, value) => {
                                this.onModelValueChange(field, value);
                            }}
                        />}
                    </Modal.Content>
                    <Modal.Actions>
                        <div className="row">
                            <div className="col-md-12">
                                <DeleteButton
                                    size='mini'
                                    float='left'
                                    label={t('Delete')}
                                    onClick={() => {this.onDeleteSubmit()}}
                                    loading={this.state.loading}
                                />
                                <PrimaryButton
                                    size='mini'
                                    float='right'
                                    label={t('Save')}
                                    onClick={() => {this.onSaveSubmit()}}
                                    loading={this.state.loading}
                                />
                            </div>
                        </div>
                    </Modal.Actions>
                </Modal>
            </div>
        )
    }
}

TermComponent.propTypes = {
    saveConfigurationResourceToSever: PropTypes.func.isRequired,
    deleteConfigurationResource: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    lists: PropTypes.array.isRequired
};

function mapStateToProps(state) {
    return {
        lists: state.system.resources.terms
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        saveConfigurationResourceToSever,
        deleteConfigurationResource
    }, dispatch)
}

export default withI18n()(connect(mapStateToProps, mapDispatchToProps)(TermComponent));