import qs from 'qs';

const LaraPagination = {
    inventories: (isRequestSearch = false) => {
        let query = {
            on_page: 1,
            per_page: 15,
            order_by: {
                sku: 'asc'
            },
            filter_by: {
                is_active: '1',
                type: 'Item,Kit'
            },
            query: [

            ],
            search: null
        };
        return isRequestSearch ? query : qs.stringify(query)
    },
    basicOrders: (type, isRequestSearch = false) => {
        let query = {
            on_page: 1,
            per_page: 30,
            order_by: {
                updated_at: 'desc'
            },
            filter_by: {
                status: 'Open,Processed,Finished,Cancelled',
                type: type
            },
            query: [

            ],
            advanced: 'no',
            search: null
        };
        return isRequestSearch ? query : qs.stringify(query)
    },
    orders: (type, isRequestSearch = false) => {
        let query = {
            on_page: 1,
            per_page: 15,
            order_by: {
                updated_at: 'desc'
            },
            filter_by: {
                status: 'Open,Processed,Finished,Cancelled',
                bo: '0,1',
                can_process: '0,1',
                type: type
            },
            query: [

            ],
            advanced: 'yes',
            search: null
        };
        return isRequestSearch ? query : qs.stringify(query)
    },
    relations: (isRequestSearch = false, type) => {
        let query = {
            on_page: 1,
            per_page: 15,
            order_by: {
                name: 'asc'
            },
            filter_by: {
                is_active: '1',
                type: `${type}`
            },
            query: [

            ],
            search: null
        };
        return isRequestSearch ? query : qs.stringify(query)
    },
    users: (isRequestSearch = false) => {
        let query = {
            on_page: 1,
            per_page: 15,
            order_by: {
                name: 'asc'
            },
            filter_by: {
                is_active: '1'
            },
            query: [

            ],
            search: null
        };
        return isRequestSearch ? query : qs.stringify(query)
    },

    shipments: (type, isRequestSearch = false) => {
        if (type !== 'inbound' && type !== 'outbound') {
            type = 'outbound'
        }
        let query = {
            on_page: 1,
            per_page: 15,
            order_by: {
                ship_date: 'desc'
            },
            filter_by: {
                status: 'In Warehouse,Ready',
                billed: '0,1',
                _type: type
            },
            query: [

            ],
            search: null
        };
        return isRequestSearch ? query : qs.stringify(query)
    },

    operatiors: (t) => {
        return [
            {value: '=', text: t('Equal'), index: '1'},
            {value: '>', text: t('Greater'), index: '2'},
            {value: '<', text: t('Less'), index: '3'},
            {value: '>=', text: t('GreatEqual'), index: '4'},
            {value: '<=', text: t('LessEqual'), index: '5'},
            {value: 'contain', text: t('Include'), index: '6'},
        ]
    },

    inventoryQueriable: (t) => {
        return [
            {value: 'sku', text: t('SKU'), index: '1'},
            {value: 'upc', text: t('UPC'), index: '2'},
            {value: 'in_stock', text: t('Total'), index: '3'},
            {value: 'reserved', text: t('Reserve'), index: '4'},
            {value: 'available', text: t('Available'), index: '5'},
            {value: 'created_at', text: t('CreatedAt'), index: '6'},
            {value: 'updated_at', text: t('UpdatedAt'), index: '7'},
            {value: 'name', text: t('Category'), index: '8'}
        ]
    },

    //------Start: July 18, 2020 Update-----//
    inventoryQuariableBasic: (t) => {
        return [
            {value: 'sku', text: t('SKU'), index: '1'},
            {value: 'upc', text: t('UPC'), index: '2'},
            // {value: 'in_stock', text: t('Total'), index: '3'},
            // {value: 'reserved', text: t('Reserve'), index: '4'},
            {value: 'created_at', text: t('CreatedAt'), index: '6'},
            {value: 'updated_at', text: t('UpdatedAt'), index: '7'}
        ]
    },
    //------End: July 18, 2020 Update-----//

    orderQueriable: (t) => {
        return [
            {value: 'reference', text: t('Reference'), index: '1'},
            {value: 'po', text: t('PO'), index: '2'},
            {value: 'created_at', text: t('CreatedAt'), index: '3'},
            {value: 'updated_at', text: t('UpdatedAt'), index: '4'},
            {value: 'name', text: t('Category'), index: '5'}
        ]
    },

    shipmentQueriable: (t) => {
        return [
            {value: 'reference', text: `${t('Order')} ${t('Reference')}`, index: '1'},
            {value: 'po', text: `${t('Order')} ${t('PO')}`, index: '2'},
            {value: 'client_id', text: t('Client'), index: '5'},
            {value: 'courier_id', text: t('Courier'), index: '6'},
            {value: 'created_at', text: t('CreatedAt'), index: '3'},
            {value: 'updated_at', text: t('UpdatedAt'), index: '4'}
        ]
    },

    clients: (isRequestSearch = false) => {
        let query = {
            on_page: 1,
            per_page: 15,
            order_by: {
                company: 'asc'
            },
            filter_by: {
                is_active: '1'
            },
            query: [

            ],
            search: null
        };
        return isRequestSearch ? query : qs.stringify(query)
    },

    integrations: (isRequestSearch = false) => {
        let query = {
            on_page: 1,
            per_page: 15,
            order_by: {
                created_at: 'desc'
            },
            filter_by: {
                type: '4,5,6,8,10,11,14,15'
            },
            query: [

            ],
            search: null
        };
        return isRequestSearch ? query : qs.stringify(query)
    },

    Locations: (isRequestSearch = false) => {
        let query = {
            on_page: 1,
            per_page: 15,
            order_by: {
                location: 'asc'
            },
            filter_by: {
                type: 'P,S'
            },
            query: [

            ],
            search: null
        };
        return isRequestSearch ? query : qs.stringify(query)
    },

    serials: (isRequestSearch = false) => {
        let query = {
            on_page: 1,
            per_page: 15,
            order_by: {
                created_at: 'desc'
            },
            filter_by: {

            },
            query: [

            ],
            search: null
        };
        return isRequestSearch ? query : qs.stringify(query)
    },

    licenses: (isRequestSearch = false) => {
        let query = {
            on_page: 1,
            per_page: 15,
            order_by: {
                license: 'desc'
            },
            filter_by: {

            },
            query: [

            ],
            search: null
        };
        return isRequestSearch ? query : qs.stringify(query)
    },

    billing: {
        items: (isRequestSearch = false) => {
            let query = {
                on_page: 1,
                per_page: 15,
                order_by: {
                    name: 'asc'
                },
                filter_by: {
                    is_active: '1'
                },
                query: [

                ],
                search: null
            };
            return isRequestSearch ? query : qs.stringify(query)
        },

        activities: (isRequestSearch = false) => {
            let query = {
                on_page: 1,
                per_page: 15,
                order_by: {
                    name: 'asc'
                },
                filter_by: {
                    is_active: '1',
                    charge: 'Manual,Inbound,Outbound,Count,Month-Begin,Month-End,Week-Beg,Week-End,Daily-Cubic,Daily-Location,Shipping-Charge'
                },
                query: [

                ],
                search: null
            };
            return isRequestSearch ? query : qs.stringify(query)
        },

        entries: (isRequestSearch = false) => {
            let query = {
                on_page: 1,
                per_page: 15,
                order_by: {
                    bill_date: 'desc'
                },
                filter_by: {

                },
                query: [

                ],
                search: null
            };
            return isRequestSearch ? query : qs.stringify(query)
        },

        newEntryQuery: (type, clientId, activityId, backTo, reference = null, billableId = null, billableType = null) => {
            return qs.stringify({
                type, clientId, activityId, backTo,reference, billableId, billableType
            })
        }
    }
}

export default LaraPagination;
