import React, { Component } from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import {
    bootGuest
} from '../actions/auth';
class GuestLayout extends Component {
    render() {
        const {authenticated, component: Component, preference, bootGuest, ...rest} = this.props
        return (
            <Route {...rest} render={props => !authenticated ? 
                <Component {...props} preference={preference} bootGuest={bootGuest} />
            : <Redirect to='/' />} />
        )
    }
}

GuestLayout.propTypes = {
    authenticated: PropTypes.bool.isRequired,
    component: PropTypes.func.isRequired,
    bootGuest: PropTypes.func.isRequired,
    preference: PropTypes.object.isRequired,
    history: PropTypes.shape({
       push: PropTypes.func.isRequired
    }).isRequired,
};


function mapStateToProps(state) {
    return {
        preference: state.system.preference
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        bootGuest
    }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GuestLayout));
