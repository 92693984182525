import React from 'react';
import PropTypes from 'prop-types';
import {Icon} from 'semantic-ui-react';
import {withI18n} from 'react-i18next';

const ResourceBooleanPropertyView = ({t, label, value}) => (
    <div className="lara-text-wrapper">
        <span className='lara-text-label'>{t(label)}:</span>
        <span className='lara-text-value'>{
            value ? <Icon name="check" color='green' /> : <Icon name="times" />
        }</span>
    </div>
)

ResourceBooleanPropertyView.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.any
};


export default withI18n()(ResourceBooleanPropertyView);