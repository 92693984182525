import update from 'immutability-helper';
import {
    SET_SERIAL_MODEL_FROM_SERVER,
    SERIAL_UPDATED_BY_FORM,
    ADD_SINGLE_NUMBER_TO_SERIAL,
    UPDATE_SINGLE_NUMBER_TO_SERIAL,
    DELETE_SINGLE_NUMBER_TO_SERIAL,
    ADD_MANY_NUMBER_TO_SERIAL
} from '../../actions/types';

export default function serialModel(state = {
    id: null,
    inventory: {},
    serials: []
}, action = {}) {
    switch(action.type) {
        case SET_SERIAL_MODEL_FROM_SERVER:
            return action.payload;

        case SERIAL_UPDATED_BY_FORM:
            return update(state, {
                [action.payload.field]: {$set: action.payload.value}
            });

        case ADD_SINGLE_NUMBER_TO_SERIAL:
            return update(state, {
                serials: {$push: [action.payload]}
            });

        case ADD_MANY_NUMBER_TO_SERIAL:
            return update(state, {
                serials: {$push: action.payload}
            });

        case UPDATE_SINGLE_NUMBER_TO_SERIAL: 
            return update(state, {
                serials: {
                    [action.payload.index]: {$set: action.payload.serial}
                }
            });

        case DELETE_SINGLE_NUMBER_TO_SERIAL:
            return update(state, {
                serials: {$splice: [[action.payload, 1]]}
            });

        default: return state;
    }
}