import {api} from '../api';
import {
    SET_USER_PAGINATED_LIST,
    SET_ROLE_LIST,
    NEW_ROLE_CREATED,
    EXISTING_ROLE_UPDATED,
    SET_USER_MODEL_FROM_SERVER,
    USER_MODEL_UPDATED_BY_FORM,
    MY_PROFILE_UPDATED,
    API_TOKEN_REQUESTED
} from './types';

export const fetchPaginatedList = (path) => (dispatch) => {
    return api.users.paginated(path).then(res => {
        dispatch({
            type: SET_USER_PAGINATED_LIST,
            payload: res.data.data
        });
        return res;
    }).catch(err => {throw err});
}

export const fetchRoleList = () => (dispatch) => {
    return api.users.roleList().then(res => {
        dispatch({
            type: SET_ROLE_LIST,
            payload: res.data.data
        });
        return res;
    }).catch(err => {throw err});
}

export const fetchById = (id) => (dispatch) => {
    return api.users.read(id).then(res => {
        dispatch({
            type: SET_USER_MODEL_FROM_SERVER,
            payload: res.data.data
        })
        return res.data.data;
    }).catch(err => {throw err});
}

export const updateModelInStore = (field, value) => {
    return {
        type: USER_MODEL_UPDATED_BY_FORM,
        payload: {
            field, value
        }
    }
}

export const createNewModelInStore = (model) => {
    return {
        type: SET_USER_MODEL_FROM_SERVER,
        payload: model
    }
}

export const updateModelOnServer = (model) => (dispatch) => {
    if (model.id) {
        return api.users.update(model.id, model).then(res => {
            return model;
        }).catch(err => {throw err});
    } else {
        return api.users.create(model).then(res => {
            return res.data.data
        }).catch(err => {throw err});
    }
}


export const saveRole = (model) => (dispatch) => {
    if (model.id) {
        return api.users.roleUpdate(model.id, model).then(res => {
            dispatch({
                type: EXISTING_ROLE_UPDATED,
                payload: {
                    key: model.id,
                    model: model
                }
            })
            return res;
        }).catch(e => {throw e});
    } else {
        return api.users.roleCreate(model).then(res => {
            const {data} = res.data;
            dispatch({
                type: NEW_ROLE_CREATED,
                payload: data
            });
            return res
        }).catch(e => {throw e});
    }
}

export const updateMyProfile = (model) => (dispatch) => {
    return api.system.profile(model).then(res => {
        dispatch({
            type: MY_PROFILE_UPDATED,
            payload: model
        })
        return res;
    }).catch(e => {throw e});
}

export const getApiToken = () => (dispatch) => {
    return api.system.apiToken().then(res => {
        dispatch({
            type: API_TOKEN_REQUESTED,
            payload: ""
        })
        return res;
    }).catch(e => {throw e});
}