import _ from 'lodash';
import {api} from '../api';
import {
    SET_CLIENT_PAGINATED_LIST,
    NEW_CLIENT_CREATED_TO_SELECTOR,
    SET_CLIENT_MODEL_FROM_SERVER,
    CLIENT_MODEL_UPDATED_BY_FORM,
    CLIENT_CATEGORY_DELETED,
    ADD_EMAIL_TO_CLIENT_DETAIL,
    REMOVE_EMAIL_FROM_CLIENT_DETAIL,
    WEBHOOK_ADDED_TO_CLIENT,
    WEBHOOK_MODIFIED_OF_CLIENT,
    WEBHOOK_DELETED_FROM_CLIENT
} from './types';
import Lara from '../lara';

export const saveWebhook = (clientId, model, hookIndex) => (dispatch) => {
    if (model.id) {
        return api.clients.updateWebhook(clientId, model.id, model).then(res => {
            dispatch({
                type: WEBHOOK_MODIFIED_OF_CLIENT,
                payload: {
                    hookIndex: hookIndex,
                    data: model
                }
            });
            return res;
        });
    } else {
        return api.clients.createWebhook(clientId, model).then(res => {
            dispatch({
                type: WEBHOOK_ADDED_TO_CLIENT,
                payload: res.data.data
            });
            return res.data.data;
        })
    }
}

export const deleteWebhook = (clientId, hookId, hookIndex) => (dispatch) => {
    return api.clients.deleteWEbhook(clientId, hookId).then(res => {
        dispatch({
            type: WEBHOOK_DELETED_FROM_CLIENT,
            payload: {hookIndex}
        });
        return res;
    })
}

export const fetchPaginatedList = (path) => (dispatch) => {
    return api.clients.paginated(path).then(res => {
        dispatch({
            type: SET_CLIENT_PAGINATED_LIST,
            payload: res.data.data
        });
        return res;
    }).catch(err => {throw err});
}

export const fetchById = (id) => (dispatch) => {
    return api.clients.read(id).then(res => {
        dispatch({
            type: SET_CLIENT_MODEL_FROM_SERVER,
            payload: res.data.data
        })
        const {data} = res.data;
        if (!_.isArray(data.detail.shipping_notification_emails)) {
            data.detail.shipping_notification_emails = [];
        }
        if (!_.isArray(data.detail.receiving_notification_emails)) {
            data.detail.receiving_notification_emails = [];
        }
        return res.data.data;
    }).catch(err => {throw err});
}

export const updateModelInStore = (field, value, subset = null) => {
    return {
        type: CLIENT_MODEL_UPDATED_BY_FORM,
        payload: {
            field, value, subset
        }
    }
}

export const createNewModelInStore = () => {
    return {
        type: SET_CLIENT_MODEL_FROM_SERVER,
        payload: Lara.models.client()
    }
}

export const addEmailToClientDetail = (email, subset) => {
    return {
        type: ADD_EMAIL_TO_CLIENT_DETAIL,
        payload: {
            email, subset
        }
    }
}

export const removeEmailFromClientDetail = (index, subset) => {
    return {
        type: REMOVE_EMAIL_FROM_CLIENT_DETAIL,
        payload: {
            index, subset
        }
    }
}

export const updateModelOnServer = (model) => (dispatch) => {
    if (model.id) {
        return api.clients.update(model.id, model).then(res => {
            return model.id;
        }).catch(err => {throw err});
    } else {
        return api.clients.create(model).then(res => {
            dispatch({
                type: NEW_CLIENT_CREATED_TO_SELECTOR,
                payload: {
                    id: res.data.data.id,
                    company: res.data.data.company,
                    is_active: true
                }
            });
            return res.data.data.id
        }).catch(err => {
            throw err
        });
    }
}

export const deleteCategory = (category, index) => (dispatch) => {
    const {id} = category;
    return api.categoryAndTag.delete(id).then(res => {
        dispatch({
            type: CLIENT_CATEGORY_DELETED,
            payload: index
        });
        return res;
    }).catch(err => {throw err});
}