const Codes = {
    //Code 10000: Server time out
    10000: 'https://y3pl.zendesk.com',
    //Code 10001: This resource does not belong to the client you specified
    10001: 'https://y3pl.zendesk.com',
    //Code 10002: Must sku count existed, Inventory.php - method isDuplicatedSku
    10002: 'https://y3pl.zendesk.com',
    10003: 'https://y3pl.zendesk.com',
    10004: 'https://y3pl.zendesk.com',
    10005: '',
    10006: 'https://y3pl.zendesk.com',

    //Update an user with an already existing email address
    20006: 'https://y3pl.zendesk.com',
    //Create an user with invalid client ID
    20007: 'https://y3pl.zendesk.com',
    //Create a main user with invalid role ID
    20008: '',

    //Update a role with an existing name
    21001: 'https://y3pl.zendesk.com',

    //Order Model
    //Duplicated SKU found in order line array when creating order
    30001: '',
    //Order lines are invalid: each line item should be contained in an array
    30003: '',
    //Order lines contain an invalid SKU, item not found
    30002: '',

    //Code 90001:
    /*
        if (!property_exists($this,'defaultParams'))
        {
            throw new BadHttpRequestException('Please contact support for help. This error is unexpected',)
        }
     */
    90001: 'https://y3pl.zendesk.com'
};

module.exports = Codes;
