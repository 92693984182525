import React from 'react';
import {List, Label} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import moment from 'moment';

class ShipmentCommentList extends React.PureComponent {
    
    render() {
        const {comments, t, orderNote} = this.props;
        return (
            <List divided relaxed>
                {orderNote ? <List.Item>
                    <List.Icon name='comment alternate' size='large' verticalAlign='middle' />
                    <List.Content>
                        <List.Header>Order Note</List.Header>
                        <List.Description>
                            {orderNote}
                        </List.Description>
                    </List.Content>
                </List.Item> : null}
                {comments.map((comment) => {
                    return (
                        <List.Item key={comment.id}>
                            <List.Icon name='comment outline' size='large' verticalAlign='middle' />
                            <List.Content>
                                <List.Header>{comment.user.name} @ {moment(comment.created_at).format('MMM Do hh:mm a')}</List.Header>
                                <List.Description>
                                    {comment.comment} <br />
                                    {comment.internal ? <Label size='mini'>{t('InternalNote')}</Label> : null}
                                </List.Description>
                            </List.Content>
                        </List.Item>
                    )
                })}
            </List>
        )
    }
}

ShipmentCommentList.propTypes = {
    comments: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired,
    orderNote: PropTypes.any
};

export default ShipmentCommentList