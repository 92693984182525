import React, { Component } from 'react';
import _ from 'lodash';
import { bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import {Modal, Button, Checkbox, Header, Table, Icon, Label, Message} from 'semantic-ui-react';
import {api} from '../../../api';
import PrimaryButton from '../../includes/buttons/PrimaryButton';
import PropTypes from 'prop-types';
import Lara from '../../../lara';

class BatchProcessShipment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            loading: false,
            doAutoBillingOnServer: false,
            doEmailAlertOnServer: true,
            result: {},
            error: {}
        }
    }

    confirmBatchClose = (selectedRecords) => {
        const selected = [];
        _.each(selectedRecords, r => {
            selected.push(r.id);
        });
        if (_.isEmpty(selected)) {
            return null;
        }
        this.setState({
            loading: true
        }, () => {
            api.shipments.batchFinish(selected, {bill: this.state.doAutoBillingOnServer, email: this.state.doEmailAlertOnServer}).then(res => {
                this.setState({
                    loading: false,
                    result: res.data.data
                });
            }).catch(err => {
                const errors = Lara.axiosError(err);
                this.setState({
                    error: errors,
                    loading: false
                });
            })
        })
    }

    getResult = (id) => {
        if (this.state.result.hasOwnProperty(id)) {
            if (this.state.result[id].result) {
                return <Label size='mini' color='green'>Success</Label>
            } else {
                return <div>
                    <Label size='mini' color='red'>Failed</Label> <br />
                    <span>{this.state.result[id].message}</span>
                </div>
            }
        }
    }
    
    render() {
        const {modal, loading} = this.state;
        const {records} = this.props.list;
        let hasShipStation = 0;
        const selected = _.filter(records, function (o) {
            return o.action && o.status === 'Ready'
        });

        _.each(selected, o => {
            if (o.ss_order_id) {
                hasShipStation = hasShipStation + 1;
            }
        })

        return (
            <div>
                <PrimaryButton 
                    size='mini'
                    float='right'
                    label="Batch Finish"
                    onClick={() => {this.setState({
                        modal: true,
                        loading: false,
                        doAutoBillingOnServer: false,
                        doEmailAlertOnServer: true,
                        result: {},
                        error: {}
                    })}}
                    loading={false}
                />
                <Modal
                    closeOnDimmerClick={false}
                    closeIcon
                    onClose={() => {
                        this.setState({
                            modal: false,
                            loading: false
                        }, () => {
                            this.props.onProcessCompleted()
                        })
                    }}
                    size='small'
                    closeOnEscape={true} open={modal}>
                    <Modal.Content>
                        <div className="row">
                            <div className="col-md-12">
                                <Header as='h3'>Mark the following as "Finished" ?</Header>
                                {!_.isEmpty(this.state.error) && _.isObject(this.state.error) ? <Message error>
                                    <Message.Header>{this.state.error.message}</Message.Header>
                                    <p>
                                        {this.state.error.reference}
                                    </p>
                                </Message> : null}
                                <Checkbox 
                                    checked={this.state.doAutoBillingOnServer}
                                    onChange={(e, {checked}) => {
                                        this.setState({
                                            doAutoBillingOnServer: checked
                                        })
                                    }}
                                    label='Do auto billing when finishing shipments (if set up correctly)' />
                                <br />
                                <Checkbox 
                                    checked={this.state.doEmailAlertOnServer}
                                    onChange={(e, {checked}) => {
                                        this.setState({
                                            doEmailAlertOnServer: checked
                                        })
                                    }}
                                    label='Do send notification emails (if set up correctly)' />
                                <Table compact>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>ID</Table.HeaderCell>
                                            <Table.HeaderCell>Reference</Table.HeaderCell>
                                            <Table.HeaderCell>Client</Table.HeaderCell>
                                            <Table.HeaderCell>Status</Table.HeaderCell>
                                            <Table.HeaderCell>Result</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {selected.map(i => {
                                            const companyName = i.order ? i.order.client ? i.order.client.company : "" : "";
                                            return <Table.Row key={i.id}>
                                                <Table.Cell>{i.id}</Table.Cell>
                                                <Table.Cell>
                                                    {i.fba_shipment_id && !i.exist_in_fba ? <Icon name='amazon' color='red' /> : null}
                                                    {i.fba_shipment_id && i.exist_in_fba ? <Icon name='amazon' color='green' /> : null}
                                                    {i.fba_shipment_id ? i.fba_shipment_id : i.order ? i.order.reference : 'NA'}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {companyName.length > 12 ? companyName.substring(0,12)+"..." : companyName}
                                                </Table.Cell>
                                                <Table.Cell>{i.status}</Table.Cell>
                                                <Table.Cell>{this.getResult(i.id)}</Table.Cell>
                                            </Table.Row>
                                        })}
                                    </Table.Body>
                                </Table>
                                {hasShipStation > 0 ? <Message info>
                                    <Message.Header>ShipStation Integration Detected</Message.Header>
                                    <p>
                                        The system will load {hasShipStation} orders from ShipStation account. Loading from ShipStation may take a long time, please limit the number of orders to be synchronized with ShipStation.
                                    </p>
                                </Message> : null}
                            </div>
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <div className="row">
                            <div className="col-md-8 col-sm-8 col-xs-8">
                                
                            </div>
                            <div className="col-md-4 col-sm-4 col-xs-4">
                                <Button
                                    positive
                                    icon='checkmark'
                                    labelPosition='right'
                                    content='Yes'
                                    onClick={() => {
                                        this.confirmBatchClose(selected)
                                    }}
                                    loading={loading}
                                    disabled={loading}
                                />
                            </div>
                        </div>
                    </Modal.Actions>
                </Modal>
            </div>
        )
    }
}

BatchProcessShipment.propTypes = {
    list: PropTypes.shape({
        records: PropTypes.array.isRequired
    }).isRequired,
    onProcessCompleted: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        list: state.shipmentList.list
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(BatchProcessShipment)