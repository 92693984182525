import React from 'react';
import PropTypes from 'prop-types';
import {Table, Icon, Loader} from 'semantic-ui-react';
import _ from 'lodash';
import moment from 'moment';
import {api} from "../../../api";
class LicenseTableRow extends React.Component {
    constructor() {
        super();
        this.state = {
            loading: false
        }
    }
    getUom = (uomId) => {
        const {uoms} = this.props;
        const i = _.findIndex(uoms, u => {
            return parseInt(u.id,10) === parseInt(uomId,10);
        });
        return i > -1 ? uoms[i].name : "NA"
    }

    getClient = (clientId) => {
        const {clients} = this.props;
        const i = _.findIndex(clients, u => {
            return parseInt(u.id,10) === parseInt(clientId,10);
        });
        return i > -1 ? clients[i].company : "NA"
    }

    getBin = () => {
        const {locations} = this.props.item;
        const locIndex = _.findIndex(locations, l => {
            return l.pivot.in_location === 1;
        })
        return locIndex > -1 ? locations[locIndex].location : "NA"
    }

    onRetrieveContentClick = () => {
        const {item, onItemsInLicenseLoaded} = this.props;
        this.setState({
            loading: true
        }, () => {
            api.licenses.inventoryList(item.id).then(res => {
                onItemsInLicenseLoaded(res.data.data);
                this.setState({
                    loading: false
                })
            }).catch(err => {
                console.log(err);
                this.setState({
                    loading: false
                })
            })
        })
    }

    render() {
        const {item, onEdit, onMove, onBreak} = this.props;
        return (
            <Table.Row>
                <Table.Cell>
                    <span className="lara-clickable lara-editable" onClick={() => {
                        onEdit();
                    }}>
                        {item.license}
                    </span>
                </Table.Cell>
                <Table.Cell>
                    {this.getClient(item.client_id)}
                </Table.Cell>
                <Table.Cell>
                <span className="lara-clickable lara-editable" onClick={() => {
                        onBreak();
                }}><Icon name="clone outline" /></span>
                    {this.getUom(item.uom_id)}
                </Table.Cell>
                <Table.Cell>
                    {`${item.length} X ${item.width} X ${item.height}`}
                </Table.Cell>
                <Table.Cell>
                    {item.weight}
                </Table.Cell>
                <Table.Cell>
                    <span className="lara-clickable lara-editable" onClick={() => {
                        onMove();
                    }}>
                        {this.getBin()}
                    </span>
                </Table.Cell>
                <Table.Cell>
                    {moment(item.created_at).format('MMM DD, Y').toString()}
                </Table.Cell>
                <Table.Cell>
                    {this.state.loading ? <Loader active inline size='mini'/> : <span className="lara-clickable lara-editable" onClick={() => {
                        this.onRetrieveContentClick();
                    }}><Icon name="list" /></span>}
                </Table.Cell>
            </Table.Row>
        )
    }
}

LicenseTableRow.propTypes = {
    item: PropTypes.shape({
        locations: PropTypes.array.isRequired
    }).isRequired,
    onEdit: PropTypes.func.isRequired,
    onMove: PropTypes.func.isRequired,
    onBreak: PropTypes.func.isRequired,
    uoms: PropTypes.array.isRequired,
    clients: PropTypes.array.isRequired,
    onItemsInLicenseLoaded: PropTypes.func.isRequired
};

export default LicenseTableRow
