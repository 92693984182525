import React, { Component } from 'react';
import { bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import {Form} from 'semantic-ui-react';
import PropTypes from 'prop-types';

class CourierPaymentSelector extends Component {
    //access router
    //this.props.history.push('/dashboard')
    render() {
        const {label, selected, onSelectChange, t} = this.props;
        const options = [
            {value: 'prepaid', text: t('Prepaid')},
            {value: 'collect', text: t('Collect')},
            {value: 'third', text: t('Third')}
        ];
        return (
            <Form.Select fluid search selection 
                label={label}
                options={options}
                name="default_shipping_payment" value={selected ? selected : "prepaid"} 
                onChange={(e, {value}) => {
                    onSelectChange(value)
                }}
            />
        )
    }
}

CourierPaymentSelector.propTypes = {
    t: PropTypes.func.isRequired,
    onSelectChange: PropTypes.func.isRequired,
    label: PropTypes.string
};

function mapStateToProps(state) {
    return {
        
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(CourierPaymentSelector)