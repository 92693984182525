import React, { Component } from 'react';
import { bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import {withI18n} from 'react-i18next';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import _ from 'lodash';
import {Segment, Modal, Table, Icon, Message, Header} from 'semantic-ui-react';
import SegmentRibbon from '../../includes/partials/SegmentRibbon';
import AlertComponent from '../../includes/messages/alert';
import {
    saveCourierToServer,
    loadShipStation,
    loadShippo
} from '../../../actions/setting';
import Lara from '../../../lara';
import CourierForm from '../forms/CourierForm';
import PrimaryButton from '../../includes/buttons/PrimaryButton';

const ModelName = 'Courier';

class CourierComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            index: null,
            errors: [],
            model: {

            },
            modalOpen: false,
            loading: false
        }
    }
    componentDidMount() {
        const {shipStation, shippo} = this.props;
        if (_.isEmpty(shipStation.couriers)) {
            this.props.loadShipStation();
        }
        if (_.isEmpty(shippo)) {
            this.props.loadShippo();
        }
    }
    //access router
    //this.props.history.push('/dashboard')
    setModelThenOpenModal = (index, model) => {
        this.setState({
            index: index,
            model: model,
            modalOpen: true
        });
    }

    getCurrentSelectedClient = (name = null) => {
        const {clients, user, t} = this.props;
        const clientIndex = _.findIndex(clients, c => {
            return parseInt(c.id,10) === parseInt(user.last_working_client);
        });
        
        if (clientIndex > -1) {
            if (name) {
                return `${t('Courier')} (${clients[clientIndex].company})`
            } else {
                return [
                    {
                        value: '0',
                        text: 'Our Own',
                        index: -1
                    },
                    {
                        value: _.toString(clients[clientIndex].id),
                        text: clients[clientIndex].company,
                        index: _.toString(clients[clientIndex].id)
                    }
                ]
            }
        }
        if (name) {
            return t("Courier");
        } else {
            return [
                {
                    value: '0',
                    text: 'Our Own',
                    index: -1
                }
            ]
        }
    }
    
    onSaveSubmit = () => {
        const errors = this.validateInput();
        if (_.isEmpty(errors)) {
            this.setState({
                loading: true
            });
            this.props.saveCourierToServer(this.state.index, this.state.model).then(res => {
                this.setState({
                    errors: [],
                    loading: false,
                    modalOpen: false,
                    index: null,
                    model: {}
                });
            }).catch(err => {
                const errors = Lara.axiosError(err, this.props.t);
                if (errors.http_code === 422) {
                    this.setState({
                        errors: errors.message,
                        loading: false
                    });
                } else {
                    this.setState({
                        loading: false
                    });
                    Lara.axiosAlert(<AlertComponent 
                        support={errors.support}
                        message={errors.message}
                        reference={errors.reference}
                        t={this.props.t}
                    />);
                }
            });
        } else {
            this.setState({
                errors: errors
            });
        }
    }

    validateInput = () => {
        const errors = [];
        const {name} = this.state.model;
        if (!name) {
            errors.push(this.props.t('Courier name is required'));
        }
        return errors;
    }

    onModelFieldChange = (field, value) => {
        this.setState({
            model: update(this.state.model, {
                [field]: {$set: value}
            })
        })
    }

    render() {
        const {t, couriers} = this.props;
        return (
            <div>
                <Segment>
                    <SegmentRibbon label={this.getCurrentSelectedClient(true)} Buttons={
                        () => {return (<React.Fragment>
                            <PrimaryButton
                                size='mini'
                                float='right'
                                label={t('Create')}
                                onClick={() => {this.setModelThenOpenModal(null, Lara.models.setting(ModelName))}}
                                loading={false}
                            />
                        </React.Fragment>)}
                    }/>
                    <Message>
                        <Message.Header>{t("Courier")}</Message.Header>
                        <p>
                        {t('MessageCourierPage')}
                        </p>
                    </Message>
                    <Table
                        compact
                        basic
                        padded={false}
                    >
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>
                                    {t("Courier")}
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    {t("Client")}
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    {t("CourierPaymentMethod")}
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    ShipStation
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    Shippo
                                </Table.HeaderCell>
                                {this.props.server.has_flex_integration_bool ? <Table.HeaderCell>Flex</Table.HeaderCell> : null}
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {couriers.map((c,index) => {
                                return (<Table.Row key={c.id}>
                                    <Table.Cell>
                                        <span className="lara-clickable lara-editable" onClick={() => {
                                            this.setModelThenOpenModal(index, c);
                                        }}>
                                            {c.name}
                                        </span>
                                    </Table.Cell>
                                    <Table.Cell>
                                        {c.client_id > 0 ? <Icon name='checkmark' color='teal'/> : <Icon name='close' color='teal'/>}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {t(_.upperFirst(c.payment_method))}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {c.ss_mapping_carrier ? <Icon name='checkmark' color='teal'/> : null}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {c.shippo_id ? <Icon name='checkmark' color='teal'/> : null}
                                    </Table.Cell>
                                    {this.props.server.has_flex_integration_bool ? <Table.Cell>
                                        {c.flex_service}
                                    </Table.Cell> : null}
                                </Table.Row>)
                            })}
                        </Table.Body>
                    </Table>
                </Segment>
                <Modal size="small" 
                    closeOnDimmerClick={false}
                    closeIcon
                    onClose={() => {this.setState({modalOpen: false})}}
                    closeOnEscape={true} open={this.state.modalOpen}>
                    <Header icon='disk' content='Courier' />
                    <Modal.Content>
                        {_.isEmpty(this.state.model) ? null : <CourierForm 
                            errors={this.state.errors}
                            t={t}
                            model={this.state.model}
                            onFormValueChange={(field, value) => {
                                this.onModelFieldChange(field, value);
                            }}
                            shipStation={this.props.shipStation}
                            shippo={this.props.shippo}
                            server={this.props.server}
                            fb={this.props.fb}
                            clients={this.getCurrentSelectedClient(null)}
                        />}
                    </Modal.Content>
                    <Modal.Actions>
                        <div className="row">
                            <div className="col-md-12">
                                <PrimaryButton
                                    size='mini'
                                    float='right'
                                    label={t('Save')}
                                    onClick={() => {this.onSaveSubmit()}}
                                    loading={this.state.loading}
                                />
                            </div>
                        </div>
                    </Modal.Actions>
                </Modal>
            </div>
        )
    }
}

CourierComponent.propTypes = {
    //history: PropTypes.shape({
    //    push: PropTypes.func.isRequired
    //}).isRequired,
    saveCourierToServer: PropTypes.func.isRequired,
    couriers: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired,
    clients: PropTypes.array.isRequired,
    user: PropTypes.object.isRequired,
    shipStation: PropTypes.shape({
        couriers: PropTypes.array.isRequired,
        packages: PropTypes.any,
        services: PropTypes.any
    }),
    shippo: PropTypes.array.isRequired,
    server: PropTypes.object.isRequired,
    fb: PropTypes.object.isRequired
};

function mapStateToProps(state) {
    return {
        couriers: state.system.resources.couriers,
        clients: state.system.clients,
        user: state.system.user,
        shipStation: state.system.ship_station.shipping,
        shippo: state.system.shippo,
        server: state.system.server_pref,
        fb: state.system.fb
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        saveCourierToServer,
        loadShipStation,
        loadShippo
    }, dispatch)
}

export default withI18n()(connect(mapStateToProps, mapDispatchToProps)(CourierComponent));