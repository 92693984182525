import React, { Component } from 'react';
import { bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {withI18n} from 'react-i18next';
import update from 'immutability-helper';
import {
    Segment, Image, Dimmer, Loader, Header, Modal
} from 'semantic-ui-react';
import PageBreadCrub from '../includes/partials/PageBreadCrub';
import SegmentRibbon from '../includes/partials/SegmentRibbon';
import ResourceTextPropertyView from '../includes/partials/ResourceTextPropertyView';
import ResourceBooleanView from '../includes/partials/ResourceBooleanPropertyView';
import ApiCredentialForm from './forms/ApiCredentialForm';
import AlertComponent from '../includes/messages/alert';
import {api} from '../../api';
import Lara from '../../lara';
import PrimaryNaviButton from '../includes/buttons/PrimaryNaviButton';
import PrimaryButton from '../includes/buttons/PrimaryButton';
import DeleteButton from '../includes/buttons/DeleteButton';

class CompanySettingViewPage extends Component {
    constructor() {
        super();
        this.state = {
            breadcrub: [
                {isLink:false, to:null, tag: 'Setting', active: false, key: 1},
                {isLink:false, to:null, tag: 'Company', active: true, key:2}
            ],
            shippingSystems: {
                shipstation: null,
                shippo: null
            },
            shippingPanelLoading: false,
            showModal: false,
            apiModel: {
                
            },
            loading: false
        }
    }

    componentDidMount() {
        this.setState({
            shippingPanelLoading: true
        });
        api.preferences.loadShippingSystems().then(res => {
            this.setState({
                shippingSystems: res.data.data,
                shippingPanelLoading: false
            });
        }).catch(err => {
            this.setState({
                shippingPanelLoading: false
            })
        })
    }

    onOpenCredentialFormModalClick = (type) => {
        this.setState({
            apiModel: Lara.models.apiCredential(type),
            showModal: true
        });
    }

    onDeleteSubmit = () => {
        const {type} = this.state.apiModel;
        this.setState({
            loading: true
        });
        if (type === 1) {
            api.preferences.deleteCompanyShipStation().then(res => {
                this.setState({
                    shippingSystems: update(this.state.shippingSystems, {
                        shipstation: {$set: null}
                    }),
                    loading: false,
                    showModal: false
                })
            }).catch(err => {
                const errors = Lara.axiosError(err, this.props.t);
                this.setState({
                    loading: false
                });
                Lara.axiosAlert(<AlertComponent 
                    support={errors.support}
                    message={errors.message}
                    reference={errors.reference}
                    t={this.props.t}
                />);
            });
        }

        if (type === 13) {
            api.preferences.deleteCompanyShippo().then(res => {
                this.setState({
                    shippingSystems: update(this.state.shippingSystems, {
                        shippo: {$set: null}
                    }),
                    loading: false,
                    showModal: false
                })
            }).catch(err => {
                const errors = Lara.axiosError(err, this.props.t);
                this.setState({
                    loading: false
                });
                Lara.axiosAlert(<AlertComponent 
                    support={errors.support}
                    message={errors.message}
                    reference={errors.reference}
                    t={this.props.t}
                />);
            });
        }
    }

    cacheShipStation = () => {
        this.setState({
            loading: true
        });
        api.preferences.recacheCompanyShipStation().then(res => {
            this.setState({
                loading: false
            });
        }).catch(err => {
            const errors = Lara.axiosError(err, this.props.t);
            this.setState({
                loading: false
            });
            Lara.axiosAlert(<AlertComponent 
                support={errors.support}
                message={errors.message}
                reference={errors.reference}
                t={this.props.t}
            />);
        });
    }

    cacheShippo = () => {
        this.setState({
            loading: true
        });
        api.preferences.recacheCompanyShippo().then(res => {
            this.setState({
                loading: false
            });
        }).catch(err => {
            const errors = Lara.axiosError(err, this.props.t);
            this.setState({
                loading: false
            });
            Lara.axiosAlert(<AlertComponent 
                support={errors.support}
                message={errors.message}
                reference={errors.reference}
                t={this.props.t}
            />);
        });
    }

    onSaveSubmit = () => {
        const {type, key_1, key_2} = this.state.apiModel;
        this.setState({
            loading: true
        });
        if (type === 1) {
            if (!key_1 || !key_2) {
                return null;
            }
            api.preferences.createCompanyShipStation({key_1, key_2}).then(res => {
                this.setState({
                    shippingSystems: update(this.state.shippingSystems, {
                        shipstation: {$set: {
                            type: 1,
                            key_1: key_1
                        }}
                    }),
                    loading: false,
                    showModal: false
                })
            }).catch(err => {
                const errors = Lara.axiosError(err, this.props.t);
                this.setState({
                    loading: false
                });
                Lara.axiosAlert(<AlertComponent 
                    support={errors.support}
                    message={errors.message}
                    reference={errors.reference}
                    t={this.props.t}
                />);
            });
        }

        if (type === 13) {
            if (!key_1) {
                return null;
            }
            api.preferences.createCompanyShippo({key_1}).then(res => {
                this.setState({
                    shippingSystems: update(this.state.shippingSystems, {
                        shippo: {$set: {
                            type: 13,
                            key_1: key_1
                        }}
                    }),
                    loading: false,
                    showModal: false
                })
            }).catch(err => {
                const errors = Lara.axiosError(err, this.props.t);
                this.setState({
                    loading: false
                });
                Lara.axiosAlert(<AlertComponent 
                    support={errors.support}
                    message={errors.message}
                    reference={errors.reference}
                    t={this.props.t}
                />);
            });
        }
    }

    onFormValueChange = (field, value) => {
        this.setState({
            apiModel: update(this.state.apiModel, {
                [field]: {$set: value}
            })
        })
    }

    render() {
        const {t, model} = this.props;
        const {shipstation, shippo} = this.state.shippingSystems;
        return (
            <div>
                {/* Breadcrub Row */}
                <div className="row">
                    <div className="col-md-12 lara-breadcrub">
                        <PageBreadCrub lists={this.state.breadcrub} t={this.props.t}/>
                    </div>
                </div>
                {/* Main content row */}
                <div className="row">
                    <div className="col-md-12">
                        <Segment>
                            <SegmentRibbon label="My Company Settings" Buttons={
                                () => {return (<React.Fragment>
                                    <PrimaryNaviButton
                                        link='/company/edit'
                                        size='mini'
                                        float='right'
                                        loading={false}
                                        label={t('Edit')}
                                    />
                                </React.Fragment>)}
                            }/>
                            <div className="row">
                                <div className="col-md-3 col-sm-6 col-xs-12">
                                    <Image src={model.logo} size='medium' />
                                </div>
                                <div className="col-md-3 col-sm-6 col-xs-12">
                                    <ResourceTextPropertyView
                                        label="CompanyName"
                                        value={model.name} />
                                    <ResourceTextPropertyView
                                        label="Address"
                                        value={model.address} />
                                    <ResourceTextPropertyView
                                        label="City"
                                        value={model.city} />
                                    <ResourceTextPropertyView
                                        label="Postal"
                                        value={model.postal} />
                                    <ResourceTextPropertyView
                                        label="State"
                                        value={model.state} />
                                    <ResourceTextPropertyView
                                        label="Country"
                                        value={model.country} />
                                    <ResourceTextPropertyView
                                        label="Phone"
                                        value={model.phone} />   
                                </div>
                                <div className="col-md-3 col-sm-6 col-xs-12">
                                    <ResourceBooleanView 
                                        label="Enable Quick Fulfill without License"
                                        value={model.quick_fulfill ? true : false}
                                    />
                                    <ResourceTextPropertyView
                                        label="Measure"
                                        value={model.measure} />  
                                    <ResourceTextPropertyView
                                        label="SSPrefix"
                                        value={model.ss_prefix} />
                                    <ResourceTextPropertyView
                                        label="HomeCountryCode"
                                        value={model.home_country_code} />
                                </div>
                                <div className="col-md-3 col-sm-6 col-xs-12">
                                    <ResourceTextPropertyView
                                        label="BrandingName"
                                        value={model.branding_name} />
                                    <ResourceTextPropertyView
                                        label="BrandingSlogan"
                                        value={model.branding} />
                                    <ResourceTextPropertyView
                                        label="Website"
                                        value={model.home_address} />
                                </div>
                            </div>
                        </Segment>
                    </div>
                </div>

                <div className="row lara-row-margin">
                    <div className="col-md-6 col-sm-6 col-xs-12 lara-row-margin">
                        <Segment>
                            <Dimmer inverted active={this.state.shippingPanelLoading}>
                                <Loader />
                            </Dimmer>
                            <SegmentRibbon label="ShipStation" Buttons={
                                () => {return (<React.Fragment>
                                    <PrimaryButton
                                        size='mini'
                                        float='right'
                                        label={t('Edit')}
                                        onClick={() => {
                                            this.onOpenCredentialFormModalClick(1);
                                        }}
                                        loading={false}
                                    />
                                </React.Fragment>)}
                            }/>
                            <div className="row lara-row-margin">
                                <Header as='h2'>
                                    {shipstation ? "ShipStation Entered" : "Not Connected"}
                                    {shipstation ? <Header.Subheader>
                                        {shipstation.key_1}
                                    </Header.Subheader> : null}
                                </Header>
                            </div>
                            {shipstation ? <div className="row lara-row-margin">
                                <PrimaryButton
                                    size='mini'
                                    float='left'
                                    label={t('Load Resource')}
                                    onClick={() => {
                                        this.cacheShipStation()
                                    }}
                                    loading={this.state.loading}
                                />
                            </div> : null}
                        </Segment>
                    </div>
                    <div className="col-md-6 col-sm-6 col-xs-12 lara-row-margin">
                        <Segment>
                            <Dimmer inverted active={this.state.shippingPanelLoading}>
                                <Loader />
                            </Dimmer>
                            <SegmentRibbon label="GoShippo" Buttons={
                                () => {return (<React.Fragment>
                                    <PrimaryButton
                                        size='mini'
                                        float='right'
                                        label={t('Edit')}
                                        onClick={() => {
                                            this.onOpenCredentialFormModalClick(13);
                                        }}
                                        loading={false}
                                    />
                                </React.Fragment>)}
                            }/>
                            <div className="row lara-row-margin">
                                <Header as='h2'>
                                    {shippo ? "GoShippo Entered" : "Not Connected"}
                                    {shippo ? <Header.Subheader>
                                        {shippo.key_1}
                                    </Header.Subheader> : null}
                                </Header>
                            </div>
                            {shippo ? <div className="row lara-row-margin">
                                <PrimaryButton
                                    size='mini'
                                    float='left'
                                    label={t('Load Resource')}
                                    onClick={() => {
                                        this.cacheShippo()
                                    }}
                                    loading={this.state.loading}
                                />
                            </div> : null}
                        </Segment>
                    </div>
                    <div className="col-md-12 col-sm-12 col-xs-12 lara-row-margin">
                        <div style={{float: 'right'}}>
                            {this.props.v} - {this.props.b}
                        </div>
                    </div>
                </div>
                <Modal size="small" 
                    closeOnDimmerClick={false}
                    closeIcon
                    onClose={() => {this.setState({showModal: false})}}
                    closeOnEscape={true} open={this.state.showModal}>
                    <Header icon='settings' content='Integration' />
                    <Modal.Content>
                        <ApiCredentialForm 
                            model={this.state.apiModel}
                            onValueChange={(field, value) => {
                                this.onFormValueChange(field, value)
                            }}
                        />
                    </Modal.Content>
                    <Modal.Actions>
                        <div className="row">
                            <div className="col-md-12">
                                <DeleteButton
                                    size='mini'
                                    float='left'
                                    label={t('Delete')}
                                    onClick={() => {this.onDeleteSubmit()}}
                                    loading={this.state.loading}
                                />
                                <PrimaryButton
                                    size='mini'
                                    float='right'
                                    label={t('Save')}
                                    onClick={() => {this.onSaveSubmit()}}
                                    loading={this.state.loading}
                                />
                            </div>
                        </div>
                    </Modal.Actions>
                </Modal>
            </div>
        )
    }
}

CompanySettingViewPage.propTypes = {
    history: PropTypes.shape({
       push: PropTypes.func.isRequired
    }).isRequired,
    t: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        model: state.system.preference,
        v: state.system.v,
        b: state.system.build
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch)
}

export default withI18n()(connect(mapStateToProps, mapDispatchToProps)(CompanySettingViewPage));