import React, { Component } from 'react';
import _ from 'lodash';
import {withI18n} from 'react-i18next';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import AlertComponent from '../../includes/messages/alert';
import FormError from '../../includes/messages/422';
import {api} from '../../../api';
import Lara from '../../../lara';
import UomSelector from '../../includes/selectors/UomSelector';
import ClientFormSelector from '../../includes/selectors/ClientFormSelector';
import {Form} from 'semantic-ui-react';
import PrimaryButton from '../../includes/buttons/PrimaryButton';
import DeleteButton from '../../includes/buttons/DeleteButton';

class BatchForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            errors: [],
            model: {
                start: "",
                count: 1,
                uom_id: "0",
                client_id: "0",
                length: 1,
                width: 1,
                height: 1,
                weight:1
            }
        }
    }

    onFormSubmit = () => {
        const errors = this.validateInput();
        if (_.isEmpty(errors)) {
            this.setState({
                loading: true
            });
            api.licenses.batch(this.state.model).then(res => {
                this.props.batchCreated();
            }).catch(err => {
                this.axiosErorros(err);
            })
        } else {
            this.setState({
                errors: errors
            });
        }
    };

    validateInput = () => {
        const {start, count, client_id, uom_id} = this.state.model;
        const errors = [];
        if (!start) {
            errors.push(this.props.t('StartLicenseNumberIsRequired'));
        }
        if (!count || parseInt(count, 10) <= 0) {
            errors.push(this.props.t('NumberOfLicensesIsRequired'));
        }
        if (parseInt(client_id, 10) <= 0) {
            errors.push(this.props.t('ClientIsRequired'));
        }
        if (parseInt(uom_id, 10) <= 0) {
            errors.push(this.props.t('UomNameIsRequired'));
        }
        return errors;
    };

    axiosErorros = (err) => {
        const errors = Lara.axiosError(err, this.props.t);
        if (errors.http_code === 422) {
            this.setState({
                errors: errors.message,
                loading: false
            });
        } else {
            this.setState({
                loading: false
            });
            Lara.axiosAlert(<AlertComponent 
                support={errors.support}
                message={errors.message}
                reference={errors.reference}
                t={this.props.t}
            />);
        }
    }

    onModelValueChange = (field, value) => {
        this.setState({
            model: update(this.state.model, {
                [field]: {$set: value}
            })
        })
    }
    
    render() {
        const {model} = this.state;
        const {t, clients} = this.props;
        return (
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12">
                    <Form>
                        {!_.isEmpty(this.state.errors) ? (
                            <FormError errors={this.state.errors} />
                        ) : null}
                        <Form.Group widths='equal'>
                            <Form.Input
                                value={model.start}
                                label={t("StartNumber")}
                                fluid
                                required={true}
                                onChange={(e, {value}) => {
                                    this.onModelValueChange('start', value);
                                }}
                            />
                            <Form.Input
                                value={model.count}
                                label={t("LpCount")}
                                fluid
                                type="number"
                                required={true}
                                onChange={(e, {value}) => {
                                    this.onModelValueChange('count', value);
                                }}
                            />
                        </Form.Group>

                        <ClientFormSelector
                            clients={clients}
                            value={model.client_id}
                            onSelected={(cid) => {
                                this.onModelValueChange('client_id', cid);
                            }}
                            size='small'
                            required={true}
                            label={t('Client')}
                        />
                        <UomSelector
                            associated={[]}
                            onUomSelected={(uid) => {
                                this.onModelValueChange('uom_id', uid);
                            }}
                            selectedValue={_.toInteger(model.uom_id)}
                            label={t("UOM")}
                        />
                        <Form.Group widths='equal'>
                            <Form.Input
                                value={model.length}
                                label={t("Length")}
                                fluid
                                type='number'
                                required={true}
                                onChange={(e, {value}) => {
                                    this.onModelValueChange('length', value);
                                }}
                            />
                            <Form.Input
                                value={model.width}
                                label={t("Width")}
                                fluid
                                type='number'
                                required={true}
                                onChange={(e, {value}) => {
                                    this.onModelValueChange('width', value);
                                }}
                            />
                        </Form.Group>
                        <Form.Group widths='equal'>
                            <Form.Input
                                value={model.height}
                                label={t("Height")}
                                fluid
                                type='number'
                                required={true}
                                onChange={(e, {value}) => {
                                    this.onModelValueChange('height', value);
                                }}
                            />
                            <Form.Input
                                value={model.weight}
                                label={t("Weight")}
                                fluid
                                type='number'
                                required={true}
                                onChange={(e, {value}) => {
                                    this.onModelValueChange('weight', value);
                                }}
                            />
                        </Form.Group>
                        <PrimaryButton
                            size='mini'
                            float='left'
                            label={t('Save')}
                            onClick={() => {
                                this.onFormSubmit()
                            }}
                            loading={this.state.loading}
                        />
                        
                        {model.id && model.fulfillments_count === 0 ? <DeleteButton
                            size='mini'
                            float='right'
                            label={t('Delete')}
                            onClick={() => {
                                this.onDeleteClick()
                            }}
                            loading={this.state.loading}
                        /> : null}
                    </Form>
                </div>
            </div>
        )
    }
}

BatchForm.propTypes = {
    clients: PropTypes.array.isRequired,
    batchCreated: PropTypes.func.isRequired
};

export default withI18n()(BatchForm)