import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import {Table, Input} from 'semantic-ui-react';
import {
    storeItemToInventoryKit,
    updateItemQtyInInventoryKitInStore,
    updateItemQtyInInventoryKitOnServer,
    deleteItemFromInventoryKit
} from '../../../actions/inventory';
import InventorySelector from '../../includes/selectors/InventorySelector';
import Lara from '../../../lara';
import AlertComponent from '../../includes/messages/alert';

class InventoryKitPane extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        }
    }

    onInventoryItemSelected = (inventory) => {
        if (!_.isObject(inventory)) {
            return null;
        }
        const existingIds = this.exceptedInventoryIds();
        if (existingIds.includes(inventory.id)) {
            return null;
        }
        this.setState({
            loading: true
        });
        this.props.storeItemToInventoryKit(this.props.id, inventory.id, 1, inventory).then(res => {
            this.setState({
                loading: false
            });
        }).catch(err => {
            const errors = Lara.axiosError(err, this.props.t);
            Lara.axiosAlert(<AlertComponent 
                support={errors.support}
                message={errors.message}
                reference={errors.reference}
                t={this.props.t}
            />);
            this.setState({
                loading: false
            });
        })
    }
    
    onUpdateQtyBtnClick = (i) => {
        this.setState({
            loading: true
        });
        this.props.updateItemQtyInInventoryKitOnServer(this.props.id, i.id, i.pivot.item_qty).then(res => {
            this.setState({
                loading: false
            });
        }).catch(err => {
            const errors = Lara.axiosError(err, this.props.t);
            Lara.axiosAlert(<AlertComponent 
                support={errors.support}
                message={errors.message}
                reference={errors.reference}
                t={this.props.t}
            />);
            this.setState({
                loading: false
            });
        })
    }
    
    onLineQtyChange = (value, index) => {
        this.props.updateItemQtyInInventoryKitInStore(value, index);
    }

    onDeleteClick = (i, index) => {
        this.setState({
            loading: true
        });
        this.props.deleteItemFromInventoryKit(this.props.id, i.id, index).then(res => {
            this.setState({
                loading: false
            });
        }).catch(err => {
            const errors = Lara.axiosError(err, this.props.t);
            Lara.axiosAlert(<AlertComponent 
                support={errors.support}
                message={errors.message}
                reference={errors.reference}
                t={this.props.t}
            />);
            this.setState({
                loading: false
            });
        });
    }

    exceptedInventoryIds = () => {
        const {items} = this.props;
        const except = [];
        if (_.isArray(items)) {
            _.each(items, item => {
                if(_.isObject(item)) {
                    except.push(item.id);
                }
            });
        }
        return except;
    }
    
    render() {
        const {t, items} = this.props;
        return (
            <div>
                <InventorySelector 
                    onSelected={(inventory) => {this.onInventoryItemSelected(inventory)}}
                    exceptIds={this.exceptedInventoryIds()}
                    canSearchKit={false}
                />
                <Table compact>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>
                                {t("SKU")}
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                {t("Name")}
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                {t("ItemQty")}
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                {t("Available")}
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                {t("Update")}
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                {t("Delete")}
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {items.map((i, index) => {
                            let {pivot} = i;
                            if (!_.isObject(pivot)) {
                                i.pivot = {}
                            }
                            return (
                                <Table.Row key={i.id}>
                                    <Table.Cell>
                                        {i.sku}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {i.name}
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Input size="mini" type="number"
                                            value={i.pivot.item_qty}
                                            onChange={(e, {value}) => {
                                                this.onLineQtyChange(value, index)
                                            }}
                                        />
                                    </Table.Cell>
                                    <Table.Cell>
                                        {i.available}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {!this.state.loading ? <span 
                                            onClick={() => {
                                                this.onUpdateQtyBtnClick(i, index);
                                            }}
                                            className="lara-editable lara-clickable">
                                            {t("Update")}
                                        </span> : null}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {!this.state.loading ? <span 
                                            onClick={() => {
                                                this.onDeleteClick(i, index);
                                            }}
                                            className="lara-editable lara-clickable">
                                            {t("Delete")}
                                        </span> : null}
                                    </Table.Cell>
                                </Table.Row>
                            )
                        })}
                    </Table.Body>
                </Table>
            </div>
        )
    }
}

InventoryKitPane.propTypes = {
    items: PropTypes.array.isRequired,
    id: PropTypes.number.isRequired,
    t: PropTypes.func.isRequired,
    storeItemToInventoryKit: PropTypes.func.isRequired,
    updateItemQtyInInventoryKitInStore: PropTypes.func.isRequired,
    updateItemQtyInInventoryKitOnServer: PropTypes.func.isRequired,
    deleteItemFromInventoryKit: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        storeItemToInventoryKit,
        updateItemQtyInInventoryKitInStore,
        updateItemQtyInInventoryKitOnServer,
        deleteItemFromInventoryKit
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(InventoryKitPane);