import React, { Component } from 'react';
import { bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import {Form} from 'semantic-ui-react';
import PropTypes from 'prop-types';

//const ModelName = 'Term';

class PaymentTermSelector extends Component {
    //access router
    //this.props.history.push('/dashboard')    
    render() {
        const {terms, label, selected, onSelectChange} = this.props;
        const options = [];
        _.each(terms, c => {
            options.push({
                value: _.toString(c.name),
                text: c.name,
                key: c.id
            });
        });
        options.unshift({
            value: "NA",
            text: "N/A",
            key: -1
        });
        return (
            <Form.Select fluid search selection 
                label={label}
                options={options}
                name="term_id" value={selected ? _.toString(selected) : "0"} 
                onChange={(e, {value}) => {
                    onSelectChange(value)
                }}
            />
        )
    }
}

PaymentTermSelector.propTypes = {
    onSelectChange: PropTypes.func.isRequired,
    terms: PropTypes.array.isRequired,
    label: PropTypes.string
    //history: PropTypes.shape({
    //    push: PropTypes.func.isRequired
    //}).isRequired,
};

function mapStateToProps(state) {
    return {
        terms: state.system.resources.terms
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentTermSelector)