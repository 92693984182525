import React from 'react';
import PropTypes from 'prop-types';
import {
    Form
} from 'semantic-ui-react';
const options = [
    {value:4, text:'Shopify'},
    {value:5, text:'WooCommerce'},
    {value:6, text:'TradeGecko'},
    {value:8, text:'ShipStation Order'},
    {value:10, text:'Magento V2'},
    {value:11, text:'Aamazon FBA Inbound (US)'},
    {value:12, text:'Client ShipStation'},
];

const ApiCrendentialForm = ({model, onValueChange}) => (
    <div>
        <Form size='mini'>
            {model.type !== 1 && model.type !== 13 ? <Form.Select 
                fluid search selection 
                label='Partner'
                options={options}
                onChange={(e, {value}) => {onValueChange('type',value)}}
                value={model.type}
            /> : null}
            {model.type === 1 ? <Form.Input 
                fluid 
                label="API Key"
                name="key_1"
                required={true}
                value={model.key_1 ? model.key_1 : ""} 
                onChange={(e) => { onValueChange(e.target.name, e.target.value) }}
            /> : null}
            {model.type === 1 ? <Form.Input 
                fluid 
                label="API Secret"
                name="key_2"
                required={true}
                value={model.key_2 ? model.key_2 : ""} 
                onChange={(e) => { onValueChange(e.target.name, e.target.value) }}
            /> : null}
            {model.type === 13 ? <Form.Input 
                fluid 
                label="API Token"
                name="key_1"
                required={true}
                value={model.key_1 ? model.key_1 : ""} 
                onChange={(e) => { onValueChange(e.target.name, e.target.value) }}
            /> : null}
        </Form>
    </div>
)

ApiCrendentialForm.propTypes = {
    model: PropTypes.object.isRequired,
    onValueChange: PropTypes.func.isRequired
};


export default ApiCrendentialForm