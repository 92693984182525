import update from 'immutability-helper';
import {
    SET_BILLING_ITEM_PAGINATED_LIST,
    NEW_BILLING_ITEM_CREATED_ON_SERVER,
    EXISTING_BILLING_ITEM_UPDATED_ON_SERVER
} from '../../actions/types';

export default function billingItemList(state = {
    list: {
        total: 0,
        per_page: 15,
        on_page: 1,
        records: [

        ]
    }
}, action = {}) {
    switch(action.type) {
        case SET_BILLING_ITEM_PAGINATED_LIST:
            return update(state, {
                list: {$set: action.payload}
            });

        case NEW_BILLING_ITEM_CREATED_ON_SERVER:
            return update(state, {
                list: {
                    records: {
                        $unshift: [action.payload]
                    }
                }
            });

        case EXISTING_BILLING_ITEM_UPDATED_ON_SERVER:
            return update(state, {
                list: {
                    records: {
                        [action.payload.index]: {
                            $set: action.payload.data
                        }
                    }
                }
            })

        default: return state;
    }
}