import {
    deleteLocalStorage,
    saveLocalAuth
} from './utils';
import {
    USER_LOGGED_OUT,
    GUEST_BOOT_LOADED
} from './types';
import {api} from '../api';

export const bootGuest = () => (dispatch) => {
    return api.auth.boot().then(res => {
        dispatch({
            type: GUEST_BOOT_LOADED,
            payload: res.data.data
        });
    }).catch( err => {
        throw err;
    });
}

export const logout = () => {
    deleteLocalStorage('lara_auth');
    return ({
        type: USER_LOGGED_OUT,
        payload: null
    });
}

export const attemptLogin = (email, password) => (dispatch) => {
    return api.auth.attemptLogin({email, password}).then(res => {
        saveLocalAuth(res.data.data);
        // saveLocalStorage('lara_user',{
        //     auth: {
        //         jwt, expire, timezone
        //     }
        // });
        return res;
    }).catch(err => {
        console.log(err);
        throw err;
    });
}