import React, { Component } from 'react';
import { bindActionCreators} from 'redux';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    Segment, Modal, Form, Header, Table, Icon, Label
} from 'semantic-ui-react';
import update from 'immutability-helper';
import validator from 'validator';
import MultiClientSelector from '../selectors/MultiClientSelector';
import SegmentRibbon from '../partials/SegmentRibbon';
import PrimaryButton from '../buttons/PrimaryButton';
import FormError from '../messages/422';
import AlertComponent from '../messages/alert';
import Lara from '../../../lara';
import {
    api
} from '../../../api';

const taskFrequency = {
    billing: [
        {value: 'Week', text: 'On every Monday, send billing report of last week'},
        {value: 'Month', text: 'On the 1st of every month, send billing report of last month'}
    ]
}

const reportTypes = {
    billing: [
        {value: '1', text: '3PL Default Report'},
        {value: '2', text: 'Outbound Shipment Report'},
        {value: '3', text: 'Billing By Item'}
    ]
}

class TaskSegment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tasks: [],
            model: {
                params: {}
            },
            errors: [],
            showModal: false,
            loading: false,
            showClientListModal: false,
            listModel: {
                id: null,
                params: {
                    clients: ""
                }
            },
            listIds: [

            ]
        }
    }

    componentDidMount() {
        api.tasks.list({report_type: [1,2,3]}).then(res => {
            this.setState({
                tasks: res.data.data
            })
        })
    }
    
    onAddTaskClick = () => {
        if (this.props.fromPage === 'billing') {
            this.setState({
                model: {
                    frequency: 'Month',
                    report_type: "1",
                    file_type: '1',
                    receiver_1: "",
                    receiver_2: "",
                    params: {
                        clients: ""
                    }
                },
                showModal: true
            })
        }
    }

    onDeleteClick = (task, index) => {
        api.tasks.delete(task.id).then(res => {
            this.setState({
                tasks: update(this.state.tasks, {
                    $splice: [[index, 1]]
                })
            })
        })
    }

    onShowListOfClientClick = (filteredList, model) => {
        this.setState({
            listModel: model,
            listIds: filteredList,
            showClientListModal: true
        })
    }

    onUpdateTaskListParamsClick = () => {
        const {id, params} = this.state.listModel;
        if (!_.isObject(params) || !id) {
            return null;
        }
        if (!params.clients) {
            return null;
        }
        this.setState({
            loading: true
        }, () => {
            //1. PUT /tasks/{id}/params to update the params
            //2. Update existing tasks list in state
            //3. Close model, reset list model
            api.tasks.updateParams(id, params).then(res => {
                const taskIndex = this.state.tasks.findIndex(i => {
                    return i.id === res.data.data.id;
                })
                if (taskIndex > -1) {
                    this.setState({
                        loading: false,
                        tasks: update(this.state.tasks, {
                            [taskIndex]: {
                                $set: res.data.data
                            }
                        }),
                        showClientListModal: false,
                        listModel: {
                            id: null,
                            params: {
                                clients: ""
                            }
                        }
                    })
                } else {
                    this.setState({
                        loading: false,
                        showClientListModal: false,
                        listModel: {
                            id: null,
                            params: {
                                clients: ""
                            }
                        }
                    })
                    Lara.alertError("Invalid index number found, please refresh your page and try again.");
                }
            }).catch(err => {
                Lara.alertError("List cannot be updated. Please contact support");
                this.setState({
                    loading: false
                })
            })
        });
    }

    onSaveTaskClick = () => {
        const errors = this.validateInput();
        if (_.isEmpty(errors)) {
            this.setState({
                loading: true,
                errors: []
            }, () => {
                api.tasks.create(this.state.model).then(res => {
                    this.setState({
                        loading: false,
                        tasks: update(this.state.tasks, {
                            $push: [res.data.data]
                        }),
                        showModal: false
                    });
                }).catch(err => {
                    const errors = Lara.axiosError(err, this.props.t);
                    if (errors.http_code === 422) {
                        this.setState({
                            errors: errors.message,
                            loading: false
                        });
                    } else {
                        this.setState({
                            loading: false
                        });
                        Lara.axiosAlert(<AlertComponent 
                            support={errors.support}
                            message={errors.message}
                            reference={errors.reference}
                            t={this.props.t}
                        />);
                    }
                })
            })
        } else {
            this.setState({
                errors: errors
            })
        }
    }

    validateInput = () => {
        const errors = [];
        if (this.props.fromPage === 'billing') {
            const {model} = this.state;
            if (_.isEmpty(model.params.clients)) {
                errors.push("Must select at least 1 client");
            }
            if (!validator.isEmail(model.receiver_1)) {
                errors.push("Send To 1 must be a valid email address");
            }
            if (model.receiver_2) {
                if (!validator.isEmail(model.receiver_2)) {
                    errors.push("Send To 2 must be a valid email address");
                }
            }
        }
        return errors;
    }

    render() {
        const {t, clients} = this.props;
        return (
            <Segment>
                <SegmentRibbon label="Auto Task" Buttons={
                    () => {return (<React.Fragment>
                        <PrimaryButton
                            onClick={() => {
                                this.onAddTaskClick()
                            }}
                            size='mini'
                            float='right'
                            loading={false}
                            label='Add'
                        />
                    </React.Fragment>)}
                }/>
                <Table compact>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>
                                Task Frequency
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Report
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Send To
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Client(s)
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Created By
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Delete
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {this.state.tasks.map((t,index) => {
                            const clientArray  = t.params.clients ? t.params.clients.split(',') : [];
                            const filtered = clientArray.filter(function (el) {
                                return el;
                            })
                            return <Table.Row key={t.id}>
                                <Table.Cell>
                                    {t.frequency}
                                </Table.Cell>
                                <Table.Cell>
                                    {parseInt(t.report_type, 10) === 1 ? "3PL Default Report" : null}
                                    {parseInt(t.report_type, 10) === 2 ? "Outbound Shipment Report" : null}
                                    {parseInt(t.report_type, 10) === 3 ? "Billing By Item" : null}
                                </Table.Cell>
                                <Table.Cell>
                                    {t.receiver_1}
                                    {t.receiver_2 ? " / " + t.receiver_2 : ""}
                                </Table.Cell>
                                <Table.Cell>
                                    <span className="lara-clickable lara-editable" onClick={() => {
                                        this.onShowListOfClientClick(filtered, t);
                                    }}>
                                        {filtered.length}
                                    </span>
                                </Table.Cell>
                                <Table.Cell>
                                    {t.user ? t.user.name : ""}
                                </Table.Cell>
                                <Table.Cell>
                                    <span className="lara-clickable lara-editable" onClick={() => {
                                        this.onDeleteClick(t, index);
                                    }}>
                                        <Icon name="trash"/>
                                    </span>
                                </Table.Cell>
                            </Table.Row>
                        })}
                    </Table.Body>
                </Table>
                <Modal
                    closeOnDimmerClick={false}
                    closeIcon
                    onClose={() => {
                        this.setState({showModal: false})
                    }}
                    size='small'
                    closeOnEscape={true} open={this.state.showModal}>
                    <Header icon='pencil' content={null} />
                    <Modal.Content>
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-xs-12">
                            {!_.isEmpty(this.state.errors) ? (
                                <FormError errors={this.state.errors} />
                            ) : null}
                            <Form size='mini'>
                                <Form.Select 
                                    label="Send Task"
                                    options={taskFrequency[this.props.fromPage]}
                                    value={this.state.model.frequency}
                                    onChange={(e, {value}) => {
                                        this.setState({
                                            model: update(this.state.model, {
                                                frequency: {$set: value}
                                            })
                                        })
                                    }}
                                    fluid
                                />
                                <Form.Select 
                                    label="Report"
                                    options={reportTypes[this.props.fromPage]}
                                    value={this.state.model.report_type}
                                    onChange={(e, {value}) => {
                                        this.setState({
                                            model: update(this.state.model, {
                                                report_type: {$set: value}
                                            })
                                        })
                                    }}
                                    fluid
                                />
                                <MultiClientSelector
                                    t={t}
                                    currentValueString={this.state.model.params.clients ? this.state.model.params.clients : ""}
                                    onFilterSelectChange={(value) => {
                                        this.setState({
                                            model: update(this.state.model, {
                                                params: {
                                                    clients: {$set: value.join()}
                                                }
                                            })
                                        })
                                    }}
                                    options={clients}
                                    label="Client"
                                />
                                <Form.Group widths='equal'>
                                    <Form.Input
                                        label="Send To 1"
                                        required={true}
                                        value={this.state.model.receiver_1}
                                        onChange={(e, {value}) => {
                                            this.setState({
                                                model: update(this.state.model, {
                                                    receiver_1: {$set: value}
                                                })
                                            })
                                        }}
                                    />
                                    <Form.Input
                                        label="Send To 2"
                                        value={this.state.model.receiver_2}
                                        onChange={(e, {value}) => {
                                            this.setState({
                                                model: update(this.state.model, {
                                                    receiver_2: {$set: value}
                                                })
                                            })
                                        }}
                                    />
                                </Form.Group>
                                <PrimaryButton
                                    onClick={() => {
                                        this.onSaveTaskClick()
                                    }}
                                    size='mini'
                                    float='right'
                                    loading={this.state.loading}
                                    label='Set Up & Save'
                                />
                            </Form>
                            </div>
                        </div>
                    </Modal.Content>
                </Modal>
            
                <Modal                     
                    closeOnDimmerClick={false}
                    closeIcon
                    onClose={() => {
                        this.setState({showClientListModal: false})
                    }}
                    size='large'
                    closeOnEscape={true} open={this.state.showClientListModal}>
                    <Modal.Content>
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-xs-12">
                                {/* {this.props.clients.map(c => {
                                    if (this.state.listIds.includes(_.toString(c.id))) {
                                        return <Label key={c.id}>
                                        {c.company}
                                    </Label>
                                    }
                                })} */}
                                <MultiClientSelector
                                    t={t}
                                    currentValueString={this.state.listModel && this.state.listModel.params ? this.state.listModel.params.clients : ""}
                                    onFilterSelectChange={(value) => {
                                        this.setState({
                                            // model: update(this.state.model, {
                                            //     params: {
                                            //         clients: {$set: value.join()}
                                            //     }
                                            // })
                                            listModel: update(this.state.listModel, {
                                                params: {
                                                    clients: {$set: value.join()}
                                                }
                                            })
                                        })
                                    }}
                                    options={clients}
                                    label="Client"
                                />
                                <PrimaryButton 
                                    onClick={() => {
                                        this.onUpdateTaskListParamsClick()
                                    }}
                                    size='mini'
                                    float='right'
                                    loading={this.state.loading}
                                    label='Update List'
                                />
                            </div>
                        </div>
                    </Modal.Content>
                </Modal>
            </Segment>
        )
    }
}

TaskSegment.propTypes = {
    user: PropTypes.object.isRequired,
    fromPage: PropTypes.string.isRequired,
    clients: PropTypes.array,
    t: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        user: state.system.user,
        clients: state.system.clients
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(TaskSegment)