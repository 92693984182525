import React from 'react';
import { bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import {Button} from 'semantic-ui-react';
import PropTypes from 'prop-types';

class AuthButton extends React.PureComponent {
    //access router
    //this.props.history.push('/dashboard')
    
    render() {
        const {label, preference, onClick, loading} = this.props;
        return (
            <Button size="medium" 
                color={preference.t_primary_auth ? preference.t_primary_auth : 'blue'} 
                fluid={true} 
                disabled={loading}
                loading={loading}
                onClick={() => {
                    onClick()
                }}>{label}
            </Button>
        )
    }
}

AuthButton.propTypes = {
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
    return {
        preference: state.system.preference
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthButton)