import React, { Component } from 'react'
import PropTypes from 'prop-types'
import LoginForm from './LoginForm';
import Alert from 'react-s-alert';

class LoginPage extends Component {
    componentDidMount() {
        this.props.bootGuest();
    }
    
    render() {
        const {
            name, address, city, postal, state, country,
            login_bg_color, logo, branding_name, branding, home_address
        } = this.props.preference;

        return (
            <div className="auth-container">
                <div className="auth-left-panel">
                    <div className="auth-form">
                        <LoginForm />
                    </div>
                </div>
                <div className="auth-right-panel" style={login_bg_color ? {backgroundColor: login_bg_color} : null}>
                    <div className="auth__branding">
                        {logo ? <div className="auth__text-box">
                            <h1 className="auth-primary">
                                <span>
                                    <img src={logo} alt="Company Logo" className="auth-primary--logo"/>
                                </span>
                                <span className="auth-primary--main">{branding_name}</span>
                                <span className="auth-primary--sub">{branding}</span>
                            </h1>
                        </div> : null}
                    </div>
                    <div className="auth__company-info">
                        {name ? <div>
                            <a href={home_address} className="auth__company-info__link">{name}</a><br />
                        {address}, {city} {state}  {postal}, {country}
                        </div> : null}
                    </div>
                </div>
                <Alert stack={{limit: 3}} />
            </div>
        )
    }
}

LoginPage.propTypes = {
    bootGuest: PropTypes.func.isRequired,
    preference: PropTypes.object.isRequired
};

export default LoginPage;