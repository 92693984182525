import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {Modal, Header, List, Image} from 'semantic-ui-react';
const loader = require('../../../assets/images/loading_2.gif');

const ActionStatusModal = ({open, messages, onCloseModalClick}) => (
    <Modal size='small' 
        closeOnDimmerClick={false}
        closeIcon
        centered={true}
        onClose={() => {onCloseModalClick()}}
        closeOnEscape={true} open={open}>
        <Header icon='alarm' content='' />
        <Modal.Content>
            {!_.isEmpty(messages) ? <List divided relaxed>
                {messages.map((message, index) => {
                    return (<List.Item key={index}>
                        <List.Icon name='exclamation' size='large' verticalAlign='middle' />
                        <List.Content>
                            <List.Header>{message.failed_reference}</List.Header>
                            <List.Description>{message.failed_reason}</List.Description>
                        </List.Content>
                    </List.Item>)
                })}
            </List> : <Image src={loader} size='small' centered />}
        </Modal.Content>
    </Modal>
)

ActionStatusModal.propTypes = {
    open: PropTypes.bool.isRequired,
    messages: PropTypes.array.isRequired,
    onCloseModalClick: PropTypes.func.isRequired
};


export default ActionStatusModal