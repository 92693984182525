import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";
import {withI18n} from 'react-i18next';
import { Form, Segment } from "semantic-ui-react";
import FormError from '../../includes/messages/422';
import PageBreadCrub from '../../includes/partials/PageBreadCrub';
import AlertComponent from '../../includes/messages/alert';
import Lara from '../../../lara';
import SegmentRibbon from '../../includes/partials/SegmentRibbon';
import SingleClientSelector from '../../includes/selectors/SingleClientSelector';
import PrimaryButton from '../../includes/buttons/PrimaryButton';
import {
    fetchActivityById,
    updateActivityModelInStore,
    updateActivityModelOnServer,
    createActivityNewModelInStore
} from '../../../actions/billing';

//Lara Button Updated
class BillingActivityEditPage extends Component {
    constructor() {
        super();
        this.state = {
            breadcrub: [
                {isLink:false, to:null, tag: '', active: true, key: 1}
            ],
            errors: [],
            loading: false
        };
    }

    componentDidMount() {
        const {match, t, model} = this.props;
        const {id} = match.params;
        if (id === 'new') {
            this.props.createActivityNewModelInStore();
            this.setState({
                breadcrub: this.getBreadCrub('Activity', 'Billing', t('Create'), null)
            });
        } else {
            if (_.toString(id) !== _.toString(model.id)) {
                this.setState({
                    loading: true
                });
                this.props.fetchActivityById(id).then(res => {
                    this.setState({
                        loading: false,
                        breadcrub: this.getBreadCrub(res.name, 
                           'Billing', 
                            t('Edit'), `/billing/activities/${res.id}/action/view`)
                    })
                }).catch(err => {
                    const errors = Lara.axiosError(err, this.props.t);
                    if (errors.http_code === 422) {
                        this.setState({
                            errors: errors.message,
                            loading: false
                        });
                    } else {
                        this.setState({
                            loading: false
                        });
                        Lara.axiosAlert(<AlertComponent 
                            support={errors.support}
                            message={errors.message}
                            reference={errors.reference}
                            t={this.props.t}
                        />);
                    }
                    this.setState({loading: false});
                })
            } else {
                this.setState({
                    breadcrub: this.getBreadCrub(model.name, 'Billing', 
                        t('Edit'), 
                        `/billing/activities/${model.id}/action/view`)
                })
            }
        }
    }

    getBreadCrub = (name, type, action, viewPageLink) => {
        return [
            {isLink:false, to:null, tag: _.upperFirst(type), active: false, key: 1},
            {isLink:viewPageLink ? true : false, to:viewPageLink, tag: _.upperFirst(name), active: false, key: 2},
            {isLink:false, to:null, tag: action, active: true, key: 3}
        ]
    }

    onFormSubmit = () => {
        const errors = this.validateInput();
        if (_.isEmpty(errors)) {
            this.setState({
                loading: true
            });
            this.props.updateActivityModelOnServer(this.props.model).then(res => {
                this.setState({
                    errors: [],
                    loading: false
                });
                this.props.history.push(`/billing/activities/${res.data.data.id}/action/view`);
            }).catch(err => {
                const errors = Lara.axiosError(err, this.props.t);
                if (errors.http_code === 422) {
                    this.setState({
                        errors: errors.message,
                        loading: false
                    });
                } else {
                    this.setState({
                        loading: false
                    });
                    Lara.axiosAlert(<AlertComponent 
                        support={errors.support}
                        message={errors.message}
                        reference={errors.reference}
                        t={this.props.t}
                    />);
                }
            });
        } else {
            this.setState({
                errors: errors
            });
        }
    };

    validateInput = () => {
        const {name, client_id} = this.props.model;
        const errors = [];
        if (!name) {
            errors.push(this.props.t('ActivityNameIsRequired'));
        }
        if (parseInt(client_id, 10) <= 0) {
            errors.push(this.props.t("ClientIsRequired"));
        }
        return errors;
    };
    
    render() {
        const {model, t, updateActivityModelInStore} = this.props
        return (
            <div>
                {/* Breadcrub Row */}
                <div className="row">
                    <div className="col-md-12 lara-breadcrub">
                        <PageBreadCrub lists={this.state.breadcrub} t={t}/>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6 col-sm-6 col-xs-12">
                        <Segment>
                            <SegmentRibbon label={model.name ? model.name : t('Form')} Buttons={
                                    () => {return (<React.Fragment>
                                        <PrimaryButton
                                            size='mini'
                                            float='right'
                                            label={t('Save')}
                                            onClick={() => {this.onFormSubmit()}}
                                            loading={this.state.loading}
                                        />
                                    </React.Fragment>)}
                                }/>
                        
                            <Form size="mini">
                                {!_.isEmpty(this.state.errors) ? (
                                    <FormError errors={this.state.errors} />
                                ) : null}
                                <Form.Input 
                                    fluid 
                                    required={true}
                                    label={t("Activity")}
                                    name="name"
                                    value={model.name ? model.name : ""} 
                                    onChange={(e) => { updateActivityModelInStore(e.target.name, e.target.value) }}
                                />
                                <div style={{marginBottom: 8}}>
                                    <span className="lara-ui-label">Client</span>
                                    <SingleClientSelector
                                        clients={this.props.clients}
                                        selected={model.client_id ? _.toString(model.client_id) : "0"}
                                        clientSelected={({value}) => {
                                            updateActivityModelInStore('client_id', value);
                                        }}
                                        disabled={this.state.loading}
                                        loading={this.state.loading}
                                        ignoreError={true}
                                    />
                                </div>
                                <Form.Select
                                    value={model.charge}
                                    options={[
                                        {value: 'Manual', text: t('Manual')},
                                        {value: 'Inbound', text: t('Inbound')},
                                        {value: 'Outbound', text: t('Outbound')},
                                        {value: 'Month-Begin', text: t('Month-Begin')},
                                        {value: 'Month-End', text: t('Month-End')},
                                        {value: 'Week-Beg', text: t('Week-Begin')},
                                        {value: 'Week-End', text: t('Week-End')},
                                        {value: 'Daily-Cubic', text: t('Daily-Cubic-Storage')},
                                        {value: 'Daily-Location', text: t('Daily-Location-Storage')},
                                        {value: 'Shipping-Charge', text: t('Shipping-Charge')}
                                    ]}
                                    label={t("ChargeType")}
                                    onChange={(e, {value}) => {
                                        updateActivityModelInStore('charge', value)
                                    }}
                                />
                                <Form.Checkbox
                                        label={t('Status')}
                                        name="is_active"
                                        onChange={(e,data) => { updateActivityModelInStore('is_active', data.checked) }}
                                        checked={model.is_active}
                                    />
                            </Form>
                        </Segment>
                    </div>
                </div>
            </div>

        );
    }
}

BillingActivityEditPage.propTypes = {
    model: PropTypes.object.isRequired,
    updateActivityModelInStore: PropTypes.func.isRequired,
    updateActivityModelOnServer: PropTypes.func.isRequired,
    createActivityNewModelInStore: PropTypes.func.isRequired,
    fetchActivityById: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    clients: PropTypes.array.isRequired
};

function mapStateToProps(state) {
    return {
        model: state.billingActivityModel,
        clients: state.system.clients
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            fetchActivityById,
            updateActivityModelInStore,
            updateActivityModelOnServer,
            createActivityNewModelInStore
        },
        dispatch
    );
}

export default withI18n()(connect(
    mapStateToProps,
    mapDispatchToProps
)(BillingActivityEditPage));