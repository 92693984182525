import React from "react";
import {
    Form, Table, Input
} from 'semantic-ui-react';
import {withI18n} from 'react-i18next';
import _ from 'lodash';
import PropTypes from "prop-types";
import PredefinedPackageSelector from '../../includes/selectors/PredefinedPackageSelector';
import PackShipmentLineSelector from '../../includes/selectors/PackShipmentLineSelector';
import PrimaryButton from "../../includes/buttons/PrimaryButton";

class SingleCartonPackForm extends React.PureComponent {
    //access router
    //this.props.history.push('/dashboard')
    getShipmentLineById = (id) => {
        const {lines} = this.props;
        const index = _.findIndex(lines, l => {
            return _.toString(l.id) === _.toString(id)
        })
        return index > -1 ? lines[index] : {inventory: {}}
    }

    render() {
        const {packages, t, model, onPackageSelected, 
            onSingleModelValueChange, 
            onPackItemLineQtyChange,
            onAddLineToSingleModelClick} = this.props;
        return (
            <div>
                <Form size="mini">
                    <PredefinedPackageSelector
                        label={t("PredefinedPackage")}
                        packages={packages}
                        onPackageSelected={pack => {
                            onPackageSelected(pack);
                        }}
                        value={
                            model.id
                                ? _.toString(model.id)
                                : "0"
                        }
                    />
                    <Form.Group widths="equal">
                        <Form.Input
                            value={model.own_number}
                            onChange={(e, { value }) => {
                                onSingleModelValueChange("own_number", value);
                            }}
                            label={t("PackageNumber")}
                            fluid
                            size="mini"
                        />
                        <Form.Input
                            value={model.length}
                            onChange={(e, { value }) => {
                                onSingleModelValueChange("length", value);
                            }}
                            label={t("Length")}
                            fluid
                            size="mini"
                        />
                        <Form.Input
                            value={model.width}
                            onChange={(e, { value }) => {
                                onSingleModelValueChange("width", value);
                            }}
                            label={t("Width")}
                            fluid
                            size="mini"
                        />
                        <Form.Input
                            value={model.height}
                            onChange={(e, { value }) => {
                                onSingleModelValueChange("height", value);
                            }}
                            label={t("Height")}
                            fluid
                            size="mini"
                        />
                        <Form.Input
                            value={model.weight}
                            onChange={(e, { value }) => {
                                onSingleModelValueChange("weight", value);
                            }}
                            label={t("Weight")}
                            fluid
                            size="mini"
                        />
                    </Form.Group>
                    <PackShipmentLineSelector
                        packedQty={this.props.packedQty}
                        lines={this.props.lines}
                        onAddLineClick={(lineId, quantity, expire) => {
                            onAddLineToSingleModelClick(lineId, quantity, expire);
                        }}
                    />
                </Form>
                <Table compact>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>{t("SKU")}</Table.HeaderCell>
                            <Table.HeaderCell>{t("Name")}</Table.HeaderCell>
                            <Table.HeaderCell>
                                {t("QuantityShipped")}
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                {t("QuantityPacked")}
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {model.contents.map((i, index) => {
                            const line = this.getShipmentLineById(i.lineId);
                            return (
                                <Table.Row key={index}>
                                    <Table.Cell>{line.inventory.sku}</Table.Cell>
                                    <Table.Cell>{line.inventory.name}</Table.Cell>
                                    <Table.Cell>
                                        {line.quantity}
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Input size='mini' value={i.quantity}
                                                onChange={(e, {value}) => {
                                                    onPackItemLineQtyChange(value, index)
                                                }} />
                                    </Table.Cell>
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                </Table>
                <div className="row">
                    <div className="col-md-12">
                        <PrimaryButton
                            size='mini'
                            float='left'
                            label={t('Save Carton')}
                            onClick={() => {
                                this.props.onAddPackageToPackModel()
                            }}
                            loading={false}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

SingleCartonPackForm.propTypes = {
    lines: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired,
    packages: PropTypes.array.isRequired,
    model: PropTypes.shape({
        contents: PropTypes.array.isRequired
    }),
    onPackageSelected: PropTypes.func.isRequired,
    onSingleModelValueChange: PropTypes.func.isRequired,
    onAddLineToSingleModelClick: PropTypes.func.isRequired,
    onPackItemLineQtyChange: PropTypes.func.isRequired,
    onAddPackageToPackModel: PropTypes.func.isRequired,
    packedQty: PropTypes.object.isRequired
};

export default withI18n()(SingleCartonPackForm);
