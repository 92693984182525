import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb, Icon, Modal, Header, Dimmer, Loader } from 'semantic-ui-react';
import {api} from '../../../api';
import TraceComponentTable from './TraceComponentTable';

class TraceComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            modal: false,
            data: {
                current_page: 1,
                data: [],
                last_page: 1,
                total: 1,
                to: 1,
                per_page: 15
            }
        }
    }
    
    onTraceClick = () => {
        this.setState({
            modal: true,
            loading: true
        });
        this.fetchTraces(1);
    }

    fetchTraces = (page) => {
        const {
            trace_id,trace_type
        } = this.props;
        api.getTraces(trace_type, trace_id, page).then(res => {
            this.setState({
                loading: false,
                data: res.data.data
            });
        }).catch(err => {
            this.setState({
                loading: false,
                data: {
                    current_page: 1,
                    data: [],
                    last_page: 1,
                    total: 1,
                    to: 1,
                    per_page: 15
                }
            });
        })
    }

    render() {
        return (
            <div style={{float: 'right'}}>
                <Breadcrumb size='large'>
                    <Breadcrumb.Section link={false}>
                        <span onClick={() => this.onTraceClick()} className="lara-editable lara-clickable">
                            <Icon name='list alternate outline' />Trace
                        </span>
                    </Breadcrumb.Section>
                </Breadcrumb>
                <Modal
                    closeOnDimmerClick={false}
                    closeIcon
                    onClose={() => {
                        this.setState({modal: false})
                    }}
                    size='large'
                    closeOnEscape={true} open={this.state.modal}>
                    <Header icon='list alternate outline' content={null} />
                    <Modal.Content>
                        {this.state.loading ? <Dimmer inverted active={true}>
                            <Loader />
                        </Dimmer> : null}
                        <TraceComponentTable 
                            traces={this.state.data.data}
                        />
                    </Modal.Content>
                </Modal>
            </div>
        )
    }
}

TraceComponent.propTypes = {
    trace_id: PropTypes.number.isRequired,
    trace_type: PropTypes.string.isRequired
};

export default TraceComponent