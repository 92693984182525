import React, { Component } from 'react';
import { bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import {withI18n} from 'react-i18next';
import PropTypes from 'prop-types';
import AlertComponent from '../../includes/messages/alert';
import FormError from '../../includes/messages/422';
import {api} from '../../../api';
import Lara from '../../../lara';
import UomSelector from '../../includes/selectors/UomSelector';
import ClientFormSelector from '../../includes/selectors/ClientFormSelector';
import {Form} from 'semantic-ui-react';
import PrimaryButton from '../../includes/buttons/PrimaryButton';
import DeleteButton from '../../includes/buttons/DeleteButton';

class LicenseForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            errors: []
        }
    }

    onFormSubmit = () => {
        const errors = this.validateInput();
        const {model, licenseSaved} = this.props;
        if (_.isEmpty(errors)) {
            this.setState({
                loading: true
            });
            if (model.id) {
                api.licenses.update(model.id, model).then(res => {
                    licenseSaved(model);
                }).catch(err => {
                    this.axiosErorros(err);
                    this.setState({
                        loading: false
                    });
                })
            } else {
                api.licenses.create(model).then(res => {
                    licenseSaved(res.data.data);
                }).catch(err => {
                    this.axiosErorros(err);
                    this.setState({
                        loading: false
                    });
                })
            }
        } else {
            this.setState({
                errors: errors
            });
        }
    };

    validateInput = () => {
        const {license, client_id, uom_id} = this.props.model;
        const errors = [];
        if (!license) {
            errors.push(this.props.t('LicenseNumberIsRequired'));
        }
        if (parseInt(client_id, 10) <= 0) {
            errors.push(this.props.t('ClientIsRequired'));
        }
        if (parseInt(uom_id, 10) <= 0) {
            errors.push(this.props.t('UomNameIsRequired'));
        }
        return errors;
    };

    axiosErorros = (err) => {
        const errors = Lara.axiosError(err, this.props.t);
        if (errors.http_code === 422) {
            this.setState({
                errors: errors.message,
                loading: false
            });
        } else {
            this.setState({
                loading: false
            });
            Lara.axiosAlert(<AlertComponent 
                support={errors.support}
                message={errors.message}
                reference={errors.reference}
                t={this.props.t}
            />);
        }
    }

    onDeleteClick = () => {
        this.setState({
            loading: true
        });
        api.licenses.delete(this.props.model.id).then(res => {
            this.props.licenseDeleted();
        }).catch(err => {
            console.log(err);
            this.axiosErorros(err);
        })
    }
    
    render() {
        const {model, onModelValueChange, t, clients} = this.props;
        return (
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12">
                    <Form>
                        {!_.isEmpty(this.state.errors) ? (
                            <FormError errors={this.state.errors} />
                        ) : null}
                        {model.id ? null : <Form.Input
                            value={model.location}
                            label={t("LicensePlate")}
                            fluid
                            required={true}
                            onChange={(e, {value}) => {
                                onModelValueChange('license', value);
                            }}
                        />}
                        {model.id ? null : <ClientFormSelector
                            clients={clients}
                            value={model.client_id}
                            onSelected={(cid) => {
                                onModelValueChange('client_id', cid);
                            }}
                            size='small'
                            required={true}
                            label={t('Client')}
                        />}
                        <UomSelector
                            associated={[]}
                            onUomSelected={(uid) => {
                                onModelValueChange('uom_id', uid);
                            }}
                            selectedValue={_.toInteger(model.uom_id)}
                            label={t("UOM")}
                        />
                        <Form.Group widths='equal'>
                            <Form.Input
                                value={model.length}
                                label={t("Length")}
                                fluid
                                type='number'
                                required={true}
                                onChange={(e, {value}) => {
                                    onModelValueChange('length', value);
                                }}
                            />
                            <Form.Input
                                value={model.width}
                                label={t("Width")}
                                fluid
                                type='number'
                                required={true}
                                onChange={(e, {value}) => {
                                    onModelValueChange('width', value);
                                }}
                            />
                        </Form.Group>
                        <Form.Group widths='equal'>
                            <Form.Input
                                value={model.height}
                                label={t("Height")}
                                fluid
                                type='number'
                                required={true}
                                onChange={(e, {value}) => {
                                    onModelValueChange('height', value);
                                }}
                            />
                            <Form.Input
                                value={model.weight}
                                label={t("Weight")}
                                fluid
                                type='number'
                                required={true}
                                onChange={(e, {value}) => {
                                    onModelValueChange('weight', value);
                                }}
                            />
                        </Form.Group>
                        <PrimaryButton
                            size='mini'
                            float='left'
                            label={t('Save')}
                            onClick={() => {
                                this.onFormSubmit()
                            }}
                            loading={this.state.loading}
                        />
                        {model.id && model.fulfillments_count === 0 ? <DeleteButton
                            size='mini'
                            float='right'
                            label={t('Delete')}
                            onClick={() => {
                                this.onDeleteClick()
                            }}
                            loading={this.state.loading}
                        /> : null}
                    </Form>
                </div>
            </div>
        )
    }
}

LicenseForm.propTypes = {
    licenseSaved: PropTypes.func.isRequired,
    model: PropTypes.object.isRequired,
    onModelValueChange: PropTypes.func.isRequired,
    clients: PropTypes.array.isRequired,
    licenseDeleted: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        
    }, dispatch)
}

export default withI18n()(connect(mapStateToProps, mapDispatchToProps)(LicenseForm))