import update from 'immutability-helper';
import _ from 'lodash';
import {
    SET_ORDER_MODEL_FROM_SERVER,
    SET_ORDER_MODEL_BY_FORM,
    SET_REALTION_AND_DATA_TO_MODEL_BY_FORM,
    SET_SHIPPING_COLLECT_ACCT_TO_MODEL_BY_FORM,
    UPDATE_ORDER_LINE_BY_FORM,
    ADD_ORDER_LINE_TO_MODEL_BY_FORM,
    REMOVE_ORDER_LINE_FROM_MODEL_BY_FORM,
    ADD_CATEGORY_TO_ORDER_MODEL,
    DELETE_CATEGORY_TO_OPRDER_MODEL,
    NEW_SHIPMENT_ADDED_TO_ORDER_MODEL,
    ORDER_HAS_BEEN_CANCELLED
} from '../../actions/types';

export default function orderModel(state = {
    id: null,
    accounting: {},
    billing_address: {},
    lines: [],
    relation: {},
    shipments: [],
    shipping: {
        collect_account: {}
    },
    shipping_address: {},
    source: {},
    order_status: null
}, action = {}) {
    switch(action.type) {
        case SET_ORDER_MODEL_FROM_SERVER:
            return action.payload;

        case ORDER_HAS_BEEN_CANCELLED:
        return update(state, {
            status: {$set: 'Cancelled'}
        });

        case SET_ORDER_MODEL_BY_FORM:
            if (action.payload.subProperty) {
                return update(state, {
                    [action.payload.subProperty]: {
                        [action.payload.field]: {
                            $set: action.payload.value
                        }
                    }
                })
            } else {
                return update(state, {
                    [action.payload.field]: {
                        $set: action.payload.value
                    }
                })
            }

        case SET_REALTION_AND_DATA_TO_MODEL_BY_FORM:
            const {relation, disableAddressPopulate} = action.payload;
            const {addresses} = relation;
            let shippingAddress = null;
            let billingAddress = null;
            _.each(addresses, addr => {
                if (addr.type === 'Both') {
                    if (!disableAddressPopulate) {
                        shippingAddress = addr;
                    }
                    billingAddress = addr;
                    return false;
                }
                if (!disableAddressPopulate) {
                    if (addr.type === 'Shipping') {
                        shippingAddress = addr;
                    }
                }
                if (addr.type === 'Billing') {
                    billingAddress = addr;
                }
            });
            // console.log(relation);
            // return state;
            return update(state, {
                relation_id: {$set: relation.id},
                shipping_address_id: {$set: shippingAddress ? shippingAddress.id : null},
                shipping_address: {$set: shippingAddress ? shippingAddress : {}},
                billing_address_id: {$set: billingAddress ? billingAddress.id : null},
                billing_address: {$set: billingAddress ? billingAddress : {}},
                accounting: {$set: {
                    tax_rate: isNaN(parseFloat(relation.default_tax_rate)) ? 0 : parseFloat(relation.default_tax_rate),
                    payment_term: relation.default_term
                }},
                shipping: {$set: {
                    api: "Manual",
                    courier_id: relation.default_courier,
                    payment: relation.default_shipping_payment,
                    collect_account: {
                        account: relation.default_courier_account,
                        collect_country_code: relation.default_collect_country,
                        collect_zip_code: relation.default_collect_postal
                    }
                }},
                print_note: {$set: relation.default_instruction ? relation.default_instruction : ""},
                note: {$set: relation.print_instruction === 'True' ? relation.default_instruction : ""},
            });

        case SET_SHIPPING_COLLECT_ACCT_TO_MODEL_BY_FORM:
            return update(state, {
                shipping: {
                    collect_account: {[action.payload.field]: {$set: action.payload.value}}
                }
            });

        case UPDATE_ORDER_LINE_BY_FORM:
            return update(state, {
                lines: {
                    [action.payload.index]: {
                        [action.payload.field]: {
                            $set: action.payload.value
                        }
                    }
                }
            });

        case ADD_ORDER_LINE_TO_MODEL_BY_FORM:
            return update(state, {
                lines: {
                    $push: [action.payload]
                }
            });

        case REMOVE_ORDER_LINE_FROM_MODEL_BY_FORM:
            return update(state, {
                lines: {
                    $splice: [[action.payload, 1]]
                }
            });

        case ADD_CATEGORY_TO_ORDER_MODEL: 
            return update(state, {
                categories: {$push: [action.payload.data]}
            });

        case DELETE_CATEGORY_TO_OPRDER_MODEL:
            return update(state, {
                categories: {$splice: [[action.payload.index, 1]]}
            });

        case NEW_SHIPMENT_ADDED_TO_ORDER_MODEL:
            if (action.payload.type === 'Full') {
                return update(state, {
                    status: {$set: 'Processed'},
                    shipments: {$push: [action.payload.data]},
                    order_status: {$set: {
                        order_id: state.id,
                        has_bo: 0,
                        type: state.type,
                        status: 'Processed'
                    }}
                });
            } else {
                return state;
            }
            
        default: return state;
    }
}