import React, { Component } from 'react';
import { bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import PropTypes from 'prop-types'
import {withI18n} from 'react-i18next';
import {Segment, Table, Dimmer, Loader,Input, Icon, Checkbox, Dropdown} from 'semantic-ui-react';
import {Link} from 'react-router-dom';
import SegmentRibbon from '../includes/partials/SegmentRibbon';
import PageBreadCrub from '../includes/partials/PageBreadCrub';
import qs from 'qs';
import update from 'immutability-helper';
import {
    fetchPaginatedList,
    fetchPredefinedRoutes,
    saveShipmentQuery,
    shipmentListLineActionChecked
} from '../../actions/shipment';
import OrderBy from '../includes/tables/OrderBy';
import SearchBar from '../includes/tables/SearchBar';
import Pagination from '../includes/tables/Pagination';
import FilterBy from '../includes/tables/FilterBy';
import LaraQuery from '../includes/tables/QueryConstructor';
import AlertComponent from '../includes/messages/alert';
import Lara from '../../lara';
import LaraPagination from '../../laraPagination';
import ListPrintMenu from './components/ListPrintMenu';
import PrimaryButton from '../includes/buttons/PrimaryButton';
import BatchProcessShipment from './components/BatchProcessShipment';
import ClientNameDisplay from '../includes/partials/ClientNameDisplay';

class ShipmentListPage extends Component {
    constructor() {
        super();
        this.state = {
            breadcrub: [
                {isLink:false, to:null, tag: '', active: true, key: 1}
            ],
            queryId: 'default',
            queryName: "",
            loading: false,
            routingQuery: {

            },
            routingPath: "",
            search: "",
            showQueryBuilder: false,
            fulfillmentType: ""
        }
    }
    
    componentDidUpdate(prevProps, prevState) {
        const {params, url} = this.props.match;
        if (url !== this.state.routingPath) {
            this.setRoutingInState(params, url);
        }
    }

    componentDidMount() {
        this.props.fetchPredefinedRoutes();
        const {params, url} = this.props.match;
        this.setRoutingInState(params,url);
    }

    setRoutingInState = ({query, queryId, type}, url) => {
        const q = qs.parse(query);
        //order_by
        //filter_by
        //query
        if (!_.isObject(q.filter_by)) {
            q.filter_by = {};
        }
        if (!_.isObject(q.order_by)) {
            q.order_by = {};
        }
        if (!_.isArray(q.query)) {
            q.query = [];
        }
        this.setState({
            loading: true,
            queryId: queryId,
            routingQuery: q,
            routingPath: url,
            search: q.search,
            fulfillmentType: type,
            breadcrub: [
                {isLink:false, to:null, tag: this.props.t('Shipment'), active: true, key: 1},
                {isLink:false, to:null, tag: this.props.t(type), active: true, key: 2}
            ]
        }, () => {
            this.fetchRecords(query)
        });
    }

    fetchRecords = () => {
        this.props.fetchPaginatedList(qs.stringify(this.state.routingQuery)).then(res => {
            this.setState({loading: false});
        }).catch(err => {
            this.axiosError(err);
        });
    }

    axiosError = (err) => {
        const errors = Lara.axiosError(err, this.props.t);
        if (errors.http_code === 422) {
            this.setState({
                errors: errors.message,
                loading: false
            });
        } else {
            this.setState({
                loading: false
            });
            Lara.axiosAlert(<AlertComponent 
                support={errors.support}
                message={errors.message}
                reference={errors.reference}
                t={this.props.t}
            />);
        }
        this.setState({loading: false});
    }

    routeToNewQuery = (newRoutingQuery) => {
        this.props.history.push(`/f/${this.state.fulfillmentType}/${this.state.queryId}/${qs.stringify(newRoutingQuery)}`);
    }

    /*
     * For table header filters
    */
    onOrderByHeaderClicked = (routingOrderName, value) => {
        const newRoutingQuery = update(this.state.routingQuery, {
            order_by: {
                [routingOrderName]: {$set: value}
            }
        });
        this.routeToNewQuery(newRoutingQuery);
    }

    /*
     * For pagination
    */
    onPaginationClicked = (e, data) => {
        const {activePage} = data;
        const newRoutingQuery = update(this.state.routingQuery, {
            on_page: {$set: activePage}
        });
        this.routeToNewQuery(newRoutingQuery);
    }

    /*
     * For Search bar: onSearchEnterKeyPressed onSearchChange onPredefinedRouteSelected (optional)
     */
    onSearchEnterKeyPressed = (e) => {
        if (e.key === 'Enter') {
            const defaultQuery = LaraPagination.shipments(this.state.fulfillmentType, true);
            defaultQuery.search = this.state.search;
            defaultQuery.filter_by.is_active="1,0";
            this.setState({
                queryId: 'default'
            }, () => {
                this.routeToNewQuery(defaultQuery);
            });
        }
    }
    onPredefinedRouteSelected = (value) => {
        const {queries} = this.props;
        let query = LaraPagination.shipments(this.state.fulfillmentType, true);
        if (value !== 'default') {
            const queryIndex = _.findIndex(queries, i => {
                return i.value === value;
            });
            if (queryIndex > 0) {
                query = queries[queryIndex].query;
            } 
        }
        this.setState({
            queryId: value
        }, () => {
            this.routeToNewQuery(query);
        });
    }

    //New Sep 12, 2019 - add pagination per page selector
    onPerPageChanged = (newPerPage) => {
        const query = update(this.state.routingQuery, {per_page: {$set: newPerPage}});
        this.routeToNewQuery(query);
    }

    /*
     * Advance Filter Section
     */
    onFilterSelectChange = (field, value) => {
        this.setState({
            routingQuery: update(this.state.routingQuery, {
                filter_by: {
                    [field]: {$set: value.join()}
                }
            })
        });
    }
    onQueryConstructorChange = (property, index, value, isRelation) => {
        if (!isRelation) {
            this.setState({
                routingQuery: update(this.state.routingQuery, {
                    query: {
                        [index]: {
                            [property]: {$set: value}
                        }
                    }
                })
            });
        } else {
            this.setState({
                routingQuery: update(this.state.routingQuery, {
                    query: {
                        [index]: {
                            relation: {$set: value === 'client_id' || value === 'reference' || value === 'po' ? 'order' : null},
                            [property]: {$set: value}
                        }
                    }
                })
            });
        }
    }
    addNewQuery = () => {
        this.setState({
            routingQuery: update(this.state.routingQuery, {
                query: {$push: [{
                    relation: 'order',
                    field: "reference",
                    operator: "=",
                    value: "",
                    join: "AND"
                }]}
            })
        });
    }
    onRemoveQueryClick = (index) => {
        this.setState({
            routingQuery: update(this.state.routingQuery, {
                query: {$splice: [[index, 1]]}
            })
        });
    }

    //New in Lara (Sep 12, 2019)
    onRecordPerPageChange = (perPage) => {
        const newRoutingQuery = update(this.state.routingQuery, {
            per_page: {$set: perPage}
        });
        this.routeToNewQuery(newRoutingQuery);
    }

    runFilter = () => {
        this.routeToNewQuery(this.state.routingQuery);
    }
    saveNewFilter = () => {
        if (this.state.queryName.length <= 0) {
            return null;
        }
        this.props.saveShipmentQuery(this.state.queryName, this.state.routingQuery).then(res => {
            this.setState({
                queryId: _.toString(res.data.data.id),
                queryName: ""
            });
        }).catch(err => {
            this.axiosError(err);
        });
    }

    onLineActionChecked = ({checked}, index) => {
        this.props.shipmentListLineActionChecked(checked, index);
    }

    getListItemSelected = () => {
        const selected = [];
        _.each(this.props.list.records, r => {
            if (r.action) {
                selected.push(r.id);
            }
        });
        return selected;
    }

    shorttenCourierName = (name) => {
        if (name.length < 18) {
            return name;
        }
        return name.substring(0,17)+"..."
    }

    //New In Lara (Sep 12, 2019)
    onBatchSelectClicked = (option) => {
        const {records} = this.props.list;
        //None
        //In-Warehouse
        //Ready
        //All

        _.each(records, (r, index) => {
            if (r.status !== 'Finished') {
                if(option === 'None') {
                    if (r.action) {
                        this.props.shipmentListLineActionChecked(false, index);
                    }
                }
                if (option === 'All') {
                    if (!r.action) {
                        this.props.shipmentListLineActionChecked(true, index);
                    }
                }
                if (option === 'In-Warehouse') {
                    if (r.status === 'In Warehouse' && !r.action) {
                        this.props.shipmentListLineActionChecked(true, index);
                    }
                    if (r.status !== 'In Warehouse' && r.action) {
                        this.props.shipmentListLineActionChecked(false, index);
                    }
                }
                if (option === 'Ready') {
                    if (r.status === 'Ready' && !r.action) {
                        this.props.shipmentListLineActionChecked(true, index);
                    }
                    if (r.status !== 'Ready' && r.action) {
                        this.props.shipmentListLineActionChecked(false, index);
                    }
                }
            }
        })
    
    }



    render() {
        const {routingQuery, search, showQueryBuilder, queryName} = this.state;
        const {t, list, queries, clients} = this.props;
        const {on_page, per_page, total} = list;
        const orderBy = _.isObject(routingQuery.order_by) ? routingQuery.order_by : {};
        const filterBy = _.isObject(routingQuery.filter_by) ? routingQuery.filter_by : {
            type: "",
            is_active: ""
        };
        const routeQuries = _.isArray(routingQuery.query) ? routingQuery.query : [];
        const queriableField = LaraPagination.shipmentQueriable(t);
        return (
            <div>
                {/* Breadcrub Row */}
                <div className="row">
                    <div className="col-md-12 lara-breadcrub">
                        <PageBreadCrub lists={this.state.breadcrub} t={t}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <Segment>
                            <SegmentRibbon label={`${t('Shipment')} ${t('Search')}`} Buttons={
                                () => {return (<React.Fragment>
                                    <PrimaryButton
                                        size='mini'
                                        float='right'
                                        label={t('Filter')}
                                        onClick={() => {this.setState({
                                            showQueryBuilder: !this.state.showQueryBuilder
                                        })}}
                                        loading={false}
                                    />
                                </React.Fragment>)}
                            }/>
                            <SearchBar
                                placeholder={`${t('Search by')} ID, reference or PO...`}
                                onSearchEnterKeyPressed={(e) => {this.onSearchEnterKeyPressed(e)}}
                                search={search}
                                onSearchChange={(search) => {this.setState({search})}}
                                onPredefinedRouteSelected={(value) => this.onPredefinedRouteSelected(value)}
                                options={queries}
                                query={this.state.queryId}
                                havePreferenceSelect={true}
                                hasPerPageSelector={routingQuery.per_page}
                                onPerPageSelcted={(perPage) => {
                                    this.onPerPageChanged(perPage);
                                }}
                            />
                        </Segment>
                        {showQueryBuilder ? <Segment>
                        <SegmentRibbon label={`${t('Advanced Filter')} (Beta)`}/>
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-xs-12">
                                <div className="row">
                                    <FilterBy
                                        t={t}
                                        label={'Status'}
                                        options={[
                                            {value: 'In Warehouse', text: 'In Warehouse'},
                                            {value: 'Ready', text: 'Ready'},
                                            {value: 'Finished', text: 'Finished'}
                                        ]}
                                        name="status"
                                        currentValueString={filterBy.status}
                                        onFilterSelectChange={(name, value) => {this.onFilterSelectChange(name, value)}}
                                    />
                                    <FilterBy
                                        t={t}
                                        label={'Billed'}
                                        options={[
                                            {value: '0', text: 'No'},
                                            {value: '1', text: 'Yes'},
                                        ]}
                                        name="billed"
                                        currentValueString={filterBy.billed}
                                        onFilterSelectChange={(name, value) => {this.onFilterSelectChange(name, value)}}
                                    />
                                </div>
                                <div className="lara-filter-name">{t('Conditions')}</div>
                                {routeQuries.map((q, index) => {
                                    return (
                                        <LaraQuery 
                                            fields={queriableField}
                                            query={q}
                                            index={index}
                                            onQueryConstructorChange={(property, index, value) => {
                                                this.onQueryConstructorChange(property, index, value, false)
                                            }}
                                            onQueryConstructorRelationalChange={(property, index, value) => {
                                                this.onQueryConstructorChange(property, index, value, true)
                                            }}
                                            key={index}
                                            onRemoveQueryClick={() => {this.onRemoveQueryClick(index)}}
                                            clients={clients}
                                            couriers={this.props.courierList}
                                        />
                                    )
                                })}
                                <div className="row">
                                    <div className="col-md-4 col-sm-6 col-xs-12 lara-row-margin">
                                        <PrimaryButton
                                            size='mini'
                                            float='left'
                                            label={t('Conditions')}
                                            onClick={() => {
                                                this.addNewQuery()
                                            }}
                                            loading={this.state.loading}
                                        />
                                        <PrimaryButton
                                            size='mini'
                                            float='left'
                                            label={t('Run Filter')}
                                            onClick={() => {
                                                this.runFilter()
                                            }}
                                            loading={this.state.loading}
                                        />
                                    </div>

                                    <div className="col-md-4 col-sm-6 col-xs-12 lara-row-margin">
                                        
                                    </div>
                                    <div className="col-md-4 col-sm-6 col-xs-12 lara-row-margin">
                                        <div className="row">
                                            <div className="col-md-8 col-sm-9 col-xs-12">
                                                <Input fluid 
                                                    size='mini'
                                                    disabled={this.state.loading}
                                                    placeholder='Enter a name and save this filter'
                                                    onChange={(e, {value}) => {this.setState({
                                                        queryName: value
                                                    })}} value={queryName} />
                                            </div>
                                            <div className="col-md-4 col-sm-3 col-xs-12">
                                                <PrimaryButton
                                                    size='mini'
                                                    float='right'
                                                    label={t('Save')}
                                                    onClick={() => {
                                                        this.saveNewFilter()
                                                    }}
                                                    loading={this.state.loading}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </Segment> : null}

                        <Segment>
                            <Dimmer inverted active={this.state.loading}>
                                <Loader />
                            </Dimmer>
                            <SegmentRibbon label={`${t('Shipment')} ${t('List')}`} Buttons={
                                () => {return (<React.Fragment>
                                    {this.props.build > 1053 ? <div style={{float:'right', marginLeft: 10}}>
                                        <BatchProcessShipment 
                                            onProcessCompleted={() => {
                                                this.fetchRecords()
                                            }}
                                            t={this.props.t}
                                        />
                                    </div> : null}
                                    <div style={{float:'right', paddingTop: 5}}>
                                        <ListPrintMenu
                                            fulfillmentType={this.state.fulfillmentType}
                                            getListItemSelected={() => {
                                                return this.getListItemSelected()
                                            }}
                                            shipmentReport={false}
                                            reFethcRcords={() => {
                                                this.fetchRecords()
                                            }}
                                            adminList={true}
                                            modelId={null}
                                            isLp={false}
                                        />
                                    </div>
                                </React.Fragment>)}
                            }/>
                            <div className="row lara-row-margin">
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <Table
                                        compact
                                        basic
                                        padded={false}
                                    >
                                        <Table.Header>
                                            <Table.Row>                                                
                                                <Table.HeaderCell>
                                                    <Dropdown
                                                        inline
                                                        header="Select in this page"
                                                        options={
                                                            [
                                                                {text: 'None', value: 'None'},
                                                                {text: 'In-WH', value: 'In-Warehouse'},
                                                                {text: 'Ready', value: 'Ready'},
                                                                {text: 'All', value: 'All'}
                                                            ]
                                                        }
                                                        defaultValue='None'
                                                        onChange={(e,{value}) => {
                                                            this.onBatchSelectClicked(value);
                                                        }}
                                                    />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    <OrderBy
                                                        t={t}
                                                        name='ID'
                                                        routingOrderName='id'
                                                        currentOrder={orderBy.id}
                                                        onOrderClick={(routingOrderName, value) => {this.onOrderByHeaderClicked(routingOrderName, value)}}
                                                    />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    {t('Reference')}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    {t('Client')}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    {t('Courier')}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    {t('Status')}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    <OrderBy
                                                        t={t}
                                                        name='ShipDate'
                                                        routingOrderName='ship_date'
                                                        currentOrder={orderBy.ship_date}
                                                        onOrderClick={(routingOrderName, value) => {this.onOrderByHeaderClicked(routingOrderName, value)}}
                                                    />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    <OrderBy
                                                        t={t}
                                                        name='PrintCount'
                                                        routingOrderName='print_count'
                                                        currentOrder={orderBy.print_count}
                                                        onOrderClick={(routingOrderName, value) => {this.onOrderByHeaderClicked(routingOrderName, value)}}
                                                    />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    <OrderBy
                                                        t={t}
                                                        name='ShipStation'
                                                        routingOrderName='ss_order_id'
                                                        currentOrder={orderBy.ss_order_id}
                                                        onOrderClick={(routingOrderName, value) => {this.onOrderByHeaderClicked(routingOrderName, value)}}
                                                    />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    {t('Destination')}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    <OrderBy
                                                        t={t}
                                                        name='CreatedAt'
                                                        routingOrderName='created_at'
                                                        currentOrder={orderBy.created_at}
                                                        onOrderClick={(routingOrderName, value) => {this.onOrderByHeaderClicked(routingOrderName, value)}}
                                                    />
                                                </Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>

                                        <Table.Body>
                                            {list.records.map((i,index) => {
                                                const companyName = i.order ? i.order.client ? i.order.client.company : "" : "";
                                                return (<Table.Row key={i.id}>
                                                    <Table.Cell collapsing>
                                                        {i.status === 'In Warehouse' || i.status === 'Ready' ? <Checkbox slider 
                                                            disabled={i.status === 'Cancelled' || (i.status === 'Processed' && !i.bo) || i.status === 'Finished'}
                                                            checked={i.action}
                                                            onChange={(e,data) => {
                                                                this.onLineActionChecked(data, index);
                                                            }}
                                                        /> : null}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        <Link to={{
                                                            pathname: `/f/${this.state.fulfillmentType}/${i.id}`,
                                                            backTo: this.state.routingPath
                                                        }}>
                                                            {i.id}
                                                        </Link>
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {i.fba_shipment_id && !i.exist_in_fba ? <Icon name='amazon' color='red' /> : null}
                                                        {i.fba_shipment_id && i.exist_in_fba ? <Icon name='amazon' color='green' /> : null}
                                                        {i.fba_shipment_id ? i.fba_shipment_id : i.order ? i.order.reference : 'NA'}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        <ClientNameDisplay 
                                                            tooltipId={`client-tooltip-${i.id}`}
                                                            companyName={companyName}
                                                        />
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {i.courier ? this.shorttenCourierName(i.courier.name) : null}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {i.status}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {i.ship_date}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {i.print_count}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {i.ss_order_id ? <Icon name="check" color='green' /> : null}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {_.isObject(i.shipping_addr) ? i.shipping_addr.country_code: ""}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {moment(i.created_at).format('MMM DD, Y').toString()}
                                                    </Table.Cell>
                                                </Table.Row>)
                                            })}
                                        </Table.Body>
                                    </Table>
                                </div>
                            </div>
                        
                            <div className="row lara-row-margin">
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <Pagination 
                                        activePage={on_page}
                                        totalRecords={total}
                                        perPage={per_page}
                                        onPageChange={(e, data) => {
                                            this.onPaginationClicked(e,data);
                                        }}
                                    />
                                </div>
                            </div>
                        </Segment>
                    </div>
                </div>
            </div>
        )
    }
}

ShipmentListPage.propTypes = {
    t: PropTypes.func.isRequired,
    history: PropTypes.shape({
       push: PropTypes.func.isRequired
    }).isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.shape({
        params: PropTypes.object.isRequired,
        path: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired
    }),
    queries: PropTypes.array.isRequired,
    fetchPaginatedList: PropTypes.func.isRequired,
    fetchPredefinedRoutes: PropTypes.func.isRequired,
    list: PropTypes.object.isRequired,
    saveShipmentQuery: PropTypes.func.isRequired,
    shipmentListLineActionChecked: PropTypes.func.isRequired,
    clients: PropTypes.array.isRequired,
    courierList: PropTypes.array.isRequired,
    build: PropTypes.number.isRequired
};

function mapStateToProps(state) {
    return {
        queries: state.shipmentList.queries,
        list: state.shipmentList.list,
        clients: state.system.clients,
        courierList: state.system.resources.couriers,
        build: state.system.build
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchPaginatedList,
        fetchPredefinedRoutes,
        saveShipmentQuery,
        shipmentListLineActionChecked
    }, dispatch)
}

export default withI18n()(connect(mapStateToProps, mapDispatchToProps)(ShipmentListPage));