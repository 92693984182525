import update from 'immutability-helper';
import {
    SET_RELATION_MODEL_FROM_SERVER,
    RELATION_UPDATED_BY_FORM,
    ADD_ADDRESS_TO_RELATION,
    UPDATE_ADDRESS_TO_RELATION,
    DELETE_ADDRESS_TO_RELATION,
} from '../../actions/types';

export default function relationModel(state = {
    id: null,
    addresses: []
}, action = {}) {
    switch(action.type) {
        case SET_RELATION_MODEL_FROM_SERVER:
            return action.payload;

        case RELATION_UPDATED_BY_FORM:
            return update(state, {
                [action.payload.field]: {$set: action.payload.value}
            });

        case ADD_ADDRESS_TO_RELATION:
            return update(state, {
                addresses: {$push: [action.payload]}
            });

        case UPDATE_ADDRESS_TO_RELATION: 
            return update(state, {
                addresses: {
                    [action.payload.index]: {$set: action.payload.addr}
                }
            });

        case DELETE_ADDRESS_TO_RELATION:
            return update(state, {
                addresses: {$splice: [[action.payload, 1]]}
            });

        default: return state;
    }
}