import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from "lodash";
import {
    Segment
} from "semantic-ui-react";
import SegmentRibbon from "../../includes/partials/SegmentRibbon";
import PrimaryButton from "../../includes/buttons/PrimaryButton";
import {api} from "../../../api";

class ExternalResourceWebhook extends Component {
    constructor(props) {
        super(props);
        this.state = {
            webhook: null
        }
    }

    componentDidMount() {
        const {cid} = this.props;
        api.clients.externalResourceWebhook(cid, 101).then(res => {
            if (!_.isEmpty(res.data.data)) {
                this.setState({
                    webhook: res.data.data
                })
            }
        })
    }

    goToExternalResource = () => {
        let {endpoint} = this.state.webhook;
        const {cid, sid, oid} = this.props;
        if (endpoint.includes("{CID}")) {
            endpoint = endpoint.replace("{CID}", cid);
        }
        if (endpoint.includes("{SID}")) {
            endpoint = endpoint.replace("{SID}", sid);
        }
        if (endpoint.includes("{OID}")) {
            endpoint = endpoint.replace("{OID}", oid);
        }
        window.open(endpoint);
    }
    //access router
    //this.props.history.push('/dashboard')
    
    render() {
        return this.state.webhook ? <Segment>
            <SegmentRibbon label="External Resource" Buttons={() => {
                return <PrimaryButton
                    size='mini'
                    float='right'
                    label="Go"
                    loading={false}
                    onClick={() => {
                        this.goToExternalResource();
                    }}
                />
            }} />
        </Segment> : null
    }
}

ExternalResourceWebhook.propTypes = {
    cid: PropTypes.number.isRequired,
    sid: PropTypes.number.isRequired,
    oid: PropTypes.number.isRequired
};

export default ExternalResourceWebhook