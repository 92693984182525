import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import {List} from 'semantic-ui-react';

class RelationDisplay extends React.PureComponent {
    render() {
        const {tooltipId, label, relation, source} = this.props;
        let displayName = relation.name;
        if (relation.name === '__CustomerByWebService') {
            displayName = source;
        }
        if (relation.name === '__InventoryAdjustment') {
            displayName = source;
        }
        
        return (
            <div className="lara-text-wrapper">
                <span className='lara-text-label'>
                    {label}
                </span>
                <span 
                    className='lara-text-value lara-clickable lara-tooltip-title'
                    data-tip data-for={tooltipId}    
                >{displayName}</span>
                <ReactTooltip id={tooltipId} place="bottom" type="dark" effect="solid">
                    <div className='lara-tooltip-list-holder'>
                        <List>
                            <List.Item>
                                <List.Icon name='user' />
                                <List.Content>{displayName}</List.Content>
                            </List.Item>
                            <List.Item>
                                <List.Icon name='id badge outline' />
                                <List.Content>{relation.account}</List.Content>
                            </List.Item>
                            <List.Item>
                                <List.Icon name='mail' />
                                <List.Content>
                                    {relation.email}
                                </List.Content>
                            </List.Item>
                            <List.Item>
                                <List.Icon name='phone volume' />
                                <List.Content>
                                    {relation.phone}
                                </List.Content>
                            </List.Item>
                        </List>
                    </div>
                </ReactTooltip>
            </div>
        )
    }
}

RelationDisplay.propTypes = {
    tooltipId: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    relation: PropTypes.object.isRequired,
    source: PropTypes.string.isRequired
};

export default RelationDisplay