import update from 'immutability-helper';
import {
    SET_CLIENT_PAGINATED_LIST
} from '../../actions/types';

export default function clients(state = {
    list: {
        total: 0,
        per_page: 15,
        on_page: 1,
        records: [

        ]
    }
}, action = {}) {
    switch(action.type) {
        case SET_CLIENT_PAGINATED_LIST:
            return update(state, {
                list: {$set: action.payload}
            })

        default: return state;
    }
}