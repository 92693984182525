import React from 'react';
import PropTypes from 'prop-types';
import {List, Label} from 'semantic-ui-react';

class OrderShipmentList extends React.PureComponent {
    onShipmentLinkClick = (id) => {
        this.props.history.push(`/orders/${this.props.orderId}/shipment/${id}/view`);
    }

    render() {
        return (
            <List divided relaxed>
                {this.props.shipments.map(i => {
                    return <List.Item key={i.id}>
                        <List.Icon name='truck' size='large' verticalAlign='middle' />
                        <List.Content>
                            <List.Header as='a' onClick={() => {
                                this.onShipmentLinkClick(i.id)
                            }}>
                                {i.fba_shipment_id ? i.fba_shipment_id : i.id}
                            </List.Header>
                            <List.Description as='a' onClick={() => {
                                this.onShipmentLinkClick(i.id)
                            }}>
                                <Label size='mini' color='teal'>{this.props.t(i.status)}</Label>
                            </List.Description>
                        </List.Content>
                    </List.Item>
                })}
            </List>
        )
    }
}

OrderShipmentList.propTypes = {
    history: PropTypes.shape({
       push: PropTypes.func.isRequired
    }).isRequired,
    t: PropTypes.func.isRequired,
    shipments: PropTypes.array.isRequired,
    orderId: PropTypes.number.isRequired
};

export default OrderShipmentList