import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import PropTypes from 'prop-types';

const styles = StyleSheet.create({
    page: {
        padding: 25,
        
    },
    container: {
        flex: 1,
        flexDirection: 'column'
    },
    headerRow: {
        fontSize: 11,
        flexDirection: 'row',
        borderTopWidth: .5,
        borderTopColor: 'grey',
        borderTopStyle: 'solid',
        borderBottomWidth: .5,
        borderBottomColor: 'grey',
        borderBottomStyle: 'solid',
        paddingTop: 4,
        paddingBottom: 1
    },
    itemRow: {
        fontSize: 10,
        flexDirection: 'row',
        borderBottomWidth: .5,
        borderBottomColor: 'grey',
        borderBottomStyle: 'solid',
        paddingTop: 5,
        paddingBottom: 5,
        display: 'flex'
    },
    oneCell: {
        flex: 1
    },
    twoCell: {
        flex: 2
    },
    threeCell: {
        flex: 3
    },
    sixCell: {
        flex: 6
    },
});

class InventoryListPDFComponent extends React.PureComponent {
    render() {
        return (
            <Document
                title="Invoice"
            >
                <Page size='A4' style={styles.page} orientation='landscape'>
                    <View style={{alignContent: 'flex-start'}}>
                        <View style={styles.headerRow}>
                            <Text style={styles.oneCell}>
                                Type
                            </Text>
                            <Text style={styles.threeCell}>
                                SKU
                            </Text>
                            <Text style={styles.sixCell}>
                                Name
                            </Text>
                            <Text style={styles.twoCell}>
                                UPC
                            </Text>
                            <Text style={styles.oneCell}>
                                Total
                            </Text>
                            <Text style={styles.oneCell}>
                                Reserve
                            </Text>
                            <Text style={styles.oneCell}>
                                Available
                            </Text>
                        </View>
                        {this.props.list.map(i => {
                            return (<View style={styles.itemRow} key={i.id}>
                                <Text style={styles.oneCell}>
                                    {i.type}
                                </Text>
                                <Text style={styles.threeCell}>
                                    {i.sku}
                                </Text>
                                <Text style={styles.sixCell}>
                                    {i.name}
                                </Text>
                                <Text style={styles.twoCell}>
                                    {i.upc}
                                </Text>
                                <Text style={styles.oneCell}>
                                    {i.type === 'Item' ? i.in_stock : 'NA'}
                                </Text>
                                <Text style={styles.oneCell}>
                                    {i.type === 'Item' ? i.reserved : 'NA'}
                                </Text>
                                <Text style={styles.oneCell}>
                                    {i.type === 'Item' ? i.available : 'NA'}
                                </Text>
                            </View>)
                        })}
                    </View>
                </Page>
            </Document>
        )
    }
}

InventoryListPDFComponent.propTypes = {
    list: PropTypes.array.isRequired
};

export default InventoryListPDFComponent