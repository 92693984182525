import React from 'react';
import { bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import {Button} from 'semantic-ui-react';
import PropTypes from 'prop-types';

class SecondaryButton extends React.PureComponent {
    //access router
    //this.props.history.push('/dashboard')
    
    render() {
        const {size, float, label, preference, onClick, loading} = this.props;
        return (
            <Button
                size={size}
                disabled={loading}
                loading={loading}
                color={preference.t_secondary_btn ? preference.t_secondary_btn : 'purple'}
                floated={float ? float : 'left'}
                onClick={() => {
                    onClick()
                }}
            >{label}</Button>
        )
    }
}

SecondaryButton.propTypes = {
    size: PropTypes.string.isRequired,
    float: PropTypes.any,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
    return {
        preference: state.system.preference
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SecondaryButton)