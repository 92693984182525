import React, { Component } from 'react';
import { bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import {withI18n} from 'react-i18next';
import PropTypes from 'prop-types';
import {Segment, Loader, Dimmer} from 'semantic-ui-react';
import {
    fetchActivityById
} from '../../../actions/billing';
import PageBreadCrub from '../../includes/partials/PageBreadCrub';
import AlertComponent from '../../includes/messages/alert';
import SegmentRibbon from '../../includes/partials/SegmentRibbon';
import ResourceTextPropertyView from '../../includes/partials/ResourceTextPropertyView';
import ResourceBooleanPropertyView from '../../includes/partials/ResourceBooleanPropertyView';
import Lara from '../../../lara';
import LaraPagination from '../../../laraPagination';
import ActivityItemTable from './ActivityItemPivotTable';
import PrimaryNaviButton from '../../includes/buttons/PrimaryNaviButton';

//Lara Button Updated
class ActivityViewPage extends Component {
    constructor() {
        super();
        this.state = {
            breadcrub: [
                {isLink:false, to:null, tag: '', active: true, key: 1}
            ],
            loading: false
        }
    }

    componentDidMount() {
        const {location, match} = this.props;
        const {id} = match.params;
        this.setState({
            loading: true
        });
        this.props.fetchActivityById(id).then(res => {
            this.setState({
                loading: false,
                breadcrub: this.getBreadCrub(location.backTo, res.name)
            })
        }).catch(err => {
            const errors = Lara.axiosError(err, this.props.t);
            if (errors.http_code === 422) {
                this.setState({
                    errors: errors.message,
                    loading: false
                });
            } else {
                this.setState({
                    loading: false
                });
                Lara.axiosAlert(<AlertComponent 
                    support={errors.support}
                    message={errors.message}
                    reference={errors.reference}
                    t={this.props.t}
                />);
            }
            this.setState({loading: false});
        })
    }

    getCourierName = () => {
        const {model, couriers} = this.props;
        const courier = Lara.findFromResourceArray(model.default_courier, 'id', couriers, true);
        return courier ? courier.name : ""
    }

    getBreadCrub = (backTo, name) => {
        if (!backTo) {
            backTo = `/billing/activities/${LaraPagination.billing.activities(false)}`;
        }
        return [
            {isLink:true, to: backTo, tag: 'Activity', active: false, key: 1},
            {isLink:false, to:null, tag: name, active: true, key: 2}
        ]
    }

    getClientName = () => {
        const {model, clients} = this.props;
        const clientIndex = _.findIndex(clients, c => {
            return _.toInteger(c.id, 10) === _.toInteger(model.client_id, 10);
        })
        if (clientIndex > -1) {
            return clients[clientIndex].company
        }
        return "NA"
    }
    
    render() {
        const {t, model} = this.props;
        return (
            <div>
                {/* Breadcrub Row */}
                <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12 lara-breadcrub">
                        <PageBreadCrub lists={this.state.breadcrub} t={t}/>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-8 col-sm-8 col-xs-12">
                        <Segment>
                            <Dimmer inverted active={this.state.loading}>
                                <Loader />
                            </Dimmer>
                            <SegmentRibbon label={model.name ? model.name : "Loading..."} Buttons={
                                () => {return (<React.Fragment>
                                    <PrimaryNaviButton
                                        link={`/billing/activities/${model.id}/action/edit`}
                                        size='mini'
                                        float='right'
                                        loading={false}
                                        label={t('Edit')}
                                    />
                                </React.Fragment>)}
                            }/>
                            <div className="row">
                                <div className="col-md-3 col-sm-6 col-xs-12">
                                    <ResourceTextPropertyView
                                        label="Name"
                                        value={model.name} />
                                </div>
                                <div className="col-md-3 col-sm-6 col-xs-12">
                                    <ResourceTextPropertyView
                                        label="ChargeType"
                                        value={model.charge} />
                                </div>
                                <div className="col-md-3 col-sm-6 col-xs-12">
                                    <ResourceTextPropertyView
                                        label="Client"
                                        value={this.getClientName()} />
                                </div>
                                <div className="col-md-3 col-sm-6 col-xs-12">
                                    <ResourceBooleanPropertyView
                                        label="Status"
                                        value={model.is_active}
                                    />
                                </div>
                            </div>
                        </Segment>
                        {model.id ? <ActivityItemTable activityType={model.charge} t={t}/> : null}
                    </div>
                </div>

                
            </div>
        )
    }
}

ActivityViewPage.propTypes = {
    fetchActivityById: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    history: PropTypes.shape({
       push: PropTypes.func.isRequired
    }).isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired
        }),
        path: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired
    }),
    clients: PropTypes.array.isRequired
};

function mapStateToProps(state) {
    return {
        model: state.billingActivityModel,
        clients: state.system.clients
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchActivityById,
    }, dispatch)
}

export default withI18n()(connect(mapStateToProps, mapDispatchToProps)(ActivityViewPage));