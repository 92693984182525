import update from 'immutability-helper';
import {
    SET_USER_MODEL_FROM_SERVER,
    USER_UPDATED_BY_FORM,
    USER_MODEL_UPDATED_BY_FORM
} from '../../actions/types';

export default function userModel(state = {
    id: null
}, action = {}) {
    switch(action.type) {
        case SET_USER_MODEL_FROM_SERVER:
            return action.payload;

        case USER_UPDATED_BY_FORM:
            return update(state, {
                [action.payload.field]: {$set: action.payload.value}
            });

        case USER_MODEL_UPDATED_BY_FORM:
            return update(state, {
                [action.payload.field]: {$set: action.payload.value}
            })

        default: return state;
    }
}