import React, { Component } from 'react';
import { bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {withI18n} from 'react-i18next';
import {List, Segment} from 'semantic-ui-react';
import PageBreadCrub from '../includes/partials/PageBreadCrub';
import CourierComponent from './setting_components/CourierComponent';
import PackageComponent from './setting_components/PackageComponent';
import TaxComponent from './setting_components/TaxComponent';
import TermComponent from './setting_components/TermComponent';
import UomComponent from './setting_components/UomComponent';

class ConfigurationViewPage extends Component {
    //access router
    //this.props.history.push('/dashboard')
    constructor() {
        super();
        this.state = {
            breadcrub: [
                {isLink:false, to:null, tag: 'Configuration', active: false, key: 1}
            ],
            url: "",
            resource: ""
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const {params, url} = this.props.match;
        if (url !== this.state.url) {
            this.setRoutingInState(params, url);
        }
    }

    componentDidMount() {
        const {params, url} = this.props.match;
        this.setRoutingInState(params,url);
    }

    setRoutingInState = ({resource}, url) => {
        this.setState({
            resource: resource,
            url: url
        });
    }

    routeToComponent = (component) => {
        this.props.history.push(`/configurations/${component}`);
    }
    
    render() {
        const {t} = this.props;
        const {resource} = this.state;
        return (
            <div>
                {/* Breadcrub Row */}
                <div className="row">
                    <div className="col-md-12 lara-breadcrub">
                        <PageBreadCrub lists={this.state.breadcrub} t={t}/>
                    </div>
                </div>
                <Segment>
                <div className="row">
                    <div className="col-md-3 col-sm-5 col-xs-12">
                        <List divided relaxed>
                            <List.Item>
                                {resource === 'couriers' ? <List.Icon name='folder open' size='large' verticalAlign='middle' /> : 
                                    <List.Icon name='folder outline' size='large' verticalAlign='middle' />}
                                <List.Content>
                                    <List.Header as='a' onClick={() => {
                                        this.routeToComponent('couriers')
                                    }}>
                                        {t('Courier')}
                                    </List.Header>
                                </List.Content>
                            </List.Item>
                            <List.Item>
                                {resource === 'taxes' ? <List.Icon name='folder open' size='large' verticalAlign='middle' /> : 
                                    <List.Icon name='folder outline' size='large' verticalAlign='middle' />}
                                <List.Content>
                                    <List.Header as='a' onClick={() => {
                                        this.routeToComponent('taxes')
                                    }}>
                                        {t('TaxRate')}
                                    </List.Header>
                                </List.Content>
                            </List.Item>
                            <List.Item>
                                {resource === 'terms' ? <List.Icon name='folder open' size='large' verticalAlign='middle' /> : 
                                    <List.Icon name='folder outline' size='large' verticalAlign='middle' />}
                                <List.Content>
                                    <List.Header as='a' onClick={() => {
                                        this.routeToComponent('terms')
                                    }}>
                                        {t('PaymentTerm')}
                                    </List.Header>
                                </List.Content>
                            </List.Item>
                            <List.Item>
                                {resource === 'packages' ? <List.Icon name='folder open' size='large' verticalAlign='middle' /> : 
                                    <List.Icon name='folder outline' size='large' verticalAlign='middle' />}
                                <List.Content>
                                    <List.Header as='a' onClick={() => {
                                        this.routeToComponent('packages')
                                    }}>
                                        {t('Package')}
                                    </List.Header>
                                </List.Content>
                            </List.Item>
                            <List.Item>
                                {resource === 'uoms' ? <List.Icon name='folder open' size='large' verticalAlign='middle' /> : 
                                    <List.Icon name='folder outline' size='large' verticalAlign='middle' />}
                                <List.Content>
                                    <List.Header as='a' onClick={() => {
                                        this.routeToComponent('uoms')
                                    }}>
                                        {t('UOM')}
                                    </List.Header>
                                </List.Content>
                            </List.Item>
                        </List>
                    </div>
                    <div className="col-md-9 col-sm-7 col-xs-12">
                        {resource === 'couriers' ? <CourierComponent /> : null}
                        {resource === 'terms' ? <TermComponent /> : null}
                        {resource === 'taxes' ? <TaxComponent /> : null}
                        {resource === 'packages' ? <PackageComponent /> : null}
                        {resource === 'uoms' ? <UomComponent /> : null}
                    </div>
                </div>
                </Segment>
            </div>
        )
    }
}

ConfigurationViewPage.propTypes = {
    t: PropTypes.func.isRequired,
    match: PropTypes.shape({
        params: PropTypes.object.isRequired,
        url: PropTypes.string.isRequired
    })
    //history: PropTypes.shape({
    //    push: PropTypes.func.isRequired
    //}).isRequired,
    
};

function mapStateToProps(state) {
    return {
        
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch)
}

export default withI18n()(connect(mapStateToProps, mapDispatchToProps)(ConfigurationViewPage));