import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {withI18n} from 'react-i18next';

const ResourceTextPropertyView = ({t, label, value, propertyKey}) => (
    <div className="lara-text-wrapper">
        <span className='lara-text-label'>{t(label)}:</span>
        {_.isArray(value) && value.map((item,key) => {
            return <span className='lara-text-value lara-inline-span' key={key}>
                {propertyKey ? item[propertyKey] : item}
            </span>
        })}
    </div>
)

ResourceTextPropertyView.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.any,
    propertyKey: PropTypes.string
};


export default withI18n()(ResourceTextPropertyView);