//API_MODEL_UPDATED_BY_FORM
import update from 'immutability-helper';
import {
    API_MODEL_UPDATED_BY_FORM,
    SET_API_MODEL
} from '../../actions/types'

export default function inventoryModel(state = {
    id: null
}, action = {}) {
    switch(action.type) {
        case SET_API_MODEL:
            return action.payload;
        
        case API_MODEL_UPDATED_BY_FORM:
            let {field, value, subset} = action.payload;
            if (!subset) {
                return update(state, {
                    [field]: {$set: value}
                });
            } else {
                return update(state, {
                    [subset]: {
                        [field]: {$set: value}
                    }
                });
            }

        default: return state;
    }
}