import React, { Component } from 'react';
import { bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {withI18n} from 'react-i18next';
import _ from 'lodash';
import FormError from '../../includes/messages/422';
import AlertComponent from '../../includes/messages/alert';
import {
    Form
} from  'semantic-ui-react';
import {
    updateItemModelByForm,
    updateItemOnServer
} from '../../../actions/billing';
import Lara from '../../../lara';
import PrimaryButton from '../../includes/buttons/PrimaryButton';

//Lara Button Updated
class ItemInlineForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: [],
            loading: false
        }
    }

    onFormSubmit = () => {
        const errors = this.validateInput();
        if (_.isEmpty(errors)) {
            this.setState({
                loading: true
            });
            this.props.updateItemOnServer(this.props.model, this.props.recordIndex).then(res => {
                this.setState({
                    errors: [],
                    loading: false
                });
                this.props.onModalClose();
            }).catch(err => {
                const errors = Lara.axiosError(err, this.props.t);
                if (errors.http_code === 422) {
                    this.setState({
                        errors: errors.message,
                        loading: false
                    });
                } else {
                    this.setState({
                        loading: false
                    });
                    Lara.axiosAlert(<AlertComponent
                        support={errors.support}
                        message={errors.message}
                        reference={errors.reference}
                        t={this.props.t}
                    />);
                }
            });
        } else {
            this.setState({
                errors: errors
            });
        }
    };

    validateInput = () => {
        const {name} = this.props.model;
        const errors = [];
        if (!name) {
            errors.push(this.props.t('CompanyNameIsRequired'));
        }
        return errors;
    };

    render() {
        const {model, t, updateItemModelByForm} = this.props;
        return (
            <div>
                {!_.isEmpty(this.state.errors) ? (
                    <FormError errors={this.state.errors} />
                ) : null}
                <Form size='mini'>
                    <Form.Group widths={2}>
                        <Form.Input
                            name='name'
                            value={model.name ? model.name : ""}
                            required={true}
                            onChange={(e, {value}) => {
                                updateItemModelByForm('name',value)
                            }}
                            fluid={true}
                            label={t('Name')}
                        />
                        <Form.Input
                            name='uom'
                            value={model.uom ? model.uom : ""}
                            onChange={(e, {value}) => {
                                updateItemModelByForm('uom',value)
                            }}
                            fluid={true}
                            label={t('UOM')}
                        />
                    </Form.Group>
                    <Form.Input
                            name='description'
                            value={model.description ? model.description : ""}
                            onChange={(e, {value}) => {
                                updateItemModelByForm('description',value)
                            }}
                            fluid={true}
                            label={t('Description')}
                        />
                    <Form.Group widths={2}>
                        <Form.Input
                            name='rate'
                            value={model.rate ? model.rate : ""}
                            onChange={(e, {value}) => {
                                updateItemModelByForm('rate',value)
                            }}
                            type='number'
                            fluid={true}
                            label={t('DefaultRate')}
                        />
                        <Form.Input
                            name='tax_code'
                            value={model.tax_code ? model.tax_code : ""}
                            onChange={(e, {value}) => {
                                updateItemModelByForm('tax_code',value)
                            }}
                            fluid={true}
                            label={t('AccountingTaxCode')}
                        />
                        <Form.Input
                            name='accounting_class'
                            value={model.accounting_class ? model.accounting_class : ""}
                            onChange={(e, {value}) => {
                                updateItemModelByForm('accounting_class',value)
                            }}
                            fluid={true}
                            label={t('AccountingClass')}
                        />
                    </Form.Group>
                    <Form.Group widths={3}>
                        <Form.Checkbox
                            label={t('ReportInternal')}
                            name="internal"
                            onChange={(e,data) => { updateItemModelByForm('internal', data.checked) }}
                            checked={model.internal}
                        />
                        <Form.Checkbox
                            label={t('ReportBreakdown')}
                            name="report_breakdown"
                            onChange={(e,data) => { updateItemModelByForm('report_breakdown', data.checked) }}
                            checked={model.report_breakdown}
                        />
                        <Form.Checkbox
                            label={t('Status')}
                            name="is_active"
                            onChange={(e,data) => { updateItemModelByForm('is_active', data.checked) }}
                            checked={model.is_active}
                        />
                    </Form.Group>
                    <PrimaryButton
                        size='mini'
                        float='left'
                        label={t('Save')}
                        onClick={() => {
                            this.onFormSubmit()
                        }}
                        loading={this.state.loading}
                    />
                    <div className="row">
                        <div className="lara-row-margin"></div>
                    </div>
                </Form>
            </div>
        )
    }
}

ItemInlineForm.propTypes = {
    model: PropTypes.object.isRequired,
    updateItemModelByForm: PropTypes.func.isRequired,
    updateItemOnServer: PropTypes.func.isRequired,
    recordIndex: PropTypes.number,
    onModalClose: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        model: state.billingItemModel
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        updateItemModelByForm,
        updateItemOnServer
    }, dispatch)
}

export default withI18n()(connect(mapStateToProps, mapDispatchToProps)(ItemInlineForm))
