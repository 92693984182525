import React, { Component } from 'react';
import _ from 'lodash';
import {Segment, Loader, Dimmer, Select, Message, Input, Form} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import update from 'immutability-helper'
import SegmentRibbon from '../../includes/partials/SegmentRibbon';
import PrimaryButton from "../../includes/buttons/PrimaryButton";
import {api} from "../../../api";
import Lara from "../../../lara";
import AlertComponent from "../../includes/messages/alert";

class CarrierUpdatePanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            current_selected: {
                id: 0
            },
            carrier_options: [],
            carriers: []
        }
    }

    componentDidMount() {
        this.setState({
            loading: true,
            current_selected: this.props.model.courier
        }, () => {
            api.shipments.getAvailableCarrier(this.props.model.id).then(res => {
                const {data} = res.data;
                const carrier_options = [];
                data.map(d => {
                    carrier_options.push({
                        key: d.id,
                        value: d.id,
                        text: d.name
                    })
                });
                this.setState({
                    loading: false,
                    carrier_options: carrier_options,
                    carriers: data
                })
            }).catch(err => {
                this.setState({
                    loading: false,
                    carrier_options: [],
                    carriers: []
                })
            })
        })
    }

    onSelectChange({value}) {
        if (parseInt(value,10) === this.state.current_selected.id) {
            return null;
        }
        const carrierIndex = _.findIndex(this.state.carriers, o => {
            return o.id === parseInt(value,10)
        });
        if (carrierIndex > -1) {
            this.setState({
                current_selected: this.state.carriers[carrierIndex]
            })
        }
    }

    onCollectChange(field, value) {
        this.setState({
            current_selected: update(this.state.current_selected, {
                [field]: {$set: value}
            })
        })
    }

    onSaveClick() {
        this.setState({
            loading: true
        }, () => {
            api.shipments.updateShipmentCarrier(this.props.model.id, this.state.current_selected).then(res => {
                this.props.courierUpdated(this.state.current_selected);
                this.setState({
                    loading: false
                })
            }).catch(err => {
                const errors = Lara.axiosError(err, this.props.t);
                this.setState({
                    loading: false
                });
                Lara.axiosAlert(<AlertComponent
                    support={errors.support}
                    message={errors.message}
                    reference={errors.reference}
                    t={this.props.t}
                />);
            })
        })
    }

    render() {
        return (
            <Segment>
                <SegmentRibbon label="Courier Update" Buttons={() => {
                    return <PrimaryButton
                        size='mini'
                        float='right'
                        label="Update"
                        loading={false}
                        onClick={() => {
                            this.onSaveClick()
                        }}
                    />
                }}/>
                <Dimmer inverted active={this.state.loading}>
                    <Loader />
                </Dimmer>
                <div className="row">
                    <div className="col-md-12">
                        {_.isObject(this.state.current_selected) ? <Form size="mini">
                            <Form.Select
                                label="Courier"
                                size='mini'
                                value={this.state.current_selected.id}
                                fluid
                                onChange={(e, data) => {
                                    this.onSelectChange(data)
                                }}
                                options={this.state.carrier_options} />
                            <Form.Select
                                fluid
                                label="Payment Method"
                                options={[
                                    {value:'prepaid', text: 'Prepaid'},
                                    {value:'collect', text: 'Collect'},
                                    {value:'third_party', text: 'Third Party'}
                                ]}
                                value={this.state.current_selected.payment_method}
                                onChange={(e, {value}) => {
                                    this.onCollectChange('payment_method', value)
                                }}
                            />
                            {this.state.current_selected.payment_method !== 'prepaid' ? <Form.Input
                                fluid
                                label='Account'
                                name="account"
                                value={this.state.current_selected.account ? this.state.current_selected.account : ""}
                                onChange={(e) => { this.onCollectChange(e.target.name, e.target.value) }}
                            /> : null}
                            {this.state.current_selected.payment_method !== 'prepaid' ? <Form.Input
                                fluid
                                label='Billing Country Code'
                                name="billing_country_code"
                                value={this.state.current_selected.billing_country_code ? this.state.current_selected.billing_country_code : ""}
                                onChange={(e) => { this.onCollectChange(e.target.name, e.target.value) }}
                            /> : null}
                            {this.state.current_selected.payment_method !== 'prepaid' ? <Form.Input
                                fluid
                                label='Billing Postal'
                                name="billing_zip"
                                value={this.state.current_selected.billing_zip ? this.state.current_selected.billing_zip : ""}
                                onChange={(e) => { this.onCollectChange(e.target.name, e.target.value) }}
                            /> : null}
                            {this.props.model.ss_order_id ? <Message>
                                <Message.Header>Order In ShipStation</Message.Header>
                                <p>
                                    Shipment already in ShipStation. This update will <strong>not</strong> change the record in ShipStation.
                                </p>
                            </Message> : null}
                            {this.state.current_selected.ss_mapping_carrier && !this.props.model.ss_order_id ? <Message>
                                <Message.Header>Change to ShipStation</Message.Header>
                                <p>
                                    You need to manually push this order to ShipStation.
                                </p>
                            </Message> : null}
                        </Form> : null}
                    </div>
                </div>
            </Segment>
        )
    }
}

CarrierUpdatePanel.propTypes = {
    model: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    courierUpdated: PropTypes.func.isRequired
};

export default CarrierUpdatePanel
