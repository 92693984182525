module.exports = {
    //Languages:
    "English": "英文",
    "SimplifiedChinese": "简体中文",

    //Page
    "3PLLogIn": "系统登陆",
    "ForgetPassword": "忘记密码",
    //Page - Inventories
    "SKU":"商品编号",
    "Type": "商品类别",
    "Name": "名称",
    "UPC": "通用条码",
    "Total": "总数量",
    "Reserve": "保留数量",
    "Available": "可用数量",
    "Description": "描述",
    "Cost": "成本价",
    "Selling": "销售价",
    "MSRP": "建议零售价",
    "Status": "状态",
    "CustomizedValueField": "预留信息",
    "Internationl": "国际运输",
    "Lot": "生产批号控制",
    "Serial": "产品序列号控制",
    "Category": "标签&种类",
    "CategorySelector": "输入一个新的值或者选取一个现存的值",
    "HSCODE": "海关HS编码",
    "CustomValue": "海关申报价值",
    "Origin": "原产地",
    "Composition": "商品组成",
    "Barcode_1": "其他条形码 1",
    "Barcode_2": "其他条形码 2",
    "MoveBetweenLp":"转移数量",
    "AdjustQty": "修改数量",
    "ConvertToPristine": "转化为可配送产品",
    "Disposal": "从库中清除劣质产品",

    //Page - Inventories Tab Menu
    "Location": "仓位",
    "Location-P": "配货仓位",
    "Location-S": "存储仓位",
    "LocationType": "仓位种类（配货或存储）",
    "LocationCategory": "仓位属性（收费用）",
    "InventoryItem": "单独物品",
    "Kits":"产品组合",
    "ItemQty":"单独物品数量",
    "Quarantine":"隔离产品",
    "Pricing": "特殊售价",
    "UOM":"计量单位",
    "UnitOfMeasure": "计量单位",
    "BaseUOM": "基础单位",
    "BaseQty": "基础单位数量",
    "Length": "长",
    "Width": "宽",
    "Height": "高",
    "Weight": "重量",
    "Dim": "体积",
    "Lb": "磅",
    "Kg": "公斤",
    "CM": "厘米",
    "In": "英寸",
    "LotExp": "批号和有效期",
    //Page - Relation
    "Account": "账号",
    "Courier": "运输方式",
    "CourierAccount": "快递账号",
    "CourierBillingPostal": "快递账号所属邮编",
    "CourierBillingCountry": "快递账号所属国家",
    "CourierPaymentMethod": "快递收费",
    "PaymentTerm": "付款方式",
    "TaxRate": "税百分比",
    "Note": "注释",
    "DisplayNote": "在配送过程中显示注释",

    //Page - Addresses
    "Contact": "联系人",
    "StreetAddress": "街道地址",

    //Courier Payment Options
    "Prepaid": "预付费",
    "Collect": "收件人付费",
    "Third": "三方付费",

    //Page - Users:
    "Role": "用户权限",
    "UserName": "用户名",
    "MainUser": "我方用户",

    //Page - Client:
    "Statistics": "统计",
    "Preference": "选项",
    "UseShipStation": "使用ShipStation",
    "ShipStationWarehouse": "ShipStation仓库",
    "InvoicePrefix": "发票前缀",
    "NextInvNum": "下一张发票号码",
    "PacingSlipPrefix": "包装单据前缀",
    "NextPackingSlipNum": "下一张包装单据号码",
    "EmailUponReceiving": "入库电邮通知",
    "EmailUponShipping": "出库电邮通知",
    "ShippingChargeModify": "运费修改参数",
    "LogoUri": "商标网址",
    "AccountingName": "会计系统真实名称",
    "AccountingTerm": "会计系统付款方式",
    "AccountingDueDate": "会计系统到期天数",
    "OrderNotification": "订单通知",
    "ShippingEmails": "入库电邮地址",
    "ReceivingEmails": "入库电邮地址",
    "OrderNotificationEmail": "订单通知电邮选项",
    "WhenOrderCreated": "当订单生产时",
    "WhenOrderProcessed": "当订单确认时",
    "DoNotNotify": "关闭通知",
    "ClientTaxRate": "税率",
    "ClientTaxCode": "税名(收费报告)",

    //Page - General Settings
    "Package": "包裹设置",
    "CourierSSMappingCarrier":"ShipStation快递公司",
    "CourierSSMappingService":"ShipStation快递服务",
    "CourierSSMappingPackage":"ShipStation快递包裹",
    "ShippoID":"Shippo快递公司",
    "ShippoService":"Shippo快递服务",
    "TaxCode": "税",

    //Page - Order
    "Reference": "编号",
    "PO":"订单号",
    "Shipment": "发货",
    "WarehouseNote": "给配送人员备注",
    "DocumentNote": "文件上的备注",
    "CannotModifyRelation":"已有订单无法修改",
    "EssentialInfo": "基础信息",
    "OptionalShippingAccount": "邮寄和会计",
    "OrderLineItems": "订单商品",
    "OrderNotes": "备注",
    "Quantity": "数量",
    "Price": "价格",
    "Stock":"库存",
    "InventorySelectorNote": "最多返回5条信息，请更加详细搜索",
    "AlertCannotEdit":"只可修改未确认订单",
    "AlertGoBack":"返回订单初始页",
    "CancelAlert":"确认取消？",
    "BillTo":"发票地址:",
    "ShipTo":"送货地址:",
    "BillFrom": "开账单地址：",
    "ShipFrom":"发货地址:",
    "OrderLineTotal": "价格",
    "TotalBeforeTax": "税前价",
    "TotalTax": "税",
    "TotalAfterTax": "税后价",
    "Open": "未确认",
    "Cancelled": "取消",
    "BO": "尾单",
    "HasBO": "存在尾单",
    "NoBo":"没有尾单",
    "EnoughStockTOFulfill": "可确认",
    "EnoughStockTOFulfillFilter": "是非可以确认",
    "Yes": "是",
    "No": "否",
    "OrderQty": "订购量",
    "Processed": "确认量",
    "RemainingQty": "剩余量",
    "Fulfilled": "配送完",
    "ToFulfill": "配送中",
    "ShipmentAdditionalInfo": "快递&日期",
    "ShipDate": "发货日期",
    "CancelDate": "取消日期",

    "Comment":"注释",

    //Page - Shipments
    "In Warehouse": "正在分拣",
    "Ready": "发货中",
    "Finished": "已发货",
    "Attachments": "附件",
    "Comments": "注释",
    "CompletedDate": "完成日期",
    "PTNumber": "包装单号",
    "TrackingNumber": "运单查询号",
    "FileComment": "附件备注",
    "InternalNote": "内部信息",
    "PrintOnDoc": "打印备注",
    "Transit": "天数:",
    

    //Page - Billing
    //Items
    "ReportInternal": "内部",
    "AccountingClass": "会计系统类别",
    "AccountingTaxCode": "会计系统税号",
    "ReportBreakdown": "显示全部记录",
    //Activities
    "ChargeType":"类别",
    "Manual": "人工输入",
    "Inbound": "入库收费",
    "Outbound": "出库收费",
    "Month-Begin": "每月开始",
    "Month-End": "每月结尾",
    "Week-Begin": "每周开始",
    "Week-End": "每周结尾",
    "Daily-Cubic-Storage": "存储体积（每日）",
    "Daily-Location-Storage": "仓位（每日）",
    "Shipping-Charge": "预付快递费",
    "DefaultRate": "默认价格",
    "DefaultQuantity": "默认数量",
    "MinimumQuantity": "最少量日",
    "MinimumRate": "低于最少量收费",
    //Entries
    "BillingEntry": "收费记录",
    "BillDate": "收费日期",

    //Page - Integration
    "Partner": "对接系统",
    "Verified": "通过验证",
    "SaveNewInventory": "如果商品不存在，则自动创建",
    "PermanentlyDeleteApi": "是否永久删除？",
    "AutoProcess": "当订单导入之后，尝试确认订单",
    "ManualImport": "手工导入",
    "StoreTimeZone": "对接系统时区",

    //Page - License Plate
    "Putaway": "移动分拣牌",
    "Break": "制作子牌号",
    "StartNumber": "开始号",
    "LpCount":"数量",

    //Page - Serial
    "Lead": "开始号",
    "Increment": "增进量",
    "UnitCount": "数量",
    "AvailableToUse":"可以使用",

    //Page - Fulfillment
    "outbound": "出库",
    "inbound": "入库",
    "PrintCount": "已打印",
    "PT": "拣货单 (普通)",
    "PTC":"拣货单 (FIFO)",
    "MB": "安卓条形码",
    "PS": "包装单",
    "CPS": "包装单 (按箱)",
    "INV": "发票",
    "SCL":"出货箱标签",
    "CI": "国际发票 (1)",
    "SR": "报告",
    "LPL": "分拣牌 (LP) 标签",
    "ShipmentVoidAlert": "是否永久删除?",
    "ShipmentVoidFinishedAlert": "是否永久删除?",
    "MobileLocked": "移动锁定",
    "Billed": "已收费",
    "LotNumber": "生产批号",
    "ExistingLot": "已有生产批号",
    "ExpireDate": "过期日期",
    "SerialLead": "序列号",
    "Waybill": "运单号",
    "Packed": "装箱",
    "Repack": "修改装箱",
    "bill": "收费",
    "ShippingCharge": "运费",
    "CartonCount": "箱数",
    "Qty": "数量",
    //Page - Pack
    "AddCarton": "添加箱",
    "PackAll": "单位装箱",
    "PredefinedPackage": "预存箱规格",
    "QuantityShipped": "发货量",
    "QuantityPacked": "装箱量 (删除请输入0)",
    "SelectInventoryToPack": "请选择物品",
    "SystemGenerated": "自动生成",

    //Page - Report
    "ReportName": "选择报告",
    "StartDate": "开始日期",
    "EndDate": "截至日期",
    "Format": "格式",
    "QuickAccessPeriod": "常用区间",

    //Page - Profile
    "MyProfile": "我的信息",
    "UpdateMyPassword": "修改密码",
    "PasswordConfirm": "确认密码",
    "APIToken": "对接验证 (API Token)",

    //Server Errors
    "UnableToAccess": "您没有权限",
    "AuthenticationFailed": "无法登录",
    "UnexpectedSubmissionFailure": "无法连接服务器， 请联系客服",
    "AnUnexpectedErrorHasOccurred": "服务器出错，请联系客服",

    
    //Server Error Codes (400, caused due to validation or incorrect user input) (as of Feb 26, 1.3.1)
    //General
    10001: "Cannot save your comment",
    10002: "Duplicated location",
    10003: "Lost connection to storage server",    
    10004: "Cannot delete file",
    10005: "Invalid filed uploaded",
    10006: "Invalid image file",
    10007: "Password is required",
    10008: "Email already exists",
    10009: "User role does not exist",
    11001: "Invalid count of sheet(s)",
    11002: "Row is missing IID o SKU field",

    //Inventory
    20001: "Inactive item or item not found",
    20002: "Cannot adjust item due to serial control. Use inbound & outbound orders to adjust serial controlled items",

    //Order
    30001: "Duplicated SKU",
    30002: "Invalid item identifier",
    30003: "Duplicated SKU or inventory ID",
    30004: "Incorret items found in order lines",
    30005: "Incorrect relation",
    30006: "Incorrect courier supplied",
    30007: "Order is not open",

    //Shipment
    40001: "Shipment not found",
    40002: "Insufficient quantity",
    40003: "Courier not found",
    40004: "Quantity cannot be greater than ordered",
    40005: "Invalid shipment",
    40006: "Invalid order type",
    40007: "Cannot over fulfill line(s)",
    40008: "Incorrect quantity",
    40009: "Quick fulfill is disabled",
    40010: "Cannot void a finished shipment",
    40011: "Not an In Warehouse shipment",
    40012: "Quantity cannot be greater than ordered",

    //Billing
    50001: "Name already exists",
    50002: "Incorrect activity supplied",
    50003: "Duplicated billing item",
    50004: "Item is not associated to activity",
    50005: "Shipment is already billed",

    //Operation
    60001: "Cannot delete used license plate(s)",
    60002: "License number is duplicated in batch create",
    60003: "Incorrect serials count",
    
    //Integration
    90001: "Integration not supported",
    90002: "Shopify order not found",
    90003: "Integration rejected by partner",
    90004: "TradeGecko order not found",
    90005: "Magento order not found",
    90006: "ShipStation account needed",
    90007: "ShipStation order not found",
    90008: "Invalid Zalora credential",
    90009: "Invalid Zalora credential",
    90010: "Zalora order not found",
    90011: "Duplicated Zalora order",
    90012: "Imported order has no line items",
    90013: "Cannot update Zalora status",
    
    
    //Others
    "500Error": "系统错误",
    "500ErrorRef": "联系客服时请时候此号码: ",
    "504Error": "无法连接服务器",

    //Buttons & Action
    "LogIn": "登录",
    "Save": "保存",
    "Upload": "上传",
    "Edit": "修改",
    "Update": "更新",
    "Confirm":"确认",
    "Cancel": "取消",
    "GoBack": "返回",
    "Create": "创建",
    "Actions": "选项",
    "Clone": "复制",
    "AdvancedFilter": "高级筛选",
    "Filter": "筛选",
    "AddFilter": "添加删选",
    "RunFilter": "运行",
    "Add": "增加",
    "Conditions": "条件",
    "Search": "查询",
    "List": "记录",
    "Search by": "搜索",
    "Delete": "删除",
    "Form": "表格",
    "LoadResource": "重载信息",
    "ClickToDelete": "点击永久删除",
    "Cancel BO": "取消尾单",
    "Batch": "批量确认",
    "BatchCreate": "批量创建",
    "Generate": "生成",
    "Print": "打印 ",
    "Void": "永久删除",

    //Form Input Validation Message
    "DataSubmissionError(s)": "信息有误",
    "EmailAddressIsinvalid": "请输入邮箱",
    "PasswordIsRequired": "请输入密码",
    "CompanyNameIsRequired": "请输入公司名",
    "UserNameIsRequired": "请输入用户名",
    "UOMIsRequired": "请输入计量单位",
    "OnlyOneBaseUOMIsAllowed": "只能拥有一个基础单位",
    "UOMNotNumber":"长宽高以及重量必须是数字",
    "NumberOfBaseUnitShouldBeOne": "基础单位数量必须是1",
    "SKUIsRequired": "请输入商品编号",
    "NameIsRequired": "请输入名称",
    "PleaseSelectARole": "请选择权限",
    "PleaseSelectAClient": "请选择客户",
    "PleaseMapToAShipStationWarehouse": "请选择ShipStation仓库",
    "InvliadNumberFormat": "数字格式错误",
    "AtLeastOneEmailIsRequired": "请输入至少一个电邮地址",
    "LogoURLIsInvalid": "商标地址不规范",
    "CourierNameIsRequired": "请输入运输方式",
    "TaxNameIsRequired": "请输入税名",
    "TaxRateIsNotAValidNumber": "税率不正确",
    "TermNameIsRequired": "请输入付款方式",
    "UomNameIsRequired": "请输入计量单位名",
    "PackageNameIsRequired": "请输入箱名",
    "RelationIsRequired": "请输入客户名或者供应商名",
    "ShipingAddressIsRequired": "请输入发票地址",
    "BillingAddressIsRequired": "请输入送货地址",
    "ReferenceIsRequired": "请输入编号",
    "CourierIsRequired": "请选择运输方式",
    "AtLeastOneLineItemIsRequired": "请添加订单物品",
    "InvalidLineItem": "订单物品出错，行号 ",
    "InvalidLineQuantity": "订单物品数量出错，行号 ",
    "InvalidLinePrice": "订单物品价格出错，行号 ",
    "CannotShipMoreThanRemaining":"确认数量大于订购数量，行号 ",
    "ActivityNameIsRequired": "请输入收费设置名",
    "ClientIsRequired": "请输入客户",
    "ActivityIsReuqired": "确实收费行为设置",
    "DateIsReuqired": "请输入日期",
    "AtLeastOneItemIsRequired": "请选择至少一个收费服务物品",
    "PleaseSelectAPartner": "请选择一个对接系统",
    "CredentialFieldsAreEmpty": "缺少必要信息 *",
    "LocationIsRequired": "请输入仓位",
    "LicenseNumberIsRequired": "请输入分拣牌号",
    "StartLicenseNumberIsRequired": "请输入开始号码",
    "NumberOfLicensesIsRequired":"请输入分拣牌数量",
    "InventoryIsRequired": "请输入物品",
    "AtLeastOneSerialNumberIsRequired": "请输入至少一个序列号",
    "UnitsMustBeGreaterThan0": "物品数量不能为0",
    "ResetEmailSent": "请查询您的邮箱",
    "ResetEmailFailed": "不能发送重置邮件，请联系客服",
    "TwoPasswordAreNotTheSame": "两个密码不同",
    //Fulfillment Modal:
    "MissingLineId": "缺少重要信息，请重新刷新您的页面",
    "InvalidQuantity": "数量必须是大于零的整数",
    "LotRequired": "请选择生产批号",
    "SerialRequired": "请选择产品序列号",
    "QuantityCannotBeMoreThanOrdered": "数量不能大于需求量",
    //Form Fields Labels
    "Email": "邮箱",
    "Password": "密码",
    //Settings => Company Page
    "CompanyName": "公司名",
    "AddressType": "地址种类",
    "Address": "地址",
    "City": "城市",
    "Postal": "邮编",
    "State": "省",
    "StateCode": "省(简写)",
    "Country": "国家",
    "CountryCode": "国家(简写)",
    "Phone": "电话",
    "BrandingName": "登录页:主要名称",
    "BrandingSlogan": "登录页：标语",
    "Website": "公司主页",
    "SSPrefix": "ShipStation订单号前缀",
    "HomeCountryCode": "公司国家",
    "Measure": "计量方式",
    "Logo": "公司商标",
    "BrandingColor": "登录页右侧颜色",
    "HeaderColor": "系统上方页眉颜色",
    "AuthBtnColor": "登录按钮颜色",
    "PrimaryBtnColor": "主要按钮颜色",
    "SecondaryBtnColor": "次要按钮颜色",
    "DeleteBtnColor": "删除按钮颜色",
    "BillingAddress": "发票地址",
    "ShippingAddress": "送货地址",
    "BothAddress": "发票和送货地址",
    "MyCompany Settings": "本公司设置",
    "FulfillEnterLp":"搜索分拣牌并按回车",
    "FulfillLpSelected":"当前分拣牌号 #: ",
    "FulfillQuantity":"添加数量",
    "FulfillQuarantine":"隔离本次录入",

    //Global
    "ReadSupport":"更多信息",
    "ErrorRef": "服务器错误代码:",

    //Side Menu (When Translate, please do not use long names)
    "Dashboard": "主页",
    "Relation": "关系",
    "Customer": "顾客",
    "Vendor": "供应商",
    "Inventory":"物品",
    "Order": "订单",
    "Customer Order": "客户订单",
    "Vendor Order":"订货单",
    "Customer Return":"客户退单",
    "Vendor Return": "退货单",
    "Operation":"操作",
    "LicensePlate":"分拣牌",
    "Receiving": "收货",
    "Shipping": "发货",
    "SerialNumber":"产品序列号",
    "BillOfLading":"Bill Of Lading",
    "Billing":"收费",
    "Item":"项目",
    "Activity":"行为",
    "Entry":"记录",
    "Report":"报告",
    "Setting":"设置",
    "Company":"公司",
    "Client":"客户",
    "User":"用户",
    "Configuration":"设置",
    "Integration":"对接",

    //Others
    //Operators:
    "Equal": "等于",
    "Greater": "大于",
    "Less": "小于",
    "GreatEqual": "大于等于",
    "LessEqual":"小于等于",
    "Include": "包括",
    "AND": "和",
    "OR": "或者",
    //Date
    "CreatedAt":"生成",
    "UpdatedAt":"更新",

    //Roles
    //Role Header
    "update_company_info":"修改本公司信息",
    "view_client_list": "观看客户",
    "view_client_model": "观看客户详细信息",
    "create_client_model": "创造新客户",
    "update_client_model": "更新客户",
    "view_user_list": "观看用户",
    "view_user_model": "观看用户详细信息",
    "create_user_model": "创造新用户",
    "update_user_model": "更新用户",
    "adjust_inventory": "修改库存量",
    "create_role_model": "创造用户权限",
    "update_role_model": "更新用户权限",
    "create_general": "创造计量单位， 税和箱",
    "update_general": "修改计量单位， 税和箱",
    "delete_general": "删除计量单位， 税和箱",
    "create_courier": "创造运输方式",
    "update_courier": "更新运输方式",
    "view_api_list": "观看对接系统",
    "view_api_model": "观看对接系统详细信息",
    "create_api_model": "创建新的对接",
    "update_api_model": "更新对接",
    "delete_api_model": "删除对接",
    "view_location_list": "观看仓位",
    "create_location_model": "创建仓位",
    "update_location_model": "更新仓位",
    "view_license_list": "观看分拣牌",
    "create_license_model": "创建分拣牌",
    "update_license_model": "更新分拣牌",
    "breakdown_license_model": "创建子分拣牌",
    "delete_license_model": "删除分拣牌",
    "put_license_to_location": "移动分拣牌",
    "view_serial_list": "观看产品序列号",
    "create_serial_model": "创建产品序列号",
    "update_serial_model": "更新产品序列号",
    "view_serial_model": "观看产品序列号信息",
    "delete_serial_model": "删除产品序列号",
    "view_shipments_list": "观看出入库单",
    "view_shipment_model": "观看出入库单详细信息",
    "update_shipment_tracking": "更新快递单号",
    "void_shipment": "删除出入库单",
    "fulfill_shipment": "录入出入库记录",
    "delete_fulfillment_line": "删除出入库纪律",
    "view_activity_list": "观看收费行为",
    "view_activity_model": "观看收费行为详细信息",
    "create_activity_model": "创建新的收费行为",
    "update_activity_model": "更新收费行为",
    "update_billing_items": "更新收费行为内容",
    "view_billing_item_list": "观看收费项目",
    "create_billing_item_model": "创建收费项目",
    "update_billing_item_model": "更新收费项目",
    "run_billing_report": "观看收费报告",
    "view_entry_list": "观看收费记录",
    "create_entry_model": "人工录入收费记录",
    "update_entry_model": "更新收费记录",
    "move_between_license": "分拣牌间转移数量",
    "view_role_list": "观看权限",
    "update_shipment_line": "出入库时更新订单物品数量",
    "role_pack": "装箱",
    "trace_history": "观看用户行为记录",

    "MessageCourierPage": "运输方式是根据客户生成的。请首先在右上角选择正确的客户",
    "MessageTaxRatePage": "税可以用在客户的发票上。一般情况下，您不需要修改这些信息。",
    "MessagPaymentTermPage": "付款方式可以用在客户的发票上。一般情况下，您不需要修改这些信息。",
    "MessagePackagePage": "您可以预设一些包裹信息。",
    "MessageOrderCloned":"成功的复制当前订单并创建一个新的订单.",
    "MessageAddItemToActivity":"请不要把价格设置为0. 当价格为0的时候，系统会自动去除这些项目",
    "MessageUpdateBillingEntry": "把价格设为0就等于删除了此项目.",
    "MessageManualImportAPI":"手动导入订单不会进行重复审核，此行为可能会产生重复订单",
    "MessageDownloadSchema": "下载一份用于上传Excel报表",
    "MessageImportSuccess": "成功导入:",
    "MessageImportErrorList":"失败:",
    "MessageExcelRow": "行",
    "MessageSerialClient": "请在右上角选择正确的客户来开始录入产品序列号",
    "MessageSelectZaloraCourier": "选择一个Zalora的运输方式",
    "MessageSelectShippingMethod": "Select a shipping method",
    "MessageZaloraDropship": "DropShip: send out the package",
    "MessageZaloraPickup": "Pickup: Shop pick up",
    "MessageZaloraSendToWarehouse": "SendToWarehouse: send to warehouse",
    "MessageZaloraFulfillUseMyOwn": "Use my own courier",
    "MessageZaloraFulfilled": "Fulfilled in Zalora, get labels",
    "MessageZaloraShippingInv": "Shipping Invoice",
    "MessageZaloraShippingLabel": "Shipping Label",
    "MessageZaloraShippingCarrierManifest": "Carrier Manifest",
    "MessageShippoPackShipment": "使用Shippo之前请先装箱.",
    "UserCreatedMessage": "用户创建成功，此用户将收到一封邮件。以下是此用户的帐号和临时密码（只会显示一次）",

    //Missing
    "FromLp": "始分拣牌",
    "ToLp": "终分拣牌",
    "AdjustmentRef": "修改原因",
    "QuantityChange": "修改数量",

    //Webhook
    "HookTrigger": "Action",
    "HookType": "Type",
    "HookMethod": "HTTP Method",
    "HookEndpoint": "Endpoint",
    "HookHashKey": "Security Key",
    "HookConfigUserName": "FTP Username",
    "HookConfigPassword": "FTP Password",
    "HookConfigPath": "File Path"
};