import React, { Component } from 'react';
import { bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types'
import {withI18n} from 'react-i18next';
import {Segment, Table, Dimmer, Loader} from 'semantic-ui-react';
import SegmentRibbon from '../../includes/partials/SegmentRibbon';
import PageBreadCrub from '../../includes/partials/PageBreadCrub';
import qs from 'qs';
import update from 'immutability-helper';
import {
    fetchEntryPaginatedList
} from '../../../actions/billing';
import BillingEntryTableBody from './BillingEntryTable';
import OrderBy from '../../includes/tables/OrderBy';
import SearchBar from '../../includes/tables/SearchBar';
import Pagination from '../../includes/tables/Pagination';
import AlertComponent from '../../includes/messages/alert';
import Lara from '../../../lara';
import LaraPagination from '../../../laraPagination';
import PrimaryNaviButton from '../../includes/buttons/PrimaryNaviButton';

//Lara Button Updated
class BillingEntryListPage extends Component {
    constructor() {
        super();
        this.state = {
            breadcrub: [
                {isLink:false, to:null, tag: '', active: true, key: 1}
            ],
            loading: false,
            routingQuery: {

            },
            routingPath: "",
            search: ""
        }
    }
    
    componentDidUpdate(prevProps, prevState) {
        const {params, url} = this.props.match;
        if (url !== this.state.routingPath) {
            this.setRoutingInState(params, url);
        }
    }

    componentDidMount() {
        const {params, url} = this.props.match;
        this.setRoutingInState(params,url);
    }

    setRoutingInState = ({query}, url) => {
        const q = qs.parse(query);
        //order_by
        //filter_by
        //query
        if (!_.isObject(q.filter_by)) {
            q.filter_by = {};
        }
        if (!_.isObject(q.order_by)) {
            q.order_by = {};
        }
        if (!_.isArray(q.query)) {
            q.query = [];
        }
        this.setState({
            loading: true,
            routingQuery: q,
            routingPath: url,
            search: q.search,
            breadcrub: [
                {isLink:false, to:null, tag: this.props.t('BillingEntry'), active: true, key: 1}
            ]
        }, () => {
            this.fetchRecords(query)
        });
    }

    fetchRecords = () => {
        this.props.fetchEntryPaginatedList(qs.stringify(this.state.routingQuery)).then(res => {
            this.setState({loading: false});
        }).catch(err => {
            const errors = Lara.axiosError(err, this.props.t);
            if (errors.http_code === 422) {
                this.setState({
                    errors: errors.message,
                    loading: false
                });
            } else {
                this.setState({
                    loading: false
                });
                Lara.axiosAlert(<AlertComponent 
                    support={errors.support}
                    message={errors.message}
                    reference={errors.reference}
                    t={this.props.t}
                />);
            }
            this.setState({loading: false});
        });
    }

    routeToNewQuery = (newRoutingQuery) => {
        this.props.history.push(`/billing/entries/${qs.stringify(newRoutingQuery)}`);
    }

    /*
     * For table header filters
    */
    onOrderByHeaderClicked = (routingOrderName, value) => {
        const newRoutingQuery = update(this.state.routingQuery, {
            order_by: {
                [routingOrderName]: {$set: value}
            }
        });
        this.routeToNewQuery(newRoutingQuery);
    }

    /*
     * For pagination
    */
    onPaginationClicked = (e, data) => {
        const {activePage} = data;
        const newRoutingQuery = update(this.state.routingQuery, {
            on_page: {$set: activePage}
        });
        this.routeToNewQuery(newRoutingQuery);
    }

    /*
     * For Search bar: onSearchEnterKeyPressed onSearchChange onPredefinedRouteSelected (optional)
     */
    onSearchEnterKeyPressed = (e) => {
        if (e.key === 'Enter') {
            const defaultQuery = LaraPagination.billing.entries(true);
            defaultQuery.search = this.state.search;
            this.setState({
                queryId: 'default'
            }, () => {
                this.routeToNewQuery(defaultQuery);
            });
        }
    }

    //New Sep 12, 2019 - add pagination per page selector
    onPerPageChanged = (newPerPage) => {
        const query = update(this.state.routingQuery, {per_page: {$set: newPerPage}});
        this.routeToNewQuery(query);
    }

    render() {
        const {routingQuery, search} = this.state;
        const {t, list} = this.props;
        const {on_page, per_page, total} = list;
        const orderBy = _.isObject(routingQuery.order_by) ? routingQuery.order_by : {};
        return (
            <div>
                {/* Breadcrub Row */}
                <div className="row">
                    <div className="col-md-12 lara-breadcrub">
                        <PageBreadCrub lists={this.state.breadcrub} t={t}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <Segment>
                            <SegmentRibbon label={`${t('BillingEntry')} ${t('Search')}`} Buttons={
                                () => {return (<React.Fragment>
                                    <PrimaryNaviButton
                                        link={`/billing/entries/action/${LaraPagination.billing.newEntryQuery('manual',null,null, this.props.location.pathname)}`}
                                        size='mini'
                                        float='right'
                                        loading={false}
                                        label={t('Create')}
                                    />
                                </React.Fragment>)}
                            }/>
                            <SearchBar
                                placeholder={`${t('Search by')} ${t('Client')}`}
                                onSearchEnterKeyPressed={(e) => {this.onSearchEnterKeyPressed(e)}}
                                search={search}
                                onSearchChange={(search) => {this.setState({search})}}
                                onPredefinedRouteSelected={() => null}
                                options={[]}
                                havePreferenceSelect={false}
                                hasPerPageSelector={routingQuery.per_page}
                                onPerPageSelcted={(perPage) => {
                                    this.onPerPageChanged(perPage);
                                }}
                            />
                        </Segment>

                        <Segment>
                            <Dimmer inverted active={this.state.loading}>
                                <Loader />
                            </Dimmer>
                            <SegmentRibbon label={`${t('BillingEntry')} ${t('List')}`}/>
                            <div className="row lara-row-margin">
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <Table
                                        compact
                                        basic
                                        padded={false}
                                    >
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell>
                                                    <OrderBy
                                                        t={t}
                                                        name='BillDate'
                                                        routingOrderName='bill_date'
                                                        currentOrder={orderBy.bill_date}
                                                        onOrderClick={(routingOrderName, value) => {this.onOrderByHeaderClicked(routingOrderName, value)}}
                                                    />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    {t('Client')}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    {t('Reference')}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    {t('Item')}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    {t('Price')}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    {t('Quantity')}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    {t('Total')}
                                                </Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        <BillingEntryTableBody t={t}/>
                                    </Table>
                                </div>
                            </div>
                        
                            <div className="row lara-row-margin">
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <Pagination 
                                        activePage={on_page}
                                        totalRecords={total}
                                        perPage={per_page}
                                        onPageChange={(e, data) => {
                                            this.onPaginationClicked(e,data);
                                        }}
                                    />
                                </div>
                            </div>
                        </Segment>
                    </div>
                </div>
            </div>
        )
    }
}

BillingEntryListPage.propTypes = {
    t: PropTypes.func.isRequired,
    history: PropTypes.shape({
       push: PropTypes.func.isRequired
    }).isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.shape({
        params: PropTypes.object.isRequired,
        path: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired
    }),
    fetchEntryPaginatedList: PropTypes.func.isRequired,
    list: PropTypes.object.isRequired
};

function mapStateToProps(state) {
    return {
        clients: state.system.clients,
        list: state.billingEntryList.list
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchEntryPaginatedList
    }, dispatch)
}

export default withI18n()(connect(mapStateToProps, mapDispatchToProps)(BillingEntryListPage));