import React from 'react';
import _ from 'lodash';
import {Header, Icon} from 'semantic-ui-react';
import PropTypes from 'prop-types';

const WooHeader = ({model}) => (
    <div>
        <Header as='h2'>
            <Icon name='settings' />
            <Header.Content>
                {model.o_auth_token}
                <Header.Subheader>Consumer Key: {model.key_1}</Header.Subheader>
                <Header.Subheader>Consumer Secret: {model.key_2}</Header.Subheader>
                <Header.Subheader>Last Synchronize: {_.isObject(model.synchronize) ? model.synchronize.updated_at : null}</Header.Subheader>
            </Header.Content>
        </Header>
    </div>
)

WooHeader.propTypes = {
    model: PropTypes.object.isRequired
};


export default WooHeader