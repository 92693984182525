import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {Table, Label} from 'semantic-ui-react';

class FulfillmentTableLine extends React.PureComponent {    
    render() {
        const {line, onFulfillClicked} = this.props;
        const {order_line} = line;
        let kit = null;
        if (order_line) {
            if (order_line.kit_id) {
                kit = order_line.kit_property ? order_line.kit_property.sku : null;
            }
        }
        let uom = "NA";
        const index = _.findIndex(line.inventory.uoms, i => {
            return i.pivot.is_base === 1;
        });
        if (index > -1) {
            uom = line.inventory.uoms[index].name;
        }
        
        let fulfilled = 0;
        _.each(line.fulfillments, ful => {
            fulfilled = fulfilled + Math.abs(ful.change);
        });

        return (
            <Table.Row>
                <Table.Cell verticalAlign='top' textAlign='left'>
                    <span className="lara-clickable lara-editable" onClick={() => {
                        onFulfillClicked()
                    }}>
                        {line.inventory.sku}
                    </span>
                    {kit ? <div>
                        <Label size='mini' color='purple'>{kit} @ {order_line.kit_property.quantity}</Label>
                    </div> : null}
                </Table.Cell>
                <Table.Cell verticalAlign='top' textAlign='left'>
                    {line.inventory.name} {order_line.comment ? <span className="lara-order-line-comment"><br />{order_line.comment}</span> : null}
                </Table.Cell>
                <Table.Cell verticalAlign='top' textAlign='left'>{line.inventory.upc}</Table.Cell>
                <Table.Cell verticalAlign='top' textAlign='left'>{uom}</Table.Cell>
                <Table.Cell verticalAlign='top' textAlign='left'>
                    {line.quantity}
                </Table.Cell>
                <Table.Cell verticalAlign='top' textAlign='left'>
                    {fulfilled}
                </Table.Cell>
                <Table.Cell verticalAlign='top' textAlign='left'>
                    {line.inventory.config.lot ? 'Lot' : null}
                    {line.inventory.config.serial ? 'SN' : null}
                </Table.Cell>
            </Table.Row>
        )
    }
}

FulfillmentTableLine.propTypes = {
    line: PropTypes.shape({
        id: PropTypes.number.isRequired,
        inventory: PropTypes.shape({
            id: PropTypes.number.isRequired,
            config: PropTypes.object.isRequired,
            uoms: PropTypes.array.isRequired
        }),
        fulfillments: PropTypes.array.isRequired
    }),
    onFulfillClicked: PropTypes.func.isRequired
};

export default FulfillmentTableLine