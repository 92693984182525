import update from 'immutability-helper';
import {
    SET_BILLING_ACTIVITY_MODEL,
    BILLING_ACTIVITY_MODEL_UPDATED_BY_FORM,
    NEW_ITEM_ADDED_TO_ACTIVITY,
    EXISTING_ITEM_UPDATED_FROM_ACTIVITY
} from '../../actions/types'

export default function activityModel(state = {
    id: null,
    items: []
}, action = {}) {
    switch(action.type) {
        case SET_BILLING_ACTIVITY_MODEL:
            return action.payload;

        case BILLING_ACTIVITY_MODEL_UPDATED_BY_FORM:
            return update(state, {
                [action.payload.field]: {
                    $set: action.payload.value
                }
            });

        case NEW_ITEM_ADDED_TO_ACTIVITY:
            return update(state, {
                items: {$push: [action.payload]}
            });

        case EXISTING_ITEM_UPDATED_FROM_ACTIVITY:
            return update(state, {
                items: {
                    [action.payload.index]: {
                        pivot: {$set: action.payload.pivot}
                    }
                }
            })
        default: return state;
    }
}