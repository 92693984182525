import {api} from '../api';

export const addCategoryToResource = (name, resource, resourceId, type) => (dispatch) => {
    return api.categoryAndTag.attach(name, resource, resourceId).then(res => {
        const {data} = res.data;
        data.pivot = {
            inventory_id: resourceId,
            category_id: data.id
        }
        dispatch({
            type: type,
            payload: {data: data}
        });
        return res;
    }).catch(err => {
        throw err;
    });
}

export const deleteCategoryToResource = (categoryId, resource, resourceId, categoryIndex, type) => (dispatch) => {
    return api.categoryAndTag.detach(categoryId,resource,resourceId).then(res => {
        dispatch({
            type: type,
            payload: {index: categoryIndex}
        });
        return res;
    }).catch(err => {
        throw err;
    })
}