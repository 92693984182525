import React from 'react'
import PropTypes from 'prop-types'
import {Label} from 'semantic-ui-react';

const SegmentRibbon = ({label, Buttons}) => (
    <div className="lara-segment-ribbon">
        <Label ribbon
            color='grey'>{label}
        </Label>
        {Buttons ? <Buttons /> : null}
    </div>
)

SegmentRibbon.propTypes = {
    label: PropTypes.string.isRequired,
    Buttons: PropTypes.any
};


export default SegmentRibbon