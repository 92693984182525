import React, { Component } from 'react';
import {Form} from 'semantic-ui-react';
import {withI18n} from 'react-i18next';
import _ from 'lodash';
import PropTypes from 'prop-types';
import PrimaryButton from '../buttons/PrimaryButton'

class PackShipmentLineSelector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lineId: "0",
            quantity: "1",
            expire: ""
        }
    }

    onAddClick = () => {
        const {lineId, quantity, expire} = this.state;
        if (parseInt(lineId, 10) > 0 && parseInt(quantity,10) > 0) {
            this.props.onAddLineClick(lineId, quantity, expire)
        }
    }
    
    render() {
        const {lines, t, packedQty} = this.props;
        const options = [{value: "0", text: t("SelectInventoryToPack")}];
        _.each(lines, l => {
            const packed = packedQty[l.inventory.id] ? packedQty[l.inventory.id] : 0
            if ( packed < l.quantity) {
                if (_.isObject(l.inventory)) {
                    options.push({
                        value: _.toString(l.id),
                        text: `${l.inventory.sku} - ${l.inventory.name}${l.inventory.upc ? ` - ${l.inventory.upc}` : null}${l.inventory.custom_2 ? ` - ${l.inventory.custom_2}` : null}`
                    })
                }
            }
        });
        return (
            <div>
                <Form.Group>
                    <Form.Select
                        size='mini'
                        width={16}
                        label={t("Inventory")}
                        value={this.state.lineId}
                        options={options}
                        search
                        onChange={(e, {value}) => {
                            this.setState({lineId: value})
                        }}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Input
                        width={4}
                        type="date"
                        label={t("Expire")}
                        value={this.state.expire}
                        onChange={(e, {value}) => {
                            this.setState({expire: value})
                        }}
                    />

                    <Form.Input
                        width={4}
                        type="number"
                        min={1}
                        label={t("Quantity")}
                        value={this.state.quantity}
                        onChange={(e, {value}) => {
                            this.setState({quantity: value})
                        }}
                    />
                    
                    <Form.Field>
                        <label>&nbsp;&nbsp;</label>
                        <PrimaryButton
                            size='mini'
                            float='left'
                            label={t('Add')}
                            onClick={() => {
                                this.onAddClick()
                            }}
                            loading={false}
                        />
                    </Form.Field>
                </Form.Group>
            </div>
        )
    }
}

PackShipmentLineSelector.propTypes = {
    lines: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired,
    onAddLineClick: PropTypes.func.isRequired,
    packedQty: PropTypes.object.isRequired
    //history: PropTypes.shape({
    //    push: PropTypes.func.isRequired
    //}).isRequired,
};

export default withI18n()(PackShipmentLineSelector)