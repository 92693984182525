import React, { Component } from 'react'
import { bindActionCreators} from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {Dropdown} from 'semantic-ui-react';
import {api} from '../../../api';

class CategoryTagSelector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            options: []
        }
    }

    componentDidMount() {
        this.setState({
            loading: true,
            options: []
        });

        api.categoryAndTag.getByType(this.props.type).then(options => {
            options.unshift({
                value: 0,
                text: this.props.t('CategorySelector'),
                key: 0
            });
            this.setState({
                loading: false,
                options: options
            });
        }).catch(err => {
            this.setState({
                loading: false,
                options: []
            });
        });
    }
    
    render() {
        return (
            <Dropdown
                options={this.state.options}
                placeholder=""
                search
                selection
                fluid
                allowAdditions
                value={0}
                disabled={this.state.loading}
                onChange={
                    (e, {value}) => {
                        this.props.onSelected(value);
                    }
                }
            />
        )
    }
}

CategoryTagSelector.propTypes = {
    type: PropTypes.string.isRequired,
    resourceId: PropTypes.number.isRequired,
    onSelected: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryTagSelector)