import React, { Component } from 'react';
import _ from 'lodash';
import { bindActionCreators} from 'redux';
import update from 'immutability-helper';
import { connect } from 'react-redux';
import {Table, Modal, Form, Header, Message} from 'semantic-ui-react';
import moment from 'moment';
import accounting from 'accounting';
import PropTypes from 'prop-types';
import AlertComponent from '../../includes/messages/alert';
import FormError from '../../includes/messages/422';
import Lara from '../../../lara';
import {billingEntryModified} from '../../../actions/billing';
import {api} from '../../../api';
import PrimaryButton from '../../includes/buttons/PrimaryButton';
import DeleteButton from '../../includes/buttons/DeleteButton';

//Lara Button Updated
class BillingEntryTableBody extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: [],
            recordIndex: -1,
            recordModal: false,
            loading: false,
            model: {
                id: null
            }
        }
    }

    getClientName = (clientId) => {
        const {clients} = this.props;
        const clientIndex = _.findIndex(clients, c => {
            return parseInt(c.id, 10) === parseInt(clientId, 10);
        })
        if (clientIndex > -1) {
            return clients[clientIndex].company
        }
        return "NA"
    }

    onModelValueChange = (field, value) => {
        this.setState({
            model: update(this.state.model, {
                [field]: {$set: value}
            })
        })
    }

    onSaveClick = () => {
        const {id, quantity} = this.state.model;
        let isDelete = true;
        if (parseInt(quantity,10) !==0) {
            isDelete = false;
        }
        this.setState({
            loading: true
        });
        api.billing.entries.update(id, this.state.model).then(res => {
            this.props.billingEntryModified(this.state.model, this.state.recordIndex, isDelete);
            this.onCloseModal();
        }).catch(err => {
            const errors = Lara.axiosError(err, this.props.t);
            if (errors.http_code === 422) {
                this.setState({
                    errors: errors.message,
                    loading: false
                });
            } else {
                this.setState({
                    loading: false
                });
                Lara.axiosAlert(<AlertComponent 
                    support={errors.support}
                    message={errors.message}
                    reference={errors.reference}
                    t={this.props.t}
                />);
            }
        })
    }

    onModifyItemClick = (entry, index) => {
        this.setState({
            recordIndex: index,
            recordModal: true,
            model: entry
        })
    }

    onCloseModal = () => {
        this.setState({
            errors: [],
            recordIndex: -1,
            recordModal: false,
            loading: false,
            model: {
                id: null
            }
        })
    }

    render() {
        const {list, t} = this.props;
        return (
            <React.Fragment>
                <Table.Body>
                    {list.records.map((i,index) => {
                        return (<Table.Row key={i.id}>
                            <Table.Cell>
                                <span className="lara-editable lara-clickable" onClick={() => {
                                    this.onModifyItemClick(i, index);
                                }}>
                                    {moment(i.bill_date).format('MMM D, Y').toString()}
                                </span>
                            </Table.Cell>
                            <Table.Cell>
                                {this.getClientName(i.client_id)}
                            </Table.Cell>
                            <Table.Cell>
                                {i.reference}
                            </Table.Cell>
                            <Table.Cell>
                                {i.item ? i.item.name : "NA"}
                            </Table.Cell>
                            <Table.Cell>
                                {accounting.formatMoney(i.price)}
                            </Table.Cell>
                            <Table.Cell>
                                {i.quantity}
                            </Table.Cell>
                            <Table.Cell>
                                {accounting.formatMoney(i.price * i.quantity)}
                            </Table.Cell>
                        </Table.Row>)
                    })}
                </Table.Body>
                <Modal size='mini' 
                    closeOnDimmerClick={false}
                    closeIcon
                    centered={false}
                    onClose={() => {this.onCloseModal()}}
                    closeOnEscape={true} open={this.state.recordModal}>
                    <Header icon='pencil' content='' />
                    <Modal.Content>
                        <Message>
                            <Message.Header>{t("Delete")}</Message.Header>
                            <p>
                            {t('MessageUpdateBillingEntry')}
                            </p>
                        </Message>
                        {!_.isEmpty(this.state.errors) ? (
                            <FormError errors={this.state.errors} />
                        ) : null}
                        <Form>
                            <Form.Input
                                type="date"
                                required={true}
                                value={this.state.model.bill_date}
                                onChange={(e, {value}) => {
                                    this.onModelValueChange('bill_date', value)
                                }}
                                label={t("BillDate")} />
                            <Form.Input
                                value={this.state.model.reference}
                                required={true}
                                onChange={(e, {value}) => {
                                    this.onModelValueChange('reference', value)
                                }}
                                label={t("Reference")} />
                            <Form.Input
                                type="number"
                                required={true}
                                value={this.state.model.quantity}
                                onChange={(e, {value}) => {
                                    this.onModelValueChange('quantity', value)
                                }}
                                label={t("Quantity")} />
                            <Form.Input
                                type="number"
                                required={true}
                                value={this.state.model.price}
                                onChange={(e, {value}) => {
                                    this.onModelValueChange('price', value)
                                }}
                                label={t("Price")} />
                            <Form.TextArea
                                value={this.state.model.note ? this.state.model.note : ""}
                                label={t("Note")}
                                onChange={(e, {value}) => {
                                    this.onModelValueChange('note', value)
                                }}
                            />
                            
                            {parseInt(this.state.model.quantity, 10) !== 0 ? <PrimaryButton
                                size='mini'
                                float='right'
                                label={t('Save')}
                                onClick={() => {
                                    this.onSaveClick()
                                }}
                                loading={this.state.loading}
                            /> : <DeleteButton
                                size='mini'
                                float='right'
                                label={t('Delete')}
                                onClick={() => {
                                    this.onSaveClick()
                                }}
                                loading={this.state.loading}
                            />}
                            <div className="row">
                                <div className="lara-row-margin"></div>
                            </div>
                        </Form>
                    </Modal.Content>
                </Modal>
            </React.Fragment>
        )
    }
}

BillingEntryTableBody.propTypes = {
    t: PropTypes.func.isRequired,
    clients: PropTypes.array.isRequired,
    billingEntryModified: PropTypes.func.isRequired,
    list: PropTypes.shape({
        records: PropTypes.array.isRequired
    })
};

function mapStateToProps(state) {
    return {
        clients: state.system.clients,
        list: state.billingEntryList.list
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        billingEntryModified
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(BillingEntryTableBody)