import React from 'react';
import PropTypes from 'prop-types';
import {
    Dropdown
} from 'semantic-ui-react';

const LaraFilterBy = ({t, currentValueString, onFilterSelectChange, name, options, label, fullWidth}) => (
    <div className={fullWidth ? "col-md-12 col-sm-12 col-xs-12" : "col-md-6 col-sm-6 col-xs-12"}>
        {label ? <div className="lara-filter-name">{t(label)}</div> : null}
        <Dropdown
            fluid selection multiple
            options={options}
            value={currentValueString.split(',')}
            onChange={(e, {value}) => {onFilterSelectChange(name,  value)}}
        />
    </div>
)

LaraFilterBy.propTypes = {
    t: PropTypes.func.isRequired,
    currentValueString: PropTypes.string.isRequired,
    onFilterSelectChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    label: PropTypes.string,
    fullWidth: PropTypes.any
};


export default LaraFilterBy