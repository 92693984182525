import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom';
import Alert from 'react-s-alert';
import {withI18n} from 'react-i18next';
import validator from 'validator';
import {Form, Header} from 'semantic-ui-react';
import FormError from '../includes/messages/422';
import AuthButton from '../includes/buttons/AuthButton';
import {api} from '../../api';
import Lara from '../../lara';
//Updated with lara button
class ForgetPasswordPage extends Component {
    constructor() {
        super();
        this.state = {
            email: "",
            errors: [

            ],
            loading: false
        };
    }

    componentDidMount() {
        this.props.bootGuest();
    }

    submitForm = () => {
        const {email} = this.state;
        if (!validator.isEmail(email)) {
            this.setState({
                errors: ["Email address is invalid"]
            })
            return;
        }

        this.setState({
            loading: true,
            errors: []
        });
        api.auth.forgetPassword(email).then(res => {
            this.setState({
                loading: false
            })
            Lara.alertSuccess(this.props.t('ResetEmailSent'));
        }).catch(err => {
            this.setState({
                loading: false
            })
            Lara.alertError(this.props.t('ResetEmailFailed'));
        })
    }
    
    render() {
        const {
            name, address, city, postal, state, country,
            login_bg_color, logo, branding_name, branding, home_address
        } = this.props.preference;
        const {t} = this.props;
        const {loading, email, errors} = this.state
        return (
            <div className="auth-container">
                <div className="auth-left-panel">
                    <div className="auth-form">
                        <Form>
                            <Header as='h1' textAlign='center'>
                                {t('ForgetPassword')}
                            </Header>
                            {errors.length > 0 ? <FormError errors={errors} /> : null}
                            <Form.Input 
                                size="large"
                                required={true}
                                label={t('Email')} 
                                name="email" 
                                value={email} 
                                onChange={(e, {value}) => {
                                    this.setState({email: value})
                                }}/>
                            <AuthButton
                                label={t('ForgetPassword')}
                                onClick={() => {
                                    this.submitForm()
                                }}
                                loading={loading}
                            />
                            <div className="lara-right">
                                <Link to='/auth/login'>{t('LogIn')}</Link>
                            </div>
                        </Form>
                    </div>
                </div>
                <div className="auth-right-panel" style={login_bg_color ? {backgroundColor: login_bg_color} : null}>
                    <div className="auth__branding">
                        {logo ? <div className="auth__text-box">
                            <h1 className="auth-primary">
                                <span>
                                    <img src={logo} alt="Company Logo" className="auth-primary--logo"/>
                                </span>
                                <span className="auth-primary--main">{branding_name}</span>
                                <span className="auth-primary--sub">{branding}</span>
                            </h1>
                        </div> : null}
                    </div>
                    <div className="auth__company-info">
                        {name ? <div>
                            <a href={home_address} className="auth__company-info__link">{name}</a><br />
                        {address}, {city} {state}  {postal}, {country}
                        </div> : null}
                    </div>
                </div>
                <Alert stack={{limit: 3}} />
            </div>
        )
    }
}

ForgetPasswordPage.propTypes = {
    bootGuest: PropTypes.func.isRequired,
    preference: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired
};

export default withI18n()(ForgetPasswordPage);