import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom';
import Alert from 'react-s-alert';
import {withI18n} from 'react-i18next';
import {Form, Header, Message} from 'semantic-ui-react';
import FormError from '../includes/messages/422';
import AuthButton from '../includes/buttons/AuthButton';
import {api} from '../../api';
import Lara from '../../lara';
class FBAAccessPage extends Component {
    constructor() {
        super();
        this.state = {
            sellerId: "",
            mwsToken: "",
            errors: [

            ],
            _token: "",
            loading: false,
            fba: {
                id: "",
                name: ""
            }
        };
    }

    componentDidMount() {
        this.props.bootGuest();
        const {params} = this.props.match;
        this.setState({
            loading: true,
            _token: params.token
        });
        api.integrations.startInvitationProcess(params.token).then(res => {
            const {data} = res.data;
            this.setState({
                loading: false,
                fba: data
            });
            if (!data) {
                this.setState({
                    errors: ["This page is invalid. Please ask your 3PL team to send another invitation."]
                })
            }
        }).catch(err => {
            this.setState({
                errors: ["This page is invalid. Please ask your 3PL team to send another invitation."]
            })
        })
    }

    submitForm = () => {
        const {sellerId, mwsToken} = this.state;
        if (!sellerId) {
            this.setState({
                errors: ["Seller ID is invalid"]
            })
            return;
        }
        if (!mwsToken) {
            this.setState({
                errors: ["MWS Token is invalid"]
            })
            return;
        }

        this.setState({
            loading: true,
            errors: []
        });
        api.integrations.saveInvitation({
            token: this.state._token,
            sellerId: sellerId,
            mwsToken: mwsToken
        }).then(res => {
            this.setState({
                loading: false,
                mwsToken: ""
            })
            Lara.alertSuccess("Integration activated. Feel free to close this page now.");
        }).catch(err => {
            this.setState({
                loading: false
            })
            const message = Lara.axiosError(err);
            Lara.alertError(message.message);
        })
    }
    
    render() {
        const {
            name, address, city, postal, state, country,
            login_bg_color, logo, branding_name, branding, home_address
        } = this.props.preference;
        const {t} = this.props;
        const {loading, errors, fba, sellerId, mwsToken} = this.state
        return (
            <div className="auth-container">
                <div className="auth-left-panel">                    
                    <div className="auth-form">
                        <Form>
                            <Header as='h1' textAlign='center'>
                                {t('FBA Integration')}
                            </Header>
                            <Message>
                                <Message.List>
                                    <li>Log in to your Amazon Seller Central account and go to the user User Permissions page.</li>
                                    <li>In the Amazon MWS Developer Permissions section, click the authorize a developer button.</li>
                                    <li>Enter the following in the Amazon Marketplace Web Service Form
                                        <ul>
                                            <li>Developer's Name: <strong>{fba.name}</strong></li>
                                            <li> Developer ID: <strong>{fba.id}</strong></li>
                                        </ul>
                                    </li>
                                    <li>
                                        Enter your seller ID and MWS Auth Token on the left
                                    </li>
                                    <li>
                                        Click on Save button
                                    </li>
                                </Message.List>
                            </Message>
                            {errors.length > 0 ? <FormError errors={errors} /> : null}
                                <Form.Input 
                                    size="large"
                                    required={true}
                                    label={t('Your Seller ID')} 
                                    name="sellerId" 
                                    value={sellerId} 
                                    onChange={(e, {value}) => {
                                        this.setState({sellerId: value})
                                    }}/>
                                <Form.Input 
                                    size="large"
                                    required={true}
                                    label={t('MWS Auth Token')} 
                                    name="mwsToken" 
                                    value={mwsToken} 
                                    onChange={(e, {value}) => {
                                        this.setState({mwsToken: value})
                                    }}/>
                                <AuthButton
                                    label={t('Save')}
                                    onClick={() => {
                                        this.submitForm()
                                    }}
                                    loading={loading}
                                />
                                <div className="lara-right">
                                    <Link to='/auth/login'>{t('LogIn')}</Link>
                                </div>
                            </Form>
                    </div>
                </div>
                <div className="auth-right-panel" style={login_bg_color ? {backgroundColor: login_bg_color} : null}>
                    <div className="auth__branding">
                        {logo ? <div className="auth__text-box">
                            <h1 className="auth-primary">
                                <span>
                                    <img src={logo} alt="Company Logo" className="auth-primary--logo"/>
                                </span>
                                <span className="auth-primary--main">{branding_name}</span>
                                <span className="auth-primary--sub">{branding}</span>
                            </h1>
                        </div> : null}
                    </div>
                    <div className="auth__company-info">
                        {name ? <div>
                            <a href={home_address} className="auth__company-info__link">{name}</a><br />
                        {address}, {city} {state}  {postal}, {country}
                        </div> : null}
                    </div>
                </div>
                <Alert stack={{limit: 3}} />
            </div>
        )
    }
}

FBAAccessPage.propTypes = {
    bootGuest: PropTypes.func.isRequired,
    preference: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    match: PropTypes.shape({
        params: PropTypes.object.isRequired
    })
};

export default withI18n()(FBAAccessPage);