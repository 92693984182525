import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";
import {withI18n} from 'react-i18next';
import { Form, Segment } from "semantic-ui-react";
import FormError from '../includes/messages/422';
import PageBreadCrub from '../includes/partials/PageBreadCrub';
import AlertComponent from '../includes/messages/alert';
import Lara from '../../lara';
import SegmentRibbon from '../includes/partials/SegmentRibbon';
import {
    fetchById,
    updateModelInStore,
    updateModelOnServer,
    createNewModelInStore
} from '../../actions/inventory';
import PrimaryButton from '../includes/buttons/PrimaryButton';

class InventoryEditPage extends Component {
    constructor() {
        super();
        this.state = {
            breadcrub: [
                {isLink:false, to:null, tag: '', active: true, key: 1}
            ],
            errors: [],
            loading: false
        };
    }

    componentDidMount() {
        const {match, t, model} = this.props;
        const {id} = match.params;
        if (id === 'new') {
            this.props.createNewModelInStore();
            this.setState({
                breadcrub: this.getBreadCrub(t('Inventory'), t('Create'), null)
            });
        } else {
            if (_.toString(id) !== _.toString(model.id)) {
                this.setState({
                    loading: true
                });
                this.props.fetchById(id).then(res => {
                    this.setState({
                        loading: false,
                        breadcrub: this.getBreadCrub(res.sku,
                            t('Edit'), `/inventories/${res.id}/action/view`)
                    })
                }).catch(err => {
                    const errors = Lara.axiosError(err, this.props.t);
                    if (errors.http_code === 422) {
                        this.setState({
                            errors: errors.message,
                            loading: false
                        });
                    } else {
                        this.setState({
                            loading: false
                        });
                        Lara.axiosAlert(<AlertComponent 
                            support={errors.support}
                            message={errors.message}
                            reference={errors.reference}
                            t={this.props.t}
                        />);
                    }
                    this.setState({loading: false});
                })
            } else {
                this.setState({
                    breadcrub: this.getBreadCrub(model.sku,
                        t('Edit'), 
                        `/inventories/${model.id}/action/view`)
                })
            }
        }
    }

    getBreadCrub = (name, action, viewPageLink) => {
        return [
            {isLink:false, to:null, tag: 'Inventory', active: false, key: 1},
            {isLink:viewPageLink ? true : false, to:viewPageLink, tag: _.upperFirst(name), active: false, key: 2},
            {isLink:false, to:null, tag: action, active: true, key: 3}
        ]
    }

    onFormSubmit = () => {
        const errors = this.validateInput();
        if (_.isEmpty(errors)) {
            this.setState({
                loading: true
            });
            this.props.updateModelOnServer(this.props.model).then(res => {
                this.props.history.push(`/inventories/${res.id}/action/view`);
            }).catch(err => {
                const errors = Lara.axiosError(err, this.props.t);
                if (errors.http_code === 422) {
                    this.setState({
                        errors: errors.message,
                        loading: false
                    });
                } else {
                    this.setState({
                        loading: false
                    });
                    Lara.axiosAlert(<AlertComponent 
                        support={errors.support}
                        message={errors.message}
                        reference={errors.reference}
                        t={this.props.t}
                    />);
                }
            });
        } else {
            this.setState({
                errors: errors
            });
        }
    };

    validateInput = () => {
        const {sku, name} = this.props.model;
        const errors = [];
        if (!sku) {
            errors.push(this.props.t('SKUIsRequired'));
        }
        if (!name) {
            errors.push(this.props.t('NameIsRequired'));
        }
        return errors;
    };
    
    render() {
        const {model, t, updateModelInStore} = this.props
        return (
            <div>
                {/* Breadcrub Row */}
                <div className="row">
                    <div className="col-md-12 lara-breadcrub">
                        <PageBreadCrub lists={this.state.breadcrub} t={t}/>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <Segment>
                            <SegmentRibbon label={model.name ? model.name : t('Form')} Buttons={
                                    () => {return (<React.Fragment>
                                        <PrimaryButton
                                            size='mini'
                                            float='right'
                                            label={t('Save')}
                                            onClick={() => {
                                                this.onFormSubmit()
                                            }}
                                            loading={this.state.loading}
                                        />
                                    </React.Fragment>)}
                                }/>
                        
                            <Form size="mini">
                                {!_.isEmpty(this.state.errors) ? (
                                    <FormError errors={this.state.errors} />
                                ) : null}
                                <Form.Group widths='equal'>
                                    <Form.Input 
                                        fluid 
                                        required={true}
                                        label={t("SKU")}
                                        name="sku"
                                        value={model.sku ? model.sku : ""} 
                                        onChange={(e) => { updateModelInStore(e.target.name, e.target.value, null) }}
                                    />
                                    <Form.Select
                                        label={t('Type')}
                                        required={true}
                                        options={[{text: t('InventoryItem'), value: 'Item'}, {text: t('Kits'), value: 'Kit'}]}
                                        disabled={model.id ? true : false}
                                        value={model.type}
                                        onChange={(e,data) => { updateModelInStore('type', data.value, null) }}
                                    />
                                    <Form.Input 
                                        fluid
                                        label={t("UPC")}
                                        name="upc"
                                        value={model.upc ? model.upc : ""} 
                                        onChange={(e) => { updateModelInStore(e.target.name, e.target.value, null) }}
                                    />
                                </Form.Group>
                                <Form.Group widths='equal'>
                                    <Form.Input 
                                        fluid
                                        label={t("Name")}
                                        required={true}
                                        name="name"
                                        value={model.name ? model.name : ""} 
                                        onChange={(e) => { updateModelInStore(e.target.name, e.target.value, null) }}
                                    />
                                </Form.Group>
                                <Form.Group widths='equal'>
                                    <Form.Input 
                                        fluid
                                        label={t("Description")}
                                        name="description"
                                        value={model.description ? model.description : ""} 
                                        onChange={(e) => { updateModelInStore(e.target.name, e.target.value, null) }}
                                    />
                                </Form.Group>
                                <Form.Group widths='equal'>
                                    <Form.Input 
                                        fluid
                                        label={t("Cost")}
                                        name="cost"
                                        type="number"
                                        value={model.cost ? model.cost : ""} 
                                        onChange={(e) => { updateModelInStore(e.target.name, e.target.value, null) }}
                                    />
                                    <Form.Input 
                                        fluid
                                        label={t("Selling")}
                                        name="selling"
                                        type="number"
                                        value={model.selling ? model.selling : ""} 
                                        onChange={(e) => { updateModelInStore(e.target.name, e.target.value, null) }}
                                    />
                                    <Form.Input 
                                        fluid
                                        label={t("MSRP")}
                                        name="msrp"
                                        type="number"
                                        value={model.msrp ? model.msrp : ""} 
                                        onChange={(e) => { updateModelInStore(e.target.name, e.target.value, null) }}
                                    />
                                </Form.Group>
                                <Form.Group widths='equal'>
                                    <Form.Input 
                                        fluid
                                        label={t('Barcode_1')}
                                        name="barcode_1"
                                        value={model.barcode_1 ? model.barcode_1 : ""} 
                                        onChange={(e) => { updateModelInStore(e.target.name, e.target.value, null) }}
                                    />
                                    <Form.Input 
                                        fluid
                                        label={t('Barcode_2')}
                                        name="barcode_2"
                                        value={model.barcode_2 ? model.barcode_2 : ""} 
                                        onChange={(e) => { updateModelInStore(e.target.name, e.target.value, null) }}
                                    />
                                    <Form.Input 
                                        fluid
                                        label={`${t("CustomizedValueField")} 1`}
                                        name="custom_1"
                                        value={model.custom_1 ? model.custom_1 : ""} 
                                        onChange={(e) => { updateModelInStore(e.target.name, e.target.value, null) }}
                                    />
                                    <Form.Input 
                                        fluid
                                        label={`${t("CustomizedValueField")} 2`}
                                        name="custom_2"
                                        value={model.custom_2 ? model.custom_2 : ""} 
                                        onChange={(e) => { updateModelInStore(e.target.name, e.target.value, null) }}
                                    />
                                    <Form.Input 
                                        fluid
                                        label={`${t("CustomizedValueField")} 3`}
                                        name="custom_3"
                                        value={model.custom_3 ? model.custom_3 : ""} 
                                        onChange={(e) => { updateModelInStore(e.target.name, e.target.value, null) }}
                                    />
                                </Form.Group>
                                <Form.Group widths='equal'>
                                    <Form.Input 
                                        fluid
                                        label={t("HSCODE")}
                                        name="hs_code"
                                        value={model.international.hs_code ? model.international.hs_code : ""} 
                                        onChange={(e) => { updateModelInStore(e.target.name, e.target.value, 'international') }}
                                    />
                                    <Form.Input 
                                        fluid
                                        label={t("CustomValue")}
                                        name="custom_value"
                                        value={model.international.custom_value ? model.international.custom_value : ""} 
                                        onChange={(e) => { updateModelInStore(e.target.name, e.target.value, 'international') }}
                                    />
                                    <Form.Input 
                                        fluid
                                        label={t("Origin")}
                                        name="origin"
                                        value={model.international.origin ? model.international.origin : ""} 
                                        onChange={(e) => { updateModelInStore(e.target.name, e.target.value, 'international') }}
                                    />
                                    <Form.Input 
                                        fluid
                                        label={t("Composition")}
                                        name="composition"
                                        value={model.international.composition ? model.international.composition : ""} 
                                        onChange={(e) => { updateModelInStore(e.target.name, e.target.value, 'international') }}
                                    />
                                </Form.Group>
                                <Form.Group widths='equal'>
                                    {model.type === 'Item' ? <Form.Checkbox
                                        label={t('Lot')}
                                        name="lot"
                                        onChange={(e,data) => { updateModelInStore('lot', data.checked, 'config') }}
                                        checked={model.config.lot ? true : false}
                                    /> : null}
                                    {model.type === 'Item' ? <Form.Checkbox
                                        label={t('Serial')}
                                        name="serial"
                                        onChange={(e,data) => { updateModelInStore('serial', data.checked, 'config') }}
                                        checked={model.config.serial ? true : false}
                                    /> : null}
                                    <Form.Checkbox
                                        label={t('Status')}
                                        name="is_active"
                                        onChange={(e,data) => { updateModelInStore('is_active', data.checked, null) }}
                                        checked={model.is_active}
                                    />
                                </Form.Group>
                            </Form>
                        </Segment>
                    </div>
                </div>
            </div>

        );
    }
}

InventoryEditPage.propTypes = {
    model: PropTypes.object.isRequired,
    updateModelInStore: PropTypes.func.isRequired,
    updateModelOnServer: PropTypes.func.isRequired,
    createNewModelInStore: PropTypes.func.isRequired,
    fetchById: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        model: state.inventoryModel
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            updateModelInStore,
            updateModelOnServer,
            createNewModelInStore,
            fetchById
        },
        dispatch
    );
}

export default withI18n()(connect(
    mapStateToProps,
    mapDispatchToProps
)(InventoryEditPage));