import React, { Component } from 'react';
import { bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import {Table, Input, Checkbox, Message} from 'semantic-ui-react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {
    updateShipmentLineQuantity,
    updateShipmentLineShouldUpdateOrderLine,
    shipmentLineQtyUpdated
} from '../../../actions/shipment';
import {api} from '../../../api';
import PrimaryButton from '../../includes/buttons/PrimaryButton';

class UpdateLineQtyTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: "",
            loading: false
        }
    }

    onUpdateClick = (l,index) => {
        const {updateOrderLine, updateQuantity} = l;
        this.setState({
            error: ""
        })
        let error = null;
        let fulfilled = 0;
        _.each(l.fulfillments, ful => {
            fulfilled = fulfilled + Math.abs(ful.change);
        });
        if (_.isUndefined(updateQuantity) || _.isNull(updateQuantity)) {
            error = "New quantity should be greater or equal to 0"
        }
        if (parseInt(updateQuantity,10) < fulfilled)
        {
            error = `New quantity cannot be less than fulfilled: ${fulfilled}`
        }
        if (parseInt(updateQuantity,10) < 0)
        {
            error = "New quantity should be greater or equal to 0"
        }
        if (error) {
            this.setState({
                error: error
            })
        } else {
            this.setState({
                loading: true
            }, () => {
                api.shipments.updateLineQty(l.shipment_id, l.id, updateQuantity, updateOrderLine ? true : false).then(res => {
                    this.setState(({
                        loading: false
                    }));
                    this.props.shipmentLineQtyUpdated(index, updateQuantity, res.data.data);
                }).catch(err => {
                    const {status, data} = err.response;
                    this.setState({
                        error: status === 422 ? "An error occurred with the new quantity. Please try again" : data.message
                    })
                })
            })
        }
    }

    render() {
        const {t, lines,
            updateShipmentLineQuantity,
            updateShipmentLineShouldUpdateOrderLine} = this.props;
        return (
            <div>
                {this.state.error ? <Message error>
                    <Message.Content>
                        <Message.Header>{t('Error')}</Message.Header>
                        {this.state.error}
                    </Message.Content>
                </Message> : null}
                <Table compact>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>
                                {t("SKU")}
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                {t("Qty")}
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                {t("NewQty")}
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                {t("UpdateOrderLine")}
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                {t("Confirm")}
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {lines.map((l, index)  => {
                            return (
                                <Table.Row key={l.id}>
                                    <Table.Cell>
                                        {l.inventory.sku}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {l.quantity}
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Input type="number" size="mini"
                                            value={l.updateQuantity ? l.updateQuantity :  ""}
                                            onChange={(e, {value}) => {
                                                updateShipmentLineQuantity(index, value);
                                            }}
                                        />
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Checkbox
                                            onChange={(e,data) => { updateShipmentLineShouldUpdateOrderLine(index, data.checked) }}
                                            checked={l.updateOrderLine ? true : false}
                                        />
                                    </Table.Cell>
                                    <Table.Cell>
                                        <PrimaryButton
                                            size='mini'
                                            float='left'
                                            label={t('Update')}
                                            onClick={() => {
                                                this.onUpdateClick(l, index);
                                            }}
                                            loading={this.state.loading}
                                        />
                                    </Table.Cell>
                                </Table.Row>
                            )
                        })}
                    </Table.Body>
                </Table>
            </div>
        )
    }
}

UpdateLineQtyTable.propTypes = {
    t: PropTypes.func.isRequired,
    updateShipmentLineQuantity: PropTypes.func.isRequired,
    updateShipmentLineShouldUpdateOrderLine: PropTypes.func.isRequired,
    shipmentLineQtyUpdated: PropTypes.func.isRequired,
    lines: PropTypes.array.isRequired,
    //history: PropTypes.shape({
    //    push: PropTypes.func.isRequired
    //}).isRequired,
};

function mapStateToProps(state) {
    return {
        lines: state.shipmentModel.shipment_lines
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        updateShipmentLineQuantity,
        updateShipmentLineShouldUpdateOrderLine,
        shipmentLineQtyUpdated
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateLineQtyTable)
