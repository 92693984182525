import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Segment, Message, Icon, List
} from "semantic-ui-react";
import SegmentRibbon from "../../includes/partials/SegmentRibbon";
import AlertComponent from "../../includes/messages/alert";
import Lara from "../../../lara";
import {api} from "../../../api";

class ShipStationSideUtility extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        }
    }

    componentDidMount() {
        
    }

    handleHttpErrors = (err) => {
        const errors = Lara.axiosError(err, this.props.t);
        if (errors.http_code === 422) {
            this.setState({
                errors: errors.message,
                loading: false
            });
        } else {
            this.setState({
                loading: false
            });
            Lara.axiosAlert(<AlertComponent 
                support={errors.support}
                message={errors.message}
                reference={errors.reference}
                t={this.props.t}
            />);
        }
    }

    onSendToShipStationClick = () => {
        this.setState({
            loading: true
        });
        api.shipments.sendToShipStation(this.props.model.id).then(res => {
            this.setState({
                loading: false
            });
            this.props.onChangeShipStationStatus(12345);
        }).catch(err => {

        })
    }

    onRetrieveShipStationClick = () => {
        this.setState({
            loading: true
        });
        api.shipments.retrieveFromShipStaion(this.props.model.id).then(res => {
            this.setState({
                loading: false
            });
            const {data} = res.data;
            this.props.updateShipmentProperty('tracking', data);
        }).catch(err => {
            this.handleHttpErrors(err);
            this.setState({loading: false})
        })
    }
    
    render() {
        const {model} = this.props;
        return <Segment>
            <SegmentRibbon label="ShipStation"/>
            {model.ss_order_id ? <div className="row">
                <div className="col-md-12">
                    <Message icon>
                        <Icon name='check circle outline' loading={this.state.loading} color="green"/>
                        <Message.Content>
                            <Message.Header>ShipStation Ready</Message.Header>
                            {!this.state.loading ? <List>
                                <List.Item>
                                    <span className="lara-clickable lara-editable" onClick={() => {
                                        this.onRetrieveShipStationClick();
                                    }}>Load Tracking #</span>
                                </List.Item>
                                {model.order && model.order.source && model.order.source.source !== 'ShipStation' ? <List.Item>
                                    Void this shipment will delete the order from ShipStation.
                                </List.Item> : null}
                                {model.order && model.order.source && model.order.source.source === 'ShipStation' ? <List.Item>
                                    Void this shipment will not delete this order from ShipStation because this is from ShipStation marketplace.
                                </List.Item> : null}
                            </List> : null}
                        </Message.Content>
                    </Message>
                </div>
            </div> : <div className="row">
                <div className="col-md-12">
                    <Message icon>
                        <Icon name='question circle outline' loading={this.state.loading} />
                        <Message.Content>
                            <Message.Header>Not In ShipStation</Message.Header>
                            {!this.state.loading ? <List>
                                <List.Item>
                                    <span className="lara-clickable lara-editable" onClick={() => {
                                        this.onSendToShipStationClick();
                                    }}>Send to ShipStation</span>
                                </List.Item>
                            </List> : null}
                        </Message.Content>
                    </Message>
                </div>
            </div>}
        </Segment>
    }
}

ShipStationSideUtility.propTypes = {
    updateShipmentProperty: PropTypes.func.isRequired,
    onChangeShipStationStatus: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    model: PropTypes.shape({
        id: PropTypes.number.isRequired,
        courier: PropTypes.object.isRequired
    }).isRequired
};

export default ShipStationSideUtility