import React from 'react';
import { bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
    Form
} from 'semantic-ui-react';

class UomSelector extends React.PureComponent {    
    render() {
        const {label, selectedValue, onUomSelected, uoms, associated} = this.props;
        const options = [];
        _.each(uoms, u => {
            const associatedIndex = _.findIndex(associated, asso => {
                return parseInt(asso.id,10) === parseInt(u.id, 10);
            });

            if (associatedIndex === -1) {
                options.push({
                    value: u.id,
                    index: u.id,
                    text: u.name
                });
            }
        });
        return (
            <Form.Select fluid search selection 
                label={label}
                options={options}
                name="uom_id" value={selectedValue} 
                onChange={(e, {value}) => {onUomSelected(value)}}
            />
        )
    }
}

UomSelector.propTypes = {
    associated: PropTypes.array.isRequired,
    uoms: PropTypes.array.isRequired,
    onUomSelected: PropTypes.func.isRequired,
    selectedValue: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired
};

function mapStateToProps(state) {
    return {
        uoms: state.system.resources.uoms
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(UomSelector);