import React, { Component } from 'react';
import {Table, Modal, Header} from 'semantic-ui-react';
import {withI18n} from 'react-i18next';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import {api} from '../../../api';
import Lara from '../../../lara';
import AddressForm from './AddressForm';
import AlertComponent from '../messages/alert';
import PrimaryButton from '../buttons/PrimaryButton';
import DeleteButton from '../buttons/DeleteButton';

class AddressTable extends Component {
    constructor() {
        super();
        this.state = {
            model: {
                id: null
            },
            modelIndex: null,
            showModal: false,
            formErrors: [],
            loading: false
        }
    }

    onEditClick = (addr, index) => {
        this.setState({
            model: addr,
            modelIndex: index,
            showModal: true
        });
    }

    onValueChange = (field, value) => {
        if (field === 'country_code') {
            this.setState({
                model: update(this.state.model, {
                    [field]: {$set: value.value},
                    country: {$set: value.text}
                })
            });
        } else {
            this.setState({
                model: update(this.state.model, {
                    [field]: {$set: value}
                })
            });
        }
    }

    onCreateAddrClicked = () => {
        this.setState({
            model: {
                id: null,
                company: "",
                contact: "",
                phone: "",
                street1: "",
                street2: "",
                street3: "",
                city: "",
                postal_code: "",
                state: "",
                state_code: "",
                country: "United States",
                country_code: "US",
                type: "Both"
            },
            showModal: true
        });
    }

    validateInput = () => {
        const errors = [];
        const {t} = this.props;

        if (!this.state.model.company) {
            errors.push(t("CompanyNameIsRequired"));
        }

        return errors;
    }

    onSaveSubmit = () => {
        const errors = this.validateInput();
        if (errors.length > 0) {
            this.setState({
                formErrors: errors
            });
            return;
        }
        const {model} = this.state;
        const {addressable_id, addressCreated, addressUpdated} = this.props;
        this.setState({loading: true})
        if (!model.id) {
            api.addresses.create(addressable_id, this.state.model).then(res => {
                addressCreated(res.data.data);
                this.setState({
                    formErrors: [],
                    model: res.data.data,
                    loading: false,
                    showModal: false
                });
            }).catch(err => {
                const errors = Lara.axiosError(err, this.props.t);
                if (errors.http_code === 422) {
                    this.setState({
                        formErrors: errors.message,
                        loading: false
                    });
                } else {
                    this.setState({
                        formErrors: [],
                        loading: false
                    });
                    Lara.axiosAlert(<AlertComponent 
                        support={errors.support}
                        message={errors.message}
                        reference={errors.reference}
                        t={this.props.t}
                    />);
                }
            })
        } else {
            api.addresses.update(addressable_id, this.state.model.id, this.state.model).then(res => {
                addressUpdated(this.state.model, this.state.modelIndex);
                this.setState({
                    formErrors: [],
                    loading: false
                });
            }).catch(err => {
                const errors = Lara.axiosError(err, this.props.t);
                if (errors.http_code === 422) {
                    this.setState({
                        formErrors: errors.message,
                        loading: false
                    });
                } else {
                    this.setState({
                        formErrors: [],
                        loading: false
                    });
                    Lara.axiosAlert(<AlertComponent 
                        support={errors.support}
                        message={errors.message}
                        reference={errors.reference}
                        t={this.props.t}
                    />);
                }
            })
        }
    }

    onDeleteSubmit = () => {
        this.setState({
            loading: true
        });
        const {id} = this.state.model;
        const {addressable_id, addressDeleted} = this.props;
        api.addresses.delete(addressable_id,id).then(res => {
            addressDeleted(this.state.modelIndex);
            this.setState({
                showModal: false,
                loading: false
            });
        }).catch(err => {
            const errors = Lara.axiosError(err, this.props.t);
            if (errors.http_code === 422) {
                this.setState({
                    formErrors: errors.message,
                    loading: false
                });
            } else {
                this.setState({
                    formErrors: [],
                    loading: false
                });
                Lara.axiosAlert(<AlertComponent 
                    support={errors.support}
                    message={errors.message}
                    reference={errors.reference}
                    t={this.props.t}
                />);
            }
        })
    }
    
    render() {
        const {t} = this.props;
        return (
            <div className="col-md-12 col-sm-12 col-xs-12">
                <Table compact>
                    <Table.Header fullWidth>
                        <Table.Row>
                            <Table.HeaderCell />
                            <Table.HeaderCell colSpan='6'>
                                <PrimaryButton
                                    size='mini'
                                    float='right'
                                    label={t('Create')}
                                    onClick={() => {
                                        this.onCreateAddrClicked()
                                    }}
                                    loading={this.state.loading}
                                />
                            </Table.HeaderCell>
                        </Table.Row>
                        <Table.Row>
                            <Table.HeaderCell>
                                {t('Company')}
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                {t('Contact')}
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                {t('StreetAddress')}
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                {t('City')}
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                {t('Postal')}
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                {t('State')}
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                {t('Country')}
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {this.props.addresses.map((addr, index) => {
                            return (
                                <Table.Row key={addr.id}>
                                    <Table.Cell>
                                        
                                        {isNaN(parseInt(addr.id, 10)) ? <span className='lara-single-line-text'>
                                            Address From API
                                        </span> : <span className='lara-single-line-text'>{t(`${addr.type} Address`)}</span>}
                                        {isNaN(parseInt(addr.id, 10)) ? <span>
                                            {addr.company}
                                        </span> : <span className="lara-clickable lara-editable" onClick={() => {
                                            this.onEditClick(addr, index)
                                        }}>
                                            {addr.company}
                                        </span>}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {addr.contact ? <span className='lara-single-line-text'>{addr.contact}</span>: null}
                                        {addr.phone ? <span className='lara-single-line-text'>{addr.phone}</span>: null}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {addr.street1}
                                        {addr.street2 ? <span className='lara-single-line-text'>{addr.street2}</span>: null}
                                        {addr.street3 ? <span className='lara-single-line-text'>{addr.street3}</span>: null}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {addr.city}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {addr.postal_code}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {addr.state ? <span className='lara-single-line-text'>{addr.state}</span>: null}
                                        {addr.state_code ? <span className='lara-single-line-text'>{addr.state_code}</span>: null}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {addr.country ? <span className='lara-single-line-text'>{addr.country}</span>: null}
                                        {addr.country_code ? <span className='lara-single-line-text'>{addr.country_code}</span>: null}
                                    </Table.Cell>
                                </Table.Row>
                            )
                        })}
                    </Table.Body>
                </Table>
                <Modal size="small" 
                    closeOnDimmerClick={false}
                    closeIcon
                    onClose={() => {this.setState({showModal: false})}}
                    closeOnEscape={true} open={this.state.showModal}>
                    <Header icon='address card' content='Address' />
                    <Modal.Content>
                        <AddressForm 
                            model={this.state.model}
                            onValueChange={(field, value) => {this.onValueChange(field, value)}}
                            onCreateSubmit={() => {this.onCreateSubmit()}}
                            onEditSubmit={() => {this.onEditSubmit()}}
                            onDeleteSubmit={() => {this.onDeleteSubmit()}}
                            errors={this.state.formErrors}
                            t={t}
                        />
                    </Modal.Content>
                    <Modal.Actions>
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-xs-12">
                                <DeleteButton
                                    size='mini'
                                    float='left'
                                    label={t('Delete')}
                                    onClick={() => {
                                        this.onDeleteSubmit()
                                    }}
                                    loading={this.state.loading}
                                />
                                <PrimaryButton
                                    size='mini'
                                    float='right'
                                    label={t('Save')}
                                    onClick={() => {
                                        this.onSaveSubmit()
                                    }}
                                    loading={this.state.loading}
                                />
                            </div>
                        </div>
                    </Modal.Actions>
                </Modal>
            </div>
        )
    }
}

AddressTable.propTypes = {
    t: PropTypes.func.isRequired,
    addressalbe_type: PropTypes.string.isRequired,
    addressable_id: PropTypes.number.isRequired,
    addresses: PropTypes.array.isRequired,
    addressCreated: PropTypes.func.isRequired,
    addressUpdated: PropTypes.func.isRequired,
    addressDeleted: PropTypes.func.isRequired
};

export default withI18n()(AddressTable);