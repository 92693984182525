import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import CountrySelector from '../../includes/selectors/CountrySelector';

class ShipmentAddressForm extends React.PureComponent {
    render() {
        const {t, address, onFieldChange} = this.props;
        return (
            <Form size='mini'>
                <Form.Input fluid
                    label={t('Company')}
                    value={address.company ? address.company : ""}
                    onChange={(e, {value}) => {
                        onFieldChange('company', value);
                    }}
                />
                <Form.Group widths='equal'>
                    <Form.Input fluid
                        label={t('Contact')}
                        value={address.contact ? address.contact : ""}
                        onChange={(e, {value}) => {
                            onFieldChange('contact', value);
                        }}
                    />
                    <Form.Input fluid
                        label={t('Phone')}
                        value={address.phone ? address.phone : ""}
                        onChange={(e, {value}) => {
                            onFieldChange('phone', value);
                        }}
                    />
                </Form.Group>

                <Form.Input fluid
                    label={t('Address')}
                    value={address.street1 ? address.street1 : ""}
                    onChange={(e, {value}) => {
                        onFieldChange('street1', value);
                    }}
                />
                <Form.Group widths='equal'>
                    <Form.Input fluid
                        label={t('Address')}
                        value={address.street2 ? address.street2 : ""}
                        onChange={(e, {value}) => {
                            onFieldChange('street2', value);
                        }}
                    />
                    <Form.Input fluid
                        label={t('Address')}
                        value={address.street3 ? address.street3 : ""}
                        onChange={(e, {value}) => {
                            onFieldChange('street3', value);
                        }}
                    />
                </Form.Group>
                <Form.Group widths='equal'>
                    <Form.Input fluid
                        label={t('City')}
                        value={address.city ? address.city : ""}
                        onChange={(e, {value}) => {
                            onFieldChange('city', value);
                        }}
                    />
                    <Form.Input fluid
                        label={t('Postal')}
                        value={address.postal_code ? address.postal_code : ""}
                        onChange={(e, {value}) => {
                            onFieldChange('postal_code', value);
                        }}
                    />
                </Form.Group>
                <Form.Group widths='equal'>
                    <Form.Input fluid
                        label={t('StateCode')}
                        value={address.state_code ? address.state_code : ""}
                        onChange={(e, {value}) => {
                            onFieldChange('state_code', value);
                        }}
                    />
                    <CountrySelector 
                        selected={address.country_code}
                        countrySelected={(value) => {
                            onFieldChange('country_code', value);
                        }}
                        label={t('Country')}
                    />
                </Form.Group>
            </Form>
        )
    }
}

ShipmentAddressForm.propTypes = {
    t: PropTypes.func.isRequired,
    address: PropTypes.object.isRequired,
    onFieldChange: PropTypes.func.isRequired
};

export default ShipmentAddressForm