import React, { Component } from 'react';
import { bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {Icon} from 'semantic-ui-react';
import {
    toggleSiderBarClicked
} from '../../actions/utils';
import SingleClientSelector from '../../components/includes/selectors/SingleClientSelector';
import {
    startGlobalLoading,
    finishGlobalLoading,
    switchClient
} from '../../actions/utils';
import Lara from '../../lara';
import AlertComponent from '../../components/includes/messages/alert';

class LaraHeader extends Component {
    componentDidMount() {
        
    }
    //access router
    //this.props.history.push('/dashboard')
    _onSwitchClient = ({value}) => {
        const {
            startGlobalLoading,
            finishGlobalLoading,
            switchClient
        } = this.props;

        startGlobalLoading();
        switchClient(value).then(res => {
            finishGlobalLoading();
            window.location.reload();
        }).catch(err => {
            const errors = Lara.axiosError(err, this.props.t);
            Lara.axiosAlert(<AlertComponent 
                support={errors.support}
                message={errors.message}
                reference={errors.reference}
                t={this.props.t}
            />);
        });
    }
    
    render() {
        const {clients, user, globalLoading, preference} = this.props;
        const {t_header_bg} = preference;
        return (
            <header className="lara-header" style={t_header_bg ? {backgroundColor: t_header_bg} : null}>
                <div className="lara-header__toggle">
                    <button onClick={() => {this.props.toggleSiderBarClicked()}} 
                        className="lara-header__toggle-btn">
                        <Icon name="bars" />
                    </button>
                </div>
                <div className="lara-header__selector">
                    <SingleClientSelector 
                        disabled={!user.is_main_user}
                        loading={globalLoading}
                        clients={clients}
                        selected={user.is_main_user ? user.last_working_client : user.client_id}
                        clientSelected={(data) => this._onSwitchClient(data)}
                    />
                </div>
            </header>
        )
    }
}

LaraHeader.propTypes = {
    toggleSiderBarClicked: PropTypes.func.isRequired,
    clients: PropTypes.array.isRequired,
    user: PropTypes.object.isRequired,
    startGlobalLoading: PropTypes.func.isRequired,
    finishGlobalLoading: PropTypes.func.isRequired,
    switchClient: PropTypes.func.isRequired,
    globalLoading: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
    return {
        clients: state.system.clients,
        user: state.system.user,
        globalLoading: state.config.globalLoading,
        preference: state.system.preference
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        toggleSiderBarClicked,
        startGlobalLoading,
        finishGlobalLoading,
        switchClient
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(LaraHeader)