import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import {withI18n} from 'react-i18next';

const AddressDisplayTable = ({t, address, tooltipId}) => (
    <div className="lara-text-wrapper">
        <span 
            className='lara-text-value lara-clickable lara-tooltip-title'
            data-tip data-for={tooltipId}    
        >
            {address.company}
        </span>
        <ReactTooltip id={tooltipId} place="bottom" type="dark" effect="solid">
            <div className="lara-text-wrapper">
                <span className='lara-text-value'>{address.company}</span>
                {address.phone ? <span className='lara-text-value'>{address.phone}</span> : null}
                {address.street1 ? <span className='lara-text-value'>{address.street1}</span> : null}
                {address.street2 ? <span className='lara-text-value'>{address.street2}</span> : null}
                {address.street3 ? <span className='lara-text-value'>{address.street3}</span> : null}
                <span className='lara-text-value'>
                    {address.city ? `${address.city}, `: null}
                    {address.state_code ? `${address.state_code}    `: null}
                    {address.postal_code ? `${address.postal_code}`: null}
                </span>
            </div>
        </ReactTooltip>
    </div>
)

AddressDisplayTable.propTypes = {
    tooltipId: PropTypes.string.isRequired,
    address: PropTypes.object.isRequired
};


export default withI18n()(AddressDisplayTable);