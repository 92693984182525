import update from 'immutability-helper';
import {
    SET_CLIENT_MODEL_FROM_SERVER,
    CLIENT_MODEL_UPDATED_BY_FORM,
    CLIENT_CATEGORY_DELETED,
    ADD_EMAIL_TO_CLIENT_DETAIL,
    REMOVE_EMAIL_FROM_CLIENT_DETAIL,
    WEBHOOK_ADDED_TO_CLIENT,
    WEBHOOK_MODIFIED_OF_CLIENT,
    WEBHOOK_DELETED_FROM_CLIENT
} from '../../actions/types';

export default function clientModel(state = {
    id: null,
    address: {
        
    },
    detail: {
        shipping_notification_emails: [],
        receiving_notification_emails: []
    },
    categories: [],
    webhooks: []
}, action = {}) {
    switch(action.type) {
        case SET_CLIENT_MODEL_FROM_SERVER:
            return action.payload;

        case CLIENT_MODEL_UPDATED_BY_FORM:
            if (action.payload.subset) {
                return update(state, {
                    [action.payload.subset]: {
                        [action.payload.field]: {$set: action.payload.value}
                    }
                });
            }
            return update(state, {
                [action.payload.field]: {$set: action.payload.value}
            });

        case CLIENT_CATEGORY_DELETED:
            return update(state, {
                categories: {$splice: [[action.payload, 1]]}
            });

        case ADD_EMAIL_TO_CLIENT_DETAIL:
            return update(state, {
                detail: {
                    [action.payload.subset]: {
                        $push: [action.payload.email]
                    }
                }
            });

        case REMOVE_EMAIL_FROM_CLIENT_DETAIL:
            return update(state, {
                detail: {
                    [action.payload.subset]: {
                        $splice: [[action.payload.index, 1]]
                    }
                }
            });

        case WEBHOOK_ADDED_TO_CLIENT:
            return update(state, {
                webhooks: {
                    $push: [action.payload]
                }
            });

        case WEBHOOK_MODIFIED_OF_CLIENT:
            return update(state, {
                webhooks: {
                    [action.payload.hookIndex]: {
                        $set: action.payload.data
                    }
                }
            });

        case WEBHOOK_DELETED_FROM_CLIENT:
            return update(state, {
                webhooks: {
                    $splice: [[action.payload.hookIndex, 1]]
                }
            })
            
        default: return state;
    }
}