import React, { Component } from 'react';
import {Segment, Loader, Dimmer, List, Header, Select, Confirm, Checkbox} from 'semantic-ui-react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import SegmentRibbon from '../../includes/partials/SegmentRibbon';
import AlertComponent from '../../includes/messages/alert';
import Lara from '../../../lara';
import {api} from '../../../api';

class ZaloraFulfillment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            selectedProvider: {},
            selectedMethod: "dropship",
            isOwnCarrier: false,
            providers: [],
            confirm: false
        }
    }

    componentDidMount() {
        const {id, exist_in_fba} = this.props;
        if (!exist_in_fba) {
            this.setState({
                loading: true,
                providers: []
            });
            api.shipments.zaloraProviders(id).then(res => {
                this.setState({
                    loading: false,
                    providers: _.isArray(res.data.data) ? res.data.data : [res.data.data]
                })
            }).catch(err => {
                this.handleHttpErrors(err);
            })
        }
    }

    handleHttpErrors = (err) => {
        const errors = Lara.axiosError(err, this.props.t);
        if (errors.http_code === 422) {
            this.setState({
                errors: errors.message,
                loading: false
            });
        } else {
            this.setState({
                loading: false
            });
            Lara.axiosAlert(<AlertComponent 
                support={errors.support}
                message={errors.message}
                reference={errors.reference}
                t={this.props.t}
            />);
        }
    }

    onCourierSeleted = (index) => {
        if (index > -1) {
            this.setState({
                selectedProvider: this.state.providers[index],
                confirm: true
            })
        } else {
            this.setState({
                selectedProvider: {
                    Name: this.props.courier,
                    tracking: this.props.tracking
                },
                confirm: true
            })
        }
    }

    onFulfillConfirmed = () => {
        if (_.isEmpty(this.state.selectedProvider) || !this.state.selectedProvider.Name) {
            return null;
        }
        this.setState({
            confirm: false,
            loading: true
        });
        const data = {
            method: this.state.selectedMethod,
            provider: this.state.selectedProvider,
            isOwnCarrier: this.state.isOwnCarrier
        };
        api.shipments.zaloraConfirmFulfill(this.props.id, data).then(res => {
            this.setState({
                loading: false
            })
            this.props.onZaloraFulfilled()
        }).catch(err => {
            this.handleHttpErrors(err);
        })
    }
    
    render() {
        const {exist_in_fba, t} = this.props;
        return (
            <Segment>
                <SegmentRibbon label="Zalora" Buttons={null}/>
                <Dimmer inverted active={this.state.loading}>
                    <Loader />
                </Dimmer>
                {exist_in_fba ? <div>
                    <Header as='h4' icon='warehouse' 
                        content={t('MessageZaloraFulfilled')}/>
                    <List divided relaxed>
                        <List.Item>
                            <List.Icon name='print' size='large' verticalAlign='middle' />
                            <List.Content>
                                <List.Header>{t('MessageZaloraShippingInv')}</List.Header>
                                <List.Description>
                                    <a href={`${this.props.host}/print/zalora/invoice/${this.props.print_key}`}
                                        rel="noopener noreferrer"
                                        target='_blank'>Print</a>
                                </List.Description>
                            </List.Content>
                        </List.Item>
                        <List.Item>
                            <List.Icon name='print' size='large' verticalAlign='middle' />
                            <List.Content>
                                <List.Header>{t('MessageZaloraShippingLabel')}</List.Header>
                                <List.Description>
                                    <a href={`${this.props.host}/print/zalora/shippingLabel/${this.props.print_key}`}
                                        rel="noopener noreferrer"
                                        target='_blank'>Print</a>
                                </List.Description>
                            </List.Content>
                        </List.Item>
                        <List.Item>
                            <List.Icon name='print' size='large' verticalAlign='middle' />
                            <List.Content>
                                <List.Header>{t('MessageZaloraShippingCarrierManifest')}</List.Header>
                                <List.Description>
                                    <a href={`${this.props.host}/print/zalora/carrierManifest/${this.props.print_key}`}
                                        rel="noopener noreferrer"
                                        target='_blank'>Print</a>
                                </List.Description>
                            </List.Content>
                        </List.Item>
                    </List>
                </div> : <div className="lara-row-margin">
                    <Header as='h4' icon='warehouse' 
                        content={t('MessageSelectShippingMethod')}/>
                    <Select fluid value={this.state.selectedMethod} 
                        options={[
                            {value: 'dropship', text: t('MessageZaloraDropship')},
                            {value: 'pickup', text: t('MessageZaloraPickup')},
                            {value: 'send_to_warehouse', text: t('MessageZaloraSendToWarehouse')}
                        ]}
                        onChange={(e, {value}) => {
                            this.setState({
                                selectedMethod: value
                            })
                        }}
                    />
                    <Header as='h4' icon='truck' 
                        content={t('MessageSelectZaloraCourier')}/>
                    <List divided relaxed>
                        {this.state.providers.map((p,index) => {
                            return (
                                <List.Item key={index}>
                                    <List.Content>
                                        <List.Header as='a' onClick={() => {
                                            this.onCourierSeleted(index);
                                        }}>{p.Name}</List.Header>
                                        <List.Description>
                                            {p.ApiIntegration === "1" ? "Integrated Courier" : "Require Tracking Number"}
                                        </List.Description>
                                    </List.Content>
                                </List.Item>
                            )
                        })}
                    </List>
                    <Checkbox
                        label={t('MessageZaloraFulfillUseMyOwn')}
                        onChange={(e,data) => {
                            if (data.checked) {
                                this.setState({
                                    isOwnCarrier: data.checked
                                }, () => {
                                    this.onCourierSeleted(-1);
                                })
                            } else {
                                this.setState({
                                    isOwnCarrier: data.checked
                                })
                            }
                        }}
                        checked={this.state.isOwnCarrier}
                    />
                </div>}
                <Confirm open={this.state.confirm} onCancel={() => {
                    this.setState({
                        confirm: false,
                        selectedProvider: {},
                        isOwnCarrier: false
                    })
                    }} onConfirm={() => {
                        this.onFulfillConfirmed()
                    }} content={`Fulfill using ${this.state.selectedProvider.Name}? This is a permanent action. (This may take a few seconds)`}
                />
            </Segment>
        )
    }
}

ZaloraFulfillment.propTypes = {
    id: PropTypes.number.isRequired,
    exist_in_fba: PropTypes.bool.isRequired,
    onZaloraFulfilled: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    courier: PropTypes.string.isRequired,
    tracking: PropTypes.string.isRequired,
    print_key: PropTypes.string.isRequired,
    host: PropTypes.string.isRequired
};

export default ZaloraFulfillment