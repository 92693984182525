import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {Dropdown} from 'semantic-ui-react';

const formatClients = (clients) => {
    const result = [];
    _.each(clients, client => {
        result.push({
            key: _.toString(client.id),
            value: _.toString(client.id),
            text: _.toString(client.company)
        });
    });   
    return result;
}

const hasSelection = (selected, ignoreError) => {
    if (ignoreError) {
        return false;
    }
    return parseInt(selected) <= 0
}

const SingleClientSelector = ({clients, selected, clientSelected, disabled, loading, ignoreError}) => (
    <Dropdown
        fluid
        search
        selection
        options={formatClients(clients)}
        onChange={(e, data) => {clientSelected(data)}}
        value={selected}
        disabled={disabled || loading}
        error={hasSelection(selected, ignoreError)}
    />
)

SingleClientSelector.propTypes = {
    clients: PropTypes.array.isRequired,
    selected: PropTypes.string.isRequired,
    clientSelected: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired
};


export default SingleClientSelector