import React, { Component } from 'react';
import { bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import PropTypes from 'prop-types'
import {withI18n} from 'react-i18next';
import {Segment, Table, Dimmer, Loader, Modal, Header} from 'semantic-ui-react';
import update from 'immutability-helper';
import SegmentRibbon from '../includes/partials/SegmentRibbon';
import PageBreadCrub from '../includes/partials/PageBreadCrub';
import qs from 'qs';
import {
    fetchPaginatedList,
    locationSaved
} from '../../actions/location';
import OrderBy from '../includes/tables/OrderBy';
import SearchBar from '../includes/tables/SearchBar';
import Pagination from '../includes/tables/Pagination';
import FilterBy from '../includes/tables/FilterBy';
import AlertComponent from '../includes/messages/alert';
import Lara from '../../lara';
import LaraPagination from '../../laraPagination';
import LocationForm from './LocationForm';
import ExcelUpload from '../includes/excel/Main';
import PrimaryButton from '../includes/buttons/PrimaryButton';
import SecondaryButton from '../includes/buttons/SecondaryButton';

class LocationListPage extends Component {
    constructor() {
        super();
        this.state = {
            breadcrub: [
                {isLink:false, to:null, tag: 'Location', active: true, key: 1}
            ],
            loading: false,
            routingQuery: {

            },
            routingPath: "",
            search: "",
            model: {

            },
            modelIndex: -1,
            editModal: false,
            excelModalDisplay: false
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const {params, url} = this.props.match;
        if (url !== this.state.routingPath) {
            this.setRoutingInState(params, url);
        }
    }

    componentDidMount() {
        const {params, url} = this.props.match;
        this.setRoutingInState(params,url);
    }

    setRoutingInState = ({query}, url) => {
        const q = qs.parse(query);
        //order_by
        //filter_by
        //query
        if (!_.isObject(q.filter_by)) {
            q.filter_by = {};
        }
        if (!_.isObject(q.order_by)) {
            q.order_by = {};
        }
        if (!_.isArray(q.query)) {
            q.query = [];
        }
        this.setState({
            loading: true,
            routingQuery: q,
            routingPath: url,
            search: q.search
        }, () => {
            this.fetchRecords(query)
        });
    }

    fetchRecords = () => {
        this.props.fetchPaginatedList(qs.stringify(this.state.routingQuery)).then(res => {
            this.setState({loading: false});
        }).catch(err => {
            const errors = Lara.axiosError(err, this.props.t);
            if (errors.http_code === 422) {
                this.setState({
                    errors: errors.message,
                    loading: false
                });
            } else {
                this.setState({
                    loading: false
                });
                Lara.axiosAlert(<AlertComponent
                    support={errors.support}
                    message={errors.message}
                    reference={errors.reference}
                    t={this.props.t}
                />);
            }
            this.setState({loading: false});
        });
    }

    routeToNewQuery = (newRoutingQuery) => {
        this.props.history.push(`/locations/${qs.stringify(newRoutingQuery)}`);
    }

    /*
     * For table header filters
    */
    onOrderByHeaderClicked = (routingOrderName, value) => {
        const newRoutingQuery = update(this.state.routingQuery, {
            order_by: {
                [routingOrderName]: {$set: value}
            }
        });
        this.routeToNewQuery(newRoutingQuery);
    }

    /*
     * For pagination
    */
    onPaginationClicked = (e, data) => {
        const {activePage} = data;
        const newRoutingQuery = update(this.state.routingQuery, {
            on_page: {$set: activePage}
        });
        this.routeToNewQuery(newRoutingQuery);
    }

    /*
     * For Search bar: onSearchEnterKeyPressed onSearchChange onPredefinedRouteSelected (optional)
     */
    onSearchEnterKeyPressed = (e) => {
        if (e.key === 'Enter') {
            const defaultQuery = LaraPagination.Locations(true);
            defaultQuery.search = this.state.search;
            this.setState({
                queryId: 'default'
            }, () => {
                this.routeToNewQuery(defaultQuery);
            });
        }
    }

    //New Sep 12, 2019 - add pagination per page selector
    onPerPageChanged = (newPerPage) => {
        const query = update(this.state.routingQuery, {per_page: {$set: newPerPage}});
        this.routeToNewQuery(query);
    }

    onPredefinedRouteSelected = (value) => {
        return null;
    }

    onEditClick = (loc, index) => {
        if (loc) {
            this.setState({
                model: loc,
                modelIndex: index,
                editModal: true
            })
        } else {
            this.setState({
                model: Lara.models.location(),
                modelIndex: -1,
                editModal: true
            })
        }
    }

    onCloseModal = () => {
        this.setState({
            model: {},
            modelIndex: -1,
            editModal: false
        })
    }

    onModelSaved = (model) => {
        this.props.locationSaved(this.state.modelIndex, model);
        this.setState({
            editModal: false,
            model: {},
            modelIndex: -1,
        });
    }

    onModelValueChange = (field, value) => {
        this.setState({
            model: update(this.state.model, {
                [field]: {$set: value}
            })
        })
    }

        /*
     *
     */
    onFilterSelectChange = (field, value) => {
        this.setState({
            routingQuery: update(this.state.routingQuery, {
                filter_by: {
                    [field]: {$set: value.join()}
                }
            })
        }, () => {
            this.routeToNewQuery(this.state.routingQuery);
        });
    }

    render() {
        const {routingQuery, search} = this.state;
        const {t, list} = this.props;
        const {on_page, per_page, total} = list;
        const orderBy = _.isObject(routingQuery.order_by) ? routingQuery.order_by : {};
        const filterBy = _.isObject(routingQuery.filter_by) ? routingQuery.filter_by : {
            type: "",
            is_active: ""
        };
        return (
            <div>
                {/* Breadcrub Row */}
                <div className="row">
                    <div className="col-md-12 lara-breadcrub">
                        <PageBreadCrub lists={this.state.breadcrub} t={t}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <Segment>
                            <SegmentRibbon label={`${t('Location')} ${t('Search')}`} Buttons={
                                () => {return (<React.Fragment>
                                    <PrimaryButton
                                        size='mini'
                                        float='right'
                                        label={t('Create')}
                                        onClick={() => {
                                            this.onEditClick(null, -1);
                                        }}
                                        loading={false}
                                    />
                                    <SecondaryButton
                                        size='mini'
                                        float='right'
                                        label='Excel'
                                        onClick={() => {
                                            this.setState({excelModalDisplay: true})
                                        }}
                                        loading={false}
                                    />
                                </React.Fragment>)}
                            }/>
                            <SearchBar
                                placeholder='Search by location...'
                                onSearchEnterKeyPressed={(e) => {this.onSearchEnterKeyPressed(e)}}
                                search={search}
                                onSearchChange={(search) => {this.setState({search})}}
                                onPredefinedRouteSelected={() => null}
                                options={[]}
                                havePreferenceSelect={false}
                                hasPerPageSelector={routingQuery.per_page}
                                onPerPageSelcted={(perPage) => {
                                    this.onPerPageChanged(perPage);
                                }}
                            />
                            <FilterBy
                                t={t}
                                label={t('LocationType')}
                                options={[
                                    {value: 'P', text: t('Location-P')},
                                    {value: 'S', text: t('Location-S')},
                                ]}
                                name="type"
                                currentValueString={filterBy.type}
                                onFilterSelectChange={(name, value) => {this.onFilterSelectChange(name, value)}}
                            />
                        </Segment>

                        <Segment>
                            <Dimmer inverted active={this.state.loading}>
                                <Loader />
                            </Dimmer>
                            <SegmentRibbon label={`${t('Location')} ${t('List')}`}/>
                            <div className="row lara-row-margin">
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <Table
                                        compact
                                        basic
                                        padded={false}
                                    >
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell>
                                                    <OrderBy
                                                        t={t}
                                                        name='Location'
                                                        routingOrderName='location'
                                                        currentOrder={orderBy.location}
                                                        onOrderClick={(routingOrderName, value) => {this.onOrderByHeaderClicked(routingOrderName, value)}}
                                                    />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    {t('LocationType')}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    <OrderBy
                                                        t={t}
                                                        name='LocationCategory'
                                                        routingOrderName='billing_category'
                                                        currentOrder={orderBy.billing_category}
                                                        onOrderClick={(routingOrderName, value) => {this.onOrderByHeaderClicked(routingOrderName, value)}}
                                                    />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    <OrderBy
                                                        t={t}
                                                        name='CreatedAt'
                                                        routingOrderName='created_at'
                                                        currentOrder={orderBy.created_at}
                                                        onOrderClick={(routingOrderName, value) => {this.onOrderByHeaderClicked(routingOrderName, value)}}
                                                    />
                                                </Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>

                                        <Table.Body>
                                            {list.records.map((i, index) => {
                                                return (<Table.Row key={i.id}>
                                                    <Table.Cell>
                                                        <span className="lara-clickable lara-editable" onClick={() => {
                                                            this.onEditClick(i, index);
                                                        }}>
                                                            {i.location}
                                                        </span>
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {i.type}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {i.billing_category}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {moment(i.created_at).format('MMM DD, Y').toString()}
                                                    </Table.Cell>
                                                </Table.Row>)
                                            })}
                                        </Table.Body>
                                    </Table>
                                </div>
                            </div>

                            <div className="row lara-row-margin">
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <Pagination
                                        activePage={on_page}
                                        totalRecords={total}
                                        perPage={per_page}
                                        onPageChange={(e, data) => {
                                            this.onPaginationClicked(e,data);
                                        }}
                                    />
                                </div>
                            </div>
                        </Segment>
                    </div>
                </div>
                <Modal size='large'
                    closeOnDimmerClick={false}
                    closeIcon
                    centered={false}
                    onClose={() => {this.onCloseModal()}}
                    closeOnEscape={true} open={this.state.editModal}>
                    <Header icon='pencil' content='' />
                    <Modal.Content>
                        <LocationForm
                            model={this.state.model}
                            saveSuccess={(model) => {
                                this.onModelSaved(model);
                            }}
                            onModelValueChange={(field, value) => {
                                this.onModelValueChange(field, value);
                            }}
                            t={t}
                        />
                    </Modal.Content>
                </Modal>
                <ExcelUpload
                    displayModal={this.state.excelModalDisplay}
                    onCloseModalClick={() => {
                        this.setState({excelModalDisplay: false})
                    }}
                    onUploaded={() => {
                        this.fetchRecords();
                    }}
                    type='Location'
                />
            </div>
        )
    }
}

LocationListPage.propTypes = {
    t: PropTypes.func.isRequired,
    history: PropTypes.shape({
       push: PropTypes.func.isRequired
    }).isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.shape({
        params: PropTypes.object.isRequired,
        path: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired
    }),
    fetchPaginatedList: PropTypes.func.isRequired,
    locationSaved: PropTypes.func.isRequired,
    list: PropTypes.object.isRequired
};

function mapStateToProps(state) {
    return {
        list: state.locations.list
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchPaginatedList,
        locationSaved
    }, dispatch)
}

export default withI18n()(connect(mapStateToProps, mapDispatchToProps)(LocationListPage));
