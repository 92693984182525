import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";
import {withI18n} from 'react-i18next';
import { Form, Segment, Header, Icon, Loader, Dimmer } from "semantic-ui-react";
import {Link} from 'react-router-dom';
import FormError from '../includes/messages/422';
import PageBreadCrub from '../includes/partials/PageBreadCrub';
import AlertComponent from '../includes/messages/alert';
import Lara from '../../lara';
import SegmentRibbon from '../includes/partials/SegmentRibbon';
import CountrySelector from '../includes/selectors/CountrySelector';
import CourierSelector from '../includes/selectors/CourierSelector';
import CourierPaymentSelector from '../includes/selectors/CourierPaymentSelector';
import RelationSelector from '../includes/selectors/RelationSelector';
import AddressSelector from '../includes/selectors/AddressSelector';
import ModalWrapper from '../includes/partials/ModalWrapper';
import AddressTable from '../includes/partials/AddressTable';
import OrderEditTable from './parts/OrderEditTable';

import {
    fetchById,
    updateModelInStore,
    updateModelOnServer,
    createNewModelInStore,
    relationSelectedForNewOrder,
    updateModelShippingCollectInStore
} from '../../actions/order';
import {
    setRelationModel,
    addressCreated,
    addressUpdated,
    addressDeleted
} from '../../actions/relation';
import PrimaryButton from "../includes/buttons/PrimaryButton";

class OrderEditPage extends Component {
    constructor() {
        super();
        this.state = {
            breadcrub: [
                {isLink:false, to:null, tag: '', active: true, key: 1}
            ],
            errors: [],
            loading: false,
            orderType: null,
            addressModal: false
        };
    }

    componentDidMount() {
        const {match, t, model} = this.props;
        const {type, id} = match.params;
        if (id === 'new') {
            this.props.createNewModelInStore(_.replace(type,'-',' '));
            this.setState({
                orderType: type,
                breadcrub: this.getBreadCrub(t(_.replace(type,'-',' ')), t(_.replace(type,'-',' ')), t('Create'), null)
            });
        } else {
            if (_.toString(id) !== _.toString(model.id)) {
                this.setState({
                    loading: true
                });
                this.props.fetchById(id).then(res => {
                    this.setState({
                        orderType: type,
                        loading: false,
                        breadcrub: this.getBreadCrub(res.reference, 
                            t(_.upperFirst(type)), 
                            t('Edit'), `/orders/${type}/${res.id}/action/view`)
                    })
                }).catch(err => {
                    const errors = Lara.axiosError(err, this.props.t);
                    if (errors.http_code === 422) {
                        this.setState({
                            errors: errors.message,
                            loading: false
                        });
                    } else {
                        this.setState({
                            loading: false
                        });
                        Lara.axiosAlert(<AlertComponent 
                            support={errors.support}
                            message={errors.message}
                            reference={errors.reference}
                            t={this.props.t}
                        />);
                    }
                    this.setState({loading: false});
                })
            } else {
                this.setState({
                    orderType: type,
                    breadcrub: this.getBreadCrub(model.reference, t(_.upperFirst(type)), 
                        t('Edit'), 
                        `/orders/${type}/${model.id}/action/view`)
                })
            }
        }
    }

    getBreadCrub = (name, type, action, viewPageLink) => {
        return [
            {isLink:false, to:null, tag: _.replace(type,'-',' '), active: false, key: 1},
            {isLink:viewPageLink ? true : false, to:viewPageLink, tag: _.upperFirst(name), active: false, key: 2},
            {isLink:false, to:null, tag: action, active: true, key: 3}
        ]
    }

    onFormSubmit = () => {
        const errors = this.validateInput();
        if (_.isEmpty(errors)) {
            this.setState({
                loading: true
            });
            this.props.updateModelOnServer(this.props.model).then(order => {
                this.setState({
                    errors: [],
                    loading: false
                });
                this.props.history.push(`/orders/${this.state.orderType}/${order.id}/action/view`);
            }).catch(err => {
                const errors = Lara.axiosError(err, this.props.t);
                if (errors.http_code === 422) {
                    this.setState({
                        errors: errors.message,
                        loading: false
                    });
                } else {
                    this.setState({
                        loading: false
                    });
                    Lara.axiosAlert(<AlertComponent 
                        support={errors.support}
                        message={errors.message}
                        reference={errors.reference}
                        t={this.props.t}
                    />);
                }
            });
        } else {
            this.setState({
                errors: errors
            });
        }
    };

    validateInput = () => {
        const {relation_id, id,
            shipping_address_id, 
            billing_address_id, 
            reference, 
            shipping, lines} = this.props.model;
        const errors = [];
        if (isNaN(parseInt(relation_id,10)) || (parseInt(relation_id,10) <= 0)) {
            errors.push(this.props.t('RelationIsRequired'));
        }
        if (!id) {
            //New manual order must have address id validated.
            //Existing orders do not validate order IDs, web service orders is automatically set that to 0
            if (isNaN(parseInt(shipping_address_id,10)) || (parseInt(shipping_address_id,10) <= 0)) {
                errors.push(this.props.t('ShipingAddressIsRequired'));
            }
            if (isNaN(parseInt(billing_address_id,10)) || (parseInt(billing_address_id,10) <= 0)) {
                errors.push(this.props.t('BillingAddressIsRequired'));
            }
        }
        if (!reference) {
            errors.push(this.props.t('ReferenceIsRequired'));
        }
        if (!_.isObject(shipping)) {
            errors.push(this.props.t('CourierIsRequired'));
        } else {
            if (isNaN(parseInt(shipping.courier_id,10)) || (parseInt(shipping.courier_id,10) <= 0)) {
                errors.push(this.props.t('CourierIsRequired'));
            }
        }
        if (_.isEmpty(lines)) {
            errors.push(this.props.t('AtLeastOneLineItemIsRequired'));
        }
        _.each(lines, (l, index) => {
            if (!_.isObject(l)) {
                errors.push(`'InvalidLineItem' ${index+1}`)
            }
            if (isNaN(parseInt(l.quantity, 10))) {
                errors.push(`'InvalidLineQuantity' ${index+1}`)
            }
            if (isNaN(parseInt(l.price, 10))) {
                errors.push(`'InvalidLinePrice' ${index+1}`)
            }
        });
        return errors;
    };

    onNewOrderRelationSelected = (val) => {
        if (!_.isObject(val)) {
            Lara.axiosAlert(<AlertComponent 
                support={false}
                message='Invalid Relation Selected, please try to search again.'
                reference=''
                t={this.props.t}
            />);
            return null;
        }
        if (!val.id) {
            Lara.axiosAlert(<AlertComponent 
                support={false}
                message='Invalid Relation Selected, please try to search again.'
                reference=''
                t={this.props.t}
            />);
            return null;
        }
        let disableAddressPopulate = false;
        if (_.isObject(this.props.serverPref)) {
            if (this.props.serverPref.hasOwnProperty("disable_order_address_autofill_bool")) {
                disableAddressPopulate = this.props.serverPref.disable_order_address_autofill_bool;
            }
        }
        this.props.relationSelectedForNewOrder(val, disableAddressPopulate);
        this.props.setRelationModel(val);
    }

    onAddressSelected = (type, val) => {
        if (!val.id) {
            Lara.axiosAlert(<AlertComponent 
                support={false}
                message='Invalid Address Selected, please try again.'
                reference=''
                t={this.props.t}
            />);
            return null;
        }
        if (type === 'billing_address_id') {
            this.props.updateModelInStore('billing_address_id', val.id, null);
            this.props.updateModelInStore('billing_address', val, null);
        }
        if (type === 'shipping_address_id') {
            this.props.updateModelInStore('shipping_address_id', val.id, null);
            this.props.updateModelInStore('shipping_address', val, null);
        }
    }

    onCourierSelected = (val) => {
        if (!val.id) {
            Lara.axiosAlert(<AlertComponent 
                support={false}
                message='Invalid Courier Selected, please try again.'
                reference=''
                t={this.props.t}
            />);
            return null;
        }
        this.props.updateModelInStore('shipping', {
            api: 'Manual',
            courier_id: val.id,
            payment: val.payment_method,
            collect_account: {
                account: val.account,
                collect_country_code: val.billing_country_code,
                collect_zip_code: val.billing_zip
            }
        }, null);
    }

    parseRelationName = (name) => {
        if (name === '__CustomerByWebService') {
            return this.props.model.source.source;
        }
        if (name === '__InventoryAdjustment') {
            return "Inventory Adjustment"
        }
        return name;
    }

    render() {
        const {model, t, updateModelInStore} = this.props;
        const {relation} = model;
        return (
            <div>
                {/* Breadcrub Row */}
                <div className="row">
                    <div className="col-md-12 lara-breadcrub">
                        <PageBreadCrub lists={this.state.breadcrub} t={t}/>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <Segment>
                            <SegmentRibbon label={model.type ? model.type : t('Form')} Buttons={
                                    () => {return (<React.Fragment>
                                        <PrimaryButton
                                            size='mini'
                                            float='right'
                                            label={t('Save')}
                                            onClick={() => this.onFormSubmit()}
                                            loading={this.state.loading}
                                        />
                                    </React.Fragment>)}
                                }/>

                            <Dimmer inverted active={this.state.loading}>
                                <Loader />
                            </Dimmer>
                            
                            <Dimmer active={model.status !== 'Open'}>
                                <Header as='h2' icon inverted>
                                    <Icon name='alarm' />
                                    {t('AlertCannotEdit')}
                                    <Header.Subheader>
                                        <Link to={`/orders/${this.state.orderType}/${model.id}/action/view`}>
                                            {t('AlertGoBack')}
                                        </Link>
                                    </Header.Subheader>
                                </Header>
                            </Dimmer>
                            
                            <Form size="mini">
                                {!_.isEmpty(this.state.errors) ? (
                                    <FormError errors={this.state.errors} />
                                ) : null}
                                <div className = "row lara-row-margin">
                                    <div className="col-md-12">
                                        {model.id ? 
                                        <div className="row">
                                            <div className="col-md-6">
                                                <Header as='h3'>
                                                    <Icon name='user' />
                                                    <Header.Content>
                                                        {relation ? this.parseRelationName(relation.name) : 'Not Available'}
                                                        <Header.Subheader>{t('CannotModifyRelation')}</Header.Subheader>
                                                    </Header.Content>
                                                </Header> 
                                            </div>
                                            <div className="col-md-6">
                                                {parseInt(model.relation_id, 10) > 0 ? <PrimaryButton 
                                                    size='mini'
                                                    float='right'
                                                    label={`${t('Edit')} ${t('Address')}`}
                                                    onClick={() => {
                                                        this.setState({addressModal: true})
                                                    }}
                                                    loading={false}
                                                /> : null}
                                            </div>
                                        </div> : 
                                        <div>
                                            <Header as='h5' dividing color='grey'>
                                                {model.type && model.type.includes('Customer') ? t('Customer') : t('Vendor')}
                                            </Header>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <RelationSelector 
                                                        onSelected={(val) => {this.onNewOrderRelationSelected(val)}}
                                                        orderType={model.type ? model.type : 'NA'}
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    {parseInt(model.relation_id, 10) > 0 ? <PrimaryButton 
                                                    size='mini'
                                                    float='right'
                                                    label={`${t('Edit')} ${t('Address')}`}
                                                    onClick={() => {
                                                        this.setState({addressModal: true})
                                                    }}
                                                    loading={false}
                                                /> : null}
                                                </div>
                                            </div>
                                        </div>}
                                    </div>

                                </div>
                                {parseInt(model.relation_id, 10) > 0 ? <div className="row lara-row-margin">
                                    <div className="col-md-12">
                                        <Header as='h5' dividing color='grey'>
                                            {t('EssentialInfo')}
                                        </Header>
                                        {parseInt(model.api_credential_id,10) > 111 ? <div className="row">
                                            <div className="col-md-12">
                                                Hello
                                            </div>
                                        </div> : 
                                        <Form.Group widths='equal'>
                                            <AddressSelector
                                                type='Billing'
                                                onSelectChange={(val) => {
                                                    this.onAddressSelected('billing_address_id', val)
                                                }}
                                                addresses={this.props.relationModel.addresses}
                                                appendNonOption={false}
                                                returnObject={true}
                                                label={t('BillingAddress')}
                                                selected={model.billing_address_id}
                                            />
                                            <AddressSelector
                                                type='Shipping'
                                                onSelectChange={(val) => {
                                                    this.onAddressSelected('shipping_address_id', val)
                                                }}
                                                addresses={this.props.relationModel.addresses}
                                                appendNonOption={false}
                                                returnObject={true}
                                                label={t('ShippingAddress')}
                                                selected={model.shipping_address_id}
                                            />
                                        </Form.Group>}
                                        <Form.Group widths='equal'>
                                            <Form.Input 
                                                fluid 
                                                required={true}
                                                label={t("Reference")}
                                                name="reference"
                                                value={model.reference ? model.reference : ""} 
                                                onChange={(e) => { updateModelInStore(e.target.name, e.target.value) }}
                                            />
                                            <Form.Input 
                                                fluid
                                                label={t("PO")}
                                                name="po"
                                                value={model.po ? model.po : ""} 
                                                onChange={(e) => { updateModelInStore(e.target.name, e.target.value) }}
                                            />
                                            <CourierSelector
                                                onSelectChange={(val) => {
                                                    this.onCourierSelected(val);
                                                }}
                                                appendNonOption={false}
                                                returnObject={true}
                                                selected={model.shipping.courier_id}
                                                label={t('Courier')}
                                                required={true}
                                            />
                                        </Form.Group>
                                        <Header as='h5' dividing color='grey'>
                                            {t('OptionalShippingAccount')}
                                        </Header>
                                        <Form.Group widths='equal'>
                                            <Form.Input 
                                                fluid
                                                label={t("PaymentTerm")}
                                                name="payment_term"
                                                value={model.accounting.payment_term ? model.accounting.payment_term : ""} 
                                                onChange={(e) => { updateModelInStore(e.target.name, e.target.value, 'accounting') }}
                                            />
                                            <Form.Input 
                                                fluid
                                                type='number'
                                                label={t("TaxRate")}
                                                name="tax_rate"
                                                value={model.accounting.tax_rate ? model.accounting.tax_rate : ""} 
                                                onChange={(e) => { updateModelInStore(e.target.name, e.target.value, 'accounting') }}
                                            />
                                            <CourierPaymentSelector
                                                t={t}
                                                onSelectChange={(val) => {
                                                    updateModelInStore('payment', val, 'shipping')
                                                }}
                                                selected={model.shipping.payment}
                                                label={t("CourierPaymentMethod")}
                                            />
                                        </Form.Group>
                                        {model.shipping.payment !== 'prepaid' ? <Form.Group widths='equal'>
                                            <Form.Input 
                                                fluid
                                                label={t("CourierAccount")}
                                                name="account"
                                                value={model.shipping.collect_account.account ? model.shipping.collect_account.account : ""} 
                                                onChange={(e) => { this.props.updateModelShippingCollectInStore(e.target.name, e.target.value) }}
                                            />
                                            <CountrySelector
                                                selected={model.shipping.collect_account.collect_country_code}
                                                countrySelected={(val) => {
                                                    this.props.updateModelShippingCollectInStore('collect_country_code', val)
                                                }}
                                                label={t("CourierBillingCountry")}
                                            />
                                            <Form.Input 
                                                fluid
                                                label={t("CourierBillingPostal")}
                                                name="collect_zip_code"
                                                value={model.shipping.collect_account.collect_zip_code ? model.shipping.collect_account.collect_zip_code : ""} 
                                                onChange={(e) => { this.props.updateModelShippingCollectInStore(e.target.name, e.target.value) }}
                                            />
                                        </Form.Group> : null}
                                        <Header as='h5' dividing color='grey'>
                                            {t('OrderLineItems')}
                                        </Header>
                                        <OrderEditTable />
                                        <Header as='h5' dividing color='grey'>
                                            {t('OrderNotes')}
                                        </Header>
                                        <Form.Group widths='equal'>
                                            <Form.TextArea
                                                label={t('WarehouseNote')}
                                                value={model.note ? model.note : ""}
                                                onChange={(e, {value}) => {
                                                    updateModelInStore('note', value, null)
                                                }}
                                            />
                                            <Form.TextArea
                                                label={t('DocumentNote')}
                                                value={model.print_note ? model.print_note : ""}
                                                onChange={(e, {value}) => {
                                                    updateModelInStore('print_note', value, null)
                                                }}
                                            />
                                        </Form.Group>
                                    </div>
                                </div> : null}                                
                            </Form>
                        </Segment>
                    </div>
                </div>
                {(parseInt(model.relation_id, 10)) > 0 && 
                    (parseInt(model.relation_id, 10) === parseInt(this.props.relationModel.id,10)) ? <ModalWrapper
                    showModal={this.state.addressModal}
                    onModalClose={() => {
                        this.setState({addressModal: false})
                    }}
                    SubComponent={AddressTable}
                    t={t}
                    addressalbe_type="relation"
                    addressable_id={this.props.relationModel.id}
                    addresses={this.props.relationModel.addresses}
                    addressCreated={(addr) => {this.props.addressCreated(addr)}}
                    addressUpdated={(addr, index) => {this.props.addressUpdated(addr, index)}}
                    addressDeleted={(index) => {this.props.addressDeleted(index)}}
                    size='large'
                /> : null}
            </div>

        );
    }
}

OrderEditPage.propTypes = {
    model: PropTypes.object.isRequired,
    relationModel: PropTypes.shape({
        addresses: PropTypes.array.isRequired
    }),
    updateModelInStore: PropTypes.func.isRequired,
    updateModelOnServer: PropTypes.func.isRequired,
    createNewModelInStore: PropTypes.func.isRequired,
    relationSelectedForNewOrder: PropTypes.func.isRequired,
    updateModelShippingCollectInStore: PropTypes.func.isRequired,
    setRelationModel: PropTypes.func.isRequired,
    fetchById: PropTypes.func.isRequired,
    addressCreated: PropTypes.func.isRequired,
    addressUpdated: PropTypes.func.isRequired,
    addressDeleted: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        model: state.orderModel,
        relationModel: state.relationModel,
        serverPref: state.system.server_pref
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            updateModelInStore,
            updateModelOnServer,
            createNewModelInStore,
            relationSelectedForNewOrder,
            setRelationModel,
            fetchById,
            addressCreated,
            addressUpdated,
            addressDeleted,
            updateModelShippingCollectInStore
        },
        dispatch
    );
}

export default withI18n()(connect(
    mapStateToProps,
    mapDispatchToProps
)(OrderEditPage));