import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";
import {withI18n} from 'react-i18next';
import { Form, Segment, Message, Icon, Label} from "semantic-ui-react";
import FormError from '../includes/messages/422';
import PageBreadCrub from '../includes/partials/PageBreadCrub';
import AlertComponent from '../includes/messages/alert';
import Lara from '../../lara';
import SegmentRibbon from '../includes/partials/SegmentRibbon';
import InventorySelector from '../includes/selectors/InventorySelector';
import {
    fetchById,
    updateModelInStore,
    updateModelOnServer,
    createNewModelInStore,
    batchNumbersGenerated,
    singleSerialCreated,
    singleSerialDeleted
} from '../../actions/serial';
import PrimaryButton from '../includes/buttons/PrimaryButton';

class SerialEditPage extends Component {
    constructor() {
        super();
        this.state = {
            breadcrub: [
                {isLink:false, to:null, tag: '', active: true, key: 1}
            ],
            errors: [],
            loading: false,
            scan: ""
        };
    }

    componentDidMount() {
        const {match, t} = this.props;
        const {id} = match.params;
        const type = 'Serial Number';
        if (id === 'new') {
            this.props.createNewModelInStore();
            this.setState({
                breadcrub: this.getBreadCrub(type, t(_.upperFirst(type)), t('Create'), null)
            });
        } else {
            this.props.history.push(`/serials/${id}/action/view`)
        }
    }

    getBreadCrub = (name, type, action, viewPageLink) => {
        return [
            {isLink:false, to:null, tag: _.upperFirst(type), active: false, key: 1},
            {isLink:viewPageLink ? true : false, to:viewPageLink, tag: _.upperFirst(name), active: false, key: 2},
            {isLink:false, to:null, tag: action, active: true, key: 3}
        ]
    }

    onFormSubmit = () => {
        const errors = this.validateInput();
        if (_.isEmpty(errors)) {
            this.setState({
                loading: true
            });
            this.props.updateModelOnServer(this.props.model).then(res => {
                this.props.history.push(`/serials/${res.data.data.id}/action/view`);
            }).catch(err => {
                const errors = Lara.axiosError(err, this.props.t);
                if (errors.http_code === 422) {
                    this.setState({
                        errors: errors.message,
                        loading: false
                    });
                } else {
                    this.setState({
                        loading: false
                    });
                    Lara.axiosAlert(<AlertComponent 
                        support={errors.support}
                        message={errors.message}
                        reference={errors.reference}
                        t={this.props.t}
                    />);
                }
            });
        } else {
            this.setState({
                errors: errors
            });
        }
    };

    validateInput = () => {
        const {inventory_id, increment, lead, units, serials} = this.props.model;
        const errors = [];
        if (!inventory_id) {
            errors.push(this.props.t('InventoryIsRequired'));
        }
        if (!increment) {
            this.props.updateModelInStore('increment', 1);
        }
        if (serials.length <= 0) {
            errors.push(this.props.t("AtLeastOneSerialNumberIsRequired"));
        }
        if (!lead) {
            if (serials.length.length > 0) {
                this.props.updateModelInStore('lead', serials.length[0].serial);
            }
        }
        if (parseInt(units,10) <= 0) {
            errors.push(this.props.t("UnitsMustBeGreaterThan0"));
        } 
        return errors;
    };

    onInventorySelected = (data) => {
        const {id, sku, name} = data;
        if (!id) {
            return null;
        }
        this.props.updateModelInStore('inventory_id', id);
        this.props.updateModelInStore('inventory', {
            id, sku, name
        });
    }

    onGenerateClick = () => {
        const {lead, increment, units} = this.props.model;
        let i;
        let serials = [];
        if (!_.isInteger(parseInt(increment))) {
            return;
        }
        if (!_.isInteger(parseInt(units))) {
            return;
        }

        if (isNaN(lead)) {
            let lead_number_pre=lead.substr(0,lead.length - 4);
            let lead_number_after=lead.substr(lead.length - 4);

            if(parseInt(lead_number_after.charAt(0)) === 0){
                Lara.alertError("Cannot parse the serial number, please use manual scan");
                return;
            }

            if(isNaN(lead_number_after)){
                Lara.alertError("The last 4 characters must be a number (auto generate), please use manual scan");
                return;
            }

            for(i=0; i<units; i++){
                let serial_after_now=parseInt(lead_number_after)+(parseInt(increment)*i);
                serials.push({
                    serial: lead_number_pre+serial_after_now
                });
            }
        } else {
            for (i=0; i<units; i++) {
                serials.push({
                    serial: parseInt(lead)+(parseInt(increment)*i)
                });
            }
        }

        this.props.batchNumbersGenerated(serials);
    }

    onScanKeyEntered = (e) => {
        if (!this.state.scan) {
            return;
        }
        if (e.key === 'Enter') {
            this.props.singleSerialCreated({serial: this.state.scan});
            this.setState({
                scan: ""
            });
            this.props.updateModelInStore('units', (parseInt(this.props.model.units,10) + 1));
        }
    }
    
    onDeleteSingleClick = (index) => {
        this.props.singleSerialDeleted(index);
    }

    render() {
        const {model, t, updateModelInStore} = this.props
        return (
            <div>
                {/* Breadcrub Row */}
                <div className="row">
                    <div className="col-md-12 lara-breadcrub">
                        <PageBreadCrub lists={this.state.breadcrub} t={t}/>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-8 col-sm-8 col-xs-12">
                        <Segment>
                            <SegmentRibbon label={model.inventory.sku ? model.inventory.sku : t('Form')} Buttons={
                                    () => {return (<React.Fragment>
                                        <PrimaryButton
                                            size='mini'
                                            float='right'
                                            label={t('Save')}
                                            onClick={() => {
                                                this.onFormSubmit()
                                            }}
                                            loading={this.state.loading}
                                        />
                                    </React.Fragment>)}
                                }/>
                        
                            <Form size="mini">
                                {!_.isEmpty(this.state.errors) ? (
                                    <FormError errors={this.state.errors} />
                                ) : null}
                                <span className="lara-ui-label">{t("Inventory")}</span>
                                <InventorySelector
                                    onSelected={(data) => {
                                        this.onInventorySelected(data);
                                    }}
                                    exceptIds={[]}
                                    canSearchKit={false}
                                />
                                <div className="lara-row-margin">
                                    <Form.Group widths='equal'>
                                        <Form.Input 
                                            fluid 
                                            required={true}
                                            label={t("Lead")}
                                            name="lead"
                                            value={model.lead ? model.lead : ""} 
                                            onChange={(e) => { updateModelInStore(e.target.name, e.target.value) }}
                                        />
                                        <Form.Input 
                                            fluid
                                            label={t("Increment")}
                                            name="increment"
                                            value={model.increment ? model.increment : ""} 
                                            onChange={(e) => { updateModelInStore(e.target.name, e.target.value) }}
                                        />
                                        <Form.Input 
                                            fluid
                                            type='number'
                                            required={true}
                                            label={t("UnitCount")}
                                            name="units"
                                            value={model.units ? model.units : ""} 
                                            onChange={(e) => { updateModelInStore(e.target.name, e.target.value) }}
                                        />
                                    </Form.Group>
                                </div>
                                <PrimaryButton
                                    size='mini'
                                    float='right'
                                    label={t('Generate')}
                                    onClick={() => {this.onGenerateClick()}}
                                    loading={this.state.loading}
                                />
                            </Form>
                            <div className="lara-row-margin">
                                <Form.Input fluid
                                    placeholder="Manual scan (press enter)..."
                                    size='mini'
                                    value={this.state.scan}
                                    onChange={(e, {value}) => {
                                        this.setState({scan: value})
                                    }}
                                    onKeyPress={(e) => {this.onScanKeyEntered(e)}}
                                />
                            </div>

                            <div className="lara-row-margin">
                                {model.serials.map((s,index) => {
                                    return (
                                        <Label key={index} size='mini'>
                                            {s.serial}
                                            <Icon name='delete' onClick={() => {
                                                this.onDeleteSingleClick(index)
                                            }}/>
                                        </Label>
                                    )
                                })}
                            </div>
                        </Segment>
                    </div>

                    <div className="col-md-4 col-sm-4 col-xs-12">
                        <Segment>
                            <SegmentRibbon label={model.name ? model.name : t('Note')} Buttons={null}/>
                            <Message icon>
                                
                                <Message.Content>
                                    <Message.Header>{t("Serial Number")}</Message.Header>
                                    <p>
                                        {t("MessageSerialClient")}
                                    </p>
                                </Message.Content>
                                <Icon name='arrow alternate circle up outline' />
                            </Message>
                        </Segment>
                    </div>
                </div>
            </div>

        );
    }
}

SerialEditPage.propTypes = {
    model: PropTypes.object.isRequired,
    updateModelInStore: PropTypes.func.isRequired,
    updateModelOnServer: PropTypes.func.isRequired,
    createNewModelInStore: PropTypes.func.isRequired,
    batchNumbersGenerated: PropTypes.func.isRequired,
    singleSerialCreated: PropTypes.func.isRequired,
    singleSerialDeleted: PropTypes.func.isRequired,
    fetchById: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        model: state.serialModel
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            updateModelInStore,
            updateModelOnServer,
            createNewModelInStore,
            batchNumbersGenerated,
            singleSerialCreated,
            singleSerialDeleted,
            fetchById
        },
        dispatch
    );
}

export default withI18n()(connect(
    mapStateToProps,
    mapDispatchToProps
)(SerialEditPage));