import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ResponsiveLine } from '@nivo/line';
import {Segment, Statistic} from 'semantic-ui-react';
import SegmentRibbon from '../includes/partials/SegmentRibbon';
import PageBreadCrub from '../includes/partials/PageBreadCrub';
import DashboardSidePanel from './DashboardSidePanel';
import {api} from '../../api';

class ClientUserDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            stats: {
                performances: [],
                numbers: {
                    to_be_shipped: 0,
                    to_be_received: 0,
                    inventories_count: 0
                }
            },
            y3pl: [

            ]
        }
    }

    componentDidMount() {
        this.setState({
            loading: false
        }, () => {
            api.system.dashboard().then(res => {
                this.setState({
                    stats: res.data.data,
                    y3pl: []
                }, () => {
                    this.setState({
                        loading: false
                    })
                })
            }).catch(err => {
                this.setState({
                    loading: false
                })
            })
        });
        api.system.y3plMessage().then(res => {
            this.setState({
                y3pl: res.data.data
            })
        })
    }
    
    render() {
        const {t, user, host} = this.props;
        return (
            <div>
                {/* Breadcrub Row */}
                <div className="row">
                    <div className="col-md-8 col-sm-8 col-xs-12 lara-breadcrub">
                        <PageBreadCrub lists={[
                            {isLink:false, to:null, tag: 'Dashboard', active: true, key: 1}
                        ]} t={t}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-9 col-sm-9 col-xs-12">
                        <div>
                            <Segment>
                                <SegmentRibbon label="Dashboard" Buttons={null}/>
                                <div className="row lara-row-bottom-margin">
                                    <div className="col-md-12 col-sm-12 col-xs-12">
                                        <Statistic.Group widths={3}>
                                            <Statistic>
                                                <Statistic.Value>{this.state.stats.numbers.to_be_shipped}</Statistic.Value>
                                                <Statistic.Label>To Be Shipped</Statistic.Label>
                                            </Statistic>
                                            <Statistic>
                                                <Statistic.Value>{this.state.stats.numbers.to_be_received}</Statistic.Value>
                                                <Statistic.Label>To Be Received</Statistic.Label>
                                            </Statistic>
                                            <Statistic>
                                                <Statistic.Value>{this.state.stats.numbers.inventories_count}</Statistic.Value>
                                                <Statistic.Label>Inventories</Statistic.Label>
                                            </Statistic>
                                        </Statistic.Group>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 col-sm-12 col-xs-12 lara-chart">
                                        {!this.state.loading ? <ResponsiveLine
                                            data={_.isArray(this.state.stats.performances) ? this.state.stats.performances : []}
                                            margin={{
                                                "top": 50,
                                                "right": 110,
                                                "bottom": 50,
                                                "left": 60
                                            }}
                                            xScale={{
                                                "type": "point"
                                            }}
                                            yScale={{
                                                "type": "linear",
                                                "stacked": true,
                                                "min": "auto",
                                                "max": "auto"
                                            }}
                                            axisTop={null}
                                            axisRight={null}
                                            axisBottom={{
                                                "orient": "bottom",
                                                "tickSize": 5,
                                                "tickPadding": 5,
                                                "tickRotation": 0,
                                                "legend": "Period",
                                                "legendOffset": 36,
                                                "legendPosition": "middle"
                                            }}
                                            axisLeft={{
                                                "orient": "left",
                                                "tickSize": 5,
                                                "tickPadding": 5,
                                                "tickRotation": 0,
                                                "legend": "Outbound Orders",
                                                "legendOffset": -40,
                                                "legendPosition": "middle"
                                            }}
                                            dotSize={10}
                                            dotColor="inherit:darker(0.3)"
                                            dotBorderWidth={2}
                                            dotBorderColor="#ffffff"
                                            enableDotLabel={true}
                                            dotLabel="y"
                                            dotLabelYOffset={-12}
                                            animate={true}
                                            motionStiffness={90}
                                            motionDamping={15}
                                            legends={[
                                                {
                                                    "anchor": "bottom-right",
                                                    "direction": "column",
                                                    "justify": false,
                                                    "translateX": 100,
                                                    "translateY": 0,
                                                    "itemsSpacing": 0,
                                                    "itemDirection": "left-to-right",
                                                    "itemWidth": 80,
                                                    "itemHeight": 20,
                                                    "itemOpacity": 0.75,
                                                    "symbolSize": 12,
                                                    "symbolShape": "circle",
                                                    "symbolBorderColor": "rgba(0, 0, 0, .5)",
                                                    "effects": [
                                                        {
                                                            "on": "hover",
                                                            "style": {
                                                                "itemBackground": "rgba(0, 0, 0, .03)",
                                                                "itemOpacity": 1
                                                            }
                                                        }
                                                    ]
                                                }
                                            ]}
                                        /> : null}
                                    </div>
                                </div>
                            </Segment>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-3 col-xs-12">
                        <DashboardSidePanel
                            user={user}
                            host={host}
                            messages={this.state.y3pl}
                        />
                    </div>
                </div>
            </div>

        )
    }
}

ClientUserDashboard.propTypes = {
    user: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    host: PropTypes.string.isRequired
};

export default ClientUserDashboard