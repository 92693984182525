import update from 'immutability-helper';
import {
    SET_BILLING_ITEM_MODEL,
    BILLING_ITEM_MODEL_UPDATED_BY_FORM
} from '../../actions/types'

export default function itemModel(state = {
    id: null
}, action = {}) {
    switch(action.type) {
        case SET_BILLING_ITEM_MODEL:
            return action.payload;

        case BILLING_ITEM_MODEL_UPDATED_BY_FORM:
            return update(state, {
                [action.payload.field]: {
                    $set: action.payload.value
                }
            })

        default: return state;
    }
}