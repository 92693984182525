import React from "react";
import PropTypes from "prop-types";

import UserLayout from './routes/User';
import GuestLayout from './routes/Guest';

import LoginPage from './components/auth/LoginPage';
import ForgetPasswordPage from './components/auth/ForgetPasswordPage';
import PasswordResetPage from './components/auth/PasswordResetPage';
import FBAAcceptPage from './components/auth/FBAAccept';

import DashboardPage from './components/HomePage';
import RelationListPage from './components/relations/RelationListPage';
import RelationViewPage from './components/relations/RelationViewPage';
import RelationEditPage from './components/relations/RelationEditPage';
import InventoryListPage from './components/inventories/InventoryListPage';
import InventoryBasicListPage from "./components/inventories/InventoryBasicListPage";
import InventoryViewPage from './components/inventories/InventoryViewPage';
import InventoryEditPage from './components/inventories/InventoryEditPage';
import OrderListPage from './components/orders/OrderListPage';
import OrderListBasicPage from './components/orders/OrderListBasicPage';
import OrderViewPage from './components/orders/OrderViewPage';
import OrderEditPage from './components/orders/OrderEditPage';
import OrderShipmentViewPage from './components/orders/OrderShipmentViewPage';

import CompanySettingViewPage from './components/settings/CompanySettingViewPage';
import CompanySettingEditPage from './components/settings/CompanySettingEditPage';
import UserListPage from './components/settings/UserListPage';
import UserViewPage from './components/settings/UserViewPage';
import UserEditPage from './components/settings/UserEditPage';
import ClientListPage from './components/settings/ClientListPage';
import ClientViewPage from './components/settings/ClientViewPage';
import ClientEditPage from './components/settings/ClientEditPage';
import MyProfilePage from './components/settings/MyProfilePage';
import ConfigurationViewPage from "./components/settings/ConfigurationViewPage";

import BillingItemPage from './components/billing/items/ItemListPage';
import BillingActivityListPage from './components/billing/activities/ActivityListPage';
import BillingActivityEditPage from './components/billing/activities/ActivityEditPage';
import BillingActivityViewPage from './components/billing/activities/ActivityViewPage';
import BillingEntryListPage from './components/billing/entries/BillingEntryListPage';
import BillingEntryNewPage from './components/billing/entries/BillingEntryNewPage';

import ApiListPage from './components/integraionts/ApiListPage';
import ApiViewPage from './components/integraionts/ApiViewPage';
import ApiEditPage from './components/integraionts/ApiEditPage';

import LocationListPage from './components/locations/LocationListPage';
import LicenseListPage from './components/licenses/LicenseListPage';

import SerialListPage from './components/serials/SerialListPage';
import SerialViewPage from './components/serials/SerialViewPage';
import SerialEditPage from './components/serials/SerialEditPage';

import ShipmentListPage from './components/shipments/ShipmentListPage';
import ShipmentViewPage from './components/shipments/ShipmentViewPage';
import ShipmentPackPage from './components/shipments/ShipmentPackPage';
import FBAFulfillmentPage from './components/shipments/FBAFulfillmentPage';

import OperationReportPage from './components/reports/OperationReport';
import ClientBillingReport from './components/reports/ClientBillingReport';

const App = (props) => <div>
    <GuestLayout path="/auth/login" exact {...props} component={LoginPage} />
    <GuestLayout path="/auth/forget-password" exact {...props} component={ForgetPasswordPage} />
    <GuestLayout path="/auth/reset-password/:token" exact {...props} component={PasswordResetPage} />
    <GuestLayout path="/auth/fba/:token" exact {...props} component={FBAAcceptPage} />

    <UserLayout path="/" exact {...props} component={DashboardPage} />

    <UserLayout path="/relations/:type/:query" exact {...props} component={RelationListPage} />
    <UserLayout path="/relations/:type/:id/action/view" exact {...props} component={RelationViewPage} />
    <UserLayout path="/relations/:type/:id/action/edit" exact {...props} component={RelationEditPage} />

    <UserLayout path="/inventories/:queryId/:query" exact {...props} component={InventoryListPage} />
    {/*//------Start: July 18, 2020 Update-----//*/}
    <UserLayout path="/inventories/:queryId/:query/list" exact {...props} component={InventoryBasicListPage} />
    {/*//------End: July 18, 2020 Update-----//*/}
    <UserLayout path="/inventories/:id/action/view" exact {...props} component={InventoryViewPage} />
    <UserLayout path="/inventories/:id/action/edit" exact {...props} component={InventoryEditPage} />

    <UserLayout path="/orders/:type/:queryId/:query" exact {...props} component={OrderListPage} />
    <UserLayout path="/orders/:type/:queryId/:query/basic" exact {...props} component={OrderListBasicPage} />
    <UserLayout path="/orders/:type/:id/action/view" exact {...props} component={OrderViewPage} />
    <UserLayout path="/orders/:type/:id/action/edit" exact {...props} component={OrderEditPage} />

    <UserLayout path="/orders/:orderId/shipment/:shipmentId/view" exact {...props} component={OrderShipmentViewPage} />

    <UserLayout path="/company" exact {...props} component={CompanySettingViewPage} />
    <UserLayout path="/company/edit" exact {...props} component={CompanySettingEditPage} />
    <UserLayout path="/profiles" exact {...props} component={MyProfilePage} />
    <UserLayout path="/users/:query" exact {...props} component={UserListPage} />
    <UserLayout path="/users/:id/action/view" exact {...props} component={UserViewPage} />
    <UserLayout path="/users/:id/action/edit" exact {...props} component={UserEditPage} />
    <UserLayout path="/clients/:query" exact {...props} component={ClientListPage} />
    <UserLayout path="/clients/:id/action/view" exact {...props} component={ClientViewPage} />
    <UserLayout path="/clients/:id/action/edit" exact {...props} component={ClientEditPage} />
    <UserLayout path="/configurations/:resource" exact {...props} component={ConfigurationViewPage} />

    <UserLayout path="/billing/items/:query" exact {...props} component={BillingItemPage} />
    <UserLayout path="/billing/activities/:query" exact {...props} component={BillingActivityListPage} />
    <UserLayout path="/billing/activities/:id/action/view" exact {...props} component={BillingActivityViewPage} />
    <UserLayout path="/billing/activities/:id/action/edit" exact {...props} component={BillingActivityEditPage} />
    <UserLayout path="/billing/entries/:query" exact {...props} component={BillingEntryListPage} />
    <UserLayout path="/billing/entries/action/:query" exact {...props} component={BillingEntryNewPage} />
    <UserLayout path="/billing/reports" exact {...props} component={ClientBillingReport} />

    <UserLayout path="/integrations/:query" exact {...props} component={ApiListPage} />
    <UserLayout path="/integrations/:id/action/view" exact {...props} component={ApiViewPage} />
    <UserLayout path="/integrations/:id/action/edit" exact {...props} component={ApiEditPage} />

    <UserLayout path="/locations/:query" exact {...props} component={LocationListPage} />
    <UserLayout path="/licenses/:query" exact {...props} component={LicenseListPage} />

    <UserLayout path="/serials/:query" exact {...props} component={SerialListPage} />
    <UserLayout path="/serials/:id/action/view" exact {...props} component={SerialViewPage} />
    <UserLayout path="/serials/:id/action/edit" exact {...props} component={SerialEditPage} />
    <UserLayout path="/f/:type/:queryId/:query" exact {...props} component={ShipmentListPage} />
    <UserLayout path="/f/:type/:id" exact {...props} component={ShipmentViewPage} />
    <UserLayout path="/f/:type/:id/action/pack" exact {...props} component={ShipmentPackPage} />
    <UserLayout path="/f/outbound/fba/:id/fulfill" exact {...props} component={FBAFulfillmentPage} />

    <UserLayout path="/reports" exact {...props} component={OperationReportPage} />
</div>

App.propTypes = {
    authenticated: PropTypes.bool.isRequired
};

export default App;
