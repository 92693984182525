// import _ from 'lodash';
import {api} from '../api';
import {
    SET_BILLING_ITEM_PAGINATED_LIST,
    SET_BILLING_ITEM_MODEL,
    BILLING_ITEM_MODEL_UPDATED_BY_FORM,
    NEW_BILLING_ITEM_CREATED_ON_SERVER,
    EXISTING_BILLING_ITEM_UPDATED_ON_SERVER,
    SET_BILLING_ACTIVITY_PAGINATED_LIST,
    SET_BILLING_ACTIVITY_MODEL,
    BILLING_ACTIVITY_MODEL_UPDATED_BY_FORM,
    NEW_ITEM_ADDED_TO_ACTIVITY,
    EXISTING_ITEM_UPDATED_FROM_ACTIVITY,
    SET_BILLING_ENTRY_PAGINATED_LIST,
    MODIFY_BILLING_ENTRY,
    DELETE_BILLING_ENTRY
    // SET_BILLING_ENTRY_PAGE_PREDIFIEND_QUERIES,
    // CREATE_BILLING_ENTRY_PAGE_PREDIFINED_QUERIES
} from './types';

export const fetchItemPaginatedList = (path) => (dispatch) => {
    return api.billing.items.paginated(path).then(res => {
        dispatch({
            type: SET_BILLING_ITEM_PAGINATED_LIST,
            payload: res.data.data
        });
        return res;
    }).catch(err => {throw err});
}

export const fetchActivityPaginatedList = (path) => (dispatch) => {
    return api.billing.activities.paginated(path).then(res => {
        dispatch({
            type: SET_BILLING_ACTIVITY_PAGINATED_LIST,
            payload: res.data.data
        });
        return res;
    }).catch(err => {throw err});
}

export const setItemModel = (model) => {
    if (model) {
        return {
            type: SET_BILLING_ITEM_MODEL,
            payload: model
        }
    } else {
        return {
            type: SET_BILLING_ITEM_MODEL,
            payload: {
                id: null,
                name: "",
                description: "",
                uom: "",
                is_active: true,
                tax_code: "",
                internal: false,
                accounting_class: "",
                report_breakdown: false
            }
        }
    }
}

export const updateItemModelByForm = (field, value) => {
    return {
        type: BILLING_ITEM_MODEL_UPDATED_BY_FORM,
        payload: {field, value}
    }
}

export const updateItemOnServer = (model, index) => (dispatch) => {
    if (model.id) {
        return api.billing.items.update(model.id, model).then(res => {
            dispatch({
                type: EXISTING_BILLING_ITEM_UPDATED_ON_SERVER,
                payload: {
                    index: index,
                    data: res.data.data
                }
            })
        });
    } else {
        return api.billing.items.create(model).then(res => {
            dispatch({
                type: NEW_BILLING_ITEM_CREATED_ON_SERVER,
                payload: res.data.data
            })
        });
    }
}

export const fetchActivityById = (id) => (dispatch) => {
    return api.billing.activities.read(id).then(res => {
        dispatch({
            type: SET_BILLING_ACTIVITY_MODEL,
            payload: res.data.data
        })
        return res.data.data;
    });
}

export const updateActivityModelInStore = (field, value) => {
    return {
        type: BILLING_ACTIVITY_MODEL_UPDATED_BY_FORM,
        payload: {field, value}
    }
}

export const updateActivityModelOnServer = (model) => (dispatch) => {
    if (model.id) {
        return api.billing.activities.update(model.id, model).then(res => {
            return res;
        })
    } else {
        return api.billing.activities.create(model).then(res => {
            return res;
        })
    }
}

export const createActivityNewModelInStore = () => {
    return {
        type: SET_BILLING_ACTIVITY_MODEL,
        payload: {
            id: null,
            items: [],
            client_id: "0",
            name: "",
            charge: "Manual",
            is_active: true
        }
    }
}

export const newItemAddedToActivity = (item) => {
    return {
        type: NEW_ITEM_ADDED_TO_ACTIVITY,
        payload: item
    }
}

export const existingItemModifiedInActivity = (index, pivot) => {
    return {
        type: EXISTING_ITEM_UPDATED_FROM_ACTIVITY,
        payload: {index,pivot}
    }
}

export const fetchEntryPaginatedList = (path) => (dispatch) => {
    return api.billing.entries.paginated(path).then(res => {
        dispatch({
            type: SET_BILLING_ENTRY_PAGINATED_LIST,
            payload: res.data.data
        });
        return res;
    }).catch(err => {throw err});
}

export const billingEntryModified = (model, index, isDelete) => {
    return isDelete ? {
        type: DELETE_BILLING_ENTRY,
        payload: index
    } : {
        type: MODIFY_BILLING_ENTRY,
        payload: {
            index, model
        }
    }
}

// export const saveBillingEntryQuery = () => (dispatch) => {
//     return api.preferences.getPaginationPreference('BillingEntry').then(res => {
//         const data = res.data.data;
//         const payload = [];
//         payload.unshift({
//             key: 'default',
//             value: 'default',
//             text: 'Default All Entry List'
//         });
//         _.each(data, q => {
//             payload.push({
//                 key: q.id,
//                 value: _.toString(q.id),
//                 text: q.name,
//                 query: q.value
//             });
//         });
//         dispatch({
//             type: SET_BILLING_ENTRY_PAGE_PREDIFIEND_QUERIES,
//             payload: payload
//         })
//     });
// }

// export const saveInventoryQuery = (name, query) => (dispatch) => {
//     return api.preferences.createPaginationPreference({
//         name: name,
//         param: query,
//         page: 'BillingEntry'
//     }).then(res => {
//         dispatch({
//             type: CREATE_BILLING_ENTRY_PAGE_PREDIFINED_QUERIES,
//             payload: res.data.data
//         });
//         return res;
//     }).catch(err => {
//         throw err;
//     })
// }
