import React from 'react';
import PropTypes from 'prop-types';
import {Form , Header, Button} from 'semantic-ui-react';

const RoleModalForm = ({t,model,onCheckboxClicked, onModelFieldChange, onSubmitBtnClicked, loading}) => (
    <Form>
        <Button floated='right' size='mini' 
            onClick={() => onSubmitBtnClicked()}
            disabled={loading}
            loading={loading}
            color='teal'>
            {t('Save')}
        </Button>
        <Form.Group>
            <Form.Input
                label={t("Name")}
                name="name"
                required={true}
                value={model.name} 
                size='mini'
                onChange={(e) => { onModelFieldChange(e.target.name, e.target.value) }}
            />
        </Form.Group>
        <Form.Group>
            <Form.Checkbox
                label={t('Status')}
                name="is_active"
                onChange={(e,data) => { onModelFieldChange('is_active', data.checked) }}
                checked={model.is_active ? true : false}
            />
        </Form.Group>
        <Header as='h5'>{t('Setting')}</Header>
        <Form.Group widths='equal'>
            <Form.Checkbox
                label={t('update_company_info')}
                name="update_company_info"
                onChange={(e,data) => { onCheckboxClicked('update_company_info', data.checked) }}
                checked={model.access.update_company_info}
            />
            <Form.Checkbox
                label={t('adjust_inventory')}
                name="adjust_inventory"
                onChange={(e,data) => { onCheckboxClicked('adjust_inventory', data.checked) }}
                checked={model.access.adjust_inventory}
            />
            <Form.Checkbox
                label={t('trace_history')}
                name="trace_history"
                onChange={(e,data) => { onCheckboxClicked('trace_history', data.checked) }}
                checked={model.access.trace_history}
            />
        </Form.Group>
        <Form.Group widths='equal'>
            <Form.Checkbox
                label={t('create_general')}
                name="create_general"
                onChange={(e,data) => { onCheckboxClicked('create_general', data.checked) }}
                checked={model.access.create_general}
            />
            <Form.Checkbox
                label={t('update_general')}
                name="update_general"
                onChange={(e,data) => { onCheckboxClicked('update_general', data.checked) }}
                checked={model.access.update_general}
            />
            <Form.Checkbox
                label={t('delete_general')}
                name="delete_general"
                onChange={(e,data) => { onCheckboxClicked('delete_general', data.checked) }}
                checked={model.access.delete_general}
            />
        </Form.Group>
        <Form.Group widths='equal'>
            <Form.Checkbox
                label={t('create_courier')}
                name="create_courier"
                onChange={(e,data) => { onCheckboxClicked('create_courier', data.checked) }}
                checked={model.access.create_courier}
            />
            <Form.Checkbox
                label={t('update_courier')}
                name="update_courier"
                onChange={(e,data) => { onCheckboxClicked('update_courier', data.checked) }}
                checked={model.access.update_courier}
            />
        </Form.Group>
        <Header as='h5'>{t('Client')}</Header>
        <Form.Group widths='equal'>
            <Form.Checkbox
                label={t('view_client_list')}
                name="view_client_list"
                onChange={(e,data) => { onCheckboxClicked('view_client_list', data.checked) }}
                checked={model.access.view_client_list}
            />
            <Form.Checkbox
                label={t('view_client_model')}
                name="view_client_model"
                onChange={(e,data) => { onCheckboxClicked('view_client_model', data.checked) }}
                checked={model.access.view_client_model}
            />
            <Form.Checkbox
                label={t('create_client_model')}
                name="create_client_model"
                onChange={(e,data) => { onCheckboxClicked('create_client_model', data.checked) }}
                checked={model.access.create_client_model}
            />
        </Form.Group>
        <Form.Group widths='equal'>
            <Form.Checkbox
                label={t('update_client_model')}
                name="update_client_model"
                onChange={(e,data) => { onCheckboxClicked('update_client_model', data.checked) }}
                checked={model.access.update_client_model}
            />
        </Form.Group>
        <Header as='h5'>{t('User')} & {t('Role')}</Header>
        <Form.Group widths='equal'>
            <Form.Checkbox
                label={t('view_user_list')}
                name="view_user_list"
                onChange={(e,data) => { onCheckboxClicked('view_user_list', data.checked) }}
                checked={model.access.view_user_list}
            />
            <Form.Checkbox
                label={t('view_user_model')}
                name="view_user_model"
                onChange={(e,data) => { onCheckboxClicked('view_user_model', data.checked) }}
                checked={model.access.view_user_model}
            />
            <Form.Checkbox
                label={t('create_user_model')}
                name="create_user_model"
                onChange={(e,data) => { onCheckboxClicked('create_user_model', data.checked) }}
                checked={model.access.create_user_model}
            />
        </Form.Group>
        <Form.Group widths='equal'>
            <Form.Checkbox
                label={t('update_user_model')}
                name="update_user_model"
                onChange={(e,data) => { onCheckboxClicked('update_user_model', data.checked) }}
                checked={model.access.update_user_model}
            />
        </Form.Group>
        <Form.Group widths='equal'>
            <Form.Checkbox
                label={t('view_role_list')}
                name="view_role_list"
                onChange={(e,data) => { onCheckboxClicked('view_role_list', data.checked) }}
                checked={model.access.view_role_list}
            />
            <Form.Checkbox
                label={t('create_role_model')}
                name="create_role_model"
                onChange={(e,data) => { onCheckboxClicked('create_role_model', data.checked) }}
                checked={model.access.create_role_model}
            />
            <Form.Checkbox
                label={t('update_role_model')}
                name="update_role_model"
                onChange={(e,data) => { onCheckboxClicked('update_role_model', data.checked) }}
                checked={model.access.update_role_model}
            />
        </Form.Group>
        <Header as='h5'>{t('Integration')}</Header>
        <Form.Group widths='equal'>
            <Form.Checkbox
                label={t('view_api_list')}
                name="view_api_list"
                onChange={(e,data) => { onCheckboxClicked('view_api_list', data.checked) }}
                checked={model.access.view_api_list}
            />
            <Form.Checkbox
                label={t('view_api_model')}
                name="view_api_model"
                onChange={(e,data) => { onCheckboxClicked('view_api_model', data.checked) }}
                checked={model.access.view_api_model}
            />
            <Form.Checkbox
                label={t('create_api_model')}
                name="create_api_model"
                onChange={(e,data) => { onCheckboxClicked('create_api_model', data.checked) }}
                checked={model.access.create_api_model}
            />
        </Form.Group>
        <Form.Group widths='equal'>
            <Form.Checkbox
                label={t('update_api_model')}
                name="update_api_model"
                onChange={(e,data) => { onCheckboxClicked('update_api_model', data.checked) }}
                checked={model.access.update_api_model}
            />
            <Form.Checkbox
                label={t('delete_api_model')}
                name="delete_api_model"
                onChange={(e,data) => { onCheckboxClicked('delete_api_model', data.checked) }}
                checked={model.access.delete_api_model}
            />
        </Form.Group>
        <Header as='h5'>{t('Location')}</Header>
        <Form.Group widths='equal'>
            <Form.Checkbox
                label={t('view_location_list')}
                name="view_location_list"
                onChange={(e,data) => { onCheckboxClicked('view_location_list', data.checked) }}
                checked={model.access.view_location_list}
            />
            <Form.Checkbox
                label={t('create_location_model')}
                name="create_location_model"
                onChange={(e,data) => { onCheckboxClicked('create_location_model', data.checked) }}
                checked={model.access.create_location_model}
            />
            <Form.Checkbox
                label={t('update_location_model')}
                name="update_location_model"
                onChange={(e,data) => { onCheckboxClicked('update_location_model', data.checked) }}
                checked={model.access.update_location_model}
            />
        </Form.Group>
        <Form.Group widths='equal'>
            <Form.Checkbox
                label={t('view_license_list')}
                name="view_license_list"
                onChange={(e,data) => { onCheckboxClicked('view_license_list', data.checked) }}
                checked={model.access.view_license_list}
            />
        </Form.Group>
        <Header as='h5'>{t('License Plate')}</Header>
        <Form.Group widths='equal'>
            <Form.Checkbox
                label={t('create_license_model')}
                name="create_license_model"
                onChange={(e,data) => { onCheckboxClicked('create_license_model', data.checked) }}
                checked={model.access.create_license_model}
            />
            <Form.Checkbox
                label={t('update_license_model')}
                name="update_license_model"
                onChange={(e,data) => { onCheckboxClicked('update_license_model', data.checked) }}
                checked={model.access.update_license_model}
            />
            <Form.Checkbox
                label={t('breakdown_license_model')}
                name="breakdown_license_model"
                onChange={(e,data) => { onCheckboxClicked('breakdown_license_model', data.checked) }}
                checked={model.access.breakdown_license_model}
            />
        </Form.Group>
        <Form.Group widths='equal'>
            <Form.Checkbox
                label={t('delete_license_model')}
                name="delete_license_model"
                onChange={(e,data) => { onCheckboxClicked('delete_license_model', data.checked) }}
                checked={model.access.delete_license_model}
            />
            <Form.Checkbox
                label={t('put_license_to_location')}
                name="put_license_to_location"
                onChange={(e,data) => { onCheckboxClicked('put_license_to_location', data.checked) }}
                checked={model.access.put_license_to_location}
            />
            <Form.Checkbox
                label={t('move_between_license')}
                name="move_between_license"
                onChange={(e,data) => { onCheckboxClicked('move_between_license', data.checked) }}
                checked={model.access.move_between_license}
            />
        </Form.Group>
        <Header as='h5'>{t('Serial Number')}</Header>
        <Form.Group widths='equal'>
            <Form.Checkbox
                label={t('view_serial_list')}
                name="view_serial_list"
                onChange={(e,data) => { onCheckboxClicked('view_serial_list', data.checked) }}
                checked={model.access.view_serial_list}
            />
            <Form.Checkbox
                label={t('create_serial_model')}
                name="create_serial_model"
                onChange={(e,data) => { onCheckboxClicked('create_serial_model', data.checked) }}
                checked={model.access.create_serial_model}
            />
            <Form.Checkbox
                label={t('update_serial_model')}
                name="update_serial_model"
                onChange={(e,data) => { onCheckboxClicked('update_serial_model', data.checked) }}
                checked={model.access.update_serial_model}
            />
        </Form.Group>
        <Form.Group widths='equal'>
            <Form.Checkbox
                label={t('view_serial_model')}
                name="view_serial_model"
                onChange={(e,data) => { onCheckboxClicked('view_serial_model', data.checked) }}
                checked={model.access.view_serial_model}
            />
            <Form.Checkbox
                label={t('delete_serial_model')}
                name="delete_serial_model"
                onChange={(e,data) => { onCheckboxClicked('delete_serial_model', data.checked) }}
                checked={model.access.delete_serial_model}
            />
        </Form.Group>
        <Header as='h5'>{t('Shipping')} & {t('Receiving')}</Header>
        <Form.Group widths='equal'>
            <Form.Checkbox
                label={t('view_shipments_list')}
                name="view_shipments_list"
                onChange={(e,data) => { onCheckboxClicked('view_shipments_list', data.checked) }}
                checked={model.access.view_shipments_list}
            />
            <Form.Checkbox
                label={t('view_shipment_model')}
                name="view_shipment_model"
                onChange={(e,data) => { onCheckboxClicked('view_shipment_model', data.checked) }}
                checked={model.access.view_shipment_model}
            />
            <Form.Checkbox
                label={t('update_shipment_tracking')}
                name="update_shipment_tracking"
                onChange={(e,data) => { onCheckboxClicked('update_shipment_tracking', data.checked) }}
                checked={model.access.update_shipment_tracking}
            />
        </Form.Group>
        <Form.Group widths='equal'>
            <Form.Checkbox
                label={t('void_shipment')}
                name="void_shipment"
                onChange={(e,data) => { onCheckboxClicked('void_shipment', data.checked) }}
                checked={model.access.void_shipment}
            />
            <Form.Checkbox
                label={t('fulfill_shipment')}
                name="fulfill_shipment"
                onChange={(e,data) => { onCheckboxClicked('fulfill_shipment', data.checked) }}
                checked={model.access.fulfill_shipment}
            />
            <Form.Checkbox
                label={t('delete_fulfillment_line')}
                name="delete_fulfillment_line"
                onChange={(e,data) => { onCheckboxClicked('delete_fulfillment_line', data.checked) }}
                checked={model.access.delete_fulfillment_line}
            />
        </Form.Group>
        <Form.Group widths='equal'>
            <Form.Checkbox
                label={t('update_shipment_line')}
                name="update_shipment_line"
                onChange={(e,data) => { onCheckboxClicked('update_shipment_line', data.checked) }}
                checked={model.access.update_shipment_line}
            />
            <Form.Checkbox
                label={t('role_pack')}
                name="pack"
                onChange={(e,data) => { onCheckboxClicked('pack', data.checked) }}
                checked={model.access.pack}
            />
        </Form.Group>
        <Header as='h5'>{t('Activity')}</Header>
        <Form.Group widths='equal'>
            <Form.Checkbox
                label={t('view_activity_list')}
                name="view_activity_list"
                onChange={(e,data) => { onCheckboxClicked('view_activity_list', data.checked) }}
                checked={model.access.view_activity_list}
            />
            <Form.Checkbox
                label={t('view_activity_model')}
                name="view_activity_model"
                onChange={(e,data) => { onCheckboxClicked('view_activity_model', data.checked) }}
                checked={model.access.view_activity_model}
            />
            <Form.Checkbox
                label={t('create_activity_model')}
                name="create_activity_model"
                onChange={(e,data) => { onCheckboxClicked('create_activity_model', data.checked) }}
                checked={model.access.create_activity_model}
            />
        </Form.Group>
        <Form.Group widths='equal'>
            <Form.Checkbox
                label={t('update_activity_model')}
                name="update_activity_model"
                onChange={(e,data) => { onCheckboxClicked('update_activity_model', data.checked) }}
                checked={model.access.update_activity_model}
            />
            <Form.Checkbox
                label={t('update_billing_items')}
                name="update_billing_items"
                onChange={(e,data) => { onCheckboxClicked('update_billing_items', data.checked) }}
                checked={model.access.update_billing_items}
            />
        </Form.Group>
        <Header as='h5'>{t('Item')}</Header>
        <Form.Group widths='equal'>
            <Form.Checkbox
                label={t('view_billing_item_list')}
                name="view_billing_item_list"
                onChange={(e,data) => { onCheckboxClicked('view_billing_item_list', data.checked) }}
                checked={model.access.view_billing_item_list}
            />
            <Form.Checkbox
                label={t('create_billing_item_model')}
                name="create_billing_item_model"
                onChange={(e,data) => { onCheckboxClicked('create_billing_item_model', data.checked) }}
                checked={model.access.create_billing_item_model}
            />
        </Form.Group>
        <Form.Group widths='equal'>
            <Form.Checkbox
                label={t('update_billing_item_model')}
                name="update_billing_item_model"
                onChange={(e,data) => { onCheckboxClicked('update_billing_item_model', data.checked) }}
                checked={model.access.update_billing_item_model}
            />
        </Form.Group>
        <Header as='h5'>{t('Entry')}</Header>
        <Form.Group widths='equal'>
            <Form.Checkbox
                label={t('run_billing_report')}
                name="run_billing_report"
                onChange={(e,data) => { onCheckboxClicked('run_billing_report', data.checked) }}
                checked={model.access.run_billing_report}
            />
            <Form.Checkbox
                label={t('view_entry_list')}
                name="view_entry_list"
                onChange={(e,data) => { onCheckboxClicked('view_entry_list', data.checked) }}
                checked={model.access.view_entry_list}
            />
            <Form.Checkbox
                label={t('create_entry_model')}
                name="create_entry_model"
                onChange={(e,data) => { onCheckboxClicked('create_entry_model', data.checked) }}
                checked={model.access.create_entry_model}
            />
        </Form.Group>
        <Form.Group widths='equal'>
            <Form.Checkbox
                label={t('update_entry_model')}
                name="update_entry_model"
                onChange={(e,data) => { onCheckboxClicked('update_entry_model', data.checked) }}
                checked={model.access.update_entry_model}
            />
        </Form.Group>
    </Form>
)

RoleModalForm.propTypes = {
    model: PropTypes.shape({
        access: PropTypes.object.isRequired
    }).isRequired,
    onCheckboxClicked: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    onModelFieldChange: PropTypes.func.isRequired,
    onSubmitBtnClicked: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired
};


export default RoleModalForm