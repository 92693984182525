import React from 'react';
import {Header} from 'semantic-ui-react';
import _ from 'lodash';
import PropTypes from 'prop-types';

const fulfilledQty = (fulfillments) => {
    let qty = 0;
    _.each(fulfillments, f => {
        qty = qty + Math.abs(f.change)
    });
    return qty;
}

const FulfillmentFormHeader = ({line, byPassLicesenControlledLotClicked, action, useLinceseLot}) => (
    <Header as='h3' dividing>
        <span className="lara-success-text">{fulfilledQty(line.fulfillments)}</span> / {line.quantity} - {line.inventory.sku}
        {line.inventory.config.lot ? <Header.Subheader>
            * Lot Required&nbsp;&nbsp;
            {action === 'outbound' ? <span className="lara-editable lara-clickable" onClick={() => {byPassLicesenControlledLotClicked(!useLinceseLot)}}>
                {useLinceseLot ? "Remove" : "Add"} LP Control</span> : null}
        </Header.Subheader> : null}
        {line.inventory.config.serial ? <Header.Subheader>* Serial Required</Header.Subheader> : null}
    </Header>
)

FulfillmentFormHeader.propTypes = {
    line: PropTypes.shape({
        inventory: PropTypes.object.isRequired
    }),
    byPassLicesenControlledLotClicked: PropTypes.func.isRequired,
    action: PropTypes.string.isRequired,
    useLinceseLot: PropTypes.bool.isRequired
};


export default FulfillmentFormHeader