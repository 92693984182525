import React, { Component } from 'react';
import {withI18n} from 'react-i18next';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {
    Dropdown,
    Modal,
    Header
} from 'semantic-ui-react';
import PDFDisplayCanvas from '../../includes/partials/PDFDisplayCanvas';
import {api} from '../../../api';
import Lara from '../../../lara';

class PrintMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            pdfModal: false,
            encodedData: ""
        }
    }

    onGetExcelPackingSlip = () => {
        if (_.isArray(this.props.modelLines)) {
            Lara.excel.customerizedExcel(this.props.modelLines);
        }
    }

    onPrintClick = (docUrl) => {
        const ids = this.props.getListItemSelected();
        if (_.isEmpty(ids)) {
            return null;
        }

        this.setState({
            loading: true,
            pdfModal: false,
            encodedData: ""
        }, () => {
            if (docUrl === '/docs/pdf/s/report') {
                api.docs.shipmentReportPDF(this.props.modelId, docUrl).then(res => {
                    this.setState({
                        loading: false,
                        pdfModal: true,
                        encodedData: res.data.data
                    })
                }).catch(err => {
                    this.setState({
                        loading: false
                    })
                })
            } else {
                api.docs.pdf(ids, docUrl).then(res => {
                    this.setState({
                        loading: false,
                        pdfModal: true,
                        encodedData: res.data.data
                    })
                }).catch(err => {
                    this.setState({
                        loading: false
                    })
                })
            }
        });
    }

    render() {
        const {t, adminList, isLp, shipmentPacked} = this.props;
        return (
            <div>
                <Dropdown text={this.state.loading ? "" : t('Print')}
                    icon='print'
                    loading={this.state.loading}
                    disabled={this.state.loading}
                    floating
                    labeled
                    direction='left'
                    className='icon' floated='right'>
                    <Dropdown.Menu>
                        {this.props.shipmentReport && !isLp ? <Dropdown.Item
                            onClick={() => {this.onPrintClick('/docs/pdf/s/report')}}
                        >{t('SR')}</Dropdown.Item> : null}
                        {adminList && !isLp? <Dropdown.Item
                            onClick={() => {this.onPrintClick('/docs/pdf/s/regular')}}
                        >{t('PT')}</Dropdown.Item> : null}
                        {adminList && !isLp ? <Dropdown.Item
                            onClick={() => {this.onPrintClick('/docs/pdf/s/controlled')}}
                        >{t('PTC')}</Dropdown.Item> : null}
                        {this.props.fulfillmentType === 'outbound' && adminList && !isLp ? <Dropdown.Item
                            onClick={() => {this.onPrintClick('/docs/pdf/s/labels/mobile')}}
                        >{t('MB')}</Dropdown.Item> : null}
                        {this.props.fulfillmentType === 'outbound' && adminList && !isLp ? <Dropdown.Item
                            onClick={() => {this.onPrintClick('/docs/pdf/s/labels/ctn')}}
                        >{t('SCL')}</Dropdown.Item> : null}
                        {this.props.fulfillmentType === 'outbound' && adminList && !isLp ? <Dropdown.Divider /> : null}
                        {this.props.fulfillmentType === 'outbound' && !isLp? <Dropdown.Item
                            onClick={() => {this.onPrintClick('/docs/pdf/s/packing')}}
                        >{t('PS')}</Dropdown.Item> : null}
                        {this.props.fulfillmentType === 'outbound' && !isLp ? <Dropdown.Item
                            onClick={() => {this.onPrintClick('/docs/pdf/s/packing/carton')}}
                        >{t('CPS')}</Dropdown.Item> : null}
                        {this.props.fulfillmentType === 'outbound' && !isLp? <Dropdown.Item
                            onClick={() => {this.onPrintClick('/docs/pdf/s/packing/kit-only')}}
                        >Custom Packing Slip 1</Dropdown.Item> : null}
                        {this.props.fulfillmentType === 'outbound' && !isLp ? <Dropdown.Item
                            onClick={() => {this.onPrintClick('/docs/pdf/s/invoice')}}
                        >{t('INV')}</Dropdown.Item> : null}
                        {this.props.fulfillmentType === 'outbound' && !isLp ? <Dropdown.Item
                            onClick={() => {this.onPrintClick('/docs/pdf/s/custom_ci')}}
                        >{t('CI')}</Dropdown.Item> : null}
                        {isLp ? <Dropdown.Item
                            onClick={() => {this.onPrintClick('/docs/pdf/lp/labels')}}
                        >{t('LPL')}</Dropdown.Item> : null}
                        {this.props.fulfillmentType === 'fba' ? <Dropdown.Item
                            onClick={() => {this.onPrintClick('/docs/fba/pdf/shipment')}}
                        >Product Item Labels (2.25 X 1.25 By Units)</Dropdown.Item> : null}
                        {this.props.fulfillmentType === 'fba' ? <Dropdown.Item
                            onClick={() => {this.onPrintClick('/docs/fba/pdf/shipment-sku')}}
                        >Product Item Labels (2.25 X 1.25 By SKU)</Dropdown.Item> : null}
                        {this.props.fulfillmentType === 'fba' ? <Dropdown.Item
                            onClick={() => {this.onPrintClick('/docs/fba/pdf/prep')}}
                        >Prep Instruction Sheet</Dropdown.Item> : null}
                        {this.props.fulfillmentType === 'fba' && shipmentPacked ? <Dropdown.Item
                            onClick={() => {this.onPrintClick('/docs/fba/pdf/two-d-feed')}}
                        >2-D Barcode Carton Label</Dropdown.Item> : null}
                        {this.props.fulfillmentType === 'outbound' && this.props.modelId ? <Dropdown.Item
                            onClick={() => {this.onGetExcelPackingSlip()}}
                        >Customized Excel 1</Dropdown.Item> : null}
                        {this.props.fulfillmentType === 'fba' ? <Dropdown.Item
                            onClick={() => {this.onPrintClick('/docs/fba/pdf/shipment/sm')}}
                        >Product Item Labels (2 X 1 By Units)</Dropdown.Item> : null}
                        {this.props.fulfillmentType === 'fba' ? <Dropdown.Item
                            onClick={() => {this.onPrintClick('/docs/fba/pdf/shipment-sku/sm')}}
                        >Product Item Labels (2 X 1 By SKU)</Dropdown.Item> : null}
                    </Dropdown.Menu>
                </Dropdown>
                <Modal
                    closeOnDimmerClick={false}
                    closeIcon
                    onClose={() => {
                        this.setState({pdfModal: false}, () => {
                            this.props.reFethcRcords()
                        })
                    }}
                    size='large'
                    closeOnEscape={true} open={this.state.pdfModal}>
                    <Header icon='file pdf outline' content={null} />
                    <Modal.Content>
                        <PDFDisplayCanvas
                            pdf={this.state.encodedData}
                        />
                    </Modal.Content>
                </Modal>
            </div>
        )
    }
}

PrintMenu.propTypes = {
    t: PropTypes.func.isRequired,
    fulfillmentType: PropTypes.string.isRequired,
    getListItemSelected: PropTypes.func.isRequired,
    shipmentReport: PropTypes.bool.isRequired,
    reFethcRcords: PropTypes.func.isRequired,
    adminList: PropTypes.bool.isRequired,
    modelLines: PropTypes.array,
    modelId: PropTypes.any,
    shipmentPacked: PropTypes.any,
    isLp: PropTypes.bool.isRequired
};

export default withI18n()(PrintMenu)
