import React, { Component } from 'react';
import update from 'immutability-helper';
import moment from 'moment';
import _ from 'lodash';
import { bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import {withI18n} from 'react-i18next';
import qs from 'querystring';
import PropTypes from 'prop-types';
import SegmentRibbon from '../../includes/partials/SegmentRibbon';
import PageBreadCrub from '../../includes/partials/PageBreadCrub';
import SingleClientSelector from '../../includes/selectors/SingleClientSelector';
import AlertComponent from '../../includes/messages/alert';
import FormError from '../../includes/messages/422';
import Lara from '../../../lara';
import LaraPagination from '../../../laraPagination';
import {
    Segment, Form, Table, Input, Icon
} from 'semantic-ui-react';
import {api} from '../../../api';
import PrimaryButton from '../../includes/buttons/PrimaryButton';
import SecondaryButton from '../../includes/buttons/SecondaryButton';

//Lara Button Updated
class BillingEntryNewPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            breadcrub: [
                {isLink:false, to:null, tag: 'Billing Entry', active: true, key: 1}
            ],
            errors: [],
            model: {
                client_id: "",
                billing_activity_id: "",
                note: "",
                bill_date: "",
                reference: "",
                billable_id: "",
                billable_type: "",
                entries: [
    
                ]
            },
            activities: [],
            backTo: ""
        }
    }
    componentDidMount() {
        const {query} = this.props.match.params;
        const request = qs.parse(query);
        this.setState({
            model: update(this.state.model, {
                client_id: {$set: request.clientId},
                billing_activity_id: {$set: request.activityId},
                bill_date: {$set: moment().format("YYYY-MM-DD").toString()},
                reference: {$set: request.reference},
                billable_id: {$set: request.billableId},
                billable_type: {$set: request.billableType},
            })
        });
        this.setState({
            backTo: request.backTo
        });
        this.loadActivities(request.clientId);
    }

    loadActivities = (clientId) => {
        if (clientId) {
            api.billing.activities.loadByClientId(clientId).then(res => {
                //console.log(res.data.data);
                this.setState({
                    activities: res.data.data
                })
            })
        }
    }

    onGoBackClick = () => {
        if (this.state.backTo) {
            this.props.history.push(this.state.backTo);
        } else {
            this.props.history.push(`/billing/entries/${LaraPagination.billing.entries(false)}`)   
        }   
    }

    onClientSelected = (clientId) => {
        this.onModelValueChange('client_id', clientId);
        this.loadActivities(clientId);
    }

    onActivitySelected = (activityId) => {
        const actIndex = _.findIndex(this.state.activities, a => {
            return parseInt(a.id,10) === parseInt(activityId,10);
        });
        if (actIndex > -1) {
            const {charges} = this.state.activities[actIndex];
            this.setState({
                model: update(this.state.model, {
                    billing_activity_id: {$set: activityId}
                })
            }, () => {
                const items = [];
                _.each(charges, c => {
                    items.push({
                        price: c.pivot.rate,
                        quantity: c.pivot.quantity,
                        billing_item_id: c.id,
                        meta_name: c.name,
                        meta_uom: c.uom
                    })
                });
                this.onModelValueChange('entries', items);
            })
        }
    }

    onModelValueChange = (field, value) => {
        this.setState({
            model: update(this.state.model, {
                [field]: {$set: value}
            })
        });
    }

    onEntryLineChange = (index, field, value) => {
        this.setState({
            model: update(this.state.model, {
                entries: {[index]: {
                    [field]: {$set: value}
                }}
            })
        })
    }

    onDeleteEntryLine = (index) => {
        this.setState({
            model: update(this.state.model, {
                entries: {$splice: [[index, 1]]}
            })
        })
    }

    onFormSubmit = () => {
        const errors = this.validateInput();
        if (_.isEmpty(errors)) {
            this.setState({
                loading: true,
                errors: []
            });
            api.billing.entries.create(this.state.model).then(res => {
                this.setState({
                    loading: false
                });
                this.onGoBackClick();
            }).catch(err => {
                const errors = Lara.axiosError(err, this.props.t);
                if (errors.http_code === 422) {
                    this.setState({
                        errors: errors.message,
                        loading: false
                    });
                } else {
                    this.setState({
                        loading: false
                    });
                    Lara.axiosAlert(<AlertComponent 
                        support={errors.support}
                        message={errors.message}
                        reference={errors.reference}
                        t={this.props.t}
                    />);
                }
            });
        } else {
            this.setState({
                errors: errors
            });
        }
    };

    validateInput = () => {
        const {client_id, billing_activity_id, bill_date, reference, entries} = this.state.model;
        const errors = [];
        if (!client_id) {
            errors.push(this.props.t('ClientIsRequired'));
        }
        if (!billing_activity_id) {
            errors.push(this.props.t("ActivityIsReuqired"));
        }
        if (!bill_date) {
            errors.push(this.props.t("DateIsReuqired"));
        }
        if (!reference) {
            errors.push(this.props.t("ReferenceIsRequired"));
        }
        if (!_.isArray(entries)) {
            errors.push(this.props.t("AtLeastOneItemIsRequired"));
        }
        if (entries.length <= 0) {
            errors.push(this.props.t("AtLeastOneItemIsRequired"));
        }
        return errors;
    };

    render() {
        const {t} = this.props;
        const activityOptions = [];
        _.each(this.state.activities, act => {
            activityOptions.push({
                value: act.id,
                text: act.name
            })
        });
        const {model} = this.state;
        return (
            <div>
                {/* Breadcrub Row */}
                <div className="row">
                    <div className="col-md-12 lara-breadcrub">
                        <PageBreadCrub lists={this.state.breadcrub} t={t}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <Segment>
                            <SegmentRibbon label={`${t('Create')}`} Buttons={
                                () => {return (<React.Fragment>
                                    <PrimaryButton
                                        size='mini'
                                        float='right'
                                        label={t('Save')}
                                        onClick={() => {
                                            this.onFormSubmit();
                                        }}
                                        loading={this.state.loading}
                                    />
                                    <SecondaryButton
                                        size='mini'
                                        float='right'
                                        label={t('GoBack')}
                                        onClick={() => {
                                            this.onGoBackClick()
                                        }}
                                        loading={this.state.loading}
                                    />
                                </React.Fragment>)}
                            }/>
                            <Form>
                                {!_.isEmpty(this.state.errors) ? (
                                    <FormError errors={this.state.errors} />
                                ) : null}
                                <div style={{marginBottom: 8}}>
                                    <span className="lara-ui-label">{t("Client")}</span>
                                    <SingleClientSelector
                                        clients={this.props.clients}
                                        selected={model.client_id ? model.client_id : "0"}
                                        clientSelected={(data) => {
                                            this.onClientSelected(data.value);
                                        }}
                                        disabled={false}
                                        loading={false}
                                        ignoreError={true}
                                    />
                                </div>

                                <Form.Group widths='equal'>
                                    <Form.Select
                                        options={activityOptions}
                                        label={t('Activity')}
                                        value={model.billing_activity_id}
                                        onChange={(e, {value}) => {
                                            this.onActivitySelected(value);
                                        }}
                                    />
                                </Form.Group>

                                {(parseInt(model.client_id, 10) > 0 && parseInt(model.billing_activity_id, 10) > 0) ? <div>
                                    <Form.Group widths='equal'>
                                        <Form.Input
                                            type='date'
                                            value={model.bill_date}
                                            fluid={true}
                                            onChange={(e, {value}) => {
                                                this.onModelValueChange('bill_date', value)
                                            }}
                                            label={t("BillDate")}
                                        />
                                        <Form.Input
                                            value={model.reference}
                                            fluid={true}
                                            onChange={(e, {value}) => {
                                                this.onModelValueChange('reference', value)
                                            }}
                                            label={t("Reference")}
                                        />
                                    </Form.Group>
                                    <Table
                                        compact
                                        basic
                                        padded={false}
                                    >
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell>
                                                    {t('Item')}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    {t('UOM')}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    {t('Quantity')}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    {t('Price')}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    {t('Delete')}
                                                </Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            {model.entries.map((item, index) => {
                                                return (<Table.Row key={index}>
                                                    <Table.Cell>
                                                        {item.meta_name}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {item.meta_uom}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        <Input size='mini' value={item.quantity} 
                                                            type="number"
                                                            onChange={(e,{value}) => {
                                                                this.onEntryLineChange(index, 'quantity', value)
                                                            }}
                                                        />
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        <Input size='mini' value={item.price} 
                                                            type="number"
                                                            onChange={(e,{value}) => {
                                                                this.onEntryLineChange(index, 'price', value)
                                                            }}
                                                        />
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        <span className="lara-editable lara-clickable">
                                                            <Icon name='times' onClick={() => {
                                                                this.onDeleteEntryLine(index);
                                                            }}/>
                                                        </span>
                                                    </Table.Cell>
                                                </Table.Row>)
                                            })}
                                        </Table.Body>
                                    </Table>
                                    <Form.TextArea
                                        value={model.note}
                                        onChange={(e, {value}) => {
                                            this.onModelValueChange('note', value)
                                        }}
                                        label={t("Note")}
                                    />
                                </div> : null}
                            </Form>
                        </Segment>
                    </div>
                </div>
            </div>
        )
    }
}

BillingEntryNewPage.propTypes = {
    clients: PropTypes.array.isRequired,
    history: PropTypes.shape({
       push: PropTypes.func.isRequired
    }).isRequired,
    t: PropTypes.func.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            query: PropTypes.string.isRequired
        })
    })
};

function mapStateToProps(state) {
    return {
        clients: state.system.clients
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch)
}

export default withI18n()(connect(mapStateToProps, mapDispatchToProps)(BillingEntryNewPage));