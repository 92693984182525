import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {Form} from 'semantic-ui-react';

class LotSelector extends React.PureComponent {
    onSelected = (value) => {
        if (this.props.action === 'inbound') {
            const {lots, onSelectChange} = this.props;
            const index = _.findIndex(lots, s => {
                return _.toString(s.id) === value
            });
            return onSelectChange(index > -1 ? lots[index] : null);
        } else {
            if (!this.props.useLicenseLot) {
                const {lots, onSelectChange} = this.props;
                const index = _.findIndex(lots, s => {
                    return _.toString(s.id) === value
                });
                return onSelectChange(index > -1 ? lots[index] : null);
            } else {
                const {lots, onSelectChange} = this.props;
                const index = _.findIndex(lots, s => {
                    return _.toString(s.lot_id) === value
                });
                return onSelectChange(index > -1 ? {
                    id: lots[index].lot_id,
                    lot: lots[index].lot_number,
                    expire: lots[index].expire_date
                } : null);
            }
        }
    }

    render() {
        const {lots, label, selected, action, useLicenseLot} = this.props;
        const options = [];
        if (action === 'inbound') {
            _.each(lots, c => {
                options.push({
                    value: _.toString(c.id),
                    text: `${c.lot} - ${c.expire}`,
                    key: c.id
                });
            });
        } else {
            if (useLicenseLot) {
                _.each(lots, (c, index) => {
                    options.push({
                        value: _.toString(c.lot_id),
                        text: `${c.lot_number} - ${c.expire_date}`,
                        key: index
                    });
                });
            } else {
                _.each(lots, c => {
                    options.push({
                        value: _.toString(c.id),
                        text: `${c.lot} - ${c.expire}`,
                        key: c.id
                    });
                });
            }
        }
        
        options.unshift({
            value: 0,
            text: "Use New Lot",
            key: -1
        });
        return (
            <Form.Select fluid search selection 
                label={label}
                size='mini'
                options={options}
                name="lot_id" value={selected ? _.toString(selected) : 0} 
                onChange={(e, {value}) => {
                    this.onSelected(value)
                }}
            />
        )
    }
}

LotSelector.propTypes = {
    onSelectChange: PropTypes.func.isRequired,
    label: PropTypes.string,
    lots: PropTypes.array.isRequired,
    action: PropTypes.string.isRequired,
    useLicenseLot: PropTypes.bool.isRequired
};

export default LotSelector