import React from 'react';
import {Header, Icon} from 'semantic-ui-react';
import PropTypes from 'prop-types';

const ShopifyHeader = ({model}) => (
    <div>
        <Header as='h2'>
            <Icon name='settings' />
            <Header.Content>
                Zalora
                <Header.Subheader>Api Key: {model.key_1}</Header.Subheader>
                <Header.Subheader>Api Secret: {model.key_2}</Header.Subheader>
                <Header.Subheader>Webhook: [Your System URL]/api/webhooks/zalora/order/{model.o_auth_token}</Header.Subheader>
            </Header.Content>
        </Header>
    </div>
)

ShopifyHeader.propTypes = {
    model: PropTypes.object.isRequired
};


export default ShopifyHeader