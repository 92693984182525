import React from 'react';
import { bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import {Table, Modal, Header} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {
    initiateFulfillmentModel,
    onFulfillmentModalClose
} from '../../actions/shipment';
import FulfillmentTableLine from './FulfillmentTableLine';
import LineFulfillmentForm from './LineFulfillmentForm';

class FulfillmentTable extends React.PureComponent {

    onFulfillClicked = (fulfillmentLine, fulfillmentLineIndex) => {
        this.props.initiateFulfillmentModel(fulfillmentLine, fulfillmentLineIndex);
    }

    getTotalQtyInShipment = () => {
        let totalQtyInShipment = 0;
        this.props.model.shipment_lines.map(l => {
            totalQtyInShipment = parseInt(l.quantity,10) + totalQtyInShipment;
        });
        return totalQtyInShipment;
    }

    getTotalFulfilled = () => {
        let totalFulfilled = 0;
        this.props.model.shipment_lines.map(l => {
            l.fulfillments.map (f => {
                totalFulfilled = totalFulfilled + Math.abs(f.change);
            })
        });
        return totalFulfilled;
    }
    
    render() {
        const {t, model, modalOpen, onFulfillmentModalClose} = this.props;
        return (
            <div>
                <Table compact>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>
                                {t('SKU')}
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                {t('Name')}
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                {t('UPC')}
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                {t('UOM')}
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                {t('Qty')}
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                {t('Fulfilled')}
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Lot / SN
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {model.shipment_lines.map((l, index) => {
                            return <FulfillmentTableLine
                                key={l.id}
                                line={l}
                                onFulfillClicked={() => {
                                    this.onFulfillClicked(l,index)
                                }}
                            />
                        })}
                    </Table.Body>
                    <Table.Footer>
                        <Table.Row>
                            <Table.Cell />
                            <Table.Cell />
                            <Table.Cell />
                            <Table.Cell />
                            <Table.Cell>
                                <strong>{this.getTotalQtyInShipment()}</strong>
                            </Table.Cell>
                            <Table.Cell>
                                <strong>{this.getTotalFulfilled()}</strong>
                            </Table.Cell>
                            <Table.Cell />
                        </Table.Row>
                    </Table.Footer>
                </Table>
                <Modal
                closeOnDimmerClick={false}
                closeIcon
                onClose={() => {
                    onFulfillmentModalClose()
                }}
                size='small'
                closeOnEscape={true} open={modalOpen}>
                <Header icon='boxes' content={t('Fulfill')} />
                <Modal.Content>
                    <LineFulfillmentForm 
                        canDelete={model.status === 'Finished' ? false : true}
                        t={t} client_id={model.order.client_id} action={this.props.action} />
                </Modal.Content>
            </Modal>
            </div>
        )
    }
}

FulfillmentTable.propTypes = {
    model: PropTypes.shape({
        order: PropTypes.object.isRequired,
        shipment_lines: PropTypes.array.isRequired
    }),
    action: PropTypes.string.isRequired,
    initiateFulfillmentModel: PropTypes.func.isRequired,
    onFulfillmentModalClose: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    modalOpen: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
    return {
        model: state.shipmentModel,
        modalOpen: state.fulfillment.modalOpen
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        initiateFulfillmentModel,
        onFulfillmentModalClose
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(FulfillmentTable)