import React from 'react';
import {Form} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {api} from '../../../api';
import PrimaryButton from '../../includes/buttons/PrimaryButton';

class ShipmentCommentForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            comment: "",
            print: false,
            internal: false
        }
    }
    
    onSaveNote = () => {
        const data = {...this.state};
        data.commentable_id = this.props.commentable_id;
        data.commentable_type = this.props.commentable_type;
        api.createComment(data).then(res => {
            this.setState({
                comment: "",
                print: false,
                internal: false
            });
            this.props.commentCreated(res.data);
        })
    }

    render() {
        return (
            <Form size='mini'>
                <Form.TextArea
                    value={this.state.comment}
                    onChange={(e, {value}) => {
                        this.setState({comment: value})
                    }}
                    rows={5}
                    label={this.props.t('Comment')}
                />
                {this.props.user.is_main_user ? <Form.Checkbox
                    label={this.props.t('InternalNote')}
                    name="internal"
                    onChange={(e,data) => {
                        this.setState({internal: data.checked})
                    }}
                    checked={this.state.internal}
                /> : null}
                {/* <Form.Checkbox
                    label={this.props.t('PrintOnDoc')}
                    name="print"
                    onChange={(e,data) => {
                        this.setState({print: data.checked})
                    }}
                    checked={this.state.print}
                /> */}
                <PrimaryButton
                    size='mini'
                    float='left'
                    label={this.props.t('Save')}
                    onClick={(() => {
                        this.onSaveNote()
                    })}
                    loading={false}
                />
                <div className="row">
                <div className="lara-row-margin"></div>
                </div>
            </Form>
        )
    }
}

ShipmentCommentForm.propTypes = {
    commentable_id: PropTypes.number.isRequired,
    commentable_type: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    commentCreated: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired
};

export default ShipmentCommentForm
