import React, { Component } from 'react';
import { bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types'
import {withI18n} from 'react-i18next';
import {Segment, Table, Dimmer, Loader, Icon} from 'semantic-ui-react';
import update from 'immutability-helper';
import SegmentRibbon from '../includes/partials/SegmentRibbon';
import PageBreadCrub from '../includes/partials/PageBreadCrub';
import qs from 'qs';
import {
    fetchPaginatedList
} from '../../actions/serial';
import OrderBy from '../includes/tables/OrderBy';
import SearchBar from '../includes/tables/SearchBar';
import Pagination from '../includes/tables/Pagination';
import AlertComponent from '../includes/messages/alert';
import Lara from '../../lara';
import LaraPagination from '../../laraPagination';
import PrimaryNaviButton from '../includes/buttons/PrimaryNaviButton';

class SerialListPage extends Component {
    constructor() {
        super();
        this.state = {
            breadcrub: [
                {isLink:false, to:null, tag: 'Serial Number', active: true, key: 1}
            ],
            loading: false,
            routingQuery: {

            },
            routingPath: "",
            search: "",
            model: {

            }
        }
    }
    
    componentDidUpdate(prevProps, prevState) {
        const {params, url} = this.props.match;
        if (url !== this.state.routingPath) {
            this.setRoutingInState(params, url);
        }
    }

    componentDidMount() {
        const {params, url} = this.props.match;
        this.setRoutingInState(params,url);
    }

    setRoutingInState = ({query}, url) => {
        const q = qs.parse(query);
        //order_by
        //filter_by
        //query
        if (!_.isObject(q.filter_by)) {
            q.filter_by = {};
        }
        if (!_.isObject(q.order_by)) {
            q.order_by = {};
        }
        if (!_.isArray(q.query)) {
            q.query = [];
        }
        this.setState({
            loading: true,
            routingQuery: q,
            routingPath: url,
            search: q.search
        }, () => {
            this.fetchRecords(query)
        });
    }

    fetchRecords = () => {
        this.props.fetchPaginatedList(qs.stringify(this.state.routingQuery)).then(res => {
            this.setState({loading: false});
        }).catch(err => {
            const errors = Lara.axiosError(err, this.props.t);
            if (errors.http_code === 422) {
                this.setState({
                    errors: errors.message,
                    loading: false
                });
            } else {
                this.setState({
                    loading: false
                });
                Lara.axiosAlert(<AlertComponent 
                    support={errors.support}
                    message={errors.message}
                    reference={errors.reference}
                    t={this.props.t}
                />);
            }
            this.setState({loading: false});
        });
    }

    routeToNewQuery = (newRoutingQuery) => {
        this.props.history.push(`/serials/${qs.stringify(newRoutingQuery)}`);
    }

    /*
     * For table header filters
    */
    onOrderByHeaderClicked = (routingOrderName, value) => {
        const newRoutingQuery = update(this.state.routingQuery, {
            order_by: {
                [routingOrderName]: {$set: value}
            }
        });
        this.routeToNewQuery(newRoutingQuery);
    }

    /*
     * For pagination
    */
    onPaginationClicked = (e, data) => {
        const {activePage} = data;
        const newRoutingQuery = update(this.state.routingQuery, {
            on_page: {$set: activePage}
        });
        this.routeToNewQuery(newRoutingQuery);
    }

    /*
     * For Search bar: onSearchEnterKeyPressed onSearchChange onPredefinedRouteSelected (optional)
     */
    onSearchEnterKeyPressed = (e) => {
        if (e.key === 'Enter') {
            const defaultQuery = LaraPagination.serials(true);
            defaultQuery.search = this.state.search;
            this.setState({
                queryId: 'default'
            }, () => {
                this.routeToNewQuery(defaultQuery);
            });
        }
    }
    onPredefinedRouteSelected = (value) => {
        return null;
    }

    onEditClick = (loc, index) => {

    }

        /*
     *
     */
    onFilterSelectChange = (field, value) => {
        this.setState({
            routingQuery: update(this.state.routingQuery, {
                filter_by: {
                    [field]: {$set: value.join()}
                }
            })
        }, () => {
            this.routeToNewQuery(this.state.routingQuery);
        });
    }

    render() {
        const {routingQuery, search} = this.state;
        const {t, list} = this.props;
        const {on_page, per_page, total} = list;
        const orderBy = _.isObject(routingQuery.order_by) ? routingQuery.order_by : {};
        return (
            <div>
                {/* Breadcrub Row */}
                <div className="row">
                    <div className="col-md-12 lara-breadcrub">
                        <PageBreadCrub lists={this.state.breadcrub} t={t}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <Segment>
                            <SegmentRibbon label={`${t('Serial')} ${t('Search')}`} Buttons={
                                () => {return (<React.Fragment>
                                    <PrimaryNaviButton
                                        link='/serials/new/action/edit'
                                        size='mini'
                                        float='right'
                                        loading={false}
                                        label={t('Create')}
                                    />
                                </React.Fragment>)}
                            }/>
                            <SearchBar
                                placeholder='Search by serial # or SKU...'
                                onSearchEnterKeyPressed={(e) => {this.onSearchEnterKeyPressed(e)}}
                                search={search}
                                onSearchChange={(search) => {this.setState({search})}}
                                onPredefinedRouteSelected={() => null}
                                options={[]}
                                havePreferenceSelect={false}
                            />
                        </Segment>

                        <Segment>
                            <Dimmer inverted active={this.state.loading}>
                                <Loader />
                            </Dimmer>
                            <SegmentRibbon label={`${t('SerialNumber')} ${t('List')}`}/>
                            <div className="row lara-row-margin">
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <Table
                                        compact
                                        basic
                                        padded={false}
                                    >
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell>
                                                    <OrderBy
                                                        t={t}
                                                        name='SerialNumber'
                                                        routingOrderName='lead'
                                                        currentOrder={orderBy.lead}
                                                        onOrderClick={(routingOrderName, value) => {this.onOrderByHeaderClicked(routingOrderName, value)}}
                                                    />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    {t('SKU')}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    {t('Name')}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    {t('UnitCount')}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    {t('AvailableToUse')}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    <OrderBy
                                                        t={t}
                                                        name='CreatedAt'
                                                        routingOrderName='created_at'
                                                        currentOrder={orderBy.created_at}
                                                        onOrderClick={(routingOrderName, value) => {this.onOrderByHeaderClicked(routingOrderName, value)}}
                                                    />
                                                </Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>

                                        <Table.Body>
                                            {list.records.map((i, index) => {
                                                return (<Table.Row key={i.id}>
                                                    <Table.Cell>
                                                        <Link to={{
                                                            pathname: `/serials/${i.id}/action/view`,
                                                            backTo: this.state.routingPath
                                                        }}>
                                                            {i.lead}
                                                        </Link>
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {i.inventory.sku}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {i.inventory.name}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {i.units}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {!i.used ? <Icon name="check" color='green' /> : <Icon name="times" />}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {moment(i.created_at).format('MMM DD, Y').toString()}
                                                    </Table.Cell>
                                                </Table.Row>)
                                            })}
                                        </Table.Body>
                                    </Table>
                                </div>
                            </div>
                        
                            <div className="row lara-row-margin">
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <Pagination 
                                        activePage={on_page}
                                        totalRecords={total}
                                        perPage={per_page}
                                        onPageChange={(e, data) => {
                                            this.onPaginationClicked(e,data);
                                        }}
                                    />
                                </div>
                            </div>
                        </Segment>
                    </div>
                </div>
            </div>
        )
    }
}

SerialListPage.propTypes = {
    t: PropTypes.func.isRequired,
    history: PropTypes.shape({
       push: PropTypes.func.isRequired
    }).isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.shape({
        params: PropTypes.object.isRequired,
        path: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired
    }),
    fetchPaginatedList: PropTypes.func.isRequired,
    list: PropTypes.object.isRequired
};

function mapStateToProps(state) {
    return {
        list: state.serialList.list
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchPaginatedList
    }, dispatch)
}

export default withI18n()(connect(mapStateToProps, mapDispatchToProps)(SerialListPage));