import React from 'react'
import PropTypes from 'prop-types'
import {Icon} from 'semantic-ui-react';

const getNextOrder = (currentOrder) => {
    if (currentOrder === 'asc') {
        return 'desc';
    } 
    if (currentOrder === 'desc') {
        return null;
    }
    if (!currentOrder) {
        return 'asc';
    }
}

const OrderBy = ({t, name, currentOrder, onOrderClick, routingOrderName}) => (
    <span className='lara-table-header-icon' 
        onClick={() => {
            onOrderClick(routingOrderName, getNextOrder(currentOrder));
        }}
    >
        {currentOrder === 'asc' ? 
            <Icon name='sort alphabet down' 
                color='teal'/> : null}
        {currentOrder === 'desc' ? 
            <Icon name='sort alphabet up' 
                color='teal'/> : null}
        {!currentOrder ? 
            <Icon name='sort'/> : null}
        {t(name)}
    </span>
)

OrderBy.propTypes = {
    t: PropTypes.func.isRequired,
    routingOrderName: PropTypes.string.isRequired,
    name: PropTypes.string,
    currentOrder: PropTypes.string,
    onOrderClick: PropTypes.func.isRequired
};


export default OrderBy;