import React from 'react';
import PropTypes from 'prop-types';
import {Form} from 'semantic-ui-react';

const TimeZoneSelector = ({zones, value, onSelected, label}) => (
    <Form.Select
        options={zones}
        value={value}
        onChange={(e, {value}) => {
            onSelected(value)
        }}
        fluid
        label={label}
        required={true}
        search
    />
)

TimeZoneSelector.propTypes = {
    zones: PropTypes.array.isRequired,
    value: PropTypes.string.isRequired,
    onSelected: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired
};


export default TimeZoneSelector