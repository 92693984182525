import React from 'react';
import accounting from 'accounting';
import _ from 'lodash';
import {
    Table, Label
} from 'semantic-ui-react';
import PropTypes from 'prop-types';

const OrderDisplayTableLine = ({line}) => (
    <Table.Row>
        <Table.Cell verticalAlign='top' textAlign='left'>
            {line.sku} <br />
            {!line.kit_id ? <Label size='mini' color='violet'>Item</Label> : 
                _.isEmpty(line.kit_property) ? <Label size='mini' color='purple'>Kit</Label> :
                <Label size='mini' color='purple'>{line.kit_property.sku} @ {line.kit_property.quantity}</Label>
            }
        </Table.Cell>
        <Table.Cell verticalAlign='top' textAlign='left'>
            {line.name} 
            {line.comment ? <span className="lara-order-line-comment"><br />{line.comment}</span> : null}
        </Table.Cell>
        <Table.Cell verticalAlign='top' textAlign='left'>
            {line.upc}
        </Table.Cell>
        <Table.Cell verticalAlign='top' textAlign='center'>
            {line.line_status.available_inventory}
        </Table.Cell>
        <Table.Cell verticalAlign='top' textAlign='center'>
            {line.line_status.leftover > line.line_status.available_inventory ?
                <span className="lara-quarantined">{parseInt(line.quantity, 10)}</span>
                : <span>{parseInt(line.quantity, 10)}</span>}
        </Table.Cell>
        <Table.Cell verticalAlign='top' textAlign='center'>
            {_.isObject(line.line_status) ? line.line_status.processed_qty : 0}
        </Table.Cell>
        <Table.Cell verticalAlign='top' textAlign='center'>
            {parseFloat(line.price)}
        </Table.Cell>
        <Table.Cell verticalAlign='top' textAlign='right'>
            {accounting.formatMoney(parseFloat(line.price) * parseInt(line.quantity, 10))}
        </Table.Cell>
    </Table.Row>
)

OrderDisplayTableLine.propTypes = {
    line: PropTypes.shape({
        line_status: PropTypes.object.isRequired
    })
};


export default OrderDisplayTableLine