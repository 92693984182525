import React from 'react';
import _ from 'lodash';
import {Header, Icon} from 'semantic-ui-react';
import PropTypes from 'prop-types';

const ShopifyHeader = ({model}) => (
    <div>
        <Header as='h2'>
            <Icon name='settings' />
            <Header.Content>
                {model.o_auth_token}
                {model.api_version === 'Default' && <Header.Subheader>Api Key: {model.key_1}</Header.Subheader>}
                {model.api_version === 'Default' && <Header.Subheader>Api Secret: {model.key_2}</Header.Subheader>}
                {model.api_version === 'Default' && <Header.Subheader>Last Synchronize: {_.isObject(model.synchronize) ? model.synchronize.updated_at : null}</Header.Subheader>}
            </Header.Content>
        </Header>
    </div>
)

ShopifyHeader.propTypes = {
    model: PropTypes.object.isRequired
};


export default ShopifyHeader
