import React, { Component } from 'react';
import { bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import {
    Segment, Dimmer, Loader, Modal, Form, Header, Table, Message
} from 'semantic-ui-react';
import update from "immutability-helper";
import validator from "validator";
import SegmentRibbon from "../../includes/partials/SegmentRibbon";
import PrimaryButton from "../../includes/buttons/PrimaryButton";
import DeleteButton from "../../includes/buttons/DeleteButton";
import FormError from "../../includes/messages/422";
import AlertComponent from "../../includes/messages/alert";
import PropTypes from 'prop-types';
import {
    saveWebhook,
    deleteWebhook
} from "../../../actions/client"
import Lara from "../../../lara";
import {api} from '../../../api';

class ClientWebhook extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: [],
            loading: false,
            showModal: false,
            hookIndex: -1,
            hook: {

            },
            testTraceModal: false,
            webhookRetryLoading: false,
            modalType: null,
            traces: []
        }
    }

    setHookModel = (model, index) => {
        this.setState({
            loading: false,
            showModal: true,
            hookIndex: index,
            hook: model
        })
    }

    onHookModelChange = (field, value, isCofing = null) => {
        if (isCofing) {
            this.setState({
                hook: update(this.state.hook, {
                    ftp_config: {
                        [field]: {$set: value}
                    }
                })
            })
        } else {
            this.setState({
                hook: update(this.state.hook, {
                    [field]: {$set: value}
                })
            })
        }
    }

    onDeleteHookClick = () => {
        this.setState({
            errors: [],
            loading: true
        });
        this.props.deleteWebhook(this.props.model.id, this.state.hook.id, this.state.hookIndex).then(res => {
            this.setState({
                loading: false,
                showModal: false,
                hookIndex: -1,
                hook: {

                }
            })
        }).catch(err => {
            this.handleHttpError(err);
        })
    }

    onSubmitClick = () => {
        const errors = this.validateInput();
        if (_.isEmpty(errors)) {
            this.setState({
                errors: [],
                loading: true
            });
            this.props.saveWebhook(this.props.model.id, this.state.hook, this.state.hookIndex).then(res => {
                this.setState({
                    loading: false,
                    showModal: false,
                    hookIndex: -1,
                    hook: {

                    }
                })
            }).catch(err => {
                this.handleHttpError(err);
            })
        } else {
            this.setState({
                errors: errors
            });
        }
    }

    handleHttpError = (err) => {
        const errors = Lara.axiosError(err, this.props.t);
        if (errors.http_code === 422) {
            this.setState({
                errors: errors.message,
                loading: false
            });
        } else {
            this.setState({
                loading: false
            });
            Lara.axiosAlert(<AlertComponent
                support={errors.support}
                message={errors.message}
                reference={errors.reference}
                t={this.props.t}
            />);
        }
    }

    validateInput = () => {
        const {
            type,endpoint,hash_key,ftp_config
        } = this.state.hook;

        const errors = [];
        if (!validator.isURL(endpoint)) {
            errors.push("Endpoint must be a valid URL");
        }
        if (type === 'HTTP') {
            if (!hash_key) {
                errors.push("Hash Key is required");
            }
        } else {
            if (!ftp_config.username) {
                errors.push("FTP username is reuqired");
            }
            if (!ftp_config.password) {
                errors.push("FTP password is reuqired");
            }
        }
        return errors;
    }

    getHookTrace = (hook) => {
        this.setState({
            loading: true,
            traces: []
        })
        api.clients.getWebhookLogs(hook.id).then(res => {
            //console.log(res.data.data);
            this.setState({
                testTraceModal: true,
                modalType: 'Hook-Trace',
                traces: res.data.data,
                loading: false
            })
        }).catch(err => {
            this.handleHttpError(err);
        })
    }

    sendTestHook = (hook) => {
        this.setState({
            loading: true
        })
        api.clients.testWebhook(hook.id).then(res => {
            this.setState({
                loading: false,
                testTraceModal: true,
                modalType: 'Test-Success',
                traces: []
            })
        }).catch(err => {
            this.setState({
                loading: false,
                testTraceModal: true,
                modalType: 'Test-Fail',
                traces: []
            })
        })
    }

    onRetryClick = (traceId) => {
        const traceIndex = _.findIndex(this.state.traces, t => {
            return t.id === traceId
        })
        this.setState({
            webhookRetryLoading: true
        }, () => {
            if (traceIndex > -1) {
                api.clients.retryWebhook(traceId).then(res => {
                    this.setState({
                        traces: update(this.state.traces, {
                            $splice: [[traceIndex, 1]]
                        }),
                        webhookRetryLoading: false
                    });
                }).catch(err => {
                    this.setState({
                        webhookRetryLoading: false
                    })
                })
            } else {
                this.setState({
                    webhookRetryLoading: false
                })
            }
        })

    }

    // downloadPayload = (trace) => {
    //     let data = "text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(trace.array_log));
    // }

    render() {
        const {webhooks} = this.props.model;
        return (
            <div className="col-md-12 col-sm-12 col-xs-12">
                <Segment>
                    <Dimmer inverted active={this.state.loading}>
                        <Loader />
                    </Dimmer>
                    <SegmentRibbon label="Webhook" Buttons={
                        () => {return (<React.Fragment>
                            <PrimaryButton
                                onClick={() => {
                                    this.setHookModel({
                                        id: null,
                                        client_id: null,
                                        trigger: 1,
                                        type: 'HTTP',
                                        method: 'POST',
                                        endpoint: '',
                                        hash_key: '',
                                        ftp_config: {
                                            username: '',
                                            password: '',
                                            path: ''
                                        }
                                    }, -1);
                                }}
                                size='mini'
                                float='right'
                                loading={this.state.loading}
                                label={this.props.t('Create')}
                            />
                        </React.Fragment>)}
                    }/>
                    <Table
                        compact
                        basic
                        padded={false}
                    >
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>
                                    {this.props.t("HookTrigger")}
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    {this.props.t("HookType")}
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    {this.props.t("HookMethod")}
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    {this.props.t("HookEndpoint")}
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    {this.props.t("Edit")}
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    {this.props.t("Trace")}
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    {this.props.t("Test")}
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {_.isArray(webhooks) ? webhooks.map((hook, index) => {
                                return (
                                    <Table.Row key={hook.id}>
                                        <Table.Cell>
                                            {hook.trigger === 1 ? "Order Created" : null}
                                            {hook.trigger === 2 ? "Shipment Created" : null}
                                            {hook.trigger === 3 ? "Shipment Finished" : null}
                                            {hook.trigger === 101 ? "External Resource" : null}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {hook.type}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {hook.method}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {hook.endpoint}
                                        </Table.Cell>
                                        <Table.Cell>
                                            <span
                                                onClick={() => {
                                                    this.setHookModel(hook, index);
                                                }}
                                                className="lara-editable lara-clickable">
                                                {this.props.t("Edit")}
                                            </span>
                                        </Table.Cell>
                                        <Table.Cell>
                                            {this.state.loading ? <Loader /> : hook.trigger <= 99 ? <span
                                                onClick={() => {
                                                    this.getHookTrace(hook);
                                                }}
                                                className="lara-editable lara-clickable">
                                                {this.props.t("Trace")}
                                            </span> : null}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {this.state.loading ? <Loader /> : hook.trigger <= 99 ? <span
                                                onClick={() => {
                                                    this.sendTestHook(hook);
                                                }}
                                                className="lara-editable lara-clickable">
                                                {this.props.t("Test")}
                                            </span> : null}
                                        </Table.Cell>
                                    </Table.Row>
                                )
                            }) : null}
                        </Table.Body>
                    </Table>
                </Segment>
                <Modal size="mini"
                    closeOnDimmerClick={false}
                    closeIcon
                    onClose={() => {this.setState({showModal: false})}}
                    closeOnEscape={true} open={this.state.showModal}>
                    <Header icon='linkify' />
                    <Modal.Content>
                        <Form size="mini">
                            {!_.isEmpty(this.state.errors) ? (
                                <FormError errors={this.state.errors} />
                            ) : null}
                            <Form.Select
                                size="mini"
                                options={[{value: 1, text: "Order Created"},
                                    {value: 2, text: "Shipment Created"},
                                    {value: 3, text: "Shipment Finished"},
                                    {value: 101, text: "External Resource"}]}
                                onChange={(e, {value}) => {
                                    this.onHookModelChange('trigger', value)
                                }}
                                value={this.state.hook.trigger}
                                label={this.props.t('HookTrigger')}
                            />
                            <Form.Select
                                size="mini"
                                options={[
                                    {value: "HTTP", text: "HTTP"},
                                    //{value: "FTP", text: "FTP"}
                                ]}
                                onChange={(e, {value}) => {
                                    this.onHookModelChange('type', value)
                                }}
                                value={this.state.hook.type}
                                label={this.props.t('HookType')}
                            />
                            {this.state.hook.type === 'HTTP' ? <Form.Select
                                size="mini"
                                options={[{value: "POST", text: "POST"},{value: "PUT", text: "PUT"}]}
                                onChange={(e, {value}) => {
                                    this.onHookModelChange('method', value)
                                }}
                                value={this.state.hook.method}
                                label={this.props.t('HookMethod')}
                            /> : null}
                            <Form.Input
                                required={true}
                                label={this.props.t('HookEndpoint')}
                                value={this.state.hook.endpoint}
                                size="mini"
                                onChange={(e, {value}) => {
                                    this.onHookModelChange('endpoint', value)
                                }}
                            />
                            {this.state.hook.type === 'HTTP' ? <Form.Input
                                label={this.props.t('HookHashKey')}
                                required={true}
                                value={this.state.hook.hash_key}
                                size="mini"
                                onChange={(e, {value}) => {
                                    this.onHookModelChange('hash_key', value)
                                }}
                            /> : null }
                            {this.state.hook.type === 'FTP' ? <Form.Input
                                required={true}
                                label={this.props.t('HookConfigUserName')}
                                value={this.state.hook.ftp_config.username}
                                size="mini"
                                onChange={(e, {value}) => {
                                    this.onHookModelChange('username', value, true)
                                }}
                            /> : null}
                            {this.state.hook.type === 'FTP' ? <Form.Input
                                required={true}
                                label={this.props.t('HookConfigPassword')}
                                value={this.state.hook.ftp_config.password}
                                size="mini"
                                onChange={(e, {value}) => {
                                    this.onHookModelChange('password', value, true)
                                }}
                            /> : null}
                            {this.state.hook.type === 'FTP' ? <Form.Input
                                label={this.props.t('HookConfigPath')}
                                value={this.state.hook.ftp_config.path}
                                size="mini"
                                onChange={(e, {value}) => {
                                    this.onHookModelChange('path', value, true)
                                }}
                            /> : null}
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        {this.state.hook.id ? <DeleteButton
                            size="mini"
                            float="left"
                            label={this.props.t('Delete')}
                            onClick={() => {
                                this.onDeleteHookClick()
                            }}
                            loading={this.state.loading}
                        /> : null}
                        <PrimaryButton
                            size='mini'
                            float='right'
                            label={this.props.t('Save')}
                            onClick={() => {
                                this.onSubmitClick()
                            }}
                            loading={this.state.loading}
                        />
                        <div className="row">
                        <div className="lara-row-margin"></div>
                        </div>
                        <div className="row">
                        <div className="lara-row-margin"></div>
                        </div>
                    </Modal.Actions>
                </Modal>
                <Modal
                    size="large"
                    closeOnDimmerClick={false}
                    closeIcon
                    onClose={() => {this.setState({testTraceModal: false})}}
                    closeOnEscape={true} open={this.state.testTraceModal}>
                    <Header icon='linkify' />
                    <Modal.Content>
                        {this.state.modalType === 'Test-Success' ? <Message success>
                            <Message.Header>Success</Message.Header>
                            <p>
                                The system connected to the endpoint successfully.
                            </p>
                        </Message> : null}
                        {this.state.modalType === 'Test-Fail' ? <Message error>
                            <Message.Header>Failed</Message.Header>
                            <p>
                                The system cannot connect to the endpoint.
                            </p>
                        </Message> : null}
                        {this.state.modalType === 'Hook-Trace' ? <div>
                            <Dimmer inverted active={this.state.webhookRetryLoading}>
                                <Loader />
                            </Dimmer>
                            <Table
                                compact
                                basic
                                padded={false}
                            >
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>
                                            ID
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            Date
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            Result
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            Resend
                                        </Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {this.state.traces.map(i => {
                                        return (<Table.Row key={i.id}>
                                            <Table.Cell>
                                                {i.id}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {i.created_at}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {i.subject}
                                            </Table.Cell>
                                            <Table.Cell>
                                                <span className='lara-editable lara-clickable' onClick={() => {
                                                    this.onRetryClick(i.id)
                                                }}>Resend</span>
                                            </Table.Cell>
                                        </Table.Row>)
                                    })}
                                </Table.Body>
                            </Table>
                        </div> : null}
                    </Modal.Content>
                </Modal>
            </div>
        )
    }
}

ClientWebhook.propTypes = {
    t: PropTypes.func.isRequired,
    model: PropTypes.shape({
        id: PropTypes.number.isRequired,
        webhooks: PropTypes.array.isRequired
    }),
    saveWebhook: PropTypes.func.isRequired,
    deleteWebhook: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        model: state.clientModel
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        saveWebhook,
        deleteWebhook
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientWebhook)
