import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {Image, Card} from 'semantic-ui-react';

const DashboardSidePanel = ({user, host, messages}) => (
    <div>
        <Card>
            <div className='lara_card_image'>
                <Image src={`${host}/avatars/${user.avatar_path}`} />
            </div>
            <Card.Content>
            <Card.Header>{user.name}</Card.Header>
            <Card.Meta>
                <span className='date'>Joined since {moment(user.created_at).format('MMM YYYY')}</span>
            </Card.Meta>
            <Card.Description>{user.email}</Card.Description>
            </Card.Content>
        </Card>
        {messages.map(i => {
            return (<Card key={i.id}>
                <Card.Content>
                    <Card.Header>{i.subject}</Card.Header>
                    <Card.Description>
                        <div dangerouslySetInnerHTML={{__html: i.memo_html}}>
                        </div>
                    </Card.Description>
                </Card.Content>
            </Card>
            )
        })}
    </div>
)

DashboardSidePanel.propTypes = {
    user: PropTypes.object.isRequired,
    host: PropTypes.string.isRequired,
    messages: PropTypes.array.isRequired
};


export default DashboardSidePanel