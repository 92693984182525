import React, { Component } from 'react';
import {List, Input, Button} from 'semantic-ui-react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import AlertComponent from '../../includes/messages/alert';
import FormError from '../../includes/messages/422';
import {api} from '../../../api';
import Lara from '../../../lara';

class PutAwayForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loc: "",
            loading: false,
            errors: []
        }
    }

    onMoveClick = () => {
        if (!this.state.loc) {
            return null;
        }
        if (!this.props.license.license) {
            return null;
        }
        const data = {
            location: this.state.loc,
            license: this.props.license.license
        };
        this.setState({
            loading: true
        });
        api.licenses.move(data).then(res => {
            this.props.licenseMoved(res.data.data);
        }).catch(err => {
            this.axiosErorros(err);  
        })
    }

    axiosErorros = (err) => {
        const errors = Lara.axiosError(err, this.props.t);
        if (errors.http_code === 422) {
            this.setState({
                errors: errors.message,
                loading: false
            });
        } else {
            this.setState({
                loading: false
            });
            Lara.axiosAlert(<AlertComponent 
                support={errors.support}
                message={errors.message}
                reference={errors.reference}
                t={this.props.t}
            />);
        }
    }
    
    render() {
        const {locations} = this.props.license;
        return (
            <div className="row">
                <div className="col-md-12">
                    {!_.isEmpty(this.state.errors) ? (
                        <FormError errors={this.state.errors} />
                    ) : null}
                    <Input value={this.state.loc} onChange={(e, {value}) => {
                        this.setState({loc: value});
                    }} fluid action>
                        <input />
                        <Button icon='share alternate' 
                            color="teal"
                            loading={this.state.loading}
                            disabled={this.state.loading}
                            type='button' onClick={() => {
                            this.onMoveClick()
                        }} />
                    </Input>
                </div>
                <div className="col-md-12 lara-row-margin">
                    <List>
                        {locations.map((l,index) => {
                            return <List.Item key={index}>
                                <List.Icon name='arrow alternate circle down outline' size='large' verticalAlign='middle' />
                                <List.Content>
                                    <List.Header>{l.location}</List.Header>
                                    <List.Description>
                                        {l.type === 'P' ? 'Picking' : 'Storage'}
                                    </List.Description>
                                </List.Content>
                        </List.Item>
                        })}
                    </List>
                </div>
                
            </div>
        )
    }
}

PutAwayForm.propTypes = {
    license: PropTypes.shape({
        locations: PropTypes.array.isRequired
    }).isRequired,
    licenseMoved: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

export default PutAwayForm