import React, { Component } from 'react';
import { bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import {withI18n} from 'react-i18next';
import PropTypes from 'prop-types';
import {Segment, Loader, Dimmer, Confirm, Header, List, Modal, Label, Message, Icon} from 'semantic-ui-react';
import {
    fetchById,
    setEmptyShipmentInStore,
    resetModelAfterFillAll,
    updateShipmentProperty
} from '../../actions/shipment';
import PageBreadCrub from '../includes/partials/PageBreadCrub';
import AlertComponent from '../includes/messages/alert';
import SegmentRibbon from '../includes/partials/SegmentRibbon';
import Lara from '../../lara';
import LaraPagination from '../../laraPagination';
import {api} from '../../api';

class ShipmentViewPage extends Component {
    constructor() {
        super();
        this.state = {
            modelId: null,
            breadcrub: [
                {isLink:false, to:null, tag: '', active: true, key: 1}
            ],
            loading: false,
            actions: [],
            submission_results: [],
            submissionModal: false
        }
    }

    componentDidMount() {
        const {location, match} = this.props;
        const {id} = match.params;
        this.setState({
            loading: true
        });
        this.props.fetchById(id).then(res => {
            this.setState({
                modelId: id,
                loading: false,
                fulfillmentType: 'fba',
                breadcrub: this.getBreadCrub(location.backTo, id, 'Outbound')
            })
        }).catch(err => {
            this.handleHttpErrors(err);
            this.setState({loading: false});
        })
        this.loadHistoryAction(id);
    }

    loadHistoryAction = (id) => {
        api.fba.getActionHistory(id).then(res => {
            this.setState({
                actions: res.data.data
            })
        })
    }

    componentWillUnmount() {
        this.props.setEmptyShipmentInStore();
    }

    getBreadCrub = (backTo, name, type) => {
        if (!backTo) {
            backTo = `/f/${type}/default/${LaraPagination.shipments(type, false)}`;
        }
        return [
            {isLink:false, to: backTo, tag: _.replace(type,'-',' '), active: false, key: 1},
            {isLink:false, to: `/f/outbound/${name}`, tag: name, active: false, key: 2},
            {isLink:false, to:null, tag: 'FBA Fulfillment', active: true, key: 3}
        ]
    }

    handleHttpErrors = (err) => {
        const errors = Lara.axiosError(err, this.props.t);
        if (errors.http_code === 422) {
            this.setState({
                errors: errors.message,
                loading: false
            });
        } else {
            this.setState({
                loading: false
            });
            Lara.axiosAlert(<AlertComponent 
                support={errors.support}
                message={errors.message}
                reference={errors.reference}
                t={this.props.t}
            />);
        }
    }

    onChangeFeedTypeClick = (type) => {
        const {model, updateShipmentProperty} = this.props;
        this.setState({
            loading: true
        })
        api.fba.changeFeedType(model.id, type).then(res => {
            updateShipmentProperty(model.id, 'fba_feed_type', type);
            this.setState({loading: false});
        }).catch(err => {
            this.handleHttpErrors(err);
        })
    }

    submitCartonFeed = (id) => {
        this.setState({
            loading: true
        });
        api.fba.submitCartonFeeds(id).then(res => {
            this.setState({
                loading: false
            })
            this.loadHistoryAction(id);
        }).catch(err => {
            this.handleHttpErrors(err);
        })
    }

    checkSubmissionResult = (actionId) => {
        this.setState({
            loading: true
        })
        api.fba.checkSubmissionResult(actionId).then(res => {
            this.setState({
                submission_results: res.data.data,
                submissionModal: true,
                loading: false
            });
        }).catch(err => {
            this.handleHttpErrors(err);
        })
    }

    submitShippingWeightAndDims = (id) => {
        this.setState({loading: true});
        api.fba.submitTransportContent(id).then(res => {
            this.setState({
                loading: false
            })
            this.loadHistoryAction(id);
        }).catch(err => {
            this.handleHttpErrors(err);
        })
    }

    submitShippingEstimateRequest = (id) => {
        this.setState({loading: true});
        api.fba.estimateTransportCost(id).then(res => {
            //console.log(res.data);
            this.setState({
                loading: false
            })
            this.loadHistoryAction(id);
        }).catch(err => {
            this.handleHttpErrors(err);
        })
    }

    getShippingCostResult = (id) => {
        this.setState({loading: true});
        api.fba.requestTransportCost(id).then(res => {
            //console.log(res.data);
            this.setState({
                loading: false
            })
            this.loadHistoryAction(id);
        }).catch(err => {
            this.handleHttpErrors(err);
        })
    }

    confirmFbaCarrierCost = (id) => {
        this.setState({loading: true});
        api.fba.confirmTransportRequest(id).then(res => {
            //console.log(res.data);
            this.setState({
                loading: false
            })
            this.loadHistoryAction(id);
        }).catch(err => {
            this.handleHttpErrors(err);
        })
    }

    markShippedInFba = (id) => {
        this.setState({loading: true});
        api.fba.markShipmentShipped(id).then(res => {
            //console.log(res.data);
            this.setState({
                loading: false
            })
            this.loadHistoryAction(id);
        }).catch(err => {
            this.handleHttpErrors(err);
        })
    }

    voidFbaCarrierLabels = (id) => {
        this.setState({loading: true});
        api.fba.voidTransport(id).then(res => {
            //console.log(res.data);
            this.setState({
                loading: false
            })
            this.loadHistoryAction(id);
        }).catch(err => {
            this.handleHttpErrors(err);
        })
    }

    parseCost = (json) => {
        try {
            return JSON.parse(json).cost
        } catch {
            return "NA";
        }
    }

    render() {
        const {t, model} = this.props;
        const address = model.shipping_addr;
        return (
            <div>
                {/* Breadcrub Row */}
                <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12 lara-breadcrub">
                        <PageBreadCrub lists={this.state.breadcrub} t={t}/>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-8 col-sm-8 col-xs-12">
                        <Segment>
                            <Dimmer inverted active={this.state.loading}>
                                <Loader />
                            </Dimmer>
                            <SegmentRibbon label={model.id ? _.toString(model.fba_shipment_id) : "Loading..."} Buttons={
                                () => {return null}
                            }/>
                            {model.id && !model.packed ? <div className="row">
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <Message error icon>
                                        <Icon name="exclamation circle" />
                                        <Message.Content>
                                            <Message.Header>Shipment is not packed.</Message.Header>
                                            Please pack your shipment first by going back to shipment main page.
                                        </Message.Content>
                                    </Message>
                                </div>
                            </div> : null}
                            {model.id && model.packed ? <div className="row">
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <List relaxed='very' size='medium'>
                                        {model.fba_feed_type === 'FEED' ? <List.Item>
                                            <List.Icon name='amazon' size='large' verticalAlign='middle' />
                                            <List.Content>
                                                <List.Header as='a' onClick={() => {
                                                    this.submitCartonFeed(model.id)
                                                }}>Submit Carton Content Feed</List.Header>
                                                {this.state.actions.map(i => {
                                                    if (i.action === "_SUBMIT_FBA_CARTON_FEED"){
                                                        return (<List.Description key={i.id}>
                                                            Feed submitted on {i.action_date}&nbsp;&nbsp;
                                                            <span 
                                                                onClick={() => {
                                                                    this.checkSubmissionResult(i.id);
                                                                }}
                                                                className="lara-editable lara-clickable">Check Result</span>
                                                        </List.Description>)
                                                    } else {
                                                        return null;
                                                    }
                                                })}
                                            </List.Content>
                                        </List.Item> : null}
                                        <List.Item>
                                            <List.Icon name='amazon' size='large' verticalAlign='middle' />
                                            <List.Content>
                                                <List.Header as='a' onClick={() => {
                                                    this.submitShippingWeightAndDims(model.id)
                                                }}>Submit Shipping Weights & Dimensions</List.Header>
                                                {this.state.actions.map(i => {
                                                    if (i.action === "_SUBMIT_TRANSPORT_CONTENT"){
                                                        return (<List.Description key={i.id}>Transport request submitted on {i.action_date}</List.Description>)
                                                    } else {
                                                        return null;
                                                    }
                                                })}
                                            </List.Content>
                                        </List.Item>
                                        <List.Item>
                                            <List.Icon name='amazon' size='large' verticalAlign='middle' />
                                            <List.Content>
                                                <List.Header as='a' onClick={() => {
                                                    this.submitShippingEstimateRequest(model.id)
                                                }}>Request Shipping Estimate</List.Header>
                                                {this.state.actions.map(i => {
                                                    if (i.action === "_SHIPPING_ESTIMATE_REQUESTED"){
                                                        return (<List.Description key={i.id}>Request submitted on {i.action_date}</List.Description>)
                                                    } else {
                                                        return null;
                                                    }
                                                })}
                                            </List.Content>
                                        </List.Item>
                                        <List.Item>
                                            <List.Icon name='amazon' size='large' verticalAlign='middle' />
                                            <List.Content>
                                                <List.Header as='a' onClick={() => {
                                                    this.getShippingCostResult(model.id)
                                                }}>Request Final Shipping Cost</List.Header>
                                                {this.state.actions.map(i => {
                                                    if (i.action === "_SHIPPING_COST_ESTIMATE_REQUESTED"){
                                                        return (<List.Description key={i.id}>
                                                            $ {this.parseCost(i.response)} requested on {i.action_date}
                                                        </List.Description>)
                                                    } else {
                                                        return null;
                                                    }
                                                })}
                                            </List.Content>
                                        </List.Item>
                                        <List.Item>
                                            <List.Icon name='amazon' size='large' verticalAlign='middle' />
                                            <List.Content>
                                                <List.Header as='a' onClick={() => {
                                                    this.confirmFbaCarrierCost(model.id)
                                                }}>Confirm Shipping Cost</List.Header>
                                                {this.state.actions.map(i => {
                                                    if (i.action === "_FBA_SHIPPING_FINAL_CONFIRMED"){
                                                        return (<List.Description key={i.id}>Shipping cost confirmed with FBA on {i.action_date}. You can only void this within 24 hours</List.Description>)
                                                    } else {
                                                        return null;
                                                    }
                                                })}
                                            </List.Content>
                                        </List.Item>
                                        <List.Item>
                                            <List.Icon name='amazon' size='large' verticalAlign='middle' />
                                            <List.Content>
                                                <List.Header>
                                                    Print Labels
                                                </List.Header>
                                                <List.Content>
                                                    <a href={`${this.props.host}/print/fba/labels/${model._key}/PackageLabel_Letter_2`}
                                                        rel="noopener noreferrer"
                                                        target='_blank'>Two labels per US Letter label sheet (Using Amazon UPS Account)</a>
                                                </List.Content>
                                            </List.Content>
                                        </List.Item>
                                        <List.Item>
                                            <List.Icon name='amazon' size='large' verticalAlign='middle' />
                                            <List.Content>
                                                <List.Header as='a' onClick={() => {
                                                    this.markShippedInFba(model.id)
                                                }}>Complete Shipment</List.Header>
                                                {this.state.actions.map(i => {
                                                    if (i.action === "_FBA_SHIPPING_SHIPMENT_SHIPPED"){
                                                        return (<List.Description key={i.id}>Shipment marked as shipped on {i.action_date}</List.Description>)
                                                    } else {
                                                        return null;
                                                    }
                                                })}
                                            </List.Content>
                                        </List.Item>
                                        <List.Item>
                                            <List.Icon name='amazon' size='large' verticalAlign='middle' />
                                            <List.Content>
                                                <List.Header as='a' onClick={() => {
                                                    this.voidFbaCarrierLabels(model.id)
                                                }}>Void Previous Courier Request</List.Header>
                                                {this.state.actions.map(i => {
                                                    if (i.action === "_FBA_SHIPPING_LABEL_VOIDED") {
                                                        return (<List.Description key={i.id}>A previous label was voided on {i.action_date}</List.Description>)
                                                    } else {
                                                        return null;
                                                    }
                                                })}
                                            </List.Content>
                                        </List.Item>
                                    </List>
                                </div>
                            </div> : null}
                        </Segment>
                    </div>
                    <div className="col-md-4 col-sm-4 col-xs-12 lara-secondary-segment-wrapper">
                        {model.id ? <Segment>
                            <SegmentRibbon label="Shipment" Buttons={() => {
                                return null
                            }}/>
                            <Dimmer inverted active={this.state.loading}>
                                <Loader />
                            </Dimmer>
                            <Header as="h3">
                                Ship To:
                            </Header>
                            <div className="lara-text-wrapper">
                                <span className='lara-text-value'>{address.company}</span>
                                {address.phone ? <span className='lara-text-value'>{address.phone}</span> : null}
                                {address.street1 ? <span className='lara-text-value'>{address.street1}</span> : null}
                                {address.street2 ? <span className='lara-text-value'>{address.street2}</span> : null}
                                {address.street3 ? <span className='lara-text-value'>{address.street3}</span> : null}
                                <span className='lara-text-value'>
                                    {address.city ? `${address.city}, `: null}
                                    {address.state_code ? `${address.state_code}    `: null}
                                    {address.postal_code ? `${address.postal_code}`: null}
                                </span>
                            </div>
                            <Header as="h3">
                                Change Feed Type from {model.fba_feed_type} to: 
                            </Header>
                            <List>
                                {model.fba_feed_type !== 'NONE' ? <List.Item>
                                    <List.Content>
                                        <List.Header as='a'>
                                            <span onClick={() => {
                                                this.onChangeFeedTypeClick('NONE')
                                            }}>Manual Entry</span>
                                        </List.Header>
                                        <List.Description>
                                            Login into FBA and manually fill in carton content.
                                        </List.Description>
                                    </List.Content>
                                </List.Item> : null}
                                {model.fba_feed_type !== '2D_BARCODE' ? <List.Item>
                                    <List.Content>
                                        <List.Header as='a'>
                                            <span onClick={() => {
                                                this.onChangeFeedTypeClick('2D_BARCODE')
                                            }}>2D Barcode</span>
                                        </List.Header>
                                        <List.Description>
                                            Use Pack and print 2D carton labels.
                                        </List.Description>
                                    </List.Content>
                                </List.Item> : null}
                                {model.fba_feed_type !== 'FEED' ? <List.Item>
                                    <List.Content>
                                        <List.Header as='a'>
                                            <span onClick={() => {
                                                this.onChangeFeedTypeClick('FEED')
                                            }}>System Feed</span>
                                        </List.Header>
                                        <List.Description>
                                            Use Pack and submit feed electronically.
                                        </List.Description>
                                    </List.Content>
                                </List.Item> : null}
                            </List>
                        </Segment>  : null}
                    </div>
                </div>
                <Confirm open={this.state.deleteConfirm} 
                    content={t('ShipmentVoidAlert')}
                    onCancel={() => {
                        this.setState({
                            deleteConfirm: false
                        })
                    }}
                    cancelButton={t('Go Back')}
                    confirmButton={t('Confirm')}
                    onConfirm={() => this.onVoidClick()} 
                />
                <Modal
                    closeOnDimmerClick={false}
                    size="mini"
                    closeIcon
                    onClose={() => {
                        this.setState({submissionModal: false})
                    }}
                    closeOnEscape={true} open={this.state.submissionModal}>
                    <Header icon='amazon' content={null} />
                    <Modal.Content>
                        <List size='large' relaxed='very'>
                            {this.state.submission_results.map((i,index) => {
                                return <List.Item key={index}>
                                    <List.Header>Submission: {i.FeedSubmissionId} </List.Header>
                                    <List.Content>
                                        Status: {i.FeedProcessingStatus === '_DONE_' ? 
                                            <Label size='mini' color='green'>
                                                    _DONE_
                                                </Label> : <Label size='mini' color='red'>
                                                {i.FeedProcessingStatus}
                                            </Label>}
                                    </List.Content>
                                    <List.Content>
                                        Started: {i.StartedProcessingDate}
                                    </List.Content>
                                    <List.Content>
                                        Finished: {i.CompletedProcessingDate}
                                    </List.Content>
                                </List.Item>
                            })}
                        </List>
                    </Modal.Content>
                </Modal>
            </div>
        )
    }
}

ShipmentViewPage.propTypes = {
    model: PropTypes.object.isRequired,
    fetchById: PropTypes.func.isRequired,
    setEmptyShipmentInStore: PropTypes.func.isRequired,
    resetModelAfterFillAll: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    history: PropTypes.shape({
       push: PropTypes.func.isRequired
    }).isRequired,
    location: PropTypes.object.isRequired,
    preference: PropTypes.object.isRequired,
    updateShipmentProperty: PropTypes.func.isRequired,
    server_time: PropTypes.string.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired
        }),
        path: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired
    }),
    host: PropTypes.string.isRequired
};

function mapStateToProps(state) {
    return {
        model: state.shipmentModel,
        preference: state.system.preference,
        host: state.system.preference.host,
        server_time: state.system.server_time,
        user: state.system.user
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchById,
        setEmptyShipmentInStore,
        resetModelAfterFillAll,
        updateShipmentProperty
    }, dispatch)
}

export default withI18n()(connect(mapStateToProps, mapDispatchToProps)(ShipmentViewPage));