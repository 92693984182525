//import _ from 'lodash';
import update from 'immutability-helper';
import {
    GUEST_BOOT_LOADED,
    USER_LOGGED_OUT,
    USER_LOGGED_IN_VIA_LOCAL_STATE,
    INITIAL_BOOT_LOADED,
    WORKING_CLIENT_SWITCHED,
    PREFERENCE_UPDATED_BY_FORM,
    SHIP_STATION_WAREHOUSE_LOADED,
    NEW_CLIENT_CREATED_TO_SELECTOR,
    COURIER_UPDATED_ON_SERVER,
    COURIER_CREATED_ON_SERVER,
    CONFIGURATION_RESOURCE_CREATED,
    CONFIGURATION_RESOURCE_UDPATED,
    CONFIGURATION_RESOURCE_DELETED,
    SHIP_STATION_SHIPPING_LOADED,
    SHIPPO_OBJECTS_LOADED,
    MY_PROFILE_UPDATED,
    API_TOKEN_REQUESTED
} from '../../actions/types';

export default function systemReducer(state = {
    user: {
        id: null,
        name: null,
        email: null,
        role_id: 0,
        is_su: false,
        is_main_user: false,
        is_active: true,
        order_controller: true,
        client_id: "0",
        last_working_client: "0",
        working_client: null,
        client: null
    },
    preference: {
        name: "",
        address: "",
        city: "",
        postal: "",
        state: "",
        country: "",
        phone: "",
        host: null,
        logo: null,
        branding_name: null,
        branding: null,
        home_address: null,
        login_bg_color: '#2185d0'
    },
    auth: {
        jwt: null,
        expire: null,
        timezone: 'America/New_York'
    },
    clients: [],
    resources: {
        couriers: [],
        taxes: [],
        terms: [],
        uoms: [],
        packages: []
    },
    ship_station: {
        warehouses: [
            
        ],
        shipping: {
            couriers: [],
            packages: {},
            services: {}
        }
    },
    shippo: [],
    timezones: [],
    server_time: "",
    do_fba: false,
    server_pref: {},
    fb: {
        ServiceName: [],
        QuoteType: [],
        IncoCode: [],
        TransportMode: []
    },
    v: "1.3.0",
    build: 0
}, action = {}) {
    switch(action.type) {
        case SHIP_STATION_SHIPPING_LOADED:
            return update(state, {
                ship_station: {
                    shipping: {
                        $set: action.payload
                    }
                }
            });

        case SHIPPO_OBJECTS_LOADED:
            return update(state, {
                shippo: {$set: action.payload}
            });

        case COURIER_CREATED_ON_SERVER:
            return update(state, {
                resources: {
                    couriers: {
                        $push: [action.payload]
                    }
                }
            });

        case COURIER_UPDATED_ON_SERVER:
            return update(state, {
                resources: {
                    couriers: {
                        [action.payload.index]: {
                            $set: action.payload.model
                        }
                    }
                }
            });

        case CONFIGURATION_RESOURCE_CREATED:
            return update(state, {
                resources: {
                    [action.payload.resource]: {
                        $push: [action.payload.model]
                    }
                }
            });

        case CONFIGURATION_RESOURCE_UDPATED:
            return update(state, {
                resources: {
                    [action.payload.resource]: {
                        [action.payload.index]: {
                            $set: action.payload.model
                        }
                    }
                }
            });

        case CONFIGURATION_RESOURCE_DELETED:
            return update(state, {
                resources: {
                    [action.payload.resource]: {
                        $splice: [[action.payload.index, 1]]
                    }
                }
            })

        case PREFERENCE_UPDATED_BY_FORM:
            return update(state, {
                preference: {
                    [action.payload.field]: {$set: action.payload.value}
                }
            });

        case NEW_CLIENT_CREATED_TO_SELECTOR:
            return update(state, {
                clients: {
                    $push: [action.payload]
                }
            });

        case GUEST_BOOT_LOADED:
            return update(state, {
                preference: {$set: action.payload}
            });

        case INITIAL_BOOT_LOADED:
            const zones = [];
            const {timezones} = action.payload;
            for (var key in timezones) {
                if (timezones.hasOwnProperty(key)) {
                    zones.push({
                        value: key,
                        text: timezones[key]
                    })
                }
            }
            return update(state, {
                preference:{$set: action.payload.preference},
                clients: {$set: action.payload.clients},
                user: {$set: action.payload.user},
                resources: {$set: action.payload.resources},
                packages: {$set: action.payload.packages},
                timezones: {$set: zones},
                server_time: {$set: action.payload.server_time},
                do_fba: {$set: action.payload.do_fba},
                server_pref: {$set: action.payload.server_preference},
                fb: {$set: action.payload.fb},
                build: {$set: action.payload.build},
                v: {$set: action.payload.v}
            });

        case USER_LOGGED_IN_VIA_LOCAL_STATE:
            return update(state, {
                user: {$set: action.payload.user},
                auth: {$set: action.payload.auth}
            });

        case WORKING_CLIENT_SWITCHED: 
            return update(state, {
                user: {
                    last_working_client: {$set: action.payload}
                }
            });

        case USER_LOGGED_OUT:
            return {
                user: {
                    id: null,
                    name: null,
                    email: null,
                    role_id: 0,
                    is_su: false,
                    is_main_user: false,
                    is_active: true,
                    order_controller: true,
                    client_id: "0",
                    last_working_client: "0",
                    working_client: null,
                    client: null
                },
                preference: {
                    logo: 'https://y3pl.com/assets/logo_1.png',
                    host: 'http://lara3.test'
                },
                auth: {
                    jwt: null,
                    expire: null,
                    timezone: 'America/New_York'
                },
                clients: []
            };

        case SHIP_STATION_WAREHOUSE_LOADED:
            return update(state, {
                ship_station: {
                    warehouses: {$set: action.payload}
                }
            });

        case MY_PROFILE_UPDATED:
            return update(state, {
                user: {
                    name: {$set: action.payload.name},
                    email: {$set: action.payload.email},
                    avatar_path: {$set: action.payload.avatar_path},
                    lang: {$set: action.payload.lang}
                }
            });

        case API_TOKEN_REQUESTED:
            return update(state, {
                user: {
                    api_token: {$set: 'abcdefgh******************'}
                }
            })

        default: return state;
    }
}