import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";
import {withI18n} from 'react-i18next';
import { Form, Segment, Dimmer, Loader } from "semantic-ui-react";
import FormError from '../includes/messages/422';
import PageBreadCrub from '../includes/partials/PageBreadCrub';
import AlertComponent from '../includes/messages/alert';
import Lara from '../../lara';
import validator from 'validator';
import SegmentRibbon from '../includes/partials/SegmentRibbon';
import CountrySelector from '../includes/selectors/CountrySelector';
import CourierSelector from '../includes/selectors/CourierSelector';
import CourierPaymentSelector from '../includes/selectors/CourierPaymentSelector';
import PaymentTermSelector from '../includes/selectors/PaymentTermSelector';
import TaxRateSelector from '../includes/selectors/TaxRateSelector';
import {
    fetchById,
    updateModelInStore,
    updateModelOnServer,
    createNewModelInStore
} from '../../actions/relation';
import PrimaryButton from '../includes/buttons/PrimaryButton';

class RelationEditPage extends Component {
    constructor() {
        super();
        this.state = {
            breadcrub: [
                {isLink:false, to:null, tag: '', active: true, key: 1}
            ],
            errors: [],
            loading: false,
            relationType: null
        };
    }

    componentDidMount() {
        const {match, t, model} = this.props;
        const {type, id} = match.params;
        if (id === 'new') {
            this.props.createNewModelInStore(type);
            this.setState({
                relationType: type,
                breadcrub: this.getBreadCrub(type, t(_.upperFirst(type)), t('Create'), null)
            });
        } else {
            if (_.toString(id) !== _.toString(model.id)) {
                this.setState({
                    loading: true
                });
                this.props.fetchById(id).then(res => {
                    this.setState({
                        relationType: type,
                        loading: false,
                        breadcrub: this.getBreadCrub(res.name, 
                            t(_.upperFirst(type)), 
                            t('Edit'), `/relations/${type}/${res.id}/action/view`)
                    })
                }).catch(err => {
                    const errors = Lara.axiosError(err, this.props.t);
                    if (errors.http_code === 422) {
                        this.setState({
                            errors: errors.message,
                            loading: false
                        });
                    } else {
                        this.setState({
                            loading: false
                        });
                        Lara.axiosAlert(<AlertComponent 
                            support={errors.support}
                            message={errors.message}
                            reference={errors.reference}
                            t={this.props.t}
                        />);
                    }
                    this.setState({loading: false});
                })
            } else {
                this.setState({
                    relationType: type,
                    breadcrub: this.getBreadCrub(model.name, t(_.upperFirst(type)), 
                        t('Edit'), 
                        `/relations/${type}/${model.id}/action/view`)
                })
            }
        }
    }

    getBreadCrub = (name, type, action, viewPageLink) => {
        return [
            {isLink:false, to:null, tag: _.upperFirst(type), active: false, key: 1},
            {isLink:viewPageLink ? true : false, to:viewPageLink, tag: _.upperFirst(name), active: false, key: 2},
            {isLink:false, to:null, tag: action, active: true, key: 3}
        ]
    }

    onFormSubmit = () => {
        const errors = this.validateInput();
        if (_.isEmpty(errors)) {
            this.setState({
                loading: true
            });
            this.props.updateModelOnServer(this.props.model).then(res => {
                this.props.history.push(`/relations/${this.state.relationType}/${res.id}/action/view`);
            }).catch(err => {
                const errors = Lara.axiosError(err, this.props.t);
                if (errors.http_code === 422) {
                    this.setState({
                        errors: errors.message,
                        loading: false
                    });
                } else {
                    this.setState({
                        loading: false
                    });
                    Lara.axiosAlert(<AlertComponent 
                        support={errors.support}
                        message={errors.message}
                        reference={errors.reference}
                        t={this.props.t}
                    />);
                }
            });
        } else {
            this.setState({
                errors: errors
            });
        }
    };

    validateInput = () => {
        const {name, email} = this.props.model;
        const errors = [];
        if (!name) {
            errors.push(this.props.t('CompanyNameIsRequired'));
        }
        if (email) {
            if (!validator.isEmail(email)) {
                errors.push(this.props.t('EmailAddressIsinvalid'));
            }
        }
        return errors;
    };
    
    render() {
        const {model, t, updateModelInStore} = this.props
        return (
            <div>
                {/* Breadcrub Row */}
                <div className="row">
                    <div className="col-md-12 lara-breadcrub">
                        <PageBreadCrub lists={this.state.breadcrub} t={t}/>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <Segment>
                            <SegmentRibbon label={model.name ? model.name : t('Form')} Buttons={
                                    () => {return (<React.Fragment>
                                        <PrimaryButton
                                            size='mini'
                                            float='right'
                                            label={t('Save')}
                                            onClick={() => {
                                                this.onFormSubmit()
                                            }}
                                            loading={this.state.loading}
                                        />
                                    </React.Fragment>)}
                                }/>
                                
                            <Dimmer inverted active={this.state.loading}>
                                <Loader />
                            </Dimmer>
                            
                            <Form size="mini">
                                {!_.isEmpty(this.state.errors) ? (
                                    <FormError errors={this.state.errors} />
                                ) : null}
                                <Form.Group widths='equal'>
                                    <Form.Input 
                                        fluid 
                                        required={true}
                                        label={t("CompanyName")}
                                        name="name"
                                        value={model.name ? model.name : ""} 
                                        onChange={(e) => { updateModelInStore(e.target.name, e.target.value) }}
                                    />
                                    <Form.Input 
                                        fluid
                                        label={t("Account")}
                                        name="account"
                                        value={model.account ? model.account : ""} 
                                        onChange={(e) => { updateModelInStore(e.target.name, e.target.value) }}
                                    />
                                    <Form.Input 
                                        fluid
                                        label={t("Email")}
                                        name="email"
                                        value={model.email ? model.email : ""} 
                                        onChange={(e) => { updateModelInStore(e.target.name, e.target.value) }}
                                    />
                                </Form.Group>
                                <Form.Group widths='equal'>
                                    <CourierSelector 
                                        onSelectChange={(value) => {
                                            updateModelInStore('default_courier', value)
                                        }}
                                        selected={model.default_courier ? model.default_courier : "0"}
                                        appendNonOption={true}
                                        returnObject={false}
                                        label={t("Courier")}
                                    />
                                    <CourierPaymentSelector
                                        onSelectChange={(value) => {
                                            updateModelInStore('default_shipping_payment', value)
                                        }}
                                        selected={model.default_shipping_payment}
                                        label={t("CourierPaymentMethod")}
                                        t={t}
                                    />
                                </Form.Group>
                                {model.default_shipping_payment !== 'prepaid' ? <Form.Group widths='equal'>
                                    <Form.Input 
                                        fluid
                                        label={t("CourierAccount")}
                                        name="default_courier_account"
                                        value={model.default_courier_account ? model.default_courier_account : ""} 
                                        onChange={(e) => { updateModelInStore(e.target.name, e.target.value) }}
                                    />
                                    <Form.Input 
                                        fluid
                                        label={t("CourierBillingPostal")}
                                        name="default_collect_postal"
                                        value={model.default_collect_postal ? model.default_collect_postal : ""} 
                                        onChange={(e) => { updateModelInStore(e.target.name, e.target.value) }}
                                    />
                                    <CountrySelector
                                        selected={model.default_collect_country ? model.default_collect_country : "N"}
                                        countrySelected={(value) => {
                                            updateModelInStore('default_collect_country', value)
                                        }}
                                        label={t('CourierBillingCountry')}
                                    />
                                </Form.Group> : null}
                                <Form.Group widths='equal'>
                                    <Form.Input 
                                        fluid
                                        label={t("Phone")}
                                        name="phone"
                                        value={model.phone ? model.phone : ""} 
                                        onChange={(e) => { updateModelInStore(e.target.name, e.target.value) }}
                                    />
                                    <TaxRateSelector
                                        selected={model.default_tax_rate ? model.default_tax_rate : "0"}
                                        onSelectChange={(value) => {
                                            updateModelInStore('default_tax_rate', value)
                                        }}
                                        label={t('TaxRate')}
                                    />
                                    <PaymentTermSelector
                                        selected={model.default_term ? model.default_term : "NA" }
                                        onSelectChange={(value) => {
                                            updateModelInStore('default_term', value)
                                        }}
                                        label={t('PaymentTerm')}
                                    />
                                </Form.Group>
                                <Form.TextArea 
                                    label={t("DocumentNote")}
                                    name='default_instruction'
                                    value={model.default_instruction ? model.default_instruction : ""}
                                    onChange={(e, {value}) => { updateModelInStore('default_instruction', value) }}
                                />
                                <Form.Group widths='equal'>
                                    <Form.Checkbox
                                        label={t('DisplayNote')}
                                        name="print_instruction"
                                        onChange={(e,data) => { updateModelInStore('print_instruction', data.checked) }}
                                        checked={model.is_active}
                                    />
                                    <Form.Checkbox
                                        label={t('Status')}
                                        name="is_active"
                                        onChange={(e,data) => { updateModelInStore('is_active', data.checked) }}
                                        checked={model.is_active}
                                    />
                                </Form.Group>
                            </Form>
                        </Segment>
                    </div>
                </div>
            </div>

        );
    }
}

RelationEditPage.propTypes = {
    model: PropTypes.object.isRequired,
    updateModelInStore: PropTypes.func.isRequired,
    updateModelOnServer: PropTypes.func.isRequired,
    createNewModelInStore: PropTypes.func.isRequired,
    fetchById: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        model: state.relationModel
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            updateModelInStore,
            updateModelOnServer,
            createNewModelInStore,
            fetchById
        },
        dispatch
    );
}

export default withI18n()(connect(
    mapStateToProps,
    mapDispatchToProps
)(RelationEditPage));