import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import {api} from '../../../api';
import {Table, Pagination} from 'semantic-ui-react';

class TraceTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            result: {
                current_page: 1,
                last_page: 1,
                data: []
            }
        }
    }

    componentDidMount() {
        const {traceableId, traceableType} = this.props;
        this.setState({
            loading: true
        });
        api.getTraces(traceableType, traceableId, 1).then(res => {
            this.setState({
                loading: false,
                result: res.data.data
            })
        });
    }
    
    onPageChange = (data) => {
        const {activePage} = data;
        const {traceableId, traceableType} = this.props;
        api.getTraces(traceableType, traceableId, activePage).then(res => {
            this.setState({
                result: res.data.data
            })
        });
    }

    render() {
        const {data, last_page, current_page} = this.state.result;
        return (
            <div className="row">
                {!this.state.loading ? <div className="col-md-12 col-sm-12 col-xs-12">
                    <Table compact
                        basic
                        padded={false}>
                        <Table.Body>
                            {_.isArray(data) && data.map(i => {
                                return (<Table.Row key={i.id}>
                                    <Table.Cell>
                                        {i.id}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {i.subject}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {moment(i.updated_at).format('MMM D @ LT').toString()}
                                    </Table.Cell>
                                </Table.Row>)
                            })}
                        </Table.Body>
                    </Table>
                </div> : null}
                <div className="col-md-12 col-sm-12 col-xs-12 lara-row-margin">
                    <Pagination
                        boundaryRange={1}
                        activePage={current_page}
                        totalPages={last_page}
                        onPageChange={(e, data) => {
                            this.onPageChange(data);
                        }}
                    />
                </div>
            </div>
        )
    }
}

TraceTable.propTypes = {
    traceableId: PropTypes.number.isRequired,
    traceableType: PropTypes.string.isRequired
};

export default TraceTable