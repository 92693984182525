import React, { Component } from 'react';
import { bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import update from 'immutability-helper';
import {withI18n} from 'react-i18next';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import AlertComponent from '../../includes/messages/alert';
import FormError from '../../includes/messages/422';
import Lara from '../../../lara';
import { Form, Table, Icon, Input, Dimmer, Loader, Message } from 'semantic-ui-react';
import {
    updateShipmentProperty,
    shipmentCommentAdded
} from '../../../actions/shipment';
import {api} from '../../../api';
import PrimaryButton from '../../includes/buttons/PrimaryButton';


class ShipmentBillingModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            message: [

            ],
            errors: [

            ],
            data: {
                client_id: "",
                billing_activity_id: "",
                note: "",
                bill_date: "",
                reference: "",
                billable_id: "",
                billable_type: "",
                entries: [
    
                ]
            }
        }
    }

    componentDidMount() {
        const {model, updateShipmentProperty, onCloseModal, shipmentCommentAdded} = this.props;
        this.setState({
            loading: true
        });
        api.shipments.bill(model.id).then(res => {
            const {billing, ss_error, courier_charged, need_entries, tracking, comment} = res.data.data;
            // console.log(billing, ss_error, courier_charged, need_entries, tracking);
            if (comment) {
                shipmentCommentAdded(comment);
            }
            if (!need_entries) {
                updateShipmentProperty(model.id, 'tracking', tracking);
                updateShipmentProperty(model.id, 'billed', true);
                onCloseModal();
            } else {
                let message = [];
                updateShipmentProperty(model.id, 'tracking', tracking);
                //console.log(billing, ss_error, courier_charged, need_entries, tracking);
                if (ss_error) {
                    message.push(`"Failed to load from your ShipStation account: ${ss_error}`);
                }
                if (courier_charged) {
                    message.push(`Courier charge captured successfully`);
                }
                if (_.isEmpty(billing.charges)) {
                    message.push(`Billing is set up without charge items, please check with your administration team`);
                }
                const data = this.structureModelData(billing, model);
                this.setState({
                    message: message,
                    data: data,
                    loading: false
                });
            }
        }).catch(err => {
            console.log(err);
        })
    }

    structureModelData = (activity, model) => {
        const data = {
            client_id: activity.client_id,
            billing_activity_id: activity.id,
            note: "",
            bill_date: moment().format('YYYY-MM-DD'),
            reference: _.isObject(model.order) ? `${model.order.reference} ${(model.order.po &&  model.order.po !== model.order.reference)? `(${model.order.po})` : ""}` : `Shipment #: ${model.id}`,
            billable_id: model.id,
            billable_type: "App\\Models\\Shipment",
            entries: [

            ]
        };

        let totalUnits = 0;
        let totalCarton = 0;
        let totalBag = 0;
        let totalPack = 0;
        let totalPallet = 0;
        const {shipment_lines} = model;
        let totalSku = shipment_lines.length;
        const {charges} = activity;
        _.each(shipment_lines, l => {
            const {quantity, inventory} = l;
            totalUnits = parseInt(quantity, 10) + totalUnits;
            if (inventory.uoms.length > 0) {
                //Step 1: Find the Carton UOM
                let cartonIndex = _.findIndex(inventory.uoms, i => {
                    return _.capitalize(i.name) === "Carton";
                });
                if (cartonIndex > -1) {
                    //If it has carton, calculate by cartons first, then take the remainder to check for bags or packs
                    const qtyPerBase = parseInt(inventory.uoms[cartonIndex].pivot.qty_per_base);
                    if (qtyPerBase > 0) {
                        let remainderQty = parseInt(quantity) % qtyPerBase;
                        let hasBagOrPack = false;
                        if (remainderQty > 0) {
                            _.each(inventory.uoms, uom => {
                                if (_.capitalize(uom.name) === "Bag") {
                                    //Bag Rule:
                                    //Only works if carton rule is not in place
                                    //If UOM qty per base is smaller than quantity ordered, add a bag.
                                    //If UOM qty per base is greater than quantity ordered, no bag
                                    const qtyPerBase = parseInt(uom.pivot.qty_per_base);
                                    if (qtyPerBase > 0 && qtyPerBase <= parseInt(quantity)) {
                                        totalBag = Math.ceil(remainderQty / qtyPerBase) + totalBag
                                    }
                                    hasBagOrPack = true;
                                }

                                if (_.capitalize(uom.name) === "Pack") {
                                    //Bag Rule:
                                    //Only works if carton rule is not in place
                                    //If UOM qty per base is smaller than quantity ordered, add a bag.
                                    //If UOM qty per base is greater than quantity ordered, no bag
                                    const qtyPerBase = parseInt(uom.pivot.qty_per_base);
                                    if (qtyPerBase > 0 && qtyPerBase <= parseInt(quantity)) {
                                        totalPack = Math.ceil(remainderQty / qtyPerBase) + totalPack
                                    }
                                    hasBagOrPack = true;
                                }
                            })
                        }

                        if (hasBagOrPack) {
                            totalCarton = Math.floor(parseInt(quantity) / qtyPerBase) + totalCarton;
                        } else {
                            totalCarton = Math.ceil(parseInt(quantity) / qtyPerBase) + totalCarton;
                        }
                    }
                }
                _.each(inventory.uoms, uom => {
                    //No carton UOM? Just bags or packs
                    if (cartonIndex <= -1) {
                        if (_.capitalize(uom.name) === "Bag") {
                            const qtyPerBase = parseInt(uom.pivot.qty_per_base);
                            if (qtyPerBase > 0 && qtyPerBase <= parseInt(quantity)) {
                                totalBag = Math.floor(parseInt(quantity) / qtyPerBase) + totalBag
                            }
                        }
    
                        if (_.capitalize(uom.name) === "Pack") {
                            const qtyPerBase = parseInt(uom.pivot.qty_per_base);
                            if (qtyPerBase > 0 && qtyPerBase <= parseInt(quantity)) {
                                totalPack = Math.floor(parseInt(quantity) / qtyPerBase) + totalPack
                            }
                        }
                    }

                    if (_.capitalize(uom.name) === "Pallet") {
                        const qtyPerBase = parseInt(uom.pivot.qty_per_base);
                        if (qtyPerBase > 0) {
                            totalPallet = Math.ceil(parseInt(quantity) / qtyPerBase) + totalPallet
                        }
                    }
                })
            }
        });
        _.each(charges, charge => {
            charge._meta = {
                quantity: 1
            };
            if (_.capitalize(charge.uom) === "Carton") {
                charge._meta.quantity = totalCarton
            } else if (_.capitalize(charge.uom) === "Pack") {
                charge._meta.quantity = totalPack
            } else if (_.capitalize(charge.uom) === "Sku") {
                charge._meta.quantity = totalSku
            } else if (_.capitalize(charge.uom) === "Bag") {
                charge._meta.quantity = totalBag
            } else if (_.capitalize(charge.uom) === "Unit") {
                charge._meta.quantity = totalUnits
            } else if (_.capitalize(charge.uom) === "Order") {
                charge._meta.quantity = 1.000
            } else if (_.capitalize(charge.uom) === "Pallet") {
                charge._meta.quantity = Math.ceil(totalPallet)
            } else {
                charge._meta.quantity = charge.pivot.quantity
            }
            data.entries.push({
                price: parseFloat(charge.pivot.rate),
                quantity: parseFloat(charge._meta.quantity,10),
                billing_item_id: charge.id,
                meta_name: charge.name,
                meta_uom: charge.uom
            });
        });
        return data;
        // console.log("Total SKU:", totalSku);
        // console.log("Total Bag:", totalBag);
        // console.log("Total Carton:", totalCarton);
        // console.log("Total Pack:", totalPack);
        // console.log("Total Units:", totalUnits);
    }
    
    onModelValueChange = (field, value) => {
        this.setState({
            data: update(this.state.data, {
                [field]: {$set: value}
            })
        });
    }

    onEntryLineChange = (index, field, value) => {
        this.setState({
            data: update(this.state.data, {
                entries: {[index]: {
                    [field]: {$set: value}
                }}
            })
        })
    }

    onDeleteEntryLine = (index) => {
        this.setState({
            data: update(this.state.data, {
                entries: {$splice: [[index, 1]]}
            })
        })
    }

    onFormSubmit = () => {
        const errors = this.validateInput();
        if (_.isEmpty(errors)) {
            this.setState({
                loading: true,
                errors: []
            });
            api.shipments.postBill(this.props.model.id, this.state.data).then(res => {
                this.setState({
                    loading: false
                }, () => {
                    this.props.updateShipmentProperty(this.props.model.id, 'billed', true);
                    this.props.onCloseModal();
                });
            }).catch(err => {
                const errors = Lara.axiosError(err, this.props.t);
                if (errors.http_code === 422) {
                    this.setState({
                        errors: errors.message,
                        loading: false
                    });
                } else {
                    this.setState({
                        loading: false
                    });
                    Lara.axiosAlert(<AlertComponent 
                        support={errors.support}
                        message={errors.message}
                        reference={errors.reference}
                        t={this.props.t}
                    />);
                }
            });
        } else {
            this.setState({
                errors: errors
            });
        }
    };

    validateInput = () => {
        const {client_id, billing_activity_id, bill_date, reference, entries} = this.state.data;
        const errors = [];
        if (!client_id) {
            errors.push(this.props.t('Client is required'));
        }
        if (!billing_activity_id) {
            errors.push(this.props.t("Activity is reuqired"));
        }
        if (!bill_date) {
            errors.push(this.props.t("Date is reuqired"));
        }
        if (!reference) {
            errors.push(this.props.t("Reference is required"));
        }
        if (!_.isArray(entries)) {
            errors.push(this.props.t("At least 1 item is required"));
        }
        if (entries.length <= 0) {
            errors.push(this.props.t("At least 1 item is required"));
        }
        return errors;
    };

    render() {
        const {t} = this.props;
        const model = this.state.data;
        return (
            <div className="row">
                <div className="col-md-12">
                <Dimmer inverted active={this.state.loading}>
                    <Loader />
                </Dimmer>
                <Message
                    header={t('Billing')}
                    list={this.state.message}
                />
                {!_.isEmpty(this.state.errors) ? (
                    <FormError errors={this.state.errors} />
                ) : null}
                {!this.state.loading ? 
                <div>
                <Form>
                    <Form.Group widths='equal'>
                        <Form.Input
                            type='date'
                            value={model.bill_date}
                            fluid={true}
                            onChange={(e, {value}) => {
                                this.onModelValueChange('bill_date', value)
                            }}
                            label={t("BillDate")}
                        />
                        <Form.Input
                            value={model.reference}
                            fluid={true}
                            onChange={(e, {value}) => {
                                this.onModelValueChange('reference', value)
                            }}
                            label={t("Reference")}
                        />
                        </Form.Group>
                        <Table
                            compact
                            basic
                            padded={false}
                        >
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>
                                    {t('Item')}
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    {t('UOM')}
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    {t('Quantity')}
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    {t('Price')}
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    {t('Delete')}
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {model.entries.map((item, index) => {
                                return (<Table.Row key={index}>
                                    <Table.Cell>
                                        {item.meta_name}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {item.meta_uom}
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Input size='mini' value={item.quantity} 
                                            type="number"
                                            onChange={(e,{value}) => {
                                                this.onEntryLineChange(index, 'quantity', value)
                                            }}
                                        />
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Input size='mini' value={item.price} 
                                            type="number"
                                            onChange={(e,{value}) => {
                                                this.onEntryLineChange(index, 'price', value)
                                            }}
                                        />
                                    </Table.Cell>
                                    <Table.Cell>
                                        <span className="lara-editable lara-clickable">
                                            <Icon name='times' onClick={() => {
                                                this.onDeleteEntryLine(index);
                                            }}/>
                                        </span>
                                    </Table.Cell>
                                </Table.Row>)
                            })}
                        </Table.Body>
                    </Table>
                    <Form.TextArea
                        value={model.note}
                        onChange={(e, {value}) => {
                            this.onModelValueChange('note', value)
                        }}
                        label={t("Note")}
                    />
                </Form>
                <div className="lara-row-margin">
                <PrimaryButton
                    size='mini'
                    float='right'
                    label={t('Save')}
                    onClick={() => {this.onFormSubmit()}}
                    loading={this.state.loading}
                />
                </div>
                </div> : null}
                </div>
            </div>
        )
    }
}

ShipmentBillingModal.propTypes = {
    model: PropTypes.shape({
        id: PropTypes.number.isRequired,
        shipment_lines: PropTypes.array.isRequired
    }).isRequired,
    onCloseModal: PropTypes.func.isRequired,
    updateShipmentProperty: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        model: state.shipmentModel
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        updateShipmentProperty,
        shipmentCommentAdded
    }, dispatch)
}

export default withI18n()(connect(mapStateToProps, mapDispatchToProps)(ShipmentBillingModal))