module.exports = {
    //Languages:
    "English": "English",
    "SimplifiedChinese": "Simplified Chinese",

    //Page
    "3PLLogIn": "3PL Log In",
    "ForgetPassword": "Forgot Password",
    //Page - Inventories
    "SKU":"SKU",
    "Type": "Type",
    "Name": "Name",
    "UPC": "UPC",
    "Total": "Total",
    "Reserve": "Reserve",
    "Available": "Available",
    "Description": "Description",
    "Cost": "Cost",
    "Selling": "Selling",
    "MSRP": "MSRP",
    "Status": "Status",
    "CustomizedValueField": "Customized Value Field",
    "Internationl": "International Shipping",
    "Lot": "Require Lot Number Control",
    "Serial": "Require Serial Number Control",
    "Category": "Tags & Category",
    "CategorySelector": "Pick a value or enter a new one",
    "HSCODE": "HS Code",
    "CustomValue": "Custom Value",
    "Origin": "Country of Origin",
    "Composition": "Composition",
    "Barcode_1": "Other Barcode 1",
    "Barcode_2": "Other Barcode 2",
    "MoveBetweenLp":"Move Quantity",
    "AdjustQty": "Adjustment",
    "ConvertToPristine": "To Pristine",
    "Disposal": "Disposal",

    //Page - Inventories Tab Menu
    "Location": "Bin Location",
    "Location-P": "Picking",
    "Location-S": "Storage",
    "LocationType": "Bin Type",
    "LocationCategory": "Bin Category",
    "InventoryItem": "Inventory Item",
    "Kits":"Kits & Assemblies",
    "ItemQty":"# of Units In Kit",
    "Quarantine":"Quarantine",
    "Pricing": "Special Pricing",
    "UOM":"UOM",
    "UnitOfMeasure": "Unit Of Measure",
    "BaseUOM": "Base",
    "BaseQty": "# of Base Unit",
    "Length": "Length",
    "Width": "Width",
    "Height": "Height",
    "Weight": "Weight",
    "Dim": "Dimension",
    "Lb": "LB",
    "Kg": "KG",
    "CM": "CM",
    "In": "Inch",
    "LotExp": "Lot & Exp",
    //Page - Relation
    "Account": "Account",
    "Courier": "Courier",
    "CourierAccount": "Courier Account",
    "CourierBillingPostal": "Courier Billing Postal",
    "CourierBillingCountry": "Courier Billing Country",
    "CourierPaymentMethod": "Courier Payment Method",
    "PaymentTerm": "Payment Term",
    "TaxRate": "Tax Rate",
    "Note": "Note",
    "DisplayNote": "Display Note to Warehouse",

    //Page - Addresses
    "Contact": "Contact",
    "StreetAddress": "Street Address",

    //Courier Payment Options
    "Prepaid": "Prepaid",
    "Collect": "Collect",
    "Third": "Third Party Account",
    "Third_party":"Third Party Account",

    //Page - Users:
    "Role": "Role",
    "UserName": "User Name",
    "MainUser": "Our Company User",

    //Page - Client:
    "Statistics": "Statistics",
    "Preference": "Preference",
    "UseShipStation": "Use ShipStation",
    "ShipStationWarehouse": "ShipStation Warehouse",
    "InvoicePrefix": "Invoice Prefix",
    "NextInvNum": "Next Invoice Number",
    "PacingSlipPrefix": "Packing Slip Prefix",
    "NextPackingSlipNum": "Next Packing Slip Number",
    "EmailUponReceiving": "Receiving Email Notification",
    "EmailUponShipping": "Shipping Email Notification",
    "ShippingChargeModify": "API Shipping Charge Modifier",
    "LogoUri": "Logo URI",
    "AccountingName": "Accounting Company Name",
    "AccountingTerm": "Accounting Payment Term",
    "AccountingDueDate": "Accounting Due Date",
    "OrderNotification": "Order Notification",
    "ShippingEmails": "Shipping Notification Emails",
    "ReceivingEmails": "Receiving Notification Emails",
    "OrderNotificationEmail": "Order Notification Email",
    "WhenOrderCreated": "When Order Created",
    "WhenOrderProcessed": "When Order Processed",
    "DoNotNotify": "No Notification",
    "ClientTaxRate": "Tax Rate",
    "ClientTaxCode": "Tax Code (Billing Report)",

    //Page - General Settings
    "Package": "Package",
    "CourierSSMappingCarrier":"ShipStation Carrier",
    "CourierSSMappingService":"ShipStation Service",
    "CourierSSMappingPackage":"ShipStation Package",
    "ShippoID":"Shippo Courier",
    "ShippoService":"Shippo Service",
    "TaxCode": "Tax Code / Name",

    //Page - Order
    "Reference": "Reference",
    "PO":"Purchase Order #",
    "Shipment": "Shipment",
    "WarehouseNote": "Notes To Operations Team",
    "DocumentNote": "Notes On Documents",
    "CannotModifyRelation":"Cannot modify for existing orders",
    "EssentialInfo": "Essential Order Information",
    "OptionalShippingAccount": "Optional Shipping and Accounting Information",
    "OrderLineItems": "Order Line Items",
    "OrderNotes": "Addtional Notes",
    "Quantity": "Quantity",
    "Price": "Price",
    "Stock":"Stock",
    "InventorySelectorNote": "Max 5 results are rendered. Provide more details",
    "AlertCannotEdit":"Only open order can be modified",
    "AlertGoBack":"Go back to order summary",
    "CancelAlert":"Are your sure? You cannot undo this.",
    "BillTo":"Bill To:",
    "ShipTo":"Ship To:",
    "BillFrom": "Bill From:",
    "ShipFrom":"Ship From:",
    "OrderLineTotal": "Line Total",
    "TotalBeforeTax": "Total",
    "TotalTax": "Tax",
    "TotalAfterTax": "Grand Total",
    "Open": "Open",
    "Cancelled": "Cancelled",
    "BO": "BO",
    "HasBO": "Has Back Orders",
    "NoBo":"No Back Orders",
    "EnoughStockTOFulfill": "Stock",
    "EnoughStockTOFulfillFilter": "Have enough inventories to fulfill",
    "Yes": "Yes",
    "No": "No",
    "OrderQty": "Ordered Qty",
    "Processed": "Processed",
    "RemainingQty": "Remaining",
    "Fulfilled": "Fulfilled",
    "ToFulfill": "To Fulfill",
    "ShipmentAdditionalInfo": "Courier & Dates",
    "ShipDate": "Ship Date",
    "CancelDate": "Cancel Date",
    "Comment":"Comment & Note",
    'BasicView': "Basic Order List View",
    "AdvancedView": "Advanced Order List View (Query by BO and Stock)",
    "BasicFilterView": "Basic Filter View (Fast)",
    "AdvancedFilterView": "BO Filter View (Slow)",

    //Page - Shipments
    "In Warehouse": "In Warehouse",
    "Ready": "Ready",
    "Finished": "Finished",
    "Attachments": "Attachments",
    "Comments": "Comments",
    "CompletedDate": "Finish Date",
    "PTNumber": "PT Number",
    "TrackingNumber": "Tracking #",
    "FileComment": "FileComment",
    "InternalNote": "Internal Note",
    "PrintOnDoc": "Print Note on Document",
    "Transit": "Transit (Days):",
    

    //Page - Billing
    //Items
    "ReportInternal": "Internal",
    "AccountingClass": "Class",
    "AccountingTaxCode": "Tax Code",
    "ReportBreakdown": "Detailed Report",
    //Activities
    "ChargeType":"Charge Type",
    "Manual": "Manual",
    "Inbound": "Inbound",
    "Outbound": "Outbound",
    "Month-Begin": "Month Begin",
    "Month-End": "Month End",
    "Week-Begin": "Week Begin",
    "Week-End": "Week End",
    "Daily-Cubic-Storage": "Daily Cubic",
    "Daily-Location-Storage": "Daily Location",
    "Shipping-Charge": "Shipping",
    "DefaultRate": "Default Rate",
    "DefaultQuantity": "Default Qty",
    "MinimumQuantity": "Minimum Required Qty",
    "MinimumRate": "$ Charge if Less than Above",
    //Entries
    "BillingEntry": "Billing Entry",
    "BillDate": "Bill Date",

    //Page - Integration
    "Partner": "Partner",
    "Verified": "Verified",
    "SaveNewInventory": "Create inventory item if not exists in system",
    "PermanentlyDeleteApi": "Permanently delete this partner?",
    "AutoProcess": "Try to create shipment after orders are imported",
    "ManualImport": "Manual Import",
    "StoreTimeZone": "Store Time Zone",

    //Page - License Plate
    "Putaway": "Put Away",
    "Break": "Break Down License",
    "StartNumber": "Start LP",
    "LpCount":"License Count",

    //Page - Serial
    "Lead": "Lead Number",
    "Increment": "Increment",
    "UnitCount": "Units",
    "AvailableToUse":"Availalbe To Assign",

    //Page - Fulfillment
    "outbound": "Shipping",
    "inbound": "Receiving",
    "PrintCount": "Print Count",
    "PT": "Picking Ticket (Generic)",
    "PTC":"Picking Ticket (Controlled)",
    "MB": "Mobile Barcode",
    "PS": "Packing Slip",
    "CPS": "Packing Slip by Carton",
    "INV": "Invoice",
    "SCL":"Shipping Carton Labels",
    "CI": "Customized CI",
    "SR": "Shipment Report",
    "LPL": "LP Labels",
    "ShipmentVoidAlert": "Permanently void this shipment?",
    "ShipmentVoidFinishedAlert": "Permannently delete this finished shipment? All billing entries will be deleted. If you are deleting an inbound order, please note it may cause negative inventory quantities if you have shipped items from the order",
    "MobileLocked": "Mobile Locked",
    "Billed": "Billed",
    "LotNunber": "Lot #",
    "ExistingLot": "Existing Lot",
    "ExpireDate": "Expire Date",
    "SerialLead": "Serial Lead",
    "Waybill": "Waybill",
    "Packed": "Packed",
    "Repack": "Edit Pack",
    "bill": "Bill",
    "ShippingCharge": "Shipping Charge",
    "CartonCount": "Carton Count",
    "Qty": "Qty",
    //Page - Pack
    "AddCarton": "Add Carton",
    "PackAll": "Pack All (UOM)",
    "PredefinedPackage": "Available Packages",
    "QuantityShipped": "Quantity Shipped",
    "QuantityPacked": "Quantity In Carton (use 0 to delete)",
    "SelectInventoryToPack": "Select item to pack in this carton",
    "SystemGenerated": "Auto Generate",

    //Page - Report
    "ReportName": "Select A Report",
    "StartDate": "Start Date",
    "EndDate": "End Date",
    "Format": "Format",
    "QuickAccessPeriod": "Quick Access Period",

    //Page - Profile
    "MyProfile": "My Profile",
    "UpdateMyPassword": "Update My Password",
    "PasswordConfirm": "Confirm Password",
    "APIToken": "API Token",

    //Server Errors
    "UnableToAccess": "Unable to access: your role does not have access to this resource",
    "AuthenticationFailed": "Authentication Failed: This could be caused by incorrect credentials or someone else is logged into your account",
    "UnexpectedSubmissionFailure": "Unexpected submission failure. Please contact support for help",
    "AnUnexpectedErrorHasOccurred": "An unexpected error has occurred, please contact support or try again later",

    //Server Error Codes (400, caused due to validation or incorrect user input) (as of Feb 26, 1.3.1)
    //General
    10001: "Cannot save your comment",
    10002: "Duplicated location",
    10003: "Lost connection to storage server",    
    10004: "Cannot delete file",
    10005: "Invalid filed uploaded",
    10006: "Invalid image file",
    10007: "Password is required",
    10008: "Email already exists",
    10009: "User role does not exist",
    11001: "Invalid count of sheet(s)",
    11002: "Row is missing IID o SKU field",

    //Inventory
    20001: "Inactive item or item not found",
    20002: "Cannot adjust item due to serial control. Use inbound & outbound orders to adjust serial controlled items",
    20003: "Quantity must be a negative integer in quarantine adjustment.",
    //Order
    30001: "Duplicated SKU",
    30002: "Invalid item identifier",
    30003: "Duplicated SKU or inventory ID, or kit without components",
    30004: "Incorret items found in order lines",
    30005: "Incorrect relation",
    30006: "Incorrect courier supplied",
    30007: "Order is not open",

    //Shipment
    40001: "Shipment not found",
    40002: "Insufficient quantity",
    40003: "Courier not found",
    40004: "Quantity cannot be greater than ordered",
    40005: "Invalid shipment",
    40006: "Invalid order type",
    40007: "Cannot over fulfill line(s)",
    40008: "Incorrect quantity",
    40009: "Quick fulfill is disabled",
    40010: "Cannot void a finished shipment",
    40011: "Not an In Warehouse shipment",
    40012: "Quantity cannot be greater than ordered",

    //Billing
    50001: "Name already exists",
    50002: "Incorrect activity supplied",
    50003: "Duplicated billing item",
    50004: "Item is not associated to activity",
    50005: "Shipment is already billed",

    //Operation
    60001: "Cannot delete used license plate(s)",
    60002: "License number is duplicated in batch create",
    60003: "Incorrect serials count",
    
    //Integration
    90001: "Integration not supported",
    90002: "Shopify order not found",
    90003: "Integration rejected by partner",
    90004: "TradeGecko order not found",
    90005: "Magento order not found",
    90006: "ShipStation account needed",
    90007: "ShipStation order not found",
    90008: "Invalid Zalora credential",
    90009: "Invalid Zalora credential",
    90010: "Zalora order not found",
    90011: "Duplicated Zalora order",
    90012: "Imported order has no line items",
    90013: "Cannot update Zalora status",
    
    
    //Others
    "500Error": "An unexpected error has occurred on the server",
    "500ErrorRef": "Please reference this code when you contact support: ",
    "504Error": "Server is not responding, please try again",

    //Buttons & Action
    "LogIn": "Log In",
    "Save": "Save",
    "Upload": "Upload",
    "Edit": "Edit",
    "Update": "Update",
    "Confirm":"Confirm",
    "Cancel": "Cancel",
    "GoBack": "Go Back",
    "Create": "Create",
    "Actions": "Actions",
    "Clone": "Clone",
    "AdvancedFilter": "Advanced Filter & Conditions",
    "Filter": "Filter",
    "AddFilter": "Add Filter",
    "RunFilter": "Run Filter",
    "Add": "Add",
    "Conditions": "Conditions",
    "Search": "Search",
    "List": "List",
    "Search by": "Search by",
    "Delete": "Delete",
    "Form": "Form",
    "LoadResource": "Load Resource",
    "ClickToDelete": "Click to permanently delete",
    "Cancel BO": "Cancel BO",
    "Batch": "Batch Process",
    "BatchCreate": "Batch Create",
    "Generate": "Generate",
    "Print": "Print ",
    "Void": "Void",

    //Form Input Validation Message
    "DataSubmissionError(s)": "Data Submission Error(s)",
    "EmailAddressIsinvalid": "Email address is invalid",
    "PasswordIsRequired": "Password is required",
    "CompanyNameIsRequired": "Company name is required",
    "UserNameIsRequired": "User name is required",
    "UOMIsRequired": "UOM is required",
    "OnlyOneBaseUOMIsAllowed": "Only 1 Base UOM is allowed",
    "UOMNotNumber":"Lenght, width, height and weight should be numbers",
    "NumberOfBaseUnitShouldBeOne": "# of Base Unit should be 1",
    "SKUIsRequired": "SKU is required",
    "NameIsRequired": "Name is required",
    "PleaseSelectARole": "Please select a role",
    "PleaseSelectAClient": "Please select a client",
    "PleaseMapToAShipStationWarehouse": "Please map to a ShipStation warehouse",
    "InvliadNumberFormat": "Invliad Number Format",
    "AtLeastOneEmailIsRequired": "At least one email is required",
    "LogoURLIsInvalid": "Logo URL is invalid",
    "CourierNameIsRequired": "Courier name is required",
    "TaxNameIsRequired": "Tax name is required",
    "TaxRateIsNotAValidNumber": "Tax rate is not a valid number (use 0.13 for 13%)",
    "TermNameIsRequired": "Term name is required",
    "UomNameIsRequired": "Unit of measure name is required",
    "PackageNameIsRequired": "Package name is required",
    "RelationIsRequired": "Relation is required",
    "ShipingAddressIsRequired": "Shiping address is required",
    "BillingAddressIsRequired": "Billing address is required",
    "ReferenceIsRequired": "Reference is required",
    "CourierIsRequired": "Courier is required",
    "AtLeastOneLineItemIsRequired": "At least one line item is required",
    "InvalidLineItem": "Invalid line item: Line # ",
    "InvalidLineQuantity": "Invalid line item: Line # ",
    "InvalidLinePrice": "Invalid line item: Line # ",
    "CannotShipMoreThanRemaining":"Cannot fulfill more than available to fulfill - Line # ",
    "ActivityNameIsRequired": "Activity name is required",
    "ClientIsRequired": "Client is required",
    "ActivityIsReuqired": "Activity is required",
    "DateIsReuqired": "Date is reuqired",
    "AtLeastOneItemIsRequired": "At least 1 item is required",
    "PleaseSelectAPartner": "Please select a partner",
    "CredentialFieldsAreEmpty": "Please fill in all credential fields marked by *",
    "LocationIsRequired": "Location is required",
    "LicenseNumberIsRequired": "License number is required",
    "StartLicenseNumberIsRequired": "Start license number is required",
    "NumberOfLicensesIsRequired":"Number of licenses is required",
    "InventoryIsRequired": "Inventory is required",
    "AtLeastOneSerialNumberIsRequired": "At least one serial # is required",
    "UnitsMustBeGreaterThan0": "Units must be greater than 0",
    "ResetEmailSent": "Reset email sent. Please check your inbox.",
    "ResetEmailFailed": "Cannot send reset email, please contact support",
    "TwoPasswordAreNotTheSame": "Two passwords are not the same",
    
    //Fulfillment Modal:
    "MissingLineId": "Missing important data, please reopen this modal",
    "InvalidQuantity": "Quantity must be an integer greater than 0",
    "LotRequired": "An existing lot or new lot is required",
    "SerialRequired": "Serial is required. Serials can be captured in Operation => Serial page",
    "QuantityCannotBeMoreThanOrdered": "Quantity cannot be greater than required",
    //Form Fields Labels
    "Email": "Email",
    "Password": "Password",
    //Settings => Company Page
    "CompanyName": "Company Name",
    "AddressType": "Address Type",
    "Address": "Address",
    "City": "City",
    "Postal": "Postal Code",
    "State": "State / Province",
    "StateCode": "State Code",
    "Country": "Country",
    "CountryCode": "Country Code",
    "Phone": "Phone Number",
    "BrandingName": "Guest Page Branding: Header",
    "BrandingSlogan": "Guest Page Branding: Slogan",
    "Website": "Company Website",
    "SSPrefix": "ShipStation Order Prefix",
    "HomeCountryCode": "Home Country Code",
    "Measure": "Measuring System",
    "Logo": "Company Logo",
    "BrandingColor": "Guest Page Branding: Right Side Screen Color",
    "HeaderColor": "Header Color: User Platform Header Color",
    "AuthBtnColor": "Guest Login Button Color",
    "PrimaryBtnColor": "Primary Button Color",
    "SecondaryBtnColor": "Secondary Button Color",
    "DeleteBtnColor": "Delete Button Color",
    "BillingAddress": "Billing Address",
    "ShippingAddress": "Shipping Address",
    "BothAddress": "Billing and Shipping",
    "MyCompany Settings": "My Company Settings",
    "FulfillEnterLp":"Search LP # and Press enter",
    "FulfillLpSelected":"Currently Selected LP #: ",
    "FulfillQuantity":"Quantity To Fulfill",
    "FulfillQuarantine":"Quarantined Entry",

    //Global
    "ReadSupport":"Read Support Article On This Issue",
    "ErrorRef": "Server Message:",

    //Side Menu (When Translate, please do not use long names)
    "Dashboard": "Dashboard",
    "Relation": "Relation",
    "Customer": "Customer",
    "Vendor": "Vendor",
    "Inventory":"Inventory",
    "Order": "Order",
    "Customer Order": "Customer Order",
    "Vendor Order":"Vendor Order",
    "Customer Return":"Customer Return",
    "Vendor Return": "Vendor Return",
    "Operation":"Operation",
    "LicensePlate":"License Plate",
    "Receiving": "Receiving",
    "Shipping": "Shipping",
    "SerialNumber":"Serial Number",
    "BillOfLading":"Bill Of Lading",
    "Billing":"Billing",
    "Item":"Item",
    "Activity":"Activity",
    "Entry":"Entry",
    "Report":"Report",
    "Setting":"Setting",
    "Company":"Company",
    "Client":"Client",
    "User":"User",
    "Configuration":"Configuration",
    "Integration":"Integration",

    //Others
    //Operators:
    "Equal": "Equal",
    "Greater": "Greater",
    "Less": "Less",
    "GreatEqual": "Greater Or Equal",
    "LessEqual":"Less Or Equal",
    "Include": "Include",
    "AND": "AND",
    "OR": "OR",
    //Date
    "CreatedAt":"Created On",
    "UpdatedAt":"Updated On",

    //Roles
    //Role Header
    "update_company_info":"Update Company Setting & Preference",
    "view_client_list": "View List of Clients",
    "view_client_model": "View Client Detail",
    "create_client_model": "Create New Client",
    "update_client_model": "Update Existing Client",
    "view_user_list": "View List of Users",
    "view_user_model": "View User Detail",
    "create_user_model": "Create New User",
    "update_user_model": "Update Existing User",
    "adjust_inventory": "Adjust Inventory Quantity",
    "create_role_model": "Create New Role",
    "update_role_model": "Update Existing Role",
    "create_general": "Create UOM, Taxes and Packages",
    "update_general": "Update UOM, Taxes and Packages",
    "delete_general": "Delete UOM, Taxes and Packages",
    "create_courier": "Create New Courier",
    "update_courier": "Update Existing Courier",
    "view_api_list": "View API Credential List",
    "view_api_model": "View API Detail",
    "create_api_model": "Create New API Connection",
    "update_api_model": "Update Existing API Connection",
    "delete_api_model": "Delete API Connection",
    "view_location_list": "View Warehouse Locations",
    "create_location_model": "Create New Location",
    "update_location_model": "Update Existing Location",
    "view_license_list": "View List of Licenses",
    "create_license_model": "Create New License",
    "update_license_model": "Update Existing License",
    "breakdown_license_model": "Breakdown License",
    "delete_license_model": "Delete License",
    "put_license_to_location": "Putaway - License & Location",
    "view_serial_list": "View Serial Number List",
    "create_serial_model": "Create New Serial Lead",
    "update_serial_model": "Update Serial Lead",
    "view_serial_model": "View Serial Detail",
    "delete_serial_model": "Delete Serial",
    "view_shipments_list": "View Shipment List",
    "view_shipment_model": "View Shipment Detail",
    "update_shipment_tracking": "Update Shipment Tracking",
    "void_shipment": "Void Existing Shipment",
    "fulfill_shipment": "Fulfill Shipment",
    "delete_fulfillment_line": "Delete & Modify Fulfillment Lines",
    "view_activity_list": "View List of Bill Activity",
    "view_activity_model": "View Activit Detail",
    "create_activity_model": "Create New Billing Activity",
    "update_activity_model": "Update Existing Billing Activity",
    "update_billing_items": "Add or Remove Associated Billing Items",
    "view_billing_item_list": "View List of Billing Item",
    "create_billing_item_model": "Create New Billing Item",
    "update_billing_item_model": "Update Existing Billing Item",
    "run_billing_report": "Run 3PL Billing Report",
    "view_entry_list": "View List of Billing Entries",
    "create_entry_model": "Manually Create New Bililng Entries",
    "update_entry_model": "Manually Update Existing Billing Entries",
    "move_between_license": "Move Items Between License Plates",
    "view_role_list": "View List of Roles",
    "update_shipment_line": "Update Shipment & Order Line Quantity",
    "role_pack": "Pack Shipments",
    "trace_history": "Trace History Records",

    "MessageCourierPage": "Couriers can be client specific. To view or modify couriers for a particular client, navigate to the client database first.",
    "MessageTaxRatePage": "Tax rates can be created here for your 3PL clients conveniences. These tax rates can be used for generating end customer invoices",
    "MessagPaymentTermPage": "Payment terms can be created here for your 3PL clients conveniences. These terms can be displayed on the end customer invoices",
    "MessagePackagePage": "You can predefine your packages here for fast packing operation process",
    "MessageOrderCloned":"Order cloned successfully.",
    "MessageAddItemToActivity":"Do not leave rate equal to zero (0) unless you no longer want this item to be populated under this activity. Using rate of 0 is the same as deleting this item from the activity. Users can always change the rate and quantity on the fly.",
    "MessageUpdateBillingEntry": "To delete this entry, set quantity to 0.",
    "MessageManualImportAPI":"Manual import ignores duplication check. You can import orders with any status and timestamp. Please use with caution.",
    "MessageDownloadSchema": "Download an import schema",
    "MessageImportSuccess": "Success Count:",
    "MessageImportErrorList":"Errors:",
    "MessageExcelRow": "Row",
    "MessageSerialClient": "You need to navigate to the correct client database to create new serial numbers",
    "MessageSelectZaloraCourier": "Select a Zalora Courier",
    "MessageSelectShippingMethod": "Select a shipping method",
    "MessageZaloraDropship": "DropShip: send out the package",
    "MessageZaloraPickup": "Pickup: Shop pick up",
    "MessageZaloraSendToWarehouse": "SendToWarehouse: send to warehouse",
    "MessageZaloraFulfillUseMyOwn": "Use my own courier",
    "MessageZaloraFulfilled": "Fulfilled in Zalora, get labels",
    "MessageZaloraShippingInv": "Shipping Invoice",
    "MessageZaloraShippingLabel": "Shipping Label",
    "MessageZaloraShippingCarrierManifest": "Carrier Manifest",
    "MessageShippoPackShipment": "Shipment must be packed before using Shippo.",
    "UserCreatedMessage": "An email is on its way to the new user. Below is the new user account. You can also take note of the following credential (this will only be displayed here once).",
    //Missing:
    "FromLp": "From LP",
    "ToLp": "To LP",
    "AdjustmentRef": "Adjustment Reference",
    "QuantityChange": "Quantity Change",

    //Webhook
    "HookTrigger": "Action",
    "HookType": "Type",
    "HookMethod": "HTTP Method",
    "HookEndpoint": "Endpoint",
    "HookHashKey": "Security Key",
    "HookConfigUserName": "FTP Username",
    "HookConfigPassword": "FTP Password",
    "HookConfigPath": "File Path"
};
