import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {Table, List} from 'semantic-ui-react';

const TraceComponentTable = ({traces}) => (
    <div>
        <Table compact>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>
                        ID
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        User
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        Subject
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        Detail
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {traces.map(d => {
                    return <Table.Row key={d.id}>
                        <Table.Cell>
                            {d.id}
                        </Table.Cell>
                        <Table.Cell>
                            {d.user ? d.user.name : 'System'}
                        </Table.Cell>
                        <Table.Cell>
                            {d.subject} <br />
                            {d.created_at}
                        </Table.Cell>
                        <Table.Cell>
                            {_.isArray(d.array_log) && !_.isEmpty(d.array_log) ? <List>
                                {d.array_log.map((item, index) => {
                                    return <List.Item key={index}>
                                        {_.isObject(item) ? `Set ${item.field} To ${item.newValue}` : JSON.stringify(item)}
                                    </List.Item>
                                })}
                            </List> : null}
                        </Table.Cell>
                    </Table.Row>
                })}
            </Table.Body>
        </Table>
    </div>
)

TraceComponentTable.propTypes = {
    traces: PropTypes.array.isRequired
};


export default TraceComponentTable