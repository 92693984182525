import React, { Component } from 'react'
import _ from 'lodash';
import PropTypes from 'prop-types'
import {api} from '../../../api'
import {Search, Label} from 'semantic-ui-react'

class BillingItemSelector extends Component {
    constructor() {
        super();
        this.state ={
            isLoading: false, 
            results: [], 
            value: ''
        }
        this.searchItem = _.debounce( (query) => {this.searchItemByValue(query)}, 750);
    }
    
    handleResultSelect = (e, { result }) => {
        const { item } = result;
        this.setState({ value: result.item.name});
        this.props.onSelected(item);
    }

    searchItemByValue = (value) => {
        if (value.length <= 2) {
            return;
        }
        api.billing.items.selector({
            search: value,
            excepts: this.props.exceptIds
        }).then( options => {
            this.setState({
                isLoading: false,
                results: options
            })
        }).catch(err => {
            this.setState({
                isLoading: false
            })
        })
    }

    handleSearchChange = (e, {value}) => {
        this.setState({
            value: value,
            isLoading: true
        }, () => {
            this.searchItem(value);
        });
    }

    _resultRender = (item) => {
        return <div>
            <Label pointing='right' color='violet' size='tiny'>{item.title}</Label>
            <Label size='tiny'>{item.uom}</Label>
        </div>
    }

    render() {
        const { isLoading, value, results } = this.state
        return (
            <Search
                resultRenderer={this._resultRender}
                className="lara-full-width"
                fluid={true}
                loading={isLoading}
                size='small'
                onResultSelect={this.handleResultSelect}
                onSearchChange={this.handleSearchChange}
                results={results}
                value={value}
            />
        )
    }
}

BillingItemSelector.propTypes = {
    onSelected: PropTypes.func.isRequired,
    exceptIds: PropTypes.array.isRequired
};

export default BillingItemSelector