import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";
import { Form, Image, Segment, Header, Button } from "semantic-ui-react";
import FormError from '../../includes/messages/422';
import CountrySelector from '../../includes/selectors/CountrySelector';
import FileUploadButton from '../../includes/partials/FileUploadButton';
import AlertComponent from '../../includes/messages/alert';
import Lara from '../../../lara';
import validator from 'validator';
import SegmentRibbon from '../../includes/partials/SegmentRibbon';
import {
    updatePreferenceInStore,
    updatePreferenceOnServer
} from '../../../actions/setting';
import PrimaryButton from '../../includes/buttons/PrimaryButton';
import PrimaryNaviButton from '../../includes/buttons/PrimaryNaviButton';

const colorOptions = [
    {value: 'red', text: 'Red'},
    {value: 'orange', text: 'Orange'},
    {value: 'yellow', text: 'Yellow'},
    {value: 'olive', text: 'Olive'},
    {value: 'green', text: 'Green'},
    {value: 'teal', text: 'Teal'},
    {value: 'blue', text: 'Blue'},
    {value: 'violet', text: 'Violet'},
    {value: 'purple', text: 'Purple'},
    {value: 'brown', text: 'Brown'},
    {value: 'grey', text: 'Grey'},
    {value: 'black', text: 'Black'},
    {value: 'facebook', text: 'Facebook'},
    {value: 'instagram', text: 'Instagram'},
    {value: 'linkedin', text: 'Linkedin'},
    {value: 'twitter', text: 'Twitter'},
    {value: 'youtube', text: 'Youtube'}
];

class CompanySettingForm extends Component {
    constructor() {
        super();
        this.state = {
            errors: [],
            loading: false
        };
    }

    onFormSubmit = () => {
        const errors = this.validateInput();
        if (_.isEmpty(errors)) {
            this.setState({
                loading: true
            });
            this.props.updatePreferenceOnServer(this.props.model).then(res => {
                this.setState({
                    errors: [],
                    loading: false
                });
                Lara.alertSuccess('Company setting updated');
            }).catch(err => {
                const errors = Lara.axiosError(err, this.props.t);
                if (errors.http_code === 422) {
                    this.setState({
                        errors: errors.message,
                        loading: false
                    });
                } else {
                    this.setState({
                        loading: false
                    });
                    Lara.axiosAlert(<AlertComponent 
                        support={errors.support}
                        message={errors.message}
                        reference={errors.reference}
                        t={this.props.t}
                    />);
                }
            });
        } else {
            this.setState({
                errors: errors
            });
        }
    };

    validateInput = () => {
        const { name, home_address, bol_base, branding_name, branding,
            logo
        } = this.props.model;
        const errors = [];
        if (!name) {
            errors.push("Please enter your company name");
        }
        if (home_address) {
            if(!validator.isURL(home_address)) {
                errors.push("Company website is not a valid URL");
            }
        }
        if (parseInt(bol_base,10) <= 0) {
            errors.push("Starting BOL number must be a positive integer");
        }
        if (!branding_name) {
            errors.push("Please fill in guest page header");
        }
        if (!branding) {
            errors.push("Please fill in guest page slogan");
        }
        if (logo) {
            if(!validator.isURL(logo)) {
                errors.push("Invalid logo URL");
            }
        }
        return errors;
    };
    
    render() {
        const {model, t, updatePreferenceInStore} = this.props
        return (
            <Segment>
                <SegmentRibbon label={t("My Company Settings")} Buttons={
                        () => {return (<React.Fragment>
                            <PrimaryButton
                                size='mini'
                                float='right'
                                label={t('Save')}
                                onClick={() => {
                                    this.onFormSubmit()
                                }}
                                loading={this.state.loading}
                            />
                            <PrimaryNaviButton
                                link='/company'
                                size='mini'
                                float='right'
                                loading={false}
                                label={t('Go Back')}
                            />
                        </React.Fragment>)}
                    }/>
            
                <Form size="mini">
                    <div className="row">
                        <div className="col-md-6 col-sm-6 col-xs-12">
                            {!_.isEmpty(this.state.errors) ? (
                                <FormError errors={this.state.errors} />
                            ) : null}
                            <Header as='h3'>
                                Company Profile
                            </Header>
                            <Form.Input 
                                fluid 
                                label={t("CompanyName")}
                                name="name"
                                value={model.name ? model.name : ""} 
                                onChange={(e) => { updatePreferenceInStore(e.target.name, e.target.value) }}
                            />
                            <Form.Input 
                                fluid 
                                label={t("Address")}
                                name="address"
                                value={model.address ? model.address : ""} 
                                onChange={(e) => { updatePreferenceInStore(e.target.name, e.target.value) }}
                            />
                            <Form.Input 
                                fluid 
                                label={t("City")}
                                name="city"
                                value={model.city ? model.city : ""} 
                                onChange={(e) => { updatePreferenceInStore(e.target.name, e.target.value) }}
                            />
                            <Form.Input 
                                fluid 
                                label={t("Postal")}
                                name="postal"
                                value={model.postal ? model.postal : ""} 
                                onChange={(e) => { updatePreferenceInStore(e.target.name, e.target.value) }}
                            />
                            <Form.Input 
                                fluid 
                                label={t("State")}
                                name="state"
                                value={model.state ? model.state : ""} 
                                onChange={(e) => { updatePreferenceInStore(e.target.name, e.target.value) }}
                            />
                            <CountrySelector
                                selected={model.country}
                                countrySelected={(value) => {updatePreferenceInStore('country', value)}}
                                label={t('Country')}
                            />
                            <Form.Input 
                                fluid 
                                label={t("Phone")}
                                name="phone"
                                value={model.phone ? model.phone : ""} 
                                onChange={(e) => { updatePreferenceInStore(e.target.name, e.target.value) }}
                            />
                            <Form.Select
                                fluid
                                options={[{value:'metric',text:'Metric (Kg, CM)'},{value: 'imperial', text: 'Imperial (Lb, Inch)'}]}
                                name="measure"
                                label={t("Measure")}
                                value={model.measure}
                                onChange={(e, {value}) => { updatePreferenceInStore('measure', value) }}
                            />
                            <Form.Checkbox
                                label={t('Quick Fulfill')}
                                name="quick_fulfill"
                                onChange={(e,data) => { updatePreferenceInStore('quick_fulfill', data.checked) }}
                                checked={model.quick_fulfill ? true : false}
                            />
                            <CountrySelector
                                selected={model.home_country_code}
                                countrySelected={(value) => {updatePreferenceInStore('home_country_code', value)}}
                                label={t('HomeCountryCode')}
                            />
                            <Form.Input 
                                fluid 
                                label={t("SSPrefix")}
                                name="ss_prefix"
                                value={model.ss_prefix ? model.ss_prefix : ""} 
                                onChange={(e) => { updatePreferenceInStore(e.target.name, e.target.value) }}
                            />
                        </div>
                        <div className="col-md-6 col-sm-6 col-xs-12">
                            <Header as='h3'>
                                Company Branding
                            </Header>
                            <Form.Input
                                fluid 
                                label={<span className="lara-ui-label" style={{
                                    color: model.t_header_bg
                                }}><strong>{t("HeaderColor")}</strong></span>}
                                name="t_header_bg"
                                value={model.t_header_bg ? model.t_header_bg : ""} 
                                onChange={(e) => { updatePreferenceInStore(e.target.name, e.target.value) }}
                            />
                            <Form.Select
                                options={colorOptions}
                                label={t("AuthBtnColor")}
                                name="t_primary_auth"
                                value={model.t_primary_auth ? model.t_primary_auth : ""}
                                onChange={(e, {value}) => {updatePreferenceInStore('t_primary_auth', value)}}
                            />
                            <Form.Select
                                options={colorOptions}
                                label={t("PrimaryBtnColor")}
                                name="t_primary_btn"
                                value={model.t_primary_btn ? model.t_primary_btn : ""}
                                onChange={(e, {value}) => {updatePreferenceInStore('t_primary_btn', value)}}
                            />
                            <Form.Select
                                options={colorOptions}
                                label={t("SecondaryBtnColor")}
                                name="t_secondary_btn"
                                value={model.t_secondary_btn ? model.t_secondary_btn : ""}
                                onChange={(e, {value}) => {updatePreferenceInStore('t_secondary_btn', value)}}
                            />
                            <Form.Select
                                options={colorOptions}
                                label={t("DeleteBtnColor")}
                                name="t_delete_btn"
                                value={model.t_delete_btn ? model.t_delete_btn : ""}
                                onChange={(e, {value}) => {updatePreferenceInStore('t_delete_btn', value)}}
                            />
                            <Form.Input 
                                fluid 
                                label={t("BrandingName")}
                                name="branding_name"
                                value={model.branding_name ? model.branding_name : ""} 
                                onChange={(e) => { updatePreferenceInStore(e.target.name, e.target.value) }}
                            />
                            <Form.Input 
                                fluid 
                                label={t("BrandingSlogan")}
                                name="branding"
                                value={model.branding ? model.branding : ""} 
                                onChange={(e) => { updatePreferenceInStore(e.target.name, e.target.value) }}
                            />
                            <Form.Input
                                fluid 
                                label={<span className="lara-ui-label" style={{
                                    color: model.login_bg_color
                                }}><strong>{t("BrandingColor")}</strong></span>}
                                name="login_bg_color"
                                value={model.login_bg_color ? model.login_bg_color : ""} 
                                onChange={(e) => { updatePreferenceInStore(e.target.name, e.target.value) }}
                            />
                            <Form.Input 
                                fluid 
                                label={t("Website")}
                                name="home_address"
                                value={model.home_address ? model.home_address : ""} 
                                onChange={(e) => { updatePreferenceInStore(e.target.name, e.target.value) }}
                            />
                            <div className="row">
                                <div className="col-md-12 col-xs-12 lara-full-space">
                                    <Button size='mini' type='button' color={model.t_primary_auth}>
                                        Login Button
                                    </Button>
                                    <Button size='mini' type='button' color={model.t_primary_btn}>
                                        Primary Button
                                    </Button>
                                    <Button size='mini' type='button' color={model.t_secondary_btn}>
                                        Secondary Button
                                    </Button>
                                    <Button size='mini' type='button' color={model.t_delete_btn}>
                                        Delete Button
                                    </Button>
                                </div>    
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-xs-12 lara-full-space">
                                    <Image src={model.logo} size='small' />
                                    <FileUploadButton
                                        name={t('Upload')+' '+t('Logo')}
                                        type='logo'
                                        filable_id={1}
                                        filable_type="NA"
                                        onSuccess={(data) => { updatePreferenceInStore('logo', data.data) }}
                                        t={this.props.t}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
            </Segment>
        );
    }
}

CompanySettingForm.propTypes = {
    model: PropTypes.object.isRequired,
    updatePreferenceInStore: PropTypes.func.isRequired,
    updatePreferenceOnServer: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        model: state.system.preference
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            updatePreferenceInStore,
            updatePreferenceOnServer
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CompanySettingForm);
