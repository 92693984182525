import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {Table, Input, Label} from 'semantic-ui-react';

class CreatePartialShipmentLineTable extends React.PureComponent {
    //access router
    //this.props.history.push('/dashboard')
    
    render() {
        const {t, lines, onLineQtyChange} = this.props;
        return (
            <Table compact>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell width={3}>
                            {t('SKU')}
                        </Table.HeaderCell>
                        <Table.HeaderCell width={7}>
                            {t('Name')}
                        </Table.HeaderCell>
                        <Table.HeaderCell width={2}>
                            {t('Stock')}
                        </Table.HeaderCell>
                        <Table.HeaderCell width={2}>
                            {t('Remaining')}
                        </Table.HeaderCell>
                        <Table.HeaderCell width={2}>
                            {t('ToFulfill')}
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {lines.map((line, index) => {
                        const {meta} = line;
                        return (
                            <Table.Row key={line.order_line_id}>
                                <Table.Cell>
                                    {meta.sku}<br />
                                    {!meta.kit_id ? <Label size='mini' color='violet'>Item</Label> : 
                                        _.isEmpty(meta.kit_property) ? <Label size='mini' color='purple'>Kit</Label> :
                                        <Label size='mini' color='purple'>{meta.kit_property.sku} @ {meta.kit_property.quantity}</Label>
                                    }
                                </Table.Cell>
                                <Table.Cell>
                                    {meta.name}
                                </Table.Cell>
                                <Table.Cell>
                                    {meta.line_status.available_inventory}
                                </Table.Cell>
                                <Table.Cell>
                                    {line.remaining}
                                </Table.Cell>
                                <Table.Cell>
                                    <Input type='number' size='mini'
                                        value={_.toString(line.quantity)}
                                        onChange={(e, {value}) => {
                                            onLineQtyChange(index, value)
                                        }}
                                    />
                                </Table.Cell>
                            </Table.Row>
                        )
                    })}
                </Table.Body>
            </Table>
        )
    }
}

CreatePartialShipmentLineTable.propTypes = {
    lines: PropTypes.array.isRequired,
    onLineQtyChange: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};


export default CreatePartialShipmentLineTable