import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";
import {withI18n} from 'react-i18next';
import { Form, Segment, Header } from "semantic-ui-react";
import FormError from '../includes/messages/422';
import PageBreadCrub from '../includes/partials/PageBreadCrub';
import AlertComponent from '../includes/messages/alert';
import Lara from '../../lara';
import validator from 'validator';
import SegmentRibbon from '../includes/partials/SegmentRibbon';
import CountrySelector from '../includes/selectors/CountrySelector';
import ShipStationWarehouseSelector from '../includes/selectors/ShipStationWarehouseSelector';
import ClientEmailList from '../includes/partials/ClientEmailList';
import {
    fetchById,
    updateModelInStore,
    updateModelOnServer,
    createNewModelInStore,
    addEmailToClientDetail,
    removeEmailFromClientDetail
} from '../../actions/client';
import {
    loadShipStationWarehouses
} from '../../actions/utils';
import PrimaryButton from "../includes/buttons/PrimaryButton";

class ClientEditPage extends Component {
    constructor() {
        super();
        this.state = {
            breadcrub: [
                {isLink:false, to:null, tag: '', active: true, key: 1}
            ],
            errors: [],
            loading: false
        };
    }

    componentDidMount() {
        const {match, t, model, warehouses} = this.props;
        const {id} = match.params;
        if (id === 'new') {
            this.props.createNewModelInStore();
            this.setState({
                breadcrub: this.getBreadCrub(t('Create'), t('Create'), null)
            });
        } else {
            if (_.toString(id) !== _.toString(model.id)) {
                this.setState({
                    loading: true
                });
                this.props.fetchById(id).then(res => {
                    this.setState({
                        loading: false,
                        breadcrub: this.getBreadCrub(res.company,
                            t('Edit'), `/clients/${res.id}/action/view`)
                    })
                }).catch(err => {
                    const errors = Lara.axiosError(err, this.props.t);
                    if (errors.http_code === 422) {
                        this.setState({
                            errors: errors.message,
                            loading: false
                        });
                    } else {
                        this.setState({
                            loading: false
                        });
                        Lara.axiosAlert(<AlertComponent
                            support={errors.support}
                            message={errors.message}
                            reference={errors.reference}
                            t={this.props.t}
                        />);
                    }
                    this.setState({loading: false});
                })
            } else {
                this.setState({
                    breadcrub: this.getBreadCrub(model.company, t('Edit'),
                        `/clients/${model.id}/action/view`)
                })
            }
        }
        if (_.isEmpty(warehouses)) {
            this.props.loadShipStationWarehouses();
        }
    }

    getBreadCrub = (name, action, viewPageLink) => {
        return [
            {isLink:false, to:null, tag: 'Client', active: false, key: 1},
            {isLink:viewPageLink ? true : false, to:viewPageLink, tag: _.upperFirst(name), active: false, key: 2},
            {isLink:false, to:null, tag: action, active: true, key: 3}
        ]
    }

    onFormSubmit = () => {
        const errors = this.validateInput();
        if (_.isEmpty(errors)) {
            this.setState({
                loading: true
            });
            this.props.updateModelOnServer(this.props.model).then(res => {
                this.setState({
                    errors: [],
                    loading: false
                });
                this.props.history.push(`/clients/${res}/action/view`);
            }).catch(err => {
                const errors = Lara.axiosError(err, this.props.t);
                if (errors.http_code === 422) {
                    this.setState({
                        errors: errors.message,
                        loading: false
                    });
                } else {
                    this.setState({
                        loading: false
                    });
                    Lara.axiosAlert(<AlertComponent
                        support={errors.support}
                        message={errors.message}
                        reference={errors.reference}
                        t={this.props.t}
                    />);
                }
            });
        } else {
            this.setState({
                errors: errors
            });
        }
    };

    validateInput = () => {
        const {company, email, detail} = this.props.model;
        const errors = [];
        if (!company) {
            errors.push(this.props.t('CompanyNameIsRequired'));
        }
        if (email) {
            if (!validator.isEmail(email)) {
                errors.push(this.props.t('EmailAddressIsinvalid'));
            }
        }
        if (detail.use_ship_station) {
            if (!detail.ss_warehouse) {
                errors.push(this.props.t('PleaseMapToAShipStationWarehouse'));
            }
        }
        if (detail.next_inv_num) {
            if (parseInt(detail.next_inv_num, 10) <= 0) {
                errors.push(this.props.t('InvliadNumberFormat'));
            }
        }
        if (detail.next_picking_num) {
            if (parseInt(detail.next_picking_num, 10) <= 0) {
                errors.push(this.props.t('InvliadNumberFormat'));
            }
        }
        if (detail.email_upon_receiving) {
            if (detail.receiving_notification_emails.length === 0) {
                errors.push(this.props.t('AtLeastOneEmailIsRequired'));
            }
        }
        if (detail.email_upon_shipping) {
            if (detail.shipping_notification_emails.length === 0) {
                errors.push(this.props.t('AtLeastOneEmailIsRequired'));
            }
        }
        if (detail.logo_uri) {
            if (!validator.isURL(detail.logo_uri)) {
                errors.push(this.props.t('LogoURLIsInvalid'));
            }
        }
        if (detail.order_notification !== 'None') {
            if (!validator.isEmail(detail.order_notification_email)) {
                errors.push(this.props.t('EmailAddressIsinvalid'));
            }
        }

        return errors;
    };

    render() {
        const {model, t, updateModelInStore} = this.props
        return (
            <div>
                {/* Breadcrub Row */}
                <div className="row">
                    <div className="col-md-12 lara-breadcrub">
                        <PageBreadCrub lists={this.state.breadcrub} t={t}/>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <Segment>
                            <SegmentRibbon label={model.company ? model.company : t('Form')} Buttons={
                                    () => {return (<React.Fragment>
                                        <PrimaryButton
                                            size='mini'
                                            float='right'
                                            label={t('Save')}
                                            onClick={() => {
                                                this.onFormSubmit()
                                            }}
                                            loading={this.state.loading}
                                        />
                                    </React.Fragment>)}
                                }/>

                            <Form size="mini">
                                {!_.isEmpty(this.state.errors) ? (
                                    <FormError errors={this.state.errors} />
                                ) : null}
                                <Header as='h5'>{t('Client')}</Header>
                                <Form.Group widths='equal'>
                                    <Form.Input
                                        fluid
                                        required={true}
                                        label={t("Company")}
                                        name="company"
                                        value={model.company ? model.company : ""}
                                        onChange={(e) => { updateModelInStore(e.target.name, e.target.value) }}
                                    />
                                    <Form.Input
                                        fluid
                                        label={t("Email")}
                                        name="email"
                                        value={model.email ? model.email : ""}
                                        onChange={(e) => { updateModelInStore(e.target.name, e.target.value) }}
                                    />
                                    <Form.Input
                                        fluid
                                        label={t("Account")}
                                        name="account"
                                        value={model.account ? model.account : ""}
                                        onChange={(e) => { updateModelInStore(e.target.name, e.target.value) }}
                                    />
                                </Form.Group>
                                <Form.Group widths='equal'>
                                    <Form.Input
                                        fluid
                                        label={t("Custom 1")}
                                        name="custom_1"
                                        value={model.custom_1 ? model.custom_1 : ""}
                                        onChange={(e) => { updateModelInStore(e.target.name, e.target.value) }}
                                    />
                                    <Form.Input
                                        fluid
                                        label={t("Custom 2")}
                                        name="custom_2"
                                        value={model.custom_2 ? model.custom_2 : ""}
                                        onChange={(e) => { updateModelInStore(e.target.name, e.target.value) }}
                                    />
                                    <Form.Input
                                        fluid
                                        label={t("Custom 3")}
                                        name="custom_3"
                                        value={model.custom_3 ? model.custom_3 : ""}
                                        onChange={(e) => { updateModelInStore(e.target.name, e.target.value) }}
                                    />
                                    <Form.Input
                                        fluid
                                        label={t("Custom 4")}
                                        name="custom_4"
                                        value={model.custom_4 ? model.custom_4 : ""}
                                        onChange={(e) => { updateModelInStore(e.target.name, e.target.value) }}
                                    />
                                    <Form.Input
                                        fluid
                                        label={t("Custom 5")}
                                        name="custom_5"
                                        value={model.custom_5 ? model.custom_5 : ""}
                                        onChange={(e) => { updateModelInStore(e.target.name, e.target.value) }}
                                    />
                                </Form.Group>
                                <Form.Group widths='equal'>
                                    <Form.Checkbox
                                        label={t('Status')}
                                        name="is_active"
                                        onChange={(e,data) => { updateModelInStore('is_active', data.checked) }}
                                        checked={model.is_active}
                                    />
                                </Form.Group>
                                <Header as='h5'>{t('Client')} {t('Address')}</Header>
                                <Form.Group widths='equal'>
                                    <Form.Input
                                        fluid
                                        label={t("CompanyName")}
                                        name="address_company"
                                        value={model.address.company ? model.address.company : ""}
                                        onChange={(e) => { updateModelInStore('company', e.target.value, 'address') }}
                                    />
                                    <Form.Input
                                        fluid
                                        label={t("Phone")}
                                        name="address_phone"
                                        value={model.address.phone ? model.address.phone : ""}
                                        onChange={(e) => { updateModelInStore('phone', e.target.value, 'address') }}
                                    />
                                    <Form.Input
                                        fluid
                                        label={t("Contact")}
                                        name="contact"
                                        value={model.address.contact ? model.address.contact : ""}
                                        onChange={(e) => { updateModelInStore(e.target.name, e.target.value, 'address') }}
                                    />
                                    <Form.Input
                                        fluid
                                        label={`${t("StreetAddress")} 1`}
                                        name="street1"
                                        value={model.address.street1 ? model.address.street1 : ""}
                                        onChange={(e) => { updateModelInStore(e.target.name, e.target.value, 'address') }}
                                    />
                                </Form.Group>
                                <Form.Group widths='equal'>
                                    <Form.Input
                                        fluid
                                        label={`${t("StreetAddress")} 2`}
                                        name="street2"
                                        value={model.address.street2 ? model.address.street2 : ""}
                                        onChange={(e) => { updateModelInStore(e.target.name, e.target.value, 'address') }}
                                    />
                                    <Form.Input
                                        fluid
                                        label={`${t("StreetAddress")} 3`}
                                        name="street3"
                                        value={model.address.street3 ? model.address.street3 : ""}
                                        onChange={(e) => { updateModelInStore(e.target.name, e.target.value, 'address') }}
                                    />
                                    <Form.Input
                                        fluid
                                        label={t('City')}
                                        name="city"
                                        value={model.address.city ? model.address.city : ""}
                                        onChange={(e) => { updateModelInStore(e.target.name, e.target.value, 'address') }}
                                    />
                                </Form.Group>
                                <Form.Group widths='equal'>
                                    <Form.Input
                                        fluid
                                        label={t('Postal')}
                                        name="state"
                                        value={model.address.state ? model.address.state : ""}
                                        onChange={(e) => { updateModelInStore(e.target.name, e.target.value, 'address') }}
                                    />
                                    <Form.Input
                                        fluid
                                        label={t('StateCode')}
                                        name="state_code"
                                        value={model.address.state_code ? model.address.state_code : ""}
                                        onChange={(e) => { updateModelInStore(e.target.name, e.target.value, 'address') }}
                                    />
                                    <CountrySelector
                                        selected={model.address.country_code ? model.address.country_code : ''}
                                        countrySelected={(value) => {
                                            updateModelInStore('country_code', value, 'address')
                                        }}
                                        label={t("Country")}
                                    />
                                </Form.Group>
                                <Header as='h5'>{t('Client')} {t('Preference')}</Header>
                                <Form.Group widths='equal'>
                                    <Form.Input
                                        fluid
                                        label={t("InvoicePrefix")}
                                        name="invoice_prefix"
                                        value={model.detail.invoice_prefix ? model.detail.invoice_prefix : ""}
                                        onChange={(e) => { updateModelInStore(e.target.name, e.target.value, 'detail') }}
                                    />
                                    <Form.Input
                                        fluid
                                        label={t("NextInvNum")}
                                        name="next_inv_num"
                                        value={model.detail.next_inv_num ? model.detail.next_inv_num : ""}
                                        onChange={(e) => { updateModelInStore(e.target.name, e.target.value, 'detail') }}
                                    />
                                    <Form.Input
                                        fluid
                                        label={t("PacingSlipPrefix")}
                                        name="packing_slip_prefix"
                                        value={model.detail.packing_slip_prefix ? model.detail.packing_slip_prefix : ""}
                                        onChange={(e) => { updateModelInStore(e.target.name, e.target.value, 'detail') }}
                                    />
                                    <Form.Input
                                        fluid
                                        label={t("NextPackingSlipNum")}
                                        name="next_picking_num"
                                        value={model.detail.next_picking_num ? model.detail.next_picking_num : ""}
                                        onChange={(e) => { updateModelInStore(e.target.name, e.target.value, 'detail') }}
                                    />
                                </Form.Group>
                                <Form.Group widths='equal'>
                                    <Form.Input
                                        fluid
                                        label={t("LogoUri")}
                                        name="logo_uri"
                                        value={model.detail.logo_uri ? model.detail.logo_uri : ""}
                                        onChange={(e) => { updateModelInStore(e.target.name, e.target.value, 'detail') }}
                                    />
                                    <Form.Input
                                        fluid
                                        label={t("AccountingName")}
                                        name="qb_mapping_name"
                                        value={model.detail.qb_mapping_name ? model.detail.qb_mapping_name : ""}
                                        onChange={(e) => { updateModelInStore(e.target.name, e.target.value, 'detail') }}
                                    />
                                    <Form.Input
                                        fluid
                                        label={t("AccountingTerm")}
                                        name="invoice_term"
                                        value={model.detail.invoice_term ? model.detail.invoice_term : ""}
                                        onChange={(e) => { updateModelInStore(e.target.name, e.target.value, 'detail') }}
                                    />
                                    <Form.Input
                                        fluid
                                        type='number'
                                        label={t("AccountingDueDate")}
                                        name="due_date"
                                        value={model.detail.due_date ? model.detail.due_date : ""}
                                        onChange={(e) => { updateModelInStore(e.target.name, e.target.value, 'detail') }}
                                    />
                                </Form.Group>
                                <Form.Group widths='equal'>
                                    <Form.Checkbox
                                        label={t('UseShipStation')}
                                        name="use_ship_station"
                                        onChange={(e,data) => { updateModelInStore('use_ship_station', data.checked, 'detail') }}
                                        checked={model.detail.use_ship_station}
                                    />
                                    <Form.Checkbox
                                        label={t('EmailUponReceiving')}
                                        name="email_upon_receiving"
                                        onChange={(e,data) => { updateModelInStore('email_upon_receiving', data.checked, 'detail') }}
                                        checked={model.detail.email_upon_receiving}
                                    />
                                    <Form.Checkbox
                                        label={t('EmailUponShipping')}
                                        name="email_upon_shipping"
                                        onChange={(e,data) => { updateModelInStore('email_upon_shipping', data.checked, 'detail') }}
                                        checked={model.detail.email_upon_shipping}
                                    />
                                </Form.Group>
                                <Form.Group widths='equal'>
                                    {model.detail.use_ship_station ? <ShipStationWarehouseSelector
                                        onSelectChange={(value) => {
                                            updateModelInStore('ss_warehouse', value, 'detail')
                                        }}
                                        label={t('ShipStationWarehouse')}
                                        selected={model.detail.ss_warehouse}
                                    /> : null }
                                    <Form.Input
                                        fluid
                                        type='number'
                                        label={t("ShippingChargeModify")}
                                        name="shipping_charge_modify"
                                        value={model.detail.shipping_charge_modify ? model.detail.shipping_charge_modify : ""}
                                        onChange={(e) => { updateModelInStore(e.target.name, e.target.value, 'detail') }}
                                    />
                                    <Form.Select
                                        label={t('OrderNotification')}
                                        options={[
                                            {value: 'Created', text: t('WhenOrderCreated')},
                                            {value: 'Processed', text: t('WhenOrderProcessed')},
                                            {value: 'None', text: t('DoNotNotify')}
                                        ]}
                                        value={model.detail.order_notification}
                                        onChange={(e, {value}) => {
                                            updateModelInStore('order_notification', value, 'detail')
                                        }}
                                    />
                                    <Form.Input
                                        fluid
                                        type='text'
                                        label={t("OrderNotificationEmail")}
                                        name="order_notification_email"
                                        value={model.detail.order_notification_email ? model.detail.order_notification_email : ""}
                                        onChange={(e) => { updateModelInStore(e.target.name, e.target.value, 'detail') }}
                                    />
                                </Form.Group>
                                <Form.Group widths={4}>
                                    <Form.Input
                                        fluid
                                        label={t("ClientTaxCode")}
                                        name="tax_code"
                                        value={model.detail.tax_code ? model.detail.tax_code : ""}
                                        onChange={(e) => { updateModelInStore(e.target.name, e.target.value, 'detail') }}
                                    />
                                    <Form.Input
                                        fluid
                                        type="number"
                                        label={`${t("ClientTaxRate")} (Use 0.05 for 5%)`}
                                        name="tax_rate"
                                        value={model.detail.tax_rate ? model.detail.tax_rate : ""}
                                        onChange={(e) => { updateModelInStore(e.target.name, e.target.value, 'detail') }}
                                    />
                                </Form.Group>
                                <div className="row">
                                    <div className="col-md-6 col-sm-6 col-xs-12">
                                        {model.detail.email_upon_receiving ? <ClientEmailList
                                            list={model.detail.receiving_notification_emails}
                                            onRemoveEmailAddress={(index) => {
                                                this.props.removeEmailFromClientDetail(index, 'receiving_notification_emails')
                                            }}
                                            onAddEmailAddress={(email) => {
                                                this.props.addEmailToClientDetail(email, 'receiving_notification_emails')
                                            }}
                                            label={t('ReceivingEmails')}
                                        /> : null}
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-xs-12">
                                        {model.detail.email_upon_shipping ? <ClientEmailList
                                            list={model.detail.shipping_notification_emails}
                                            onRemoveEmailAddress={(index) => {
                                                this.props.removeEmailFromClientDetail(index, 'shipping_notification_emails')
                                            }}
                                            onAddEmailAddress={(email) => {
                                                this.props.addEmailToClientDetail(email, 'shipping_notification_emails')
                                            }}
                                            label={t('ShippingEmails')}
                                        /> : null}
                                    </div>
                                </div>
                                {this.props.server_pref.has_flex_integration_bool ? <div className="row">
                                    <div className="col-md-12">
                                        <Header as='h5'>CON-LINQ (Flex) Integration</Header>
                                        <Form.Group widths='equal'>
                                            <Form.Input
                                                fluid
                                                label="Customer ID"
                                                name="custom_1"
                                                value={model.custom_1 ? model.custom_1 : ""}
                                                onChange={(e) => { updateModelInStore(e.target.name, e.target.value) }}
                                            />
                                            <Form.Input
                                                fluid
                                                label="User Email"
                                                name="custom_2"
                                                value={model.custom_2 ? model.custom_2 : ""}
                                                onChange={(e) => { updateModelInStore(e.target.name, e.target.value) }}
                                            />
                                            <Form.Input
                                                fluid
                                                label="Client Currency"
                                                name="custom_3"
                                                value={model.custom_3 ? model.custom_3 : ""}
                                                onChange={(e) => { updateModelInStore(e.target.name, e.target.value) }}
                                            />
                                        </Form.Group>
                                    </div>
                                </div> : null}
                            </Form>
                        </Segment>
                    </div>
                </div>
            </div>

        );
    }
}

ClientEditPage.propTypes = {
    model: PropTypes.object.isRequired,
    updateModelInStore: PropTypes.func.isRequired,
    updateModelOnServer: PropTypes.func.isRequired,
    createNewModelInStore: PropTypes.func.isRequired,
    loadShipStationWarehouses: PropTypes.func.isRequired,
    fetchById: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    warehouses: PropTypes.array.isRequired,
    addEmailToClientDetail: PropTypes.func.isRequired,
    removeEmailFromClientDetail: PropTypes.func.isRequired,
    server_pref: PropTypes.object.isRequired
};

function mapStateToProps(state) {
    return {
        model: state.clientModel,
        warehouses: state.system.ship_station.warehouses,
        server_pref: state.system.server_pref
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            updateModelInStore,
            updateModelOnServer,
            createNewModelInStore,
            fetchById,
            loadShipStationWarehouses,
            addEmailToClientDetail,
            removeEmailFromClientDetail
        },
        dispatch
    );
}

export default withI18n()(connect(
    mapStateToProps,
    mapDispatchToProps
)(ClientEditPage));
