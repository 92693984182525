import React, { Component } from 'react';
import {Segment, Loader, Dimmer} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import SegmentRibbon from '../../includes/partials/SegmentRibbon';
import PrimaryButton from "../../includes/buttons/PrimaryButton";
import ExcelUpload from '../../includes/excel/Main';

class ExcelShippingSegment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            excelModalDisplay: false
        }
    }

    render() {
        return (
            <Segment>
                <SegmentRibbon label="Excel" Buttons={() => {
                return <PrimaryButton
                    size='mini'
                    float='right'
                    label="Upload (Beta)"
                    loading={false}
                    onClick={() => {
                        this.setState({excelModalDisplay: true})
                    }}
                />
            }}/>
                <Dimmer inverted active={this.state.loading}>
                    <Loader />
                </Dimmer>
                <ExcelUpload
                    displayModal={this.state.excelModalDisplay}
                    onCloseModalClick={() => {
                        this.setState({excelModalDisplay: false})
                    }}
                    onUploaded={() => {
                        this.props.onExcelUploaded();
                    }}
                    type='ShipmentFulfillmentUpload'
                    shipmentModel={this.props.model}
                />
            </Segment>
        )
    }
}

ExcelShippingSegment.propTypes = {
    model: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    onExcelUploaded: PropTypes.func.isRequired
};

export default ExcelShippingSegment
