import _ from 'lodash';
import {api} from '../api';
import {
    SET_SHIPMENTS_PAGINATED_LIST,
    SET_SHIPMENT_MODEL_FROM_SERVER,
    SET_SHIPMENT_PAGE_PREDIFIEND_QUERIES,
    CREATE_SHIPMENT_PAGE_PREDIFINED_QUERIES,
    SHIPMENT_LIST_LINE_ACTION_CLICKED,
    SET_SHIPMENT_LINE_TO_FULFILLMENT_MODEL,
    FULFILLMENT_MODAL_CLOASED,
    FULFILLMENT_MODEL_UPDATED_BY_FORM,
    FULFILLMENT_RESOURCE_LICENSES_LOADED,
    FULFILLMENT_RESOURCE_SERIALS_LOADED,
    SHIPMENT_LINE_FULFILLMENT_SAVED,
    FULFILLMENT_RESOURCE_LOTS_LOADED,
    NEW_LOT_ADDED_TO_INBOUND_FULFILLMENT,
    FULFILLMENT_LINE_DELETED,
    SHIPMENT_MODEL_PROPERTY_UPDATED,
    SHIPMENT_LINE_QUANTITY_UPDATED_BY_FORM,
    SHIPMENT_LINE_SHOULD_UPDATE_ORDER_LINE_UPDATED_BY_FORM,
    SHIPMENT_LINE_QUANTITY_UPDATED_ON_SERVER,
    SHIPPO_PURCHASED_UNDER_SHIPMENT,
    NEW_ATTACHMENT_UPLOADED_TO_SHIPMENT,
    ATTACHMENT_DELETED_FROM_SHIPMENT,
    SHIPPO_VOIDED,
    SHIPMENT_SHIPSTATION_STATUS_CHANGED,
    SHIPMENT_COMMENT_ADDED
} from './types';

export const fetchPaginatedList = (path) => (dispatch) => {
    return api.shipments.paginated(path).then(res => {
        const {data} = res.data;
        _.each(data.records, r => {
            r.action = false;
        });
        dispatch({
            type: SET_SHIPMENTS_PAGINATED_LIST,
            payload: data
        });
        return res;
    }).catch(err => {throw err});
}

export const fetchById = (id) => (dispatch) => {
    return api.shipments.read(id).then(res => {
        dispatch({
            type: SET_SHIPMENT_MODEL_FROM_SERVER,
            payload: res.data.data
        });
        return res;
    }).catch(err => {throw err});
}

export const resetModelAfterFillAll = (data) => {
    return {
        type: SET_SHIPMENT_MODEL_FROM_SERVER,
        payload: data
    }
}

export const setEmptyShipmentInStore = () => {
    return {
        type: SET_SHIPMENT_MODEL_FROM_SERVER,
        payload: {
            id: null,
            shipping_addr: {},
            billing_addr: {},
            tracking: {},
            shipping: {},
            accounting: {},
            courier: {},
            shippo: [],
            order: {},
            shipment_lines: [],
            containers: [],
            comments: [],
            files: []
        }
    }
}

export const shipmentListLineActionChecked = (checked, index) => {
    return {
        type: SHIPMENT_LIST_LINE_ACTION_CLICKED,
        payload: {checked, index}
    }
}

export const fetchPredefinedRoutes = () => (dispatch) => {
    return api.preferences.getPaginationPreference('Shipment').then(res => {
        const data = res.data.data;
        const payload = [];
        payload.unshift({
            key: 'default',
            value: 'default',
            text: 'Default All Shipment List'
        });
        _.each(data, q => {
            payload.push({
                key: q.id,
                value: _.toString(q.id),
                text: q.name,
                query: q.value
            });
        });
        dispatch({
            type: SET_SHIPMENT_PAGE_PREDIFIEND_QUERIES,
            payload: payload
        })
    });
}

export const saveShipmentQuery = (name, query) => (dispatch) => {
    return api.preferences.createPaginationPreference({
        name: name,
        param: query,
        page: 'Shipment'
    }).then(res => {
        dispatch({
            type: CREATE_SHIPMENT_PAGE_PREDIFINED_QUERIES,
            payload: res.data.data
        });
        return res;
    }).catch(err => {
        throw err;
    })
}

export const initiateFulfillmentModel = (line, lineIndex) => {
    return {
        type: SET_SHIPMENT_LINE_TO_FULFILLMENT_MODEL,
        payload: {
            lineIndex: lineIndex,
            line: line,
            model: {
                lineId: line.id,
                quantity: 1,
                quarantine: false,
                license: "",
                serialId: "",
                lotId: "",
                newLot: {
                    lot: "",
                    expire: ""
                },
                reference: ""
            }
        }
    }
}

export const onFulfillmentModalClose = () => {
    return {
        type: FULFILLMENT_MODAL_CLOASED,
        payload: null
    }
}

export const updateFulfillmentModelByForm = (field, value, isNewLot) => {
    return {
        type: FULFILLMENT_MODEL_UPDATED_BY_FORM,
        payload: {
            field: field,
            value: value,
            isNewLot: isNewLot
        }
    }
}

export const fetchFulfillmentLicenses = (client_id, license, action, inventory_id) => (dispatch) => {
    return api.shipments.searchLpAndLotNumber(client_id, license, action, inventory_id).then(res => {
        dispatch({
            type: FULFILLMENT_RESOURCE_LICENSES_LOADED,
            payload: res.data.data
        });
        return res.data.data
    })
}

export const fetchFulfillmentSerisl = (inventory_id) => (dispatch) => {
    return api.shipments.searchSnNumber(inventory_id).then(res => {
        dispatch({
            type: FULFILLMENT_RESOURCE_SERIALS_LOADED,
            payload: res.data.data
        })
    })
}

export const fetchFulfillmentLot = (inventory_id) => (dispatch) => {
    return api.shipments.searchLotNumber(inventory_id).then(res => {
        dispatch({
            type: FULFILLMENT_RESOURCE_LOTS_LOADED,
            payload: res.data.data
        })
        return res;
    })
}

export const deleteFulfillmentLine = (shipmentId, lineId, fulfillmentId, lineIndex, fulfillmentIndex) => (dispatch) => {
    return api.shipments.deleteFulfillmentLine(shipmentId, lineId, fulfillmentId).then(res => {
        dispatch({
            type: FULFILLMENT_LINE_DELETED,
            payload: {
                lineIndex: lineIndex,
                fulfillmentIndex: fulfillmentIndex
            }
        })
        return res;
    })
}

export const saveFulfillmentModel = (id, model, shipmentLineIndex, action) => (dispatch) => {
    // console.log("1",action);
    // console.log("2",model);
    return api.shipments.fillLine(id, model).then(res => {
        dispatch({
            type: SHIPMENT_LINE_FULFILLMENT_SAVED,
            payload: {
                index: shipmentLineIndex,
                data: res.data.data,
                resetModel: {
                    lineId: model.lineId,
                    quantity: 1,
                    quarantine: false,
                    license: "",
                    serialId: "",
                    lotId: "",
                    newLot: {
                        lot: "",
                        expire: ""
                    },
                    reference: ""
                }
            }
        });
        // console.log(action, model);
        // console.log(res.data.data);
        if (action === 'inbound') {
            if (!model.lotId && model.newLot.lot) {
                const {lots} = res.data.data.appended;
                if (lots) {
                    dispatch({
                        type: NEW_LOT_ADDED_TO_INBOUND_FULFILLMENT,
                        payload: lots
                    })
                }
            }
        }
        return res;
    })
}

export const updateShipmentProperty = (id, field, value) => (dispatch) => {
    if (field === 'waybill' || field === 'cost' || field === 'carton_count' || field === 'attr_1') {
        return api.shipments.update(id, field, value).then(res => {
            dispatch({
                type: SHIPMENT_MODEL_PROPERTY_UPDATED,
                payload: {field, value}
            })
            return res;
        })
    } else {
        dispatch({
            type: SHIPMENT_MODEL_PROPERTY_UPDATED,
            payload: {field, value}
        })
    }
}

export const updateShipmentLineQuantity = (index, quantity) => {
    return {
        type: SHIPMENT_LINE_QUANTITY_UPDATED_BY_FORM,
        payload: {index, quantity}
    }
}

export const updateShipmentLineShouldUpdateOrderLine = (index, value) => {
    return {
        type: SHIPMENT_LINE_SHOULD_UPDATE_ORDER_LINE_UPDATED_BY_FORM,
        payload: {index, value}
    }
}

export const shipmentLineQtyUpdated = (index, qty, newStatus) => {
    return {
        type: SHIPMENT_LINE_QUANTITY_UPDATED_ON_SERVER,
        payload: {
            index: index,
            quantity: qty,
            newStatus: newStatus
        }
    }
}

export const shippoPurchased = (data) => {
    return {
        type: SHIPPO_PURCHASED_UNDER_SHIPMENT,
        payload: data
    }
}

export const shippoVoided = (index, data) => {
    return {
        type: SHIPPO_VOIDED,
        payload: {
            index, data
        }
    }
}

export const newFileUploaded = (data) => {
    return {
        type: NEW_ATTACHMENT_UPLOADED_TO_SHIPMENT,
        payload: data
    }
}

export const deleteShipmentFile = (index) => {
    return {
        type: ATTACHMENT_DELETED_FROM_SHIPMENT,
        payload: index
    }
}

export const onChangeShipStationStatus = (ssIdOrNull) => {
    return {
        type: SHIPMENT_SHIPSTATION_STATUS_CHANGED,
        payload: ssIdOrNull
    }
}

export const shipmentCommentAdded = (data) => {
    return {
        type: SHIPMENT_COMMENT_ADDED,
        payload: data
    }
}
