import React from 'react';
import {Header, Icon} from 'semantic-ui-react';
import PropTypes from 'prop-types';

const FBAHeader = ({model}) => (
    <div>
        <Header as='h2'>
            <Icon name='settings' />
            <Header.Content>
                FBA Integration
                <Header.Subheader>MWS Seller ID: {model.key_4}</Header.Subheader>
                <Header.Subheader>MWS Token: {model.key_3}</Header.Subheader>
            </Header.Content>
        </Header>
    </div>
)

FBAHeader.propTypes = {
    model: PropTypes.object.isRequired
};


export default FBAHeader