import React, { Component } from 'react';
import { bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import {Form} from 'semantic-ui-react';
import PropTypes from 'prop-types';

class AddressSelector extends Component {
    //access router
    //this.props.history.push('/dashboard')
    rtnObjectSelectedValue = (value) => {
        const {addresses, onSelectChange} = this.props;
        const record = addresses.filter(i => {
            return _.toString(i.id) === value;
        });

        if (record.length > 0) {
            return onSelectChange(record[0]);
        } else {
            return {
                id: null,
                company: "NA"
            }
        }
    }
    
    render() {
        const {addresses, appendNonOption, returnObject, label, selected, onSelectChange, type} = this.props;
        const options = [];
        _.each(addresses, c => {
            if (c.type === type || c.type === 'Both') {
                options.push({
                    value: _.toString(c.id),
                    text: `${c.company+' - '} ${c.street1+','} 
                        ${c.street2 ? c.street2+',' : ''} 
                        ${c.street3 ? c.street3+',' : ''}
                        ${c.city ? c.city : ''}
                        ${c.state_code ? c.state_code : ''}
                        ${c.postal_code ? c.postal_code : ''}`,
                    key: c.id
                });
            }
        });
        if (appendNonOption) {
            options.unshift({
                value: "0",
                text: "NA",
                key: 0
            });
        }
        return (
            <Form.Select fluid search selection 
                label={label}
                required={true}
                options={options}
                name="address_id" value={selected ? _.toString(selected) : "0"} 
                onChange={(e, {value}) => {
                    returnObject ? this.rtnObjectSelectedValue(value) : onSelectChange(value)
                }}
            />
        )
    }
}

AddressSelector.propTypes = {
    onSelectChange: PropTypes.func.isRequired,
    addresses: PropTypes.array.isRequired,
    appendNonOption: PropTypes.bool.isRequired,
    returnObject: PropTypes.bool.isRequired,
    label: PropTypes.string,
    selected: PropTypes.any,
    type: PropTypes.string.isRequired
    //history: PropTypes.shape({
    //    push: PropTypes.func.isRequired
    //}).isRequired,
};

function mapStateToProps(state) {
    return {
        
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AddressSelector)