import React from 'react';
import { bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import {Button} from 'semantic-ui-react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';

class PrimaryNaviButton extends React.PureComponent {
    //access router
    //this.props.history.push('/dashboard')
    
    render() {
        const {link, size, float, label, preference, loading} = this.props;
        return (
            <Button
                size={size} 
                as={Link}
                to={link}
                floated={float}
                disabled={loading}
                loading={loading}
                color={preference.t_primary_btn ? preference.t_primary_btn : 'teal'}
            >{label}</Button>
        )
    }
}

PrimaryNaviButton.propTypes = {
    link: PropTypes.string.isRequired,
    size: PropTypes.string.isRequired,
    float: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
    return {
        preference: state.system.preference
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(PrimaryNaviButton)