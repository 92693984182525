import {api} from '../api';
import {
    SET_LICENSES_PAGINATED_LIST,
    LICENSE_SAVED_ADD_TO_LIST,
    LICENSE_DELETED,
    LICENSE_MOVED
} from './types';

export const fetchPaginatedList = (path) => (dispatch) => {
    return api.licenses.paginated(path).then(res => {
        dispatch({
            type: SET_LICENSES_PAGINATED_LIST,
            payload: res.data.data
        });
        return res;
    }).catch(err => {throw err});
}

export const licenseSaved = (index, model) => {
    return {
        type: LICENSE_SAVED_ADD_TO_LIST,
        payload: {index, model}
    }
}

export const licenseDeleted = (index) => {
    return {
        type: LICENSE_DELETED,
        payload: index
    }
}

export const licenseMoved = (index, data) => {
    return {
        type: LICENSE_MOVED,
        payload: {
            index: index,
            data: data
        }
    }
}