import React, { Component } from 'react';
import { bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import PropTypes from 'prop-types'
import {withI18n} from 'react-i18next';
import {Segment, Table, Dimmer, Loader, Icon, Popup} from 'semantic-ui-react';
import {Link} from 'react-router-dom';
import SegmentRibbon from '../includes/partials/SegmentRibbon';
import PageBreadCrub from '../includes/partials/PageBreadCrub';
import qs from 'qs';
import update from 'immutability-helper';
import {
    fetchPaginatedListBasicView,
    fetchPredefinedRoutes,
    saveInventoryQuery
} from '../../actions/inventory';
import OrderBy from '../includes/tables/OrderBy';
import SearchBar from '../includes/tables/SearchBar';
import Pagination from '../includes/tables/Pagination';
import FilterBy from '../includes/tables/FilterBy';
import AlertComponent from '../includes/messages/alert';
import Lara from '../../lara';
import LaraPagination from '../../laraPagination';
import ExcelUpload from '../includes/excel/Main';
import PrimaryNaviButton from '../includes/buttons/PrimaryNaviButton';
import SecondaryButton from '../includes/buttons/SecondaryButton';
import PrimaryButton from '../includes/buttons/PrimaryButton';
import InventoryDisplay from "../includes/partials/InventoryDisplay";
import PdfModal from '../includes/pdf/PdfModal';
import InventoryListPDFComponent from '../includes/pdf/docs/InventoryListPDFComponent';

class InventoryBasicListPage extends Component {
    constructor() {
        super();
        this.state = {
            breadcrub: [
                {isLink:false, to:null, tag: '', active: true, key: 1}
            ],
            queryId: 'default',
            queryName: "",
            loading: false,
            routingQuery: {

            },
            routingPath: "",
            search: "",
            showQueryBuilder: false,
            excelModalDisplay: false,
            excelUomModalDisplay: false,
            showPDFModal: false
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const {params, url} = this.props.match;
        if (url !== this.state.routingPath) {
            this.setRoutingInState(params, url);
        }
    }

    componentDidMount() {
        this.props.fetchPredefinedRoutes('BasicInventory');
        const {params, url} = this.props.match;
        this.setRoutingInState(params,url);
    }

    setRoutingInState = ({query, queryId}, url) => {
        const q = qs.parse(query);
        //order_by
        //filter_by
        //query
        if (!_.isObject(q.filter_by)) {
            q.filter_by = {};
        }
        if (!_.isObject(q.order_by)) {
            q.order_by = {};
        }
        if (!_.isArray(q.query)) {
            q.query = [];
        }
        this.setState({
            loading: true,
            queryId: queryId,
            routingQuery: q,
            routingPath: url,
            search: q.search,
            breadcrub: [
                {isLink:false, to:null, tag: this.props.t('Inventory'), active: true, key: 1},
                {isLink: true, to: `/inventories/default/${LaraPagination.inventories()}`, tag: 'Advanced View', active: true, key: 2},
                {isLink:false, to:null, tag: 'Quick View', active: true, key: 3},
            ]
        }, () => {
            this.fetchRecords(query)
        });
    }

    fetchRecords = () => {
        this.props.fetchPaginatedListBasicView(qs.stringify(this.state.routingQuery)).then(res => {
            this.setState({loading: false});
        }).catch(err => {
            const errors = Lara.axiosError(err, this.props.t);
            if (errors.http_code === 422) {
                this.setState({
                    errors: errors.message,
                    loading: false
                });
            } else {
                this.setState({
                    loading: false
                });
                Lara.axiosAlert(<AlertComponent
                    support={errors.support}
                    message={errors.message}
                    reference={errors.reference}
                    t={this.props.t}
                />);
            }
            this.setState({loading: false});
        });
    }

    routeToNewQuery = (newRoutingQuery) => {
        this.props.history.push(`/inventories/${this.state.queryId}/${qs.stringify(newRoutingQuery)}/list`);
    }

    /*
     * For table header filters
    */
    onOrderByHeaderClicked = (routingOrderName, value) => {
        const newRoutingQuery = update(this.state.routingQuery, {
            order_by: {$set: {
                [routingOrderName]: value
            }}
        });
        this.routeToNewQuery(newRoutingQuery);
    }

    /*
     * For pagination
    */
    onPaginationClicked = (e, data) => {
        const {activePage} = data;
        const newRoutingQuery = update(this.state.routingQuery, {
            on_page: {$set: activePage}
        });
        this.routeToNewQuery(newRoutingQuery);
    }

    /*
     * For Search bar: onSearchEnterKeyPressed onSearchChange onPredefinedRouteSelected (optional)
     */
    onSearchEnterKeyPressed = (e) => {
        if (e.key === 'Enter') {
            const defaultQuery = LaraPagination.inventories(true);
            defaultQuery.search = this.state.search;
            defaultQuery.filter_by.is_active="1,0";
            this.setState({
                queryId: 'default'
            }, () => {
                this.routeToNewQuery(defaultQuery);
            });
        }
    }
    onPredefinedRouteSelected = (value) => {
        const {queries} = this.props;
        let query = LaraPagination.inventories(true);
        if (value !== 'default') {
            const queryIndex = _.findIndex(queries, i => {
                return i.value === value;
            });
            if (queryIndex > 0) {
                query = queries[queryIndex].query;
            }
        }
        this.setState({
            queryId: value
        }, () => {
            this.routeToNewQuery(query);
        });
    }

    //New Sep 12, 2019 - add pagination per page selector
    onPerPageChanged = (newPerPage) => {
        const query = update(this.state.routingQuery, {per_page: {$set: newPerPage}});
        this.routeToNewQuery(query);
    }

    /*
     * Advance Filter Section
     */
    onFilterSelectChange = (field, value) => {
        this.setState({
            routingQuery: update(this.state.routingQuery, {
                filter_by: {
                    [field]: {$set: value.join()}
                }
            })
        });
    }
    onQueryConstructorChange = (property, index, value, isRelation) => {
        if (!isRelation) {
            this.setState({
                routingQuery: update(this.state.routingQuery, {
                    query: {
                        [index]: {
                            [property]: {$set: value}
                        }
                    }
                })
            });
        } else {
            this.setState({
                routingQuery: update(this.state.routingQuery, {
                    query: {
                        [index]: {
                            relation: {$set: value === 'name' ? 'categories' : null},
                            [property]: {$set: value}
                        }
                    }
                })
            });
        }
    }
    addNewQuery = () => {
        this.setState({
            routingQuery: update(this.state.routingQuery, {
                query: {$push: [{
                    relation: null,
                    field: "sku",
                    operator: "=",
                    value: "",
                    join: "AND"
                }]}
            })
        });
    }
    onRemoveQueryClick = (index) => {
        this.setState({
            routingQuery: update(this.state.routingQuery, {
                query: {$splice: [[index, 1]]}
            })
        });
    }
    runFilter = () => {
        this.routeToNewQuery(this.state.routingQuery);
    }
    saveNewFilter = () => {
        if (this.state.queryName.length <= 0) {
            return null;
        }
        this.props.saveInventoryQuery(this.state.queryName, this.state.routingQuery, 'BasicInventory').then(res => {
            this.setState({
                queryId: _.toString(res.data.data.id),
                queryName: ""
            });
        }).catch(err => {
            const errors = Lara.axiosError(err, this.props.t);
            if (errors.http_code === 422) {
                this.setState({
                    errors: errors.message,
                    loading: false
                });
            } else {
                this.setState({
                    loading: false
                });
                Lara.axiosAlert(<AlertComponent
                    support={errors.support}
                    message={errors.message}
                    reference={errors.reference}
                    t={this.props.t}
                />);
            }
        });
    }
    onPrintClicked = (show) => {
        this.setState({
            showPDFModal: show
        })
    }
    render() {
        const {routingQuery, search, showQueryBuilder, queryName} = this.state;
        const {t, list, queries} = this.props;
        const {on_page, per_page, total} = list;
        const orderBy = _.isObject(routingQuery.order_by) ? routingQuery.order_by : {};
        const filterBy = _.isObject(routingQuery.filter_by) ? routingQuery.filter_by : {
            type: "",
            is_active: ""
        };
        const routeQuries = _.isArray(routingQuery.query) ? routingQuery.query : [];
        const queriableField = LaraPagination.inventoryQuariableBasic(t);
        return (
            <div>
                {/* Breadcrub Row */}
                <div className="row">
                    <div className="col-md-12 lara-breadcrub">
                        <PageBreadCrub lists={this.state.breadcrub} t={t}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <Segment>
                            <SegmentRibbon label={`${t('Inventory')} ${t('Search')}`} Buttons={
                                () => {return (<React.Fragment>
                                    <PrimaryNaviButton
                                        link='/inventories/new/action/edit'
                                        size='mini'
                                        float='right'
                                        loading={false}
                                        label={t('Create')}
                                    />
                                    <SecondaryButton
                                        size='mini'
                                        float='right'
                                        label={t('Filter')}
                                        onClick={() => {
                                            this.setState({
                                                showQueryBuilder: !this.state.showQueryBuilder
                                            })
                                        }}
                                        loading={false}
                                    />
                                    <SecondaryButton
                                        size='mini'
                                        float='right'
                                        label='Excel (Item)'
                                        onClick={() => {
                                            this.setState({excelModalDisplay: true})
                                        }}
                                        loading={false}
                                    />
                                    <SecondaryButton
                                        size='mini'
                                        float='right'
                                        label='Excel (UOM)'
                                        onClick={() => {
                                            this.setState({excelUomModalDisplay: true})
                                        }}
                                        loading={false}
                                    />
                                </React.Fragment>)}
                            }/>
                            <SearchBar
                                placeholder={`${t('Search by')} ${t('SKU')}, ${t('Name')}, ${t('UPC')}...`}
                                onSearchEnterKeyPressed={(e) => {this.onSearchEnterKeyPressed(e)}}
                                search={search}
                                onSearchChange={(search) => {this.setState({search})}}
                                onPredefinedRouteSelected={(value) => this.onPredefinedRouteSelected(value)}
                                options={queries}
                                query={this.state.queryId}
                                havePreferenceSelect={false}
                                hasPerPageSelector={routingQuery.per_page}
                                onPerPageSelcted={(perPage) => {
                                    this.onPerPageChanged(perPage);
                                }}
                            />
                        </Segment>
                        {showQueryBuilder ? <Segment>
                        <SegmentRibbon label={`${t('AdvancedFilter')} (Beta)`}/>
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-xs-12">
                                <div className="row">
                                    <FilterBy
                                        t={t}
                                        label={'Type'}
                                        options={[
                                            {value: 'Item', text: 'Inventory Item'},
                                            {value: 'Kit', text: 'Inventory Kit'},
                                        ]}
                                        name="type"
                                        currentValueString={filterBy.type}
                                        onFilterSelectChange={(name, value) => {this.onFilterSelectChange(name, value)}}
                                    />
                                    <FilterBy
                                        t={t}
                                        label={'Status'}
                                        options={[
                                            {value: '0', text: 'Disabled'},
                                            {value: '1', text: 'Active'},
                                        ]}
                                        name="is_active"
                                        currentValueString={filterBy.is_active}
                                        onFilterSelectChange={(name, value) => {this.onFilterSelectChange(name, value)}}
                                    />
                                </div>
                                {/*<div className="lara-filter-name">{t('Conditions')}</div>*/}
                                {/*{routeQuries.map((q, index) => {*/}
                                {/*    return (*/}
                                {/*        <LaraQuery*/}
                                {/*            fields={queriableField}*/}
                                {/*            query={q}*/}
                                {/*            index={index}*/}
                                {/*            onQueryConstructorChange={(property, index, value) => {*/}
                                {/*                this.onQueryConstructorChange(property, index, value, false)*/}
                                {/*            }}*/}
                                {/*            onQueryConstructorRelationalChange={(property, index, value) => {*/}
                                {/*                this.onQueryConstructorChange(property, index, value, true)*/}
                                {/*            }}*/}
                                {/*            key={index}*/}
                                {/*            onRemoveQueryClick={() => {this.onRemoveQueryClick(index)}}*/}
                                {/*        />*/}
                                {/*    )*/}
                                {/*})}*/}
                                <div className="row">
                                    <div className="col-md-4 col-sm-6 col-xs-12 lara-row-margin">
                                {/*        <PrimaryButton*/}
                                {/*            size='mini'*/}
                                {/*            float='left'*/}
                                {/*            label={t('Conditions')}*/}
                                {/*            onClick={() => {*/}
                                {/*                this.addNewQuery()*/}
                                {/*            }}*/}
                                {/*            loading={this.state.loading}*/}
                                {/*        />*/}
                                        <PrimaryButton
                                            size='mini'
                                            float='left'
                                            label={t('Run Filter')}
                                            onClick={() => {
                                                this.runFilter()
                                            }}
                                            loading={this.state.loading}
                                        />
                                {/*    </div>*/}

                                {/*    <div className="col-md-4 col-sm-6 col-xs-12 lara-row-margin">*/}
                                {/*        <Input fluid*/}
                                {/*            size='mini'*/}
                                {/*            disabled={this.state.loading}*/}
                                {/*            placeholder='Enter a name and save this filter'*/}
                                {/*            onChange={(e, {value}) => {this.setState({*/}
                                {/*                queryName: value*/}
                                {/*            })}} value={queryName} />*/}
                                {/*    </div>*/}
                                {/*    <div className="col-md-4 col-sm-6 col-xs-12 lara-row-margin">*/}
                                {/*        <PrimaryButton*/}
                                {/*            size='mini'*/}
                                {/*            float='left'*/}
                                {/*            label={t('Save')}*/}
                                {/*            onClick={() => {*/}
                                {/*                this.saveNewFilter()*/}
                                {/*            }}*/}
                                {/*            loading={this.state.loading}*/}
                                {/*        />*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                        </Segment> : null}

                        <Segment>
                            <Dimmer inverted active={this.state.loading}>
                                <Loader />
                            </Dimmer>
                            <SegmentRibbon label={`${t('Inventory')} ${t('List')}`} Buttons={
                                () => {return (<React.Fragment>
                                    <SecondaryButton
                                        size='mini'
                                        float='right'
                                        label={t('Print')}
                                        onClick={() => {this.onPrintClicked(true)}}
                                        loading={false}
                                    />
                                    <Popup
                                        trigger={<Icon size='large' name='question circle outline' />}
                                        header="Quick View"
                                        content='This inventory view provides a much faster searching speed. Click on Advanced View to use all inventory view functions.'
                                        size='mini'
                                    />
                                </React.Fragment>)}
                            }/>
                            <div className="row lara-row-margin">
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <Table
                                        compact
                                        basic
                                        padded={false}
                                    >
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell>
                                                    {t('Type')}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    <OrderBy
                                                        t={t}
                                                        name='SKU'
                                                        routingOrderName='sku'
                                                        currentOrder={orderBy.sku}
                                                        onOrderClick={(routingOrderName, value) => {this.onOrderByHeaderClicked(routingOrderName, value)}}
                                                    />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    {t('UOM')}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    <OrderBy
                                                        t={t}
                                                        name='Name'
                                                        routingOrderName='name'
                                                        currentOrder={orderBy.name}
                                                        onOrderClick={(routingOrderName, value) => {this.onOrderByHeaderClicked(routingOrderName, value)}}
                                                    />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    <OrderBy
                                                        t={t}
                                                        name='UPC'
                                                        routingOrderName='upc'
                                                        currentOrder={orderBy.upc}
                                                        onOrderClick={(routingOrderName, value) => {this.onOrderByHeaderClicked(routingOrderName, value)}}
                                                    />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    {t('Total')}
                                                    {/*<OrderBy*/}
                                                    {/*    t={t}*/}
                                                    {/*    name='Total'*/}
                                                    {/*    routingOrderName='in_stock'*/}
                                                    {/*    currentOrder={orderBy.in_stock}*/}
                                                    {/*    onOrderClick={(routingOrderName, value) => {this.onOrderByHeaderClicked(routingOrderName, value)}}*/}
                                                    {/*/>*/}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    {t('Reserve')}
                                                    {/*<OrderBy*/}
                                                    {/*    t={t}*/}
                                                    {/*    name='Reserve'*/}
                                                    {/*    routingOrderName='reserved'*/}
                                                    {/*    currentOrder={orderBy.reserved}*/}
                                                    {/*    onOrderClick={(routingOrderName, value) => {this.onOrderByHeaderClicked(routingOrderName, value)}}*/}
                                                    {/*/>*/}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    {t('Available')}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    {t('Status')}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    <OrderBy
                                                        t={t}
                                                        name='UpdatedAt'
                                                        routingOrderName='updated_at'
                                                        currentOrder={orderBy.updated_at}
                                                        onOrderClick={(routingOrderName, value) => {this.onOrderByHeaderClicked(routingOrderName, value)}}
                                                    />
                                                </Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>

                                        <Table.Body>
                                            {list.records.map(i => {
                                                return (<Table.Row key={i.id}>
                                                    <Table.Cell>
                                                        <Link to={{
                                                            pathname: `/inventories/${i.id}/action/view`,
                                                            backTo: {
                                                                page: 'basic',
                                                                link: this.state.routingPath
                                                            }
                                                        }}>{i.type}</Link>
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {i.sku}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {i.base_uom.length > 0 ? i.base_uom[0].name : ""}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {/* {i.name} */}
                                                        {/* {i.name ? i.name.length > 60 ? i.name.substring(0,60) + '...' : i.name : null} */}
                                                        <InventoryDisplay
                                                            tooltipId={`inventory-tooltip-${i.id}`}
                                                            item={i}
                                                        />
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {i.upc}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {i.type === 'Item' ? i.in_stock : 'NA'}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {i.type === 'Item' ? i.reserved : 'NA'}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {i.type === 'Item' ? (i.in_stock - i.reserved) : 'NA'}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {i.is_active ? <Icon name="check" color='green' /> : <Icon name="times" />}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {moment(i.updated_at).format('MMM DD, Y').toString()}
                                                    </Table.Cell>
                                                </Table.Row>)
                                            })}
                                        </Table.Body>
                                    </Table>
                                </div>
                            </div>

                            <div className="row lara-row-margin">
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <Pagination
                                        activePage={on_page}
                                        totalRecords={total}
                                        perPage={per_page}
                                        onPageChange={(e, data) => {
                                            this.onPaginationClicked(e,data);
                                        }}
                                    />
                                </div>
                            </div>
                        </Segment>
                    </div>
                <ExcelUpload
                    displayModal={this.state.excelModalDisplay}
                    onCloseModalClick={() => {
                        this.setState({excelModalDisplay: false})
                    }}
                    onUploaded={() => {
                        this.fetchRecords();
                    }}
                    type='Inventory'
                />
                <ExcelUpload
                    displayModal={this.state.excelUomModalDisplay}
                    onCloseModalClick={() => {
                        this.setState({excelUomModalDisplay: false})
                    }}
                    onUploaded={() => {

                    }}
                    type='InventoryUom'
                />
                <PdfModal
                    showModal={this.state.showPDFModal}
                    onModalClose={() => {
                        this.onPrintClicked(false)
                    }}
                    PdfComponent={InventoryListPDFComponent}
                    list={_.isArray(list.records) ? list.records : []}
                />
                </div>
            </div>
        )
    }
}

InventoryBasicListPage.propTypes = {
    t: PropTypes.func.isRequired,
    history: PropTypes.shape({
       push: PropTypes.func.isRequired
    }).isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.shape({
        params: PropTypes.object.isRequired,
        path: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired
    }),
    queries: PropTypes.array.isRequired,
    fetchPaginatedListBasicView: PropTypes.func.isRequired,
    fetchPredefinedRoutes: PropTypes.func.isRequired,
    list: PropTypes.object.isRequired,
    saveInventoryQuery: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        queries: state.inventoryList.queries,
        list: state.inventoryList.list
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchPaginatedListBasicView,
        fetchPredefinedRoutes,
        saveInventoryQuery
    }, dispatch)
}

export default withI18n()(connect(mapStateToProps, mapDispatchToProps)(InventoryBasicListPage));
