import React, { Component } from 'react';
import { bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import {withI18n} from 'react-i18next';
import PropTypes from 'prop-types';
import {Segment, Loader, Dimmer, Statistic} from 'semantic-ui-react';
import {
    fetchById,
} from '../../actions/inventory';
import PageBreadCrub from '../includes/partials/PageBreadCrub';
import AlertComponent from '../includes/messages/alert';
import SegmentRibbon from '../includes/partials/SegmentRibbon';
import ResourceTextPropertyView from '../includes/partials/ResourceTextPropertyView';
import ResourceBooleanPropertyView from '../includes/partials/ResourceBooleanPropertyView';
import InventoryTabInfo from './InventoryTabInfo';
import TraceComponent from '../includes/partials/TraceComponent';
import Lara from '../../lara';
import LaraPagination from '../../laraPagination';
import PrimaryNaviButton from '../includes/buttons/PrimaryNaviButton';

class InventoryViewPage extends Component {
    constructor() {
        super();
        this.state = {
            breadcrub: [
                {isLink:false, to:null, tag: '', active: true, key: 1}
            ],
            loading: false
        }
    }

    componentDidMount() {
        const {location, match} = this.props;
        const {id} = match.params;
        this.setState({
            loading: true
        });
        this.props.fetchById(id).then(res => {
            this.setState({
                loading: false,
                breadcrub: this.getBreadCrub(location.backTo, res.sku)
            })
        }).catch(err => {
            const errors = Lara.axiosError(err, this.props.t);
            if (errors.http_code === 422) {
                this.setState({
                    errors: errors.message,
                    loading: false
                });
            } else {
                this.setState({
                    loading: false
                });
                Lara.axiosAlert(<AlertComponent
                    support={errors.support}
                    message={errors.message}
                    reference={errors.reference}
                    t={this.props.t}
                />);
            }
            this.setState({loading: false});
        })
    }

    getBreadCrub = (backTo, name) => {
        let backToLink = `/inventories/default/${LaraPagination.inventories()}/list`
        if (_.isObject(backTo)) {
            backToLink = backTo.link
        }
        return [
            {isLink:true, to: backToLink, tag: 'Inventory', active: false, key: 1},
            {isLink:false, to:null, tag: name, active: true, key: 2}
        ]
    }

    getKitAvailableQty = () => {
        const {kits} = this.props.model;
        let availables = [];
        _.each(kits, item => {
            if(_.isObject(item.pivot)) {
                const perKit = parseInt(item.pivot.item_qty, 10);
                const stock = parseInt(item.available);
                if (perKit > 0) {
                    availables.push(Math.floor(stock / perKit));
                }
            }
        });
        if (availables.length === 0) {
            return 0;
        } else {
            return Math.min.apply(Math, availables).toString();
        }
    }

    roundNumber = (value, precision) => {
        var multiplier = Math.pow(10, precision || 0);
        return Math.round(value * multiplier) / multiplier;
    }

    render() {
        const {t, model} = this.props;
        return (
            <div>
                {/* Breadcrub Row */}
                <div className="row">
                    <div className="col-md-8 col-sm-8 col-xs-12 lara-breadcrub">
                        <PageBreadCrub lists={this.state.breadcrub} t={t}/>
                    </div>
                    {model.id && this.props.user.is_main_user ? <div className="col-md-4 col-sm-4 col-xs-12 lara-breadcrub">
                        <TraceComponent
                            trace_id={model.id}
                            trace_type='App\Models\Inventory'
                        />
                    </div> : null}
                </div>

                <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <Segment>
                            <Dimmer inverted active={this.state.loading}>
                                <Loader />
                            </Dimmer>
                            <SegmentRibbon label={model.name ? model.name : "Loading..."} Buttons={
                                () => {return (<React.Fragment>
                                    <PrimaryNaviButton
                                        link={`/inventories/${model.id}/action/edit`}
                                        size='mini'
                                        float='right'
                                        loading={false}
                                        label={t('Edit')}
                                    />
                                </React.Fragment>)}
                            }/>
                            <div className="row">
                                <div className="col-md-9 col-sm-9 col-xs-12">
                                    <div className="row">
                                        <div className="col-md-4 col-sm-4 col-xs-12">
                                            <ResourceTextPropertyView
                                                label="SKU"
                                                value={model.sku} />
                                            <ResourceTextPropertyView
                                                label="Type"
                                                value={model.type} />
                                            <ResourceTextPropertyView
                                                label="Name"
                                                value={model.name} />
                                            <ResourceTextPropertyView
                                                label="UPC"
                                                value={model.upc} />
                                            <ResourceBooleanPropertyView
                                                label="Lot"
                                                value={_.isObject(model.config) ? model.config.lot : false}
                                            />
                                            <ResourceBooleanPropertyView
                                                label="Serial"
                                                value={_.isObject(model.config) ? model.config.serial : false}
                                            />
                                            <ResourceBooleanPropertyView
                                                label="Status"
                                                value={model.is_active}
                                            />
                                        </div>
                                        <div className="col-md-4 col-sm-4 col-xs-12">
                                            <ResourceTextPropertyView
                                                label="Cost"
                                                value={this.roundNumber(model.cost, 2)} />
                                            <ResourceTextPropertyView
                                                label="Selling"
                                                value={this.roundNumber(model.selling, 2)} />
                                            <ResourceTextPropertyView
                                                label="MSRP"
                                                value={this.roundNumber(model.msrp, 2)} />
                                            <ResourceTextPropertyView
                                                label="Barcode_1"
                                                value={model.barcode_1} />
                                            <ResourceTextPropertyView
                                                label="Barcode_2"
                                                value={model.barcode_2} />
                                            <ResourceTextPropertyView
                                                label="CustomizedValueField"
                                                value={model.custom_1} />
                                            <ResourceTextPropertyView
                                                label="CustomizedValueField"
                                                value={model.custom_2} />
                                            <ResourceTextPropertyView
                                                label="CustomizedValueField"
                                                value={model.custom_3} />
                                        </div>
                                        <div className="col-md-4 col-sm-4 col-xs-12">
                                            <ResourceTextPropertyView
                                                label="HSCODE"
                                                value={_.isObject(model.international) ? model.international.hs_code : null} />
                                            <ResourceTextPropertyView
                                                label="CustomValue"
                                                value={_.isObject(model.international) ? model.international.custom_value : null} />
                                            <ResourceTextPropertyView
                                                label="Origin"
                                                value={_.isObject(model.international) ? model.international.origin : null} />
                                            <ResourceTextPropertyView
                                                label="Composition"
                                                value={_.isObject(model.international) ? model.international.composition : null} />
                                            <ResourceTextPropertyView
                                                label="Available"
                                                value={model.type === 'Item' ? _.toString(model.available) : this.getKitAvailableQty()} />
                                            <ResourceTextPropertyView
                                                label="Reserve"
                                                value={model.type === 'Item' ? _.toString(model.reserved) : 'NA'} />
                                            <ResourceTextPropertyView
                                                label="Total"
                                                value={model.type === 'Item' ? _.toString(model.in_stock) : 'NA'} />
                                        </div>
                                        <div className="col-md-12 col-sm-12 col-xs-12">
                                            <ResourceTextPropertyView
                                                label="Description"
                                                value={model.description} />
                                        </div>
                                    </div>
                                </div>
                                {_.isArray(model.uoms) && model.uoms.length > 0 ? <div className="col-md-3 col-sm-3 col-xs-12">
                                    <div className="row">
                                        {model.uoms.map(uom => {
                                            const isBase = uom.pivot.is_base;
                                            const qtyPerBase = parseFloat(uom.pivot.qty_per_base);
                                            let precision = 1
                                            if (uom.name === "Cubic") {
                                                precision = 2
                                            }
                                            if (isBase) {
                                                return <div className="col-md-12 col-sm-12 col-xs-12 lara-row-margin" key={uom.id}>
                                                    <Statistic color='green'>
                                                        <Statistic.Value>{this.roundNumber(model.available / (qtyPerBase > 0 ? qtyPerBase : 1), precision)}</Statistic.Value>
                                                        <Statistic.Label>{uom.name}</Statistic.Label>
                                                    </Statistic>
                                                </div>
                                            } else {
                                                return <div className="col-md-12 col-sm-12 col-xs-12 lara-row-margin" key={uom.id}>
                                                    <Statistic>
                                                        <Statistic.Value>{this.roundNumber(model.available / (qtyPerBase > 0 ? qtyPerBase : 1), precision)}</Statistic.Value>
                                                        <Statistic.Label >{uom.name}</Statistic.Label>
                                                    </Statistic>
                                                </div>
                                            }
                                        })}
                                    </div>
                                </div> : null}
                            </div>
                        </Segment>
                    </div>
                </div>

                {model.id && !this.state.loading ? <div className="row lara-row-margin">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <Segment>
                            <SegmentRibbon label={model.name ? model.name : "Loading..."} Buttons={null} />
                            <InventoryTabInfo
                                t={t}
                            />
                        </Segment>
                    </div>
                </div> : null}
            </div>
        )
    }
}

InventoryViewPage.propTypes = {
    fetchById: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    history: PropTypes.shape({
       push: PropTypes.func.isRequired
    }).isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired
        }),
        path: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired
    }),
    model: PropTypes.shape({
        id: PropTypes.isRequired,
        config: PropTypes.object.isRequired,
        international: PropTypes.object.isRequired
    })

};

function mapStateToProps(state) {
    return {
        model: state.inventoryModel,
        user: state.system.user
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchById,
    }, dispatch)
}

export default withI18n()(connect(mapStateToProps, mapDispatchToProps)(InventoryViewPage));
