import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Progress
} from 'semantic-ui-react';
import AlertMessage from '../messages/alert';
import {TextArea, Form} from 'semantic-ui-react';
import {api} from '../../../api';
import Lara from '../../../lara';

class FileUploadButton extends Component {
    constructor() {
        super();
        this.state = {
            uploading: false,
            comment: ""
        }
    }
    //access router
    //this.props.history.push('/dashboard')
    handleUploadFile = (event) => {
        this.setState({
            uploading: true
        });
        const data = new FormData();
        data.append('file', event.target.files[0]);
        data.append('type', this.props.type);
        data.append('filable_id', this.props.filable_id);
        data.append('filable_type', this.props.filable_type);
        data.append('comment', this.state.comment);
        api.uploadFile(data).then(res => {
            this.setState({
                uploading: false,
                comment: ""
            });
            this.props.onSuccess(res.data);
        }).catch(err => {
            const errors = Lara.axiosError(err, this.props.t);
            this.setState({
                uploading: false
            });
            Lara.axiosAlert(<AlertMessage 
                support={errors.support}
                message={errors.message}
                reference={errors.reference}
                t={this.props.t}
            />);
        });
    }

    render() {
        const {uploading} = this.state;
        const {comment} = this.props;
        if (uploading) {
            return (
                <div className="row">
                    <div className="col-md-12">
                        <Progress percent={100} active size='tiny' />
                    </div>
                </div>
            );
        } else {
            return (
                <div className="row">
                    {comment ? 
                    <div className="col-md-12">
                        <div className="lara-row-bottom-margin">
                            <Form>
                                <TextArea value={this.state.comment} 
                                    placeholder={this.props.t('Comments')}
                                    onChange={(e, {value}) => {
                                        this.setState({comment: value});
                                    }}
                                />
                            </Form>
                        </div>
                    </div> : null}
                    <div className="lara-upload-btn-wrapper">
                        <button className="lara-upload-btn">{this.props.name}</button>
                        <input type="file" name="myfile" onChange={this.handleUploadFile}/>
                    </div>
                </div>
                
            )
        }
    }
}

FileUploadButton.propTypes = {
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    filable_id: PropTypes.number.isRequired,
    filable_type: PropTypes.string.isRequired,
    onSuccess: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    comment: PropTypes.bool
};


export default FileUploadButton;