import React, { Component } from 'react'
import _ from 'lodash';
import PropTypes from 'prop-types'
import {api} from '../../../api'
import {Search, Label} from 'semantic-ui-react'

class InventorySearchReport extends Component {
    constructor() {
        super();
        this.state ={
            isLoading: false, 
            results: [], 
            value: ''
        }
        this.searchProduct = _.debounce( (query) => {this.searchProductByValue(query)}, 750);
    }
    
    handleResultSelect = (e, { result }) => {
        const { inventory } = result;
        this.setState({ value: result.inventory.sku});
        this.props.onSelected(inventory);
    }

    searchProductByValue = (value) => {
        if (value.length <= 2) {
            return;
        }
        if (_.toInteger(this.props.clientId) <= 0) {
            return;
        }
        api.inventories.selectorReport({
            search: value,
            excepts: this.props.exceptIds,
            cid: this.props.clientId
        }).then( options => {
            this.setState({
                isLoading: false,
                results: options
            })
        }).catch(err => {
            this.setState({
                isLoading: false
            })
        })
    }

    handleSearchChange = (e, {value}) => {
        this.setState({
            value: value,
            isLoading: true
        }, () => {
            this.searchProduct(value);
        });
    }

    _resultRender = (item) => {
        return <div>
            <Label pointing='right' color='violet' size='tiny'>{item.title}</Label>
            <Label size='tiny'>{item.description}</Label>
            <Label size='tiny' color='green'>{item.available} In Stock</Label>
        </div>
    }

    render() {
        const { isLoading, value, results } = this.state
        return (
            <Search
                resultRenderer={this._resultRender}
                className="lara-full-width"
                fluid={true}
                loading={isLoading}
                size='small'
                onResultSelect={this.handleResultSelect}
                onSearchChange={this.handleSearchChange}
                results={results}
                value={value}
            />
        )
    }
}

InventorySearchReport.propTypes = {
    onSelected: PropTypes.func.isRequired,
    exceptIds: PropTypes.array.isRequired,
    canSearchKit: PropTypes.bool.isRequired,
    clientId: PropTypes.string
};

export default InventorySearchReport