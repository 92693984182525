import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {Form, Message} from 'semantic-ui-react';
import AlertComponent from '../includes/messages/alert';
import FormError from '../includes/messages/422';
import {api} from '../../api';
import Lara from '../../lara';
import PrimaryButton from '../includes/buttons/PrimaryButton';

class LocationForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: [],
            loading: false,
            lps: [],
            moveToLoc: "",
            moveError: ""
        }
    }

    componentDidMount() {
        api.locations.getLps(this.props.model.id).then(res => {
            this.setState({
                lps: res.data.licenses
            });
        })
    }

    onFormSubmit = () => {
        const errors = this.validateInput();
        const {model, saveSuccess} = this.props;
        if (_.isEmpty(errors)) {
            this.setState({
                loading: true
            });
            if (model.id) {
                api.locations.update(model.id, model).then(res => {
                    saveSuccess(model);
                }).catch(err => {
                    this.axiosErorros(err);
                    this.setState({
                        loading: false
                    });
                })
            } else {
                api.locations.create(model).then(res => {
                    saveSuccess(res.data.data);
                }).catch(err => {
                    this.axiosErorros(err);
                    this.setState({
                        loading: false
                    });
                })
            }
        } else {
            this.setState({
                errors: errors
            });
        }
    };

    validateInput = () => {
        const {location} = this.props.model;
        const errors = [];
        if (!location) {
            errors.push(this.props.t('LocationIsRequired'));
        }
        return errors;
    };

    axiosErorros = (err) => {
        const errors = Lara.axiosError(err, this.props.t);
        if (errors.http_code === 422) {
            this.setState({
                errors: errors.message,
                loading: false
            });
        } else {
            this.setState({
                loading: false
            });
            Lara.axiosAlert(<AlertComponent
                support={errors.support}
                message={errors.message}
                reference={errors.reference}
                t={this.props.t}
            />);
        }
    }

    onLocationMoveClick = () => {

        if (!this.state.moveToLoc) {
            this.setState({
                moveError: "New bin location cannot be empty"
            })
            return;
        }
        if (String(this.state.moveToLoc.trim()) === String(this.props.model.location)) {
            this.setState({
                moveError: "Cannot move to the same bin"
            })
            return;
        }

        this.setState({
            loading: true,
            moveError: ""
        });
        api.locations.moveEntireLocation(this.props.model.id, this.state.moveToLoc.trim()).then(res => {
            this.setState({
                loading: false,
                lps: [],
                moveToLoc: ""
            })
        }).catch(err => {
            this.setState({
                loading: false,
                moveError: "Cannot move bin location. Is the new location correctly spelled?"
            });
        })
    }

    render() {
        const {model, t, onModelValueChange} = this.props;
        return (
            <div className="row">
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                    <h4>Edit Location</h4>
                    <Form>
                        {!_.isEmpty(this.state.errors) ? (
                            <FormError errors={this.state.errors} />
                        ) : null}
                        <Form.Input
                            value={model.location}
                            label={t("Location")}
                            fluid
                            onChange={(e, {value}) => {
                                onModelValueChange('location', value);
                            }}
                        />
                        <Form.Select
                            value={model.type}
                            label={t("LocationType")}
                            fluid
                            options={[
                                {value: 'P', text: t('Location-P')},
                                {value: 'S', text: t('Location-S')}
                            ]}
                            onChange={(e, {value}) => {
                                onModelValueChange('type', value);
                            }}
                        />
                        <Form.Input
                            value={model.billing_category}
                            label={t("LocationCategory")}
                            fluid
                            onChange={(e, {value}) => {
                                onModelValueChange('billing_category', value);
                            }}
                        />
                        <PrimaryButton
                            size='mini'
                            float='left'
                            label={t('Save')}
                            onClick={() => {this.onFormSubmit()}}
                            loading={this.state.loading}
                        />
                    </Form>
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                    <h4>Licenses</h4>
                    {this.state.lps.length === 0 ? <Message>
                        <p>No licenses currently associated to this bin location.</p>
                    </Message> : null}
                    <div role="list" className="ui list">
                        {this.state.lps.map(i => {
                            return <div role="listitem" className="item" key={i.id}><strong>{i.license}</strong>: {i.client.company}</div>
                        })}
                    </div>
                </div>
                {this.state.lps.length > 0 ? <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                    <h4>Location Change</h4>
                    <Message>
                    <p>This will move all licenses to another location.</p>
                    </Message>
                {this.state.moveError ? <Message negative>
                    <p>{this.state.moveError}</p>
                    </Message> : null}
                    <Form>
                    <Form.Input
                    value={this.state.moveToLoc}
                    label={t('Move To (New Bin Location #)')}
                    onChange={(e, {value}) => {
                    this.setState({moveToLoc: value})
                }}
                    />
                    <PrimaryButton
                    size='mini'
                    float='left'
                    label={t('Move')}
                    onClick={() => {this.onLocationMoveClick()}}
                    loading={this.state.loading}
                    />
                    </Form>
                    </div> : null}
            </div>
        )
    }
}

LocationForm.propTypes = {
    model: PropTypes.object.isRequired,
    saveSuccess: PropTypes.func.isRequired,
    onModelValueChange: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

export default LocationForm
