import React, { Component } from 'react';
import { bindActionCreators} from 'redux';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import {Link} from 'react-router-dom';
import {Form, Header} from 'semantic-ui-react';
import {withI18n} from 'react-i18next';
import validator from 'validator';
import FormError from '../includes/messages/422';
import AlertComponent from '../includes/messages/alert';
import {
    attemptLogin
} from '../../actions/auth';
import Lara from '../../lara';
import AuthButton from '../includes/buttons/AuthButton';
//Updated with lara button
class LoginForm extends Component {
    constructor() {
        super();
        this.state = {
            email: "",
            password: "",
            errors: [

            ],
            loading: false
        };
    }
    
    updateFormValue = (e, value) => {
        this.setState({
            [e.target.name]: value
        });
    }

    submitForm = () => {
        const errors = this.validateInput();
        if (errors.length > 0) {
            this.setState({
                errors
            });
            return null;
        }
        this.setState({
            loading: true
        });
        this.props.attemptLogin(this.state.email, this.state.password).then(res => {
            window.location.reload();
        }).catch(err => {
            const errors = Lara.axiosError(err, this.props.t);
            if (errors.http_code === 422) {
                this.setState({
                    errors: errors.message,
                    loading: false
                });
            } else {
                this.setState({
                    loading: false
                });
                Lara.axiosAlert(<AlertComponent 
                    support={errors.support}
                    message={errors.message}
                    reference={errors.reference}
                    t={this.props.t}
                />);
            }
        });
    }
    
    validateInput = () => {
        const errors = [];
        const {t} = this.props;

        if (!validator.isEmail(this.state.email)) {
            errors.push(t("EmailAddressIsinvalid"));
        }

        if (!this.state.password) {
            errors.push(t("PasswordIsRequired"));
        }

        return errors;
    }

    render() {
        const {email, password, errors, loading} = this.state;
        const {t} = this.props;
        return (
            <Form>
                <Header as='h1' textAlign='center'>
                    {t('3PLLogIn')}
                </Header>
                {errors.length > 0 ? <FormError errors={errors} /> : null}
                <Form.Input 
                    size="large"
                    label={t('Email')} 
                    name="email" 
                    value={email} 
                    onChange={(e, {value}) => {this.updateFormValue(e, value)}}/>
                
                <Form.Input
                    size="large"
                    label={t('Password')} 
                    name="password" 
                    type="password" 
                    value={password} 
                    onChange={(e, {value}) => {this.updateFormValue(e, value)}}/>
                <AuthButton
                    label={t('LogIn')}
                    onClick={() => {
                        this.submitForm()
                    }}
                    loading={loading}
                />
                <div className="lara-right">
                    <Link to='/auth/forget-password'>{t('ForgetPassword')}?</Link>
                </div>
            </Form>
        )
    }
}

LoginForm.propTypes = {
    attemptLogin: propTypes.func.isRequired,
    t: propTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        attemptLogin
    }, dispatch)
}

export default withI18n()(connect(mapStateToProps, mapDispatchToProps)(LoginForm));
//export default translate('translations')(connect(mapStateToProps, mapDispatchToProps)(LoginForm));