import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";
import {withI18n} from 'react-i18next';
import { Form, Segment, Message } from "semantic-ui-react";
import FormError from '../includes/messages/422';
import PageBreadCrub from '../includes/partials/PageBreadCrub';
import AlertComponent from '../includes/messages/alert';
import Lara from '../../lara';
import SegmentRibbon from '../includes/partials/SegmentRibbon';
import ClientFormSelector from '../includes/selectors/ClientFormSelector';
import {
    fetchById,
    updateModelInStore,
    createModelOnServer,
    createNewModelInStore,
    updateModelOnServer
} from '../../actions/integration';
import TimeZoneSelector from '../includes/selectors/TimeZoneSelector';
import PrimaryButton from '../includes/buttons/PrimaryButton';

class ApiEditPage extends Component {
    constructor() {
        super();
        this.state = {
            breadcrub: [
                {isLink:false, to:null, tag: '', active: true, key: 1}
            ],
            errors: [],
            loading: false
        };
    }

    componentDidMount() {
        const {match, t} = this.props;
        const {id} = match.params;
        if (id === 'new') {
            this.props.createNewModelInStore();
            this.setState({
                breadcrub: this.getBreadCrub('Integration', 'Integration', t('Create'), null)
            });
        } else {
            this.setState({
                loading: true
            });
            this.props.fetchById(id).then(res => {
                this.setState({
                    loading: false,
                    breadcrub: this.getBreadCrub(res.name,
                        'Integration',
                        t('Integration'), `/integrations/${res.id}/action/view`)
                })
            }).catch(err => {
                const errors = Lara.axiosError(err, this.props.t);
                if (errors.http_code === 422) {
                    this.setState({
                        errors: errors.message,
                        loading: false
                    });
                } else {
                    this.setState({
                        loading: false
                    });
                    Lara.axiosAlert(<AlertComponent
                        support={errors.support}
                        message={errors.message}
                        reference={errors.reference}
                        t={this.props.t}
                    />);
                }
                this.setState({loading: false});
            })
        }
    }

    getBreadCrub = (name, type, action, viewPageLink) => {
        return [
            {isLink:false, to:null, tag: _.upperFirst(type), active: false, key: 1},
            {isLink:viewPageLink ? true : false, to:viewPageLink, tag: _.upperFirst(name), active: false, key: 2},
            {isLink:false, to:null, tag: action, active: true, key: 3}
        ]
    }

    onFormSubmit = () => {
        const errors = this.validateInput();
        if (_.isEmpty(errors)) {
            this.setState({
                loading: true
            });
            if (this.props.model.id) {
                this.props.updateModelOnServer(this.props.model).then(res => {
                    this.setState({
                        errors: [],
                        loading: false
                    });
                    this.props.history.push(`/integrations/${res.data.data.id}/action/view`);
                }).catch(err => {
                    this.handleHttpError(err);
                });
            } else {
                this.props.createModelOnServer(this.props.model).then(res => {
                    this.setState({
                        errors: [],
                        loading: false
                    });
                    this.props.history.push(`/integrations/${res.data.data.id}/action/view`);
                }).catch(err => {
                    this.handleHttpError(err);
                });
            }
        } else {
            this.setState({
                errors: errors
            });
        }
    };

    handleHttpError = (err) => {
        const errors = Lara.axiosError(err, this.props.t);
        if (errors.http_code === 422) {
            this.setState({
                errors: errors.message,
                loading: false
            });
        } else {
            this.setState({
                loading: false
            });
            Lara.axiosAlert(<AlertComponent
                support={errors.support}
                message={errors.message}
                reference={errors.reference}
                t={this.props.t}
            />);
        }
    }

    validateInput = () => {
        const {model} = this.props;
        const {name, client_id, type} = model;
        const errors = [];
        if (!name) {
            errors.push(this.props.t('NameIsRequired'));
        }
        if (!client_id) {
            errors.push(this.props.t("ClientIsRequired"));
        }
        if (!type) {
            errors.push(this.props.t("PleaseSelectAPartner"));
        }

        if (type === "4") {
            if (!model.access_token || !model.o_auth_token || model.api_version !== 'V1') {
                errors.push(this.props.t('CredentialFieldsAreEmpty'));
            }
        }

        if (type === "5") {
            if (!model.key_1 || !model.key_2 || !model.o_auth_token) {
                errors.push(this.props.t('CredentialFieldsAreEmpty'));
            }
        }

        if (type === "6") {
            if (!model.key_1) {
                errors.push(this.props.t('CredentialFieldsAreEmpty'));
            }
        }

        if (type === "10") {
            if (!model.key_1 || !model.key_2 || !model.key_4) {
                errors.push(this.props.t('CredentialFieldsAreEmpty'));
            }
        }

        if (type === "14") {
            if (!model.key_1 || !model.key_2) {
                errors.push(this.props.t('CredentialFieldsAreEmpty'));
            }
        }
        return errors;
    };

    render() {
        const {model, t, updateModelInStore} = this.props;
        return (
            <div>
                {/* Breadcrub Row */}
                <div className="row">
                    <div className="col-md-12 lara-breadcrub">
                        <PageBreadCrub lists={this.state.breadcrub} t={t}/>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-7 col-sm-7 col-xs-12" style={{paddingRight: 10}}>
                        <Segment>
                            <SegmentRibbon label={model.name ? model.name : t('Form')} Buttons={
                                    () => {return (<React.Fragment>
                                        <PrimaryButton
                                            size='mini'
                                            float='right'
                                            label={t('Save')}
                                            onClick={() => {
                                                this.onFormSubmit()
                                            }}
                                            loading={this.state.loading}
                                        />
                                    </React.Fragment>)}
                                }/>

                            <Form size="mini">
                                {!_.isEmpty(this.state.errors) ? (
                                    <FormError errors={this.state.errors} />
                                ) : null}
                                {model.type === "10" ? <Message>
                                    <Message.Header>
                                        Magento V2
                                    </Message.Header>
                                    <p>
                                        You should set timezone to UTC unless the Rest API has been customized by your client's developers which will
                                        return orders by local time.
                                    </p>
                                </Message> : null}
                                <ClientFormSelector
                                    clients={this.props.clients}
                                    value={model.client_id ? model.client_id : "0"}
                                    onSelected={(value) => {
                                        updateModelInStore('client_id', value)
                                    }}
                                    size='small'
                                    required={true}
                                    label={t('Client')}
                                />
                                <Form.Select
                                    fluid
                                    required={true}
                                    label={t("Partner")}
                                    options={[
                                        {value: '4', text: t('Shopify')},
                                        {value: '5', text: t('WooCommerce')},
                                        {value: '6', text: t('TradeGecko')},
                                        {value: '8', text: t('ShipStation Order')},
                                        {value: '10', text: t('Magento V2')},
                                        {value: '11', text: t('US FBA')},
                                        {value: '14', text: t('3rd Party ShipStation')},
                                        {value: '15', text: t('Zalora')}
                                    ]}
                                    value={model.type}
                                    onChange={(e, {value}) => { updateModelInStore('type', value) }}
                                />
                                <Form.Input
                                    fluid
                                    required={true}
                                    label={t("Name")}
                                    name="name"
                                    value={model.name ? model.name : ""}
                                    onChange={(e) => { updateModelInStore(e.target.name, e.target.value) }}
                                />
                                {model.type === "5" || model.type === "10" ? <TimeZoneSelector
                                    zones={this.props.zones}
                                    value={model.store_time_zone ? model.store_time_zone : ""}
                                    onSelected={(value) => {
                                        updateModelInStore('store_time_zone', value)
                                    }}
                                    label={t('StoreTimeZone')}
                                /> : null}
                                <Form.Checkbox
                                    label={t('SaveNewInventory')}
                                    name="create_new_item"
                                    onChange={(e,data) => { updateModelInStore('create_new_item', data.checked) }}
                                    checked={model.create_new_item ? true : false}
                                />
                                <Form.Checkbox
                                    label={t('AutoProcess')}
                                    name="auto_process"
                                    onChange={(e,data) => { updateModelInStore('auto_process', data.checked) }}
                                    checked={model.auto_process ? true : false}
                                />
                                {model.type === "4" ? <div>
                                    <Form.Input
                                        fluid
                                        required={true}
                                        label="Access Token"
                                        name="access_token"
                                        value={model.access_token ? model.access_token : ""}
                                        onChange={(e) => { updateModelInStore(e.target.name, e.target.value) }}
                                    />
                                    <Form.Input
                                        fluid
                                        required={true}
                                        label="Store URL"
                                        name="o_auth_token"
                                        placeholder="my-store.myshopify.com"
                                        value={model.o_auth_token ? model.o_auth_token : ""}
                                        onChange={(e) => { updateModelInStore(e.target.name, e.target.value) }}
                                    />
                                    <Form.Select
                                        fluid
                                        required={true}
                                        label="API Version"
                                        options={[
                                            {value: 'V1', text: "Shopify Fulfillment Service"}
                                        ]}
                                        value={model.api_version}
                                        onChange={(e, {value}) => { updateModelInStore('api_version', value) }}
                                    />
                                </div> : null}
                                {model.type === "5" ? <div>
                                    <Form.Input
                                        fluid
                                        required={true}
                                        label="Consumer Key"
                                        name="key_1"
                                        value={model.key_1 ? model.key_1 : ""}
                                        onChange={(e) => { updateModelInStore(e.target.name, e.target.value) }}
                                    />
                                    <Form.Input
                                        fluid
                                        required={true}
                                        label="Consumer Secret"
                                        name="key_2"
                                        value={model.key_2 ? model.key_2 : ""}
                                        onChange={(e) => { updateModelInStore(e.target.name, e.target.value) }}
                                    />
                                    <Form.Input
                                        fluid
                                        required={true}
                                        label="Store URL (WC 3.0.x or later)"
                                        name="o_auth_token"
                                        placeholder="https://example.com"
                                        value={model.o_auth_token ? model.o_auth_token : ""}
                                        onChange={(e) => { updateModelInStore(e.target.name, e.target.value) }}
                                    />
                                </div> : null}
                                {model.type === "6" ? <div>
                                    <Form.Input
                                        fluid
                                        required={true}
                                        label="Privileged Token"
                                        name="key_1"
                                        value={model.key_1 ? model.key_1 : ""}
                                        onChange={(e) => { updateModelInStore(e.target.name, e.target.value) }}
                                    />
                                </div> : null}
                                {model.type === "10" ? <div>
                                    <Form.Input
                                        fluid
                                        required={true}
                                        label="Api Access Token"
                                        name="key_1"
                                        value={model.key_1 ? model.key_1 : ""}
                                        onChange={(e) => { updateModelInStore(e.target.name, e.target.value) }}
                                    />
                                    <Form.Input
                                        fluid
                                        required={true}
                                        label="Store URL"
                                        name="key_2"
                                        placeholder="https://www.mystore.com"
                                        value={model.key_2 ? model.key_2 : ""}
                                        onChange={(e) => { updateModelInStore(e.target.name, e.target.value) }}
                                    />
                                    <Form.Input
                                        fluid
                                        required={true}
                                        label="Order Status"
                                        name="key_4"
                                        placeholder="pending,pending_payment,payment_received,invoiced,processing"
                                        value={model.key_4 ? model.key_4 : ""}
                                        onChange={(e) => { updateModelInStore(e.target.name, e.target.value) }}
                                    />
                                </div> : null}
                                {model.type === "14" ? <div>
                                    <Form.Input
                                        fluid
                                        required={true}
                                        label="API Key"
                                        name="key_1"
                                        value={model.key_1 ? model.key_1 : ""}
                                        onChange={(e) => { updateModelInStore(e.target.name, e.target.value) }}
                                    />
                                    <Form.Input
                                        fluid
                                        required={true}
                                        label="API Password"
                                        name="key_2"
                                        value={model.key_2 ? model.key_2 : ""}
                                        onChange={(e) => { updateModelInStore(e.target.name, e.target.value) }}
                                    />
                                </div> : null}
                                {model.type === "15" ? <div>
                                    <Form.Input
                                        fluid
                                        required={true}
                                        label="API Key"
                                        name="key_1"
                                        value={model.key_1 ? model.key_1 : ""}
                                        onChange={(e) => { updateModelInStore(e.target.name, e.target.value) }}
                                    />
                                    <Form.Input
                                        fluid
                                        required={true}
                                        label="API Password"
                                        name="key_2"
                                        value={model.key_2 ? model.key_2 : ""}
                                        onChange={(e) => { updateModelInStore(e.target.name, e.target.value) }}
                                    />
                                    <Form.Input
                                        fluid
                                        required={true}
                                        label="Store Endpoint (Marketplace)"
                                        name="key_4"
                                        placeholder="https://sellercenter-api.zalora.com.hk/"
                                        value={model.key_4 ? model.key_4 : ""}
                                        onChange={(e) => { updateModelInStore(e.target.name, e.target.value) }}
                                    />
                                </div> : null}
                            </Form>
                        </Segment>
                    </div>
                </div>
            </div>

        );
    }
}

ApiEditPage.propTypes = {
    model: PropTypes.object.isRequired,
    updateModelInStore: PropTypes.func.isRequired,
    createModelOnServer: PropTypes.func.isRequired,
    createNewModelInStore: PropTypes.func.isRequired,
    updateModelOnServer: PropTypes.func.isRequired,
    fetchById: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    zones: PropTypes.array.isRequired
};

function mapStateToProps(state) {
    return {
        model: state.apiModel,
        clients: state.system.clients,
        zones: state.system.timezones
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            updateModelInStore,
            createModelOnServer,
            createNewModelInStore,
            updateModelOnServer,
            fetchById
        },
        dispatch
    );
}

export default withI18n()(connect(
    mapStateToProps,
    mapDispatchToProps
)(ApiEditPage));
