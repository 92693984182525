import React, { Component } from "react";
import { bindActionCreators } from "redux";
import Alert from 'react-s-alert';
import { Route, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import LaraHeader from './partials/header';
import LaraSidebar from './partials/siderbar';

class UserLayout extends Component {
    render() {
        const { authenticated, component: Component,config, ...rest } = this.props;
        return (
            <Route
                {...rest}
                render={props =>
                    authenticated ? <div className="lara-container">
                        
                        <div className="lara-content">
                            {config.showSideBar ? <LaraSidebar {...props} /> : null}
        
                            <main className={config.showSideBar ? "lara-content__component" : "lara-content__component-nosidebar"}>
                                <LaraHeader />
                                <div className="lara-component-wrapper">
                                <Component {...props} />
                                </div>
                            </main>
                        </div>
                        <Alert stack={{limit: 3}} />
                    </div> : <Redirect to='/auth/login' />
                }
            />
        );
    }
}

UserLayout.propTypes = {
    authenticated: PropTypes.bool.isRequired,
    component: PropTypes.func.isRequired,
    history: PropTypes.shape({
       push: PropTypes.func.isRequired
    }).isRequired
};

function mapStateToProps(state) {
    return {
        config: state.config
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
    }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserLayout));