import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import update from "immutability-helper";
import _ from "lodash";
import {withI18n} from 'react-i18next';
import { Form, Segment, Modal, Header, Message } from "semantic-ui-react";
import FormError from '../includes/messages/422';
import PageBreadCrub from '../includes/partials/PageBreadCrub';
import AlertComponent from '../includes/messages/alert';
import Lara from '../../lara';
import validator from 'validator';
import SegmentRibbon from '../includes/partials/SegmentRibbon';
import {
    fetchRoleList,
    fetchById,
    updateModelInStore,
    updateModelOnServer,
    createNewModelInStore
} from '../../actions/user';
import PrimaryButton from "../includes/buttons/PrimaryButton";
import LaraPagination from "../../laraPagination";
import {api} from "../../api";

class UserEditPage extends Component {
    constructor() {
        super();
        this.state = {
            breadcrub: [
                {isLink:false, to:null, tag: '', active: true, key: 1}
            ],
            errors: [],
            loading: false,
            new_user_created_modal: false,
            new_user_created: {

            },
            password: {
                password: "",
                password_confirmation: ""
            }
        };
    }

    componentDidMount() {
        const {match, t, model, roles} = this.props;
        const {id} = match.params;
        if (id === 'new') {
            this.props.createNewModelInStore(Lara.models.user());
            this.setState({
                breadcrub: this.getBreadCrub(t('User'), t('Create'), null)
            });
        } else {
            if (_.toString(id) !== _.toString(model.id)) {
                this.setState({
                    loading: true
                });
                this.props.fetchById(id).then(res => {
                    this.setState({
                        loading: false,
                        breadcrub: this.getBreadCrub(res.name, 
                            t('Edit'), `/users/${res.id}/action/view`)
                    })
                }).catch(err => {
                    const errors = Lara.axiosError(err, this.props.t);
                    if (errors.http_code === 422) {
                        this.setState({
                            errors: errors.message,
                            loading: false
                        });
                    } else {
                        this.setState({
                            loading: false
                        });
                        Lara.axiosAlert(<AlertComponent 
                            support={errors.support}
                            message={errors.message}
                            reference={errors.reference}
                            t={this.props.t}
                        />);
                    }
                    this.setState({loading: false});
                })
            } else {
                this.setState({
                    breadcrub: this.getBreadCrub(model.name, 
                        t('Edit'), 
                        `/users/${model.id}/action/view`)
                })
            }
        }

        if (roles.length === 0) {
            this.props.fetchRoleList();
        }
    }

    getBreadCrub = (name, action, viewPageLink) => {
        return [
            {isLink:false, to:null, tag: 'User', active: false, key: 1},
            {isLink:viewPageLink ? true : false, to:viewPageLink, tag: _.upperFirst(name), active: false, key: 2},
            {isLink:false, to:null, tag: action, active: true, key: 3}
        ]
    }

    onConfirmNewUserCreated = () => {
        const {id} = this.state.new_user_created;
        if (id) {
            this.props.history.push(`/users/${id}/action/view`);
        } else {
            this.props.history.push(`/users/${LaraPagination.users(false)}`)
        }
    }

    onFormSubmit = () => {
        const errors = this.validateInput();
        if (_.isEmpty(errors)) {
            this.setState({
                loading: true
            });
            this.props.updateModelOnServer(this.props.model).then(res => {
                this.setState({
                    errors: [],
                    loading: false
                });

                if (this.props.model.id) {
                    this.props.history.push(`/users/${res.id}/action/view`);
                } else {
                    this.setState({
                        new_user_created: res,
                        new_user_created_modal: true
                    })
                }
            }).catch(err => {
                const errors = Lara.axiosError(err, this.props.t);
                if (errors.http_code === 422) {
                    this.setState({
                        errors: errors.message,
                        loading: false
                    });
                } else {
                    this.setState({
                        loading: false
                    });
                    Lara.axiosAlert(<AlertComponent 
                        support={errors.support}
                        message={errors.message}
                        reference={errors.reference}
                        t={this.props.t}
                    />);
                }
            });
        } else {
            this.setState({
                errors: errors
            });
        }
    };

    validateInput = () => {
        const {name, email, is_main_user, client_id, role_id} = this.props.model;
        const errors = [];
        if (!name) {
            errors.push(this.props.t('User name is required'));
        }
        if (!validator.isEmail(email)) {
            errors.push(this.props.t('Email address is invalid'));
        }
        if (is_main_user) {
            if (parseInt(role_id, 10) <= 0) {
                errors.push(this.props.t('Please select a role'));
            }
        } else {
            if (parseInt(client_id, 10) <= 0) {
                errors.push(this.props.t('Please select a client'));
            }
        }
        return errors;
    };

    onUpdatePasswordConfirm = () => {
        const {password} = this.state;
        if (!password.password) {
            return null;
        }
        if (password.password !== password.password_confirmation) {
            return null;
        }
        this.setState({
            loading: true
        });
        api.users.suUpdatePassword(this.props.model.id, this.state.password).then(res => {
            this.setState({
                loading: false,
                password: {
                    password: "",
                    password_confirmation: ""
                }
            })
            Lara.alertSuccess("User Password Updated");
        }).catch(err => {
            const errors = Lara.axiosError(err, this.props.t);
            if (errors.http_code === 422) {
                this.setState({
                    errors: errors.message,
                    loading: false
                });
            } else {
                this.setState({
                    loading: false
                });
                Lara.axiosAlert(<AlertComponent 
                    support={errors.support}
                    message={errors.message}
                    reference={errors.reference}
                    t={this.props.t}
                />);
            }
        })
    }
    
    render() {
        const {model, t, updateModelInStore} = this.props;
        const {clients, roles} = this.props;
        const clientsOptions = [];
        _.each(clients, client => {
            clientsOptions.push({
                key: _.toString(client.id),
                value: _.toString(client.id),
                text: _.toString(client.company)
            });
        });
        const rolesOptions = [{key: "0", value: "0", text: "NA"}];
        _.each(roles, role => {
            rolesOptions.push({
                key: _.toString(role.id),
                value: _.toString(role.id),
                text: _.toString(role.name)
            });
        });
        return (
            <div>
                {/* Breadcrub Row */}
                <div className="row">
                    <div className="col-md-12 lara-breadcrub">
                        <PageBreadCrub lists={this.state.breadcrub} t={t}/>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6 col-sm-6 col-xs-12">
                        <Segment>
                            <SegmentRibbon label={model.name ? model.name : t('Form')} Buttons={
                                    () => {return (<React.Fragment>
                                        <PrimaryButton
                                            size='mini'
                                            float='right'
                                            label={t('Save')}
                                            onClick={() => {this.onFormSubmit()}}
                                            loading={this.state.loading}
                                        />
                                    </React.Fragment>)}
                                }/>
                        
                            <Form size="mini">
                                {!_.isEmpty(this.state.errors) ? (
                                    <FormError errors={this.state.errors} />
                                ) : null}
                                <Form.Group widths='equal'>
                                    <Form.Input 
                                        fluid 
                                        required={true}
                                        label={t("UserName")}
                                        name="name"
                                        value={model.name ? model.name : ""} 
                                        onChange={(e) => { updateModelInStore(e.target.name, e.target.value) }}
                                    />
                                    <Form.Input 
                                        fluid 
                                        required={true}
                                        label={t("Email")}
                                        name="email"
                                        value={model.email ? model.email : ""} 
                                        onChange={(e) => { updateModelInStore(e.target.name, e.target.value) }}
                                    />
                                </Form.Group>
                                <Form.Group widths='equal'>
                                    <Form.Checkbox
                                        label={t('MainUser')}
                                        name="is_main_user"
                                        onChange={(e,data) => { updateModelInStore('is_main_user', data.checked) }}
                                        checked={model.is_main_user}
                                    />
                                </Form.Group>
                                {model.is_main_user ? <Form.Group widths='equal'>
                                    <Form.Select 
                                        label={t('Role')}
                                        options={rolesOptions} 
                                        value={_.toString(model.role_id)} 
                                        onChange={(e, {value}) => {updateModelInStore('role_id', value)}}
                                    />
                                </Form.Group> : <Form.Group widths='equal'>
                                    <Form.Select
                                        disabled={model.id ? true : false}
                                        label={t('Client')}
                                        options={clientsOptions} 
                                        value={_.toString(model.client_id)} 
                                        onChange={(e, {value}) => {updateModelInStore('client_id', value)}}
                                    />
                                </Form.Group>}
                                <Form.Group widths='equal'>
                                    <Form.Checkbox
                                        label={t('Status')}
                                        name="is_active"
                                        onChange={(e,data) => { updateModelInStore('is_active', data.checked) }}
                                        checked={model.is_active}
                                    />
                                </Form.Group>
                            </Form>
                        </Segment>
                    </div>
                    {this.props.me.is_su && this.props.model.id ? <div className="col-md-6 col-sm-6 col-xs-12">
                        <Segment>
                            <SegmentRibbon label={t('Password')} Buttons={null}/>
                            <div className="row">
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                <Message>
                                    <Message.Header>
                                        Change User Password
                                    </Message.Header>
                                    <Message.List>
                                        You are an super administrator. You can change this user's password.
                                    </Message.List>
                                </Message>
                                <Form size="mini">
                                    <Form.Group widths="equal">
                                        <Form.Input
                                            fluid 
                                            required={true}
                                            type="password"
                                            label={t("Password")}
                                            name="password"
                                            value={this.state.password.password} 
                                            onChange={(e) => {this.setState({
                                                password: update(this.state.password, {
                                                    password: {$set: e.target.value}
                                                })
                                            })}}
                                        />
                                        <Form.Input
                                            fluid 
                                            required={true}
                                            error={this.state.password.password !== this.state.password.password_confirmation}
                                            type="password"
                                            label={t("Confirm Password")}
                                            name="password_confirmation"
                                            value={this.state.password.password_confirmation} 
                                            onChange={(e) => {this.setState({
                                                password: update(this.state.password, {
                                                    password_confirmation: {$set: e.target.value}
                                                })
                                            })}}
                                        />
                                    </Form.Group>
                                    <PrimaryButton 
                                        size='mini'
                                        float='right'
                                        label={t('Update')}
                                        onClick={() => {
                                            this.onUpdatePasswordConfirm();
                                        }}
                                        loading={this.state.loading}
                                    />
                                </Form>
                                </div>
                            </div>
                        </Segment>
                    </div> : null}
                    <Modal size="small" 
                        closeOnDimmerClick={false}
                        closeIcon
                        onClose={() => {
                            this.onConfirmNewUserCreated();
                        }}
                        closeOnEscape={true} open={this.state.new_user_created_modal}>
                        <Header icon='user' />
                        <Modal.Content>
                            <div className="row">
                                <div className="col-md-12">
                                    <Message>
                                        <Message.Header>
                                            {t("UserCreatedMessage")}
                                        </Message.Header>
                                        <Message.List>
                                            <Message.Item>{t('Email')}: {this.state.new_user_created.email}</Message.Item>
                                            <Message.Item>{t('Password')}: {this.state.new_user_created.newlyCreatedPassword}</Message.Item>
                                        </Message.List>
                                    </Message>
                                </div>
                            </div>
                        </Modal.Content>
                        <Modal.Actions>
                            <PrimaryButton
                                size='mini'
                                float='right'
                                label={t('OK')}
                                onClick={() => {
                                    this.onConfirmNewUserCreated();
                                }}
                                loading={this.state.loading}
                            />
                            <div className="row">
                                <div className="lara-row-margin"></div>
                            </div>
                            <div className="row">
                                <div className="lara-row-margin"></div>
                            </div>
                        </Modal.Actions>
                    </Modal>
                </div>
            </div>

        );
    }
}

UserEditPage.propTypes = {
    model: PropTypes.object.isRequired,
    updateModelInStore: PropTypes.func.isRequired,
    updateModelOnServer: PropTypes.func.isRequired,
    createNewModelInStore: PropTypes.func.isRequired,
    fetchById: PropTypes.func.isRequired,
    fetchRoleList: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    me: PropTypes.object.isRequired
};

function mapStateToProps(state) {
    return {
        model: state.userModel,
        clients: state.system.clients,
        roles: state.role.list,
        me: state.system.user
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            updateModelInStore,
            updateModelOnServer,
            createNewModelInStore,
            fetchById,
            fetchRoleList
        },
        dispatch
    );
}

export default withI18n()(connect(
    mapStateToProps,
    mapDispatchToProps
)(UserEditPage));