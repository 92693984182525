import i18n from 'i18next';

export default function configI18n (lang) {
    i18n.init({
        resources: {
            en: {
                translations: require('./data/lang-en')
            },
            ch: {
                translations: require('./data/lang-ch')
            }
        },
        lng: lang ? lang : 'en',
        fallbackLng: 'en',
        debug: false,
        ns: ['translations'],
        defaultNS: 'translations',
        keySeparator: false,
        interpolation: {
            escapeValue: false, // not needed for react!!
            formatSeparator: ','
        },
        react: {
            wait: true
        }
    });

    return i18n;
}
