import {
    SET_SERIALS_PAGINATED_LIST,
    SET_SERIAL_MODEL_FROM_SERVER,
    SERIAL_UPDATED_BY_FORM,
    ADD_SINGLE_NUMBER_TO_SERIAL,
    ADD_MANY_NUMBER_TO_SERIAL,
    UPDATE_SINGLE_NUMBER_TO_SERIAL,
    DELETE_SINGLE_NUMBER_TO_SERIAL
} from './types';
import {api} from '../api';
import Lara from '../lara';

export const fetchPaginatedList = (path) => (dispatch) => {
    return api.serials.paginated(path).then(res => {
        dispatch({
            type: SET_SERIALS_PAGINATED_LIST,
            payload: res.data.data
        });
        return res;
    }).catch(err => {throw err});
}

export const setSerialModel = (model) => {
    return {
        type: SET_SERIAL_MODEL_FROM_SERVER,
        payload: model
    }
}

export const fetchById = (id) => (dispatch) => {
    return api.serials.read(id).then(res => {
        dispatch({
            type: SET_SERIAL_MODEL_FROM_SERVER,
            payload: res.data.data
        });
        return res.data.data;
    }).catch(err => {throw err});
}

export const createNewModelInStore = () => {
    return {
        type: SET_SERIAL_MODEL_FROM_SERVER,
        payload: Lara.models.serial()
    }
}

export const updateModelOnServer = (model) => (dispatch) => {
    return api.serials.create(model).then(res => {
        return res;
    });
}

export const updateModelInStore = (field, value) => {
    return {
        type: SERIAL_UPDATED_BY_FORM,
        payload: {field, value}
    }
}

export const batchNumbersGenerated = (data) => {
    return {type: ADD_MANY_NUMBER_TO_SERIAL, payload: data}
}

export const singleSerialCreated = (serial) => {
    return {
        type: ADD_SINGLE_NUMBER_TO_SERIAL,
        payload: serial
    }
}

export const singleSerialUpdated = (serial, index) => {
    return {
        type: UPDATE_SINGLE_NUMBER_TO_SERIAL,
        payload: {
            serial, index
        }
    }
}

export const singleSerialDeleted = (index) => {
    return {
        type: DELETE_SINGLE_NUMBER_TO_SERIAL,
        payload: index
    }
}