//import _ from 'lodash';
import axios from 'axios';
import _ from 'lodash';
import qs from 'qs';
let DOMAIN = 'http://127.0.0.1:3000/api';
let BASE_URI = `${DOMAIN}/v3`;
if (process.env.NODE_ENV === 'production') {
    DOMAIN = '/api';
    BASE_URI = '/api/v3';
}

export const api = {
    auth: {
        attemptLogin: (auth) => {
            return axios.post(`${DOMAIN}/auth/login`, auth);
        },
        boot: () => {
            return axios.get(`${DOMAIN}/auth/guest/boot`);
        },
        forgetPassword: (email) => {
            return axios.post(`${DOMAIN}/auth/forget-password`, {email: email})
        },
        getEmailByToken: (token) => {
            return axios.post(`${DOMAIN}/auth/forget-password/token`, {token: token})
        },
        resetPassword: (data) => {
            return axios.post(`${DOMAIN}/auth/reset-password`, data);
        }
    },
    configurations: {
        createCourier: (model) => {
            return axios.post(`${BASE_URI}/couriers`, model);
        },
        updateCourier: (model) => {
            return axios.put(`${BASE_URI}/couriers/${model.id}`, model);
        },
        createSetting: (name, model) => {
            return axios.post(`${BASE_URI}/settings/${name}`, model);
        },
        updateSetting: (name, model) => {
            return axios.put(`${BASE_URI}/settings/${model.id}/${name}`, model);
        },
        deleteSetting: (name, model) => {
            return axios.delete(`${BASE_URI}/settings/${model.id}/${name}`);
        },
        loadShipStationShippingResource: () => {
            return axios.get(`${BASE_URI}/integrations/shipstation/couriers`);
        },
        loadShippoResource: () => {
            return axios.get(`${BASE_URI}/integrations/shippo/couriers`);
        },
        timezone: () => {
            return axios.get(`${BASE_URI}/system/timezones`);
        }
    },
    system: {
        boot: () => {
            return axios.get(`${BASE_URI}/boot`);
        },
        updatePreference: (data) => {
            return axios.post(`${BASE_URI}/system/config`, data);
        },
        settings: () => {
            return axios.get(`${BASE_URI}/settings`);
        },
        couriers: () => {
            return axios.get(`${BASE_URI}/couriers`);
        },
        profile: (model) => {
            return axios.post(`${BASE_URI}/me/profile`, model);
        },
        apiToken: () => {
            return axios.post(`${BASE_URI}/me/token`, []);
        },
        dashboard: () => {
            return axios.get(`${BASE_URI}/dashboard`);
        },
        y3plMessage: () => {
            return axios.get(`${BASE_URI}/y3pl/message`);
        }
    },
    clients: {
        paginated: (path) => {
            return axios.get(`${BASE_URI}/clients?${path}`);
        },
        read: (id) => {
            return axios.get(`${BASE_URI}/clients/${id}`);
        },
        create: (data) => {
            return axios.post(`${BASE_URI}/clients`, data);
        },
        update: (id, data) => {
            return axios.put(`${BASE_URI}/clients/${id}`, data);
        },
        switch: (id) => {
            return axios.put(`${BASE_URI}/system/client/${id}`);
        },
        statistics: (id) => {
            return axios.get(`${BASE_URI}/clients/${id}/statistics`);
        },
        createWebhook: (clientId, data) => {
            return axios.post(`${BASE_URI}/clients/${clientId}/webhook`, data);
        },
        updateWebhook: (clientId, webhookId, data) => {
            return axios.put(`${BASE_URI}/clients/${clientId}/webhook/${webhookId}`, data);
        },
        deleteWEbhook: (clientId, webhookId) => {
            return axios.delete(`${BASE_URI}/clients/${clientId}/webhook/${webhookId}`);
        },
        testWebhook: (webhookId) => {
            return axios.put(`${BASE_URI}/clients/hook/webhook/${webhookId}/send`, {});
        },
        getWebhookLogs: (webhookId) => {
            return axios.get(`${BASE_URI}/clients/action/webhook/${webhookId}/logs`);
        },
        externalResourceWebhook: (clientId, type) => {
            return axios.get(`${BASE_URI}/clients/${clientId}/webhook?type=${type}`);
        },
        retryWebhook: (traceId) => {
            return axios.put(`${BASE_URI}/clients/action/webhook/${traceId}/retry`)
        }
    },
    relations: {
        paginated: (path) => {
            return axios.get(`${BASE_URI}/relations?${path}`);
        },
        read: (id) => {
            return axios.get(`${BASE_URI}/relations/${id}`);
        },
        create: (data) => {
            return axios.post(`${BASE_URI}/relations`, data);
        },
        update: (id, data) => {
            return axios.put(`${BASE_URI}/relations/${id}`, data);
        },
        selector: (type, search) => {
            return axios.post(`${BASE_URI}/relations/selector`, {type, search}).then(res => {
                const {data} = res.data;
                _.each(data, item => {
                    item.key = item.id;
                    item.title = item.name;
                    if (item.is_active) {
                        item.is_active = 'True';
                    } else {
                        item.is_active = 'False';
                    }
                    if (item.print_instruction) {
                        item.print_instruction = 'True';
                    } else {
                        item.print_instruction = 'False';
                    }
                });
                return data;
            });
        }
    },
    inventories: {
        paginated: (path) => {
            return axios.get(`${BASE_URI}/inventories?${path}`);
        },
        //------Start: July 18, 2020 Update-----//
        paginatedQuickView: (path) => {
            return axios.get(`${BASE_URI}/inventories/list/get?${path}`);
        },
        //------End: July 18, 2020 Update-----//
        read: (id) => {
            return axios.get(`${BASE_URI}/inventories/${id}`);
        },
        create: (data) => {
            return axios.post(`${BASE_URI}/inventories`, data);
        },
        update: (id, data) => {
            return axios.put(`${BASE_URI}/inventories/${id}`, data);
        },
        storeUom: (inventoryId, data) => {
            return axios.post(`${BASE_URI}/inventories/${inventoryId}/uoms`, data);
        },
        deleteUom: (inventoryId, uomId) => {
            return axios.delete(`${BASE_URI}/inventories/${inventoryId}/uoms/${uomId}`)
        },
        storeItemToKit: (kitId, qty, itemId) => {
            return axios.post(`${BASE_URI}/inventories/${kitId}/assemblies`, {
                qty, itemId
            });
        },
        deleteItemFromKit: (kitId, itemId) => {
            return axios.delete(`${BASE_URI}/inventories/${kitId}/assemblies/${itemId}`)
        },
        selector: (data) => {
            return axios.post(`${BASE_URI}/inventories/selector`, data).then(res => {
                const {data} = res.data;
                const options = [];
                _.each(data, item => {
                    let ava = item.available;
                    if (item.type === 'Kit') {
                        //Calculate available quantity for this kit
                        const {kits} = item;
                        let itemInStockArray = [];
                        _.each(kits, kit => {
                            if(_.isObject(kit.pivot)) {
                                const perKit = parseInt(kit.pivot.item_qty, 10);
                                const stock = parseInt(kit.available);
                                if (perKit > 0) {
                                    itemInStockArray.push(Math.floor(stock / perKit));
                                }
                            }
                        })
                        if (itemInStockArray.length > 0) {
                            ava = Math.min.apply(Math, itemInStockArray);
                        }
                    }
                    options.push({
                        id: item.id,
                        key: item.id,
                        title: item.sku,
                        description: item.name,
                        available: ava,
                        inventory: item
                    });
                });
                return options;
            }).catch(err => {throw err});
        },
        selectorReport: (data) => {
            return axios.post(`${BASE_URI}/inventories/selector/report`, data).then(res => {
                const {data} = res.data;
                const options = [];
                _.each(data, item => {
                    let ava = item.available;
                    options.push({
                        id: item.id,
                        key: item.id,
                        title: item.sku,
                        description: item.name,
                        available: ava,
                        inventory: item
                    });
                });
                return options;
            }).catch(err => {throw err});
        },
        move: (data) => {
            return axios.post(`${BASE_URI}/operations/inventories/move`, data);
        },
        adjust: (data) => {
            return axios.post(`${BASE_URI}/operations/inventories/adjust`, data);
        },
        modifyLot: (data) => {
            return axios.post(`${BASE_URI}/operations/inventories/lot/update`, data);
        }
    },
    order: {
        paginated: (path) => {
            return axios.get(`${BASE_URI}/orders?${path}`);
        },
        read: (id) => {
            return axios.get(`${BASE_URI}/orders/${id}`);
        },
        create: (model) => {
            return axios.post(`${BASE_URI}/orders`, model);
        },
        allAllAvailable: () => {
            return axios.get(`${BASE_URI}/orders/utils/availables`);
        },
        update: (model) => {
            return axios.put(`${BASE_URI}/orders/${model.id}`, model);
        },
        fullShip: (id) => {
            return axios.put(`${BASE_URI}/orders/${id}/shipment/full`);
        },
        partialShip: (id, data) => {
            return axios.put(`${BASE_URI}/orders/${id}/shipment/partial`, data);
        },
        cancel: (id) => {
            return axios.delete(`${BASE_URI}/orders/${id}`);
        },
        clone: (id) => {
            return axios.put(`${BASE_URI}/orders/${id}/clone`)
        },
        batch: (ids) => {
            return axios.post(`${BASE_URI}/orders/batch/processing`, {orderIDs: ids})
        },
        shipment: (orderId, shipmentId) => {
            return axios.get(`${BASE_URI}/orders/${orderId}/shipment/${shipmentId}`)
        }
    },
    uploadFile: (data) => {
        return axios.post(`${BASE_URI}/upload/files`, data);
    },
    deleteFile: (file) => {
        return axios.post(`${BASE_URI}/upload/files/delete`, {path: file});
    },
    downloadFile: (file) => {
        return axios.get(`${BASE_URI}/download/files?path=${file}`)
    },
    loadComment: (id, model) => {
        return axios.get(`${BASE_URI}/upload/comments?commentable_id=${id}&commentable_type=${model}`);
    },
    createComment: (data) => {
        return axios.post(`${BASE_URI}/upload/comments`,data);
    },
    preferences: {
        getPaginationPreference: (page, version) => {
            return axios.get(`${BASE_URI}/utility/pagination/${page}?v=${version}`);
        },
        createPaginationPreference: (request) => {
            return axios.post(`${BASE_URI}/utility/pagination`, request);
        },
        deletePaginationPreference: (id) => {
            return axios.delete(`${BASE_URI}/utility/pagination/${id}`);
        },
        loadShippingSystems: () => {
            return axios.get(`${BASE_URI}/integrations/settings/shipping`);
        },
        loadShipStationWarehouses: () => {
            return axios.get(`${BASE_URI}/integrations/shipstation/warehouses`);
        },
        createCompanyShipStation: (data) => {
            return axios.post(`${BASE_URI}/integrations/shipstation`, data);
        },
        recacheCompanyShipStation: () => {
            return axios.post(`${BASE_URI}/integrations/shipstation/cache`, {});
        },
        deleteCompanyShipStation: () => {
            return axios.delete(`${BASE_URI}/integrations/shipstation`);
        },
        createCompanyShippo: (data) => {
            return axios.post(`${BASE_URI}/integrations/shippo`, data);
        },
        recacheCompanyShippo: () => {
            return axios.post(`${BASE_URI}/integrations/shippo/cache`, {});
        },
        deleteCompanyShippo: () => {
            return axios.delete(`${BASE_URI}/integrations/shippo`);
        },
    },
    addresses: {
        list: (relationId) => {
            return axios.get(`${BASE_URI}/relations/${relationId}/addresses`);
        },
        read: (relationId, id) => {
            return axios.get(`${BASE_URI}/relations/${relationId}/addresses/${id}`);
        },
        create: (relationId, data) => {
            return axios.post(`${BASE_URI}/relations/${relationId}/addresses`, data);
        },
        update: (relationId, addressId, data) => {
            return axios.put(`${BASE_URI}/relations/${relationId}/addresses/${addressId}`, data);
        },
        delete: (relationId, addressId) => {
            return axios.delete(`${BASE_URI}/relations/${relationId}/addresses/${addressId}`);
        }
    },
    categoryAndTag: {
        getByType: (type) => {
            return axios.get(`${BASE_URI}/categories/${type}`).then(res => {
                const {data} = res.data;
                const options = [];
                _.each(data, tag => {
                    options.push({
                        value: tag.name,
                        text: tag.name,
                        key: tag.id
                    });
                })
                return options;
            }).catch(err => {throw err})
        },
        attach: (name, resource, resourceId) => {
            return axios.post(`${BASE_URI}/categories/attach`, {
                name, resource, resourceId
            });
        },
        detach: (categoryId, resource, resourceId) => {
            return axios.post(`${BASE_URI}/categories/detach`, {
                categoryId, resource, resourceId
            });
        },
        delete: (id) => {
            return axios.delete(`${BASE_URI}/categories/${id}`);
        }
    },
    users: {
        paginated: (path) => {
            return axios.get(`${BASE_URI}/users?${path}`);
        },
        read: (id) => {
            return axios.get(`${BASE_URI}/users/${id}`);
        },
        create: (data) => {
            return axios.post(`${BASE_URI}/users`, data);
        },
        update: (id, data) => {
            return axios.put(`${BASE_URI}/users/${id}`, data);
        },
        roleList: () => {
            return axios.get(`${BASE_URI}/roles`);
        },
        roleCreate: (data) => {
            return axios.post(`${BASE_URI}/roles`, data);
        },
        roleUpdate: (id, data) => {
            return axios.put(`${BASE_URI}/roles/${id}`,data);
        },
        suUpdatePassword: (id, data) => {
            return axios.put(`${BASE_URI}/users/${id}/password`, data);
        }
    },
    integrations: {
        paginated: (path) => {
            return axios.get(`${BASE_URI}/integrations?${path}`);
        },
        read: (id) => {
            return axios.get(`${BASE_URI}/integrations/${id}`);
        },
        create: (model) => {
            return axios.post(`${BASE_URI}/integrations`, model);
        },
        update: (model, shouldReverify = false) => {
            model.reverfify = shouldReverify;
            return axios.put(`${BASE_URI}/integrations/${model.id}`, model)
        },
        delete: (id) => {
            return axios.delete(`${BASE_URI}/integrations/${id}`);
        },
        importByRef: (id, ref) => {
            return axios.put(`${BASE_URI}/integrations/${id}/import/reference`, {
                reference: ref
            })
        },
        shopifyFulfillmentCtr: (id) => {
            return axios.get(`${BASE_URI}/integrations/${id}/shopify/fulfillment`)
        },
        updateShopifyFulfillmentCtr: (id, key) => {
            return axios.put(`${BASE_URI}/integrations/${id}/shopify/fulfillment`, {key})
        },
        tgFulfillmentCtr: (id) => {
            return axios.get(`${BASE_URI}/integrations/${id}/tg/fulfillment`)
        },
        updateTgfillmentCtr: (id, key) => {
            return axios.put(`${BASE_URI}/integrations/${id}/tg/fulfillment`, {key})
        },
        ssStores: (id) => {
            return axios.get(`${BASE_URI}/integrations/${id}/ss/stores`)
        },
        updateSsStore: (id, key) => {
            return axios.put(`${BASE_URI}/integrations/${id}/ss/stores`, {key})
        },
        fbaInvite: (data) => {
            return axios.post(`${BASE_URI}/fba/invite`, data);
        },
        startInvitationProcess: (token) => {
            return axios.post(`${DOMAIN}/auth/fba/token`, {token: token})
        },
        saveInvitation: (data) => {
            return axios.post(`${DOMAIN}/auth/fba/save`, data)
        },
        installShopifyService: async (id, values) => {
            const {data} = await axios.post(`${BASE_URI}/integrations/${id}/shopify/install-service`, values)
            return data
        },
        removeShopifyService: async (id) => {
            await axios.delete(`${BASE_URI}/integrations/${id}/shopify/remove-service`)
        }
    },
    locations: {
        paginated: (path) => {
            return axios.get(`${BASE_URI}/locations?${path}`);
        },
        read: (id) => {
            return axios.get(`${BASE_URI}/locations/${id}`);
        },
        create: (data) => {
            return axios.post(`${BASE_URI}/locations`, data);
        },
        update: (id, data) => {
            return axios.put(`${BASE_URI}/locations/${id}`, data);
        },
        getLps: (id) => {
            return axios.get(`${BASE_URI}/locations/${id}/licenses`);
        },
        moveEntireLocation: (id, newLoc) => {
            return axios.put(`${BASE_URI}/locations/${id}/move/${newLoc}`, {});
        }
    },
    licenses: {
        paginated: (path) => {
            return axios.get(`${BASE_URI}/licenses?${path}`);
        },
        read: (id) => {
            return axios.get(`${BASE_URI}/licenses/${id}`);
        },
        create: (data) => {
            return axios.post(`${BASE_URI}/licenses`, data);
        },
        update: (id, data) => {
            return axios.put(`${BASE_URI}/licenses/${id}`, data);
        },
        delete: (id) => {
            return axios.delete(`${BASE_URI}/licenses/${id}`);
        },
        batch: (data) => {
            return axios.post(`${BASE_URI}/licenses/batch`, data);
        },
        break: (masterId, data) => {
            return axios.put(`${BASE_URI}/licenses/${masterId}/break`, data);
        },
        move: (data) => {
            return axios.post(`${BASE_URI}/licenses/move`, data);
        },
        inventoryList: (id) => {
            return axios.get(`${BASE_URI}/licenses/${id}/util/inventories`);
        }
    },
    serials: {
        paginated: (path) => {
            return axios.get(`${BASE_URI}/serials?${path}`);
        },
        read: (id) => {
            return axios.get(`${BASE_URI}/serials/${id}`);
        },
        create: (data) => {
            return axios.post(`${BASE_URI}/serials`, data);
        },
        delete: (id) => {
            return axios.delete(`${BASE_URI}/serials/${id}`);
        },
        addSerial: (bucketId, data) => {
            return axios.post(`${BASE_URI}/serials/${bucketId}/serial`, data);
        },
        updateSerial: (bucketId, serialId, data) => {
            return axios.put(`${BASE_URI}/serials/${bucketId}/serial/${serialId}`, data);
        },
        deleteSerial: (bucketId, serialId) => {
            return axios.delete(`${BASE_URI}/serials/${bucketId}/serial/${serialId}`);
        }
    },
    shipments: {
        paginated: (path) => {
            return axios.get(`${BASE_URI}/shipments?${path}`);
        },
        read: (id) => {
            return axios.get(`${BASE_URI}/shipments/${id}`);
        },
        update: (id, field, value) => {
            return axios.put(`${BASE_URI}/shipments/${id}`, {
                field, value
            });
        },
        permanentlyDelete: (id) => {
            return axios.delete(`${BASE_URI}/shipments/${id}`);
        },
        permanentlyDeleteFinished: (id) => {
            return axios.delete(`${BASE_URI}/shipments/${id}/finished/void`);
        },
        searchLpAndLotNumber: (client_id, license, action, inventory_id) => {
            return axios.post(`${BASE_URI}/shipments/fulfill/licenses-lot`, {client_id, license, action, inventory_id});
        },
        searchSnNumber: (inventory_id) => {
            return axios.post(`${BASE_URI}/shipments/fulfill/sn`, {inventory_id});
        },
        searchLotNumber: (inventory_id) => {
            return axios.post(`${BASE_URI}/shipments/fulfill/lot`, {inventory_id});
        },
        updateLineQty: (shipmentId, lineId, qty, update) => {
            return axios.put(`${BASE_URI}/shipments/${shipmentId}/quantity/${lineId}`, {
                quantity: qty,
                shouldUpdateOrderLine: update
            })
        },
        fillAll: (id) => {
            return axios.post(`${BASE_URI}/shipments/${id}/fulfill/all`, {});
        },
        fillLine: (id, data) => {
            return axios.post(`${BASE_URI}/shipments/${id}/fulfill/line`, data)
        },
        deleteFulfillmentLine: (id, lineId, fulfillId) => {
            return axios.delete(`${BASE_URI}/shipments/${id}/line/${lineId}/fulfill/${fulfillId}`)
        },
        pack: (id, data) => {
            return axios.put(`${BASE_URI}/shipments/${id}/utility/pack`, {containers: data});
        },
        rePack: (id) => {
            return axios.delete(`${BASE_URI}/shipments/${id}/utility/rePack`);
        },
        bill: (id) => {
            return axios.get(`${BASE_URI}/shipments/${id}/billing/activities`);
        },
        postBill: (shipmentId, data) => {
            return axios.put(`${BASE_URI}/billing/${shipmentId}/shipment/charge`, data);
        },
        zaloraProviders: (id) => {
            return axios.get(`${BASE_URI}/shipments/${id}/zalora/providers`);
        },
        zaloraConfirmFulfill: (id, data) => {
            return axios.put(`${BASE_URI}/shipments/${id}/zalora/fulfill`, data);
        },
        shippoRate: (data) => {
            return axios.put(`${BASE_URI}/shipments/ship/shippo/rate`, data);
        },
        purchaseLabel: (id, rate) => {
            return axios.put(`${BASE_URI}/shipments/${id}/shippo/purchase`,rate);
        },
        voidLabel: (shippoId) => {
            return axios.delete(`${BASE_URI}/shipments/ship/shippo/void/${shippoId}`);
        },
        sendToShipStation: (id) => {
            return axios.put(`${BASE_URI}/shipments/${id}/ss/push`,{});
        },
        retrieveFromShipStaion: (id) => {
            return axios.get(`${BASE_URI}/shipments/${id}/ss/pull`);
        },
        voidInShipStation: (id) => {
            return axios.delete(`${BASE_URI}/shipments/${id}/ss/delete`);
        },
        batchFinish: (ids, options) => {
            return axios.post(`${BASE_URI}/shipments/action/batch/finish`, {
                ids,options
            })
        },
        get_picking_ticket_data: (id) => {
            return axios.get(`${BASE_URI}/shipments/${id}/util/excel/pt`);
        },
        getAvailableCarrier: (shipment_id) => {
            return axios.get(`${BASE_URI}/shipments/${shipment_id}/util/carrier`);
        },
        updateShipmentCarrier: (shipment_id, data) => {
            return axios.put(`${BASE_URI}/shipments/${shipment_id}/util/carrier`, data);
        }
    },
    flex: {
        getClientCurrency: (clientId) => {
            return axios.get(`${BASE_URI}/customization/${clientId}/currency`);
        },
        requestLabel: (shipmentId, model) => {
            return axios.put(`${BASE_URI}/customization/flex/${shipmentId}/book`, model);
        }
    },
    fba: {
        inboundPlan: (orderId) => {
            return axios.put(`${BASE_URI}/fba/plan/${orderId}`, {});
        },
        getActionHistory: (shipmentId) => {
            return axios.get(`${BASE_URI}/fba/history/${shipmentId}`);
        },
        createShipmentById: (shipmentId) => {
            return axios.put(`${BASE_URI}/fba/confirm/${shipmentId}`, {});
        },
        updateFeedTypeToModelOnly: (shipmentId, feedType) => {
            return axios.put(`${BASE_URI}/fba/feed/${shipmentId}/update`, {type: feedType});
        },
        changeFeedType: (shipmentId, feedType) => {
            return axios.put(`${BASE_URI}/fba/feed/${shipmentId}/change`, {type: feedType});
        },
        submitCartonFeeds: (shipmentId) => {
            return axios.put(`${BASE_URI}/fba/feed/${shipmentId}/submit`, {});
        },
        submitTransportContent: (shipmentId) => {
            return axios.put(`${BASE_URI}/fba/transport/${shipmentId}/submit`, {});
        },
        estimateTransportCost: (shipmentId) => {
            return axios.put(`${BASE_URI}/fba/transport/${shipmentId}/cost`, {});
        },
        cancelCartonFeedsSubmission: (actionId) => {
            return axios.put(`${BASE_URI}/fba/feed/${actionId}/cancel`, {});
        },
        requestTransportCost: (shipmentId) => {
            return axios.put(`${BASE_URI}/fba/transport/${shipmentId}/request`, {});
        },
        confirmTransportRequest: (shipmentId) => {
            return axios.put(`${BASE_URI}/fba/transport/${shipmentId}/confirm`, {});
        },
        voidTransport: (shipmentId) => {
            return axios.put(`${BASE_URI}/fba/transport/${shipmentId}/void`, {});
        },
        markShipmentShipped: (shipmentId) => {
            return axios.put(`${BASE_URI}/fba/shipment/${shipmentId}/complete`, {});
        },
        updateShipment: (shipmentId) => {
            return axios.put(`${BASE_URI}/fba/shipment/${shipmentId}/update`, {});
        },
        checkSubmissionResult: (actionId) => {
            return axios.put(`${BASE_URI}/fba/feed/${actionId}/check`, {});
        }
    },
    billing: {
        items: {
            paginated: (path) => {
                return axios.get(`${BASE_URI}/billing/items?${path}`);
            },
            read: (id) => {
                return axios.get(`${BASE_URI}/billing/items/${id}`);
            },
            create: (data) => {
                return axios.post(`${BASE_URI}/billing/items`, data);
            },
            update: (id, data) => {
                return axios.put(`${BASE_URI}/billing/items/${id}`, data);
            },
            selector: (data) => {
                return axios.post(`${BASE_URI}/billing/items/selector`, data).then(res => {
                    const {data} = res.data;
                    const options = [];
                    _.each(data, item => {
                        options.push({
                            id: item.id,
                            key: item.id,
                            title: item.name,
                            uom: item.uom,
                            item: item
                        });
                    });
                    return options;
                }).catch(err => {throw err});
            }
        },
        activities: {
            paginated: (path) => {
                return axios.get(`${BASE_URI}/billing/activities?${path}`);
            },
            read: (id) => {
                return axios.get(`${BASE_URI}/billing/activities/${id}`);
            },
            create: (data) => {
                return axios.post(`${BASE_URI}/billing/activities`, data);
            },
            update: (id, data) => {
                return axios.put(`${BASE_URI}/billing/activities/${id}`, data);
            },
            associateItem: (activityId, itemId, pivot) => {
                return axios.post(`${BASE_URI}/billing/activities/${activityId}/items/${itemId}`, pivot);
            },
            updateAssociatedItem: (activityId, itemId, pivot) => {
                return axios.put(`${BASE_URI}/billing/activities/${activityId}/items/${itemId}`, pivot);
            },
            loadByClientId: (clientId) => {
                return axios.get(`${BASE_URI}/billing/activities/${clientId}/load`);
            }
        },
        entries: {
            paginated: (path) => {
                return axios.get(`${BASE_URI}/billing/entries?${path}`);
            },
            create: (data) => {
                return axios.post(`${BASE_URI}/billing/entries`, data);
            },
            update: (id, data) => {
                return axios.put(`${BASE_URI}/billing/entries/${id}`, data);
            }
        }
    },
    docs: {
        pdf: (id, url) => {
            return axios.get(`${BASE_URI}${url}?${qs.stringify({ids: id})}`)
        },
        shipmentReportPDF: (id, url) => {
            return axios.get(`${BASE_URI}${url}?id=${id}`)
        },
        operationReport: (data) => {
            return axios.post(`${BASE_URI}/reports/operations`,data);
        },
        billingReport: (data) => {
            return axios.post(`${BASE_URI}/reports/billing`, data);
        }
    },
    tasks: {
        list: (types = []) => {
            return axios.get(`${BASE_URI}/tasks?${qs.stringify(types)}`);
        },
        create: (data) => {
            return axios.post(`${BASE_URI}/tasks`, data);
        },
        delete: (id) => {
            return axios.delete(`${BASE_URI}/tasks/${id}`, {});
        },
        updateParams: (id, params) => {
            return axios.put(`${BASE_URI}/tasks/${id}/params`, {
                params: params
            })
        }
    },
    getTraces: (type, id, page) => {
        return axios.get(`${BASE_URI}/system/trace?traceable_id=${id}&traceable_type=${type}&page=${page}`)
    }
}
