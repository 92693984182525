import React, { Component } from 'react';
import { bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import update from 'immutability-helper';
import {withI18n} from 'react-i18next';
import PropTypes from 'prop-types';
import {Segment, Loader, Dimmer, Label, Modal, Header, Form} from 'semantic-ui-react';
import {
    fetchById,
    updateModelInStore,
    singleSerialCreated,
    singleSerialUpdated,
    singleSerialDeleted
} from '../../actions/serial';
import PageBreadCrub from '../includes/partials/PageBreadCrub';
import AlertComponent from '../includes/messages/alert';
import SegmentRibbon from '../includes/partials/SegmentRibbon';
import ResourceTextPropertyView from '../includes/partials/ResourceTextPropertyView';
import ResourceBooleanView from '../includes/partials/ResourceBooleanPropertyView';
import Lara from '../../lara';
import LaraPagination from '../../laraPagination';
import {api} from '../../api';
import DeleteButton from '../includes/buttons/DeleteButton';
import PrimaryButton from '../includes/buttons/PrimaryButton';

class SerialViewPage extends Component {
    constructor() {
        super();
        this.state = {
            breadcrub: [
                {isLink:false, to:null, tag: '', active: true, key: 1}
            ],
            loading: false,
            editModel: {
                id: null,
                serial: ""
            },
            editIndex: -1
        }
    }

    componentDidMount() {
        const {location, match} = this.props;
        const {id} = match.params;
        this.setState({
            loading: true
        });
        this.props.fetchById(id).then(res => {
            this.setState({
                loading: false,
                breadcrub: this.getBreadCrub(location.backTo, res.lead)
            })
        }).catch(err => {
            this.axiosError(err);
        })
    }

    axiosError = (err) => {
        const errors = Lara.axiosError(err, this.props.t);
        if (errors.http_code === 422) {
            this.setState({
                errors: errors.message,
                loading: false
            });
        } else {
            this.setState({
                loading: false
            });
            Lara.axiosAlert(<AlertComponent 
                support={errors.support}
                message={errors.message}
                reference={errors.reference}
                t={this.props.t}
            />);
        }
        this.setState({loading: false});
    }

    getBreadCrub = (backTo, name) => {
        if (!backTo) {
            backTo = `/serials/${LaraPagination.serials(false)}`;
        }
        return [
            {isLink:true, to: backTo, tag: 'Serial Number', active: false, key: 1},
            {isLink:false, to:null, tag: name, active: true, key: 2}
        ]
    }
    
    onDeleteBucketClick = () => {
        api.serials.delete(this.props.model.id).then(res => {
            this.props.history.push(`/serials/${LaraPagination.serials(false)}`);
        }).catch(err => {
            this.axiosError(err);
            this.setState({
                loading: false
            });
        })
    }

    onDeleteSingleClick = () => {
        const {editIndex, editModel} = this.state;
        const {id, serials} = this.props.model;
        this.setState({
            loading: true
        });
        if (editIndex > -1) {
            api.serials.deleteSerial(id, editModel.id).then(res => {
                if (serials.length === 1) {
                    this.props.history.push(`/serials/${LaraPagination.serials(false)}`);
                } else {
                    this.props.updateModelInStore('units', (serials.length - 1))
                    this.props.singleSerialDeleted(editIndex);
                    this.onCloseModal();
                }
            }).catch(err => {
                this.axiosError(err);
                this.setState({
                    loading: false
                });
            })
        }
    }

    onEditConfirmed = () => {
        const {editIndex, editModel} = this.state;
        if (!editModel.serial) {
            return;
        }
        const {id, serials} = this.props.model;
        this.setState({
            loading: true
        });
        if (editModel.id === 'new') {
            api.serials.addSerial(id, editModel).then(res => {
                const {data} = res.data;
                this.props.singleSerialCreated(data);
                this.props.updateModelInStore('units', (serials.length + 1))
                this.onCloseModal();
            }).catch(err => {
                this.axiosError(err);
                this.setState({
                    loading: false
                });
            })
        } else {
            api.serials.updateSerial(id, editModel.id, editModel).then(res => {
                this.props.singleSerialUpdated(editModel, editIndex);
                this.onCloseModal();
            }).catch(err => {
                this.axiosError(err);
                this.setState({
                    loading: false
                });
            })
        }
    }

    onEditClick = (index, serial) => {
        this.setState({
            editModel: serial,
            editIndex: index
        })
    }

    onCloseModal = () => {
        this.setState({
            editModel: {
                id: null,
                serial: ""
            },
            editIndex: -1,
            loading: false
        })
    }

    render() {
        const {t, model} = this.props;
        return (
            <div>
                {/* Breadcrub Row */}
                <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12 lara-breadcrub">
                        <PageBreadCrub lists={this.state.breadcrub} t={t}/>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <Segment>
                            <Dimmer inverted active={this.state.loading}>
                                <Loader />
                            </Dimmer>
                            <SegmentRibbon label={model.lead ? model.lead : "Loading..."} Buttons={
                                () => {return (<React.Fragment>
                                    {model.used ? null : <DeleteButton
                                        size='mini'
                                        float='right'
                                        label={t('Delete')}
                                        onClick={() => {
                                            this.onDeleteBucketClick();
                                        }}
                                        loading={this.state.loading}
                                    />}
                                </React.Fragment>)}
                            }/>
                            <div className="row">
                                <div className="col-md-4 col-sm-6 col-xs-12">
                                    <ResourceTextPropertyView
                                        label="SKU"
                                        value={model.inventory.sku} />
                                </div>
                                <div className="col-md-4 col-sm-6 col-xs-12">
                                    <ResourceTextPropertyView
                                        label="Name"
                                        value={model.inventory.name} />
                                </div>
                                <div className="col-md-4 col-sm-6 col-xs-12">
                                    <ResourceBooleanView
                                        label="AvailableToUse"
                                        value={!model.used}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4 col-sm-6 col-xs-12">
                                    <ResourceTextPropertyView
                                        label="Lead"
                                        value={model.lead} />
                                </div>
                                <div className="col-md-4 col-sm-6 col-xs-12">
                                    <ResourceTextPropertyView
                                        label="Increment"
                                        value={model.increment} />
                                </div>
                                <div className="col-md-4 col-sm-6 col-xs-12">
                                    <ResourceTextPropertyView
                                        label="UnitCount"
                                        value={model.units} />
                                </div>
                            </div>
                        </Segment>
                    </div>
                </div>

                {model.id ? <div className="row lara-row-margin">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <Segment>
                            <SegmentRibbon label={t("Serial Number")} Buttons={
                                () => {return (<React.Fragment>
                                    {model.used ? null : <PrimaryButton
                                        size='mini'
                                        float='right'
                                        label={t('Add')}
                                        onClick={() => {
                                            this.onEditClick(-1,{id:'new',serial: ""});
                                        }}
                                        loading={false}
                                    />}
                                </React.Fragment>)}
                            }/>
                            {model.serials.map((s,index) => {
                                return (
                                    <Label as='a' key={index} size='small' onClick={() => {
                                        this.onEditClick(index,s);
                                    }}>
                                        {s.serial}
                                    </Label>
                                )
                            })}
                        </Segment>
                    </div>
                </div> : null}

                <Modal size='mini' 
                    closeOnDimmerClick={false}
                    closeIcon
                    onClose={() => {this.onCloseModal()}}
                    closeOnEscape={true} open={this.state.editModel.id ? true : false}>
                    <Header icon='pencil' content='' />
                    <Modal.Content>
                        <Form>
                            <Form.Input value={this.state.editModel.serial}
                                onChange={(e,{value}) => {
                                    this.setState({
                                        editModel: update(this.state.editModel,{'serial': {
                                            $set: value
                                        }})
                                    })
                                }} fluid />
                        </Form>
                        <div className="row">
                        <div className="lara-row-margin"></div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                {this.state.editModel.id !== 'new' ? <DeleteButton 
                                    size='mini'
                                    float='left'
                                    label={t('Delete')}
                                    onClick={() => {
                                        this.onDeleteSingleClick();
                                    }}
                                    loading={false}
                                /> : null}
                                <PrimaryButton
                                    size='mini'
                                    float='right'
                                    label={t('Save')}
                                    onClick={() => {
                                        this.onEditConfirmed();
                                    }}
                                    loading={this.state.loading}
                                />
                            </div>
                        </div>
                        
                    </Modal.Content>
                </Modal>
            </div>
        )
    }
}

SerialViewPage.propTypes = {
    fetchById: PropTypes.func.isRequired,
    updateModelInStore: PropTypes.func.isRequired,
    singleSerialCreated: PropTypes.func.isRequired,
    singleSerialUpdated: PropTypes.func.isRequired,
    singleSerialDeleted: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    history: PropTypes.shape({
       push: PropTypes.func.isRequired
    }).isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired
        }),
        path: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired
    }),
};

function mapStateToProps(state) {
    return {
        model: state.serialModel,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchById,
        updateModelInStore,
        singleSerialCreated,
        singleSerialUpdated,
        singleSerialDeleted
    }, dispatch)
}

export default withI18n()(connect(mapStateToProps, mapDispatchToProps)(SerialViewPage));