import React, { Component } from "react";
import {
    Segment,
    Loader,
    Dimmer,
    List,
    Modal,
    Header,
    Form,
    Button,
    Icon
} from "semantic-ui-react";
import moment from 'moment';
import PropTypes from "prop-types";
import SegmentRibbon from "../../includes/partials/SegmentRibbon";
import _ from "lodash";
import update from 'immutability-helper';
import AlertComponent from "../../includes/messages/alert";
import Lara from "../../../lara";
import { api } from "../../../api";
require('moment-timezone');

class ShippoFulfillment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            quotes: [],
            cartons: [

            ],
            cartonModel: {

            },
            cartonIndex: -1,
            cartonModalOpen: false,
            customs: [

            ],
            customModel: {

            },
            customIndex: -1,
            customModelOpen: false,
            reference: {
                reference_1: "",
                reference_2: ""
            }
        };
    }

    componentDidMount() {
        const {model, homeCountry} = this.props;
        const customs = [];
        if (model.shipping_addr.country_code !== homeCountry) {
            _.each(model.shipment_lines, line => {
                const inventory = line.inventory ? line.inventory : {};
                customs.push({
                    description: inventory.international.composition ? inventory.international.composition : "Merchandise",
                    quantity: line.quantity,
                    value_amount: inventory.international.custom_value ? inventory.international.custom_value : inventory.cost,
                    value_currency: "USD",
                    origin_country: inventory.international.origin ? inventory.international.origin : "CN",
                    tariff_number: inventory.international.hs_code ? inventory.international.hs_code : "",
                    sku_code: inventory.sku
                })
            })
        }
        this.setState({
            customs: customs,
            quotes: [],
            reference: {
                reference_1: model.order.reference,
                reference_2: model.id
            }
        })
    }

    onUsePackClicked = () => {
        const containers = _.cloneDeep(this.props.model.containers);
        this.setState({
            cartons: containers
        })
    }

    onAddCartonClick = (ctn, index) => {
        this.setState({
            cartonModalOpen: true,
            cartonIndex: index,
            cartonModel: ctn ? ctn : {
                id: `_${_.uniqueId()}`,
                weight: 1,
                length: 1,
                width: 1,
                height: 1
            }
        })
    }

    onSaveCartonClick = () => {
        if (this.state.cartonIndex > -1) {
            this.setState({
                cartons: update(this.state.cartons, {
                    [this.state.cartonIndex]: {
                        $set: this.state.cartonModel
                    }
                }),
                cartonModalOpen: false
            })
        } else {
            this.setState({
                cartons: update(this.state.cartons, {
                    $push: [this.state.cartonModel]
                }),
                cartonModalOpen: false
            })
        }
    }

    onRemoveCartonClick = (index) => {
        this.setState({
            cartons: update(this.state.cartons, {
                $splice: [[index, 1]]
            })
        })
    }

    onEditCustomClick = (custom, index) => {
        this.setState({
            customModelOpen: true,
            customIndex: index,
            customModel: custom
        });
    }

    onSaveCustomClick = () => {
        if (this.state.customIndex > -1) {
            this.setState({
                customs: update(this.state.customs, {
                    [this.state.customIndex]: {
                        $set: this.state.customModel
                    }
                }),
                customModelOpen: false
            })
        }
    }

    getTotalUnitCount = () => {
        let quantity = 0;
        _.each(this.props.model.shipment_lines, l => {
            quantity = quantity + l.quantity;
        })
        return quantity;
    }

    handleHttpErrors = (err) => {
        const errors = Lara.axiosError(err, this.props.t);
        if (errors.http_code === 422) {
            this.setState({
                errors: errors.message,
                loading: false
            });
        } else {
            this.setState({
                loading: false
            });
            Lara.axiosAlert(<AlertComponent 
                support={errors.support}
                message={errors.message}
                reference={errors.reference}
                t={this.props.t}
            />);
        }
    }
    
    onGetRateClick = () => {
        const data = {
            address: this.props.model.shipping_addr,
            customs: this.state.customs,
            parcels: this.state.cartons,
            courier: this.props.model.courier,
            reference: this.state.reference,
            unitsCount: this.getTotalUnitCount()
        }

        this.setState({
            loading: true,
            quotes: []
        })
        api.shipments.shippoRate(data).then(res => {
            this.setState({
                loading: false,
                quotes: res.data.data
            })
        }).catch(err => {this.handleHttpErrors(err)})
    }

    onPurchaseClick = (rate) => {
        this.setState({
            loading: true
        });
        api.shipments.purchaseLabel(this.props.model.id, rate).then(res => {
            this.props.shippoPurchased(res.data.data);
            this.setState({
                loading: false
            })
        }).catch(err => {this.handleHttpErrors(err)})
    }

    onVoidLabelClick = (label, index) => {
        this.setState({
            loading: true
        });
        api.shipments.voidLabel(label.id).then(res => {
            this.props.shippoVoided(res.data.data, index);
            this.setState({
                loading: false
            })
        }).catch(err => {this.handleHttpErrors(err)})
    }

    expired = (createdAt) => {
        return moment(createdAt).add(7,'days').format("MMM DD")
    }

    render() {
        const { t, measure } = this.props;
        const length = measure === "metric" ? "CM" : "In";
        const weight = measure === "metric" ? "Kg" : "Lb";
        return (
            <Segment>
                <SegmentRibbon label="Shippo" Buttons={null} />
                <Dimmer inverted active={this.state.loading}>
                    <Loader />
                </Dimmer>
                {this.props.model.shippo.length > 0 ? <div className="row lara-row-bottom-margin">
                    <div className="col-md-12">
                        <Header as='h3'>Labels</Header>
                        <List>
                            {this.props.model.shippo.map((s, index) => {
                                return (
                                    <List.Item key={s.object_id}>
                                        <List.Content>
                                            <List.Header>
                                                {s.status === "SUCCESS" ? 
                                                    <a href={`${this.props.host}/print/shippo/labels/${s.label_url}`}
                                                    rel="noopener noreferrer"
                                                    target='_blank'><Icon name='print' /> Print &nbsp;&nbsp;&nbsp;</a>
                                                : null}
                                                {s.status === 'SUCCESS' ? <span>{s.tracking_number}</span> : 
                                                    <span className='lara-voided-tracking'>{s.tracking_number}</span>}
                                            </List.Header>
                                            <List.Description>
                                                {s.status === 'SUCCESS' ? `Label Expire On ${this.expired(s.created_at)}` : 'Voided'}
                                                &nbsp;&nbsp;
                                                {s.status === 'SUCCESS' ? <span className="lara-clickable lara-editable" 
                                                    onClick={() => {
                                                        this.onVoidLabelClick(s, index)
                                                    }}>
                                                    <Icon name='trash' color='red'/>Void
                                                </span> : null}
                                            </List.Description>
                                        </List.Content>
                                    </List.Item>
                                );
                            })}
                        </List>
                    </div>
                </div> : null}
                <div className="row">
                    {_.isEmpty(this.state.quotes) ? <div className="col-md-12">
                        <Header as='h3'>Packages</Header>
                        <List>
                            {this.state.cartons.map((container, index) => {
                                return (
                                    <List.Item key={index}>
                                        <List.Content>
                                            <List.Header>
                                                Carton {index + 1}
                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                <span className="lara-clickable lara-editable" onClick={() => {
                                                    this.onAddCartonClick(container, index)
                                                }}>
                                                    <Icon name="edit" />
                                                </span>
                                                &nbsp;&nbsp;
                                                <span className="lara-clickable lara-editable" onClick={() => {
                                                    this.onRemoveCartonClick(index)
                                                }}>
                                                    <Icon name='trash' />
                                                </span>
                                            </List.Header>
                                            <List.Description>
                                                {container.weight} {t(weight)}
                                                &nbsp;@&nbsp;
                                                {container.length} X{" "}
                                                {container.width} X{" "}
                                                {container.height} {t(length)}
                                            </List.Description>
                                        </List.Content>
                                    </List.Item>
                                );
                            })}
                            <List.Item>
                                <List.Content>
                                    <List.Header>
                                        <span className="lara-clickable lara-editable" onClick={() => {
                                            this.onAddCartonClick(null, -1)
                                        }}>
                                            <Icon name='plus square' />
                                        </span>
                                        
                                        {_.isEmpty(this.props.model.containers) ? null : <span 
                                            style={{float: 'right'}}
                                            className="lara-clickable lara-editable" onClick={() => {
                                            this.onUsePackClicked()
                                        }}>
                                            Use Pack
                                        </span>}
                                    </List.Header>
                                </List.Content>
                            </List.Item>
                        </List>
                        <Header as='h3'>Customs</Header>
                        {_.isEmpty(this.state.customs) ? "Not Required" : <List>
                            {this.state.customs.map((i, index) => {
                                return (
                                    <List.Item key={index}>
                                        <List.Content>
                                            <List.Header>
                                                {i.sku_code} X {i.quantity}
                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                <span className="lara-clickable lara-editable" onClick={() => {
                                                    this.onEditCustomClick(i, index)
                                                }}>
                                                    <Icon name="edit" />
                                                </span>
                                            </List.Header>
                                            <List.Description>
                                                ${i.value_amount} {i.value_currency}, {i.description}
                                            </List.Description>
                                        </List.Content>
                                    </List.Item>
                                );
                            })}
                        </List>}
                        <Header as='h3'>Reference</Header>
                        <Form size='mini'>
                            <Form.Input 
                                size='mini'
                                label="Reference 1"
                                value={this.state.reference.reference_1}
                                onChange={(e, {value}) => {
                                    this.setState({
                                        reference: update(this.state.reference, {
                                            reference_1: {$set: value}
                                        })
                                    })
                                }}
                            />
                            <Form.Input 
                                size='mini'
                                label="Reference 2"
                                value={this.state.reference.reference_2}
                                onChange={(e, {value}) => {
                                    this.setState({
                                        reference: update(this.state.reference, {
                                            reference_2: {$set: value}
                                        })
                                    })
                                }}
                            />
                        </Form>
                    </div> : <div className="col-md-12 lara-row-margin">
                        <List>
                            {this.state.quotes.map((quote) => {
                                return (
                                    <List.Item key={quote.object_id}>
                                        <List.Content>
                                            <List.Header>
                                                <span className="lara-clickable lara-editable" onClick={() => {
                                                    this.onPurchaseClick(quote)
                                                }}>
                                                    {quote.service}
                                                </span>
                                            </List.Header>
                                            <List.Description>
                                                {t('Cost')}: {quote.cost} <br />
                                                {t('Transit')}: {quote.dates} days
                                            </List.Description>
                                        </List.Content>
                                    </List.Item>
                                );
                            })}
                        </List></div>}
                    <div className="col-md-12 lara-row-margin">
                        <Button 
                            loading={this.state.loading}
                            size='mini'
                            disabled={this.state.loading || _.isEmpty(this.state.cartons) || !_.isEmpty(this.state.quotes)}
                            color='teal'
                            onClick={() => {
                                this.onGetRateClick()
                            }}>
                            {t("GetRates")}    
                        </Button>
                        {this.state.quotes.length > 0 ? <Button
                            floated='right'
                            onClick={() => {
                                this.setState({quotes: []})
                            }}
                            size='mini'
                            color='orange'>{t("ReQuote")}</Button> : null}
                    </div>
                </div>
                <Modal
                    closeOnDimmerClick={false}
                    closeIcon
                    onClose={() => {
                        this.setState({cartonModalOpen: false})
                    }}
                    size='mini'
                    closeOnEscape={true} open={this.state.cartonModalOpen}>
                    <Header icon='pencil' content={null} />
                    <Modal.Content>
                        <Form size='mini'>
                            <Form.Input 
                                size='mini'
                                label={`Weight ${t(weight)}`}
                                type='number'
                                value={this.state.cartonModel.weight}
                                onChange={(e, {value}) => {
                                    this.setState({
                                        cartonModel: update(this.state.cartonModel, {
                                            weight: {$set: value}
                                        })
                                    })
                                }}
                            />
                            <Form.Input 
                                size='mini'
                                label={`Length ${t(length)}`}
                                value={this.state.cartonModel.length}
                                type='number'
                                onChange={(e, {value}) => {
                                    this.setState({
                                        cartonModel: update(this.state.cartonModel, {
                                            length: {$set: value}
                                        })
                                    })
                                }}
                            />
                            <Form.Input 
                                size='mini'
                                label={`Width ${t(length)}`}
                                value={this.state.cartonModel.width}
                                type='number'
                                onChange={(e, {value}) => {
                                    this.setState({
                                        cartonModel: update(this.state.cartonModel, {
                                            width: {$set: value}
                                        })
                                    })
                                }}
                            />
                            <Form.Input 
                                size='mini'
                                label={`Height ${t(length)}`}
                                value={this.state.cartonModel.height}
                                type='number'
                                onChange={(e, {value}) => {
                                    this.setState({
                                        cartonModel: update(this.state.cartonModel, {
                                            height: {$set: value}
                                        })
                                    })
                                }}
                            />
                            <Button size='mini' color='teal' onClick={() => {
                                this.onSaveCartonClick()
                            }}>{t('Save')}</Button>
                        </Form>
                    </Modal.Content>
                </Modal>
                <Modal
                    closeOnDimmerClick={false}
                    closeIcon
                    onClose={() => {
                        this.setState({customModelOpen: false})
                    }}
                    size='mini'
                    closeOnEscape={true} open={this.state.customModelOpen}>
                    <Header icon='pencil' content={null} />
                    <Modal.Content>
                        <Form size='mini'>
                            <Form.Input 
                                size='mini'
                                label={t("Description")}
                                value={this.state.customModel.description}
                                onChange={(e, {value}) => {
                                    this.setState({
                                        customModel: update(this.state.customModel, {
                                            description: {$set: value}
                                        })
                                    })
                                }}
                            />
                            <Form.Input 
                                size='mini'
                                label={t("Origin")}
                                value={this.state.customModel.origin_country}
                                onChange={(e, {value}) => {
                                    this.setState({
                                        customModel: update(this.state.customModel, {
                                            origin_country: {$set: value}
                                        })
                                    })
                                }}
                            />
                            <Form.Input 
                                size='mini'
                                label={`${t("Custom Value")} (USD)`}
                                value={this.state.customModel.value_amount}
                                onChange={(e, {value}) => {
                                    this.setState({
                                        customModel: update(this.state.customModel, {
                                            value_amount: {$set: value}
                                        })
                                    })
                                }}
                            />
                            <Form.Input 
                                size='mini'
                                label={t("HS_CODE")}
                                value={this.state.customModel.tariff_number}
                                onChange={(e, {value}) => {
                                    this.setState({
                                        customModel: update(this.state.customModel, {
                                            tariff_number: {$set: value}
                                        })
                                    })
                                }}
                            />
                            <Button size='mini' color='teal' onClick={() => {
                                this.onSaveCustomClick()
                            }}>{t('Save')}</Button>
                        </Form>
                    </Modal.Content>
                </Modal>
            </Segment>
        );
    }
}

ShippoFulfillment.propTypes = {
    model: PropTypes.shape({
        id: PropTypes.number.isRequired,
        shipping_addr: PropTypes.object.isRequired,
        shippo: PropTypes.array.isRequired,
        containers: PropTypes.array.isRequired,
        courier: PropTypes.object.isRequired,
        shipment_lines: PropTypes.array.isRequired
    }),
    t: PropTypes.func.isRequired,
    measure: PropTypes.string.isRequired,
    homeCountry: PropTypes.string.isRequired,
    shippoPurchased: PropTypes.func.isRequired,
    shippoVoided: PropTypes.func.isRequired,
    server_time: PropTypes.string.isRequired,
    host: PropTypes.string.isRequired
};

export default ShippoFulfillment;
