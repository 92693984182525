import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {Form} from 'semantic-ui-react';
import {api} from '../../../../api';
import PrimaryButton from '../../buttons/PrimaryButton';

class TgFulfillmentLocationSelector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            locations: [
                {value: "0", text: 'Loading...'}
            ]
        }
    }
    componentDidMount() {
        const {apiId} = this.props;
        api.integrations.tgFulfillmentCtr(apiId).then(res => {
            const {data} = res.data;
            data.unshift({value: "0", text: 'Synchronization Disabled'});
            this.setState({
                locations: data
            })
        })
    }
    render() {
        const {onValueChange, label, value, t, onUpdateClick} = this.props; 
        return (
            <Form>
                <Form.Select
                    fluid
                    size='mini'
                    label={label}
                    onChange={(e, {value}) => {
                        onValueChange(value)
                    }}
                    options={this.state.locations}
                    value={value ? value : "0"}
                />
                <PrimaryButton
                    size='mini'
                    float='right'
                    label={t('Update')}
                    onClick={() => {
                        onUpdateClick()
                    }}
                    loading={this.state.loading}
                />
                <div className="row">
                <div className="lara-row-margin"></div>
                </div>
                <div className="row">
                <div className="lara-row-margin"></div>
                </div>
            </Form>
        )
    }
}

TgFulfillmentLocationSelector.propTypes = {
    t: PropTypes.func.isRequired,
    onValueChange: PropTypes.func.isRequired,
    value: PropTypes.any,
    label: PropTypes.string,
    apiId: PropTypes.number.isRequired,
    onUpdateClick: PropTypes.func.isRequired
};

export default TgFulfillmentLocationSelector