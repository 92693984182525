import React, { Component } from 'react'
import _ from 'lodash';
import PropTypes from 'prop-types'
import {api} from '../../../api'
import {Search, Label} from 'semantic-ui-react'

class RelationSelector extends Component {
    constructor() {
        super();
        this.state ={
            isLoading: false, 
            results: [], 
            value: ''
        }
        this.searchRelation = _.debounce( (query) => {this.searchRelationByValue(query)}, 750);
    }
    
    handleResultSelect = (e, { result }) => {
        this.setState({ value: result.title});
        this.props.onSelected(result);
    }

    getRelationType = () => {
        const {orderType} = this.props;
        if (orderType === 'Customer Order' || orderType === 'Customer Return') {
            return 'customer';
        }
        if (orderType === 'Vendor Order' || orderType === 'Vendor Return') {
            return 'vendor'
        }
        return null;
    }

    searchRelationByValue = (value) => {
        if (value.length <= 2) {
            return;
        }

        api.relations.selector(this.getRelationType(), value).then( options => {
            this.setState({
                isLoading: false,
                results: options
            })
        }).catch(err => {
            this.setState({
                isLoading: false
            })
        })
    }

    handleSearchChange = (e, {value}) => {
        this.setState({
            value: value,
            isLoading: true
        }, () => {
            this.searchRelation(value);
        });
    }

    _resultRender = (item) => {
        return <div>
            <Label pointing='right' color='violet' size='tiny'>{item.name}</Label>
            {item.account ? <Label size='tiny' color='teal'>{item.account}</Label> : null}
            {item.email ? <Label size='tiny' color='teal'>{item.email}</Label> : null}
        </div>
    }

    render() {
        const { isLoading, value, results } = this.state
        return (
            <Search
                resultRenderer={this._resultRender}
                className="lara-full-width"
                fluid={true}
                loading={isLoading}
                size='small'
                placeholder={`Start by searching a ${this.getRelationType()}`}
                onResultSelect={this.handleResultSelect}
                onSearchChange={this.handleSearchChange}
                results={results}
                value={value}
            />
        )
    }
}

RelationSelector.propTypes = {
    onSelected: PropTypes.func.isRequired,
    orderType: PropTypes.string.isRequired
};

export default RelationSelector