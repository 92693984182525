import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {Form} from 'semantic-ui-react';

class ShipStationShippoSelector extends Component {    
    render() {
        const {label, options, value, text, selected, 
            onValueSelected, parentValue, shouldHaveParent} = this.props;
        const selectOptions = [{
            value: "",
            text: "Unmapped",
            index: -1
        }];
        if (!shouldHaveParent && _.isArray(options)) {
            _.each(options, o => {
                selectOptions.push({
                    value: _.toString(o[value]),
                    text: o[text],
                    key: _.uniqueId()
                });
            });
        } else {
            if (_.isObject(options)) {
                const subOptions = options[parentValue] ? options[parentValue] : [];
                _.each(subOptions, o => {
                    selectOptions.push({
                        value: _.toString(o[value]),
                        text: o[text],
                        key: _.uniqueId()
                    });
                })
            }
        }

        return (
            <Form.Select 
                fluid
                options={selectOptions}
                value={selected}
                onChange={(e, {value}) => {
                    onValueSelected(value);
                }}
                label={label}
            />
        )
    }
}

ShipStationShippoSelector.propTypes = {
    label: PropTypes.string.isRequired,
    options: PropTypes.any.isRequired,
    value: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    //Parent value and should have parent
    //Use to determine ship station service and package
    //when shipping carrier is ups, the parent value should be ups
    //and shouldHaveParent should be true
    parentValue: PropTypes.string,
    shouldHaveParent: PropTypes.bool.isRequired,
    selected: PropTypes.string,
    onValueSelected: PropTypes.func.isRequired
};

export default ShipStationShippoSelector