import React, { Component } from 'react';
import { bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import {withI18n} from 'react-i18next';
import _ from 'lodash';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import moment from 'moment';
import SegmentRibbon from '../includes/partials/SegmentRibbon';
import PageBreadCrub from '../includes/partials/PageBreadCrub';
import {Form, Segment, Modal, Header} from 'semantic-ui-react';
import AlertComponent from '../includes/messages/alert';
import Lara from '../../lara';
import MultiClientSelector from '../includes/selectors/MultiClientSelector';
import DataGridView from '../includes/partials/DataGridView';
import PDFDisplayCanvas from '../includes/partials/PDFDisplayCanvas';
import {api} from '../../api';
import PrimaryButton from '../includes/buttons/PrimaryButton';
import SecondaryButton from "../includes/buttons/SecondaryButton";
import TaskSegment from "../includes/tasks/TaskSegment";

const ReportMain = [
    {value: 'billing-by-item', text: 'Billing By Item'},
    {value: 'outbound-report', text: 'Outbound Shipment Billing'},
    {value: 'outbound-report-no-summary', text: 'Outbound Shipment Billing (No Summary)'},
    {value: 'default-billing', text: '3PL Billing Report'},
    {value: 'default-billing-with-tax', text: '3PL Billing With Tax'},
    {value: 'receiving-qty-by-period-excel', text: 'Receiving Quantity Analysis'},
    {value: 'shipping-qty-by-period-excel', text: 'Shipping Quantity Analysis'}
];

const columDefs = {
    item: [
        {headerName: "ChargeReference", field: "ChargeReference", sortable:true, filter:true},
        {headerName: "ChargedOn", field: "ChargedOn", sortable:true, filter:true},
        {headerName: "ClientName", field: "ClientName", sortable:true, filter:true},
        {headerName: "Description", field: "Description", sortable:true, filter:true},
        {headerName: "ItemName", field: "ItemName", sortable:true, filter:true},
        {headerName: "QuantityCharged", field: "QuantityCharged", sortable:true, filter:true},
        {headerName: "RateCharged", field: "RateCharged", sortable:true, filter:true},
        {headerName: "UOM", field: "UOM", sortable:true, filter:true},
    ],
    outbound: [
        {headerName: "ShipmentId", field: "ShipmentId", sortable:true, filter:true},
        {headerName: "BillDate", field: "BillDate", sortable:true, filter:true},
        {headerName: "BillItem", field: "BillItem", sortable:true, filter:true},
        {headerName: "ClientName", field: "ClientName", sortable:true, filter:true},
        {headerName: "OrderReference", field: "OrderReference", sortable:true, filter:true},
        {headerName: "Quantity", field: "Quantity", sortable:true, filter:true},
        {headerName: "Rate", field: "Rate", sortable:true, filter:true},
        {headerName: "ShipTo", field: "ShipTo", sortable:true, filter:true},
        {headerName: "ShipmentNumber", field: "ShipmentNumber", sortable:true, filter:true},
        {headerName: "Total", field: "Total", sortable:true, filter:true}
    ],
    default: [
        {headerName: "Activity", field: "Activity", sortable:true, filter:true},
        {headerName: "BillDate", field: "BillDate", sortable:true, filter:true},
        {headerName: "ChargeItem", field: "ChargeItem", sortable:true, filter:true},
        {headerName: "Class", field: "Class", sortable:true, filter:true},
        {headerName: "ClientName", field: "ClientName", sortable:true, filter:true},
        {headerName: "DetailedLine", field: "DetailedLine", sortable:true, filter:true},
        {headerName: "Note", field: "Note", sortable:true, filter:true},
        {headerName: "Quantity", field: "Quantity", sortable:true, filter:true},
        {headerName: "Rate", field: "Rate", sortable:true, filter:true},
        {headerName: "Reference", field: "Reference", sortable:true, filter:true},
        {headerName: "TaxCode", field: "TaxCode", sortable:true, filter:true},
        {headerName: "Total", field: "Total", sortable:true, filter:true},
        {headerName: "UOM", field: "UOM", sortable:true, filter:true}
    ],
    tax_default: [
        {headerName: "Activity", field: "Activity", sortable:true, filter:true},
        {headerName: "BillDate", field: "BillDate", sortable:true, filter:true},
        {headerName: "ChargeItem", field: "ChargeItem", sortable:true, filter:true},
        {headerName: "Class", field: "Class", sortable:true, filter:true},
        {headerName: "ClientName", field: "ClientName", sortable:true, filter:true},
        {headerName: "DetailedLine", field: "DetailedLine", sortable:true, filter:true},
        {headerName: "Note", field: "Note", sortable:true, filter:true},
        {headerName: "Quantity", field: "Quantity", sortable:true, filter:true},
        {headerName: "Rate", field: "Rate", sortable:true, filter:true},
        {headerName: "Reference", field: "Reference", sortable:true, filter:true},
        {headerName: "Total", field: "Total", sortable:true, filter:true},
        {headerName: "TaxRate", field: "TaxRate", sortable: false, filter: false},
        {headerName: "TotalAfterTax", field: "TotalAfterTax", sortable: false, filter: false},
        {headerName: "UOM", field: "UOM", sortable:true, filter:true},
    ],
    receiving_qty: [
        {headerName: "ClientName", field: "ClientName", sortable:true, filter:true},
        {headerName: "ClientAccount", field: "ClientAccount", sortable:true, filter:true},
        {headerName: "StartDate", field: "StartDate", sortable:true, filter:true},
        {headerName: "ToDate", field: "ToDate", sortable:true, filter:true},
        {headerName: "TotalInboundOrders", field: "TotalInboundOrders", sortable:true, filter:true},
        {headerName: "TotalInboundShipments", field: "TotalInboundShipments", sortable:true, filter:true},
        {headerName: "QuantityReceived", field: "QuantityReceived", sortable:true, filter:true}
    ],
    shipping_qty: [
        {headerName: "ClientName", field: "ClientName", sortable:true, filter:true},
        {headerName: "ClientAccount", field: "ClientAccount", sortable:true, filter:true},
        {headerName: "StartDate", field: "StartDate", sortable:true, filter:true},
        {headerName: "ToDate", field: "ToDate", sortable:true, filter:true},
        {headerName: "TotalOutboundOrders", field: "TotalOutboundOrders", sortable:true, filter:true},
        {headerName: "TotalOutboundShipments", field: "TotalOutboundShipments", sortable:true, filter:true},
        {headerName: "QuantityShipped", field: "QuantityShipped", sortable:true, filter:true}
    ]
}

class ClientBillingReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrub: [
                {isLink:false, to:null, tag: 'Report', active: true, key: 1}
            ],
            loading: false,
            model: {
                clients: "",
                name: "default-billing",
                period: "month",
                from_date: moment().startOf('month').format('YYYY-MM-DD'),
                to_date: moment().endOf('month').format('YYYY-MM-DD'),
                format: "excel-visual"
            },
            items: [

            ],
            encodedData: "",
            pdfModal: false,
            grid: {
                columnDefs: [],
                rowData: []
            },
            dataGridModal: false
        }
    }

    setPeriod = (value) => {
        if (value === 'last-month') {
            this.setState({
                model: update(this.state.model, {
                    from_date: {$set: moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD')},
                    to_date: {$set: moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')},
                    period: {$set: value}
                })
            })
        } else if (value === 'last-week') {
            this.setState({
                model: update(this.state.model, {
                    from_date: {$set: moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD')},
                    to_date: {$set: moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD')},
                    period: {$set: value}
                })
            })
        } else {
            this.setState({
                model: update(this.state.model, {
                    from_date: {$set: moment().startOf(value).format('YYYY-MM-DD')},
                    to_date: {$set: moment().endOf(value).format('YYYY-MM-DD')},
                    period: {$set: value}
                })
            })
        }
    }

    handleHttpErrors = (err) => {
        const errors = Lara.axiosError(err, this.props.t);
        this.setState({
            loading: false
        });
        Lara.axiosAlert(<AlertComponent
            support={errors.support}
            message={errors.message}
            reference={errors.reference}
            t={this.props.t}
        />);
    }

    prepDataGrid = (data, name) => {
        let column = null;
        if (name === 'default-billing') {
            column = columDefs.default
        }
        if (name === 'outbound-report' || name === 'outbound-report-no-summary') {
            column = columDefs.outbound
        }
        if (name === 'billing-by-item') {
            column = columDefs.item
        }
        if (name === 'default-billing-with-tax') {
            column = columDefs.tax_default
        }
        if (name === 'receiving-qty-by-period-excel') {
            column = columDefs.receiving_qty
        }
        if (name === 'shipping-qty-by-period-excel') {
            column = columDefs.shipping_qty
        }
        if (!column || _.isEmpty(data)) {
            this.setState({
                loading: false
            })
            Lara.axiosAlert("No record found");
            return null;
        }
        this.setState({
            loading: false,
            grid: {
                columnDefs: column,
                rowData: data
            },
            dataGridModal: true
        })
    }

    onRetrieveDataClick = () => {
        const {model} = this.state;
        if (!model.name) {
            return;
        }
        if (!model.clients) {
            return null;
        }
        this.setState({loading: true});

        api.docs.billingReport(model).then(res => {
            // console.log(res.data.data);
            // return;
            if (model.format === 'excel') {
                if (!_.isEmpty(res.data.data)) {
                    Lara.excel.report(res.data.data, this.getReportName())
                } else {
                    Lara.axiosAlert("No record found");
                }
                this.setState({
                    loading: false
                });
            }
            if (model.format === 'pdf') {
                this.setState({
                    loading: false,
                    pdfModal: true,
                    encodedData: res.data.data
                })
            }
            if (model.format === 'excel-visual') {
                this.prepDataGrid(res.data.data, model.name);
            }
        }).catch(err => {
            this.handleHttpErrors(err);
        });
    }

    getReportName = () => {
        if (!this.state.model.name) {
            return;
        }
        const index = _.findIndex(ReportMain, i => {
            return i.value === this.state.model.name
        })
        return index > -1 ? ReportMain[index].text.replace(/ /g,"_") : "Report"
    }

    onSelectAllClientsClick = () => {
        const {clients} = this.props;
        let id = "";
        _.each(clients, (c, index) => {
            if (index === 0) {
                id = c.id;
            } else {
                id = id + "," + c.id;
            }
        })
        this.setState({
            model: update(this.state.model, {
                clients: {$set: id}
            })
        })
    }

    onReportNameChange = (value) => {
        if (value.includes('excel')) {
            this.setState({
                model: update(this.state.model, {
                    name: {$set: value},
                    format: {$set: 'excel-visual'}
                })
            })
        } else {
            this.setState({
                model: update(this.state.model, {
                    name: {$set: value}
                })
            })
        }
    }

    render() {
        const {t, clients} =this.props;
        const {model} = this.state;
        const reportFormatOptions = [
            {value: 'excel', text: 'Excel'},
            {value: 'excel-visual', text: 'Data Grid'},
            //{value: 'pdf', text: 'PDF'}
        ];
        if (!model.name.includes('excel')) {
            reportFormatOptions.push({
                value: 'pdf', text: 'PDF'
            })
        }

        return (
            <div>
                <div className="row">
                    <div className="col-md-12 lara-breadcrub">
                        <PageBreadCrub lists={this.state.breadcrub} t={t}/>
                    </div>
                </div>
                    <Segment>
                        <SegmentRibbon label={`${t('Report')}`} Buttons={null}/>
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-xs-12">
                                <Form size='mini'>
                                    <MultiClientSelector
                                        t={t}
                                        currentValueString={model.clients}
                                        onFilterSelectChange={(value) => {
                                            this.setState({
                                                model: update(model, {
                                                    clients: {$set: value.join()}
                                                })
                                            })
                                        }}
                                        options={clients}
                                        label="Client"
                                    />
                                    <Form.Group widths='equal'>
                                        <Form.Select
                                            label={t('ReportName')}
                                            required={true}
                                            options={ReportMain}
                                            value={model.name}
                                            onChange={(e,{value}) => {
                                                this.onReportNameChange(value)
                                            }}
                                        />
                                        <Form.Select
                                            label={t('QuickAccessPeriod')}
                                            options={[
                                                {value: 'year', text: 'This Year'},
                                                {value: 'month', text: 'This Month'},
                                                {value: 'quarter', text: 'This Quarter'},
                                                {value: 'week', text: 'This Week'},
                                                {value: 'last-month', text: 'Last Month'},
                                                {value: 'last-week', text: 'Last Week'}
                                            ]}
                                            value={model.period}
                                            onChange={(e,{value}) => {
                                                this.setPeriod(value);
                                            }}
                                        />
                                        <Form.Input
                                            label={t('StartDate')}
                                            type='date'
                                            required={true}
                                            value={model.from_date}
                                            onChange={(e, {value}) => {
                                                this.setState({
                                                    model: update(model, {
                                                        from_date: {$set: value}
                                                    })
                                                })
                                            }}
                                        />
                                        <Form.Input
                                            label={t('EndDate')}
                                            type='date'
                                            required={true}
                                            value={model.to_date}
                                            onChange={(e, {value}) => {
                                                this.setState({
                                                    model: update(model, {
                                                        to_date: {$set: value}
                                                    })
                                                })
                                            }}
                                        />
                                        <Form.Select
                                            label={t('Format')}
                                            options={reportFormatOptions}
                                            value={model.format}
                                            onChange={(e,{value}) => {
                                                this.setState({
                                                    model: update(model, {
                                                        format: {$set: value}
                                                    })
                                                })
                                            }}
                                        />
                                    </Form.Group>
                                    <PrimaryButton
                                        size='mini'
                                        float='left'
                                        label={t('Run')}
                                        onClick={() => {
                                            this.onRetrieveDataClick()
                                        }}
                                        loading={this.state.loading}
                                    />
                                    <SecondaryButton
                                        size="mini"
                                        float="right"
                                        label="All Clients"
                                        onClick={() => {
                                            this.onSelectAllClientsClick();
                                        }}
                                        loading={this.state.loading}
                                    />
                                </Form>
                            </div>
                        </div>
                    </Segment>
                    <TaskSegment fromPage="billing" t={t}/>
                <Modal
                    closeOnDimmerClick={false}
                    closeIcon
                    onClose={() => {
                        this.setState({pdfModal: false})
                    }}
                    size='large'
                    closeOnEscape={true} open={this.state.pdfModal}>
                    <Header icon='file pdf outline' content={null} />
                    <Modal.Content>
                        <PDFDisplayCanvas
                            pdf={this.state.encodedData}
                        />
                    </Modal.Content>
                </Modal>
                <Modal
                    closeOnDimmerClick={false}
                    closeIcon
                    onClose={() => {
                        this.setState({dataGridModal: false})
                    }}
                    size='fullscreen'
                    closeOnEscape={true} open={this.state.dataGridModal}>
                    <Header icon='file excel outline' content={null} />
                    <Modal.Content>
                        <DataGridView
                            columnDefs={this.state.grid.columnDefs}
                            rowData={this.state.grid.rowData}
                        />
                    </Modal.Content>
                </Modal>
            </div>
        )
    }
}

ClientBillingReport.propTypes = {
    t: PropTypes.func.isRequired,
    clients: PropTypes.array
};

function mapStateToProps(state) {
    return {
        user: state.system.user,
        clients: state.system.clients
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({

    }, dispatch)
}

export default withI18n()(connect(mapStateToProps, mapDispatchToProps)(ClientBillingReport))
