import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom';
import Alert from 'react-s-alert';
import {withI18n} from 'react-i18next';
import validator from 'validator';
import {Form, Header} from 'semantic-ui-react';
import FormError from '../includes/messages/422';
import {api} from '../../api';
import AuthButton from '../includes/buttons/AuthButton';
import Lara from '../../lara';
//Updated with lara button
class PasswordResetPage extends Component {
    constructor() {
        super();
        this.state = {
            email: "",
            password: "",
            password_confirmation: "",
            _token: "",
            errors: [

            ],
            loading: false
        };
    }

    componentDidMount() {
        this.props.bootGuest();
        const {params} = this.props.match;
        this.setState({
            _token: params.token
        });
        api.auth.getEmailByToken(params.token).then(res => {
            const {data} = res.data;
            this.setState({
                email: data
            });
            if (!data) {
                this.setState({
                    errors: ["Cannot find your account. Please request a reset again. Your previous request might be expired"]
                })
            }
        }).catch(err => {
            this.setState({
                errors: ["Cannot find your account. Please request a reset again. Your previous request might be expired"]
            })
        })
    }

    submitForm = () => {
        const {email, password, _token} = this.state;
        
        const errors = this.validateInput();
        if (errors.length > 0) {
            this.setState({
                errors: errors
            })
            return null;
        }
        this.setState({
            loading: true
        });
        api.auth.resetPassword({email,password,_token}).then(res => {
            Lara.alertSuccess("Your password is reset. Please login");
            this.setState({
                email: "",
                password: "",
                password_confirmation: "",
                _token: "",
                loading: false
            });
        }).catch(err => {
            Lara.alertSuccess("Cannot reset password: your request is not valid");
            this.setState({
                loading: false
            });
        })
    }

    validateInput = () => {
        const {email, password, password_confirmation} = this.state;
        const errors = [];
        if (!validator.isEmail(email)) {
            errors.push(this.props.t("Email address is invalid"));
        }
        if (!password) {
            errors.push(this.props.t("Password is required"));
        }
        if (password !== password_confirmation) {
            errors.push(this.props.t("TwoPasswordAreNotTheSame"));
        }
        return errors;
    }
    
    render() {
        const {
            name, address, city, postal, state, country,
            login_bg_color, logo, branding_name, branding, home_address
        } = this.props.preference;
        const {t} = this.props;
        const {loading, email, errors, password, password_confirmation} = this.state
        return (
            <div className="auth-container">
                <div className="auth-left-panel">
                    <div className="auth-form">
                        <Form>
                            <Header as='h1' textAlign='center'>
                                {t('Reset Password')}
                            </Header>
                            {errors.length > 0 ? <FormError errors={errors} /> : null}
                            <Form.Input 
                                size="large"
                                label={t('Email')} 
                                name="email" 
                                value={email}
                                readOnly={true}
                                onChange={(e, {value}) => {
                                    this.setState({email: value})
                                }}/>
                            <Form.Input
                                size="large"
                                label={t('Password')} 
                                name="password" 
                                type="password" 
                                value={password} 
                                onChange={(e, {value}) => {
                                    this.setState({password: value})
                                }}/>
                            <Form.Input
                                size="large"
                                label={t('Password Confirmation')} 
                                name="password_confirmation" 
                                type="password" 
                                value={password_confirmation} 
                                onChange={(e, {value}) => {
                                    this.setState({password_confirmation: value})
                                }}/>
                            <AuthButton
                                label={t('Reset Password')}
                                onClick={() => {
                                    this.submitForm()
                                }}
                                loading={loading}
                            />
                            <div className="lara-right">
                                <Link to='/auth/login'>{t('Log In')}</Link>
                            </div>
                        </Form>
                    </div>
                </div>
                <div className="auth-right-panel" style={login_bg_color ? {backgroundColor: login_bg_color} : null}>
                    <div className="auth__branding">
                        {logo ? <div className="auth__text-box">
                            <h1 className="auth-primary">
                                <span>
                                    <img src={logo} alt="Company Logo" className="auth-primary--logo"/>
                                </span>
                                <span className="auth-primary--main">{branding_name}</span>
                                <span className="auth-primary--sub">{branding}</span>
                            </h1>
                        </div> : null}
                    </div>
                    <div className="auth__company-info">
                        {name ? <div>
                            <a href={home_address} className="auth__company-info__link">{name}</a><br />
                        {address}, {city} {state}  {postal}, {country}
                        </div> : null}
                    </div>
                </div>
                <Alert stack={{limit: 3}} />
            </div>
        )
    }
}

PasswordResetPage.propTypes = {
    bootGuest: PropTypes.func.isRequired,
    preference: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    match: PropTypes.shape({
        params: PropTypes.object.isRequired
    })
};

export default withI18n()(PasswordResetPage);