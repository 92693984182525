import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Header } from 'semantic-ui-react';

class AlertMessage extends Component {
    render() {
        const {support, message, reference, t} = this.props;

        return (
            <div>
                {!_.isArray(message) ? <Header sub>{message}</Header> : <ul>
                    {message.map(m => {
                        return (
                            <li id={_.uniqueId()}>{m}</li>
                        )
                    })}
                </ul>}
                {reference ? <p>{t('ErrorRef')} {reference}</p> : null}
                {support ? <p>
                    <a href={support} target="blank">{t('ReadSupport')}</a>
                </p> : null}
            </div>
        )
    }
}

AlertMessage.propTypes = {
    support: PropTypes.string,
    message:PropTypes.string,
    reference:PropTypes.string,
    t: PropTypes.func
};

export default AlertMessage