import React, { Component } from 'react';
import { bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import {withI18n} from 'react-i18next';
import PropTypes from 'prop-types';
import {Segment, Loader, Dimmer} from 'semantic-ui-react';
import {
    fetchById,fetchRoleList
} from '../../actions/user';
import PageBreadCrub from '../includes/partials/PageBreadCrub';
import AlertComponent from '../includes/messages/alert';
import SegmentRibbon from '../includes/partials/SegmentRibbon';
import ResourceTextPropertyView from '../includes/partials/ResourceTextPropertyView';
import ResourceBooleanPropertyView from '../includes/partials/ResourceBooleanPropertyView';
import Lara from '../../lara';
import TraceComponent from '../includes/partials/TraceComponent';
import LaraPagination from '../../laraPagination';
import PrimaryNaviButton from '../includes/buttons/PrimaryNaviButton';

class UserViewPage extends Component {
    constructor() {
        super();
        this.state = {
            breadcrub: [
                {isLink:false, to:null, tag: '', active: true, key: 1}
            ],
            loading: false,
            relationType: null
        }
    }

    componentDidMount() {
        const {location, match, roles} = this.props;
        const {id} = match.params;
        this.setState({
            loading: true
        });
        this.props.fetchById(id).then(res => {
            this.setState({
                loading: false,
                breadcrub: this.getBreadCrub(location.backTo, res.name)
            })
        }).catch(err => {
            const errors = Lara.axiosError(err, this.props.t);
            if (errors.http_code === 422) {
                this.setState({
                    errors: errors.message,
                    loading: false
                });
            } else {
                this.setState({
                    loading: false
                });
                Lara.axiosAlert(<AlertComponent
                    support={errors.support}
                    message={errors.message}
                    reference={errors.reference}
                    t={this.props.t}
                />);
            }
            this.setState({loading: false});
        });
        if (roles.length === 0) {
            this.props.fetchRoleList();
        }
    }

    getBreadCrub = (backTo, name) => {
        if (!backTo) {
            backTo = `/users/${LaraPagination.users(false)}`;
        }
        return [
            {isLink:true, to: backTo, tag: 'User', active: false, key: 1},
            {isLink:false, to:null, tag: name, active: true, key: 2}
        ]
    }

    getClientName = () => {
        const {model, clients} = this.props;
        if (_.toInteger(model.client_id, 10) > 0) {
            const clientIndex = _.findIndex(clients, c => {
                return _.toInteger(model.client_id, 10) === _.toInteger(c.id, 10)
            });
            if (clientIndex > -1) {
                return clients[clientIndex].company;
            }

            return "NA";
        }
        return "Our User";
    }

    getRoleName = () => {
        const {model, roles} = this.props;

        if (model.is_su) {
            return "Super Admin"
        }

        if (_.toInteger(model.role_id, 10) > 0) {
            const roleIndex = _.findIndex(roles, r => {
                return _.toInteger(model.role_id, 10) === _.toInteger(r.id, 10)
            });
            if (roleIndex > -1) {
                return roles[roleIndex].name;
            }

            return "NA";
        } else {
            return "Client User";
        }
    }

    render() {
        const {t, model} = this.props;
        return (
            <div>
                {/* Breadcrub Row */}
                <div className="row">
                    <div className="col-md-8 col-sm-8 col-xs-12 lara-breadcrub">
                        <PageBreadCrub lists={this.state.breadcrub} t={t}/>
                    </div>
                    {model.id && this.props.user.is_main_user ? <div className="col-md-4 col-sm-4 col-xs-12 lara-breadcrub">
                        <TraceComponent
                            trace_id={model.id}
                            trace_type='App\Models\User'
                        />
                    </div> : null}
                </div>

                <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <Segment>
                            <Dimmer inverted active={this.state.loading}>
                                <Loader />
                            </Dimmer>
                            <SegmentRibbon label={model.name ? model.name : "Loading..."} Buttons={
                                () => {return (<React.Fragment>
                                    <PrimaryNaviButton
                                        link={`/users/${model.id}/action/edit`}
                                        size='mini'
                                        float='right'
                                        loading={false}
                                        label={t('Edit')}
                                    />
                                </React.Fragment>)}
                            }/>
                            <div className="row">
                                <div className="col-md-3 col-sm-6 col-xs-12">
                                    <ResourceTextPropertyView
                                        label="Name"
                                        value={model.name} />
                                    <ResourceTextPropertyView
                                        label="Email"
                                        value={model.email} />
                                    <ResourceBooleanPropertyView
                                        label="Status"
                                        value={model.is_active} />
                                </div>
                                <div className="col-md-3 col-sm-6 col-xs-12">
                                    <ResourceTextPropertyView
                                        label="Client"
                                        value={this.getClientName()} />
                                    <ResourceTextPropertyView
                                        label="Role"
                                        value={this.getRoleName()} />
                                    <ResourceTextPropertyView
                                        label="Language"
                                        value={_.toUpper(model.lang)} />
                                </div>
                                <div className="col-md-3 col-sm-6 col-xs-12">
                                </div>
                                <div className="col-md-3 col-sm-6 col-xs-12">
                                </div>
                            </div>
                        </Segment>
                    </div>
                </div>

                {/* {model.id ? <div className="row lara-row-margin">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <Segment>
                            <SegmentRibbon label={t("Address")} Buttons={null} />
                        </Segment>
                    </div>
                </div> : null} */}
            </div>
        )
    }
}

UserViewPage.propTypes = {
    fetchById: PropTypes.func.isRequired,
    fetchRoleList: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    history: PropTypes.shape({
       push: PropTypes.func.isRequired
    }).isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired
        }),
        path: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired
    }),
};

function mapStateToProps(state) {
    return {
        model: state.userModel,
        clients: state.system.clients,
        roles: state.role.list,
        user: state.system.user
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchById,
        fetchRoleList
    }, dispatch)
}

export default withI18n()(connect(mapStateToProps, mapDispatchToProps)(UserViewPage));
