import update from 'immutability-helper';
import {
    SET_SHIPMENT_LINE_TO_FULFILLMENT_MODEL,
    FULFILLMENT_RESOURCE_LICENSES_LOADED,
    FULFILLMENT_RESOURCE_SERIALS_LOADED,
    FULFILLMENT_MODAL_CLOASED,
    FULFILLMENT_MODEL_UPDATED_BY_FORM,
    SHIPMENT_LINE_FULFILLMENT_SAVED,
    FULFILLMENT_RESOURCE_LOTS_LOADED,
    NEW_LOT_ADDED_TO_INBOUND_FULFILLMENT,
    FULFILLMENT_LINE_DELETED
} from '../../actions/types';

export default function fulfillmentModel(state = {
    shipmentLineIndex: -1,
    shipmentLine: {
        id: null
    },
    fulfillmentModel: {
        lineId: null,
        quantity: 1,
        quarantine: false,
        license: "",
        serialId: "",
        lotId: "",
        newLot: {
            lot: "",
            expire: ""
        },
        reference: ""
    },
    resources: {
        license: [],
        serials: [],
        lots: []
    },
    modalOpen: false
}, action = {}) {
    switch(action.type) {
        case SET_SHIPMENT_LINE_TO_FULFILLMENT_MODEL:
            return update(state, {
                shipmentLineIndex: {$set: action.payload.lineIndex},
                shipmentLine: {$set: action.payload.line},
                fulfillmentModel: {$set: action.payload.model},
                modalOpen: {$set: true}
            });

        case FULFILLMENT_RESOURCE_LICENSES_LOADED:
            return update(state, {
                resources: {
                    license: {$set: action.payload}
                }
            });

        case FULFILLMENT_RESOURCE_SERIALS_LOADED:
            return update(state, {
                resources: {
                    serials: {$set: action.payload}
                }
            });

        case FULFILLMENT_RESOURCE_LOTS_LOADED:
            return update(state, {
                resources: {
                    lots: {$set: action.payload}
                }
            });

        case FULFILLMENT_MODAL_CLOASED:
            return {
                shipmentLineIndex: -1,
                shipmentLine: {
                    id: null
                },
                fulfillmentModel: {
                    newLot: {}
                },
                resources: {
                    license: [],
                    serials: [],
                    lots:[]
                },
                modalOpen: false
            }

        case SHIPMENT_LINE_FULFILLMENT_SAVED:
            return update(state, {
                shipmentLine: {
                    fulfillments: {$push: [action.payload.data.appended]},
                },
                fulfillmentModel: {$set: action.payload.resetModel}
            })

        case FULFILLMENT_MODEL_UPDATED_BY_FORM:
            if (action.payload.isNewLot) {
                return update(state, {
                    fulfillmentModel: {
                        newLot: {
                            [action.payload.field]: {
                                $set: action.payload.value
                            }
                        }
                    }
                })
            } else {
                return update(state, {
                    fulfillmentModel: {
                        [action.payload.field]: {$set: action.payload.value}
                    }
                })
            }

        case NEW_LOT_ADDED_TO_INBOUND_FULFILLMENT:
            return update(state, {
                resources: {
                    lots: {$push: [action.payload]}
                }
            })

        case FULFILLMENT_LINE_DELETED:
            return update(state, {
                shipmentLine: {
                    fulfillments: {$splice: [[action.payload.fulfillmentIndex, 1]]}
                }
            })

        default: return state;
    }
}
