import React, { Component } from 'react'
import {
    Message
} from 'semantic-ui-react'
import PDFObject from 'pdfobject'
import PropTypes from 'prop-types'

class PDFDisplay extends Component {
    constructor() {
        super();
        this.state = {
            cannotRender: false
        }
    }
    
    componentDidMount() {
        const {pdf} = this.props;
        const options = {
            height: '700px'
        }
        if(PDFObject.supportsPDFs){
            PDFObject.embed(`data:application/pdf;base64,${pdf}`, "#pdfCanvas", options);
         } else {
            this.setState({
                cannotRender: true
            });
         }
        
    }
    
    render() {
        return (
            <div className="row">
                {this.state.cannotRender ?   <div className="col-md-12">
                    <Message negative>
                        <Message.Header>Cannot display PDF</Message.Header>
                        <p>Your browser does not support displaying this PDF file. 
                            Please use a different browser. You can download 
                            <a href='https://www.google.com/intl/en_ca/chrome/' target="blank"> Google Chrome </a> here.
                        </p>
                    </Message>
                </div> : null}
                {!this.state.cannotRender ? <div className="col-md-12" id="pdfCanvas">

                </div> : null}
            </div>
        )
    }
}

PDFDisplay.propTypes = {
    pdf:PropTypes.string.isRequired
};

export default PDFDisplay