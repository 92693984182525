import axios from "axios";
import store from 'store';
import sjcl from 'sjcl';
import _ from 'lodash';
import {
    TOGGLE_SHOW_SIDE_BAR,
    SET_SUBMENU_TO_SHOW,
    GLOBAL_LOADING_START,
    GLOBAL_LOADING_DONE,
    WORKING_CLIENT_SWITCHED,
    SHIP_STATION_WAREHOUSE_LOADED
} from './types';
import {api} from '../api';

export const setSubMenuToShow = (name, currentValue) => {
    return ({
        type: SET_SUBMENU_TO_SHOW,
        payload: name === currentValue ? null : name
    });
}

export const toggleSiderBarClicked = () => {
    return ({
        type: TOGGLE_SHOW_SIDE_BAR,
        payload: null
    })
}

export const startGlobalLoading = () => {
    return ({
        type: GLOBAL_LOADING_START,
        payload: null
    })
}

export const finishGlobalLoading = () => {
    return ({
        type: GLOBAL_LOADING_DONE,
        payload: null
    })
}

export const switchClient = (clientId) => (dispatch) => {
    return api.clients.switch(clientId).then(res => {
        dispatch({
            type: WORKING_CLIENT_SWITCHED,
            payload: clientId
        });
        return res;
    }).catch(err => {
        throw err;
    });
}

export const setAxiosAuthorizationHeader = (token = null) => {
  if (token) {
    axios.defaults.headers.common.authorization = `Bearer ${token}`;
    axios.defaults.headers.common['Content-Type'] = 'application/json';
  } else {
    delete axios.defaults.headers.common.authorization;
  }
};

export const saveLocalAuth = (auth) => {
    const {jwt, expire, timezone} = auth;
    const hashKey = process.env.REACT_APP_CRYPTO_KEY;
    try {
        const hashed = sjcl.encrypt(hashKey,jwt);
        saveLocalStorage('lara_auth', {
            secret: true,
            token: hashed,
            expire,
            timezone
        });
    } catch (e) {
        saveLocalStorage('lara_auth', {
            secret: false,
            token: jwt,
            expire,
            timezone
        });
    }
    // const {expire, jwt, timezone} = state;
    // const hashKey = process.env.REACT_APP_CRYPTO_KEY;
    // console.log(sjcl.encrypt(hashKey,jwt));
}

export const loadLocalAuth = () => {
    const hashKey = process.env.REACT_APP_CRYPTO_KEY;
    const auth = loadLocalStorage('lara_auth');
    if (_.isObject(auth)) {
        const {secret, token, expire, timezone} = auth;
        if (secret) {
            return {
                jwt: sjcl.decrypt(hashKey, token),
                expire,
                timezone
            }
        } else {
            return {
                jwt: token,
                expire,
                timezone
            }
        }
    }

    return null;
}

export const saveLocalStorage = (name, state) => {
    try {
        store.set(name, state);
    } catch (error) {
        return false;
    }
}

export const deleteLocalStorage = (name) => {
    try {
        return store.remove(name);
    } catch (error) {
        return false;
    }
}

export const loadLocalStorage = (name) => {
    try {
        return store.get(name);
    } catch (error) {
        return null;
    }
}

export const loadShipStationWarehouses = () => (dispatch) => {
    return api.preferences.loadShipStationWarehouses().then(res => {
        dispatch({
            type: SHIP_STATION_WAREHOUSE_LOADED,
            payload: res.data.data
        })
        return res;
    }).catch(err => {
        dispatch({
            type: SHIP_STATION_WAREHOUSE_LOADED,
            payload: []
        })
    })
}