import React from 'react';
import {
    Table, Icon
} from 'semantic-ui-react';
import PropTypes from 'prop-types';

const FulfillmentTable = ({t, fulfillments, onDeleteClick, canDelete}) => (
    <Table compact>
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell>
                    {t("LicensePlate")}
                </Table.HeaderCell>
                <Table.HeaderCell>
                    {t("LotNumber")}
                </Table.HeaderCell>
                <Table.HeaderCell>
                    {t("SerialLead")}
                </Table.HeaderCell>
                <Table.HeaderCell>
                    {t("Quantity")}
                </Table.HeaderCell>
                <Table.HeaderCell>
                    {t("Quarantine")}
                </Table.HeaderCell>
                {canDelete ? <Table.HeaderCell>
                    {t("Delete")}
                </Table.HeaderCell> : null}
            </Table.Row>
        </Table.Header>
        <Table.Body>
                {fulfillments.map((f, index) => {
                    return (
                        <Table.Row key={f.id}>
                            <Table.Cell>
                                {f.licenses ? f.licenses.license : 'NA'}
                            </Table.Cell>
                            <Table.Cell>
                                {f.lots ? f.lots.lot : 'NA'}
                            </Table.Cell>
                            <Table.Cell>
                                {f.serials ? f.serials.lead : 'NA'}
                            </Table.Cell>
                            <Table.Cell>
                                {Math.abs(f.change)}
                            </Table.Cell>
                            <Table.Cell>
                                {f.quarantine ? f.reference ? f.reference : <Icon name="checkmark" /> : <Icon name="times" />}
                            </Table.Cell>
                            {canDelete ? <Table.Cell>
                                <span className="lara-editable lara-clickable" onClick={() => {
                                    onDeleteClick(f, index);
                                }}>
                                    <Icon name="trash alternate" />
                                </span>
                            </Table.Cell> : null}
                        </Table.Row>
                    )
                })}
            </Table.Body>
    </Table>
)

FulfillmentTable.propTypes = {
    t: PropTypes.func.isRequired,
    fulfillments: PropTypes.array.isRequired,
    onDeleteClick: PropTypes.func.isRequired,
    canDelete: PropTypes.bool.isRequired
};


export default FulfillmentTable
