import React from 'react';
import _ from 'lodash';
import {Form} from 'semantic-ui-react';
import PropTypes from 'prop-types';

class PredefinedPackageSelector extends React.PureComponent {
    onValueSelected = (id) => {
        const {packages} = this.props;
        const index = _.findIndex(packages, p => {
            return _.toString(p.id) === id
        });
        if (index > -1) {
            this.props.onPackageSelected(packages[index]);
        } else {
            this.props.onPackageSelected(null);
        }
    }
    
    render() {
        const {label, packages, value} = this.props;
        const options = [{
            value: "0", text: "Use Own Carton"
        }];
        _.each(packages, p => {
            options.push({
                text: p.name,
                value: _.toString(p.id)
            })
        });
        return (
            <Form.Select
                options={options}
                label={label}
                onChange={(e, {value}) => {
                    this.onValueSelected(value);
                }}
                value={value}
                size='mini'
                fluid
                search
            />
        )
    }
}

PredefinedPackageSelector.propTypes = {
    label: PropTypes.string.isRequired,
    packages: PropTypes.array.isRequired,
    onPackageSelected: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired
};

export default PredefinedPackageSelector