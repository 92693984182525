import React, { Component } from 'react';
import { bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import {Tab} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import InventoryBinLocationPane from './Tabs/InventoryBinLocation';
import InventoryCategoryPane from './Tabs/InventoryCategory';
import InventoryKitPane from './Tabs/InventoryKits';
import InventoryUomPane from './Tabs/InventoryUoms';

class InventoryTabInfo extends Component {
    render() {
        const {t, model} = this.props;
        return (
            <Tab 
                menu={{ pointing: true }}
                panes={[
                        model.type === 'Item' ? { menuItem: t('Location'), render: () => <InventoryBinLocationPane
                        lots={model.lot_qty}
                        locations={model.fulfillments_total_change_with_quarantine}
                        requireLot={model.config.lot}
                        model={model}
                        t={t}
                    /> } : null,
                    { menuItem: t('Category'), render: () => <InventoryCategoryPane 
                        categories={model.categories}
                        t={t}
                        id={model.id}
                    /> },
                    { menuItem: t('UOM'), render: () => <InventoryUomPane 
                        uoms={model.uoms}
                        id={model.id}
                        t={t}
                    /> },
                    model.type === 'Kit' ? { menuItem: t('Kits'), render: () => <InventoryKitPane 
                        items={model.kits}
                        id={model.id}
                        t={t}
                    /> } : null
                ]}
            />
        )
    }
}

InventoryTabInfo.propTypes = {
    t: PropTypes.func.isRequired,
    model: PropTypes.shape({
        id: PropTypes.isRequired,
        config: PropTypes.object.isRequired,
        international: PropTypes.object.isRequired,
        uoms: PropTypes.array.isRequired,
        categories: PropTypes.array.isRequired,
        kits: PropTypes.array.isRequired,
        fulfillments_total_change_with_quarantine: PropTypes.array.isRequired
    })
};

function mapStateToProps(state) {
    return {
        model: state.inventoryModel
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(InventoryTabInfo)