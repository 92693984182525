import React, { Component } from 'react';
import { bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import {withI18n} from 'react-i18next';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import {Segment, Loader, Dimmer, Modal, Header, Icon, Table, Message} from 'semantic-ui-react';
import {
    fetchById,
    setEmptyShipmentInStore
} from '../../actions/shipment';
import PageBreadCrub from '../includes/partials/PageBreadCrub';
import AlertComponent from '../includes/messages/alert';
import SegmentRibbon from '../includes/partials/SegmentRibbon';
import SingleCartonPackForm from './components/SingleCartonPackForm';
import Lara from '../../lara';
import {api} from '../../api';
import PrimaryButton from '../includes/buttons/PrimaryButton';

class ShipmentPackPage extends Component {
    constructor() {
        super();
        this.state = {
            error: "",
            breadcrub: [
                {isLink:false, to:null, tag: '', active: true, key: 1}
            ],
            loading: false,
            fulfillmentType: "",
            packModel: [
                
            ],
            addCartonModal: false,
            singleModel: {
                id: "F5118",
                own_number: '',
                weight: 1,
                length: 1,
                width: 1,
                height: 1,
                contents: [
                    
                ]
            }
        }
    }

    componentDidMount() {
        const {match} = this.props;
        const {type, id} = match.params;
        this.setState({
            loading: true
        });
        this.props.fetchById(id).then(res => {
            const {data} = res.data;
            const model = [];
            if (data.packed && _.isArray(data.containers)) {
                data.containers.map(c => {
                    const ctn = {
                        contents: [],
                        height: c.height,
                        id: c.id,
                        length: c.length,
                        own_number: c.own_number ? c.own_number : "",
                        weight: c.weight,
                        width: c.width
                    }
                    c.shipment_lines.map(l => {
                        ctn.contents.push({
                            lineId: l.pivot.line_id,
                            quantity: l.pivot.quantity,
                            expire: l.pivot.expire
                        })
                    })
                    model.push(ctn);
                });
            }
            this.setState({
                packModel: model,
                modelId: id,
                loading: false,
                fulfillmentType: type,
                breadcrub: this.getBreadCrub(id, type, `/f/${type}/${id}`)
            })
        }).catch(err => {
            this.handleHttpErrors(err);
            this.setState({loading: false});
        })
    }

    componentWillUnmount() {
        this.props.setEmptyShipmentInStore();
    }

    getBreadCrub = (name, type, link) => {
        return [
            {isLink:false, to:null, tag: _.replace(type,'-',' '), active: false, key: 1},
            {isLink:true, to:link, tag: name, active: false, key: 2},
            {isLink:false, to:null, tag: 'Pack', active: false, key: 3},
        ]
    }

    handleHttpErrors = (err) => {
        const errors = Lara.axiosError(err, this.props.t);
        if (errors.http_code === 422) {
            this.setState({
                errors: errors.message,
                loading: false
            });
        } else {
            this.setState({
                loading: false
            });
            Lara.axiosAlert(<AlertComponent 
                support={errors.support}
                message={errors.message}
                reference={errors.reference}
                t={this.props.t}
            />);
        }
    }

    onPackageSelected = (predefined) => {
        if (predefined) {
            this.setState({
                singleModel: update(this.state.singleModel, {
                    length: {$set: predefined.length},
                    width: {$set: predefined.width},
                    height: {$set: predefined.height},
                    weight: {$set: predefined.weight},
                })
            }) 
        } else {
            this.setState({
                singleModel: update(this.state.singleModel, {
                    length: {$set: 1},
                    width: {$set: 1},
                    height: {$set: 1},
                    weight: {$set: 1},
                })
            }) 
        }
    }

    onSingleModelValueChange = (field, value) => {
        this.setState({
            singleModel: update(this.state.singleModel, {
                [field]: {$set: value}
            })
        })   
    }

    onAddLineToSingleModelClick = (lineId, quantity, expire) => {
        this.setState({
            singleModel: update(this.state.singleModel, {
                contents: {$push: [{lineId, quantity, expire}]}
            })
        });
    }

    onPackItemLineQtyChange = (newQty, index) => {
        if (parseInt(newQty, 10) > 0) {
            this.setState({
                singleModel: update(this.state.singleModel, {
                    contents: {
                        [index]: {quantity: {
                            $set: newQty
                        }}
                    }
                })
            })
        } else {
            this.setState({
                singleModel: update(this.state.singleModel, {
                    contents: {
                        $splice: [[index, 1]]
                    }
                })
            })
        }
    }

    randomId = () => {
        var text = "";
        var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      
        for (var i = 0; i < 6; i++)
          text += possible.charAt(Math.floor(Math.random() * possible.length));
      
        return text;
    }

    onAddPackageToPackModel = () => {
        const carton = this.state.singleModel;
        if (_.isEmpty(carton.contents)) {
            return null;
        }
        if (!_.isArray(carton.contents)) {
            return null;
        }
        const existingIndex = _.findIndex(this.state.packModel, i => {
            return i.id === carton.id;
        })
        if (existingIndex > -1) {
            this.setState({
                packModel: update(this.state.packModel, {
                    [existingIndex]: {$set: carton}
                }),
                singleModel: {
                    id: this.randomId(),
                    own_number: '',
                    weight: 1,
                    length: 1,
                    width: 1,
                    height: 1,
                    contents: [
                        
                    ]
                },
                addCartonModal: false
            })
        } else {
            this.setState({
                packModel: update(this.state.packModel, {
                    $push: [carton]
                }),
                singleModel: {
                    id: this.randomId(),
                    own_number: '',
                    weight: 1,
                    length: 1,
                    width: 1,
                    height: 1,
                    contents: [
                        
                    ]
                },
                addCartonModal: false
            })
        }
    }

    packSummeryByLineId = (lineId) => {
        const {packModel} = this.state;
        const pkgs = [];
        _.each(packModel, p => {
            if (_.isArray(p.contents)) {
                _.each(p.contents, c => {
                    if (_.toString(c.lineId) === _.toString(lineId)) {
                        pkgs.push({
                            id: p.id,
                            own_number: p.own_number,
                            length: p.length,
                            width: p.width,
                            height: p.height,
                            weight: p.weight,
                            contents: p.contents,
                            _lineId: c.lineId,
                            _quantity: c.quantity
                        })
                    }
                })
            }
        });
        return pkgs;
    }

    onEditCartonClick = (carton) => {
        this.setState({
            addCartonModal: true,
            singleModel: carton
        })
    }

    onUomPackClick = () => {
        const {shipment_lines} = this.props.model;
        const packs = [];
        _.each(shipment_lines, l => {
            let qtyPerCarton = 1;
            let dims = {};
            const {id, quantity} = l;
            if (l.inventory.uoms && l.inventory.uoms.length > 0) {
                _.each(l.inventory.uoms, u => {
                    if (u.name === 'Carton' || u.name === 'CTN') {
                        qtyPerCarton = parseInt(u.pivot.qty_per_base,10);
                        dims = u.pivot;
                    }
                })
            }
            if (qtyPerCarton > 0) {
                let totalCarton = Math.ceil(quantity / qtyPerCarton);
                for (var i = 1; i <= totalCarton; i ++) {
                    packs.push({
                        contents: [{
                            lineId: id,
                            quantity: qtyPerCarton
                        }],
                        height: dims.height ? dims.height : 1,
                        length: dims.length ? dims.length : 1,
                        width: dims.width ? dims.width : 1,
                        weight: dims.weight ? dims.weight : 1,
                        id: this.randomId(),
                        own_number: ""
                    })
                }
            }
        });
        this.setState({
            packModel: packs
        })
    }

    onSaveClick = () => {
        const {shipment_lines} = this.props.model;
        let error = "";
        _.each(shipment_lines, line => {
            const pkgs = this.packSummeryByLineId(line.id);
            const shippedQty = line.quantity;
            let packedQty = 0;
            _.each(pkgs, pk => {
                packedQty = packedQty + parseInt(pk._quantity);
            });
            if (shippedQty !== packedQty) {
                error = `${line.inventory.sku} packed quantity does not equal to shipped quantity`;
                return null;
            }
        });
        if (!error) {
            this.setState({
                loading: true
            });
            api.shipments.pack(this.props.model.id, this.state.packModel).then(res => {
                this.props.history.push(`/f/${this.state.fulfillmentType}/${this.props.model.id}`);
            }).catch(err => {
                const {status, data} = err.response;
                this.setState({
                    loading: false,
                    error: status === 422 ? "An error occurred with the new quantity. Please try again" : data.message
                })
            })
        } else {
            this.setState({error});
        }
    }

    onRepackClick = () => {
        const {id} = this.props.model;
        api.shipments.rePack(id).then(res => {
            this.props.history.push(`/f/${this.state.fulfillmentType}/${this.props.model.id}`);
        }).catch(err => {
            this.handleHttpErrors(err);
        })
    }

    getItemModelByContentLineId = (lineId) => {
        const {shipment_lines} = this.props.model;
        const lineIndex = shipment_lines.findIndex(i => {
            return i.id === parseInt(lineId, 10);
        })
        if (lineIndex > -1) {
            return shipment_lines[lineIndex].inventory
        }
        return {
            sku: "NA",
            name: "NA"
        }
    }

    packedQty = () => {
        const {packModel} = this.state;
        const lineToInv = {};
        this.props.model.shipment_lines.map(l => {
            lineToInv[l.id] = l.inventory.id
        });
        const packed = {};
        packModel.map(ctn => {
            if (_.isArray(ctn.contents)) {
                ctn.contents.map(line => {
                    if (lineToInv[parseInt(line.lineId,10)]) {
                        const invId = lineToInv[parseInt(line.lineId,10)];
                        const packedQty = packed[invId] ? packed[invId] : 0;
                        packed[invId] = packedQty + parseInt(line.quantity,10)
                    }
                })
            }
        })
        return packed;
    }

    onDeleteCartonClick = (ctn) => {
        const {id} = ctn;
        const ctnIndex = this.state.packModel.findIndex(p => {
            return p.id === id;
        });
        if (ctnIndex > -1) {
            this.setState({
                packModel: update(this.state.packModel, {
                    $splice: [[ctnIndex, 1]]
                })
            })
        }
    }

    render() {
        const {t, packages, model, measure} = this.props;
        const {packModel} = this.state;
        return (
            <div>
                {/* Breadcrub Row */}
                <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12 lara-breadcrub">
                        <PageBreadCrub lists={this.state.breadcrub} t={t}/>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-8 col-sm-8 col-xs-12">
                        <Segment>
                            <Dimmer inverted active={this.state.loading}>
                                <Loader />
                            </Dimmer>
                            <SegmentRibbon label={model.id ? "Pack By Carton" : "Loading..."} Buttons={
                                () => {return (
                                    <React.Fragment>
                                        <PrimaryButton
                                            size='mini'
                                            float='right'
                                            label={t('Save')}
                                            onClick={() => {this.onSaveClick()}}
                                            loading={false}
                                        />
                                        <PrimaryButton
                                            size='mini'
                                            float='right'
                                            label={t('AddCarton')}
                                            onClick={() => {
                                                this.setState({
                                                    addCartonModal: true,
                                                    singleModel: {
                                                        id: this.randomId(),
                                                        own_number: '',
                                                        weight: 1,
                                                        length: 1,
                                                        width: 1,
                                                        height: 1,
                                                        contents: []
                                                    }
                                                })
                                            }}
                                            loading={false}
                                        />
                                        
                                    </React.Fragment>
                                )}
                            }/>
                            {model.id ? <div className="row">
                                <div className="col-md-12">
                                    {this.state.error ? <Message error>
                                        <Message.Content>
                                            <Message.Header>{t('Error')}</Message.Header>
                                            {this.state.error}
                                        </Message.Content>
                                    </Message> : null}
                                    {packModel.map(ctn => {
                                        return <div className="lara-row-margin" key={ctn.id}>
                                            <Header as='h3' color='teal' floated='left'>
                                                <Icon name='box' />
                                                <Header.Content>
                                                    <span onClick={() => {
                                                        this.onEditCartonClick(ctn);
                                                    }} className="lara-editable lara-clickable">
                                                        {ctn.id}
                                                    </span>
                                                    <Header.Subheader>
                                                        {ctn.length} X {ctn.width} X {ctn.height} {measure === 'metric' ? 'CM' : 'Inch'} @ {ctn.weight} {measure === 'metric' ? 'KG' : 'LB'}
                                                    </Header.Subheader>
                                                </Header.Content>
                                            </Header>
                                            <Header as="h3" floated="right">
                                                <Header.Content>
                                                    &nbsp;&nbsp;
                                                    <Header.Subheader>
                                                        <span className="lara-editable lara-clickable" onClick={() => {
                                                            this.onDeleteCartonClick(ctn)
                                                        }}>
                                                            <Icon name='trash' />
                                                        </span>
                                                    </Header.Subheader>
                                                </Header.Content>
                                            </Header>
                                            <Table compact>
                                                <Table.Body>
                                                    {ctn.contents.map((line) => {
                                                        const item = this.getItemModelByContentLineId(line.lineId);
                                                        return <Table.Row key={line.lineId}>
                                                            <Table.Cell>
                                                                {item.sku} <br />
                                                                {item.name}
                                                            </Table.Cell>
                                                            <Table.Cell verticalAlign='top'>
                                                                {line.quantity}
                                                            </Table.Cell>
                                                            <Table.Cell verticalAlign='top'>
                                                                {line.expire}
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    })}
                                                </Table.Body>
                                            </Table>
                                        </div>
                                    })}
                                </div>
                            </div> : null}
                        </Segment>
                    </div>
                    <div className="col-md-4 col-sm-4 col-xs-12 lara-secondary-segment-wrapper">
                        <Segment>
                                <Dimmer inverted active={this.state.loading}>
                                    <Loader />
                                </Dimmer>
                                <SegmentRibbon label={model.id ? t('PackAll') : "Loading..."} Buttons={
                                    () => {return (
                                        <React.Fragment>
                                            <PrimaryButton
                                                size='mini'
                                                float='right'
                                                label={t('Create')}
                                                onClick={() => {this.onUomPackClick()}}
                                                loading={false}
                                            />
                                            {model.packed ? <PrimaryButton 
                                                size='mini'
                                                float='right'
                                                label='Delete All'
                                                onClick={() => {
                                                    this.onRepackClick();
                                                }}
                                                loading={false}
                                            /> : null}
                                        </React.Fragment>
                                    )}
                                }/>
                            <Message>
                                <Message.Header>{t('PackAll')}</Message.Header>
                                <p>
                                    The system will automatically separate the shipment into cartons by inventory UOM. This will only work if each inventory item 
                                    in this shipment has an UOM named "Carton" or "CTN".
                                </p>
                            </Message>
                        </Segment>
                        <Segment>
                            <SegmentRibbon label={model.id ? "Item Summary" : "Loading..."} />
                            {model.shipment_lines.map(line => {
                                const pkgs = this.packSummeryByLineId(line.id);
                                return <div className="lara-row-margin" key={line.id}>
                                    <Header as='h3' color='teal'>
                                        <Icon name='box' />
                                        <Header.Content>
                                            Required Qty: {line.quantity}
                                            <Header.Subheader>{line.inventory.sku}</Header.Subheader>
                                        </Header.Content>
                                    </Header>
                                    <Table compact>
                                        <Table.Body>
                                            {pkgs.map((pkg, index) => {
                                                return <Table.Row key={pkg.id}>
                                                    <Table.Cell>
                                                        <span onClick={() => {
                                                            this.onEditCartonClick(pkg);
                                                        }} className="lara-editable lara-clickable">
                                                            {pkg.own_number ? pkg.own_number : pkg.id}
                                                        </span>
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {pkg.length} X {pkg.width} X {pkg.height}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {pkg.weight}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {pkg._quantity}
                                                    </Table.Cell>
                                                </Table.Row>
                                            })}
                                        </Table.Body>
                                    </Table>
                                </div>
                            })}
                        </Segment>
                    </div>
                </div>
                <Modal
                    closeOnDimmerClick={false}
                    closeIcon
                    onClose={() => {
                        this.setState({addCartonModal: false})
                    }}
                    closeOnEscape={true} open={this.state.addCartonModal}>
                    <Header icon='boxes' content={null} />
                    <Modal.Content>
                        <SingleCartonPackForm
                            lines={model.shipment_lines ? model.shipment_lines : []}
                            packages={packages}
                            model={this.state.singleModel}
                            onPackageSelected={(pack) => {
                                this.onPackageSelected(pack);
                            }}
                            onSingleModelValueChange={(field, value) => {                                
                                this.onSingleModelValueChange(field, value);
                            }}
                            onAddLineToSingleModelClick={(lineId, quantity) => {
                                this.onAddLineToSingleModelClick(lineId, quantity);
                            }}
                            onPackItemLineQtyChange={(newQty, index) => {
                                this.onPackItemLineQtyChange(newQty, index);
                            }}
                            onAddPackageToPackModel={() => {
                                this.onAddPackageToPackModel()
                            }}
                            packedQty = {this.packedQty()}
                        />
                    </Modal.Content>
                </Modal>
            </div>
        )
    }
}

ShipmentPackPage.propTypes = {
    fetchById: PropTypes.func.isRequired,
    setEmptyShipmentInStore: PropTypes.func.isRequired,
    history: PropTypes.shape({
       push: PropTypes.func.isRequired
    }).isRequired,
    location: PropTypes.object.isRequired,
    packages: PropTypes.array.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            type: PropTypes.string.isRequired,
            id: PropTypes.string.isRequired
        }),
        path: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired
    }),
    measure: PropTypes.string.isRequired
};

function mapStateToProps(state) {
    return {
        model: state.shipmentModel,
        packages: state.system.resources.packages,
        measure: state.system.preference.measure
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchById,
        setEmptyShipmentInStore
    }, dispatch)
}

export default withI18n()(connect(mapStateToProps, mapDispatchToProps)(ShipmentPackPage));