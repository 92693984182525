import React from 'react';
import {Form, Message} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import PrimaryButton from '../../includes/buttons/PrimaryButton';

const getFormLabel = (type) => {
    if (type === "5") {
        return "Import order by ID: "
    }
    if (type === "4") {
        return "Import order by #: "
    }
    if (type === "6") {
        return "Import order by order #: "
    }
    if (type === "10") {
        return "Import order by order #: "
    }
    if (type === "8" || type === "14") {
        return "Import order by number: "
    }
    if (type === "15") {
        return "Import order by ID: "
    }
}

const OrderImportForm = ({type, reference, onReferenceChange, onSubmit, loading, t}) => (
    <div>
        <Message
            header={t('ManualImport')}
            content={t('MessageManualImportAPI')}
        />
        <Form>
            <Form.Input
                value={reference}
                label={getFormLabel(type)}
                onChange={(e, {value}) => {
                    onReferenceChange(value)
                }}
                fluid
            />
            <PrimaryButton
                size='mini'
                label={t('Import')}
                onClick={() => {
                    onSubmit()
                }}
                loading={loading}
            />
            <div className="row">
            <div className="lara-row-margin"></div>
            </div>
            <div className="row">
            <div className="lara-row-margin"></div>
            </div>
        </Form>
    </div>
)

OrderImportForm.propTypes = {
    type: PropTypes.string.isRequired,
    reference: PropTypes.string,
    onReferenceChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired
};

export default OrderImportForm