import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Form } from "semantic-ui-react";
import CountrySelector from '../../includes/selectors/CountrySelector';
import ShipStationShippoSelector from '../../includes/selectors/shipping/ShipStationShippo';
import FormError from '../../includes/messages/422';

class CourierForm extends Component {
    render() {
        const {model, t, onFormValueChange, errors} = this.props
        return (
            <Form size="mini">
                {!_.isEmpty(errors) ? (
                    <FormError errors={errors} />
                ) : null}
                <Form.Group widths='equal'>
                    <Form.Input 
                        fluid 
                        required={true}
                        label={t("Courier")}
                        name="name"
                        value={model.name ? model.name : ""} 
                        onChange={(e) => { onFormValueChange(e.target.name, e.target.value) }}
                    />
                    <Form.Select
                        fluid
                        required={true}
                        options={this.props.clients}
                        label={t("Client")}
                        name="client_id"
                        value={_.toString(model.client_id)}
                        onChange={(e, {value}) => {
                            onFormValueChange('client_id', value)
                        }}
                    />
                </Form.Group>
                <Form.Group widths='equal'>
                    <Form.Input 
                        fluid 
                        label={t("Courier Account")}
                        name="account"
                        value={model.account ? model.account : ""} 
                        onChange={(e) => { onFormValueChange(e.target.name, e.target.value) }}
                    />
                    <Form.Select
                        fluid
                        label={t("Courier Payment Method")}
                        options={[
                            {value:'prepaid', text: t('Prepaid')},
                            {value:'collect', text: t('Collect')},
                            {value:'third_party', text: t('Third')}
                        ]}
                        value={model.payment_method}
                        onChange={(e, {value}) => {
                            onFormValueChange('payment_method', value)
                        }}
                    />
                </Form.Group>
                {model.payment_method !== 'prepaid' ? <Form.Group widths='equal'>
                    <Form.Input
                        fluid 
                        label={t("Courier Billing Postal")}
                        name="billing_zip"
                        value={model.billing_zip ? model.billing_zip : ""} 
                        onChange={(e) => { onFormValueChange(e.target.name, e.target.value) }}
                    />
                    <CountrySelector 
                        selected={model.billing_country_code}
                        countrySelected={(value) => {
                            onFormValueChange('billing_country_code', value)
                        }}
                        label={t("Courier Billing Country")}
                    />
                </Form.Group> : null}
                <Form.Group widths="equal">
                    <ShipStationShippoSelector
                        label={t("CourierSSMappingCarrier")}
                        options={this.props.shipStation.couriers}
                        value="code"
                        text="name"
                        parentValue=""
                        shouldHaveParent={false}
                        selected={model.ss_mapping_carrier}
                        onValueSelected={(value) => {
                            onFormValueChange('ss_mapping_carrier', value)
                        }}
                    />
                    <ShipStationShippoSelector
                        label={t("CourierSSMappingService")}
                        options={this.props.shipStation.services}
                        value="code"
                        text="name"
                        parentValue={model.ss_mapping_carrier}
                        shouldHaveParent={true}
                        selected={model.ss_mapping_service}
                        onValueSelected={(value) => {
                            onFormValueChange('ss_mapping_service', value)
                        }}
                    />
                    <ShipStationShippoSelector
                        label={t("CourierSSMappingPackage")}
                        options={this.props.shipStation.packages}
                        value="code"
                        text="name"
                        parentValue={model.ss_mapping_carrier}
                        shouldHaveParent={true}
                        selected={model.ss_mapping_package}
                        onValueSelected={(value) => {
                            onFormValueChange('ss_mapping_package', value)
                        }}
                    />
                </Form.Group>
                <Form.Group widths='equal'>
                    <ShipStationShippoSelector
                        label={t("ShippoID")}
                        options={this.props.shippo}
                        value="object_id"
                        text="account_id"
                        parentValue=""
                        shouldHaveParent={false}
                        selected={model.shippo_id}
                        onValueSelected={(value) => {
                            onFormValueChange('shippo_id', value)
                        }}
                    />
                    <Form.Input
                        fluid 
                        label={<a href='https://goshippo.com/docs/reference#servicelevels' 
                            target='_blank' 
                            rel="noopener noreferrer">{t("ShippoService")}</a>}
                        name="shippo_service"
                        value={model.shippo_service ? model.shippo_service : ""} 
                        onChange={(e) => { onFormValueChange(e.target.name, e.target.value) }}
                    />
                </Form.Group>
                {this.props.server.has_flex_integration_bool ? <Form.Group widths='equal'>
                    <Form.Select
                        search
                        fluid
                        label={t("Flex Book Service")}
                        options={_.isArray(this.props.fb.SupplierId) ? this.props.fb.SupplierId : []}
                        value={model.flex_service}
                        onChange={(e, {value}) => {
                            onFormValueChange('flex_service', value)
                        }}
                    />
                </Form.Group> : null}
                <Form.Group widths='equal'>
                    <Form.Checkbox
                        label={t('Status')}
                        name="status"
                        onChange={(e,data) => { onFormValueChange('status', data.checked) }}
                        checked={model.status}
                    />
                </Form.Group>
            </Form>
        );
    }
}

CourierForm.propTypes = {
    errors: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired,
    model: PropTypes.object.isRequired,
    onFormValueChange: PropTypes.func.isRequired,
    shipStation: PropTypes.object.isRequired,
    shippo: PropTypes.array.isRequired,
    clients: PropTypes.array.isRequired,
    server: PropTypes.object.isRequired,
    fb: PropTypes.object.isRequired
};

export default CourierForm