// import _ from 'lodash';
import {api} from '../api';
import {
    SET_API_PAGINATED_LIST,
    SET_API_MODEL,
    API_MODEL_UPDATED_BY_FORM
} from './types';
import Lara from '../lara';

export const fetchPaginatedList = (path) => (dispatch) => {
    return api.integrations.paginated(path).then(res => {
        dispatch({
            type: SET_API_PAGINATED_LIST,
            payload: res.data.data
        });
        return res;
    }).catch(err => {throw err});
}

export const fetchById = (id) => (dispatch) => {
    return api.integrations.read(id).then(res => {
        dispatch({
            type: SET_API_MODEL,
            payload: res.data.data
        })
        return res.data.data;
    }).catch(err => {throw err});
}

export const updateModelInStore = (field, value, subset = null) => {
    return {
        type: API_MODEL_UPDATED_BY_FORM,
        payload: {
            field, value, subset
        }
    }
}

export const createNewModelInStore = () => {
    return {
        type: SET_API_MODEL,
        payload: Lara.models.apiCredential("4")
    }
}

export const createModelOnServer = (model) => (dispatch) => {
    return api.integrations.create(model).then(res => {
        dispatch({
            type: SET_API_MODEL,
            payload: res.data.data
        });
        return res;
    });
}

export const updateModelOnServer = (model) => (dispatch) => {
    return api.integrations.update(model, true).then(res => {
        dispatch({
            type: SET_API_MODEL,
            payload: res.data.data
        });
        return res;
    })
}