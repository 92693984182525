import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {Input, Table, Icon, Label} from 'semantic-ui-react';

const OrderEditTableLine = ({line, index, onLineUpdate, onDeleteLine}) => (
    <Table.Row>
        <Table.Cell verticalAlign='top'>
            {line.sku}
        </Table.Cell>
        <Table.Cell verticalAlign='top'>
            {/* {line.kit_id && _.isEmpty(line.kit_property) ? <Label size='mini' color='purple'>Kit</Label> : 
                <Label size='mini' color='violet'>Item</Label>} {line.name} */}
            {!line.kit_id ? <Label size='mini' color='violet'>Item</Label> : 
                _.isEmpty(line.kit_property) ? <Label size='mini' color='purple'>Kit</Label> :
                <Label size='mini' color='purple'>{line.kit_property.sku}</Label>
            } {line.name} <br />
            <div style={{width: '100%', paddingTop: 3}}>
                <Input size='mini' value={line.comment ? line.comment : ""} fluid onChange={(e, {value}) => {
                    onLineUpdate('comment', value)
                }} icon='comment' iconPosition='left' placeholder='Line picking comment... (max 255 characters)' />
            </div>
        </Table.Cell>
        <Table.Cell verticalAlign='top'>
            <Input size='mini' value={line.quantity} type='number' onChange={(e, {value}) => {
                onLineUpdate('quantity', value)
            }}/>
        </Table.Cell>
        <Table.Cell verticalAlign='top'>
            <Input size='mini' value={line.price} type='number' onChange={(e, {value}) => {
                onLineUpdate('price', value)
            }}/>
        </Table.Cell>
        <Table.Cell verticalAlign='top'>
            <span className='lara-editable lara-clickable' onClick={() => {
                onDeleteLine();
            }}>
                <Icon name='times' />
            </span>
        </Table.Cell>
    </Table.Row>
)

OrderEditTableLine.propTypes = {
    index: PropTypes.number.isRequired,
    line: PropTypes.shape({
        international: PropTypes.object.isRequired,
        kit_property: PropTypes.any
    }),
    onLineUpdate: PropTypes.func.isRequired,
    onDeleteLine: PropTypes.func.isRequired
};


export default OrderEditTableLine