import React from 'react';
import PropTypes from 'prop-types';
import {Pagination, Icon} from 'semantic-ui-react';

const LaraPagination = ({activePage, totalRecords, perPage, onPageChange}) => (
    <Pagination 
        ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
        firstItem={{ content: <Icon name='angle double left' />, icon: true }}
        lastItem={{ content: <Icon name='angle double right' />, icon: true }}
        prevItem={{ content: <Icon name='angle left' />, icon: true }}
        nextItem={{ content: <Icon name='angle right' />, icon: true }}
        activePage={activePage}
        totalPages={Math.ceil(totalRecords / perPage)}
        onPageChange={(e, data) => {onPageChange(e, data)}}
    />
)

LaraPagination.propTypes = {
    totalRecords: PropTypes.number.isRequired,
    perPage: PropTypes.number.isRequired,
    activePage: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired
};


export default LaraPagination;