import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators} from 'redux';
import {withI18n} from 'react-i18next';
import {
    Modal, Header, List, Message
} from 'semantic-ui-react';
import FileUploadButton from '../partials/FileUploadButton';
import Lara from '../../../lara';
import {api} from "../../../api";

class LaraExcelUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isNew: false,
            result: {
                success: 0,
                errors: [

                ]
            }
        }
    }

    uploaded = (data) => {
        this.setState({
            isNew: true,
            result: data.data
        }, () => {
            this.props.onUploaded()
        });
    }

    onGenerateSchemaClick = () => {
        const {type,resources, measure, clients} = this.props;
        if (type === 'Location') {
            Lara.excel.locations();
        }
        if (type === 'Relation') {
            Lara.excel.relations(resources.couriers);
        }
        if (type === 'Inventory') {
            Lara.excel.inventories();
        }
        if (type === 'InventoryUom') {
            Lara.excel.uoms(resources.uoms, measure);
        }
        if (type === 'Order') {
            Lara.excel.orders(resources.couriers);
        }
        if (type === 'PutAway') {
            Lara.excel.putaway(clients, resources.uoms, measure);
        }
        if (type === 'ShipmentFulfillmentUpload') {
            if(this.props.shipmentModel.order.type === 'Vendor Order' || this.props.shipmentModel.order.type === 'Customer Return') {
                Lara.excel.shipmentSchema(this.props.shipmentModel);
            }
            if(this.props.shipmentModel.order.type === 'Customer Order' || this.props.shipmentModel.order.type === 'Vendor Return') {
                api.shipments.get_picking_ticket_data(this.props.shipmentModel.id).then(res => {
                    Lara.excel.outboundShipmentSchema(res.data.data);
                })
            }
        }
    }

    render() {
        const {
            displayModal, onCloseModalClick, t
        } = this.props;
        const {success, errors} = this.state.result;
        return (
            <Modal
                closeOnDimmerClick={false}
                closeIcon
                onClose={() => {onCloseModalClick()}}
                closeOnEscape={true} open={displayModal}>
                <Header icon='file excel outline' content='' />
                <Modal.Content>
                    <FileUploadButton
                        name="Upload Excel"
                        type="schema"
                        filable_id={this.props.shipmentModel ? this.props.shipmentModel.id : 0}
                        filable_type={this.props.type}
                        onSuccess={(data) => {
                            this.uploaded(data);
                        }}
                        t={this.props.t}
                        comment={false}
                    />
                    {this.state.isNew ? <div className="row lara-row-margin">
                        <div className="col-md-12">
                            <Message>
                                <Message.Header>{t('MessageImportSuccess')} {success}</Message.Header>
                            </Message>
                            <Message error>
                                <Message.Header>{t('MessageImportErrorList')}</Message.Header>
                                <List divided relaxed>
                                    {errors.map((e,index) => {
                                        return <List.Item key={index}>
                                        <List.Icon name='question circle outline' size='large' verticalAlign='middle' />
                                        <List.Content>
                                            <List.Header>{t('MessageExcelRow')}: {e.row}</List.Header>
                                            <List.Description as='a'>
                                                {e.message}
                                            </List.Description>
                                        </List.Content>
                                    </List.Item>
                                    })}
                                </List>
                            </Message>
                        </div>
                    </div> : null}
                    {this.props.type !== 'CountSheet' ? <div className="row lara-row-margin">
                        <div className="col-md-12">
                            <span
                                onClick={() => {
                                    this.onGenerateSchemaClick();
                                }}
                                className="lara-clickable lara-editable">{this.props.t('MessageDownloadSchema')}</span>
                        </div>
                    </div> : null}
                </Modal.Content>
            </Modal>
        )
    }
}

LaraExcelUpload.propTypes = {
    t: PropTypes.func.isRequired,
    displayModal: PropTypes.bool.isRequired,
    onCloseModalClick: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    onUploaded: PropTypes.func.isRequired,
    clients: PropTypes.array.isRequired,
    shipmentModel: PropTypes.object
};

function mapStateToProps(state) {
    return {
        resources: state.system.resources,
        measure: state.system.preference.measure,
        clients: state.system.clients
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({

    }, dispatch)
}

export default withI18n()(connect(mapStateToProps, mapDispatchToProps)(LaraExcelUpload));
