import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {Modal, Header} from 'semantic-ui-react';
import { PDFViewer } from '@react-pdf/renderer';

class PdfModal extends Component {    
    render() {
        const {showModal, onModalClose, PdfComponent, ...props} = this.props;
        return (
            <Modal size="large" 
                closeOnDimmerClick={false}
                closeIcon
                centered={false}
                onClose={() => {onModalClose()}}
                closeOnEscape={true} open={showModal}>
                <Header icon='file pdf outline' content='PDF Viewer' />
                <Modal.Content>
                    <PDFViewer className='lara-pdf-viewer'>
                        <PdfComponent {...props} />
                    </PDFViewer>
                </Modal.Content>
            </Modal>
        )
    }
}

PdfModal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    onModalClose: PropTypes.func.isRequired,
    PdfComponent: PropTypes.func.isRequired
    //history: PropTypes.shape({
    //    push: PropTypes.func.isRequired
    //}).isRequired,
};

export default PdfModal