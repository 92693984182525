import React, { Component } from 'react';
import { bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import {Form} from 'semantic-ui-react';
import PropTypes from 'prop-types';

class CourierSelector extends Component {
    //access router
    //this.props.history.push('/dashboard')
    rtnObjectSelectedValue = (value) => {
        const {couriers, onSelectChange} = this.props;
        const record = couriers.filter(i => {
            return _.toString(i.id) === value;
        });

        if (record.length > 0) {
            return onSelectChange(record[0]);
        } else {
            return {
                id: "0",
                name: "NA",
                client_id: null
            }
        }
    }
    
    render() {
        const {couriers, appendNonOption, returnObject, label, selected, onSelectChange, required} = this.props;
        const options = [];
        _.each(couriers, c => {
            options.push({
                value: _.toString(c.id),
                text: c.name,
                key: c.id
            });
        });
        if (appendNonOption) {
            options.unshift({
                value: "0",
                text: "NA",
                key: 0
            });
        }
        return (
            <Form.Select fluid search selection 
                label={label}
                options={options}
                name="courier_id" value={selected ? _.toString(selected) : "0"} 
                onChange={(e, {value}) => {
                    returnObject ? this.rtnObjectSelectedValue(value) : onSelectChange(value)
                }}
                required={required ? true : false}
            />
        )
    }
}

CourierSelector.propTypes = {
    onSelectChange: PropTypes.func.isRequired,
    couriers: PropTypes.array.isRequired,
    appendNonOption: PropTypes.bool.isRequired,
    returnObject: PropTypes.bool.isRequired,
    label: PropTypes.string
    //history: PropTypes.shape({
    //    push: PropTypes.func.isRequired
    //}).isRequired,
};

function mapStateToProps(state) {
    return {
        couriers: state.system.resources.couriers
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(CourierSelector)