import React, { Component } from 'react';
import { bindActionCreators} from 'redux';
import {Table, Icon, Modal, Header} from 'semantic-ui-react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import update from 'immutability-helper';
import {
    storeUomToInventory,
    deleteUomToInventory
} from '../../../actions/inventory';
import InventoryUomForm from './InventoryUomForm';
import Lara from '../../../lara';
import FormError from '../../includes/messages/422';
import AlertComponent from '../../includes/messages/alert';
import PrimaryButton from '../../includes/buttons/PrimaryButton';

class InventoryUomPane extends Component {
    constructor(props) {
        super(props);
        this.state = {
            model: {
                uomId: "",
                is_base: 0,
                qty_per_base: 1,
                weight: 1,
                length: 1,
                width: 1,
                height: 1
            },
            index: -1,
            errors: [],
            showModal: false,
            loading: false
        }
    }

    onEditClick = (uom, index) => {
        const {pivot} = uom;
        if (!_.isObject(pivot)) {
            return null;
        }
        this.setState({
            model: {
                uomId: uom.id,
                is_base: pivot.is_base,
                qty_per_base: pivot.qty_per_base,
                weight: pivot.weight,
                length: pivot.length,
                width: pivot.width,
                height: pivot.height
            },
            index: index,
            errors: [],
            showModal: true
        });
    }

    onCreateClicked = () => {
        this.setState({
            model: {
                uomId: "",
                is_base: 0,
                qty_per_base: 1,
                weight: 1,
                length: 1,
                width: 1,
                height: 1
            },
            index: -1,
            errors: [],
            showModal: true
        });
    }

    onDeleteClick = (uom, index) => {
        this.setState({
            loading: true
        }, () => {
            this.props.deleteUomToInventory(this.props.id, uom.id, index)
                .then(res => {
                    this.setState({
                        loading: false
                    });
                }).catch(err => {
                    const errors = Lara.axiosError(err, this.props.t);
                    this.setState({
                        loading: false
                    });
                    Lara.axiosAlert(<AlertComponent 
                        support={errors.support}
                        message={errors.message}
                        reference={errors.reference}
                        t={this.props.t}
                    />);
                })
        })
    }

    onModelValueChange = (field, value) => {
        if (field === 'is_base') {
            value ? value = 1 : value =0;
        }
        this.setState({
            model: update(this.state.model, {
                [field]: {$set: value}
            })
        })
    }

    onSaveSubmit = () => {
        const errors = this.validateInput();
        if (errors.length > 0) {
            this.setState({
                errors: errors
            });
        } else {
            const uomModel = this.props.availableUoms.filter(u => {
                return parseInt(u.id, 10) === parseInt(this.state.model.uomId, 10);
            });
            if (uomModel.length === 0) {
                return;
            }
            this.setState({
                errors: [],
                loading: true
            }, () => {
                this.props.storeUomToInventory(this.state.model, 
                    this.props.id, 
                    this.state.index,
                    uomModel[0]
                ).then(res => {
                    this.setState({
                        loading: false,
                        showModal: false
                    });
                }).catch(err => {
                    const errors = Lara.axiosError(err, this.props.t);
                    if (errors.http_code === 422) {
                        this.setState({
                            errors: errors.message,
                            loading: false
                        });
                    } else {
                        this.setState({
                            loading: false
                        });
                        Lara.axiosAlert(<AlertComponent 
                            support={errors.support}
                            message={errors.message}
                            reference={errors.reference}
                            t={this.props.t}
                        />);
                    }
                })
            });
        }
    }

    validateInput = () => {
        const {t} = this.props;
        const {uomId,is_base,qty_per_base, length, width, height, weight} = this.state.model;
        const errors = [];
        if (!uomId) {
            errors.push(t("UOMIsRequired"));
        }
        if (is_base === 1) {
            if (parseInt(qty_per_base, 10) !== 1) {
                errors.push(t("NumberOfBaseUnitShouldBeOne"));
            }
            const hasBaseUom = this.hasBaseUom();
            if (_.isObject(hasBaseUom)) {
                if (parseInt(this.state.model.uomId, 10) !== parseInt(hasBaseUom.id, 10)) {
                    errors.push(t("OnlyOneBaseUOMIsAllowed"));
                }
            }
        }
        
        if (!length || !width || !height || !weight) {
            errors.push(t("UOMNotNumber"));
        }
        
        return errors;
    }

    hasBaseUom = () => {
        const hasBaseUom = this.props.uoms.filter(u => {
            return parseInt(u.pivot.is_base) === 1;
        });
        if (hasBaseUom.length > 0) {
            return hasBaseUom[0];
        }
        return false;
    }
    
    getEditUomName = () => {
        const u = this.props.uoms[this.state.index];
        if (_.isObject(u)) {
            return u.name;
        }
        return "NA";
    }

    render() {
        const {t, uoms, measure} = this.props;
        return (
            <div>
                <Table compact>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell />
                            <Table.HeaderCell colSpan='9'>
                                <PrimaryButton
                                    size='mini'
                                    float='right'
                                    label={t('Create')}
                                    onClick={() => {
                                        this.onCreateClicked()
                                    }}
                                    loading={false}
                                />
                            </Table.HeaderCell>
                        </Table.Row>
                        <Table.Row>
                            <Table.HeaderCell>
                                {t("UOM")}
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                {t("BaseUOM")}
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                {t("BaseQty")}
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                {t("Length")} ({measure === 'metric' ? t('CM') : t('In')})
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                {t("Width")} ({measure === 'metric' ? t('CM') : t('In')})
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                {t("Height")} ({measure === 'metric' ? t('CM') : t('In')})
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                {t("Weight")} ({measure === 'metric' ? t('KG') : t('Lb')})
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                {t("Edit")}
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                {t("Delete")}
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {uoms.map((i, index) => {
                            let {pivot} = i;
                            if (!_.isObject(pivot)) {
                                pivot = {}
                            }
                            return (
                                <Table.Row key={i.id}>
                                    <Table.Cell>
                                        {i.name}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {pivot.is_base ? 
                                            <Icon name="check" color='green' /> :
                                            <Icon name="times" /> 
                                        }
                                    </Table.Cell>
                                    <Table.Cell>
                                        {parseFloat(pivot.qty_per_base)}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {pivot.length}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {pivot.width}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {pivot.height}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {pivot.weight}
                                    </Table.Cell>
                                    <Table.Cell>
                                        <span 
                                            onClick={() => {
                                                this.onEditClick(i, index);
                                            }}
                                            className="lara-editable lara-clickable">
                                            {t('Edit')}
                                        </span>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <span 
                                            onClick={() => {
                                                this.onDeleteClick(i, index);
                                            }}
                                            className="lara-editable lara-clickable">
                                            {t("Delete")}
                                        </span>
                                    </Table.Cell>
                                </Table.Row>
                            )
                        })}
                    </Table.Body>
                </Table>
                <Modal size="small" 
                    closeOnDimmerClick={false}
                    closeIcon
                    onClose={() => {this.setState({showModal: false})}}
                    closeOnEscape={true} open={this.state.showModal}>
                    <Header icon='sitemap' content={this.state.index === -1 ? "UOM" : this.getEditUomName()} />
                    <Modal.Content>
                        {!_.isEmpty(this.state.errors) ? (
                            <FormError errors={this.state.errors} />
                        ) : null}
                        <InventoryUomForm
                            model={this.state.model}
                            associated={this.props.uoms}
                            onValueChange={(field, value) => {
                                this.onModelValueChange(field, value);
                            }}
                            currentIndex={this.state.index}
                        />
                    </Modal.Content>
                    <Modal.Actions>
                        <PrimaryButton
                            size='mini'
                            float='right'
                            label={t('Save')}
                            onClick={() => {
                                this.onSaveSubmit()
                            }}
                            loading={this.state.loading}
                        />
                        <div className="row">
                        <div className="lara-row-margin"></div>
                        </div>
                        <div className="row">
                        <div className="lara-row-margin"></div>
                        </div>
                    </Modal.Actions>
                </Modal>
            </div>
        )
    }
}

InventoryUomPane.propTypes = {
    uoms: PropTypes.array.isRequired,
    id: PropTypes.number.isRequired,
    t: PropTypes.func.isRequired,
    measure: PropTypes.string.isRequired,
    availableUoms: PropTypes.array.isRequired,
    storeUomToInventory: PropTypes.func.isRequired,
    deleteUomToInventory: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    return {
        measure: state.system.preference.measure,
        availableUoms: state.system.resources.uoms
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        storeUomToInventory,
        deleteUomToInventory
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(InventoryUomPane);