import React from 'react';
import PropTypes from 'prop-types';
import {Input, Select} from 'semantic-ui-react';
//import _ from 'lodash';

const LaraSearchBar = ({placeholder, onSearchEnterKeyPressed, 
        search, onSearchChange, onPredefinedRouteSelected, 
        options, query, havePreferenceSelect, hasPerPageSelector, onPerPageSelcted}) => (
    <div className="row lara-row-margin">
        <div className="col-md-6 col-sm-6 col-xs-12">
            <Input 
                icon='search plus'
                fluid
                value={search ? search : ""}
                onChange={(e, {value}) => {onSearchChange(value)}}
                placeholder={placeholder ? placeholder : 'Search...'}
                onKeyPress={(e) => {onSearchEnterKeyPressed(e)}}
            />
        </div>
        {/* {havePreferenceSelect ? <div className="col-md-6 col-sm-6 col-xs-12">
            <Select 
                options={options} 
                fluid
                onChange={(e, {value}) => onPredefinedRouteSelected(value)}
                value={query}
            />
        </div>: null} */}
        <div className="col-md-6 col-sm-6 col-xs-12">
            <div className="row">
                {havePreferenceSelect ? <div className="col-md-6 col-sm-6 col-xs-12">
                    <Select 
                        options={options} 
                        fluid
                        onChange={(e, {value}) => onPredefinedRouteSelected(value)}
                        value={query}
                    />
                </div>: null}
                {hasPerPageSelector ? <div className="col-md-6 col-sm-6 col-xs-12">
                    <Select
                        options={[{value: 15, text: "15 Per Page"}, {value: 30, text: "30 Per Page"}, {value: 50, text: "50 Per Page"}, {value: 75, text: "75 Per Page"}, {value: 100, text: "100 Per Page"}]}
                        fluid
                        onChange={(e, {value}) => onPerPageSelcted(value)}
                        value={parseInt(hasPerPageSelector)}
                    />
                </div> : null}
            </div>
        </div>
    </div>
)

LaraSearchBar.propTypes = {
    onSearchEnterKeyPressed: PropTypes.func.isRequired,
    onSearchChange: PropTypes.func.isRequired, 
    onPredefinedRouteSelected: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
    havePreferenceSelect: PropTypes.bool.isRequired
};


export default LaraSearchBar;