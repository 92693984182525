import React from 'react';
import PropTypes from 'prop-types';
import {Image} from 'semantic-ui-react';
const SHOPIFY_ICON = require("../../../assets/images/shopify_icon.jpg");
const WOO_ICON = require("../../../assets/images/woo_icon.png");
const TG_ICON = require("../../../assets/images/tg_icon.png");
const SS_ICON = require("../../../assets/images/ship-station-branding.png");
const MEGENTO_ICON = require("../../../assets/images/magento.png");
const AMAZON_ICON = require("../../../assets/images/amazon_icon.png");
const ZALORA_ICON = require("../../../assets/images/zalora_icon.png");

const PartinerLogo = ({type}) => (
    <div>
        {type === "4" ?   <div>
            <Image src={SHOPIFY_ICON} avatar />
            <span>Shopify</span>
        </div> : null}
        {type === "5" ?   <div>
            <Image src={WOO_ICON} avatar />
            <span>WooCommerce</span>
        </div> : null}
        {type === "6" ?   <div>
            <Image src={TG_ICON} avatar />
            <span>TradeGecko</span>
        </div> : null}
        {type === "8" ?   <div>
            <Image src={SS_ICON} avatar />
            <span>ShipStation Order</span>
        </div> : null}
        {type === "10" ?   <div>
            <Image src={MEGENTO_ICON} avatar />
            <span>Megento V2</span>
        </div> : null}
        {type === "11" ?   <div>
            <Image src={AMAZON_ICON} avatar />
            <span>US FBA</span>
        </div> : null}
        {type === "14" ?   <div>
            <Image src={SS_ICON} avatar />
            <span>3rd Party ShipStation</span>
        </div> : null}
        {type === "15" ?   <div>
            <Image src={ZALORA_ICON} avatar />
            <span>Zalora</span>
        </div> : null}
    </div>
)

PartinerLogo.propTypes = {
    type: PropTypes.string.isRequired
};


export default PartinerLogo