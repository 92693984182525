import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import _ from 'lodash';
import {List} from 'semantic-ui-react';
import {withI18n} from 'react-i18next';

const BrtFulfillmentStatusTable = ({t, meta, tooltipId}) => (
    <div className="lara-text-wrapper">
        <span 
            className='lara-text-value lara-clickable lara-tooltip-title'
            data-tip data-for={tooltipId}    
        >
            {!meta.brt_status || _.isEmpty(meta.brt_status)? "NA" : _.isObject(meta.brt_status[0]) ? meta.brt_status[0].status : "NA"}
        </span>
        <ReactTooltip id={tooltipId} place="bottom" type="dark" effect="solid">
            <div className="lara-text-wrapper" style={{width: '400px', paddingTop: '10px'}}>
                {!_.isEmpty(meta.brt_status) ? <List>
                    {meta.brt_status.map((l,index) => {
                        return <List.Item key={index}>
                            <List.Icon name='arrow alternate circle up outline' size='large' verticalAlign='middle' />
                            <List.Content>
                                <List.Header><span className='lara-white-font'>{l.shipment_id}: {l.status}</span></List.Header>
                                <List.Description>
                                    <span className="lara-white-font">[{l.date}]{l.comments ? ` - ${l.comments}` : null}</span>
                                </List.Description>
                            </List.Content>
                    </List.Item>
                    })}
                </List> : <List>
                    <List.Item>
                            <List.Icon name='arrow alternate circle up outline' size='large' verticalAlign='middle' />
                            <List.Content>
                                <List.Header>
                                    <span className='lara-white-font'>NA</span>
                                </List.Header>
                                <List.Description>
                                    <span className='lara-white-font'>Status not available.</span>
                                </List.Description>
                            </List.Content>
                    </List.Item>
                </List>}
            </div>
        </ReactTooltip>
    </div>
)

BrtFulfillmentStatusTable.propTypes = {
    tooltipId: PropTypes.string.isRequired,
    meta: PropTypes.object.isRequired
};


export default withI18n()(BrtFulfillmentStatusTable);