import React, { Component } from 'react';
import { bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import {withI18n} from 'react-i18next';
import PropTypes from 'prop-types';
import {List} from 'semantic-ui-react';
import update from 'immutability-helper';
import Lara from '../../../lara';
import RoleModalForm from './RoleModalForm';
import AlertComponent from '../messages/alert';
import {
    saveRole
} from '../../../actions/user';

class RoleModalCotent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            model: {
                id: null
            },
            edit: false,
            loading: false
        }
    }

    onEditClicked = (role) => {
        this.setState({
            model: role,
            edit: true
        });
    }

    onModelFieldChange = (field, value) => {
        this.setState({
            model: update(this.state.model, {
                [field]: {$set: value}
            })
        });
    }

    onModelAccessChange = (field, value) => {
        this.setState({
            model: update(this.state.model, {
                access: {
                    [field]: {$set: value}
                }
            })
        });
    }

    onSaveRoleClick = () => {
        const {model} = this.state;
        this.setState({
            loading: true
        });
        this.props.saveRole(model).then(res => {
            const {data} = res.data;
            if (!model.id) {
                this.setState({
                    model: data,
                    loading: false
                });
            } else {
                this.setState({
                    loading: false
                });
            }
        }).catch(err => {
            this.setState({
                loading: false
            });
            const errors = Lara.axiosError(err, this.props.t);
            Lara.axiosAlert(<AlertComponent 
                support={errors.support}
                message={errors.message}
                reference={errors.reference}
                t={this.props.t}
            />);
        })
    }

    render() {
        const {roles, t} = this.props;
        const {model} = this.state;
        return (
            <div className="row">
                <div className="col-md-2 col-sm-3 col-xs-12">
                <List selection verticalAlign='middle'>
                    {roles.map(r => {
                        return (
                            <List.Item key={r.id} onClick={() => {
                                this.onEditClicked(r)
                            }}>
                                <List.Icon name='universal access' 
                                    size='large' 
                                    verticalAlign='middle' 
                                    color={r.id === this.state.model.id ? 'teal' : 'grey'} />
                                <List.Content>
                                    <List.Header>{r.name}</List.Header>
                                </List.Content>
                            </List.Item>
                        )
                    })}
                    <List.Item onClick={() => {
                        this.onEditClicked(Lara.models.role())
                    }}>
                        <List.Icon name='add' 
                            size='large' 
                            verticalAlign='middle' 
                            color={(!this.state.model.id && this.state.edit) ? 'teal' : 'grey'} />
                        <List.Content>
                            <List.Header>{this.props.t('Create')}</List.Header>
                        </List.Content>
                    </List.Item>
                </List>
                </div>
                <div className="col-md-10 col-sm-9 col-xs-12">
                    <div className="row">
                        {this.state.edit ? 
                            <RoleModalForm 
                                model={model}
                                onCheckboxClicked={(field, value) => {this.onModelAccessChange(field, value)}}
                                t={t}
                                onModelFieldChange={(field, value) => {this.onModelFieldChange(field, value)}}
                                onSubmitBtnClicked={() => {this.onSaveRoleClick() }}
                                loading={this.state.loading}
                            /> : null}
                    </div>
                </div>
            </div>
        )
    }
}
 RoleModalCotent.propTypes = {
    t:PropTypes.func.isRequired,
    roles: PropTypes.array.isRequired,
    saveRole: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        roles: state.role.list
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        saveRole
    }, dispatch)
}

export default withI18n()(connect(mapStateToProps, mapDispatchToProps)(RoleModalCotent));