import React, { Component } from 'react';
import { bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import {withI18n} from 'react-i18next';
import PropTypes from 'prop-types';
import {Segment, Loader, Dimmer} from 'semantic-ui-react';
import {
    fetchById,
    addressCreated,
    addressUpdated,
    addressDeleted
} from '../../actions/relation';
import AddressTable from '../includes/partials/AddressTable';
import PageBreadCrub from '../includes/partials/PageBreadCrub';
import AlertComponent from '../includes/messages/alert';
import SegmentRibbon from '../includes/partials/SegmentRibbon';
import ResourceTextPropertyView from '../includes/partials/ResourceTextPropertyView';
import ResourceBooleanPropertyView from '../includes/partials/ResourceBooleanPropertyView';
import TraceComponent from '../includes/partials/TraceComponent';
import Lara from '../../lara';
import LaraPagination from '../../laraPagination';
import PrimaryNaviButton from '../includes/buttons/PrimaryNaviButton';

class RelationViewPage extends Component {
    constructor() {
        super();
        this.state = {
            breadcrub: [
                {isLink:false, to:null, tag: '', active: true, key: 1}
            ],
            loading: false,
            relationType: null
        }
    }

    componentDidMount() {
        const {location, match} = this.props;
        const {type, id} = match.params;
        this.setState({
            loading: true
        });
        this.props.fetchById(id).then(res => {
            this.setState({
                loading: false,
                relationType: type,
                breadcrub: this.getBreadCrub(location.backTo, res.name, type)
            })
        }).catch(err => {
            const errors = Lara.axiosError(err, this.props.t);
            if (errors.http_code === 422) {
                this.setState({
                    errors: errors.message,
                    loading: false
                });
            } else {
                this.setState({
                    loading: false
                });
                Lara.axiosAlert(<AlertComponent 
                    support={errors.support}
                    message={errors.message}
                    reference={errors.reference}
                    t={this.props.t}
                />);
            }
            this.setState({loading: false});
        })
    }

    getCourierName = () => {
        const {model, couriers} = this.props;
        const courier = Lara.findFromResourceArray(model.default_courier, 'id', couriers, true);
        return courier ? courier.name : ""
    }

    getBreadCrub = (backTo, name, type) => {
        if (!backTo) {
            backTo = `/relations/${type}/${LaraPagination.relations(false, type)}`;
        }
        return [
            {isLink:true, to: backTo, tag: _.upperFirst(type), active: false, key: 1},
            {isLink:false, to:null, tag: name, active: true, key: 2}
        ]
    }
    
    render() {
        const {t, model} = this.props;
        return (
            <div>
                {/* Breadcrub Row */}
                <div className="row">
                    <div className="col-md-8 col-sm-8 col-xs-12 lara-breadcrub">
                        <PageBreadCrub lists={this.state.breadcrub} t={t}/>
                    </div>
                    {model.id && this.props.user.is_main_user ? <div className="col-md-4 col-sm-4 col-xs-12 lara-breadcrub">
                        <TraceComponent
                            trace_id={model.id}
                            trace_type='App\Models\Relation'
                        />
                    </div> : null}
                </div>

                <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <Segment>
                            <Dimmer inverted active={this.state.loading}>
                                <Loader />
                            </Dimmer>
                            <SegmentRibbon label={model.name ? model.name : "Loading..."} Buttons={
                                () => {return (<React.Fragment>
                                    <PrimaryNaviButton
                                        link={`/relations/${this.state.relationType}/${model.id}/action/edit`}
                                        size='mini'
                                        float='right'
                                        loading={false}
                                        label={t('Edit')}
                                    />
                                </React.Fragment>)}
                            }/>
                            <div className="row">
                                <div className="col-md-3 col-sm-6 col-xs-12">
                                    <ResourceTextPropertyView
                                        label="Account"
                                        value={model.account} />
                                    <ResourceTextPropertyView
                                        label="Email"
                                        value={model.email} />
                                    <ResourceTextPropertyView
                                        label="Phone"
                                        value={model.phone} />
                                </div>
                                <div className="col-md-3 col-sm-6 col-xs-12">
                                    <ResourceTextPropertyView
                                        label="Courier"
                                        value={this.getCourierName()} />
                                    <ResourceTextPropertyView
                                        label="CourierAccount"
                                        value={model.default_courier_account} />
                                    <ResourceTextPropertyView
                                        label="CourierBillingPostal"
                                        value={model.default_collect_postal} />
                                </div>
                                <div className="col-md-3 col-sm-6 col-xs-12">
                                    <ResourceTextPropertyView
                                        label="CourierBillingCountry"
                                        value={model.default_collect_country} />
                                    <ResourceTextPropertyView
                                        label="CourierPaymentMethod"
                                        value={_.upperFirst(model.default_shipping_payment)} />
                                    <ResourceTextPropertyView
                                        label="TaxRate"
                                        value={model.default_tax_rate} />
                                </div>
                                <div className="col-md-3 col-sm-6 col-xs-12">
                                    <ResourceTextPropertyView
                                        label="PaymentTerm"
                                        value={model.default_term} />
                                    <ResourceBooleanPropertyView
                                        label="Status"
                                        value={model.is_active} />
                                    <ResourceBooleanPropertyView
                                        label="DisplayNote"
                                        value={model.is_active} />
                                </div>
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <ResourceTextPropertyView
                                        label="Note"
                                        value={model.default_instruction} />
                                </div>
                            </div>
                        </Segment>
                    </div>
                </div>

                {model.id ? <div className="row lara-row-margin">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <Segment>
                            <SegmentRibbon label={t("Address")} Buttons={null} />
                            <div className="row">
                                <AddressTable
                                    addressalbe_type="relation"
                                    addressable_id={model.id}
                                    addresses={model.addresses}
                                    addressCreated={(addr) => {this.props.addressCreated(addr)}}
                                    addressUpdated={(addr, index) => {this.props.addressUpdated(addr, index)}}
                                    addressDeleted={(index) => {this.props.addressDeleted(index)}}
                                />
                            </div>
                        </Segment>
                    </div>
                </div> : null}
            </div>
        )
    }
}

RelationViewPage.propTypes = {
    fetchById: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    history: PropTypes.shape({
       push: PropTypes.func.isRequired
    }).isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            type: PropTypes.string.isRequired,
            id: PropTypes.string.isRequired
        }),
        path: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired
    }),
    couriers: PropTypes.array.isRequired,
    addressCreated: PropTypes.func.isRequired,
    addressUpdated: PropTypes.func.isRequired,
    addressDeleted: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    return {
        model: state.relationModel,
        couriers: state.system.resources.couriers,
        user: state.system.user
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchById,
        addressCreated,
        addressUpdated,
        addressDeleted
    }, dispatch)
}

export default withI18n()(connect(mapStateToProps, mapDispatchToProps)(RelationViewPage));