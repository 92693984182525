import React from 'react';
import ReactDOM from 'react-dom';
import {createStore, applyMiddleware} from 'redux';
import {Provider} from 'react-redux';
import {BrowserRouter, Route} from 'react-router-dom';
import {composeWithDevTools} from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import {I18nextProvider} from 'react-i18next';
import 'semantic-ui-css/semantic.min.css';
import 'flexboxgrid/dist/flexboxgrid.min.css';
import 'react-s-alert/dist/s-alert-default.css';
import './sass/main.scss';
import rootReducer from './reducers/root';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {
    loadLocalAuth,
    setAxiosAuthorizationHeader,
    saveLocalAuth
} from './actions/utils';
import configI18n from './i18n';
import {api} from './api';

let store = null;
if (process.env.NODE_ENV === 'production') {
    store = createStore(rootReducer, applyMiddleware(thunk));
} else {
    store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));
}

//const localStore = loadLocalStorage('lara_user');
const localAuth = loadLocalAuth();
//console.log("Loaded from local auth", localAuth);
let authenticated = false;

try {
    if (!localAuth) {
        throw new Error("No local auth");
    }
    const now = new Date().getTime() / 1000;
    if (now >= localAuth.expire) {
        throw new Error("Expired Token");
    }
    setAxiosAuthorizationHeader(localAuth.jwt);
    api.system.boot().then(res => {
        const {data} = res.data;
        let i18n = configI18n(data.user.lang ? data.user.lang : 'en');
        //Every boot (browser refresh), server returns a new token
        localAuth.jwt = data.renew;
        localAuth.expire = data.expire;
        saveLocalAuth(localAuth);
        setAxiosAuthorizationHeader(localAuth.jwt);
        store.dispatch({
            type: 'USER_LOGGED_IN_VIA_LOCAL_STATE',
            payload: localAuth
        });
        store.dispatch({
            type: 'INITIAL_BOOT_LOADED',
            payload: data
        });
        const {user} = data;
        localStorage.setItem("lara_current_user_name", user.name);
        localStorage.setItem("lara_current_user_email", user.email);
        authenticated = true;
        ReactDOM.render(
        <I18nextProvider i18n={i18n}>
            <BrowserRouter>
                <Provider store={store}>
                    <Route render={() => <App authenticated={authenticated} />} />
                </Provider>
            </BrowserRouter>
        </I18nextProvider>
        , document.getElementById('root'));
    }).catch(err => {
        let i18n = configI18n('en');
        ReactDOM.render(
        <I18nextProvider i18n={i18n}>
            <BrowserRouter>
                <Provider store={store}>
                    <Route render={props => <App authenticated={authenticated} {...props} />} />
                </Provider>
            </BrowserRouter>
        </I18nextProvider>, document.getElementById('root'));
    });
} catch (e) {
    let i18n = configI18n('en');
    ReactDOM.render(
    <I18nextProvider i18n={i18n}>
        <BrowserRouter>
            <Provider store={store}>
                <Route render={props => <App authenticated={authenticated} {...props} />} />
            </Provider>
        </BrowserRouter>
    </I18nextProvider>, document.getElementById('root'));
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

