import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
    Dropdown
} from 'semantic-ui-react';

const formatClients = (clients) => {
    const result = [];
    _.each(clients, client => {
        result.push({
            key: _.toString(client.id),
            value: _.toString(client.id),
            text: _.toString(client.company)
        });
    });   
    return result;
}

const MultipleClientSelector = ({t, currentValueString, onFilterSelectChange, options, label}) => (
    <div className="lara-row-bottom-margin">
        <div className="lara-ui-label">{t(label)}</div>
        <Dropdown
            className="lara-full-width"
            fluid selection multiple
            search
            options={formatClients(options)}
            value={currentValueString.split(',')}
            onChange={(e, {value}) => {onFilterSelectChange(value)}}
        />
    </div>
)

MultipleClientSelector.propTypes = {
    t: PropTypes.func.isRequired,
    currentValueString: PropTypes.string.isRequired,
    onFilterSelectChange: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
    label: PropTypes.string
};


export default MultipleClientSelector