import React, { Component } from 'react';
import { bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import {
    Form
} from 'semantic-ui-react';
import {
    onFulfillmentModalClose,
    updateFulfillmentModelByForm,
    fetchFulfillmentLicenses,
    fetchFulfillmentSerisl,
    saveFulfillmentModel,
    fetchFulfillmentLot,
    deleteFulfillmentLine
} from '../../actions/shipment';
import PropTypes from 'prop-types';
import FormError from '../includes/messages/422';
import AlertComponent from '../includes/messages/alert';
import Lara from '../../lara';
import Header from './components/FulfillmentFormHeader';
import FulfillmentTable from './components/FulfillmentTable';
import SerialSelector from '../includes/selectors/FulfillmentSerialSelector';
import LotSelector from '../includes/selectors/FulfillmentLotsSelector';
import PrimaryButton from '../includes/buttons/PrimaryButton';

class LineFulfillmentForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            errors: [],
            lpSearch: "",
            lpError: false,
            useLinceseLot: true
        }
    }

    componentDidMount() {
        const {inventory} = this.props.line;
        if (inventory.config.serial) {
            this.props.fetchFulfillmentSerisl(inventory.id);
        }
        if (inventory.config.lot && this.props.action === 'inbound') {
            this.props.fetchFulfillmentLot(inventory.id);
        }
    }

    onFormSubmit = () => {
        const errors = this.validateInput();
        const {index, model, line, action} = this.props;
        const {inventory} = this.props.line;
        //console.log(action);return;
        if (_.isEmpty(errors)) {
            this.setState({
                loading: true
            });
            this.props.saveFulfillmentModel(line.shipment_id, model, index, action)
            .then(res => {
                this.setState({
                    loading: false,
                    errors: [],
                    lpSearch: "",
                    lpError: false
                });
                if (inventory.config.serial) {
                    this.props.fetchFulfillmentSerisl(inventory.id);
                }
            }).catch(err => {
                this.handleAxiosError(err);
            });
        } else {
            this.setState({
                errors: errors
            });
        }
    };

    validateInput = () => {
        const {lineId, quantity, serialId, lotId, newLot} = this.props.model;
        const {config} = this.props.line.inventory;
        const errors = [];
        if (!lineId) {
            errors.push(this.props.t('MissingLineId'))
        }
        if (parseInt(quantity,10) <= 0) {
            errors.push(this.props.t('InvalidQuantity'))
        }
        if (config.lot) {
            if (!lotId) {
                if (!newLot.lot) {
                    errors.push(this.props.t('LotRequired'))
                }
            }
            if (lotId) {
                this.props.updateFulfillmentModelByForm('newLot', {
                    lot: "",
                    expire: ""
                }, false)
            }
        }
        if (config.serial) {
            if (!serialId) {
                errors.push(this.props.t('SerialRequired'))
            }
        }
        if (parseInt(quantity, 10) > this.remainingQty()) {
            errors.push(this.props.t('QuantityCannotBeMoreThanOrdered'))
        }
        return errors;
    };

    remainingQty = () => {
        const {fulfillments, quantity} = this.props.line
        let fulfilled = 0;
        _.each(fulfillments, f => {
            fulfilled = fulfilled + Math.abs(f.change)
        });
        return quantity - fulfilled;
    }

    handleAxiosError = (err) => {
        console.log(err);
        const errors = Lara.axiosError(err, this.props.t);
        if (errors.http_code === 422) {
            this.setState({
                errors: errors.message,
                loading: false
            });
        } else {
            this.setState({
                loading: false
            });
            Lara.axiosAlert(<AlertComponent
                support={errors.support}
                message={errors.message}
                reference={errors.reference}
                t={this.props.t}
            />);
        }
        this.setState({loading: false});
    }

    onSearchLpPressed = (e) => {
        if (e.key === 'Enter' && this.state.lpSearch) {
            this.setState({
                loading: true
            });

            this.props.fetchFulfillmentLicenses(
                this.props.client_id, this.state.lpSearch, this.props.action, this.props.line.inventory_id
            ).then(res => {
                if (res.length > 0) {
                    this.props.updateFulfillmentModelByForm('license', res[0].license, false);
                    this.setState({
                        loading: false,
                        lpError: false
                    });
                } else {
                    this.setState({
                        loading: false,
                        lpError: true
                    });
                }
            }).catch(err => {
                this.handleAxiosError(err);
            });
        }
    }

    onSerialSelected = (serial) => {
        if (_.isObject(serial)) {
            this.props.updateFulfillmentModelByForm('serialId', serial.id, false);
            this.props.updateFulfillmentModelByForm('quantity', serial.units, false);
        }
    }

    onLotSelected = (lot) => {
        if (lot) {
            this.props.updateFulfillmentModelByForm('lotId', lot.id, false);
            this.props.updateFulfillmentModelByForm('newLot', {
                lot: "",
                expire: ""
            }, false)
        } else {
            this.props.updateFulfillmentModelByForm('lotId', "", false);
            this.props.updateFulfillmentModelByForm('newLot', {
                lot: "",
                expire: ""
            }, false)
        }
    }

    onDeleteFulfillmentLineClick = (fulfillment, index) => {
        const {inventory} = this.props.line;
        const {line} = this.props;
        this.props.deleteFulfillmentLine(line.shipment_id, line.id, fulfillment.id, this.props.index, index).catch(err => {
            if (inventory.config.serial) {
                this.props.fetchFulfillmentSerisl(inventory.id);
            }
            this.handleAxiosError(err);
        })
    }

    byPassLicesenControlledLotClicked = (nextState) => {
        this.setState({
            useLinceseLot: nextState
        });
        this.props.updateFulfillmentModelByForm('lotId', "", false);
        this.props.updateFulfillmentModelByForm('newLot', {
            lot: "",
            expire: ""
        }, false)
        if (!nextState) {
            const {inventory} = this.props.line;
            this.props.fetchFulfillmentLot(inventory.id);
        }
    }

    render() {
        const {
            model, modalOpen, line, t, updateFulfillmentModelByForm
        } = this.props;
        const {config} = line.inventory;
        return (
            <div>
                <Header line={line} action={this.props.action} useLinceseLot={this.state.useLinceseLot} byPassLicesenControlledLotClicked={(nextState) => {
                    this.byPassLicesenControlledLotClicked(nextState)
                }}/>
                {modalOpen ? <div>
                    {!_.isEmpty(this.state.errors) ? (
                        <FormError errors={this.state.errors} />
                    ) : null}

                <Form size="mini" onSubmit={e => { e.preventDefault(); }}>
                    <Form.Group widths='equal'>
                        <Form.Input
                            fluid
                            loading={this.state.loading}
                            value={this.state.lpSearch}
                            onChange={(e, {value}) => {
                                this.setState({lpSearch: value})
                            }}
                            error={this.state.lpError}
                            label={`${t('FulfillEnterLp')} ${model.license ? `- ${t('FulfillLpSelected')} ${model.license}` : ''}`}
                            icon={!this.state.lpError && model.license ? 'check' : 'times' }
                            iconPosition='left'
                            onKeyPress={(e) => {this.onSearchLpPressed(e)}}
                        />
                        {config.serial ? <SerialSelector
                            onSelectChange={(sModel) => {
                                this.onSerialSelected(sModel);
                            }}
                            label={t('SerialLead')}
                            serials={this.props.serials}
                            selected={model.serialId}
                        /> : null}
                        {config.lot ? <LotSelector
                            useLicenseLot={this.state.useLinceseLot}
                            onSelectChange={(sModel) => {
                                this.onLotSelected(sModel);
                            }}
                            label={t('ExistingLot')}
                            lots={this.props.action === 'outbound' && this.state.useLinceseLot ? this.props.license : this.props.lots}
                            selected={model.lotId}
                            action={this.props.action}
                        /> : null}
                    </Form.Group>
                    {this.props.action === 'inbound' ? <Form.Group widths='equal'>
                        {config.lot && !model.lotId ? <Form.Input
                            fluid
                            value={model.newLot.lot}
                            onChange={(e, {value}) => {
                                updateFulfillmentModelByForm('lot', value, true)
                            }}
                            label={t('LotNumber')}
                            icon= 'barcode'
                            iconPosition='left'
                        /> : null}
                        {config.lot && !model.lotId ? <Form.Input
                            fluid
                            value={model.newLot.expire}
                            type='date'
                            onChange={(e, {value}) => {
                                updateFulfillmentModelByForm('expire', value, true)
                            }}
                            label={t('ExpireDate')}
                            icon= 'calendar alternate'
                            iconPosition='left'
                        /> : null}
                    </Form.Group> : null}
                    <Form.Input
                        fluid
                        value={model.quantity}
                        type='number'
                        onChange={(e, {value}) => {
                            updateFulfillmentModelByForm('quantity', value, false)
                        }}
                        label={t('FulfillQuantity')}
                        icon= 'calculator'
                        iconPosition='left'
                    />
                    {this.props.action === 'inbound' ? <Form.Checkbox
                        label={t('FulfillQuarantine')}
                        name="quarantine"
                        onChange={(e,data) => { updateFulfillmentModelByForm('quarantine', data.checked, false) }}
                        checked={model.quarantine}
                    /> : null}
                    {this.props.action === 'inbound' && model.quarantine ? <Form.Input
                        fluid
                        value={model.reference}
                        type='text'
                        onChange={(e, {value}) => {
                            updateFulfillmentModelByForm('reference', value, false)
                        }}
                        label={t('Reason')}
                    /> : null}
                </Form>
                <div className="row lara-row-margin">
                    <div className="col-md-12">
                        <PrimaryButton
                            size='mini'
                            float='left'
                            label={t('Fulfill')}
                            onClick={() => {this.onFormSubmit()}}
                            loading={this.state.loading}
                        />
                    </div>
                </div>
                <div className="lara-row-margin">
                    <FulfillmentTable
                        t={t}
                        fulfillments={line.fulfillments}
                        canDelete={this.props.canDelete}
                        onDeleteClick={(f, index) => {
                            this.onDeleteFulfillmentLineClick(f, index);
                        }}
                    />
                </div>
                </div> : null}
            </div>
        )
    }
}

LineFulfillmentForm.propTypes = {
    onFulfillmentModalClose: PropTypes.func.isRequired,
    updateFulfillmentModelByForm: PropTypes.func.isRequired,
    fetchFulfillmentLicenses: PropTypes.func.isRequired,
    fetchFulfillmentSerisl: PropTypes.func.isRequired,
    saveFulfillmentModel: PropTypes.func.isRequired,
    fetchFulfillmentLot: PropTypes.func.isRequired,
    deleteFulfillmentLine: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    line: PropTypes.shape({
        id: PropTypes.number.isRequired,
        fulfillments: PropTypes.array.isRequired
    }),
    model: PropTypes.object.isRequired,
    serials: PropTypes.array.isRequired,
    modalOpen: PropTypes.bool.isRequired,
    client_id: PropTypes.number.isRequired,
    action: PropTypes.string.isRequired,
    license: PropTypes.array.isRequired,
    lots: PropTypes.array.isRequired,
    canDelete: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
    return {
        index: state.fulfillment.shipmentLineIndex,
        line: state.fulfillment.shipmentLine,
        model: state.fulfillment.fulfillmentModel,
        license: state.fulfillment.resources.license,
        serials: state.fulfillment.resources.serials,
        lots: state.fulfillment.resources.lots,
        modalOpen: state.fulfillment.modalOpen
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        onFulfillmentModalClose,
        updateFulfillmentModelByForm,
        fetchFulfillmentLicenses,
        fetchFulfillmentSerisl,
        saveFulfillmentModel,
        fetchFulfillmentLot,
        deleteFulfillmentLine
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(LineFulfillmentForm)
