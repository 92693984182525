import _ from 'lodash';
import moment from 'moment';
import Alert from 'react-s-alert';
import XLSX from 'xlsx';

const Lara = {
    _uid: () => {
        return '_' + Math.random().toString(36).substr(2, 9);
    },
    axiosError({response}, t = null) {
        if (typeof response !== 'object') {
            //If it's not a form submission error, we return an object
            return {
                toast: true,
                support: null,
                http_code: null,
                message: typeof t === 'function' ? t("Unexpected submission failure. Please contact support for help") : "Unexpected submission failure. Please contact support for help",
                reference: null
            }
        }

        const {status, data} = response;

        if (status === 422) {
            //If it's a form submission error, we return an array
            const messages = [];
            const {errors} = data;
            if (_.isObject(errors)) {
                _.each(errors, (value) => {
                    if (_.isString(value)) {
                        messages.push(value);
                    }
                    if (_.isArray(value)) {
                        _.each(value, (message) => {
                            messages.push(message);
                        })
                    }
                })
            }
            return {
                toast: false,
                support: null,
                http_code: status,
                message: messages,
                reference: null
            }
        }

        if (status === 401) {
            return {
                toast: false,
                support: null,
                http_code: 422,
                message: [
                    typeof t === 'function' ? t('AuthenticationFailed') : 'Authentication Failed'
                ],
                reference: null
            }
        }

        if (status === 403) {
            return {
                toast: true,
                support: null,
                http_code: 400,
                message: typeof t === 'function' ? t('UnableToAccess') : 'Unable to access: your role does not have access to this resource',
                reference: null
            }
        }

        if (status === 400) {
            return {
                toast: true,
                //support: this.zendeskList(data.error_code),
                support: null,
                http_code: 400,
                message: typeof t === 'function' ? t(_.toString(data.error_code)) : data.message,
                reference: data.message
            }
        }

        if (status === 500) {
            return {
                toast: true,
                support: null,
                //support: this.zendeskList(data.error_code),
                http_code: 500,
                message: typeof t === 'function' ? t('500Error') : "An unexpected error has occurred on the server. ",
                reference: typeof t === 'function' ? `${t('500ErrorRef')}: ${data.message}`: `Please reference ${data.message} when you contact support`,
            }
        }

        if (status === 504) {
            return {
                toast: true,
                support: null,
                //support: this.zendeskList(10000),
                http_code: 504,
                message: typeof t === 'function' ? t(_.toString('504Error')) : "Server is not responding, please try again",
                reference: data.message
            }
        }

        return {
            toast: true,
            support: null,
            http_code: status,
            message: typeof t === 'function' ? t('AnUnexpectedErrorHasOccurred') : 'An unexpected error has occurred, please contact support or try again later',
            reference: data.message
        }
    },

    axiosAlert(component) {
        Alert.error(component, {
            position: 'bottom',
            timeout: 'none'
        });
    },

    alertSuccess(message) {
        Alert.success(message, {
            position: 'bottom',
            timeout: 'none'
        });
    },

    alertError(message) {
        Alert.error(message, {
            position: 'bottom',
            timeout: 'none'
        });
    },

    zendeskList(code) {
        const list = require('./data/codes');
        return list[code]
    },

    findFromResourceArray: (value, key, resources = [], toString = true) => {
        const result = resources.filter(r => {
            if (toString) {
                return _.toString(value) === _.toString(r[key]);
            } else {
                return value === r[key];
            }
        });
        if (result.length > 0) {
            return result[0]
        };
        return null
    },

    models: {
        inventory: () => {
            return {
                id: null,
                type: 'Item',
                sku: "",
                name: "",
                cost: "",
                selling: "",
                msrp: "",
                is_active: true,
                config: {
                    lot: false,
                    serial: false,
                },
                international: {
                    hs_code: "",
                    custom_value: "",
                    origin: "",
                    composition: ""
                },
                custom_1: "",
                custom_2: "",
                custom_3: ""
            }
        },
        relation: (type) => {
            return {
                id: null,
                client_id: null,
                name: "",
                account: "",
                email: "",
                phone: "",
                type: type === 'customer' ? 'customer' : 'vendor',
                is_active: true,
                default_term: "0",
                default_tax_rate: "0",
                default_instruction: "",
                print_instruction: false,
                default_courier_account: "",
                default_collect_postal: "",
                default_collect_country: "",
                default_shipping_payment: "prepaid",
                addresses: []
            }
        },
        order: (type) => {
            return {
                id: null,
                client_id: null,
                relation_id: "0",
                shipping_address_id: "0",
                billing_address_id: "0",
                shipping_address: {

                },
                billing_address: {

                },
                type: type,
                status: 'Open',
                reference: "",
                po: "",
                accounting: {
                    tax_rate: 0,
                    payment_term: ""
                },
                source:  {
                    source: "Manual",
                    source_id: _.uniqueId(),
                    source_key: null
                },
                shipping: {
                    api: "Manual",
                    courier_id: "0",
                    payment: "prepaid",
                    collect_account: {
                        account: "",
                        collect_country_code: "",
                        collect_zip_code: ""
                    }
                },
                note: "",
                print_note: "",
                api_credential_id: null,
                web_origin: "",
                relation: {

                },
                lines: [],
                shipments: [],
                categories: [],
                comments: []
            }
        },
        apiCredential: (type) => {
            return {
                name: '',
                type: type,
                client_id: 0,
                key_1: '',
                key_2: '',
                key_3: '',
                key_4: '',
                o_auth_token: '',
                verified: 0,
                mapping: {},
                key_5: '',
                create_new_item: false,
                auto_process: true,
                store_time_zone: 'America/New_York',
                synchronize: {}
            }
        },
        location: () => {
            return {
                id: null,
                type: 'P',
                location: '',
                billing_category: ''
            }
        },
        license: () => {
            return {
                id: null,
                license: "",
                uom_id: "0",
                client_id: "0",
                length: 1,
                height: 1,
                width: 1,
                weight: 1
            }
        },
        serial: () => {
            return {
                id: null,
                inventory_id: "",
                increment: 1,
                lead: "",
                units: 0,
                inventory: {},
                serials: []
            }
        },
        role: () => {
            return {
                id: null,
                name: "",
                access: {
                    update_company_info: true,
                    view_client_list: true,
                    view_client_model: true,
                    create_client_model: true,
                    update_client_model: true,
                    update_client_address: true,
                    update_client_detail: true,
                    view_user_list: true,
                    view_user_model: true,
                    create_user_model: true,
                    update_user_model: true,
                    adjust_inventory: true,
                    create_role_model: true,
                    update_role_model: true,
                    create_general: true,
                    update_general: true,
                    delete_general: true,
                    create_courier: true,
                    update_courier: true,
                    delete_courier: true,
                    view_api_list: true,
                    view_api_model: true,
                    create_api_model: true,
                    update_api_model: true,
                    delete_api_model: true,
                    view_location_list: true,
                    create_location_model: true,
                    update_location_model: true,
                    delete_location_model: true,
                    view_license_list: true,
                    create_license_model: true,
                    update_license_model: true,
                    breakdown_license_model: true,
                    delete_license_model: true,
                    put_license_to_location: true,
                    view_serial_list: true,
                    create_serial_model: true,
                    update_serial_model: true,
                    view_serial_model: true,
                    delete_serial_model: true,
                    view_shipments_list: true,
                    view_shipment_model: true,
                    update_shipment_tracking: true,
                    void_shipment: true,
                    fulfill_shipment: true,
                    delete_fulfillment_line: true,
                    view_activity_list: true,
                    view_activity_model: true,
                    create_activity_model: true,
                    update_activity_model: true,
                    update_billing_items: true,
                    view_billing_item_list: true,
                    view_billing_item_model: true,
                    create_billing_item_model: true,
                    update_billing_item_model: true,
                    run_billing_report: true,
                    view_entry_list: true,
                    create_entry_model: true,
                    update_entry_model: true,
                    delete_billing_entry: true
                },
                is_active: 1
            }
        },
        user: () => {
            return {
                id: null,
                name: "",
                email: "",
                is_su: false,
                is_main_user: true,
                is_active: true,
                role_id: "0",
                client_id: "0",
                avatar_path: "user_1.png",
                lang: "en"
            }
        },
        setting: (model) => {
            let result = {};
            if (model === 'Term') {
                result = {
                    name: '',
                    description: ''
                }
            }
            if (model === 'Tax') {
                result = {
                    name: '',
                    rate: 0
                }
            }
            if (model === 'Package') {
                result = {
                    name: '',
                    length: 1,
                    width: 1,
                    height: 1,
                    weight: 1
                }
            }
            if (model === 'Uom') {
                result = {
                    name: '',
                    is_active: true
                }
            }
            if (model === 'Courier') {
                result = {
                    name: '',
                    account: '',
                    payment_method: 'prepaid',
                    ss_mapping_carrier: '',
                    ss_mapping_service: '',
                    ss_mapping_package: '',
                    client_id: "0",
                    billing_zip: '',
                    billing_country_code: 'US',
                    status: true,
                    shippo_id: '',
                    shippo_service: '',
                    shippo_address_id: null
                }
            }
            return result;
        },
        client: () => {
            return {
                company: "",
                email: "",
                account: "",
                is_active: true,
                address: {
                    company: "",
                    contact: "",
                    street1: "",
                    street2: "",
                    street3: "",
                    city: "",
                    postal_code: "",
                    state_code: "",
                    country_code: "",
                    phone: ""
                },
                detail: {
                    use_ship_station: false,
                    ss_warehouse: "0",
                    invoice_prefix: "",
                    next_inv_num: "1000",
                    packing_slip_prefix: "",
                    next_picking_num: "1000",
                    email_upon_receiving: false,
                    email_upon_shipping: false,
                    shipping_notification_emails: [],
                    receiving_notification_emails: [],
                    shipping_charge_modify: 0,
                    logo_uri: "",
                    qb_mapping_name: "",
                    invoice_term: "",
                    due_date: "",
                    order_notification: "None",
                    order_notification_email: "",
                    tax_code: "",
                    tax_rate: ""
                }
            }
        }
    },

    excel: {
        outboundShipmentSchema: (shipmentModel) => {
            if (!_.isObject(shipmentModel)) {
                return null;
            }
            if (!shipmentModel.id) {
                return null;
            }
            if (!_.isArray(shipmentModel.shipment_lines)) {
                return null;
            }
            const ts = moment().format('LLL').toString();
            const wb = XLSX.utils.book_new();
            const linesArray = [
                ["ShipmentId","SKU","Name","Description","CV1","CV2","CV3","OrderedQty","PickQty","Bin","LPID","License","LotID","Lot","Expire","OnHandAtBin"]
            ];
            const serial_items = [
                ["SKU","TotalPick","SerialNumber"]
            ]
            _.each(shipmentModel.shipment_lines, l => {
                if (l.inventory.config && l.inventory.config.serial) {
                    for(let i = 0; i < l.quantity; i++) {
                        serial_items.push([l.inventory.sku, l.quantity, ""])
                    }
                }
                if(_.isArray(l.inventory.picking_locations)) {
                    if (l.inventory.picking_locations.length > 0) {
                        _.each(l.inventory.picking_locations, location => {
                            linesArray.push([
                                shipmentModel.id, l.inventory.sku, l.inventory.name, l.inventory.description,
                                l.inventory.custom_1,l.inventory.custom_2,l.inventory.custom_3,
                                parseInt(l.quantity,10), 0, location.location, location.license_id,
                                location.license, location.lot_id,
                                location.lot_number === '_SystemNoLotNumber' ? '' : location.lot_number,
                                location.lot_number === '_SystemNoLotNumber' ? '' : location.lot_expire,
                                parseInt(location.qty_left, 10)
                            ]);
                        })
                    } else {
                        linesArray.push([
                            shipmentModel.id, l.inventory.sku, l.inventory.name, l.inventory.description,
                            l.inventory.custom_1,l.inventory.custom_2,l.inventory.custom_3,
                            parseInt(l.quantity,10), 0, '', '',
                            '', '', '', '', ''
                        ]);
                    }
                } else {
                    linesArray.push([
                        shipmentModel.id, l.inventory.sku, l.inventory.name, l.inventory.description,
                        l.inventory.custom_1,l.inventory.custom_2,l.inventory.custom_3,
                        parseInt(l.quantity,10), 0, '', '',
                        '', '', '', '',''
                    ]);
                }
            })
            const sheetSchema = XLSX.utils.aoa_to_sheet(linesArray);
            const sheetInstruction = XLSX.utils.aoa_to_sheet([
                ["ColumnName","Required","ValueOption","Detail"],
                ["ShipmentId","No","Any","Not required, for your reference only."],
                ["SKU","Yes","An existing SKU from this shipment","If you have multiple rows for the same SKU (i.e. different LP number), make sure all rows have SKU"],
                ["Name","No","Any","Not required, for your reference only"],
                ["Description","No","Any","Not required, for your reference only"],
                ["CV1","No","Any","Not required, for your reference only (custom field 1)"],
                ["CV2","No","Any","Not required, for your reference only (custom field 1)"],
                ["CV3","No","Any","Not required, for your reference only (custom field 1)"],
                ["Ordered","No","Any","Not required, for your reference only."],
                ["PickQty","Yes","Positive Integer","Enter quantity picked under this row's LP and Lot combination"],
                ["LPID","Yes","Original Exported Value","Do not add, delete or change the LP ID value. This is what the system uses to tracking LP picked from"],
                ["License","No","Any","The LP number in this location"],
                ["LotID","Yes","Original Exported Value","Do not add, delete or change the Lot ID value even if the SKU does not need Lot control. This is what the system uses to tracking Lot picked from"],
                ["Lot","No","Any","The Lot # contained in this LP"],
                ["OnHandAtBin","No","Any","Quantity at the bin location"],
                [],
                [],
                ["Using This Workbook",""],
                ["1","You must upload this file under the matching shipment page. Do not attempt to upload a file with a shipment ID different from your web page."],
                ["2","It is suggested to format your entire sheet to TEXT, especially if the product SKU is a number"],
                ["3","Please do not delete any sheet from the workbook"],
                ["4","Please make sure the Schema sheet is the first sheet in the workbook"],
                ["5","You can edit this file using MS Excel or Google Doc."],
                ["6","LibreOffice and WPS are known to create formatting problems"],
                ["7","This file is exported on: " + ts],
                ["8","Please limit number of shipping lines per upload to 50"],
                ["9","If any item require serial number, a separate sheet called SerialNumbers will be created"],
                ["10-1","The file will create one or multiple rows based on number of units you need pick"],
                ["10-2","Fill in the sheet one serial number per row"],
                ["10-3","Do not add, change or delete the first column (SKU)"],
                ["10-4","Please make sure the SerialNumber sheet is the second sheet."]
            ]);
            XLSX.utils.book_append_sheet(wb, sheetSchema, 'Schema');
            if (serial_items.length > 1) {
                XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet(serial_items), 'SerialNumbers');
            }
            XLSX.utils.book_append_sheet(wb, sheetInstruction, 'Instruction');
            XLSX.writeFile(wb, `ShippingExcelFile-${shipmentModel.id}.xlsx`);
        },
        shipmentSchema: (shipmentModel) => {
            if (!_.isObject(shipmentModel)) {
                return null;
            }
            if (!shipmentModel.id) {
                return null;
            }
            if (!_.isArray(shipmentModel.shipment_lines)) {
                return null;
            }
            const ts = moment().format('LLL').toString();
            const wb = XLSX.utils.book_new();
            const linesArray = [
                ["ShipmentId","SKU","Name", "Ordered","RowQty","License","Quarantine","Lot","Expire"]
            ];
            _.each(shipmentModel.shipment_lines, l => {
                linesArray.push([
                    shipmentModel.id, l.inventory.sku, l.inventory.name, parseInt(l.quantity,10), "", "", "N"
                ]);
            })
            const sheetSchema = XLSX.utils.aoa_to_sheet(linesArray);
            const sheetInstruction = XLSX.utils.aoa_to_sheet([
                ["ColumnName","Required","ValueOption","Detail"],
                ["ShipmentId","No","Any","Not required, for your reference only."],
                ["SKU","Yes","An existing SKU from this shipment","If you have multiple rows for the same SKU (i.e. different LP number), make sure all rows have SKU"],
                ["Name","No","Any","Not required, for your reference only"],
                ["Ordered","No","Any","Not required, for your reference only."],
                ["RowQty","Yes","Positive Integer","Quantity to fulfill in this row. Must be a positive integer and less or equal to total quantity ordered per SKU. Total quantities for a single SKU in multiple rows should also be less or equal to ordered."],
                ["License","No","Any","New LP will be created if not exist in the system"],
                ["Quarantine","Yes","Y or N","Use Y or N to indicate this row is a quarantined entry."],
                ["Lot","No","Any","Only items with lot control will be saved"],
                ["Expire","No","Date","YYYY-MM-DD format only"],
                [],
                [],
                ["Using This Workbook",""],
                ["1","You must upload this file under the matching shipment page. Do not attempt to upload a file with a shipment ID different from your web page."],
                ["2","Please do not delete any sheet from the workbook"],
                ["3","Please make sure the Schema sheet is the first sheet in the workbook"],
                ["4","You can edit this file using MS Excel or Google Doc."],
                ["5","LibreOffice and WPS are known to create formatting problems"],
                ["6","This file is exported on: " + ts],
                ["7","Please limit number of receiving lines per upload to 50"],
            ]);
            XLSX.utils.book_append_sheet(wb, sheetSchema, 'Schema');
            XLSX.utils.book_append_sheet(wb, sheetInstruction, 'Instruction');
            XLSX.writeFile(wb, `ReceivingExcelFile-${shipmentModel.id}.xlsx`);
        },
        putaway: (clients, uoms, measure) => {
            let weight = 'Kg';
            let length = 'CM';
            if (measure === 'imperial') {
                weight = 'Lb';
                length = 'Inch'
            }
            const t = moment().format('MM-DD-HH-MM').toString();
            const ts = moment().format('LLL').toString();
            const wb = XLSX.utils.book_new();
            const sheetSchema = XLSX.utils.aoa_to_sheet([
                ["LicenseNumber","LocationNumber","ClientId","UomId","Length","Width","Height","Weight"]
            ]);
            const availableClients = [["ID","Company"]];
            _.each(clients, c => {
                availableClients.push([
                    c.id, c.company
                ])
            });
            const sheetAvailableClient = XLSX.utils.aoa_to_sheet(availableClients);
            const availableUoms = [["ID","Name"]];
            _.each(uoms, c => {
                availableUoms.push([
                    c.id, c.name
                ])
            });
            const sheetAvailableUom = XLSX.utils.aoa_to_sheet(availableUoms);
            const sheetInstruction = XLSX.utils.aoa_to_sheet([
                ["ColumnName","Required","ValueOption","Detail"],
                ["LicenseNumber","Yes","Any","LP number, can be a new licnese plate."],
                ["LocationNumber","Yes","Any","Location bin number, must exist in the system."],
                ["ClientId","No","Any","The matching ID of the AvailalbeClient sheet. Only required if license does not exist."],
                ["UomId","No","Any","The matching ID of the AvailableUomSheet sheet. Only required if license does not exist."],
                ["Length","No","Any",`Length in ${length}, default to 1 if it's empty`],
                ["Width","No","Any",`Width in ${length}, default to 1 if it's empty`],
                ["Height","No","Any",`Height in ${length}, default to 1 if it's empty`],
                ["Weight","No","Any",`Weight in ${weight}, default to 1 if it's empty`],
                [],
                [],
                [],
                ["Using This Workbook",""],
                ["1","Please do not delete any sheet from the workbook"],
                ["2","Please make sure the Schema sheet is the first sheet in the workbook"],
                ["3","You can edit this file using MS Excel or Google Doc."],
                ["4","LibreOffice and WPS are known to create formatting problems"],
                ["5","This file is exported on: " + ts]
            ]);

            XLSX.utils.book_append_sheet(wb, sheetSchema, 'Schema');
            XLSX.utils.book_append_sheet(wb, sheetAvailableClient, 'AvailableClients');
            XLSX.utils.book_append_sheet(wb, sheetAvailableUom, 'AvailableUoms');
            XLSX.utils.book_append_sheet(wb, sheetInstruction, 'Instructions');
            XLSX.writeFile(wb, `Putaway-${t}.xlsx`);
        },
        locations: () => {
            const t = moment().format('MM-DD-HH-MM').toString();
            const ts = moment().format('LLL').toString();
            const wb = XLSX.utils.book_new();
            const sheetSchema = XLSX.utils.aoa_to_sheet([
                ["Bin_Number","Location_Type","Billing_Category"]
            ]);
            const sheetInstruction = XLSX.utils.aoa_to_sheet([
                ["ColumnName","Required","ValueOption","Detail"],
                ["Bin_Number","Yes","Any","Bin location name, for example - Aisle 1 Bin 1"],
                ["Location_Type","Yes","P or S","Use P for picking and S for storage"],
                ["Billing_Category","No","Any","Used for daily location billing. Different categories can have different storage charge rate"],
                [],
                [],
                [],
                ["Using This Workbook",""],
                ["1","Please do not delete any sheet from the workbook"],
                ["2","Please make sure the Schema sheet is the first sheet in the workbook"],
                ["3","You can edit this file using MS Excel or Google Doc."],
                ["4","LibreOffice and WPS are known to create formatting problems"],
                ["5","This file is exported on: " + ts]
            ]);
            XLSX.utils.book_append_sheet(wb, sheetSchema, 'Schema');
            XLSX.utils.book_append_sheet(wb, sheetInstruction, 'Instruction');
            XLSX.writeFile(wb, `Location-${t}.xlsx`);
        },
        relations: (couriers) => {
            const t = moment().format('MM-DD-HH-MM').toString();
            const ts = moment().format('LLL').toString();
            const wb = XLSX.utils.book_new();
            const sheetSchema = XLSX.utils.aoa_to_sheet([
                [
                    "Type","Name","Account","Email","Phone","Status",
                    "DefaultTerm","DefaultCourier","DefaultTaxRate","DefaultInstruction","PrintInstruction","DefaultCourierAccount",
                    "DefaultCollectCountry","DefaultCollectPostal","DefaultShippingPayment","AddressType","BillingCompany","BillingContact",
                    "BillingPhone","BillingStreet1","BillingStreet2","BillingStreet3","BillingCity","BillingPostalCode",
                    "BillingStateCode","BillingCountryCode","ShippingCompany","ShippingContact","ShippingPhone","ShippingStreet1",
                    "ShippingStreet2","ShippingStreet3","ShippingCity","ShippingPostalCode","ShippingStateCode","ShippingCountryCode"
                ]
            ]);
            const sheetInstruction = XLSX.utils.aoa_to_sheet([
                ["ColumnName","Required","ValueOption","Detail"],
                ["Type","Yes","customer or vendor","Value should be lower case"],
                ["Name","Yes","Any","Name of the relation. A relation can be a vendor or a customer. Existing relations with the same name will be updated"],
                ["Account","No","Any",""],
                ["Email","No","Valid email address",""],
                ["Phone","No","Any",""],
                ["Status","No","0 or 1","Unless you specifically put 0 here, the relation will be active"],
                ["DefaultTerm","No","Any","For example, NET 15"],
                ["DefaultCourier","No","See Couriers Sheet","If this is not empty, it must match one of the courier names in the couriers sheet"],
                ["DefaultTaxRate","No","Number","For example, 0.05"],
                ["DefaultInstruction","No","Any",""],
                ["PrintInstruction","No","Any",""],
                ["DefaultCourierAccount","No","Any","For collect or third party common courier accounts"],
                ["DefaultCollectCountry","No","2 Character","For collect or third party collect account, use 2 character country code"],
                ["DefaultCollectPostal","No","Any","For collect or third party collect account"],
                ["DefaultShippingPayment","No","prepaid, collect or third","Value should be lower case, default to prepaid if empty"],
                ["AddressType","No","single or both","If value is single, it will import shipping and billing address. If value is both, only billing address will be imported as billing and shipping. If no value is provided, no address will be imported"],
                ["Company","No","Any",""],
                ["Contact","No","Any",""],
                ["Phone","No","Any",""],
                ["Street1","No","Any",""],
                ["Street2","No","Any",""],
                ["Street3","No","Any",""],
                ["City","No","Any",""],
                ["PostalCode","No","Any",""],
                ["StateCode","No","Any","Two character state code for United States and Canada"],
                ["CountryCode","No","Any","Two character country code"],
                [],
                [],
                [],
                ["Using This Workbook",""],
                ["1","Please do not delete any sheet from the workbook"],
                ["2","Please make sure the Schema sheet is the first sheet in the workbook"],
                ["3","You can edit this file using MS Excel or Google Doc."],
                ["4","LibreOffice and WPS are known to create formatting problems"],
                ["5","This file is exported on: " + ts]
            ]);
            const courierOptions = [["Available Couriers"]];
            _.each(couriers, c => {
                courierOptions.push([c.name]);
            })
            const sheetCouriers = XLSX.utils.aoa_to_sheet(courierOptions);
            XLSX.utils.book_append_sheet(wb, sheetSchema, 'Schema');
            XLSX.utils.book_append_sheet(wb, sheetInstruction, 'Instruction');
            XLSX.utils.book_append_sheet(wb, sheetCouriers, 'Couriers');
            XLSX.writeFile(wb, `Relation-${t}.xlsx`);
        },
        inventories: () => {
            const t = moment().format('MM-DD-HH-MM').toString();
            const ts = moment().format('LLL').toString();
            const wb = XLSX.utils.book_new();
            const sheetSchema = XLSX.utils.aoa_to_sheet([
                [
                    "SKU","Name","UPC","Barcode_1","Barcode_2","Description","Cost","Selling",
                    "MSRP","Status","Custom_1","Custom_2","Custom_3","Hs_Code","Custom_Value",
                    "Origin","Composition","Require_Lot","Require_Serial"
                ]
            ]);
            const sheetInstruction = XLSX.utils.aoa_to_sheet([
                ["ColumnName","Required","ValueOption","Detail"],
                ["SKU","Yes","Any","Existing SKU will be updated."],
                ["Name","Yes","Any",""],
                ["UPC","No","Any",""],
                ["Barcode_1","No","Any",""],
                ["Barcode_2","No","Any",""],
                ["Description","No","",""],
                ["Cost","No","Number",""],
                ["Selling","No","Number",""],
                ["MSRP","No","Number",""],
                ["Status","No","0 or 1","Unless you specifically put 0 here, the inventory item will be active"],
                ["Custom_1","No","Any",""],
                ["Custom_2","No","Any",""],
                ["Custom_3","No","Any",""],
                ["Hs_Code","No","Any",""],
                ["Custom_Value","No","Any",""],
                ["Origin","No","Any",""],
                ["Composition","No","Any",""],
                ["Require_Lot","No","0 or 1","Default is no lot control. Put 1 here otherwise"],
                ["Require_Serial","No","0 or 1","Default is no lot control. Put 1 here otherwise"],
                ["","","",""],
                [],
                [],
                [],
                ["Using This Workbook",""],
                ["1","Please do not delete any sheet from the workbook"],
                ["2","Please make sure the Schema sheet is the first sheet in the workbook"],
                ["3","You can edit this file using MS Excel or Google Doc."],
                ["4","LibreOffice and WPS are known to create formatting problems"],
                ["5","This file is exported on: " + ts]
            ]);
            XLSX.utils.book_append_sheet(wb, sheetSchema, 'Schema');
            XLSX.utils.book_append_sheet(wb, sheetInstruction, 'Instruction');
            XLSX.writeFile(wb, `Inventory-${t}.xlsx`);
        },
        uoms: (uoms, measure) => {
            let weight = 'Kg';
            let length = 'CM';
            if (measure === 'imperial') {
                weight = 'Lb';
                length = 'Inch'
            }
            const t = moment().format('MM-DD-HH-MM').toString();
            const ts = moment().format('LLL').toString();
            const wb = XLSX.utils.book_new();
            const sheetSchema = XLSX.utils.aoa_to_sheet([
                [
                    "SKU","UOM","BaseUOM","QtyPerBase","Weight","Length",
                    "Width","Height"
                ]
            ]);
            const sheetInstruction = XLSX.utils.aoa_to_sheet([
                ["ColumnName","Required","ValueOption","Detail"],
                ["SKU","Yes","Any","Must exist in the system"],
                ["UOM","Yes","See UOMs Sheet","Must match on of available UOM"],
                ["BaseUOM","Yes","1 or 0","Must be 1 or 0, please do not leave this empty"],
                ["QtyPerBase","Yes","Number","If BaseUOM is 1, this must be 1"],
                ["Weight","Yes","Number",`Please enter a number in ${weight}, do not leave this emtpy. Use 0 or 1 if needed`],
                ["Length","Yes","Number",`Please enter a number in ${length}, do not leave this emtpy. Use 0 or 1 if needed`],
                ["Width","Yes","Number",`Please enter a number in ${length}, do not leave this emtpy. Use 0 or 1 if needed`],
                ["Height","Yes","Number",`Please enter a number in ${length}, do not leave this emtpy. Use 0 or 1 if needed`],
                [],
                [],
                [],
                ["Using This Workbook",""],
                ["1","[Impotant] This import will recreate all UOMs under a SKU. You should enter all UOMs in this file even if one or some of them already exist"],
                ["2","Please do not delete any sheet from the workbook"],
                ["3","Please make sure the Schema sheet is the first sheet in the workbook"],
                ["4","You can edit this file using MS Excel or Google Doc."],
                ["5","LibreOffice and WPS are known to create formatting problems"],
                ["6","This file is exported on: " + ts]
            ]);
            const courierOptions = [["Available Unit of Measures"]];
            _.each(uoms, c => {
                courierOptions.push([c.name]);
            })
            const sheetCouriers = XLSX.utils.aoa_to_sheet(courierOptions);
            XLSX.utils.book_append_sheet(wb, sheetSchema, 'Schema');
            XLSX.utils.book_append_sheet(wb, sheetInstruction, 'Instruction');
            XLSX.utils.book_append_sheet(wb, sheetCouriers, 'UOMs');
            XLSX.writeFile(wb, `InventoryUom-${t}.xlsx`);
        },
        orders: (couriers) => {
            const t = moment().format('MM-DD-HH-MM').toString();
            const ts = moment().format('LLL').toString();
            const wb = XLSX.utils.book_new();
            const sheetSchema = XLSX.utils.aoa_to_sheet([
                [
                    "OrderType","RelationName","Reference","CourierName","SKU","Quantity",
                    "Price","Comment","PO","TaxRate","PaymentTerm","ShippingPayment",
                    "CollectAccount","CollectPostalCode","CollectCountryCode","OrderNote","PrintNote","AutoProcess",
                    "BillingCompany","BillingContact","BillingPhone","BillingStreet1","BillingStreet2",
                    "BillingStreet3","BillingCity","BillingPostalCode","BillingStateCode","BillingCountryCode",
                    "ShippingCompany","ShippingContact","ShippingPhone","ShippingStreet1","ShippingStreet2",
                    "ShippingStreet3","ShippingCity","ShippingPostalCode","ShippingStateCode","ShippingCountryCode"
                ]
            ]);
            const sheetInstruction = XLSX.utils.aoa_to_sheet([
                ["ColumnName","Required","ValueOption","Detail"],
                ["OrderType","Yes","Customer Order, Vendor Order, Customer Return or Vendor Return",""],
                ["RelationName","Yes","Any","Must match existing customer or vendor name"],
                ["Reference","Yes","Any",""],
                ["CourierName","Yes","See Couriers Sheet","Must match an existing courier"],
                ["SKU","Yes","Any","Must match existing inventory record"],
                ["Quantity","Yes","Number",""],
                ["Price","No","Any",""],
                ["Comment","No","Max 255 characters","Picking comment for this item"],
                ["PO","No","Any",""],
                ["TaxRate","No","Number","For example: 0.05"],
                ["PaymentTerm","No","Any",""],
                ["ShippingPayment","No","prepaid, collect or third","Value should be lower case, default to prepaid if empty"],
                ["CollectAccount","No","Any","For collect or third party common courier accounts"],
                ["CollectPostalCode","No","Any","For collect or third party collect account"],
                ["CollectCountryCode","No","2 Character","For collect or third party collect account, use 2 character country code"],
                ["OrderNote","No","Any","Note for warehouse operations"],
                ["PrintNote","No","Any","Note to be displayed on packing slip or invoice"],
                ["AutoProcess","No","Y or N","Should the system create a shipment when order is created"],
                ["Company","No","Any",""],
                ["Contact","No","Any",""],
                ["Phone","No","Any",""],
                ["Street1","No","Any",""],
                ["Street1","No","Any",""],
                ["Street2","No","Any",""],
                ["Street3","No","Any",""],
                ["City","No","Any",""],
                ["PostalCode","No","Any",""],
                ["StateCode","No","Any","Two character state code for United States and Canada"],
                ["CountryCode","No","Any","Two character country code"],
                [],
                [],
                [],
                ["Using This Workbook",""],
                ["1","Please do not delete any sheet from the workbook"],
                ["2","Please make sure the Schema sheet is the first sheet in the workbook"],
                ["3","You can edit this file using MS Excel or Google Doc."],
                ["4","LibreOffice and WPS are known to create formatting problems"],
                ["5","This file is exported on: " + ts]
            ]);
            const courierOptions = [["Available Couriers"]];
            _.each(couriers, c => {
                courierOptions.push([c.name]);
            })
            const sheetCouriers = XLSX.utils.aoa_to_sheet(courierOptions);
            XLSX.utils.book_append_sheet(wb, sheetSchema, 'Schema');
            XLSX.utils.book_append_sheet(wb, sheetInstruction, 'Instruction');
            XLSX.utils.book_append_sheet(wb, sheetCouriers, 'Couriers');
            XLSX.writeFile(wb, `Order-${t}.xlsx`);
        },
        report: (data, name) => {
            if (name === 'AfterShip') {
                const t = moment().format('MM-DD').toString();
                const wb = XLSX.utils.book_new()
                const ws = XLSX.utils.json_to_sheet(data)
                XLSX.utils.book_append_sheet(wb, ws, 'test')
                XLSX.writeFile(wb, `${name}-${t}.csv`)
            } else {
                const t = moment().format('MM-DD').toString();
                const wb = XLSX.utils.book_new();
                const sheetSchema = XLSX.utils.json_to_sheet(data);
                XLSX.utils.book_append_sheet(wb, sheetSchema, 'Report');
                XLSX.writeFile(wb, `${name}-${t}.xlsx`);
            }
        },
        customerizedExcel: (lines) => {
            console.log(lines);
            const rows = [
                ['Item','Description','UOM','Lot','Exp','Ordered','Shipped']
            ];
            _.each(lines, line => {
                const {inventory} = line;
                let uom = "";
                const uomArray = _.filter(inventory.uoms, u => {return u.pivot.is_base === 1})
                if (uomArray.length === 1) {
                    uom = uomArray[0].name
                }
                if (line.fulfillments.length > 0) {
                    _.each(line.fulfillments, ful => {
                        rows.push([
                            inventory.sku,
                            inventory.name,
                            uom,
                            _.isObject(ful.lots) && ful.lots.lot !== '_SystemNoLotNumber' ? ful.lots.lot : "",
                            _.isObject(ful.lots) && ful.lots.lot !== '_SystemNoLotNumber' ? ful.lots.expire : "",
                            line.order_line.quantity,
                            Math.abs(ful.change)
                        ])
                    })
                } else {
                    rows.push([
                        inventory.sku,
                        inventory.name,
                        uom,
                        "",
                        "",
                        line.order_line.quantity,
                        0
                    ])
                }
            });
            const wb = XLSX.utils.book_new();
            const sheet = XLSX.utils.aoa_to_sheet(rows);
            XLSX.utils.book_append_sheet(wb, sheet, 'ItemList');
            XLSX.writeFile(wb, `ItemPackingList.xlsx`);
        }
    }
}

export default Lara;
