import React, { Component } from 'react';
import { bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {Button} from 'semantic-ui-react';
import Icon from '../../includes/svg/icon';
import {
    logout
} from '../../../actions/auth';

class ProfileCard extends Component {
    constructor() {
        super();
        this.state = {
            disabled: false
        }
    }
    //access router
    //this.props.history.push('/dashboard')
    _logout = () => {
        this.setState({disabled: true}, () => {
            localStorage.removeItem("lara_current_user_name");
            localStorage.removeItem("lara_current_user_email");
            this.props.logout();
            window.location.reload();
        });
    }

    render() {
        const {user, host, t} = this.props;
        const {avatar_path} = user;
        return (
            <div className="lara-nav-profile">
                <div className="lara-profile-image">
                    <img src={`${host}/avatars/${avatar_path}`} alt='User Avatar' className="lara-avatar"/>
                </div>
                <div className="lara-profile-user">
                    <div className="lara-profile-left">
                        <div className="lara-profile-user__name">{user.name}</div>
                        <div className="lara-profile-user__action">
                            <Link to='/profiles'>{t('MyProfile')}</Link>
                        </div>
                    </div>
                    <div className="lara-profile-right">
                        <Button color='blue'
                            basic
                            size='mini'
                            disabled={this.state.disabled}
                            loading={this.state.disabled}
                            onClick={() => {this._logout()}}>
                            <Icon icon="Logout" className="lara-profile-icon"/>
                        </Button>
                    </div>
                </div>
            </div>
        )
    }
}

ProfileCard.propTypes = {
    history: PropTypes.shape({
       push: PropTypes.func.isRequired
    }).isRequired,
    t: PropTypes.func.isRequired,
    host: PropTypes.string.isRequired,
    logout: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        user: state.system.user,
        host: state.system.preference.host
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        logout
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileCard)
