import React from 'react';
import PropTypes from 'prop-types';
import {
    Form
} from 'semantic-ui-react';
import {withI18n} from 'react-i18next';
import UomSelector from '../../includes/selectors/UomSelector';

const InventoryUomForm = ({model, onValueChange, t, associated, currentIndex}) => (
    <Form>
        {currentIndex === -1 ? <UomSelector
            onUomSelected={(value) => {onValueChange('uomId', value)}}
            selectedValue={parseInt(model.uomId,10)}
            associated={associated}
            label={t('UOM')}
        /> : null}
        <Form.Checkbox
            label={t('BaseUOM')}
            name="is_base"
            onChange={(e,data) => { onValueChange('is_base', data.checked) }}
            checked={model.is_base ? true : false}
        />
        <Form.Group widths='equal'>
            <Form.Input 
                fluid
                label={t("BaseQty")}
                name="qty_per_base"
                type='number'
                value={model.qty_per_base} 
                onChange={(e) => { onValueChange(e.target.name, e.target.value) }}
            />
            <Form.Input 
                fluid
                label={t("Weight")}
                name="weight"
                type='number'
                value={model.weight} 
                onChange={(e) => { onValueChange(e.target.name, e.target.value) }}
            />
        </Form.Group>
        <Form.Group widths='equal'>
            <Form.Input 
                fluid
                label={t("Length")}
                name="length"
                type='number'
                value={model.length} 
                onChange={(e) => { onValueChange(e.target.name, e.target.value) }}
            />
            <Form.Input 
                fluid
                label={t("Width")}
                name="width"
                type='number'
                value={model.width} 
                onChange={(e) => { onValueChange(e.target.name, e.target.value) }}
            />
            <Form.Input 
                fluid
                label={t("Height")}
                name="height"
                type='number'
                value={model.height} 
                onChange={(e) => { onValueChange(e.target.name, e.target.value) }}
            />
        </Form.Group>
    </Form>
);

InventoryUomForm.propTypes = {
    model: PropTypes.object.isRequired,
    onValueChange: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    currentIndex: PropTypes.number.isRequired
};


export default withI18n()(InventoryUomForm);