import {api} from '../api';
import _ from 'lodash';
import {
    PREFERENCE_UPDATED_BY_FORM,
    COURIER_UPDATED_ON_SERVER,
    COURIER_CREATED_ON_SERVER,
    CONFIGURATION_RESOURCE_CREATED,
    CONFIGURATION_RESOURCE_UDPATED,
    CONFIGURATION_RESOURCE_DELETED,
    SHIP_STATION_SHIPPING_LOADED,
    SHIPPO_OBJECTS_LOADED
} from './types';

export const saveCourierToServer = (index, model) => (dispatch) => {
    if (model.id) {
        return api.configurations.updateCourier(model).then(res => {
            dispatch({
                type: COURIER_UPDATED_ON_SERVER,
                payload: {
                    index, model
                }
            });
            return res;
        }).catch(err => {throw err});
    } else {
        return api.configurations.createCourier(model).then(res => {
            dispatch({
                type: COURIER_CREATED_ON_SERVER,
                payload: res.data.data
            });
            return res;
        }).catch(err => {throw err});
    }
}

export const saveConfigurationResourceToSever = (resource, index, model) => (dispatch) => {
    if (model.id) {
        return api.configurations.updateSetting(resource, model).then(res => {
            dispatch({
                type: CONFIGURATION_RESOURCE_UDPATED,
                payload: {
                    resource: modelNameToStoreName(resource),
                    index: index,
                    model: model
                }
            });
            return res;
        }).catch(err => {throw err})
    } else {
        return api.configurations.createSetting(resource, model).then(res => {
            dispatch({
                type: CONFIGURATION_RESOURCE_CREATED,
                payload: {
                    resource: modelNameToStoreName(resource),
                    model: res.data.data
                }
            });
            return res;
        }).catch(err => {throw err})
    }
}

export const deleteConfigurationResource = (resource, index, model) => (dispatch) => {
    return api.configurations.deleteSetting(resource, model).then(res => {
        dispatch({
            type: CONFIGURATION_RESOURCE_DELETED,
            payload: {
                resource:modelNameToStoreName(resource),
                index:index
            }
        });
        return res;
    }).catch(err => {throw err})
}

export const updatePreferenceInStore = (field, value) => {
    return {
        type: PREFERENCE_UPDATED_BY_FORM,
        payload: {
            field, value
        }
    }
}
export const updatePreferenceOnServer = (preference) => (dispatch) => {
    return api.system.updatePreference(preference).catch(err => {
        throw err;
    })
}
export const loadShipStation = () => (dispatch) => {
    return api.configurations.loadShipStationShippingResource().then(res => {
        dispatch({
            type: SHIP_STATION_SHIPPING_LOADED,
            payload: res.data.data
        });
        return res;
    })
}
export const loadShippo = () => (dispatch) => {
    return api.configurations.loadShippoResource().then(res => {
        dispatch({
            type: SHIPPO_OBJECTS_LOADED,
            payload: res.data.data
        });
        return res;
    })
}

function modelNameToStoreName(name) {
    if (name === 'Tax') {
        return 'taxes';
    }
    return `${_.lowerCase(name)}s`;
}