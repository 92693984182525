import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types'
import {withI18n} from 'react-i18next';
import {Dropdown, Input} from 'semantic-ui-react';
import LaraPagination from '../../../laraPagination';
import SecondaryButton from '../buttons/SecondaryButton';

const clientOptions = (clients) => {
    const clientOptions = [];
    _.each(clients, c => {
        clientOptions.push({
            value: _.toString(c.id),
            text: c.company
        })
    });
    return clientOptions;
}

const courierOptions = (couriers) => {
    const courierOptions = [];
    _.each(couriers, c => {
        courierOptions.push({
            value: _.toString(c.id),
            text: c.name
        })
    });
    return courierOptions;
}

let isDropdown = false;
let isOperationDisabled = false;
let isCourierQuery = false;

class LaraQuery extends Component {
    //access router
    //this.props.history.push('/dashboard')
    
    render() {
        const {t, fields, query, index, onQueryConstructorChange, 
            onQueryConstructorRelationalChange, onRemoveQueryClick, clients, couriers} = this.props;
            if (query.field === 'client_id') {
                isDropdown = true;
                isOperationDisabled = true;
                isCourierQuery = false;
            }
            if (query.field === 'courier_id') {
                isDropdown = true;
                isOperationDisabled = true;
                isCourierQuery = true;
            }
        return (
            <div className={`row ${index > 0 ? 'lara-row-margin' : null}`}>
                    <div className="col-md-2 col-sm-4 col-xs-12">
                        <Dropdown
                            options={[{value: 'AND', text: t('AND')}, {value: 'OR', text: t('OR')}]}
                            selection fluid
                            value={query.join}
                            onChange={(e, data) => {onQueryConstructorChange('join',index, data.value)}}
                        />
                    </div>
                    <div className="col-md-2 col-sm-4 col-xs-12">
                        <Dropdown
                            options={fields}
                            selection fluid
                            value={query.field}
                            onChange={(e, data) => {
                                if (data.value === 'client_id') {
                                    isDropdown = true;
                                    isOperationDisabled = true;
                                } else {
                                    isDropdown = false;
                                    isOperationDisabled = false;
                                }
                                onQueryConstructorRelationalChange('field',index, data.value)
                            }}
                        />
                    </div>
                    <div className="col-md-2 col-sm-4 col-xs-12">
                        <Dropdown
                            options={LaraPagination.operatiors(t)}
                            selection fluid
                            value={query.operator}
                            disabled={isOperationDisabled}
                            onChange={(e, data) => {onQueryConstructorChange('operator',index, data.value)}}
                        />
                    </div>
                    <div className="col-md-5 col-sm-10 col-xs-12">
                        {isDropdown && !isCourierQuery ? <Dropdown
                            options={clientOptions(clients)}
                            selection fluid
                            value={_.toString(query.value)}
                            onChange={(e, data) => {onQueryConstructorChange('value',index, data.value)}}
                        /> : null} 
                        {isDropdown && isCourierQuery ? <Dropdown
                            options={courierOptions(couriers)}
                            selection fluid
                            value={_.toString(query.value)}
                            onChange={(e, data) => {onQueryConstructorChange('value',index, data.value)}}
                        /> : null} 
                        {!isDropdown ? <Input 
                            fluid
                            value={query.value}
                            onChange={(e, data) => {onQueryConstructorChange('value',index, data.value)}}
                        /> : null}
                    </div>
                    <div className="col-md-1 col-sm-2 col-xs-12">
                        <SecondaryButton
                            size='small'
                            float='right'
                            label={t('Delete')}
                            onClick={() => {
                                onRemoveQueryClick()
                            }}
                            loading={false}
                        />
                    </div>
                </div>
        )
    }
}

LaraQuery.propTypes = {
    t: PropTypes.func.isRequired,
    fields: PropTypes.array.isRequired,
    query: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    onQueryConstructorChange: PropTypes.func.isRequired,
    onRemoveQueryClick: PropTypes.func.isRequired,
    onQueryConstructorRelationalChange: PropTypes.func.isRequired,
    clients: PropTypes.array
};

export default withI18n()(LaraQuery);