import React, { PureComponent } from 'react';
import {Table} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import accounting from 'accounting';
import OrderDisplayTableLine from './OrderDisplayTableLine';

class OrderDisplayTable extends PureComponent {
    getTotalDollarValue = () => {
        const {lines, accounting} = this.props;
        let total = 0;
        _.each(lines, l => {
            total = total + parseFloat(l.price) * parseInt(l.quantity, 10);
        });
        let taxRate = 0;
        if (accounting.tax_rate) {
            taxRate = parseFloat(accounting.tax_rate);
        }
        return {
            before: total,
            tax: total*taxRate,
            rate: `${taxRate * 100} %`,
            after: total*(1+taxRate)
        }
    }

    getTotalUnitsOrdered = () => {
        let qty = 0;
        this.props.lines.map(l => {
            qty = qty + parseInt(l.quantity);
        });
        return qty;
    }

    render() {
        const {t, lines} = this.props;
        const money = this.getTotalDollarValue();
        return (
            <div className="row">
                <div className="col-md-12">
                    <Table compact>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell width={2}>
                                    {t('SKU')}
                                </Table.HeaderCell>
                                <Table.HeaderCell width={4}>
                                    {t('Name')}
                                </Table.HeaderCell>
                                <Table.HeaderCell width={2}>
                                    {t('UPC')}
                                </Table.HeaderCell>
                                <Table.HeaderCell width={1}>
                                    {t('Stock')}
                                </Table.HeaderCell>
                                <Table.HeaderCell width={1}>
                                    {t('Quantity')}
                                </Table.HeaderCell>
                                <Table.HeaderCell width={1}>
                                    {t('Fulfilled')}
                                </Table.HeaderCell>
                                <Table.HeaderCell width={1}>
                                    {t('Price')}
                                </Table.HeaderCell>
                                <Table.HeaderCell width={2}>
                                    {t('OrderLineTotal')}
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {lines.map((line) => {
                                return (
                                    <OrderDisplayTableLine
                                        key={line.id}
                                        line={line}
                                    />
                                )
                            })}
                            <Table.Row>
                                <Table.HeaderCell colSpan={4}  textAlign='right'>
                                    {t('Quantity')}:
                                </Table.HeaderCell>
                                <Table.HeaderCell colSpan={2} textAlign='right'>
                                    {this.getTotalUnitsOrdered()}
                                </Table.HeaderCell>
                            </Table.Row>
                            <Table.Row>
                                <Table.HeaderCell colSpan={4}  textAlign='right'>
                                    {t('TotalBeforeTax')}:
                                </Table.HeaderCell>
                                <Table.HeaderCell colSpan={2} textAlign='right'>
                                    {accounting.formatMoney(money.before)}
                                </Table.HeaderCell>
                            </Table.Row>
                            <Table.Row>
                                <Table.HeaderCell colSpan={4}  textAlign='right'>
                                    {t('TotalTax')} ({money.rate}):
                                </Table.HeaderCell>
                                <Table.HeaderCell colSpan={2} textAlign='right'>
                                    {accounting.formatMoney(money.tax)}
                                </Table.HeaderCell>
                            </Table.Row>
                            <Table.Row>
                                <Table.HeaderCell colSpan={4}  textAlign='right'>
                                    {t('TotalAfterTax')}:
                                </Table.HeaderCell>
                                <Table.HeaderCell colSpan={2} textAlign='right'>
                                    {accounting.formatMoney(money.after)}
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </div>
            </div>
        )
    }
}

OrderDisplayTable.propTypes = {
    t: PropTypes.func.isRequired,
    lines: PropTypes.array.isRequired,
    accounting: PropTypes.object.isRequired
};

export default OrderDisplayTable