import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Form } from "semantic-ui-react";
import FormError from '../../includes/messages/422';

class ConfigurationForm extends Component {
    render() {
        const {model, t, onFormValueChange, errors, type} = this.props
        return (
            <Form size="mini">
                {!_.isEmpty(errors) ? (
                    <FormError errors={errors} />
                ) : null}
                {type === 'Tax' ? <Form.Group widths='equal'>
                    <Form.Input 
                        fluid 
                        label={t("TaxCode")}
                        required={true}
                        placeholder="GST"
                        name="name"
                        value={model.name ? model.name : ""} 
                        onChange={(e) => { onFormValueChange(e.target.name, e.target.value) }}
                    />
                    <Form.Input 
                        fluid 
                        required={true}
                        type='number'
                        placeholder="0.05"
                        label={t("Tax Rate")}
                        name="rate"
                        value={model.rate} 
                        onChange={(e) => { onFormValueChange(e.target.name, e.target.value) }}
                    />
                </Form.Group> : null}
                {type === 'Term' ? <div>
                <Form.Group widths='equal'>
                    <Form.Input 
                        fluid
                        placeholder="NET 30"
                        required={true}
                        label={t("Payment Term")}
                        name="name"
                        value={model.name ? model.name : ""} 
                        onChange={(e) => { onFormValueChange(e.target.name, e.target.value) }}
                    />
                </Form.Group>
                <Form.Group widths='equal'>
                    <Form.Input 
                        fluid
                        placeholder="Payment is due in full 30 days"
                        label={t("Description")}
                        name="description"
                        value={model.description ? model.description : ""} 
                        onChange={(e) => { onFormValueChange(e.target.name, e.target.value) }}
                    />
                </Form.Group>
                </div> : null}
                {type === 'Uom' ? <div>
                <Form.Group widths='equal'>
                    <Form.Input 
                        fluid
                        placeholder="Unit"
                        required={true}
                        label={t("UnitOfMeasure")}
                        name="name"
                        value={model.name ? model.name : ""} 
                        onChange={(e) => { onFormValueChange(e.target.name, e.target.value) }}
                    />
                </Form.Group>
                </div> : null}
                {type === 'Package' ? <div>
                <Form.Group widths='equal'>
                    <Form.Input 
                        fluid
                        placeholder="Small Carton"
                        required={true}
                        label={t("Name")}
                        name="name"
                        value={model.name ? model.name : ""} 
                        onChange={(e) => { onFormValueChange(e.target.name, e.target.value) }}
                    />
                </Form.Group>
                <Form.Group widths='equal'>
                    <Form.Input 
                        fluid
                        type='number'
                        required={true}
                        label={t("Length")}
                        name="length"
                        value={model.length ? model.length : ""} 
                        onChange={(e) => { onFormValueChange(e.target.name, e.target.value) }}
                    />
                    <Form.Input 
                        fluid
                        type='number'
                        required={true}
                        label={t("Width")}
                        name="width"
                        value={model.width ? model.width : ""} 
                        onChange={(e) => { onFormValueChange(e.target.name, e.target.value) }}
                    />
                </Form.Group>
                <Form.Group widths='equal'>
                    <Form.Input 
                        fluid
                        type='number'
                        required={true}
                        label={t("Height")}
                        name="height"
                        value={model.length ? model.height : ""} 
                        onChange={(e) => { onFormValueChange(e.target.name, e.target.value) }}
                    />
                    <Form.Input 
                        fluid
                        type='number'
                        label={t("Weight")}
                        name="weight"
                        value={model.weight ? model.weight : ""} 
                        onChange={(e) => { onFormValueChange(e.target.name, e.target.value) }}
                    />
                </Form.Group>
                </div> : null}
            </Form>
        );
    }
}

ConfigurationForm.propTypes = {
    errors: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    model: PropTypes.object.isRequired,
    onFormValueChange: PropTypes.func.isRequired
};

export default ConfigurationForm