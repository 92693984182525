import React from 'react';
import _ from 'lodash';
import {Form} from 'semantic-ui-react';
import PropTypes from 'prop-types';

const getClientOptions = (clients) => {
    const options = [];
    _.each(clients, c => {
        options.push({
            value: _.toString(c.id),
            text: c.company
        });
    })
    return options;
}

const ClientFormSelector = ({value, onSelected, clients, size, required, label}) => (
    <Form.Select
        fluid={true}
        required={required}
        search
        size={size}
        value={_.toString(value)}
        onChange={(e, {value}) => {
            onSelected(value)
        }}
        label={label}
        options={getClientOptions(clients)}
    />
)

ClientFormSelector.propTypes = {
    clients: PropTypes.array.isRequired,
    value: PropTypes.any,
    onSelected: PropTypes.func.isRequired,
    size: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired
};

export default ClientFormSelector