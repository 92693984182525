import React, { Component } from 'react';
import {Segment, Loader, Dimmer, Header, List, Message} from 'semantic-ui-react';
import {Link} from 'react-router-dom'
import PropTypes from 'prop-types';
import SegmentRibbon from '../../includes/partials/SegmentRibbon';
import AlertComponent from '../../includes/messages/alert';
import Lara from '../../../lara';
import ListPrintMenu from './ListPrintMenu';
import {api} from '../../../api';

class FBAFulfillmentSidePanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        }
    }

    componentDidMount() {
        
    }
    
    onSubmitContentFeedAndCreateShipmentClick = (feed) => {
        const {model, updateShipmentProperty} = this.props;
        //1. call API.updateFeedTypeToModelOnly(feed)
        //2. THEN call API.createShipmentById
        this.setState({
            loading: true
        });
        api.fba.updateFeedTypeToModelOnly(model.id, feed).then(res => {
            api.fba.createShipmentById(model.id).then(res => {
                updateShipmentProperty(model.id, 'fba_feed_type', feed);
                updateShipmentProperty(model.id, 'exist_in_fba' ,1);
                this.setState({
                    loading: false
                });
            }).catch(err => {
                this.setState({
                    loading: false
                });
                this.handleHttpErrors(err);
            })
        }).catch(err => {
            this.setState({
                loading: false
            });
            this.handleHttpErrors(err);
        })
    }

    handleHttpErrors = (err) => {
        const errors = Lara.axiosError(err, this.props.t);
        if (errors.http_code === 422) {
            this.setState({
                errors: errors.message,
                loading: false
            });
        } else {
            Lara.axiosAlert(<AlertComponent 
                support={errors.support}
                message={errors.message}
                reference={errors.reference}
                t={this.props.t}
            />);
        }
    }

    
    render() {
        const {model, t} = this.props;
        return (
            <Segment>
                <SegmentRibbon label="FBA" Buttons={() => {
                    return <div style={{float: 'right', paddingTop: 2}}>
                        {model.exist_in_fba ? <ListPrintMenu
                            t={t}
                            fulfillmentType='fba'
                            getListItemSelected={() => {
                                return [model.id]
                            }}
                            shipmentReport={false}
                            reFethcRcords={() => {return null}}
                            adminList={false}
                            modelId={model.id}
                            isLp={false}
                            shipmentPacked={model.packed}
                        /> : null}
                    </div>
                }}/>
                <Dimmer inverted active={this.state.loading}>
                    <Loader />
                </Dimmer>
                {!model.exist_in_fba ? <div>
                    <Header as="h3">
                        Require Confirmation
                        <Header.Subheader>
                            Not exist in FBA yet.
                        </Header.Subheader>
                    </Header>
                    <List>
                        <List.Item>
                            <List.Content>
                                <List.Header as='a'>
                                    <span onClick={() => {
                                        this.onSubmitContentFeedAndCreateShipmentClick('NONE')
                                    }}>Manual Entry</span>
                                </List.Header>
                                <List.Description>
                                    Login into FBA and manually fill in carton content.
                                </List.Description>
                            </List.Content>
                        </List.Item>
                        <List.Item>
                            <List.Content>
                                <List.Header as='a'>
                                    <span onClick={() => {
                                        this.onSubmitContentFeedAndCreateShipmentClick('2D_BARCODE')
                                    }}>2D Barcode</span>
                                </List.Header>
                                <List.Description>
                                    Use Pack and print 2D carton labels.
                                </List.Description>
                            </List.Content>
                        </List.Item>
                        <List.Item>
                            <List.Content>
                                <List.Header as='a'>
                                    <span onClick={() => {
                                        this.onSubmitContentFeedAndCreateShipmentClick('FEED')
                                    }}>System Feed</span>
                                </List.Header>
                                <List.Description>
                                    Use Pack and submit feed electronically.
                                </List.Description>
                            </List.Content>
                        </List.Item>
                    </List>
                    <Message>
                        Please confirm this shipment with in 48 hours of creation date: {model.created_at}
                    </Message>
                </div> : <div>
                    <Header as="h3">
                        Shipment Confirmed with FBA
                        <Header.Subheader>
                            <Link to={`/f/outbound/fba/${model.id}/fulfill`}>
                                Continue with FBA process
                            </Link>
                        </Header.Subheader>
                    </Header>
                </div>}
            </Segment>
        )
    }
}

FBAFulfillmentSidePanel.propTypes = {
    model: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    updateShipmentProperty: PropTypes.func.isRequired
};

export default FBAFulfillmentSidePanel