import React, { Component } from 'react';
import { bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import PropTypes from 'prop-types'
import {withI18n} from 'react-i18next';
import {Segment, Table, Dimmer, Loader, Icon} from 'semantic-ui-react';
import {Link} from 'react-router-dom';
import SegmentRibbon from '../includes/partials/SegmentRibbon';
import PageBreadCrub from '../includes/partials/PageBreadCrub';
import qs from 'qs';
import update from 'immutability-helper';
import {
    fetchPaginatedList
} from '../../actions/relation';
import OrderBy from '../includes/tables/OrderBy';
import SearchBar from '../includes/tables/SearchBar';
import Pagination from '../includes/tables/Pagination';
import FilterBy from '../includes/tables/FilterBy';
import AlertComponent from '../includes/messages/alert';
import Lara from '../../lara';
import LaraPagination from '../../laraPagination';
import ExcelUpload from '../includes/excel/Main';
import PrimaryNaviButton from '../includes/buttons/PrimaryNaviButton';
import SecondaryButton from '../includes/buttons/SecondaryButton';

//Updated with Lara buttons
class RelationListPage extends Component {
    constructor() {
        super();
        this.state = {
            breadcrub: [
                {isLink:false, to:null, tag: '', active: true, key: 1}
            ],
            loading: false,
            routingQuery: {

            },
            routingPath: "",
            search: "",
            relationType: "",
            excelModalDisplay: false
        }
    }
    
    componentDidUpdate(prevProps, prevState) {
        const {params, url} = this.props.match;
        if (url !== this.state.routingPath) {
            this.setRoutingInState(params, url);
        }
    }

    componentDidMount() {
        const {params, url} = this.props.match;
        this.setRoutingInState(params,url);
    }

    setRoutingInState = ({query, type}, url) => {
        const q = qs.parse(query);
        //order_by
        //filter_by
        //query
        if (!_.isObject(q.filter_by)) {
            q.filter_by = {
                type: type
            };
        } else {
            q.filter_by.type = type;
        }

        if (!_.isObject(q.order_by)) {
            q.order_by = {};
        }
        if (!_.isArray(q.query)) {
            q.query = [];
        }
        this.setState({
            loading: true,
            routingQuery: q,
            routingPath: url,
            search: q.search,
            relationType: type,
            breadcrub: [
                {isLink:false, to:null, tag: _.upperFirst(this.props.t(_.upperFirst(type))), active: true, key: 1}
            ]
        }, () => {
            this.fetchRecords(query)
        });
    }

    fetchRecords = () => {
        this.props.fetchPaginatedList(qs.stringify(this.state.routingQuery)).then(res => {
            this.setState({loading: false});
        }).catch(err => {
            const errors = Lara.axiosError(err, this.props.t);
            if (errors.http_code === 422) {
                this.setState({
                    errors: errors.message,
                    loading: false
                });
            } else {
                this.setState({
                    loading: false
                });
                Lara.axiosAlert(<AlertComponent 
                    support={errors.support}
                    message={errors.message}
                    reference={errors.reference}
                    t={this.props.t}
                />);
            }
            this.setState({loading: false});
        });
    }

    routeToNewQuery = (newRoutingQuery) => {
        this.props.history.push(`/relations/${this.state.relationType}/${qs.stringify(newRoutingQuery)}`);
    }

    /*
     * For table header filters
    */
    onOrderByHeaderClicked = (routingOrderName, value) => {
        const newRoutingQuery = update(this.state.routingQuery, {
            order_by: {
                [routingOrderName]: {$set: value}
            }
        });
        this.routeToNewQuery(newRoutingQuery);
    }

    /*
     * For pagination
    */
    onPaginationClicked = (e, data) => {
        const {activePage} = data;
        const newRoutingQuery = update(this.state.routingQuery, {
            on_page: {$set: activePage}
        });
        this.routeToNewQuery(newRoutingQuery);
    }

    /*
     * For Search bar: onSearchEnterKeyPressed onSearchChange onPredefinedRouteSelected (optional)
     */
    onSearchEnterKeyPressed = (e) => {
        if (e.key === 'Enter') {
            const defaultQuery = LaraPagination.inventories(true);
            defaultQuery.search = this.state.search;
            defaultQuery.filter_by.is_active="1,0";
            this.setState({
                queryId: 'default'
            }, () => {
                this.routeToNewQuery(defaultQuery);
            });
        }
    }

    //New Sep 12, 2019 - add pagination per page selector
    onPerPageChanged = (newPerPage) => {
        const query = update(this.state.routingQuery, {per_page: {$set: newPerPage}});
        this.routeToNewQuery(query);
    }

    onFilterSelectChange = (field, value) => {
        this.setState({
            routingQuery: update(this.state.routingQuery, {
                filter_by: {
                    [field]: {$set: value.join()}
                }
            })
        }, () => {
            this.routeToNewQuery(this.state.routingQuery);
        });
    }

    render() {
        const {routingQuery, search, relationType} = this.state;
        const {t, list} = this.props;
        const {on_page, per_page, total} = list;
        const orderBy = _.isObject(routingQuery.order_by) ? routingQuery.order_by : {};
        const filterBy = _.isObject(routingQuery.filter_by) ? routingQuery.filter_by : {};
        if (_.isEmpty(filterBy)) {
            filterBy['is_active'] = '1'
        }
        return (
            <div>
                {/* Breadcrub Row */}
                <div className="row">
                    <div className="col-md-12 lara-breadcrub">
                        <PageBreadCrub lists={this.state.breadcrub} t={t}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <Segment>
                            <SegmentRibbon label={`${t(_.upperFirst(relationType))} ${t('Search')}`} Buttons={
                                () => {return (<React.Fragment>
                                    <PrimaryNaviButton 
                                        link={`/relations/${this.state.relationType}/new/action/edit`}
                                        size='mini'
                                        float='right'
                                        loading={false}
                                        label={t('Create')}
                                    />
                                    <SecondaryButton
                                        size='mini'
                                        float='right'
                                        label='Excel'
                                        loading={false}
                                        onClick={() => {
                                            this.setState({excelModalDisplay: true})
                                        }}
                                    />
                                </React.Fragment>)}
                            }/>
                            <SearchBar
                                placeholder={`${t('Search by')} ${t('Company')}, ${t('Account')}, ${t('Email')}...`}
                                onSearchEnterKeyPressed={(e) => {(this.onSearchEnterKeyPressed(e))}}
                                search={search}
                                onSearchChange={(search) => {this.setState({search})}}
                                onPredefinedRouteSelected={(value) => {console.log(value)}}
                                options={[]}
                                query={null}
                                havePreferenceSelect={false}
                                hasPerPageSelector={routingQuery.per_page}
                                onPerPageSelcted={(perPage) => {
                                    this.onPerPageChanged(perPage);
                                }}
                            />
                            <div className="row lara-row-margin">
                                <FilterBy
                                    t={t}
                                    label={""}
                                    options={[
                                        {value: '0', text: 'Disabled'},
                                        {value: '1', text: 'Active'},
                                    ]}
                                    name="is_active"
                                    currentValueString={filterBy.is_active}
                                    onFilterSelectChange={(name, value) => {this.onFilterSelectChange(name, value)}}
                                />
                            </div>
                        </Segment>
            
                        <Segment>
                            <Dimmer inverted active={this.state.loading}>
                                <Loader />
                            </Dimmer>
                            <SegmentRibbon label={`${t(_.upperFirst(relationType))} ${t('List')}`}/>
                            <div className="row lara-row-margin">
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <Table
                                        compact
                                        basic
                                        padded={false}
                                    >
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell>
                                                    <OrderBy
                                                        t={t}
                                                        name='Company'
                                                        routingOrderName='name'
                                                        currentOrder={orderBy.name}
                                                        onOrderClick={(routingOrderName, value) => {this.onOrderByHeaderClicked(routingOrderName, value)}}
                                                    />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    <OrderBy
                                                        t={t}
                                                        name='Account'
                                                        routingOrderName='account'
                                                        currentOrder={orderBy.account}
                                                        onOrderClick={(routingOrderName, value) => {this.onOrderByHeaderClicked(routingOrderName, value)}}
                                                    />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    <OrderBy
                                                        t={t}
                                                        name='Email'
                                                        routingOrderName='email'
                                                        currentOrder={orderBy.email}
                                                        onOrderClick={(routingOrderName, value) => {this.onOrderByHeaderClicked(routingOrderName, value)}}
                                                    />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    {t('Status')}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    <OrderBy
                                                        t={t}
                                                        name='UpdatedAt'
                                                        routingOrderName='updated_at'
                                                        currentOrder={orderBy.updated_at}
                                                        onOrderClick={(routingOrderName, value) => {this.onOrderByHeaderClicked(routingOrderName, value)}}
                                                    />
                                                </Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>

                                        <Table.Body>
                                            {list.records.map(i => {
                                                return (<Table.Row key={i.id}>
                                                    <Table.Cell>
                                                        <Link to={{
                                                            pathname: `/relations/${relationType}/${i.id}/action/view`,
                                                            backTo: this.state.routingPath
                                                        }}>
                                                            {i.name}
                                                        </Link>
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {i.account}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {i.email}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {i.is_active ? <Icon name="check" color='green' /> : <Icon name="times" />}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {moment(i.updated_at).format('MMM DD, Y').toString()}
                                                    </Table.Cell>
                                                </Table.Row>)
                                            })}
                                        </Table.Body>
                                    </Table>
                                </div>
                            </div>
                        
                            <div className="row lara-row-margin">
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <Pagination 
                                        activePage={on_page}
                                        totalRecords={total}
                                        perPage={per_page}
                                        onPageChange={(e, data) => {
                                            this.onPaginationClicked(e,data);
                                        }}
                                    />
                                </div>
                            </div>
                        </Segment>
                    </div>
                </div>
                <ExcelUpload
                    displayModal={this.state.excelModalDisplay}
                    onCloseModalClick={() => {
                        this.setState({excelModalDisplay: false})
                    }}
                    onUploaded={() => {
                        this.fetchRecords();
                    }}
                    type='Relation'
                />
            </div>
        )
    }
}

RelationListPage.propTypes = {
    t: PropTypes.func.isRequired,
    history: PropTypes.shape({
       push: PropTypes.func.isRequired
    }).isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.shape({
        params: PropTypes.object.isRequired,
        path: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired
    }),
    fetchPaginatedList: PropTypes.func.isRequired,
    list: PropTypes.object.isRequired
};

function mapStateToProps(state) {
    return {
        list: state.relationList.list
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchPaginatedList
    }, dispatch)
}

export default withI18n()(connect(mapStateToProps, mapDispatchToProps)(RelationListPage));