import React, { Component } from 'react'
import { bindActionCreators} from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {withI18n} from 'react-i18next';
import MainUserDashboard from './dashboards/MainUser';
import ClientUserDashboard from './dashboards/ClientUser';

class DashboardPage extends Component {
    //access router
    //this.props.history.push('/dashboard')
    
    render() {
        const {user, t, host} = this.props;
        if (user.is_main_user) {
            return (
                <MainUserDashboard
                    user={user}
                    t={t}
                    host={host}
                />
            )
        } else {
            return (
                <ClientUserDashboard
                    user={user}
                    t={t}
                    host={host}
                />
            )
        }
    }
}

DashboardPage.propTypes = {
    user: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    host: PropTypes.string.isRequired
};

function mapStateToProps(state) {
    return {
        user: state.system.user,
        host: state.system.preference.host
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch)
}

export default withI18n()(connect(mapStateToProps, mapDispatchToProps)(DashboardPage));