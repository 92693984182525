import update from 'immutability-helper';
import {
    SET_LOCATIONS_PAGINATED_LIST,
    LOCATION_SAVED_ADD_TO_LIST
} from '../../actions/types'

export default function user(state = {
    list: {
        total: 0,
        per_page: 15,
        on_page: 1,
        records: [

        ]
    }
}, action = {}) {
    switch(action.type) {
        case SET_LOCATIONS_PAGINATED_LIST:
            return update(state, {
                list: {$set: action.payload}
            });

        case LOCATION_SAVED_ADD_TO_LIST:
            const {index, model} = action.payload;
            if (index > -1) {
                return update(state, {
                    list: {
                        records: {
                            [index]: {
                                $set: model
                            }
                        }
                    }
                })
            } else {
                return update(state, {
                    list: {
                        records: {
                            $unshift: [model]
                        }
                    }
                })
            }

        default: return state;
    }
}