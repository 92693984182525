import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";
import {withI18n} from 'react-i18next';
import { Table, Icon } from "semantic-ui-react";
import InventorySelector from '../../includes/selectors/InventorySelector';
import Lara from '../../../lara';
import {
    updateOrderModelLineItem,
    deleteOrderModelLineItem,
    addOrderModelLineItem
} from '../../../actions/order';
import {api} from "../../../api";
import OrderEditTableLine from './OrderEditTableLine';

class OrderEditTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        }
    }

    itemSelected = (item) => {
        if (!_.isObject(item)) {
            return null;
        }
        if (!item.id) {
            return null;
        }
        const existingIds = this.exceptedInventoryIds();
        if (existingIds.includes(item.id)) {
            return null;
        }
        const {type} = this.props.model;
        let priceProp = 'cost';
        if (type && type.includes('Customer')) {
            priceProp = 'selling';
        }
        this.props.addOrderModelLineItem({
            id: null,
            order_id: null,
            inventory_id: item.id,
            sku: item.sku,
            name: item.name,
            price: item[priceProp] ? item[priceProp] : 0,
            quantity: 1,
            source_id: Lara._uid(),
            source: 'Web',
            international: item.international,
            kit_id: item.type === 'Item' ? null : 1,
            kit_property: {}
        });
    }

    exceptedInventoryIds = () => {
        const {lines} = this.props.model;
        const except = [];
        if (_.isArray(lines)) {
            _.each(lines, item => {
                if(_.isObject(item)) {
                    except.push(item.inventory_id);
                }
            });
        }
        return except;
    }

    onAddAllAvailableClick = () => {
        this.setState({
            loading: true
        });
        api.order.allAllAvailable().then(res => {
            const {data} = res.data;
            data.map(item => {
                this.props.addOrderModelLineItem({
                    id: null,
                    order_id: null,
                    inventory_id: item.id,
                    sku: item.sku,
                    name: item.name,
                    price: 0,
                    quantity: item.available,
                    source_id: Lara._uid(),
                    source: 'Web',
                    international: item.international,
                    kit_id: null,
                    kit_property: {}
                })
            })
            this.setState({
                loading: false
            });
        })
    }

    render() {
        const {model, t, updateOrderModelLineItem, deleteOrderModelLineItem} = this.props
        return (
            <div className="row">
                <div className="col-md-12">
                    <Table compact>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell colSpan={3}>
                                    <InventorySelector
                                        onSelected={(item) => {
                                            this.itemSelected(item);
                                        }}
                                        exceptIds={this.exceptedInventoryIds()}
                                        canSearchKit={true}
                                    />
                                </Table.HeaderCell>
                                <Table.HeaderCell colSpan={2}>
                                    <div style={{float: 'right', paddingRight: 15}}>
                                        {this.state.loading ? <Icon name='spinner' loading /> : <span className="lara-clickable lara-editable" onClick={() => {
                                            this.onAddAllAvailableClick()
                                        }}>
                                            All
                                        </span>}
                                    </div>
                                </Table.HeaderCell>
                            </Table.Row>
                            <Table.Row>
                                <Table.HeaderCell width={3}>
                                    {t('SKU')}
                                </Table.HeaderCell>
                                <Table.HeaderCell width={7}>
                                    {t('Name')}
                                </Table.HeaderCell>
                                <Table.HeaderCell width={2}>
                                    {t('Quantity')}
                                </Table.HeaderCell>
                                <Table.HeaderCell width={3}>
                                    {t('Price')}
                                </Table.HeaderCell>
                                <Table.HeaderCell width={1}>
                                    {t('Delete')}
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {model.lines.map((line, index) => {
                                return (
                                    <OrderEditTableLine
                                        key={line.id ? line.id : line.source_id}
                                        line={line}
                                        index={index}
                                        onLineUpdate={(field, value) => updateOrderModelLineItem(
                                            field, value, index
                                        )}
                                        onDeleteLine={() => {
                                            deleteOrderModelLineItem(index);
                                        }}
                                    />
                                )
                            })}
                        </Table.Body>
                    </Table>
                </div>
            </div>
        );
    }
}

OrderEditTable.propTypes = {
    updateOrderModelLineItem: PropTypes.func.isRequired,
    deleteOrderModelLineItem: PropTypes.func.isRequired,
    addOrderModelLineItem: PropTypes.func.isRequired,
    model: PropTypes.shape({
        lines: PropTypes.array.isRequired
    }),
    t: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        model: state.orderModel
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            updateOrderModelLineItem,
            deleteOrderModelLineItem,
            addOrderModelLineItem
        },
        dispatch
    );
}

export default withI18n()(connect(
    mapStateToProps,
    mapDispatchToProps
)(OrderEditTable));