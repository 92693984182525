import {
    SET_RELATION_PAGINATED_LIST,
    SET_RELATION_MODEL_FROM_SERVER,
    ADD_ADDRESS_TO_RELATION,
    UPDATE_ADDRESS_TO_RELATION,
    DELETE_ADDRESS_TO_RELATION,
    RELATION_UPDATED_BY_FORM
} from './types';
import {api} from '../api';
import Lara from '../lara';

export const fetchPaginatedList = (path) => (dispatch) => {
    return api.relations.paginated(path).then(res => {
        dispatch({
            type: SET_RELATION_PAGINATED_LIST,
            payload: res.data.data
        });
        return res;
    }).catch(err => {throw err});
}

export const setRelationModel = (model) => {
    return {
        type: SET_RELATION_MODEL_FROM_SERVER,
        payload: model
    }
}

export const fetchById = (id) => (dispatch) => {
    return api.relations.read(id).then(res => {
        dispatch({
            type: SET_RELATION_MODEL_FROM_SERVER,
            payload: res.data.data
        });
        return res.data.data;
    }).catch(err => {throw err});
}

export const createNewModelInStore = (type) => {
    return {
        type: SET_RELATION_MODEL_FROM_SERVER,
        payload: Lara.models.relation(type)
    }
}

export const updateModelInStore = (field, value) => {
    return {
        type: RELATION_UPDATED_BY_FORM,
        payload: {field, value}
    }
}

export const updateModelOnServer = (model) => (dispatch) => {
    if (model.id) {
        return api.relations.update(model.id, model).then(res => {
            return model;
        }).catch(err => {throw err});
    } else {
        return api.relations.create(model).then(res => {
            dispatch({
                type: SET_RELATION_MODEL_FROM_SERVER,
                payload: res.data.data
            });
            return res.data.data;
        }).catch(err => {throw err});
    }
}


export const addressCreated = (addr) => {
    return {
        type: ADD_ADDRESS_TO_RELATION,
        payload: addr
    }
}

export const addressUpdated = (addr, index) => {
    return {
        type: UPDATE_ADDRESS_TO_RELATION,
        payload: {
            addr, index
        }
    }
}

export const addressDeleted = (index) => {
    return {
        type: DELETE_ADDRESS_TO_RELATION,
        payload: index
    }
}