import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {List, Modal, Header, Input} from 'semantic-ui-react';
import {withI18n} from 'react-i18next';
import validator from 'validator';
import PrimaryButton from '../buttons/PrimaryButton';

class ClientEmailList extends Component {
    constructor() {
        super();
        this.state = {
            email: "",
            showModal: false
        }
    }

    onAddEmailClick = () => {
        const {email} = this.state;
        if (validator.isEmail(email)) {
            this.props.onAddEmailAddress(email);
            this.setState({
                email: ""
            });
        }
    }

    render() {
        const {list, onRemoveEmailAddress, label, t} = this.props;
        return (
            <div className="lara-row-margin">
                <strong>{label}</strong> 
                <span className="lara-clickable lara-editable" onClick={
                    () => {this.setState({showModal: true})}
                }>  {t('Create')}</span>
                <List>
                    {list.map((email,index) => {
                        return (
                            <List.Item key={index} onClick={() => {onRemoveEmailAddress(index)}}>
                                <List.Icon name='mail' />
                                <List.Content>
                                    <List.Header as='a'>{email}</List.Header>
                                    <List.Description>Click to remove</List.Description>
                                </List.Content>
                            </List.Item>
                        )
                    })}
                </List>
                <Modal size="small" 
                    closeOnDimmerClick={false}
                    closeIcon
                    onClose={() => {this.setState({showModal: false})}}
                    closeOnEscape={true} open={this.state.showModal}>
                    <Header icon='mail' />
                    <Modal.Content>
                        <Input fluid size='mini' value={this.state.email}
                            onChange={(e, {value}) => {this.setState({
                                email: value
                        })}} />
                    </Modal.Content>
                    <Modal.Actions>
                        <PrimaryButton
                            size='mini'
                            float='right'
                            label={t('Save')}
                            onClick={() => {
                                this.onAddEmailClick()
                            }}
                            loading={false}
                        />
                    </Modal.Actions>
                </Modal>
            </div>
        )
    }
}

ClientEmailList.propTypes = {
    t: PropTypes.func.isRequired,
    list: PropTypes.array.isRequired,
    onRemoveEmailAddress:PropTypes.func.isRequired,
    onAddEmailAddress:PropTypes.func.isRequired,
    label: PropTypes.string.isRequired
};

export default withI18n()(ClientEmailList);