import {combineReducers} from 'redux';
import system from './utils/system';
import config from './utils/config';
import relationList from './relations/relationList';
import relationModel from './relations/relationModel';
import inventoryList from './inventories/inventoryList';
import inventoryModel from './inventories/inventoryModel';
import orderList from './orders/orderList';
import orderModel from './orders/orderModel';
import partialShipment from './shipments/partialShipment';
import userList from './utils/userList';
import userModel from './utils/userModel';
import clientList from './utils/clientList';
import clientModel from './utils/clientModel';
import billingItemList from './billings/itemList';
import billingItemModel from './billings/itemModel';
import billingActivityList from './billings/activityList';
import billingActivityModel from './billings/activityModel';
import billingEntryList from './billings/entryList';
import role from './utils/role';
import apiList from './integrations/apiList';
import apiModel from './integrations/apiModel';
import locations from './utils/location';
import licenses from './operations/license';
import serialList from './serials/serialList';
import serialModel from './serials/serialModel';
import shipmentList from './shipments/shipmentList';
import shipmentModel from './shipments/shipmentModel';
import fulfillment from './shipments/fulfillment';

export default combineReducers({
    system, config, relationList, relationModel, inventoryList, inventoryModel, orderList,
    orderModel, userList, userModel, role, clientList, clientModel, partialShipment, 
    billingItemList, billingItemModel, billingActivityList, billingActivityModel,
    billingEntryList, apiList, apiModel, locations, licenses, serialList, serialModel,
    shipmentList, shipmentModel, fulfillment
});