import update from 'immutability-helper';
import _ from 'lodash';
import {
    SET_ORDERS_PAGINATED_LIST,
    SET_ORDER_PAGE_PREDIFIEND_QUERIES,
    SET_ORDER_ACTION_CHECKBOX,
    CREATE_ORDER_PAGE_PREDIFINED_QUERIES
} from '../../actions/types';

export default function order(state = {
    routing: {
        params: {
            on_page: 1,
            per_page: 15,
            order_by: {
            },
            filter_by: {
            },
            query: {
            },
            search: null
        },
        path: null,
    },
    list: {
        total: 0,
        per_page: 15,
        on_page: 1,
        records: [

        ]
    },
    queries: [

    ]
}, action = {}) {
    switch(action.type) {
        case SET_ORDERS_PAGINATED_LIST:
            return update(state, {
                list: {$set: action.payload}
            });

        case SET_ORDER_PAGE_PREDIFIEND_QUERIES:
            return update(state, {
                queries: {$set: action.payload}
            });

        case SET_ORDER_ACTION_CHECKBOX:
            return update(state, {
                list: {
                    records: {
                        [action.payload.index]: {
                            action: {$set: action.payload.value}
                        }
                    }
                }
            });

        case CREATE_ORDER_PAGE_PREDIFINED_QUERIES:
            return update(state, {
                queries: {$push: [{
                    value: _.toString(action.payload.id),
                    text: action.payload.name,
                    key: action.payload.id,
                    query: action.payload.value
                }]}
            });

        default: return state;
    }
}