import React, { Component } from 'react';
import { bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import {withI18n} from 'react-i18next';
import PropTypes from 'prop-types';
import {Segment, Loader, Dimmer, List, Label, Statistic} from 'semantic-ui-react';
import {
    fetchById,
    deleteCategory
} from '../../actions/client';
import {
    loadShipStationWarehouses
} from '../../actions/utils';
import PageBreadCrub from '../includes/partials/PageBreadCrub';
import AlertComponent from '../includes/messages/alert';
import SegmentRibbon from '../includes/partials/SegmentRibbon';
import ResourceTextPropertyView from '../includes/partials/ResourceTextPropertyView';
import ResourceBooleanPropertyView from '../includes/partials/ResourceBooleanPropertyView';
import ResourceListPropertyView from '../includes/partials/ResourceListPropertyView';
import Lara from '../../lara';
import LaraPagination from '../../laraPagination';
import TraceComponent from '../includes/partials/TraceComponent';
import {api} from '../../api';
import ClientWebhook from './webhook/ClientWebhook';
import PrimaryNaviButton from '../includes/buttons/PrimaryNaviButton';

class ClientViewPage extends Component {
    constructor() {
        super();
        this.state = {
            breadcrub: [
                {isLink:false, to:null, tag: '', active: true, key: 1}
            ],
            loading: false,
            deleteCategoryLoading: false,
            statistics: {
                inventory_count: 0,
                order_count: 0,
                relation_count: 0,
                entries_count: 0
            }
        }
    }

    componentDidMount() {
        const {location, match, warehouses} = this.props;
        const {id} = match.params;
        this.setState({
            loading: true
        });
        this.props.fetchById(id).then(res => {
            this.setState({
                loading: false,
                breadcrub: this.getBreadCrub(location.backTo, res.company)
            });

            if (_.isEmpty(warehouses)) {
                this.props.loadShipStationWarehouses();
            }
        }).catch(err => {
            const errors = Lara.axiosError(err, this.props.t);
            if (errors.http_code === 422) {
                this.setState({
                    errors: errors.message,
                    loading: false
                });
            } else {
                this.setState({
                    loading: false
                });
                Lara.axiosAlert(<AlertComponent
                    support={errors.support}
                    message={errors.message}
                    reference={errors.reference}
                    t={this.props.t}
                />);
            }
            this.setState({loading: false});
        });

        api.clients.statistics(id).then(res => {
            this.setState({
                statistics: res.data.data
            });
        })

    }

    getBreadCrub = (backTo, name) => {
        if (!backTo) {
            backTo = `/clients/${LaraPagination.clients(false)}`;
        }
        return [
            {isLink:true, to: backTo, tag: 'Client', active: false, key: 1},
            {isLink:false, to:null, tag: name, active: true, key: 2}
        ]
    }

    onDeleteCategoryClick = (category, index) => {
        this.setState({
            deleteCategoryLoading: true
        });
        this.props.deleteCategory(category,index).then(res => {
            this.setState({
                deleteCategoryLoading: false
            });
        }).catch(err => {
            const errors = Lara.axiosError(err, this.props.t);
            this.setState({
                deleteCategoryLoading: false
            });
            Lara.axiosAlert(<AlertComponent
                support={errors.support}
                message={errors.message}
                reference={errors.reference}
                t={this.props.t}
            />);
        });
    }

    getShipStationWarehouseName = (id) => {
        const {warehouses} = this.props;
        const warehouseIndex = _.findIndex(warehouses, i => {
            return parseInt(i.warehouseId,10) === parseInt(id,10);
        });
        if (warehouseIndex > -1) {
            return warehouses[warehouseIndex].warehouseName
        }
        return "NA"
    }

    render() {
        const {t, model} = this.props;
        const {address, detail, categories} = model;

        return (
            <div>
                {/* Breadcrub Row */}
                <div className="row">
                    <div className="col-md-8 col-sm-8 col-xs-12 lara-breadcrub">
                        <PageBreadCrub lists={this.state.breadcrub} t={t}/>
                    </div>
                    {model.id && this.props.user.is_main_user ? <div className="col-md-4 col-sm-4 col-xs-12 lara-breadcrub">
                        <TraceComponent
                            trace_id={model.id}
                            trace_type='App\Models\Client'
                        />
                    </div> : null}
                </div>

                <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <Segment>
                            <Dimmer inverted active={this.state.loading}>
                                <Loader />
                            </Dimmer>
                            <SegmentRibbon label={model.company ? model.company : "Loading..."} Buttons={
                                () => {return (<React.Fragment>
                                    <PrimaryNaviButton
                                        link={`/clients/${model.id}/action/edit`}
                                        size='mini'
                                        float='right'
                                        loading={false}
                                        label={t('Edit')}
                                    />
                                </React.Fragment>)}
                            }/>
                            <div className="row">
                                <div className="col-md-3 col-sm-6 col-xs-12">
                                    <ResourceTextPropertyView
                                        label="Company"
                                        value={model.company} />
                                    <ResourceTextPropertyView
                                        label="Email"
                                        value={model.email} />
                                    <ResourceTextPropertyView
                                        label="Account"
                                        value={model.account} />
                                    <ResourceBooleanPropertyView
                                        label="Status"
                                        value={model.is_active} />
                                </div>
                                <div className="col-md-3 col-sm-6 col-xs-12">
                                    {_.isObject(address) ? <ResourceTextPropertyView
                                        label="CompanyName"
                                    value={address.company} /> : null}
                                    {_.isObject(address) ? <ResourceTextPropertyView
                                        label="Contact"
                                    value={address.contact} /> : null}
                                    {_.isObject(address) ? <ResourceTextPropertyView
                                        label="Phone"
                                    value={address.phone} /> : null}
                                </div>
                                <div className="col-md-3 col-sm-6 col-xs-12">
                                    {_.isObject(address) ? <ResourceTextPropertyView
                                        label="StreetAddress"
                                    value={address.street1} /> : null}
                                    {_.isObject(address) ? <ResourceTextPropertyView
                                        label="StreetAddress"
                                    value={address.street2} /> : null}
                                    {_.isObject(address) ? <ResourceTextPropertyView
                                        label="StreetAddress"
                                    value={address.street3} /> : null}
                                </div>
                                <div className="col-md-3 col-sm-6 col-xs-12">
                                    {_.isObject(address) ? <ResourceTextPropertyView
                                        label="City"
                                    value={address.city} /> : null}
                                    {_.isObject(address) ? <ResourceTextPropertyView
                                        label="Postal"
                                    value={address.postal_code} /> : null}
                                    {_.isObject(address) ? <ResourceTextPropertyView
                                        label="StateCode"
                                    value={address.state_code} /> : null}
                                    {_.isObject(address) ? <ResourceTextPropertyView
                                        label="Country"
                                    value={address.country_code} /> : null}
                                </div>
                            </div>
                        </Segment>
                    </div>
                </div>

                {model.id ? <div className="row lara-row-margin">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <Segment>
                            <Dimmer inverted active={this.state.loading}>
                                <Loader />
                            </Dimmer>
                            <SegmentRibbon label={t("Setting")} Buttons={null} />
                            <div className="row">
                                <div className="col-md-3 col-sm-6 col-xs-12">
                                    {_.isObject(detail) ? <ResourceTextPropertyView
                                        label="AccountingName"
                                    value={detail.qb_mapping_name} /> : null}
                                    {_.isObject(detail) ? <ResourceTextPropertyView
                                        label="AccountingTerm"
                                    value={detail.invoice_term} /> : null}
                                    {_.isObject(detail) ? <ResourceTextPropertyView
                                        label="AccountingDueDate"
                                    value={detail.due_date} /> : null}
                                    {_.isObject(detail) ? <ResourceTextPropertyView
                                        label="LogoUri"
                                    value={detail.logo_uri} /> : null}
                                    {this.props.server_pref.has_flex_integration_bool ? <ResourceTextPropertyView
                                        label="Flex Customer ID"
                                        value={model.custom_1}
                                    /> : null}
                                    {this.props.server_pref.has_flex_integration_bool ? <ResourceTextPropertyView
                                        label="Flex User Email"
                                        value={model.custom_2}
                                    /> : null}
                                    {this.props.server_pref.has_flex_integration_bool ? <ResourceTextPropertyView
                                        label="Client Currency"
                                        value={model.custom_3}
                                    /> : null}
                                </div>
                                <div className="col-md-3 col-sm-6 col-xs-12">
                                    {_.isObject(detail) ? <ResourceBooleanPropertyView
                                        label="UseShipStation"
                                    value={detail.use_ship_station} /> : null}
                                    {_.isObject(detail) ? <ResourceTextPropertyView
                                        label="ShipStationWarehouse"
                                    value={this.getShipStationWarehouseName(detail.ss_warehouse)} /> : null}
                                    {_.isObject(detail) ? <ResourceTextPropertyView
                                        label="ShippingChargeModify"
                                    value={detail.shipping_charge_modify} /> : null}
                                    {_.isObject(detail) ? <ResourceTextPropertyView
                                        label="OrderNotification"
                                    value={detail.order_notification} /> : null}
                                    {_.isObject(detail) ? <ResourceTextPropertyView
                                        label="OrderNotificationEmail"
                                    value={detail.order_notification_email} /> : null}
                                </div>
                                <div className="col-md-3 col-sm-6 col-xs-12">
                                    {_.isObject(detail) ? <ResourceTextPropertyView
                                        label="InvoicePrefix"
                                    value={detail.invoice_prefix} /> : null}
                                    {_.isObject(detail) ? <ResourceTextPropertyView
                                        label="NextInvNum"
                                    value={detail.next_inv_num} /> : null}
                                    {_.isObject(detail) ? <ResourceTextPropertyView
                                        label="PacingSlipPrefix"
                                    value={detail.packing_slip_prefix} /> : null}
                                    {_.isObject(detail) ? <ResourceTextPropertyView
                                        label="NextPackingSlipNum"
                                    value={detail.next_picking_num} /> : null}
                                    {_.isObject(detail) ? <ResourceTextPropertyView
                                        label="ClientTaxCode"
                                    value={detail.tax_code} /> : null}
                                    {_.isObject(detail) ? <ResourceTextPropertyView
                                        label="ClientTaxRate"
                                    value={detail.tax_rate} /> : null}
                                </div>
                                <div className="col-md-3 col-sm-6 col-xs-12">
                                    {_.isObject(detail) ? <ResourceBooleanPropertyView
                                        label="EmailUponReceiving"
                                    value={detail.email_upon_receiving} /> : null}
                                    {_.isObject(detail) ? <ResourceBooleanPropertyView
                                        label="EmailUponShipping"
                                    value={detail.email_upon_shipping} /> : null}
                                    {_.isObject(detail) ? <ResourceListPropertyView
                                        label="ReceivingEmails"
                                        propertyKey=""
                                        value={detail.receiving_notification_emails} /> : null}
                                    {_.isObject(detail) ? <ResourceListPropertyView
                                        label="ShippingEmails"
                                        propertyKey=""
                                        value={detail.shipping_notification_emails} /> : null}

                                </div>
                            </div>
                        </Segment>
                    </div>
                </div> : null}

                <div className="row lara-row-margin">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <Segment>
                            <Dimmer inverted active={this.state.loading}>
                                <Loader />
                            </Dimmer>
                            <SegmentRibbon label={t("Custom Fields")} Buttons={null} />
                            <div className="row">
                                <div className="col-md-2 col-sm-2 col-xs-6">
                                    <ResourceTextPropertyView
                                        label="Custom 1"
                                        value={model.custom_1} />
                                </div>
                                <div className="col-md-2 col-sm-2 col-xs-6">
                                    <ResourceTextPropertyView
                                        label="Custom 2"
                                        value={model.custom_2} />
                                </div>
                                <div className="col-md-2 col-sm-2 col-xs-6">
                                    <ResourceTextPropertyView
                                        label="Custom 3"
                                        value={model.custom_3} />
                                </div>
                                <div className="col-md-2 col-sm-2 col-xs-6">
                                    <ResourceTextPropertyView
                                        label="Custom 4"
                                        value={model.custom_4} />
                                </div>
                                <div className="col-md-2 col-sm-2 col-xs-6">
                                    <ResourceTextPropertyView
                                        label="Custom 5"
                                        value={model.custom_5} />
                                </div>
                                <div className="col-md-2 col-sm-2 col-xs-6">

                                </div>
                            </div>
                        </Segment>
                    </div>
                </div>

                {model.id ? <div className="row lara-row-margin">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <Segment>
                            <Dimmer inverted active={this.state.loading}>
                                <Loader />
                            </Dimmer>
                            <SegmentRibbon label={t("Statistics")} Buttons={null} />
                            <Statistic.Group widths={4}>
                                <Statistic>
                                    <Statistic.Value>{this.state.statistics.inventory_count}</Statistic.Value>
                                    <Statistic.Label>Inventories</Statistic.Label>
                                </Statistic>
                                <Statistic>
                                    <Statistic.Value>{this.state.statistics.relation_count}</Statistic.Value>
                                    <Statistic.Label>Relations</Statistic.Label>
                                </Statistic>
                                <Statistic>
                                    <Statistic.Value>{this.state.statistics.order_count}</Statistic.Value>
                                    <Statistic.Label>Orders</Statistic.Label>
                                </Statistic>
                                <Statistic>
                                    <Statistic.Value>{this.state.statistics.entries_count}</Statistic.Value>
                                    <Statistic.Label>Billing Entries</Statistic.Label>
                                </Statistic>
                            </Statistic.Group>
                        </Segment>
                    </div>
                </div> : null}

                {model.id ? <div className="row lara-row-margin">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <Segment>
                            <Dimmer inverted active={this.state.deleteCategoryLoading}>
                                <Loader />
                            </Dimmer>
                            <SegmentRibbon label={t("Category")} Buttons={null} />
                            <List divided relaxed>
                                {categories.map((cat,index) => {
                                    return (
                                        <List.Item key={cat.id} onClick={() => {this.onDeleteCategoryClick(cat,index)}}>
                                            <List.Icon name='paperclip' size='large' verticalAlign='middle' />
                                            <List.Content>
                                                <List.Header as='a'>
                                                    <Label size='mini' color='teal'>{cat.tag}</Label>
                                                    <Label size='mini' color='purple'>{cat.name}</Label>
                                                </List.Header>
                                                <List.Description as='a'>{t('ClickToDelete')}</List.Description>
                                            </List.Content>
                                        </List.Item>
                                    )
                                })}
                            </List>
                        </Segment>
                    </div>
                </div> : null}

                {model.id ? <div className="row lara-row-margin">
                    <ClientWebhook t={t} />
                </div> : null}

            </div>
        )
    }
}

ClientViewPage.propTypes = {
    fetchById: PropTypes.func.isRequired,
    deleteCategory: PropTypes.func.isRequired,
    loadShipStationWarehouses: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    history: PropTypes.shape({
       push: PropTypes.func.isRequired
    }).isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired
        }),
        path: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired
    }),
    warehouses: PropTypes.array.isRequired,
    server_pref: PropTypes.object.isRequired
};

function mapStateToProps(state) {
    return {
        model: state.clientModel,
        warehouses: state.system.ship_station.warehouses,
        user: state.system.user,
        server_pref: state.system.server_pref
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchById,
        deleteCategory,
        loadShipStationWarehouses
    }, dispatch)
}

export default withI18n()(connect(mapStateToProps, mapDispatchToProps)(ClientViewPage));
