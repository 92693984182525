import React from 'react';
import PropTypes from 'prop-types';
import {Form} from 'semantic-ui-react';
const options = require('../../../data/countries.json');

const CountrySelector = ({label, selected, countrySelected}) => (
    <Form.Select fluid search selection 
        label={label}
        options={options}
        name="country" value={selected} 
        onChange={(e, {value}) => {countrySelected(value)}}
    />
)

CountrySelector.propTypes = {
    selected: PropTypes.string,
    countrySelected: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired
};


export default CountrySelector