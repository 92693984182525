import update from 'immutability-helper';
import {
    SET_PARTIAL_SHIPMENT_MODEL_FROM_ORDER,
    PARTIAL_SHIPMENT_MODEL_UPDATED_BY_FORM,
    PARTIAL_SHIPMENT_LINES_UPDATED_BY_FORM
} from '../../actions/types';

export default function partialShipment(state = {
    shipping_addr: {},
    billing_addr: {},
    courier: {},
    lines: []
}, action = {}) {
    switch(action.type) {
        case SET_PARTIAL_SHIPMENT_MODEL_FROM_ORDER:
            return action.payload;

        case PARTIAL_SHIPMENT_MODEL_UPDATED_BY_FORM:
            if (action.payload.propertyName) {
                return update(state, {
                    [action.payload.propertyName]: {
                        [action.payload.field]: {
                            $set: action.payload.value
                        }
                    }
                });
            } else {
                return update(state, {
                    [action.payload.field]: {
                        $set: action.payload.value
                    }
                });
            }

        case PARTIAL_SHIPMENT_LINES_UPDATED_BY_FORM:
            return update(state, {
                lines: {
                    [action.payload.index]: {
                        quantity: {$set: action.payload.quantity}
                    }
                }
            })
        default: return state;
    }
}