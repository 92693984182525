import update from 'immutability-helper';
import _ from 'lodash';
import {
    SET_ROLE_LIST,
    SET_ROLE_MODEL,
    NEW_ROLE_CREATED,
    EXISTING_ROLE_UPDATED
} from '../../actions/types';

export default function role(state = {
    list: [],
    model: {

    }
}, action = {}) {
    switch(action.type) {
        case SET_ROLE_LIST:
            return update(state, {
                list: {$set: action.payload}
            });

        case SET_ROLE_MODEL:
            return update(state, {
                model: {$set: action.payload}
            });

        case NEW_ROLE_CREATED:
            return update(state, {
                list: {$push: [action.payload]}
            });

        case EXISTING_ROLE_UPDATED:
            const {key, model} = action.payload;
            const updateIndex = _.findIndex(state.list, i => {
                return _.toInteger(i.id, 10) === _.toInteger(key);
            });
            if (updateIndex > -1) {
                return update(state, {
                    list: {
                        [updateIndex]: {$set: model}
                    }
                })
            }
            return state;

        default: return state;
    }
}