import React, { Component } from 'react';
import { bindActionCreators} from 'redux';
import {withI18n} from 'react-i18next';
import { connect } from 'react-redux';
import _ from 'lodash';
import {Form} from 'semantic-ui-react';
import CountrySelector from '../../includes/selectors/CountrySelector';
import ShipmentAddressForm from './ShipmentAddressForm';
import CourierSelector from '../../includes/selectors/CourierSelector';
import CreatePartialShipmentLineTable from './CreatePartialShipmentLineTable';
import CourierPaymentSelector from '../../includes/selectors/CourierPaymentSelector';
import AlertComponent from '../../includes/messages/alert';
import FormError from '../../includes/messages/422';
import Lara from '../../../lara';
import {
    partialShipmentModelUpdatedByForm,
    partialShipmentLinesUpdatedByForm,
    createPartialOnServer
} from '../../../actions/order';
import PropTypes from 'prop-types';
import PrimaryButton from '../../includes/buttons/PrimaryButton';

class CreatePartialShipmentComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            errrors: []
        }
    }

    onFormSubmit = () => {
        const errors = this.validateInput();
        if (_.isEmpty(errors)) {
            this.setState({
                loading: true,
                errors: []
            });
            this.props.createPartialOnServer(this.props.model, this.props.orderId).then(shipment => {
                this.setState({
                    loading: false
                });
                this.props.shipmentCreated();
            }).catch(err => {
                const errors = Lara.axiosError(err, this.props.t);
                if (errors.http_code === 422) {
                    this.setState({
                        errors: errors.message,
                        loading: false
                    });
                } else {
                    this.setState({
                        loading: false
                    });
                    Lara.axiosAlert(<AlertComponent 
                        support={errors.support}
                        message={errors.message}
                        reference={errors.reference}
                        t={this.props.t}
                    />);
                }
            });
        } else {
            this.setState({
                errors: errors
            });
        }
    };

    validateInput = () => {
        const {model, t} = this.props;
        const errors = [];
        if (parseInt(model.courier.id,10) <= 0) {
            errors.push("CourierIsRequired");
        }
        _.each(model.lines, (l,index) => {
            if (parseInt(l.quantity,10) > l.remaining) {
                errors.push(`${t('CannotShipMoreThanRemaining')} ${index + 1}`)
            }
        })
        return errors;
    }
    
    render() {
        const {model, t, partialShipmentModelUpdatedByForm, partialShipmentLinesUpdatedByForm} = this.props;
        return (
            <div className="row lara-row-margin">
                {model._id ? <div className="col-md-12">
                    <div className="row">
                        {!_.isEmpty(this.state.errors) ? (
                            <div className="col-md-12">
                                <FormError errors={this.state.errors} />
                            </div>
                        ) : null}
                        <div className="col-md-4">
                            <span>{t('BillingAddress')}</span>
                            <ShipmentAddressForm
                                t={t}
                                address={model.billing_addr}
                                onFieldChange={(field, value) => {
                                    partialShipmentModelUpdatedByForm('billing_addr', field, value)
                                }}
                            />
                        </div>
                        <div className="col-md-4">
                            <span>{t('ShippingAddress')}</span>
                            <ShipmentAddressForm
                                t={t}
                                address={model.shipping_addr}
                                onFieldChange={(field, value) => {
                                    partialShipmentModelUpdatedByForm('shipping_addr', field, value)
                                }}
                            />
                        </div>
                        <div className="col-md-4">
                            <span>{t('ShipmentAdditionalInfo')}</span>
                            <Form size='mini'>
                                <Form.Input
                                    fluid
                                    label={t('ShipDate')}
                                    type='date'
                                    value={model.ship_date ? model.ship_date : ""}
                                    onChange={(e, {value}) => {
                                        partialShipmentModelUpdatedByForm(false,'ship_date', value);
                                    }}
                                />
                                <Form.Input
                                    fluid
                                    label={t('CancelDate')}
                                    type='date'
                                    value={model.cancel_date ? model.cancel_date : ""}
                                    onChange={(e, {value}) => {
                                        partialShipmentModelUpdatedByForm(false,'cancel_date', value);
                                    }}
                                />
                                <CourierSelector
                                    onSelectChange={(value) => {
                                        partialShipmentModelUpdatedByForm('courier', 'id', value)
                                    }}
                                    appendNonOption={false}
                                    returnObject={false}
                                    label={t('Courier')}
                                    selected={model.courier.id}
                                />
                                <CourierPaymentSelector
                                    t={t}
                                    onSelectChange={(value) => {
                                        partialShipmentModelUpdatedByForm('courier', 'payment_method', value)
                                    }}
                                    label={t('CourierPaymentMethod')}
                                    selected={model.courier.payment_method}
                                />
                                {model.courier.payment_method !== 'prepaid' ? <Form.Input
                                    fluid
                                    label={t('CourierAccount')}
                                    value={model.courier.account ? model.courier.account : ""}
                                    onChange={(e, {value}) => {
                                        partialShipmentModelUpdatedByForm('courier','account', value);
                                    }}
                                /> : null}
                                {model.courier.payment_method !== 'prepaid' ? <Form.Input
                                    fluid
                                    label={t('CourierBillingPostal')}
                                    value={model.courier.billing_zip ? model.courier.billing_zip : ""}
                                    onChange={(e, {value}) => {
                                        partialShipmentModelUpdatedByForm('courier','billing_zip', value);
                                    }}
                                /> : null}
                                {model.courier.payment_method !== 'prepaid' ? <CountrySelector
                                    selected={model.courier.billing_country_code}
                                    countrySelected={(value) => {
                                        partialShipmentModelUpdatedByForm('courier', 'billing_country_code', value);
                                    }}
                                    label={t('CourierBillingCountry')}
                                /> : null}
                            </Form>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <CreatePartialShipmentLineTable
                                t={t}
                                lines={model.lines}
                                onLineQtyChange={(index, quantity) => {
                                    partialShipmentLinesUpdatedByForm(index, quantity);
                                }}
                            />
                        </div>
                    </div>
                    <div className="row lara-row-margin">
                        <div className="col-md-12">
                            <PrimaryButton
                                size='mini'
                                float='left'
                                label={t('Create')}
                                onClick={() => this.onFormSubmit()}
                                loading={this.state.loading}
                            />
                        </div>
                    </div>
                </div> : null}
            </div>
        )
    }
}

CreatePartialShipmentComponent.propTypes = {
    shipmentCreated: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    partialShipmentModelUpdatedByForm: PropTypes.func.isRequired,
    orderId: PropTypes.number.isRequired,
    model: PropTypes.shape({
        shipping_addr: PropTypes.object.isRequired,
        billing_addr: PropTypes.object.isRequired,
        courier: PropTypes.object.isRequired,
        lines: PropTypes.array.isRequired
    })
    //history: PropTypes.shape({
    //    push: PropTypes.func.isRequired
    //}).isRequired,
};

function mapStateToProps(state) {
    return {
        createPartialOnServer: PropTypes.func.isRequired,
        partialShipmentModelUpdatedByForm: PropTypes.func.isRequired,
        partialShipmentLinesUpdatedByForm: PropTypes.func.isRequired,
        model: state.partialShipment
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        partialShipmentModelUpdatedByForm,
        partialShipmentLinesUpdatedByForm,
        createPartialOnServer
    }, dispatch)
}

export default withI18n()(connect(mapStateToProps, mapDispatchToProps)(CreatePartialShipmentComponent));