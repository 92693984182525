import React, { Component } from 'react';
import { bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {withI18n} from 'react-i18next';
import _ from 'lodash';
import update from 'immutability-helper';
import {Form, Segment, Image, Message, Modal, Header} from 'semantic-ui-react';
import AlertComponent from '../includes/messages/alert';
import SegmentRibbon from '../includes/partials/SegmentRibbon';
import PageBreadCrub from '../includes/partials/PageBreadCrub';
import FormError from '../includes/messages/422';
import Lara from '../../lara';
import {
    updateMyProfile,
    getApiToken
} from '../../actions/user'
import PrimaryButton from '../includes/buttons/PrimaryButton';
class MyProfilePage extends Component {
    constructor() {
        super();
        this.state = {
            model: {
                id: null
            },
            errors: [],
            breadcrub: [
                {isLink:false, to:null, tag: 'Profile', active: false, key: 1}
            ],
            loading: false,
            new_token: "",
            tokenModal: false
        }
    }

    componentDidMount() {
        const u = _.cloneDeep(this.props.user);
        u.is_update_password = false;
        u.password = "";
        u.password_confirm = "";
        this.setState({
            model: u
        })
    }

    onAvatarSelect = (avatar) => {
        this.setState({
            model: update(this.state.model, {
                avatar_path: {$set: avatar}
            })
        })
    }

    onFormSubmit = () => {
        const errors = this.validateInput();
        if (_.isEmpty(errors)) {
            this.setState({
                loading: true
            });
            this.props.updateMyProfile(this.state.model).then(res => {
                this.setState({
                    loading: false,
                    errors: []
                })
                Lara.alertSuccess("Profile updated.");
            }).catch(err => this.handleAxiosError(err));
        } else {
            this.setState({
                errors: errors
            });
        }
    };

    validateInput = () => {
        const {email, name, password, is_update_password, password_confirm} = this.state.model;
        const errors = [];
        if (!email) {
            errors.push(this.props.t('Email is required'));
        }
        if (!name) {
            errors.push(this.props.t('Name is required'));
        }
        if (is_update_password) {
            if (!password) {
                errors.push(this.props.t('Password is required'));
            }
            if (password !== password_confirm) {
                errors.push(this.props.t('Two password are not the same'));
            }
        }
        return errors;
    };

    handleAxiosError = (err) => {
        const errors = Lara.axiosError(err, this.props.t);
        if (errors.http_code === 422) {
            this.setState({
                errors: errors.message,
                loading: false
            });
        } else {
            this.setState({
                loading: false
            });
            Lara.axiosAlert(<AlertComponent 
                support={errors.support}
                message={errors.message}
                reference={errors.reference}
                t={this.props.t}
            />);
        }
        this.setState({loading: false});
    }

    onGenerateTokenClick = () => {
        this.setState({
            loading: true
        });
        this.props.getApiToken().then(res => {
            this.setState({
                loading: false,
                new_token: res.data.data,
                tokenModal: true
            })
        }).catch(err => {
            this.handleAxiosError(err);
        })
    }
    
    render() {
        const {model} = this.state;
        const {t, host} = this.props;
        return (
            <div>
                <div className="row">
                    <div className="col-md-12 lara-breadcrub">
                        <PageBreadCrub lists={this.state.breadcrub} t={t}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Segment>
                            <SegmentRibbon label={`${t('MyProfile')}`} Buttons={
                                () => {return (<React.Fragment>
                                    <PrimaryButton
                                        size='mini'
                                        float='right'
                                        label={t('Save')}
                                        onClick={() => {this.onFormSubmit()}}
                                        loading={this.state.loading}
                                    />
                                </React.Fragment>)}
                            }/>
                            {model.id ? <Form size='mini'>
                                {!_.isEmpty(this.state.errors) ? (
                                    <FormError errors={this.state.errors} />
                                ) : null}
                                <Form.Input
                                    type='email'
                                    value={model.email}
                                    onChange={(e, {value}) => {
                                        this.setState({
                                            model: update(this.state.model, {
                                                email: {$set: value}
                                            })
                                        })
                                    }}
                                    label={t('Email')}
                                />
                                <Form.Input
                                    value={model.name}
                                    onChange={(e, {value}) => {
                                        this.setState({
                                            model: update(this.state.model, {
                                                name: {$set: value}
                                            })
                                        })
                                    }}
                                    label={t('Name')}
                                />
                                <Form.Checkbox
                                    label={t('UpdateMyPassword')}
                                    name="is_update_password"
                                    onChange={(e,data) => {
                                        //data.checked
                                        this.setState({
                                            model: update(this.state.model, {
                                                is_update_password: {$set: data.checked}
                                            })
                                        })
                                    }}
                                    checked={model.is_update_password}
                                />
                                {model.is_update_password ? <Form.Input
                                    type='password'
                                    value={model.password}
                                    onChange={(e, {value}) => {
                                        this.setState({
                                            model: update(this.state.model, {
                                                password: {$set: value}
                                            })
                                        })
                                    }}
                                    label={t('Password')}
                                /> : null}
                                {model.is_update_password ? <Form.Input
                                    type='password'
                                    value={model.password_confirm}
                                    onChange={(e, {value}) => {
                                        this.setState({
                                            model: update(this.state.model, {
                                                password_confirm: {$set: value}
                                            })
                                        })
                                    }}
                                    label={t('PasswordConfirm')}
                                /> : null}
                                <Form.Select
                                    options={[
                                        {value: 'en', text: t('English')},
                                        // {value: 'ch', text: t('SimplifiedChinese')}
                                    ]}
                                    value={model.lang}
                                    onChange={(e, {value}) => {
                                        this.setState({
                                            model: update(this.state.model, {
                                                lang: {$set: value}
                                            })
                                        })
                                    }}
                                    label={t('Language')}
                                />
                                <div>
                                    <Image.Group size='mini'>
                                        <span className="lara-clickable" onClick={() => {
                                            this.onAvatarSelect('user_1.png')
                                        }}>
                                            <Image src={`${host}/avatars/user_1.png`} size='mini' 
                                                bordered={model.avatar_path === 'user_1.png' ? true : false}/>
                                        </span>
                                        <span className="lara-clickable" onClick={() => {
                                            this.onAvatarSelect('user_2.png')
                                        }}>
                                            <Image src={`${host}/avatars/user_2.png`} size='mini' 
                                                bordered={model.avatar_path === 'user_2.png' ? true : false}/>
                                        </span>
                                        <span className="lara-clickable" onClick={() => {
                                            this.onAvatarSelect('user_3.png')
                                        }}>
                                            <Image src={`${host}/avatars/user_3.png`} size='mini' 
                                                bordered={model.avatar_path === 'user_3.png' ? true : false}/>
                                        </span>
                                        <span className="lara-clickable" onClick={() => {
                                            this.onAvatarSelect('user_4.png')
                                        }}>
                                            <Image src={`${host}/avatars/user_4.png`} size='mini' 
                                                bordered={model.avatar_path === 'user_4.png' ? true : false}/>
                                        </span>
                                        <span className="lara-clickable" onClick={() => {
                                            this.onAvatarSelect('user_5.png')
                                        }}>
                                            <Image src={`${host}/avatars/user_5.png`} size='mini' 
                                                bordered={model.avatar_path === 'user_5.png' ? true : false}/>
                                        </span>
                                        <span className="lara-clickable" onClick={() => {
                                            this.onAvatarSelect('user_6.png')
                                        }}>
                                            <Image src={`${host}/avatars/user_6.png`} size='mini' 
                                                bordered={model.avatar_path === 'user_6.png' ? true : false}/>
                                        </span>
                                        <span className="lara-clickable" onClick={() => {
                                            this.onAvatarSelect('user_7.png')
                                        }}>
                                            <Image src={`${host}/avatars/user_7.png`} size='mini' 
                                                bordered={model.avatar_path === 'user_7.png' ? true : false}/>
                                        </span>
                                        <span className="lara-clickable" onClick={() => {
                                            this.onAvatarSelect('user_8.png')
                                        }}>
                                            <Image src={`${host}/avatars/user_8.png`} size='mini' 
                                                bordered={model.avatar_path === 'user_8.png' ? true : false}/>
                                        </span>
                                        <span className="lara-clickable" onClick={() => {
                                            this.onAvatarSelect('user_9.png')
                                        }}>
                                            <Image src={`${host}/avatars/user_9.png`} size='mini' 
                                                bordered={model.avatar_path === 'user_9.png' ? true : false}/>
                                        </span>
                                        <span className="lara-clickable" onClick={() => {
                                            this.onAvatarSelect('user_10.png')
                                        }}>
                                            <Image src={`${host}/avatars/user_10.png`} size='mini' 
                                                bordered={model.avatar_path === 'user_10.png' ? true : false}/>
                                        </span>
                                        <span className="lara-clickable" onClick={() => {
                                            this.onAvatarSelect('user_11.png')
                                        }}>
                                            <Image src={`${host}/avatars/user_11.png`} size='mini' 
                                                bordered={model.avatar_path === 'user_11.png' ? true : false}/>
                                        </span>
                                        <span className="lara-clickable" onClick={() => {
                                            this.onAvatarSelect('user_12.png')
                                        }}>
                                            <Image src={`${host}/avatars/user_12.png`} size='mini' 
                                                bordered={model.avatar_path === 'user_12.png' ? true : false}/>
                                        </span>
                                        <span className="lara-clickable" onClick={() => {
                                            this.onAvatarSelect('user_13.png')
                                        }}>
                                            <Image src={`${host}/avatars/user_13.png`} size='mini' 
                                                bordered={model.avatar_path === 'user_13.png' ? true : false}/>
                                        </span>
                                        <span className="lara-clickable" onClick={() => {
                                            this.onAvatarSelect('user_14.png')
                                        }}>
                                            <Image src={`${host}/avatars/user_14.png`} size='mini' 
                                                bordered={model.avatar_path === 'user_14.png' ? true : false}/>
                                        </span>
                                        <span className="lara-clickable" onClick={() => {
                                            this.onAvatarSelect('user_15.png')
                                        }}>
                                            <Image src={`${host}/avatars/user_15.png`} size='mini' 
                                                bordered={model.avatar_path === 'user_15.png' ? true : false}/>
                                        </span>
                                        <span className="lara-clickable" onClick={() => {
                                            this.onAvatarSelect('user_16.png')
                                        }}>
                                            <Image src={`${host}/avatars/user_16.png`} size='mini' 
                                                bordered={model.avatar_path === 'user_16.png' ? true : false}/>
                                        </span>
                                        <span className="lara-clickable" onClick={() => {
                                            this.onAvatarSelect('user_17.png')
                                        }}>
                                            <Image src={`${host}/avatars/user_17.png`} size='mini' 
                                                bordered={model.avatar_path === 'user_17.png' ? true : false}/>
                                        </span>
                                        <span className="lara-clickable" onClick={() => {
                                            this.onAvatarSelect('user_18.png')
                                        }}>
                                            <Image src={`${host}/avatars/user_18.png`} size='mini' 
                                                bordered={model.avatar_path === 'user_18.png' ? true : false}/>
                                        </span>
                                        <span className="lara-clickable" onClick={() => {
                                            this.onAvatarSelect('user_19.png')
                                        }}>
                                            <Image src={`${host}/avatars/user_19.png`} size='mini' 
                                                bordered={model.avatar_path === 'user_19.png' ? true : false}/>
                                        </span>
                                        <span className="lara-clickable" onClick={() => {
                                            this.onAvatarSelect('user_20.png')
                                        }}>
                                            <Image src={`${host}/avatars/user_20.png`} size='mini' 
                                                bordered={model.avatar_path === 'user_20.png' ? true : false}/>
                                        </span>
                                        <span className="lara-clickable" onClick={() => {
                                            this.onAvatarSelect('user_21.png')
                                        }}>
                                            <Image src={`${host}/avatars/user_21.png`} size='mini' 
                                                bordered={model.avatar_path === 'user_21.png' ? true : false}/>
                                        </span>
                                        <span className="lara-clickable" onClick={() => {
                                            this.onAvatarSelect('user_22.png')
                                        }}>
                                            <Image src={`${host}/avatars/user_22.png`} size='mini' 
                                                bordered={model.avatar_path === 'user_22.png' ? true : false}/>
                                        </span>
                                        <span className="lara-clickable" onClick={() => {
                                            this.onAvatarSelect('user_23.png')
                                        }}>
                                            <Image src={`${host}/avatars/user_23.png`} size='mini' 
                                                bordered={model.avatar_path === 'user_23.png' ? true : false}/>
                                        </span>
                                        <span className="lara-clickable" onClick={() => {
                                            this.onAvatarSelect('user_24.png')
                                        }}>
                                            <Image src={`${host}/avatars/user_24.png`} size='mini' 
                                                bordered={model.avatar_path === 'user_24.png' ? true : false}/>
                                        </span>
                                        <span className="lara-clickable" onClick={() => {
                                            this.onAvatarSelect('user_25.png')
                                        }}>
                                            <Image src={`${host}/avatars/user_25.png`} size='mini' 
                                                bordered={model.avatar_path === 'user_25.png' ? true : false}/>
                                        </span>
                                        <span className="lara-clickable" onClick={() => {
                                            this.onAvatarSelect('user_26.png')
                                        }}>
                                            <Image src={`${host}/avatars/user_26.png`} size='mini' 
                                                bordered={model.avatar_path === 'user_26.png' ? true : false}/>
                                        </span>
                                        <span className="lara-clickable" onClick={() => {
                                            this.onAvatarSelect('user_27.png')
                                        }}>
                                            <Image src={`${host}/avatars/user_27.png`} size='mini' 
                                                bordered={model.avatar_path === 'user_27.png' ? true : false}/>
                                        </span>
                                        <span className="lara-clickable" onClick={() => {
                                            this.onAvatarSelect('user_28.png')
                                        }}>
                                            <Image src={`${host}/avatars/user_28.png`} size='mini' 
                                                bordered={model.avatar_path === 'user_28.png' ? true : false}/>
                                        </span>
                                        <span className="lara-clickable" onClick={() => {
                                            this.onAvatarSelect('user_29.png')
                                        }}>
                                            <Image src={`${host}/avatars/user_29.png`} size='mini' 
                                                bordered={model.avatar_path === 'user_29.png' ? true : false}/>
                                        </span>
                                    </Image.Group>
                                    <span style={{fontSize: "10px"}}>Avatars designed by Freepik from Flaticon</span>
                                </div>
                            </Form> : null}
                        </Segment>
                    </div>
                </div>
                <div className="row lara-row-margin">
                    <div className="col-md-6">
                        <Segment>
                            <SegmentRibbon label={`${t('APIToken')}`} Buttons={
                                () => {return (<React.Fragment>
                                    <PrimaryButton
                                        size='mini'
                                        float='right'
                                        label={t('Generate')}
                                        onClick={() => {this.onGenerateTokenClick()}}
                                        loading={this.state.loading}
                                    />
                                </React.Fragment>)}
                            }/>
                            {this.props.user.has_api_token ? <Message>
                                <Message.Header>You already have a token</Message.Header>
                                <Message.List>
                                    <Message.Item>
                                        Your token will not be expired. You can click "generate" to revoke the existing token.
                                    </Message.Item>
                                    {this.props.user.is_main_user ? <Message.Item>
                                        This token is also used by the Android app.
                                    </Message.Item> : null}
                                </Message.List>
                            </Message> : <Message>
                                <Message.Header>You do not have API token</Message.Header>
                                <Message.List>
                                    <Message.Item>
                                        Each account can have only one token
                                    </Message.Item>
                                    <Message.Item>
                                        Your token will not be expired. You can click "generate" to revoke the existing token.
                                    </Message.Item>
                                    {this.props.user.is_main_user ? <Message.Item>
                                        If you want to create a token for your account, please make sure your account 
                                        is not used in the Android app.
                                    </Message.Item> : null}
                                </Message.List>
                            </Message>}
                        </Segment>
                    </div>
                    <Modal
                        closeOnDimmerClick={false}
                        closeIcon
                        onClose={() => {
                            this.setState({tokenModal: false})
                        }}
                        size='mini'
                        closeOnEscape={true} open={this.state.tokenModal}>
                        <Header icon='lock' content={null} />
                        <Modal.Content>
                            <Header as='h4'>
                                Please save your token, this will not show again.
                            </Header>
                            <span className="lara-token-wrap">
                                {this.state.new_token}
                            </span>
                        </Modal.Content>
                    </Modal>
                </div>
            </div>
        )
    }
}

MyProfilePage.propTypes = {
    user: PropTypes.object.isRequired,
    updateMyProfile: PropTypes.func.isRequired,
    getApiToken: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        user: state.system.user,
        host: state.system.preference.host
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        updateMyProfile,
        getApiToken
    }, dispatch)
}

export default withI18n()(connect(mapStateToProps, mapDispatchToProps)(MyProfilePage));